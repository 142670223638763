import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../../shared/guard/auth.guard';
import { MediaDashboardFranchiseeComponent } from './media-dashboard-franchisee.component';

export const franchiseeDashboardModuleName = 'Franchisee Embedded Dashboard';

const routes: Routes = [
  {
    path: '',
    component: MediaDashboardFranchiseeComponent,
    canActivate: [AuthGuard],
    data: { module: franchiseeDashboardModuleName },
  },
  {
    path: 'group/:groupId',
    component: MediaDashboardFranchiseeComponent,
    canActivate: [AuthGuard],
    data: { module: franchiseeDashboardModuleName },
  },
  {
    path: ':accountId',
    component: MediaDashboardFranchiseeComponent,
    canActivate: [AuthGuard],
    data: { module: franchiseeDashboardModuleName },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MediaDashboardFranchiseeRoutingModule {}
