import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnInit,
  ViewContainerRef,
  ComponentFactory,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { InputComponent } from '../input/input.component';
import { ButtonComponent } from '../button/button.component';
import { SelectFieldComponent } from '../select/select.component';
import { DateComponent } from '../date/date.component';
import { RadiobuttonComponent } from '../radiobutton/radiobutton.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { FieldConfig } from '../../../../shared/interfaces/field.interface';
import { SwitchFieldComponent } from '../switch/switch.component';
import { ListboxFieldComponent } from '../listbox/listbox.component';
import { LabelComponent } from '../label/label.component';
import { SliderComponent } from '../slider/slider.component';
import { MultiSelectFieldComponent } from '../multi-select/multi-select.component';
import { TextareaComponent } from '../textarea/textarea.component';
import { ObjectIndexer } from '../../../../shared/interfaces';
import { ChipsComponent } from '../chip/chip.component';
import { TableInputComponent } from '../table-input/table-input.component';

const componentMapper: ObjectIndexer<any> = {
  input: InputComponent,
  textarea: TextareaComponent,
  button: ButtonComponent,
  select: SelectFieldComponent,
  multiselect: MultiSelectFieldComponent,
  listbox: ListboxFieldComponent,
  date: DateComponent,
  radiobutton: RadiobuttonComponent,
  checkbox: CheckboxComponent,
  switch: SwitchFieldComponent,
  label: LabelComponent,
  slider: SliderComponent,
  chips: ChipsComponent,
  "table-input": TableInputComponent
};
interface FormComponentRef {
  field: FieldConfig;
  group: UntypedFormGroup | undefined;
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[dynamicField]',
})
export class DynamicFieldDirective implements OnInit {

  private _field: FieldConfig | undefined;
  public get field(): FieldConfig | undefined {
    return this._field;
  }
  @Input() public set field(value: FieldConfig | undefined) {
    this._field = value;
    this.Initialize();
  }

  private _group: UntypedFormGroup | undefined;
  public get group(): UntypedFormGroup | undefined {
    return this._group;
  }
  @Input()
  public set group(value: UntypedFormGroup | undefined) {
    this._group = value;
    this.Initialize();
  }
  componentRef: ComponentRef<FormComponentRef>|undefined;
  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {
    this.Initialize();

  }
  private Initialize() {
    if (this.field && this.group) {
      const factory = this.resolver.resolveComponentFactory(componentMapper[this.field.type]);
      this.componentRef = this.container.createComponent(factory as ComponentFactory<FormComponentRef>);
      this.componentRef.instance.field = this.field;
      this.componentRef.instance.group = this.group;
    }
  }

  ngOnInit() {

  }
}
