import { Component, OnInit } from '@angular/core';
import { FieldClass } from '../field/field.class';
import { DropdownOption } from '../../../../shared/interfaces';
@Component({
  selector: 'app-chips',
  template: ` <div
    class="row mar-b-50 inputfield_"
    [ngClass]="{
      is_block_full: field?.isBlock,
      haselement: field?.hasElement,
      is_col_6: field?.size === 'xxxsmall',
      is_col_5: field?.size === 'xxsmall',
      is_col_4: field?.size === 'xsmall',
      is_col_3: field?.size === 'small',
      is_col_2: field?.size === 'medium',
      is_fullwidth: !field?.size || field?.size === 'large'
    }"
  >
    <div [ngClass]="{ i_tooltip: field?.hasTooltip }">
      <div class="nopadding">
        <app-form-chips
          [id]="field?.id"
          [name]="field?.name"
          [readonly]="isReadonly()"
          (onChange)="changed($event)"
          [group]="group"
          [validations]="field?.validations"
          [cssStyle]="field?.style"
          [options]="field?.options || ''"
          [placeholder]="field?.label + (field?.isRequired ? '*' : '')"
          [disabled]="disabled()"
        ></app-form-chips>
      </div>
      <div
        class="col-md-4 col-sm-4 nopadding"
        *ngIf="field?.suffix || field?.tooltip"
      >
        <label style="font-weight: normal;" *ngIf="field?.suffix">{{
          field?.suffix
        }}</label>
        <!-- Tooltip Info -->
        <div class="pri-tooltip lookback-info" *ngIf="field?.tooltip">
          <strong class="epsilon">
            <i
              class="fa fa-question-circle"
              style="color: #1e88e5;font-size: 16px;"
            >
            </i>
          </strong>
          <p class="content">
            <strong>{{ field?.tooltipTitle }}</strong>
            {{ field?.tooltip }}
          </p>
        </div>
      </div>
    </div>
    <div class="pri-tooltip lookback-info" *ngIf="field?.description">
      <strong class="epsilon">
        <i
          class="fa fa-question-circle"
          style="color: #1e88e5;font-size: 16px;"
        ></i>
      </strong>
      <p class="content">
        {{ field?.description }}
      </p>
    </div>
  </div>`,
  styles: [],
})
export class ChipsComponent extends FieldClass implements OnInit {
  constructor() {
    super();
  }
  ngOnInit() {}

  isReadonly() {
    return this.field?.readonly ? this.field?.readonly() : false;
  }

  changed(value: DropdownOption[]) {
    if (this.field?.returnChipsAsStringArray) {
      this.chipValues?.setValue(value.map(val => {
        return val.value;
      }));
    } else {
      this.chipValues?.setValue(value);
    }

    this.chipValues?.updateValueAndValidity();
  }

  get chipValues() {
      return this.group?.get(this.field?.name as string);
  }
}
