import { Action } from '@ngrx/store';
import { LoginPayload } from '../../interfaces';
import { AuthToken } from '../../interfaces/auth.interface';

export const AUTH_AUTHENTICATE = 'Auth Auth Authenticate';
export const AUTH_AUTHENTICATE_SUCCESS = 'Auth Auth Authenticate success';
export const AUTH_AUTHENTICATE_FAIL = 'Auth Auth Authenticate fail';

export const AUTH_LOGOUT = 'Auth Auth Logout';
export const AUTH_LOGOUT_SUCCESS = 'Auth Auth Logout success';
export const AUTH_LOGOUT_FAIL = 'Auth Auth Logout fail';

export const SET_AUTH_AUTHENTICATED = 'Auth Set Auth Authenticated';

export class AuthAuthenticate implements Action {
  readonly type = AUTH_AUTHENTICATE;
  constructor(public payload: LoginPayload) {}
}

export class AuthAuthenticateSuccess implements Action {
  readonly type = AUTH_AUTHENTICATE_SUCCESS;
  constructor(public payload: AuthToken) {}
}

export class AuthAuthenticateFail implements Action {
  readonly type = AUTH_AUTHENTICATE_FAIL;
  constructor(public payload?: string) {}
}

export class SetAuthAuthenticated implements Action {
  readonly type = SET_AUTH_AUTHENTICATED;
  constructor(public payload: boolean, public error?: string) {}
}

export class AuthLogout implements Action {
  readonly type = AUTH_LOGOUT;
  constructor() {}
}

export class AuthLogoutSuccess implements Action {
  readonly type = AUTH_LOGOUT_SUCCESS;
  constructor() {}
}

export class AuthLogoutFail implements Action {
  readonly type = AUTH_LOGOUT_FAIL;
  constructor(public payload?: string) {}
}

export type All
  = AuthAuthenticate
  | AuthAuthenticateSuccess
  | AuthAuthenticateFail
  | AuthLogout
  | AuthLogoutSuccess
  | AuthLogoutFail
  | SetAuthAuthenticated;
