import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as GlobalFilterActions from './global-filter.actions';
import { AppState } from '../state';
import { TableSorting } from '../../models/global-filtering.model';
import {
  DateComparisonType,
  DateRollup,
  LocationSummaryResponse,
  SelectedLocationFilters,
} from '../../interfaces';

@Injectable()
export class GlobalFilterFacade {
  constructor(private store: Store<AppState>) {}

  changeInSelectedSortingOption(selectedSortingOption: TableSorting) {
    this.store.dispatch(
      GlobalFilterActions.actionChangeInSortingOption({
        selectedSortingOption,
      })
    );
  }

  changeInLocationFilters(
    selectedLocationFilters: SelectedLocationFilters,
    comparePrevious?: boolean,
    selectedDateComparisonOption?: DateComparisonType
  ) {
    this.store.dispatch(
      GlobalFilterActions.actionChangeInLocationFilters({
        selectedLocationFilters,
        comparePrevious,
        selectedDateComparisonOption,
      })
    );
  }

  changeInLocationSummaryData(
    locationSummary: LocationSummaryResponse['data'],
    accountId: string
  ) {
    this.store.dispatch(
      GlobalFilterActions.actionChangeInLocationSummaryData({
        locationSummary,
        accountId,
      })
    );
  }

  changeInDateFiltering(
    fromDate: Date,
    toDate: Date,
    comparePrevious: boolean,
    selectedDateComparisonOption: DateComparisonType,
    dateRollUp: DateRollup,
    selectedLocationFilters?: SelectedLocationFilters
  ) {
    this.store.dispatch(
      GlobalFilterActions.actionChangeInDateFiltering({
        fromDate,
        toDate,
        comparePrevious,
        selectedDateComparisonOption,
        dateRollUp,
        selectedLocationFilters,
      })
    );
  }

  changeInDateRange(fromDate: Date, toDate: Date) {
    this.store.dispatch(
      GlobalFilterActions.actionChangeInDateRange({
        fromDate,
        toDate,
      })
    );
  }

  changeInLocationSearch(searchInput: string) {
    this.store.dispatch(
      GlobalFilterActions.actionChangeInLocationSearch({ searchInput })
    );
  }

  resetGlobalFilterState() {
    this.store.dispatch(
      GlobalFilterActions.actionResetGlobalFilterState()
    );
  }

}
