import { createSelector } from '@ngrx/store';
import { AppState } from '../state';
import { AdvertiserState } from './accounts.model';

export const selectedAccountState = (state: AppState) => state.advertiser;

export const selectAccountStateAccounts = createSelector(
  selectedAccountState,
  (state: AdvertiserState) => state?.accounts
);

export const selectAccountStateAccount = createSelector(
  selectedAccountState,
  (state: AdvertiserState) => state?.account
);

export const selectAccountStateGroups = createSelector(
  selectedAccountState,
  (state: AdvertiserState) => state?.groups
);

export const selectAccountStateGroup = createSelector(
  selectedAccountState,
  (state: AdvertiserState) => state?.group
);

export const selectAccountStateLoading = createSelector(
  selectedAccountState,
  (state: AdvertiserState) => state?.loading
);

export const selectAccountStateStatus = createSelector(
  selectedAccountState,
  (state: AdvertiserState) => state?.status
);
