import { Component, OnInit } from '@angular/core';
import { FieldClass } from '../field/field.class';
@Component({
  selector: 'app-radiobutton',
  template: `
  <div class="row radiofield_ mar-b-50" *ngIf="display()" [formGroup]="group"
  [ngClass]="{
    'is_block_full': field.isBlock,
    'haselement': field.hasElement,
    'is_col_6': field.size === 'xxxsmall',
    'is_col_5': field.size === 'xxsmall',
    'is_col_4': field.size === 'xsmall',
    'is_col_3': field.size === 'small',
    'is_col_2': field.size === 'medium',
    'is_fullwidth': !field.size || field.size === 'large'}">
    <div class="col-md-12 nopadding">
      <label class="nlabel" style="display:inline-block;">{{field.label}}<span *ngIf="field.isRequired" style="color: red;">*</span></label>
      <div class="pri-tooltip lookback-info" *ngIf="field.description">
        <strong class="epsilon">
          <i class="fa fa-question-circle" style="color: #1e88e5;font-size: 16px;"></i>
        </strong>
        <p class="content">
          {{field.description}}
        </p>
      </div>
      <div class="nopadding mar-b-30">
        <app-form-radio
        [options]="field.options"
        [formControlName]="field.name"
        [id]="field.id"
        [disabled]="disabled()"
        (onChange)="onChange($event)"
        [value]="field.value"></app-form-radio>
      </div>
    </div>
  </div>`,
  styles: []
})
export class RadiobuttonComponent extends FieldClass implements OnInit {
  constructor() {
    super();
  }
  ngOnInit() {}
}
