import { AccountGroup, SearchResult, Account } from '../../../interfaces';
import { UserWhoIsInfo, ResourceClaim } from '../../../interfaces/claim.interface';

export interface AccountState {
  loading?: boolean;
  userLoading?: boolean;
  groupLoading?: boolean;
  advertiserLoading?: boolean;
  searchLoading?: boolean;
  searchResult?: SearchResult;
  user?: UserWhoIsInfo[];
  groupId: string;
  advertiserId: string;
  activeSelection: {
    Name: string,
    Type: string
  };
  activeAdvertiser: Account;
  activeAdvertiserGroup: AccountGroup;
  advertisers: Account[];
  groupAdvertisers?: Account[];
  allowedAdvertisers?: Account[];
  advertiserGroups: AccountGroup[];
  advertiserRooftop: AccountGroup[];
  claims?: ResourceClaim[];
  deletedAdvertiser?: string;
  error?: string;
  searchError?: string;
  userError?: string;
  groupError?: string;
  advertiserError?: string;
  showInactive?: boolean;
  status?: string;
}

export enum ActiveSelectionTypes {
  Account = 'Advertiser',
  AccountGroup = 'Advertiser Group'
}
