import { createSelector } from '@ngrx/store';
import { AppState } from '../state';


export const selectPermissionState = (state: AppState) => state.permissions;

export const selectPermissionsStatePermissions = createSelector(
  selectPermissionState,
  (state) => state?.permissions
);

export const selectPermissionsStateDashboard = createSelector(
  selectPermissionState,
  (state) => state?.dashboard
);

export const selectPermissionsStateModule = createSelector(
  selectPermissionState,
  (state) => state?.module
);

export const selectPermissionsStateAccess = createSelector(
  selectPermissionState,
  (state) => state?.access
);
