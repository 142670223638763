import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// Router---------------------------------------------
import { RouterModule } from '@angular/router';
// COMPONENTS

// PIPES
// 3rd Party
import './rxjs-extensions';

import { ngfModule } from 'angular-file';

import { MaterialModule } from '../material.module';
import { GroupListComponent } from '../components/directives/group-list/group-list.directive';
import { PageMessageComponent } from '../components/directives/page-message/page-message.directive';

import { CustomFormModule } from './form.module';
import { OrderModule } from 'ngx-order-pipe';
import { LoadingOverlayComponent } from '../components/directives/overlay/overlay.component';
import { QueryFilterBuilderComponent } from '../components/directives/query-filter/query-filter.directive';
import { AttributionFilterComponent } from '../components/attribution/directives/filter/filter.directive';
import { AppSpinnerComponent } from '../components/directives/spinner/spinner.component';
import { FileUploadComponent } from '../components/directives/file-upload/file-upload.directive';
import { AppPieChartComponent } from '../components/directives/pie-chart/pie-chart.component';
import { AppCalendarWrapperComponent } from '../components/directives/calendar-wrapper/calendar-wrapper.component';
import { AppLineChartComponent } from '../components/directives/line-chart/line-chart.component';
import { TableRenderComponent } from '../components/directives/table render/table-render.directive';
import { FilterColumnComponent } from '../components/directives/table render/filter/filter.directive';
import { SafePipe, KeyValuePipe } from '../pipes';
import { QueryBuilderModule } from '@syncfusion/ej2-angular-querybuilder';
import {
  TreeViewModule,
  AccordionModule,
} from '@syncfusion/ej2-angular-navigations';
import {
  CheckBoxModule,
  RadioButtonModule,
} from '@syncfusion/ej2-angular-buttons';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { AppColumnChartComponent } from '../components/directives/column-chart/column-chart.component';
import { AppPageOverlayComponent } from '../components/directives/page-overlay/page-overlay.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { GroupTreeDisplayContentComponent } from '../components/directives/tree-display/group-tree-display/tree-display.directive';
import { D3TimeSeriesLineChartComponent } from '../components/directives/d3-chart-library/time-series-line-chart/time-series-line-chart.component';
import { NumberCardComponent } from '../components/directives/d3-chart-library/number-card/number-card.component';
import { DataTableComponent } from '../components/directives/d3-chart-library/data-table/data-table.component';
import { DateRangePickerComponent } from '../components/directives/date-range-picker/date-range-picker.directive';
import { DateDialogContentComponent } from '../components/directives/date-dialog/date-range-picker-dialog.directive';
import { D3ColumnChartComponent } from '../components/directives/d3-chart-library/column-chart/column-chart.component';
import { D3PieChartComponent } from '../components/directives/d3-chart-library/pie-chart/pie-chart.component';
import { IntegrationSubmitButton } from '../components/directives/integration-submit-button/integration-submit-button.component';
import { GlobalFilterComponent } from '../components/directives/global-filter/global-filter.directive';
import { GlobalFilterDatePickerComponent } from '../components/directives/global-filter/global-filter-date-picker/global-filter-date-picker.directive';
import { GlobalFilterRightSheetComponent } from '../components/directives/global-filter/global-filter-right-sheet/global-filter-right-sheet.component';
import { ErrorIntercomTextComponent } from '../components/directives/d3-chart-library/error-intercom-text/error-intercom-text.component';
import { IntercomTicketBtnComponent } from '../components/directives/d3-chart-library/intercom-ticket-btn/intercom-ticket-btn.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    GroupListComponent,
    PageMessageComponent,
    LoadingOverlayComponent,
    IntegrationSubmitButton,
    AppPageOverlayComponent,
    QueryFilterBuilderComponent,
    AttributionFilterComponent,
    AppSpinnerComponent,
    FileUploadComponent,
    AppPieChartComponent,
    AppCalendarWrapperComponent,
    AppLineChartComponent,
    TableRenderComponent,
    FilterColumnComponent,
    AppColumnChartComponent,
    D3TimeSeriesLineChartComponent,
    D3ColumnChartComponent,
    D3PieChartComponent,
    NumberCardComponent,
    DataTableComponent,
    ErrorIntercomTextComponent,
    IntercomTicketBtnComponent,
    SafePipe,
    KeyValuePipe,
    GroupTreeDisplayContentComponent,
    DateRangePickerComponent,
    DateDialogContentComponent,
    GlobalFilterComponent,
    GlobalFilterDatePickerComponent,
    GlobalFilterRightSheetComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    OrderModule,
    ngfModule,
    CustomFormModule,
    QueryBuilderModule,
    TreeViewModule,
    CheckBoxModule,
    ListViewModule,
    AccordionModule,
    DropDownListModule,
    RadioButtonModule,
    NgxMaskModule.forRoot(maskConfig)
  ],
  exports: [
    CommonModule,
    RouterModule,
    GroupListComponent,
    PageMessageComponent,
    MaterialModule,
    ngfModule,
    CustomFormModule,
    LoadingOverlayComponent,
    IntegrationSubmitButton,
    AppPageOverlayComponent,
    QueryFilterBuilderComponent,
    AttributionFilterComponent,
    AppSpinnerComponent,
    FileUploadComponent,
    AppPieChartComponent,
    AppCalendarWrapperComponent,
    AppLineChartComponent,
    FilterColumnComponent,
    TableRenderComponent,
    SafePipe,
    KeyValuePipe,
    QueryBuilderModule,
    TreeViewModule,
    CheckBoxModule,
    ListViewModule,
    AccordionModule,
    DropDownListModule,
    RadioButtonModule,
    AppColumnChartComponent,
    D3TimeSeriesLineChartComponent,
    D3ColumnChartComponent,
    D3PieChartComponent,
    NumberCardComponent,
    DataTableComponent,
    ErrorIntercomTextComponent,
    IntercomTicketBtnComponent,
    GroupTreeDisplayContentComponent,
    NgxMaskModule,
    DateRangePickerComponent,
    DateDialogContentComponent,
    GlobalFilterComponent,
    GlobalFilterDatePickerComponent,
    GlobalFilterRightSheetComponent
  ],
  providers: [],
  bootstrap: [],
})
export class CoreModule {}
