import { ModulePermission } from '../interfaces/common.interface';
import { PermissionNamespaceMap } from '../state/permissions/permissions-namespace-map.enum';
import { Routes } from './routes.model';

export const pagePermissions: ModulePermission[] = [
  {
    namespace: 'com.transitiv.dashboard.franchisee',
    module: 'Dashboard',
  },
  {
    namespace: 'com.transitiv.querybuilder',
    module: 'Query Builder',
  },
  {
    namespace: 'com.transitiv.customer',
    module: 'Customer',
  },
  {
    namespace: 'com.transitiv.integrations',
    module: 'Integrations',
  },
  {
    namespace: 'com.transitiv.dashboard',
    module: 'Dashboard',
  },
  {
    namespace: 'com.transitiv.dashboard.agency',
    module: 'Dashboard',
  },
  {
    namespace: 'com.transitiv.traffic',
    module: 'Traffic',
  },
  {
    namespace: 'com.transitiv.traffic.campaign',
    module: 'Traffic Campaigns',
  },
  {
    namespace: 'com.transitiv.traffic.creative',
    module: 'Creatives',
  },
  {
    namespace: 'com.transitiv.traffic.conversion',
    module: 'Tracking',
  },
  {
    namespace: 'com.transitiv.analytics',
    module: 'Analytics',
  },
  {
    namespace: 'com.transitiv.attribution',
    module: 'Attribution',
  },
  {
    namespace: 'com.transitiv.datasources',
    module: 'Data Sources',
  },
  {
    namespace: 'com.transitiv.dashboard.fit',
    module: 'FIT Home',
  },
];

export const dashboards = [
  {
    namespace: 'com.transitiv.dashboard',
    path: '/dashboard/',
  },
  {
    namespace: 'com.transitiv.dashboard.default',
    path: '/dashboard/default/',
  },
  {
    namespace: 'com.transitiv.dashboard.franchisee',
    path: '/dashboard/embedded/',
  },
  {
    namespace: 'com.transitiv.dashboard.agency',
    path: '/dashboard/agency/',
  },
  {
    namespace: 'com.transitiv.dashboard.franchisor',
    path: '/dashboard/',
  },
  {
    namespace: 'com.transitiv.dashboard.engine',
    path: '/dashboards/',
  },
];

export const dashboardPermissionHierarchy = [
  { namespace: PermissionNamespaceMap.FITHome, route: Routes.fit },
  {
    namespace: PermissionNamespaceMap.DashboardEngine,
    route: Routes.leaderboards,
  },
  {
    namespace: PermissionNamespaceMap.DashboardEngine,
    route: Routes.dashboards,
  },
  {
    namespace: PermissionNamespaceMap.FranchisorEmbeddedDashboard,
    route: Routes.mediaDashboardFranchisor,
  },
  {
    namespace: PermissionNamespaceMap.FranchiseeEmbeddedDashboard,
    route: Routes.mediaDashboardFranchisee,
  },
];
