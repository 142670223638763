import { EntitySelectorsFactory } from '@ngrx/data';
import { createSelector } from '@ngrx/store';
import { ModuleConfig } from '../../interfaces/common.interface';
import { EntityMap } from '../entity/entity-metadata';
import { ActiveSelectionTypes } from '../global';
import { selectAccountState } from '../global/account/account.selectors';

export const moduleConfigSelectors = new EntitySelectorsFactory().create<
  ModuleConfig
>(EntityMap.ModuleConfig);

export const selectModuleConfig = createSelector(
  moduleConfigSelectors.selectEntities,
  (data) => data
);

export const selectModuleConfigLoading = createSelector(
  moduleConfigSelectors.selectLoading,
  (data) => data
);

export const selectModuleConfigAndStatus = createSelector(
  moduleConfigSelectors.selectEntities,
  moduleConfigSelectors.selectLoaded,
  moduleConfigSelectors.selectLoading,
  selectAccountState,
  (moduleConfig, hasLoaded, isLoading, accountState) => {
    const activeSelectionType =
      accountState?.activeSelection?.Type === ActiveSelectionTypes.AccountGroup
        ? ActiveSelectionTypes.AccountGroup
        : accountState?.activeSelection?.Type === ActiveSelectionTypes.Account
        ? ActiveSelectionTypes.Account
        : undefined;
    let selectedId = '';
    if (activeSelectionType === ActiveSelectionTypes.AccountGroup) {
      selectedId = accountState?.activeAdvertiserGroup.GroupId;
    } else if (activeSelectionType === ActiveSelectionTypes.Account) {
      selectedId = accountState?.activeAdvertiser.AccountId;
    }
    return {
      activeSelectionType,
      selectedId,
      hasLoaded,
      isLoading,
      permissions: moduleConfig,
    };
  }
);
