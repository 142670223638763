import { createSelector } from '@ngrx/store';
import { AppState } from '../state';


export const selectSideMenuStatusState = (state: AppState) => state.sideMenuStatus;

export const selectSideMenuStatus = createSelector(
  selectSideMenuStatusState,
  (state) => state?.isOpen
);
