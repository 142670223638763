import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Tab } from '../../shared/interfaces/tab.interface';

@Injectable()
export class TabService {
  private tabs = new Subject<Tab[]>();
  private currentTab = new Subject<string>();
  itemsHandler = this.tabs.asObservable();
  currentTabHandler = this.currentTab.asObservable();

  setTab(tabs: Tab[]) {
    this.tabs.next(tabs);
  }

  setCurrentTab(tab: string) {
    this.currentTab.next(tab);
  }
}
