export interface DashboardLeaderboardV2Payload {
  Start: string;
  End: string;
  Filters: LeaderboardFilter[];
}

export type LeaderboardFilter =
  | NoFilter
  | FilterAllLocations
  | FilterToRegionsOrTerritories
  | FilterToFranchiseAge
  | FilterToTags
  | FilterToFitRange
  | FilterOnPeriod;

export interface FilterOnPeriod {
  Type: LeaderboardV2Filtering.OnPeriod;
  From: string;
  To: string;
}

export interface FilterToFranchiseAge {
  Type: LeaderboardV2Filtering.OnFranchiseAge;
  MinAge: number;
  MaxAge: number;
}

export interface FilterToFitRange {
  Type: LeaderboardV2Filtering.OnFit;
  MinScore: number;
  MaxScore: number;
}

export interface FilterToTags {
  Type: LeaderboardV2Filtering.OnTags;
  Tags: string[];
}

export interface FilterToRegionsOrTerritories {
  Type: LeaderboardV2Filtering.OnRegionOrTerritory;
  Regions: string[];
}

export interface FilterAllLocations {
  Type: LeaderboardV2Filtering.OnAllLocations;
}

export interface NoFilter {
  Type: LeaderboardV2Filtering.None;
}

export enum LeaderboardV2Filtering {
  None = 0,
  OnAllLocations = 1,
  OnRegionOrTerritory = 2,
  OnFranchiseAge = 3,
  OnTags = 4,
  OnFit = 5,
  OnPeriod = 6
}
