import { Injectable } from '@angular/core';
import {
  DashboardLeaderboardCarouselData,
  DashboardLeaderboardV2Response,
  DashboardLeaderboardV2TableData,
  DashobardLeaderobardV2Data,
} from '../../shared/interfaces';

@Injectable()
export class LeaderboardUtilityService {
  mapSourceToDestination(
    leaderboardV2Response: DashboardLeaderboardV2Response[],
    accountId: string
  ): DashobardLeaderobardV2Data[] {
    return leaderboardV2Response.map(
      (data): DashobardLeaderobardV2Data => {
        return {
          title: data.Title,
          unit: data.Unit,
          description: data.Description,
          rankingType: data.RankingType,
          errors: data.Errors,
          tableData: data.Data.map(
            (tableData): DashboardLeaderboardV2TableData => {
              return {
                rank: tableData.Rank,
                account: tableData.AccountName,
                accountId: tableData.AccountId,
                value: tableData.AggregatedValue,
                isSelf: tableData.AccountId === accountId,
                changeInRank: tableData.ChangeInRank,
                percentChangeInValue: tableData.ChangeInPercent,
              };
            }
          ),
          carouselData: data.Summaries.map(
            (summary): DashboardLeaderboardCarouselData => {
              return {
                name: summary.Name,
                value: summary.Value,
                percentChange: summary.PercentChange,
              };
            }
          ),
        };
      }
    );
  }
}
