import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityMap } from '../entity/entity-metadata';
import { CommonUtilityService } from '@services/utility/common-utility.service';
import { UserInfo } from '../../interfaces/common.interface';
import { AuthService } from '@services/auth/auth.service';

@Injectable()
export class UserDataService extends DefaultDataService<UserInfo> {

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private authService: AuthService,
  ) {
    super(EntityMap.User, http, httpUrlGenerator);
  }

  getAll(): Observable<UserInfo[]> {
    return this.authService.getUserInfo().pipe(
      map((user) => [CommonUtilityService.timestampStateData(user)]));
  }
}
