import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { MarketingSpend } from '../../shared/interfaces/market.interface';
import { MarketingSpendPayload } from '../../shared/interfaces';

@Injectable()
export class MarketingSpendService {
  public apiClient = <ApiClient>{};
  constructor() {}

  getMarketingSpend(payload: MarketingSpendPayload): Observable<MarketingSpend[]> {
    const { advertiserId, startDate, endDate } = payload;
    return this.apiClient.callHttpGet({
      path: `/marketingspend/data/${advertiserId}/${startDate}/${endDate}`,
      type: 'analytics'
    });
  }
}
