import { Action, createReducer, on } from '@ngrx/store';
import { actionAccountGetAllGroups, actionAccountGetAllGroupsSuccess, actionAccountGet, actionAccountGetSuccess,
  actionAccountError, actionAccountGetAllAccounts, actionAccountGetAllAccountsSuccess,
  actionAccountGroupGetSuccess, actionAccountGroupGet } from './accounts.actions';
import { AdvertiserState } from './accounts.model';

export const initialState: AdvertiserState = {
  id: '',
  loading: false
};

const reducer = createReducer(
  initialState,
  on(actionAccountGetAllGroups, (state, { type }) => ({
    ...state,
    loading: true,
    status: type,
    groups: undefined,
    error: undefined,
  })),
  on(actionAccountGetAllGroupsSuccess, (state, { groups, type }) => ({
    ...state,
    loading: false,
    groups,
    status: type,
    error: undefined
  })),
  on(actionAccountGetAllAccounts, (state, { type }) => ({
    ...state,
    loading: true,
    status: type,
    accounts: undefined,
    error: undefined,
  })),
  on(actionAccountGetAllAccountsSuccess, (state, { accounts, type }) => ({
    ...state,
    loading: false,
    accounts,
    status: type,
    error: undefined
  })),
  on(actionAccountGet, (state, { id, type }) => ({
    ...state,
    loading: true,
    account: undefined,
    status: type,
    error: undefined,
    id
  })),
  on(actionAccountGetSuccess, (state, { account, type }) => ({
    ...state,
    loading: false,
    account,
    status: type,
    error: undefined
  })),
  on(actionAccountGroupGet, (state, { id, type }) => ({
    ...state,
    loading: true,
    group: undefined,
    status: type,
    error: undefined,
    id
  })),
  on(actionAccountGroupGetSuccess, (state, { group, type }) => ({
    ...state,
    loading: false,
    group,
    status: type,
    error: undefined
  })),
  on(actionAccountError, (state, { error, type }) => ({
    ...state,
    status: type,
    loading: false,
    error
  }))
);

export function advertiserReducer(
  state: AdvertiserState | undefined,
  action: Action
) {
  return reducer(state, action);
}
