import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { Segmentation, RfmResponse, LTVModel, LTV, ModelMetaData, ModelMetaDataRun, SavedRfmResponse, LTVFlat } from '../../shared/interfaces/segmentation.interface';
import { BackendLongrunningTaskHandle } from '../../shared/interfaces/custumer.interface';

@Injectable()
export class SegmentationService {
  public apiClient = <ApiClient>{};
  constructor() { }

  runRfm(id: string, intId: string, payload: Segmentation): Observable<RfmResponse|BackendLongrunningTaskHandle> {
    return this.apiClient.callHttpPost({path: `/${id}/${intId ? intId : '00000000000000000000000000000000'}/segmentation/model/rfm`,
    param: payload, type: 'edgewarehouse'});
  }

  saveRfm(id: string, intId: string, payload: Segmentation): Observable<void> {
    return this.apiClient.callHttpPost({path: `/${id}/${intId}/segmentation/model/rfm/save`,
    param: payload, type: 'edgewarehouse'});
  }

  runLtv(id: string, intId: string, payload: LTVModel): Observable<LTVFlat|BackendLongrunningTaskHandle> {
    return this.apiClient.callHttpPost({path: `/${id}/${intId ? intId : '00000000000000000000000000000000'}/segmentation/model/ltv`,
    param: payload, type: 'edgewarehouse'});
  }

  saveLtv(id: string, intId: string, payload: LTVModel): Observable<void> {
    return this.apiClient.callHttpPost({path: `/${id}/${intId}/segmentation/model/ltv/save`,
    param: payload, type: 'edgewarehouse'});
  }

  linkCustomer(id: string, intId: string): Observable<void> {
    return this.apiClient.callHttpPostWithoutResponse({
      path: `/customers/${id}/${intId}/link`,
      param: {},
      type: 'edgewarehouse'
    });
  }

  getLTVMeta(id: string, intId: string): Observable<ModelMetaData> {
    return this.apiClient.callHttpGet({path: `/segmentation/${id}/${intId ? intId : '00000000000000000000000000000000'}/model/ltv/meta`,
    type: 'edgewarehouse'});
  }

  getRFMMeta(id: string, intId: string): Observable<ModelMetaData> {
    return this.apiClient.callHttpGet({path: `/segmentation/${id}/${intId ? intId : '00000000000000000000000000000000'}/model/rfm/meta`,
    type: 'edgewarehouse'});
  }

  getRFMData(id: string): Observable<ModelMetaDataRun[]> {
    return this.apiClient.callHttpGet({path: `/segmentation/${id}/model/rfm/results/metadata`,
    type: 'edgewarehouse'});
  }

  getRFMDataByDate(id: string, modelName: string, runDate: string): Observable<SavedRfmResponse[]> {
    return this.apiClient.callHttpGet({path: `/segmentation/${id}/model/rfm/${modelName}/results/${runDate}`,
    type: 'edgewarehouse'});
  }

  getLTVData(id: string): Observable<ModelMetaDataRun[]> {
    return this.apiClient.callHttpGet({path: `/segmentation/${id}/model/ltv/results/metadata`,
    type: 'edgewarehouse'});
  }

  getLTVDataByDate(id: string, modelName: string, runDate: string): Observable<LTV[]> {
    return this.apiClient.callHttpGet({path: `/segmentation/${id}/model/ltv/${modelName}/results/${runDate}`,
    type: 'edgewarehouse'});
  }
}
