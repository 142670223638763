import { AppState } from '../../state';
import * as CreativeDetailsActions from './creative-details.actions';
import { CreativeDetails } from './creative-details.model';

export type DetailsAction = CreativeDetailsActions.AllDetails;

/**
 * Define all store queries for CreativeDetails(s)
 */
export namespace CreativeDetailsQuery {
  export const creativeDetails = (state: AppState) => state.creativeDetails;
}

/// Reducer function
export function creativeDetailsReducer(state: CreativeDetails, action: DetailsAction) {

  switch (action.type) {
    case CreativeDetailsActions.RESET_DETAILS_STATE:
      return {};
    case CreativeDetailsActions.GET_DETAILS_CREATIVE:
      return { ...state, creativeIsLoading: true, status: action.type};
    case CreativeDetailsActions.GET_DETAILS_CREATIVE_SUCCESS:
      return { ...state, creative: action.payload, creativeIsLoading: false, status: action.type };
    case CreativeDetailsActions.GET_DETAILS_CREATIVE_FAIL:
      return { ...state, creativeError: action.payload, creativeIsLoading: false, status: action.type };
    case CreativeDetailsActions.SAVE_DETAILS_CREATIVE:
      return { ...state, creativeIsSaving: true, status: action.type};
    case CreativeDetailsActions.SAVE_DETAILS_CREATIVE_SUCCESS:
      return { ...state, creativeIsSaving: false, status: action.type };
    case CreativeDetailsActions.SAVE_DETAILS_CREATIVE_FAIL:
      return { ...state, creativeError: action.payload, creativeIsSaving: false, status: action.type };
    case CreativeDetailsActions.GET_DETAILS_ADVERTISERS:
      return { ...state, advertiserIsLoading: true, status: action.type};
    case CreativeDetailsActions.GET_DETAILS_ADVERTISERS_SUCCESS:
      return { ...state, advertisers: action.payload, advertiserIsLoading: false, status: action.type };
    case CreativeDetailsActions.GET_DETAILS_ADVERTISERS_FAIL:
      return { ...state, advertiserError: action.payload, advertiserIsLoading: false, status: action.type };
    case CreativeDetailsActions.GET_DETAILS_EVENTS:
      return { ...state, eventIsLoading: true, status: action.type};
    case CreativeDetailsActions.GET_DETAILS_EVENTS_SUCCESS:
      return { ...state, events: action.payload, eventIsLoading: false, status: action.type };
    case CreativeDetailsActions.GET_DETAILS_EVENTS_FAIL:
      return { ...state, eventError: action.payload, eventIsLoading: false, status: action.type };
    case CreativeDetailsActions.GET_DETAILS_VARIATIONS:
      return { ...state, variationIsLoading: true, status: action.type};
    case CreativeDetailsActions.GET_DETAILS_VARIATIONS_SUCCESS:
      if (action.primary) {
        return { ...state, variation: action.payload, variationIsLoading: false, status: action.type };
      } else {
        state.loadedVariations.push(action.payload);
        return { ...state, variationIsLoading: false, status: action.type };
      }
    case CreativeDetailsActions.GET_DETAILS_VARIATIONS_FAIL:
      return { ...state, variationError: action.payload, variationIsLoading: false, status: action.type };
    case CreativeDetailsActions.GET_DETAILS_CREATIVE_EVENTS:
      return { ...state, creativeEventIsLoading: true, status: action.type};
    case CreativeDetailsActions.GET_DETAILS_CREATIVE_EVENTS_SUCCESS:
      return { ...state, creativeEvents: action.payload, creativeEventIsLoading: false, status: action.type };
    case CreativeDetailsActions.GET_DETAILS_CREATIVE_EVENTS_FAIL:
      return { ...state, creativeEventError: action.payload, creativeEventIsLoading: false, status: action.type };
    default:
      return state;
  }
}
