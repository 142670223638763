import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

@Injectable()
export class LoaderService {

  private display = new Subject<boolean>();

  itemsHandler = this.display.asObservable();
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      localStorage.removeItem('transitiv-loader');
    }
  }

  setLoaderStatus(display: boolean) {
    this.display.next(display);
  }

  clearStatus() {
    let loader: boolean[] = [];
    if (this.isBrowser) {
      localStorage.setItem('transitiv-loader', JSON.stringify(loader));
    }
  }
}
