export enum PermissionNamespaceMap {
  Attribution = 'com.transitiv.attribution',
  Dashboard = 'com.transitiv.dashboard',
  SpringbotDashboard = 'com.transitiv.dashboard.agency',
  DashboardEngine = 'com.transitiv.dashboard.engine',
  FITHome = 'com.transitiv.dashboard.fit',
  FranchiseeEmbeddedDashboard = 'com.transitiv.dashboard.franchisee',
  FranchisorEmbeddedDashboard = 'com.transitiv.dashboard.franchisor',
  DataSources = 'com.transitiv.datasources',
  Integrations = 'com.transitiv.integrations',
  LocationManagement = 'com.transitiv.locations',
  MarketingSpend = 'com.transitiv.marketingspend',
  MobilePushNotifications = 'com.transitiv.messaging.pushnotifications',
  QueryBuilder = 'com.transitiv.querybuilder',
  Traffic = 'com.transitiv.traffic',
  TrafficCampaigns = 'com.transitiv.traffic.campaign',
  Tracking = 'com.transitiv.traffic.conversion',
  Creatives = 'com.transitiv.traffic.creative',
}
