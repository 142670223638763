import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { environment } from '../../../environments/environment';
import {
  createAuthHeaders,
  createUrlEncodedFormHeaders,
} from '../helpers/createHeaders';
import { AmbiguousData } from '../../shared/interfaces/common.interface';
import { LoginPayload, ForgotPasswordPayload } from '../../shared/interfaces';
import {
  AuthToken,
  ExternalLogin,
} from '../../shared/interfaces/auth.interface';

@Injectable()
export class LoginService {
  appServer: string;
  public apiClient = <ApiClient>{};
  redirect_url = window.location.origin;
  private _authenticated = false;
  isBrowser: boolean;

  constructor(
    private platformLocation: PlatformLocation,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.appServer = this.isBrowser
      ? (platformLocation as any).location.origin
      : '';
  }

  public authenticated(): Observable<boolean> {
    return observableOf(this._authenticated);
  }

  json2Query(obj: AmbiguousData) {
    const keys = Object.keys(obj);
    let q = '';
    keys.forEach((key, i) => {
      q += (i === 0 ? '' : '&') + key + '=' + obj[key];
    });
    return q;
  }

  doLogin(payload: LoginPayload): Observable<AuthToken> {
    this.apiClient.setBaseUrl(environment.authBase);
    let param = '';
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        param = `${param}${key}=${payload[key]}&`;
      }
    }
    return this.apiClient.callHttpPost({
      path: `/connect/token`,
      param: param,
      version: '',
      headers: createUrlEncodedFormHeaders(),
    });
  }

  getAuthLink(): Observable<ExternalLogin[]> {
    this.apiClient.setBaseUrl(environment.authBase);
    const api_url = `/Account/ExternalLogins?returnUrl=${this.appServer}&generateState=true`;
    return this.apiClient.callHttpGet({
      path: api_url,
      version: '',
      headers: createAuthHeaders(),
    });
  }

  doForgotPassword(payload: ForgotPasswordPayload): Observable<void> {
    this.apiClient.setBaseUrl(environment.authBase);
    return this.apiClient.callHttpPostWithoutResponse({
      path: `/Account/ForgotPassword`,
      param: payload,
      version: '',
      headers: createAuthHeaders(),
    });
  }
}
