import { Component, OnInit } from '@angular/core';
import { FieldClass } from '../field/field.class';
import { DropdownOption } from '../../../../shared/interfaces';
import { TableColumnConfig } from '../../../../shared/interfaces/field.interface';
@Component({
  selector: 'app-form-table',
  templateUrl: './table-input.component.html',
  styleUrls: ['./table-input.component.scss'],
})
export class TableInputComponent extends FieldClass implements OnInit {
  tableData: any[] = [];

  constructor() {
    super();
  }
  ngOnInit() {
    this.fetchData();
    this.buildTableData();
  }

  buildTableData() {
    if (this.tableValues?.value?.length) {
      this.tableData = this.tableValues.value;
    } else if (this.field && this.field.tableConfig) {
      let rowData: any = {};
      this.field.tableConfig.forEach((column) => {
        rowData[column.name] = '';
      });
      this.tableData = [rowData];
    }
  }

  tableKeys(rowData: {}) {
    return Object.keys(rowData);
  }

  displayTableHelper(key: string, attribute?: string) {
    if (this.field && this.field?.tableConfig) {
      let tableConfigIndex = this.field.tableConfig.findIndex(
        (column) => column.name === key
      );
      if (tableConfigIndex > -1) {
        let column = this.field.tableConfig[tableConfigIndex];
        if (attribute) {
          return (column as any)[attribute as string];
        } else {
          return column;
        }
      }
    }
  }

  handleRemoveTableRow(rowIndex: number) {
    this.tableData.splice(rowIndex, 1);
    let isValueEmpty = this.checkDataTableValues();
    if (!isValueEmpty) {
      this.tableValues?.setValue(this.tableData);
    }
  }

  addTableRow() {
    if (this.field && this.field.tableConfig) {
      let rowData: any = {};
      this.field.tableConfig.forEach((column) => {
        rowData[column.name] = '';
      });
      this.tableData = [...this.tableData, rowData];
      this.tableValues?.setValue(null);
    }
  }

  checkDataTableValues(): boolean {
    let isValueEmpty = false;
    this.tableData.map((row) => {
      for (let key in row) {
        if (row[key] === '') {
          isValueEmpty = true;
          return;
        }
      }
    });
    return isValueEmpty;
  }

  fetchData() {
    if (this.field && this.field.tableConfig) {
      this.field.tableConfig.forEach((column, index) => {
        if (column.fetchData) {
          if (this.field && this.field.tableConfig) {
            this.field.tableConfig[index].dataSource = [];
            column.fetchData.subscribe((data) => {
              data.forEach((element) => {
                if (this.field && this.field.tableConfig) {
                  this.field.tableConfig[index].dataSource.push({
                    label: element.CampaignName,
                    value: element.id,
                  });
                }
              });
              if (this.field && this.field.tableConfig) {
                this.field.tableConfig[index].dataSource = [
                  ...this.field.tableConfig[index].dataSource,
                ];
              }
            });
          }
        }
      });
    }
  }

  isReadonly() {
    return this.field?.readonly ? this.field?.readonly() : false;
  }

  placeholderAdjuster(column: TableColumnConfig) {
    if (column) {
      return column.label + (column?.isRequired ? '*' : '');
    } else {
      return '';
    }
  }

  changed(value: string, key: string, index: number) {
    let tableData = this.tableData;
    let rowData;
    if (tableData[index]) {
      rowData = tableData[index];
      rowData[key] = value;
    } else {
      rowData = {
        [key]: value,
      };
    }
    this.tableData[index] = rowData;
    let isValueEmpty = this.checkDataTableValues();
    this.tableValues?.setValue(isValueEmpty ? null : this.tableData);
    this.tableValues?.updateValueAndValidity();
  }

  get tableValues() {
    return this.group?.get(this.field?.name as string);
  }
}
