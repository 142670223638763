import { AppState } from '../../state';
import * as CreativeAnalysisActions from './creative-analysis.actions';
import { CreativeAnalysis } from './creative-analysis.model';

export type AnalysisAction = CreativeAnalysisActions.AllAnalysis;

/**
 * Define all store queries for CreativeAnalysis(s)
 */
export namespace CreativeAnalysisQuery {
  export const creativeAnalysis = (state: AppState) => state.creativeAnalysis;
}

/// Reducer function
export function creativeAnalysisReducer(state: CreativeAnalysis, action: AnalysisAction) {

  switch (action.type) {
    case CreativeAnalysisActions.RESET_ANALYSIS_STATE:
      return {};
    case CreativeAnalysisActions.SET_ANALYSIS_VISUAL:
      return { ...state, Visual: action.payload, status: action.type};
    case CreativeAnalysisActions.SET_ANALYSIS_HEATMAP:
      return { ...state, heatmap: action.payload, status: action.type };
    case CreativeAnalysisActions.SET_ANALYSIS_LABEL:
      return { ...state, label: action.payload, status: action.type };
    case CreativeAnalysisActions.SET_ANALYSIS_LEGEND:
      return { ...state, legend: action.payload, status: action.type };
    case CreativeAnalysisActions.SET_ANALYSIS_DIAGRAM:
      return { ...state, diagram: action.payload, status: action.type };
    case CreativeAnalysisActions.SET_ANALYSIS_TOGGLE:
      return { ...state, toggle: action.payload, status: action.type };
    default:
      return state;
  }
}
