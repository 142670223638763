import {
  Component,
  AfterViewInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AccountGroup,
  AdvertiserGroupNode,
  Account
} from '@shared/interfaces';
import { cloneDeep, filter, find, orderBy } from 'lodash-es';
import { Observable, SubscriptionLike as ISubscription } from 'rxjs';
import { TreeViewComponent, NodeData } from '@syncfusion/ej2-angular-navigations';
import { select, Store } from '@ngrx/store';
import { DynamicDatabase } from './services/dynamic-database.service';
import { DynamicFlatNode } from './models/dynamic-flatnode.model';
import { AccountFacade, AccountState } from '@shared/state/global';
import { UtilityService } from '@services/utility';
import { selectAccountGroups } from '@shared/state/global/account/account.selectors';
import { AppState } from '@shared/state/state';

export interface BreadcrumbDefinition {
  advertiser: boolean;
  data: string[];
}

@Component({
  selector: 'app-tree-display',
  templateUrl: 'tree-display.directive.html',
  styleUrls: ['tree-display.directive.scss'],
})
export class TreeDisplayContentComponent
  implements OnInit, AfterViewInit, OnDestroy {
  allData: DynamicFlatNode[] = [];
  selectedNode: AdvertiserGroupNode|Account|undefined;
  @Output() onSelect = new EventEmitter<Account|AccountGroup>();
  @Output() inactiveEnabled = new EventEmitter<boolean>();
  @Output() breadcrumb = new EventEmitter<BreadcrumbDefinition>();
  private _showInactive = false;
  store: DynamicDatabase;
  _searchFilter = '';
  private accountSubscription: ISubscription|undefined;
  state = <AccountState>{};
  hierarchyPath: string[] = [];
  dataSubscription: ISubscription|undefined;
  fields: Object = {};
  data: DynamicFlatNode[] = [];
  @ViewChild('tree')
  public tree: TreeViewComponent|undefined;
  parent = '';
  loaded: { [key: string]: boolean } = {};
  loading: { [key: string]: boolean } = {};
  persistent = false;
  lastInactiveStatus = false;
  dataCount = -1;
  oldSearchFilter = '';
  breadcrumbPath: string[] = [];
  groups$: Observable<AccountGroup[]>;
  groupSubscription: ISubscription|undefined;

  constructor(
    database: DynamicDatabase,
    private accountFacade: AccountFacade,
    private utilities: UtilityService,
    private stateStore: Store<AppState>
  ) {
    this.store = database;
    this.groups$ = this.stateStore.pipe(select(selectAccountGroups));
    this.groupSubscription = this.groups$.subscribe((groups) => {
      if (groups) {
        this.fields = {
          dataSource: this.store.initialData(this.showInactive),
          id: 'AccountId',
          parentID: 'parent',
          text: 'AdvertiserName',
          hasChildren: 'expandable',
        };
      }
    });
  }

  ngOnInit() {


  }

  ngAfterViewInit() {
    this.accountSubscription = this.accountFacade.account$.subscribe(
      (state) => {
        this.state = state;
        if (
          this.state &&
          this.state.activeSelection &&
          this.state.activeSelection.Type === 'Advertiser'
        ) {
          const parent = find(this.state.advertiserGroups, {
            GroupId: this.state.activeAdvertiser.AccountGroupId,
          });
          if (parent) {
            if (!parent.IsActive || !this.state.activeAdvertiser.IsActive) {
              this.showInactive = true;
              this.inactiveEnabled.emit(true);
            }
            this.hierarchyPath = this.utilities.string2array(
              parent.ParentHierarchyPath,
              '/'
            );
            this.hierarchyPath.push(parent.GroupId);
            this.selectedNode = this.state.activeAdvertiser;
            this.breadcrumbPath = cloneDeep(this.hierarchyPath);
            this.breadcrumbPath.push(this.selectedNode.AccountId);
            this.breadcrumb.emit({advertiser: true, data: this.breadcrumbPath});
            this.openNode();
            this.switchSubscribeAccount();
          }
        } else if (
          this.state &&
          this.state.activeSelection &&
          this.state.activeSelection.Type === 'Advertiser Group'
        ) {
          const group = find(this.state.advertiserGroups, {
            GroupId: this.state.activeAdvertiserGroup.GroupId,
          });
          if (group) {
            if (!group.IsActive) {
              this.showInactive = true;
              this.inactiveEnabled.emit(true);
            }
            this.hierarchyPath = this.utilities.string2array(
              group.ParentHierarchyPath,
              '/'
            );
            this.hierarchyPath.push(group.GroupId);
            this.selectedNode = {
              ...this.state.activeAdvertiserGroup,
              AdvertiserName: this.state.activeAdvertiserGroup.Name,
              AccountId: this.state.activeAdvertiserGroup.GroupId,
              isGroup: true,
              isLoading: false,
              expandable: false,
            };
            this.breadcrumbPath = cloneDeep(this.hierarchyPath);
            this.breadcrumb.emit({advertiser: false, data: this.breadcrumbPath});
            this.openNode();
            this.switchSubscribeAccount();
          }
        }
      }
    );
    this.dataSubscription = this.store.dataChange.subscribe((data) => {
      if (this.searchFilter) {
        this.updateTree(data);
      }
    });
  }

  async toggleNode(node: any) {
    if (!this.persistent) {
      this.persistent = true;
    }
    const e: NodeData = node.nodeData as NodeData;
    e.expanded = !e.expanded;
    const item = find(this.state.advertiserGroups, { GroupId: e.id });
    if (item && !this.loaded[e.id]) {
      this.parent = e.id;
      this.loaded[e.id] = true;
      this.loading[e.id] = true;
      if (this.tree) {
        this.tree.addNodes(
          [
            {
              AdvertiserName: 'Loading...',
              AccountId: 'loading' + e.id,
              isGroup: false,
              isLoading: false,
              expandable: false,
            },
          ],
          e.id
        );
        await this.expandNode(item, e.id);
        this.loading[e.id] = false;
      }
    }
  }

  async expandNode(node: AccountGroup, id: string) {
    const response = await this.store.getSubGroups(node);
    if (!response) {
      return;
    }
    let childData: (AdvertiserGroupNode | Account)[] = [];
    let groups: AdvertiserGroupNode[] = [];
    if (response.groups) {
      groups = response.groups as AdvertiserGroupNode[];
      if (!response.showInactive) {
        groups = filter(response.groups, {
          IsActive: true,
        }) as AdvertiserGroupNode[];
      }
      groups = groups.map((item) => {
        return {
          ...item,
          AdvertiserName: item.Name,
          AccountId: item.GroupId,
          isGroup: item.GroupId ? true : false,
          isLoading: false,
          expandable: true,
        };
      }) as AdvertiserGroupNode[];
    }
    if (response.advertisers) {
      let advertisers = response.advertisers;
      if (!this.showInactive) {
        advertisers = filter(response.advertisers, { IsActive: true });
      }
      childData = [
        ...orderBy(groups, [(group) => group?.Name?.toLowerCase() || ''], ['asc']),
        ...orderBy(
          advertisers,
          [(advertiser) => advertiser?.Name?.toLowerCase() || ''],
          ['asc']
        ),
      ];
    } else {
      childData = [
        ...orderBy(groups, [(group) => group?.Name?.toLowerCase() || ''], ['asc']),
      ];
    }
    if (this.tree) {
      const n = this.tree.getNode(id);
      if (n.id) {
        this.tree.addNodes(childData as unknown as {
          [key: string]: Object;
        }[], id);
      }
      this.tree.removeNodes(['loading' + id]);
    }
  }

  private async openNode() {
    if (this.hierarchyPath.length > 0) {
      const node = find(this.state.advertiserGroups, {
        GroupId: this.hierarchyPath[0],
      }) as AdvertiserGroupNode;
      if (node && !this.loaded[node.GroupId]) {
        this.loaded[node.GroupId] = true;
        if (!this.loading[node.GroupId]) {
          this.loading[node.GroupId] = true;
          await this.expandNode(node, node.GroupId);
          this.loading[node.GroupId] = false;
          this.hierarchyPath.splice(0, 1);
          if (this.hierarchyPath.length > 0) {
            await this.openNode();
          }
        }
      }
    }
  }

  private switchSubscribeAccount() {
    this.unsubscribeAccount();
    this.accountSubscription = this.accountFacade.account$.subscribe(
    (state) => {
      this.state = state;
    });
  }

  private unsubscribeAccount() {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.groupSubscription) {
      this.groupSubscription.unsubscribe();
    }
    this.unsubscribeAccount();
  }

  get showInactive() {
    return this._showInactive;
  }

  @Input() set showInactive(value: boolean) {
    this._showInactive = value;
    if (this.state) {
      if (
        (this.showInactive && !this.state.showInactive) ||
        (!this.showInactive && this.state.showInactive)
      ) {
        this.accountFacade.setShowInactive(this.showInactive);
      }
    }
    this.refreshData();
  }

  private getFilteredData() {
    if (this.tree && ((this.lastInactiveStatus !== this.showInactive)
    || (this.oldSearchFilter !== '' && this.searchFilter === ''))) {
      this.lastInactiveStatus = this.showInactive;
      this.oldSearchFilter = this.searchFilter;
      this.updateTree(this.store.initialData(this.showInactive));
    }
  }

  private updateTree(data: (Account|AccountGroup)[]) {
    this.loaded = {};
    if (this.tree) {
      this.dataCount = data.length || 0;
      this.tree.fields.dataSource = data as any[];
    }
  }

  get searchFilter() {
    return this._searchFilter;
  }

  @Input() set searchFilter(value: string) {
    this._searchFilter = value;
    this.refreshData();
  }

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (n: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;

  private refreshData() {
    if (this.searchFilter && this.searchFilter !== this.oldSearchFilter) {
      this.oldSearchFilter = this.searchFilter;
      this.store.search(this.searchFilter);
    } else {
      this.getFilteredData();
    }
  }

  selectNode(node: AdvertiserGroupNode|Account) {
    if (node.Name !== 'Loading...') {
      this.selectedNode = node;
      this.onSelect.emit(node);
    }
  }

  isSelected(node: AdvertiserGroupNode|Account) {
    return (
      this.isSelectedGroup(node as AdvertiserGroupNode) ||
      this.isSelectedAdvertiser(node as Account)
    );
  }

  isSelectedGroup(node: AdvertiserGroupNode) {
    return (
      this.selectedNode &&
      (this.selectedNode as AccountGroup).GroupId &&
      (this.selectedNode as AccountGroup).GroupId ===
        (node as AccountGroup).GroupId
    );
  }

  isSelectedAdvertiser(node: Account) {
    if (this.selectedNode && (this.selectedNode as Account).AccountId) {
      return (this.selectedNode as Account).AccountId === node.AccountId;
    }
    return false;
  }
}
