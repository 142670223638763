import { NgModule } from '@angular/core';

import {
  EntityDataService,
  EntityDataModule} from '@ngrx/data';
import { MarkovDataService } from '../analytics/markov.data.service';
import { CampaignDataService } from '../campaign/campaign.data.service';
import { DashboardDataService } from '../dashboard/dashboard.data.service';
import { FitDataService } from '../fit/fit.data.service';
import { ChannelGroupingsDataService } from '../integrations/data/channel-groupings.data.service';
import { AccountIntegrationsDataService } from '../integrations/account/integrations.data.service';
import { LocationsDataService } from '../locations/locations.data.service';
import { MarketingSpendDataService } from '../marketing-spend/marketing-spend.data.service';
import { EntityMap, entityMetadata } from './entity-metadata';
import { AccountIntegrationTypeDataService } from '../integrations/account/integration-type.data.service';
import { GroupIntegrationsDataService } from '../integrations/group/integrations.data.service';
import { GroupIntegrationTypeDataService } from '../integrations/group/integration-type.data.service';
import { ModuleConfigDataService } from '../module-config/module-config.data.service';
import { AccountDataService } from '../advertisers/accounts.data.service';
import { AccountGroupDataService } from '../advertisers/account-group.data.service';
import { UserClaimsDataService } from '../claims/claims.data.service';
import { UserDataService } from '../user/user.data.service';
import { WhoisDataService } from '../user/whois.data.service';
import { DataSourceDataService } from '../data-sources/data-sources.data.service';


@NgModule({
  imports: [
    EntityDataModule.forRoot({
      entityMetadata: entityMetadata
    })
  ],
  providers: [
    AccountDataService,
    AccountGroupDataService,
    AccountIntegrationsDataService,
    AccountIntegrationTypeDataService,
    CampaignDataService,
    ChannelGroupingsDataService,
    FitDataService,
    DashboardDataService,
    DataSourceDataService,
    GroupIntegrationsDataService,
    GroupIntegrationTypeDataService,
    LocationsDataService,
    MarketingSpendDataService,
    MarkovDataService,
    ModuleConfigDataService,
    UserDataService,
    UserClaimsDataService,
    WhoisDataService,
  ]
})
export class EntityStoreModule {
  constructor(
    entityDataService: EntityDataService,
    accountDataService: AccountDataService,
    accountGroupDataService: AccountGroupDataService,
    accountIntegrationsDataService: AccountIntegrationsDataService,
    accountIntegrationTypeDataService: AccountIntegrationTypeDataService,
    campaignDataService: CampaignDataService,
    channelGroupingsDataService: ChannelGroupingsDataService,
    claimsDataService: UserClaimsDataService,
    dashboardDataService: DashboardDataService,
    dataSourceDataService: DataSourceDataService,
    fitDataService: FitDataService,
    groupIntegrationsDataService: GroupIntegrationsDataService,
    groupIntegrationTypeDataService: GroupIntegrationTypeDataService,
    locationsDataService: LocationsDataService,
    marketingSpendDataService: MarketingSpendDataService,
    markovDataService: MarkovDataService,
    moduleConfigDataService: ModuleConfigDataService,
    userDataService: UserDataService,
    whoisDataService: WhoisDataService,
  ) {
    entityDataService.registerService(EntityMap.Account, accountDataService);
    entityDataService.registerService(EntityMap.AccountGroup, accountGroupDataService);
    entityDataService.registerService(EntityMap.AccountIntegration, accountIntegrationsDataService);
    entityDataService.registerService(EntityMap.AccountIntegrationType, accountIntegrationTypeDataService);
    entityDataService.registerService(EntityMap.Campaign, campaignDataService);
    entityDataService.registerService(EntityMap.ChannelGrouping, channelGroupingsDataService);
    entityDataService.registerService(EntityMap.UserClaim, claimsDataService);
    entityDataService.registerService(EntityMap.Dashboard, dashboardDataService);
    entityDataService.registerService(EntityMap.DataSource, dataSourceDataService);
    entityDataService.registerService(EntityMap.Fit, fitDataService);
    entityDataService.registerService(EntityMap.GroupIntegration, groupIntegrationsDataService);
    entityDataService.registerService(EntityMap.GroupIntegrationType, groupIntegrationTypeDataService);
    entityDataService.registerService(EntityMap.Locations, locationsDataService);
    entityDataService.registerService(EntityMap.MarketingSpend, marketingSpendDataService);
    entityDataService.registerService(EntityMap.Markov, markovDataService);
    entityDataService.registerService(EntityMap.ModuleConfig, moduleConfigDataService);
    entityDataService.registerService(EntityMap.User, userDataService);
    entityDataService.registerService(EntityMap.UserWhois, whoisDataService);
  }
}
