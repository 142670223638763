<div id="login" class="login-body">
  <div class="login-panel ui-fluid">
    <div class="loginoverflow" #loader>
      <app-spinner></app-spinner>
    </div>
    <div class="login-panel-header">
      <img [src]="logo" *ngIf="pageLoaded" id="transitiv-logo" />
    </div>
    <div class="login-panel-content">
      <h2 class="text-center mar-t-30 mar-b-10" id="page-title">
        Reset Password
      </h2>
      <div class="row flush centered">
        <div class="ui-g">
          <div class="ui-g-12 nopadding-top reset_pword">
            <app-dynamic-form
              [fields]="accountFormConfig"
              (submit)="setPassword($event)"
            ></app-dynamic-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
