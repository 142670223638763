import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import {
  combineLatest,
  Observable,
  SubscriptionLike as ISubscription,
} from 'rxjs';
import { find, isEqual } from 'lodash-es';
import { select, Store } from '@ngrx/store';
import { AdvertiserService } from '@services/advertiser/advertiser.service';
import { AuthService } from '@services/auth/auth.service';
import { TitleService } from '@services/title/title.service';
import { ClaimsService } from '@services/claims/claims.service';
import { Router, NavigationEnd } from '@angular/router';
import { UserWhoIsInfo } from '@shared/interfaces/claim.interface';
import {
  AccountFacade,
  AccountState,
  ActiveSelectionTypes,
  LOAD_MANAGEMENT_STATE,
  SET_MANAGEMENT_SELECTED_ADVERTISER,
} from '@shared/state/global/index';
import { AuthFacade, AuthLogout } from '@shared/state/auth';
import { TabService } from '@services/tab/tab.service';
import { Tab } from '@shared/interfaces/tab.interface';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { PermissionsFacade, Permissions } from '@shared/state/permissions';
import { pagePermissions, dashboards } from '@shared/models/permissions.model';
import { TaskFacade, TaskState } from '@shared/state/tasks';
import { SignarlRService } from '@services/signal-r/signarl-r.service';
import {
  AuthCache,
  UserInfo,
  ModulePermissionPath,
} from '@shared/interfaces/common.interface';
import { AppState } from '@shared/state/state';
import { selectAuthStateAuthenticated } from '@shared/state/auth/auth.selector';
import {
  selectPermissionsStateAccess,
  selectPermissionsStateDashboard,
  selectPermissionsStateModule,
} from '@shared/state/permissions/permissions.selectors';
import { LaunchDarklyService } from '@services/launch-darkly/launch-darkly.service';
import { selectSideMenuStatus } from '@shared/state/side-menu-status/side-menu-status.selectors';
import { IntegrationEffects } from '@shared/state/integrations/new/integrations.effects';
declare var Beamer: any;

interface RouteData {
  path: string;
  data: {
    module: string;
  };
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  pageTitle = '';
  tabMenuItems: Tab[] = [];
  authCache: AuthCache;
  userInfo = <UserInfo>{};
  showVerificationNotice = false;
  nav = {
    adServe: {
      isVisible: false,
    },
  };
  groupId = '';
  accountId = '';
  url = '';
  state = <AccountState>{};
  taskState = <TaskState>{};
  showTab = false;
  pagePermissions = pagePermissions;
  pageContext = '';
  activeLink: Tab | undefined;
  permState = <Partial<Permissions>>{};
  path: string[] = [];
  dashRouteData = <RouteData>{};
  allDashboards = { modules: <string[]>[], data: <ModulePermissionPath[]>[] };
  isDashboard = false;
  authenticated: boolean | undefined;
  sideMenuIsOpen = false;
  sideMenuStatus$: Observable<boolean>;
  sideMenuSubscription: ISubscription | undefined;
  headerSubscriptions: ISubscription[] = [];
  waitForAccountToLoad = false;

  constructor(
    private authService: AuthService,
    private advertiserService: AdvertiserService,
    private titleService: TitleService,
    private tabService: TabService,
    private claimsService: ClaimsService,
    private apiClient: ApiService,
    private signalRService: SignarlRService,
    private errorHandler: ErrorHandlerService,
    private accountFacade: AccountFacade,
    private taskFacade: TaskFacade,
    private authFacade: AuthFacade,
    private store: Store<AppState>,
    private permissionsFacade: PermissionsFacade,
    private router: Router,
    private launchDarklyService: LaunchDarklyService,
    private integrationEffects: IntegrationEffects
  ) {
    this.authCache = authService.cache;
    this.apiClient.errorHandler = this.errorHandler;
    this.authService.apiClient = this.apiClient;
    this.advertiserService.apiClient = this.apiClient;
    this.claimsService.apiClient = this.apiClient;
    this.signalRService.apiClient = this.apiClient;
    this.headerSubscriptions.push(
      this.accountFacade.account$.subscribe((state) => {
        if (state) {
          const accountIsCurrentlySelected =
            state.status === LOAD_MANAGEMENT_STATE &&
            state.activeSelection?.Type === ActiveSelectionTypes.Account;

          if (
            !accountIsCurrentlySelected &&
            (state.advertiserLoading || state.status === LOAD_MANAGEMENT_STATE)
          ) {
            this.waitForAccountToLoad = true;
            return;
          }
          if (this.waitForAccountToLoad) {
            if (state.status === SET_MANAGEMENT_SELECTED_ADVERTISER) {
              this.waitForAccountToLoad = false;
              this.updateState(state);
            }
          } else {
            this.updateState(state);
          }
        }
      })
    );
    this.headerSubscriptions.push(
      this.store
        .select(selectAuthStateAuthenticated)
        .subscribe((authenticated) => {
          this.authenticated = authenticated;
          if (!authenticated) {
            this.accountFacade.resetState();
          }
        })
    );
    this.headerSubscriptions.push(
      this.titleService.contextHandler.subscribe((response) => {
        this.pageContext = response;
      })
    );
    this.headerSubscriptions.push(
      this.tabService.itemsHandler.subscribe((response) => {
        this.tabMenuItems = response;
        this.showTab = this.tabMenuItems.length > 0;
        const active = find(this.tabMenuItems, { active: true });
        if (!active && this.tabMenuItems.length > 0) {
          this.activeLink = this.tabMenuItems[0];
        } else if (active) {
          this.activeLink = active;
        }
      })
    );
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
    this.sideMenuStatus$ = this.store.pipe(select(selectSideMenuStatus));
    this.headerSubscriptions.push(
      this.sideMenuStatus$.subscribe((sideMenuStatus) => {
        this.sideMenuIsOpen = sideMenuStatus;
      })
    );
  }

  ngOnInit() {
    this.advertiserService.loadSavedData();
    if (this.authService.isAuthenticated()) {
      let loggedInUser: UserInfo | string = this.authService.getUser();
      loggedInUser = loggedInUser ? JSON.parse(loggedInUser) : {};
      if ((loggedInUser as UserInfo).Email) {
        this.userInfo = loggedInUser as UserInfo;
        this.showVerificationNotice =
          this.url === '/dashboard' && !this.userInfo['HasConfirmed']
            ? true
            : false;
        this.authCache.isConfirmed = this.showVerificationNotice;
        this.getActiveUserClaim();
      } else {
        this.checkAuth();
      }
    }
    this.headerSubscriptions.push(
      combineLatest([
        this.store.select(selectPermissionsStateDashboard),
        this.store.select(selectPermissionsStateModule),
        this.store.select(selectPermissionsStateAccess),
      ]).subscribe(([dashboard, module, access]) => {
        this.permState = {
          dashboard: dashboard,
          module: module,
          access: access,
        };
        if (this.moduleHasNotBeenChecked()) {
          const mod = find(this.pagePermissions, {
            module: this.permState.module,
          });
          if (mod) {
            this.setAccess(mod);
          } else if (
            this.permState.dashboard &&
            this.permState.module !== 'Auth'
          ) {
            const dash = find(this.allDashboards.data, {
              namespace: this.permState.dashboard.ModuleNamespace,
            });
            if (dash) {
              this.setAccessDenied(
                this.permState.module as string,
                dash.path + this.accountId
              );
            }
          }
        } else if (this.moduleHasBeenChecked()) {
          if (this.redirectPending()) {
            this.setRedirected(this.permState.access as Permissions['access']);
            this.router.navigate(['/' + this.permState.access?.redirectPage]);
          }
        }
      })
    );
  }

  ngAfterViewInit() {
    this.integrateChangelog();
  }

  updateState(state: AccountState) {
    if (state) {
      if (!isEqual(state, this.state)) {
        this.state = state;
        this.init();
      }
    }
  }

  private integrateChangelog() {
    if (!(window as any).TEST_MODE && Beamer) {
      Beamer.init();
    }
  }

  private redirectPending() {
    return (
      this.permState &&
      this.permState.access &&
      this.permState.access.redirect &&
      !this.permState.access.redirected &&
      this.permState.access.redirectPage
    );
  }

  private setAccess(mod: { namespace?: string; module: string }) {
    const namespace = find(this.permState.permissions, {
      ModuleNamespace: mod.namespace,
    });
    if (namespace) {
      this.setAccessGranted(mod.module);
    } else if (
      this.permState &&
      this.permState.module === 'Dashboard' &&
      this.permState.dashboard &&
      this.permState.dashboard.ModuleNamespace
    ) {
      const dash = find(dashboards, {
        namespace: this.permState.dashboard.ModuleNamespace,
      });
      if (dash) {
        this.setAccessDenied(mod.module, dash.path + this.accountId);
      }
    }
  }

  private setAccessGranted(mod: string) {
    this.permissionsFacade.setAccess({
      module: mod,
      granted: true,
      redirected: false,
      redirectPage: '',
      redirect: false,
    });
  }

  private setAccessDenied(mod: string, redirectPage: string) {
    this.permissionsFacade.setAccess({
      module: mod,
      granted: false,
      redirected: false,
      redirectPage: redirectPage,
      redirect: true,
    });
  }

  private setRedirected(access: Permissions['access']) {
    access.redirected = true;
    this.permissionsFacade.setAccess(access);
  }

  private moduleHasNotBeenChecked() {
    return (
      this.permState &&
      this.permState.permissions &&
      this.permState.module &&
      (!this.permState.access ||
        this.permState.access.module !== this.permState.module)
    );
  }

  private moduleHasBeenChecked() {
    return (
      this.permState &&
      this.permState.module &&
      this.permState.access &&
      this.permState.access.module === this.permState.module
    );
  }

  ngOnDestroy() {
    this.headerSubscriptions.forEach((subscription) => {
      if (subscription && subscription.unsubscribe) subscription.unsubscribe();
    });
  }

  /**
   * @ngdoc function
   * @name getActiveUserClaim
   * @methodOf app.home.controller:HeaderCtrl
   * @description Get authenticated user's username & email.
   *
   */
  getActiveUserClaim() {
    if (this.authCache.activeUser.UserName === this.userInfo['Email']) {
      return;
    }
    const userInfo = <UserWhoIsInfo>{};
    userInfo.UserName = this.userInfo['Email'];
    this.claimsService.getUserWhois(userInfo).subscribe((data) => {
      this.authCache.activeUser = data[0];
    });
  }

  checkAuth() {
    this.advertiserService.wipeActiveAdvertisers();
    if (this.authService.getToken()) {
      this.authService.getUserInfo().subscribe(
        (data) => {
          this.userInfo = data;
          this.showVerificationNotice =
            this.url === '/dashboard' && !data.HasConfirmed ? true : false;
          this.authCache.isConfirmed = this.showVerificationNotice;

          this.getActiveUserClaim();
        },
        () => {
          this.authService.wipeUser();
        }
      );
    } else {
      this.logout();
    }
  }

  logout() {
    this.signalRService.disconnectSignalR();
    if (!(window as any).TEST_MODE && Beamer) {
      Beamer.destroy();
    }
    this.launchDarklyService.disconnect();
    this.authService.wipeUser();
    this.userInfo = <UserInfo>{};
    this.authFacade.setAuthenticated();
    this.authService.doLogout();
    this.store.dispatch(new AuthLogout());
    this.router.navigate(['logoutsuccess']);
  }

  resendConfirmation() {
    this.authService.doResendConfirmation();
  }

  closeNotice() {
    this.showVerificationNotice = false;
    this.authCache.isConfirmed = this.showVerificationNotice;
  }

  checkSavedData() {
    if (
      this.state &&
      this.state.activeAdvertiserGroup &&
      this.state.activeAdvertiser &&
      this.state.activeSelection
    ) {
      const { activeSelection } = this.state;
      if (activeSelection.Type === ActiveSelectionTypes.AccountGroup) {
        if (this.groupId !== this.state.activeAdvertiserGroup.GroupId) {
          this.initGroupLevelProcesses();
        }
      } else if (activeSelection.Type === ActiveSelectionTypes.Account) {
        if (this.accountId !== this.state.activeAdvertiser.AccountId) {
          this.initAccountLevelProcesses();
        }
      }
    }
  }

  initGroupLevelProcesses() {
    if (this.state && this.state.activeAdvertiserGroup) {
      this.groupId = this.state.activeAdvertiserGroup?.GroupId;
      if (this.groupId) {
        this.getGroupLevelIntegrations();
      }
    }
  }

  initAccountLevelProcesses() {
    if (this.state && this.state.activeAdvertiser) {
      this.accountId = this.state.activeAdvertiser?.AccountId;
      if (this.accountId) {
        this.getAccountLevelIntegrations();
        this.initLaunchDarkly();
        this.updateBeamer();
      }
    }
  }

  getAccountLevelIntegrations() {
    this.integrationEffects.getIntegrations(this.accountId);
  }

  getGroupLevelIntegrations() {
    this.integrationEffects.getGroupIntegrations(this.groupId);
  }

  initLaunchDarkly() {
    let { localUserId } = this.launchDarklyService;
    if (!localUserId) {
      this.launchDarklyService.initializeClient(this.accountId);
    } else if (localUserId !== this.accountId) {
      this.launchDarklyService.updateUserclient(this.accountId);
    }
  }

  updateBeamer() {
    if (Beamer) {
      Beamer.update(
        JSON.stringify({
          user_id: this.accountId
            ? this.accountId
            : this.groupId
            ? this.groupId
            : '',
        })
      );
    }
  }

  setNotificationConnection() {
    if (this.state && this.state.activeSelection) {
      const { isConnected, localAdvertiserId } = this.signalRService;
      if (isConnected) {
        if (this.state.activeSelection.Type === 'Advertiser') {
          if (
            this.state.activeAdvertiser &&
            this.state.activeAdvertiser.AccountId
          ) {
            const newAdvertiserId = this.state.activeAdvertiser.AccountId;
            if (localAdvertiserId) {
              if (newAdvertiserId !== localAdvertiserId) {
                return this.taskFacade.signalRSwitchAdvertiser(newAdvertiserId);
              }
              return;
            } else {
              return this.taskFacade.signalRAddToGroup(newAdvertiserId);
            }
          }
        } else if (
          this.state.activeSelection.Type === 'Advertiser Group' &&
          this.accountId
        ) {
          if (this.signalRService.isConnected) {
            this.taskFacade.signalRRemoveFromGroup(this.accountId);
          }
        }
      } else {
        this.taskFacade.signalRStartConnectionProcess(
          this.state.activeSelection.Type === 'Advertiser',
          this.accountId
        );
      }
    }
  }

  init() {
    this.checkSavedData();
    this.setNotificationConnection();
  }

  onTabClick(link: Tab) {
    this.activeLink = link;
    if (link.command) {
      link.command();
    }
  }

  goToManagePage() {
    if (this.state && this.state.activeSelection.Type === 'Advertiser') {
      this.router.navigate([
        '/account/manage',
        this.state.activeAdvertiser.AccountId,
      ]);
    } else if (
      this.state &&
      this.state.activeSelection.Type === 'Advertiser Group'
    ) {
      this.router.navigate([
        '/account/manage/group',
        this.state.activeAdvertiserGroup.GroupId,
      ]);
    }
  }

  goToRoadmapPage() {
    if (this.state?.activeSelection?.Type === 'Advertiser') {
      this.router.navigate(['/roadmap', this.state.activeAdvertiser.AccountId]);
    } else if (this.state?.activeSelection?.Type === 'Advertiser Group') {
      this.router.navigate([
        '/roadmap/group',
        this.state.activeAdvertiserGroup.GroupId,
      ]);
    }
  }

  hasPushPermissions() {
    if (this.permState && this.permState.permissions) {
      const index = this.permState.permissions.findIndex(
        (obj) =>
          obj.ModuleNamespace === 'com.transitiv.messaging.pushnotifications'
      );
      return index > -1;
    }
    return false;
  }
}
