import { createReducer, on, Action } from '@ngrx/store';
import * as moment from 'moment';
import { GlobalFilterState } from '.';
import {
  DateComparisonType,
  DateRollup,
  LocationSummaryResponse,
} from '../../interfaces';
import { TableSorting } from '../../models/global-filtering.model';
import * as globalFilterActions from './global-filter.actions';

export const initialGlobalFilterState: GlobalFilterState = {
  selectedSortingOption: TableSorting.Ascending,
  selectedLocationFilters: {
    selectedFitRange: [350, 850],
    isFilteringByFit: false,
    selectedTags: [],
    selectedRegions: [],
    selectedAgeRange: [],
    isFilteringByAge: false,
  },
  locationSummary: {
    data: <LocationSummaryResponse['data']>{},
    accountIdUsed: '',
    hasLoaded: false,
  },
  dateRange: {
    fromDate: moment().startOf('D').subtract(30, 'days').toDate(),
    toDate: moment().startOf('D').subtract(1, 'days').toDate(),
  },
  searchInput: '',
  comparePrevious: true,
  selectedDateComparisonOption: DateComparisonType.Period,
  dateRollUp: DateRollup.Automatic,
};

const reducer = createReducer(
  initialGlobalFilterState,
  on(
    globalFilterActions.actionChangeInSortingOption,
    (state, { selectedSortingOption }) => ({
      ...state,
      selectedSortingOption,
    })
  ),
  on(
    globalFilterActions.actionChangeInLocationFilters,
    (
      state,
      { selectedLocationFilters, comparePrevious, selectedDateComparisonOption }
    ) => ({
      ...state,
      selectedLocationFilters,
      comparePrevious:
        comparePrevious !== undefined ? comparePrevious : state.comparePrevious,
      selectedDateComparisonOption:
        selectedDateComparisonOption !== undefined
          ? selectedDateComparisonOption
          : state.selectedDateComparisonOption,
    })
  ),
  on(
    globalFilterActions.actionChangeInLocationSummaryData,
    (state, { locationSummary, accountId }) => ({
      ...state,
      locationSummary: {
        data: locationSummary,
        accountIdUsed: accountId,
        hasLoaded: true,
      },
    })
  ),
  on(
    globalFilterActions.actionChangeInDateFiltering,
    (
      state,
      {
        fromDate,
        toDate,
        dateRollUp,
        comparePrevious,
        selectedDateComparisonOption,
        selectedLocationFilters,
      }
    ) => ({
      ...state,
      dateRange: {
        fromDate,
        toDate,
      },
      dateRollUp,
      comparePrevious,
      selectedDateComparisonOption,
      selectedLocationFilters:
        selectedLocationFilters !== undefined
          ? selectedLocationFilters
          : state.selectedLocationFilters,
    })
  ),
  on(
    globalFilterActions.actionChangeInDateRange,
    (state, { fromDate, toDate }) => ({
      ...state,
      dateRange: {
        fromDate,
        toDate,
      },
    })
  ),
  on(
    globalFilterActions.actionChangeInLocationSearch,
    (state, { searchInput }) => ({
      ...state,
      searchInput,
    })
  ),
  on(globalFilterActions.actionResetGlobalFilterState, () => ({
    ...initialGlobalFilterState,
  }))
);

export function globalFilterReducer(
  state: GlobalFilterState | undefined,
  action: Action
): GlobalFilterState {
  return reducer(state, action);
}
