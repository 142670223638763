import { LeaderboardFilter, SummaryOptionFranchiseAge } from '.';
import {
  DashboardAggregationType,
  DashboardChartType,
  DashboardColumnUnit,
  UnitType,
} from '../../components/integrations/interfaces';
import { ResponseStructure } from './api-client.interface';
import {
  ColumnChartContainerData,
  DataTableComponentData,
  LineChartContainerData,
  NumberCardData,
  PercentChangeType,
  PieChartContainerData,
} from './d3-charts.interface';
import { RawSchema } from './query.interface';

export interface DashboardPageResponseSeries {
  series_title: string;
  series_unit_type: DashboardColumnUnit;
  series_agg_type: DashboardAggregationType;
  series_base: {
    loc_ct: number;
    x_axis: string[];
    y_axis: number[];
  };
  series_comparator?: {
    loc_ct: number;
    x_axis: string[];
    y_axis: number[];
  };
}

export interface DashboardPageResponseCharts {
  chart_title: string;
  chart_order: number;
  chart_type: DashboardChartType;
  chart_series: DashboardPageResponseSeries[];
  chart_desc?: string;
}

export interface DashboardPageResponse extends ResponseStructure {
  data: {
    section_title: string;
    section_order: number;
    section_charts: DashboardPageResponseCharts[];
  }[];
}

export interface LocationSummaryAges {
  age: {
    min_years: number;
    max_years: number;
  };
  loc_ct: number;
}

export interface LocationSummaryRegions {
  region: string;
  loc_ct: number;
}

export interface LocationSummaryResponse extends ResponseStructure {
  data: {
    ages: LocationSummaryAges[];
    regions: LocationSummaryRegions[];
    tags: string[];
    oldest_age?: number;
  };
}

export interface LocationCountByActiveFiltersResponse extends ResponseStructure {
  data: {
    LocationCount: number
  };
}

export interface DashboardConfigDataSource extends ResponseStructure {
  data: RawSchema[];
}

export interface DashboardPagePayload {
  range_start: string;
  range_end: string;
  date_rollup: DateRollup;
  comp_prev_period: boolean;
  acct_comp: {
    comp_type: AccountComparison;
    comp_region?: string;
    comp_fran_age?: {
      min_years: number;
      max_years: number;
    };
    comp_tags?: string[];
  };
}

export interface FilterComparisonToTags {
  Type: PageComparison.Tags;
  Tags: string[];
}

export interface FilterComparisonToRegionsOrTerritories {
  Type: PageComparison.ToRegionOrTerritory;
  Regions: string[];
}

export interface DashboardLeaderboardPayload {
  range_start: string;
  range_end: string;
}

export interface FilterChipObj {
  name: string;
  type: string;
  optionName?: string;
}

export interface AccountComparisonChangeEvent {
  accountComparisonType: AccountComparison;
  comparison:
    | SummaryOptionFranchiseAge['value']
    | string
    | undefined
    | string[];
}

export interface LeaderboardSummariesData {
  Value: number;
  PercentChange: number;
  Name: string;
}

export interface DashboardLeaderboardResponseDataObj {
  Rank: number;
  AccountName: string;
  AccountId: string;
  AggregatedValue: number;
  ChangeInRank: number;
  RankPercentile: number;
}

export interface DashboardLeaderboardV2ResponseData {
  Rank: number;
  AccountName: string;
  AccountId: string;
  AggregatedValue: number;
  ChangeInRank: number;
  ChangeInPercent: number;
}

export interface DashboardLeaderboardResponse {
  Title: string;
  Order: number;
  Description: string;
  Unit: DashboardColumnUnit;
  RankingType: number;
  Data: DashboardLeaderboardResponseDataObj[];
}

export interface DashboardLeaderboardV2Response {
  Title: string;
  Order: number;
  Description: string;
  RankingType: PercentChangeType;
  Unit: UnitType;
  Data: DashboardLeaderboardV2ResponseData[];
  Summaries: LeaderboardSummariesData[];
  Errors: string[] | null;
}

export interface DashboardLeaderboardAPIResponse extends ResponseStructure {
  data: DashboardLeaderboardResponse[];
}

export interface DashobardLeaderboardV2APIResponse extends ResponseStructure {
  data: DashboardLeaderboardV2Response[];
}

export interface DashboardLeaderboardTableData {
  rank: number;
  account: string;
  amount: number | string;
  isSelf: boolean;
  changeInRank: number;
  rankPercentile: number;
}

export interface DashboardLeaderboardV2TableData {
  rank: number;
  formattedRank?: string;
  account: string;
  accountId: string;
  value: number | string;
  isSelf: boolean;
  noData?: boolean;
  changeInRank?: number;
  rankArrowDirection?: string;
  percentChangeInValue?: number;
  percentChangeDisplayValue?: number | string;
  percentChangeDirection?: string;
}

export interface DashboardLeaderboardCarouselData {
  name: string;
  value: number | string;
  percentChange: number;
}

export interface DashboardLeaderboardData {
  title: string;
  unit: DashboardColumnUnit;
  rankingType: RankingType;
  description?: string;
  tableData: DashboardLeaderboardTableData[];
}

export interface DashobardLeaderobardV2Data {
  title: string;
  unit: UnitType;
  rankingType: PercentChangeType;
  description?: string;
  errors: string[] | null;
  tableData: DashboardLeaderboardV2TableData[];
  carouselData: DashboardLeaderboardCarouselData[];
}

export interface DashboardDataTableObj {
  name: string;
  amount: number;
}

export interface PageComparisonToFranchiseAge {
  Type: PageComparison.ToFranchiseAge;
  MinAge: number;
  MaxAge: number;
}

export interface PageComparisonToRegionOrTerritory {
  Type: PageComparison.ToRegionOrTerritory;
  Region: string;
}

export interface PageComparisonToAllLocations {
  Type: PageComparison.ToAllLocations;
}

export interface PageComparisonToPeriod {
  Type: PageComparison.ToPeriod;
  From: string;
  To: string;
}

export interface PageComparisonToTags {
  Type: PageComparison.Tags;
  Tags: string[];
}

export interface PageComparisonNone {
  Type: PageComparison.None;
}

export type DashboardV2PageComparison =
  | PageComparisonNone
  | PageComparisonToPeriod
  | PageComparisonToAllLocations
  | PageComparisonToRegionOrTerritory
  | PageComparisonToFranchiseAge
  | PageComparisonToTags;

export interface DashboardV2PagePayload {
  Start: string;
  End: string;
  Rollup: DateRollup;
  Comparisons: LeaderboardFilter[];
}

export type DashboardV2PageResponseChart =
  | LineChartContainerData
  | NumberCardData
  | DataTableComponentData
  | ColumnChartContainerData
  | PieChartContainerData;

export interface DashboardV2PageResponse extends ResponseStructure {
  data: {
    Title: string;
    Order: number;
    Charts: DashboardV2PageResponseChart[];
  }[];
}

export interface DashboardV2WithDateRollUpPageResponse
  extends ResponseStructure {
  data: {
    Rollup: DateRollup;
    DashboardEngineResponseSections: {
      Title: string;
      Order: number;
      Charts: DashboardV2PageResponseChart[];
    }[];
  };
}

export type LocationCountByActiveFiltersPayload = {
  Filters: LeaderboardFilter[]
}

export enum PageComparison {
  None = 0,
  ToPeriod = 1,
  ToAllLocations = 2,
  ToRegionOrTerritory = 3,
  ToFranchiseAge = 4,
  Tags = 5,
}

export enum DateRollup {
  Automatic = 0,
  Daily = 1,
  Monthly = 2,
  Quarterly = 3,
  Yearly = 4,
}

export enum AccountComparison {
  None = 0,
  AllLocations = 1,
  RegionOrTerritory = 2,
  FranchiseAge = 3,
  Tag = 4,
}

export enum RankingType {
  RedYellowGreen = 0,
  GreenYellowRed = 1,
}

export enum DateComparisonType {
  Week = 'lastWeek',
  Month = 'lastMonth',
  Quarter = 'lastQuarter',
  Year = 'lastYear',
  Period = 'previousPeriod',
  None = '',
}
