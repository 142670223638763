import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormGroup,
  UntypedFormControl,
} from '@angular/forms';
import { Time } from '@shared/interfaces/time';
import { UtilityService } from '@services/utility';
import { OnChangeFunction, OnTouchFunction } from '../types';
import {
  Validator,
  FieldConfig,
} from '@shared/interfaces/field.interface';

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => DatePickerFormElementComponent),
  multi: true,
};
@Component({
  selector: 'app-form-date-picker',
  template: `
    <div *ngIf="group" [formGroup]="group">
      <mat-form-field>
        <input
          matInput
          [id]="itemId"
          [matDatepicker]="picker"
          [placeholder]="placeholder"
          [formControlName]="name"
          [readonly]="readonly"
          [(ngModel)]="value"
          (dateChange)="changed($event)"
          [max]="max"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [startAt]="minDate"></mat-datepicker>
        <app-form-time-picker
          class="time_picker_"
          *ngIf="showTime"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="time"
          [meridian]="true"
          (ngModelChange)="changeTime()"
        ></app-form-time-picker>
        <ng-container
          *ngFor="let validation of validations"
          ngProjectAs="mat-error"
        >
          <mat-error *ngIf="group.get(name)?.hasError(validation.name)">{{
            validation.message
          }}</mat-error>
        </ng-container>
      </mat-form-field>
    </div>
    <div *ngIf="!group">
      <mat-form-field>
        <input
          matInput
          [id]="itemId"
          [matDatepicker]="picker"
          [placeholder]="placeholder"
          [readonly]="readonly"
          [(ngModel)]="value"
          (dateChange)="changed($event)"
          [max]="max"
          [disabled]="disableInput"
          [matDatepickerFilter]="datePickerFilter"
          (click)="picker.open()"
          [min]="minDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [startAt]="minDate"></mat-datepicker>
        <app-form-time-picker
          class="time_picker_"
          *ngIf="showTime"
          [(ngModel)]="time"
          [meridian]="true"
          (ngModelChange)="changeTime()"
        ></app-form-time-picker>
      </mat-form-field>
    </div>
  `,
  styles: [],
  styleUrls: ['./date.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class DatePickerFormElementComponent implements ControlValueAccessor {
  @Input() appendTo = '';
  @Input() minDate: Date | undefined;
  @Input() max: Date | undefined;
  @Input() time: Time = { hour: 0, minute: 0 };
  @Input() placeholder = '';
  @Input() cssClass = '';
  @Input() name = '';
  @Input() group: UntypedFormGroup | undefined;
  @Input() validations: Validator[] = [];
  @Input() disabled = false;
  @Input() disableInput = false;
  @Input() showTime = false;
  @Input() readonly = false;
  @Input() inline = false;
  @Input() hourFormat: number | undefined;
  @Input() itemId = '';
  @Input() datePickerFilter: () => boolean = () => true;
  @Output() onSelect = new EventEmitter<Date>();
  timeSet = false;
  public bankFilterCtrl: UntypedFormControl = new UntypedFormControl();
  innerValue: Date | undefined;
  private onTouchedCallback: OnTouchFunction = noop;
  private onChangeCallback: OnChangeFunction<Date> = noop;

  constructor(private utilityService: UtilityService) {}

  get value(): Date | undefined {
    return this.innerValue;
  }

  @Input() set value(v: Date | undefined) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.setTime();
      this.onChangeCallback(v as Date);
    }
  }

  get defaultTime(): Time {
    return this.time;
  }

  @Input() set defaultTime(v: Time) {
    this.time = v;
  }

  changed(e: Date) {
    this.time = this.defaultTime ? this.defaultTime : { hour: 0, minute: 0 };
    this.setTime();
    if (!this.showTime) {
      this.onBlur();
      this.onSelect.emit(e);
    }
  }

  private setTime() {
    if (this.showTime) {
      let date: Date;
      if (this.group) {
        date = this.utilityService.getFormValue(
          <FieldConfig>{ name: this.name },
          this.group
        );
        date.setHours(this.time.hour);
        date.setMinutes(this.time.minute);
        const start = this.group.get(this.name);
        if (start) {
          start.setValue(date);
        }
      } else {
        date = this.value as Date;
        date.setHours(this.time.hour);
        date.setMinutes(this.time.minute);
        this.value = date;
      }

      this.onBlur();
      this.onSelect.emit(date);
    }
  }

  changeTime() {
    this.setTime();
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: Date) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: OnChangeFunction<Date>) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: OnTouchFunction) {
    this.onTouchedCallback = fn;
  }
}
