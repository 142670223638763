import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from '@services/account/account.service';
import { TitleService } from '@services/title/title.service';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { FieldConfig } from '@shared/interfaces/field.interface';
import { FormValidatorService } from '@services/form-validator/form-validator.service';
import { environment } from '@environments/environment';
import {
  ForgotPasswordPayload,
  ResetPasswordPayload,
  ResetPasswordTokenCheckPayload,
} from '@shared/interfaces';
import { LoginService } from '@services/login/login.service';
import { ConfirmationDialogContentComponent } from '../../directives/confirmation-dialog/confirmation-dialog.directive';

@Component({
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  error = '';
  errors = {
    Password: false,
    ConfirmPassword: false,
  };
  isLoading = false;
  accountFormConfig: FieldConfig[] = [
    {
      type: 'input',
      id: 'form-new-password',
      label: 'New Password',
      inputType: 'password',
      name: 'Password',
      isBlock: true,
      hasTooltip: false,
      hasElement: true,
      tooltip:
        'Passwords should have one uppercase letter, one number, one special symbol (!,@,?,etc)',
      validations: [
        {
          name: 'required',
          validator: Validators.required,
          message: 'New Password is Required',
        },
        {
          name: 'minlength',
          validator: Validators.minLength(8),
          message: 'Password must be at least 8 characters',
        },
        {
          name: 'hasSpecialCharacters',
          // tslint:disable-next-line: max-line-length
          validator: FormValidatorService.patternValidator(
            /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:/,
            { hasSpecialCharacters: true }
          ),
          message: 'Passwords must contain at least one Special Character',
        },
        {
          name: 'hasSmallCase',
          validator: FormValidatorService.patternValidator(/[a-z]/, {
            hasSmallCase: true,
          }),
          message: 'Password must contain at least one Letter in Small Case',
        },
        {
          name: 'hasNumber',
          validator: FormValidatorService.patternValidator(/\d/, {
            hasNumber: true,
          }),
          message: 'Password should contain at least one number',
        },
        {
          name: 'hasCapitalCase',
          validator: FormValidatorService.patternValidator(/[A-Z]/, {
            hasCapitalCase: true,
          }),
          message: 'Passwords must contain at least one in Capital Case',
        },
      ],
    },
    {
      type: 'input',
      id: 'form-confirm-password',
      label: 'Confirm Password',
      inputType: 'password',
      name: 'ConfirmPassword',
      isBlock: true,
      hasTooltip: false,
      hasElement: true,
      validations: [
        {
          name: 'required',
          validator: Validators.required,
          message: 'Confirm Password Required',
        },
        {
          name: 'passwordsNotEqual',
          validator: FormValidatorService.RepeatPasswordValidator,
          message: 'Passwords must match',
        },
      ],
    },
    {
      type: 'button',
      id: 'form-password-save',
      label: 'Reset Password',
      buttonLocation: 'normal',
    },
  ];
  email = '';
  code = '';
  isBrowser: boolean;
  @ViewChild('loader', { static: true }) private loaderElement:
    | ElementRef
    | undefined;
  pageLoaded = false;
  appServer = '';
  logo = environment.defaultLogo;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiClient: ApiService,
    private accountService: AccountService,
    private titleService: TitleService,
    private errorHandler: ErrorHandlerService,
    private loginSignupService: LoginService,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.titleService.setTitle('Change Password');
    this.apiClient.errorHandler = this.errorHandler;
    this.accountService.apiClient = this.apiClient;
    this.loginSignupService.apiClient = this.apiClient;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.code = decodeURIComponent(decodeURI(params['code'] || ''));
      this.email = params['email'];
      if (this.isBrowser) {
        this.checkCode();
      }
    });
  }

  checkCode() {
    const payload: ResetPasswordTokenCheckPayload = {
      Email: this.email,
      Code: this.code,
    };
    this.accountService
      .checkResetPasswordToken(payload)
      .subscribe((response) => {
        if (response) {
          if (!response.data.IsValid) {
            this.handleCodeIsInvalid();
          } else {
            this.pageLoaded = true;
            if (this.loaderElement) {
              this.loaderElement.nativeElement.remove();
            }
          }
        }
      });
  }

  handleCodeIsInvalid() {
    const forgotPasswordPayload: ForgotPasswordPayload = {
      Email: this.email,
    };
    this.dialog.open(ConfirmationDialogContentComponent, {
      data: {
        title: 'Password Could Not Be Reset',
        message: `You are using an an invalid Reset Password link.
          <br>
          Please ensure that you are using the most recent Reset Password link, and that the link is less than 24 hours old.
          <br>
          A new Reset Password email has been sent to you. Please use that new link.`,
        acceptButton: 'OK',
        omitCancelButton: true,
        textAlignCenter: true,
      },
    });
    this.loginSignupService
      .doForgotPassword(forgotPasswordPayload)
      .subscribe(() => {});
  }

  setPassword(payload: ResetPasswordPayload) {
    this.isLoading = true;
    this.error = '';
    payload.Email = this.email;
    payload.Code = this.code;
    this.accountService.resetPassword(payload).subscribe(() => {
      this.isLoading = false;
      this.router.navigate(['/login']);
    });
  }
}
