import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  public registerIcons(): void {
    const icons = [
      { iconName: 'leaderboard-icon', url: 'assets/img/leaderboard-icon.svg' },
      { iconName: 'fit-icon', url: 'assets/img/fit-home-icon.svg' },
    ];
    this.loadIcons(icons);
  }

  private loadIcons(
    icons: {
      iconName: string;
      url: string;
    }[]
  ): void {
    icons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon.iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url)
      );
    });
  }
}
