import { ResponseStructure } from './api-client.interface';

export interface AccountsAPIResponse extends ResponseStructure {
  data: Account[];
}

export interface AccountAPIResponse extends ResponseStructure {
  data: Account;
}

export interface AccountGroupAPIResponse extends ResponseStructure {
  data: AccountGroup;
}

export interface AccountGroupsAPIResponse extends ResponseStructure {
  data: AccountGroup[];
}

export interface Account {
  AccountGroupId: string;
  AudienceTagHost: string;
  AdServerHost: string;
  AccountId: string;
  Name: string;
  IsActive: boolean;
  CreatedOn?: string;
  CookieCollection?: string[];
  DefaultDestinationUrl?: string;
  Type: AccountType | undefined;
  Tags?: string[];
}

export interface AccountGroup {
  GroupId: string;
  Name: string;
  ParentHierarchyPath: string;
  DefaultAudienceTagHostName: string;
  DefaultAdServerHostName: string;
  IsActive: boolean;
  Type: GroupType;
}

export interface AdvertiserGroupNode extends AccountGroup {
  AccountId: string;
  AdvertiserName: string;
  isGroup: boolean;
  isLoading: boolean;
  expandable: boolean;
}

export enum AccountType {
  Other = 0,
  Franchisor = 1,
  Franchisee = 2,
  FranchiseDevelopment = 3,
  FranchiseRecruitment = 4,
}

export enum GroupType {
  Other = 0,
  Partner = 1,
}
