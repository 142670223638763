import { AppState } from '../state';
import * as PermissionsActions from './permissions.actions';
import { Permissions } from './permissions.model';
export type Action = PermissionsActions.All;

export namespace PermissionsQuery {
  export const permissions = (state: AppState) => state.permissions;
}

export function permissionsReducer(state: Permissions, action: Action) {
  switch (action.type) {
  case PermissionsActions.SET_PERMISSIONS:
    return { ...state, permissions: action.payload, status: action.type };
  case PermissionsActions.SET_DASHBOARD:
    return { ...state, dashboard: action.payload, status: action.type };
  case PermissionsActions.SET_ACCESS:
    return { ...state, access: action.payload, status: action.type };
  default:
    return state;
  }
}
