import { IntegrationType } from './integration-type.interface';
import { ChannelGrouping } from './payloads.interface';
import { DateRange } from './date.interface';
import { DropdownOption } from './dropdown-option.interface';
import { ResponseStructure } from './api-client.interface';

export interface IntegrationDefinition extends IntegrationType {
  Inputs: IntegrationType[];
  Outputs: IntegrationType[];
  ValidInputTypes: number[];
  ValidOutputTypes: number[];
  CompatibilityType?: IntegrationCompatibilityType;
}

export enum IntegrationCompatibilityType
{
    None = 0,
    Account = 1,
    AccountGroup = 2,
    Both = 3
}

export interface IntegrationsDefinitionApiResponse extends ResponseStructure {
  data: IntegrationDefinition[]
}

export interface IntegrationDefinitionApiResponse extends ResponseStructure {
  data: IntegrationDefinition
}

interface IntegrationPayload {
  Type: number;
  TypeDescriptorId: number|string;
  Name: string;
  ParameterValues: [{ParameterName: string, ParameterValue: string}];
  IntegrationId: string;
}

export interface BudgetOptimizationParam {
  ChannelName: string;
  Budget: number;
}

export interface AttributionScenario {
  Name: string;
  DateCreated: string|Date;
  DateModified: string|Date;
  DateRange: DateRange;
  ConversionFilter: string;
  FilterIntegrationId?: string | null;
  BudgetDeltaAggregate: number;
  BudgetOptimizationParams: BudgetOptimizationParam[];
}

export interface ProductCatalogIntegrationDefinition extends IntegrationType {
  PrimaryKeyColumns: string[];
  SnapshotMatchColumn: string[];
  ChannelGroupings: ChannelGrouping[];
  Scenarios: AttributionScenario[];
  ExcludeSpecificChannelNames: string[];
  QueryName: string;
  FilterIntegrationId?: string|null;
}

export interface CustomerIntegration extends IntegrationType {
  Name: string|null;
  IntegrationInputIds: never[];
  HealthStatus: number;
  QueryName: string|null;
  Type: number;
  DateCreated: string|Date;
  DateModified: string|Date;
  Filter: string;
  FilterDescription: string;
  FilterIntegrationId: string;
  FilterName: string;
  IntegrationId: string;
}

export interface RFMIntegration extends IntegrationPayload {
  FilterIntegrationId: string;
  FrequencyWeight: number;
  MonetaryWeight: number;
  QueryName: string;
  RecencyWeight: number;
}

export interface LTVIntegration extends IntegrationPayload {
  CohortStartDate: string|Date;
  CurrentMargin: number;
  FilterIntegrationId: string;
  ForecastPeriods: number;
  ProjectedMargin: number;
  QueryName: string;
  PenalizerCoefficient: number;
  ObservationEndDate: string|Date;
  ForecastDays: number;
  CalibrationEndDate: string|Date;
}

export interface UrlFilter {
  columns: DropdownOption[];
  FormatType: number;
  ColumnName: string|null;
  IsImageUrlColumn: boolean;
  IsInternallyDelimitedColumn: boolean;
  InternalDelimiter: string;
  IsDestinationUrl: boolean;
  isEdit: boolean;
  Selected?: number;
  delete: boolean;
  DateFormatString: string;
  NumberFormatString: string;
  RatingSymbol: number;
  RatingRangeMinimumValue: number;
  RatingRangeMaximumValue: number;
}

export interface CampaignToScreeshot {
  campaign: string;
  adOptions: DropdownOption[];
  ads: DropdownOption[];
}

export enum SourceEntityType {
  Account = 0,
  AccountGroup = 1
}
