import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationItem } from '../../components/directives/notification/notification.component';


@Injectable()
export class NotificationsService {
  notificationChange: Subject<NotificationItem> = new Subject<NotificationItem>();

  notify(notification: NotificationItem) {
    this.notificationChange.next(notification);
  }
}
