import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityMap } from '../entity/entity-metadata';
import { CommonUtilityService } from '@services/utility/common-utility.service';
import { UserWhoIsInfo } from '../../interfaces/claim.interface';
import { ClaimsService } from '@services/claims/claims.service';

@Injectable()
export class WhoisDataService extends DefaultDataService<UserWhoIsInfo> {
  email = '';

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private claimsService: ClaimsService,
  ) {
    super(EntityMap.UserWhois, http, httpUrlGenerator);
  }

  getAll(): Observable<UserWhoIsInfo[]> {
    return this.claimsService.getUserWhois({
      UserId: '',
      UserName: '',
      Email: this.email
    }).pipe(
      map((user) => user.map(whois => CommonUtilityService.timestampStateData(whois))));
  }
}
