import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { filter, groupBy } from 'lodash-es';
import { combineLatest, Observable, SubscriptionLike as ISubscription } from 'rxjs';
import { OverlayRef, PositionStrategy, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Store } from '@ngrx/store';
import { UtilityService } from '@services/utility/utility.service';
import { AccountState, AccountFacade } from '@shared/state/global';
import { Account, AccountGroup } from '@shared/interfaces/index';
import { NavPanelComponent } from './directives/nav-panel.component';
import { AuthFacade } from '@shared/state/auth';
import { CommonUtilityService } from '@services/utility/common-utility.service';
import { selectGlobalAccountActiveItem, selectGlobalAccountActiveGroup,
  selectGlobalAccountActiveAccount, selectAccountGroups } from '@shared/state/global/account/account.selectors';
import { AppState } from '@shared/state/state';
import { selectClaims } from '@shared/state/claims/claims.data.selectors';

@Component({
  selector: 'app-nav-tree',
  templateUrl: './nav-tree.component.html',
  styleUrls: ['./nav-tree.component.scss'],
})
export class NavTreeComponent implements OnInit, OnDestroy, AfterViewInit {
  selectedItem: AccountGroup|Account|undefined;
  category = '';
  selectedAdvertiser: Account|null = <Account>{};
  keyword = '';
  searchMode = false;
  routesRef: string[] = [];
  error = '';
  isLoading = false;
  gid = '';
  id = '';
  url = '';
  state = <AccountState>{};
  selectedGroup: AccountGroup|null = <AccountGroup>{};
  private accountSubscription: ISubscription|undefined;
  loadedGroup = '';
  overlayRef: OverlayRef|undefined;
  overlayPosition: PositionStrategy|undefined;
  navPanelComponentPortal: ComponentPortal<NavPanelComponent>|undefined;
  @ViewChild('navTarget') buttonRef: ElementRef|undefined;
  authenticated = false;
  observable: ISubscription|undefined;
  ref: ComponentRef<NavPanelComponent>|undefined;
  isSingleAccount = false;
  activeItem$: Observable<[AccountState['activeSelection'], AccountGroup|undefined, Account|undefined]>;
  private activeItemSubscription: ISubscription|undefined;
  private groupsSubscription: ISubscription|undefined;
  activeItem: Partial<AccountState>|undefined;
  advertiserGroups: AccountGroup[]|undefined;
  routeParams: Params|undefined;

  constructor(
    private utilityService: UtilityService,
    private utilities: CommonUtilityService,
    private accountFacade: AccountFacade,
    private authFacade: AuthFacade,
    public viewContainerRef: ViewContainerRef,
    private store: Store<AppState>,
    public overlay: Overlay,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.activeItem$ = combineLatest([
      this.store.select(selectGlobalAccountActiveItem),
      this.store.select(selectGlobalAccountActiveGroup),
      this.store.select(selectGlobalAccountActiveAccount),]
    );
  }

  ngOnInit() {
    this.accountFacade.initState({ advertiserId: this.id, groupId: this.gid });
    this.routesRef = this.router.config.map((ref) => {
      let path = ref.path ? ref.path.split(':')[0] : '';
      const lastChar = path.substring(path.length - 1);
      if (lastChar === '/') {
        path = path.slice(0, -1);
      }
      return path;
    });

    this.route.params.subscribe((params) => {
      this.routeParams = params;
    });

    this.activeItemSubscription = this.activeItem$.subscribe(([activeItem, group, advertiser]) => {
      if (activeItem  && (group || advertiser)) {
        this.activeItem = {activeSelection: activeItem, activeAdvertiser: advertiser, activeAdvertiserGroup: group};
        this.refreshGroupRooftop();
        this.handleRouting();
      }
    });
    this.accountSubscription = this.store.select(selectClaims).subscribe((state) => {
      if (state && state) {
        const claims = groupBy(state, 'EntityKey');
        const keys = Object.keys(claims);
        if (keys.length === 1) {
          const advertisers = filter(claims[keys[0]], {EntityIsAdvertiser: true});
          this.isSingleAccount = advertisers.length === claims[keys[0]].length;
        }
      }
    });
    this.groupsSubscription = this.store.select(selectAccountGroups).subscribe((state) => {
      this.advertiserGroups = state;
      this.refreshGroupRooftop();
    });
    this.observable = this.authFacade.authenticated$.subscribe((authenticated) => {
      this.authenticated = authenticated;
      if (!authenticated && this.overlayRef) {
        this.overlayRef.detach();
      }
    });
  }

  ngOnDestroy() {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
    if (this.observable) {
      this.observable.unsubscribe();
    }
    if (this.activeItemSubscription) {
      this.activeItemSubscription.unsubscribe();
    }
    if (this.groupsSubscription) {
      this.groupsSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.overlayRef = this.overlay.create(new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      disposeOnNavigation: true,
      positionStrategy: this.getOverlayPosition(),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    }));
    this.overlayRef.backdropClick().subscribe(() => {
      if (this.overlayRef) {
        this.overlayRef.detach();
      }
    });
    this.navPanelComponentPortal = new ComponentPortal(
      NavPanelComponent,
      this.viewContainerRef
    );
  }

  handleRouting() {
    const route = this.router.url.split('/');
    this.handleAccountRouting(route);
    this.handleDashboardRouting(route);
    this.handleCreativesRouting(route);
    this.handleConversionRouting(route);
    this.handleAttributionRouting(route);
    this.handleQuerybuilderRouting(route);
    this.handleCampaignRouting(route);
    this.handleDataSourcesRouting(route);
    this.handleIntegrationRouting(route);
    this.handleMarketingSpendRouting(route);
    this.handleAdtagRouting(route);
    this.handleLocationsRouting(route);
  }

  private handleLocationsRouting(route: string[]) {
    if (route[1] === 'locations') {
      if (this.advertiserContextChanged(route) || this.groupContextChanged(route)) {
        this.router.navigate(['/locations', this.activeItem?.activeAdvertiserGroup?.GroupId]);
      }
    }
  }

  private handleAccountRouting(route: string[]) {
    if (route[1] === 'account') {
      if (this.advertiserContextChanged(route)) {
        this.router.navigate(['/account/manage', this.activeItem?.activeAdvertiser?.AccountId]);
      } else if (this.groupContextChanged(route)) {
        this.router.navigate(['/account/manage/group', this.activeItem?.activeAdvertiserGroup?.GroupId]);
      }
    }
  }

  private handleAdtagRouting(route: string[]) {
    if (route[1] === 'adtag') {
      if (this.advertiserContextChanged(route)) {
        this.router.navigate(['/adtag', this.activeItem?.activeAdvertiser?.AccountId]);
      } else if (this.groupContextChanged(route)) {
        this.router.navigate(['/adtag/group', this.activeItem?.activeAdvertiserGroup?.GroupId]);
      }
    }
  }

  private handleCampaignRouting(route: string[]) {
    if (route[1] === 'campaign') {
      if (this.advertiserContextChanged(route)) {
        this.router.navigate(['/campaign', this.activeItem?.activeAdvertiser?.AccountId]);
      } else if (this.groupContextChanged(route)
        ) {
        this.router.navigate(['/campaign/group', this.activeItem?.activeAdvertiserGroup?.GroupId]);
      }
    }
  }

  private handleDataSourcesRouting(route: string[]) {
    if (route[1] === 'data-sources') {
      if (this.advertiserContextChanged(route)) {
        this.router.navigate(['/data-sources', this.activeItem?.activeAdvertiser?.AccountId]);
      } else if (this.groupContextChanged(route)
        ) {
        this.router.navigate(['/data-sources/group', this.activeItem?.activeAdvertiserGroup?.GroupId]);
      }
    }
  }

  private handleIntegrationRouting(route: string[]) {
    if (route[1] === 'integration') {
      if (this.advertiserContextChanged(route)) {
        this.router.navigate(['/integration', this.activeItem?.activeAdvertiser?.AccountId]);
      } else if (this.groupContextChanged(route)
        ) {
        this.router.navigate(['/integration/group', this.activeItem?.activeAdvertiserGroup?.GroupId]);
      }
    }
  }

  private handleMarketingSpendRouting(route: string[]) {
    if (route[1] === 'marketing-spend') {
      if (this.advertiserContextChanged(route)) {
        this.router.navigate(['/marketing-spend', this.activeItem?.activeAdvertiser?.AccountId]);
      } else if (this.groupContextChanged(route)) {
        this.router.navigate(['/marketing-spend/group', this.activeItem?.activeAdvertiserGroup?.GroupId]);
      }
    }
  }

  private handleAttributionRouting(route: string[]) {
    if (route[1] === 'attribution') {
      if (this.advertiserContextChanged(route)) {
        let accountId = this.activeItem?.activeAdvertiser?.AccountId;
        if (route[3] === 'channels') {
          this.router.navigate([`/attribution/${accountId}/channels`]);
        } else if (route[3] === 'scenarios') {
          this.router.navigate([`/attribution/${accountId}/scenarios`]);
        } else {
          this.router.navigate(['/attribution', accountId]);
        }
      } else if (this.groupContextChanged(route)) {
        this.router.navigate([
          '/attribution/group',
          this.activeItem?.activeAdvertiserGroup?.GroupId,
        ]);
      }
    }
  }

  private handleQuerybuilderRouting(route: string[]) {
    if (route[1] === 'querybuilder') {
      if (this.advertiserContextChanged(route)) {
        if ((route[3] === 'advanced' && this.activeItem?.activeAdvertiser?.AccountId === route[4]) ||
        this.activeItem?.activeAdvertiser?.AccountId === route[3]) {
          return;
        }
        this.router.navigate(['/querybuilder/list', this.activeItem?.activeAdvertiser?.AccountId]);
      } else if (this.groupContextChanged(route)
        ) {
        this.router.navigate(['/querybuilder/group', this.activeItem?.activeAdvertiserGroup?.GroupId]);
      }
    }
  }

  private handleCreativesRouting(route: string[]) {
    if (route[1] === 'creatives') {
      if (this.advertiserContextChanged(route)) {
        this.router.navigate(['/creatives', this.activeItem?.activeAdvertiser?.AccountId]);
      } else if (this.groupContextChanged(route)
        ) {
        this.router.navigate(['/creatives/group', this.activeItem?.activeAdvertiserGroup?.GroupId]);
      }
    }
  }

  private handleConversionRouting(route: string[]) {
    if (route[1] === 'conversion') {
      if (this.advertiserContextChanged(route)) {
        this.router.navigate(['/conversion', this.activeItem?.activeAdvertiser?.AccountId]);
      } else if (this.groupContextChanged(route)
        ) {
        this.router.navigate(['/conversion/group', this.activeItem?.activeAdvertiserGroup?.GroupId]);
      }
    }
  }

  private handleDashboardRouting(route: string[]) {
    if (route[1] === 'dashboard') {
      if (this.activeItem && this.activeItem.activeSelection?.Type === 'Advertiser' &&
      this.activeItem?.activeAdvertiser && route.indexOf('group') > 1) {
        if (route.indexOf('default') > -1) {
          this.router.navigate(['/dashboard/default', this.activeItem.activeAdvertiser.AccountId]);
        } else if (route.indexOf('agency') > -1) {
          this.router.navigate(['/dashboard/agency', this.activeItem.activeAdvertiser.AccountId]);
        } else if (route.indexOf('embedded') > -1) {
          this.router.navigate(['/dashboard/embedded', this.activeItem.activeAdvertiser.AccountId]);
        } else {
          this.router.navigate(['/dashboard', this.activeItem.activeAdvertiser.AccountId]);
        }

      } else if (this.activeItem && this.activeItem.activeSelection?.Type === 'Advertiser Group' &&
      this.activeItem?.activeAdvertiserGroup && route.indexOf('group') === -1) {
        if (route.indexOf('default') > -1) {
          this.router.navigate(['/dashboard/default/group', this.activeItem.activeAdvertiserGroup.GroupId]);
        } else if (route.indexOf('agency') > -1) {
          this.router.navigate(['/dashboard/agency/group', this.activeItem.activeAdvertiserGroup.GroupId]);
        } else if (route.indexOf('embedded') > -1) {
          this.router.navigate(['/dashboard/embedded/group', this.activeItem.activeAdvertiserGroup.GroupId]);
        } else {
          this.router.navigate(['/dashboard/group', this.activeItem.activeAdvertiserGroup.GroupId]);
        }
      }
    }
  }

  private groupContextChanged(route: string[]) {
    return this.activeItem && this.activeItem.activeSelection?.Type === 'Advertiser Group' &&
      this.activeItem?.activeAdvertiserGroup && (route.indexOf('group') === -1 ||
        (this.routeParams && this.routeParams['gid'] !== this.activeItem.activeAdvertiserGroup.GroupId &&
          this.routeParams['groupid'] !== this.activeItem.activeAdvertiserGroup.GroupId));
  }

  private advertiserContextChanged(route: string[]) {
    return this.activeItem && this.activeItem.activeSelection?.Type === 'Advertiser' &&
      this.activeItem.activeAdvertiser && (route.indexOf('group') > 1 ||
        (this.routeParams && this.routeParams['id'] !== this.activeItem.activeAdvertiser.AccountId));
  }

  getOverlayPosition(): PositionStrategy {
    if (this.buttonRef) {
      this.overlayPosition = this.overlay.position()
      .flexibleConnectedTo(this.buttonRef)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ]);
    }
    return this.overlayPosition as PositionStrategy;
  }

  onButtonClick() {
    if (this.overlayRef && !this.overlayRef.hasAttached() && !this.isSingleAccount) {
      this.ref = this.overlayRef.attach(this.navPanelComponentPortal);
      if (this.ref) {
        this.ref.instance.cleanup = {run: () => {
          if (this.overlayRef) {
            this.overlayRef.detach();
          }
        }};
      }
    } else if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  getAdvertiserName() {
    return this.selectedItem
      ? (this.selectedItem as Account).Name
      : null;
  }

  getGroupName() {
    return this.selectedItem
      ? (this.selectedItem as AccountGroup).Name
      : null;
  }

  refreshGroupRooftop() {
    if (this.activeItem && this.advertiserGroups) {
      const options = this.advertiserGroups;
      const allGroupsDef: {[key: string]: AccountGroup} = this.utilities.doDefinitionObject(
        options,
        'GroupId'
      );
      const parentGroup = this.activeItem.activeAdvertiserGroup;
      const parentPath =
        parentGroup && parentGroup.ParentHierarchyPath
          ? this.string2array(parentGroup.ParentHierarchyPath)
          : [];
      let rooftop: AccountGroup[] = [];

      // Get Ascendants Groups
      rooftop = parentPath.reduce((memo: AccountGroup[], parent) => {
        if (allGroupsDef[parent]) {
          memo.push(allGroupsDef[parent]);
        }
        return memo;
      }, []);
      // Get Current Group
      if (parentGroup) {
        rooftop.push(parentGroup);
      }
      if (
        this.utilities.isEmptyObject(this.selectedAdvertiser) &&
        this.activeItem.activeSelection?.Type === 'Advertiser'
      ) {
        this.selectedAdvertiser = this.activeItem.activeAdvertiser as Account;
      }
      if (this.utilities.isEmptyObject(this.selectedGroup)) {
        this.selectedGroup = this.activeItem.activeAdvertiserGroup as AccountGroup;
      }
      if (
        this.activeItem &&
        this.activeItem.activeSelection &&
        this.activeItem.activeSelection.Type === 'Advertiser Group'
      ) {
        this.category = 'Group';
        this.selectedItem = this.activeItem.activeAdvertiserGroup;
      }
      if (
        this.activeItem &&
        this.activeItem.activeSelection &&
        this.activeItem.activeSelection.Type === 'Advertiser' &&
        this.activeItem.activeAdvertiser?.AccountId
      ) {
        this.category = 'Account';
        this.selectedItem = this.activeItem.activeAdvertiser;
      }
      if (
        !this.activeItem ||
        !this.activeItem.activeSelection ||
        (this.activeItem.activeSelection.Type !== 'Advertiser' &&
          this.activeItem.activeSelection.Type !== 'Advertiser Group')
      ) {
        this.category = '';
        this.selectedItem = <AccountGroup>{};
      }
      return rooftop;
    }
    return null;
  }

  string2array(str: string) {
    return this.utilityService.string2array(str, '/');
  }

  url2Route(urlPath: string) {
    if (urlPath === '/' || urlPath === undefined) {
      urlPath = 'dashboard';
      return urlPath;
    }
    urlPath = urlPath.slice(1);
    if (urlPath.includes('/')) {
      urlPath = urlPath.split('/')[0];
    }
    return urlPath;
  }
}
