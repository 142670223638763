
import {switchMap, map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import {AppState} from '../state';
import {AuthQuery} from './auth.reducer';
import * as AuthActions from './auth.actions';
import { LoginService } from '@services/login/login.service';
import { AuthService } from '@services/auth/auth.service';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { LoginPayload } from '../../interfaces';
type Action = AuthActions.All;

@Injectable()
export class AuthFacade {
  auth$ = this.store.select(AuthQuery.auth);
  authenticated$ = this.store.select(AuthQuery.authenticated);
  authenticate$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.AUTH_AUTHENTICATE),
    map((action: AuthActions.AuthAuthenticate) => action.payload),
    switchMap(payload => this.loginService.doLogin(payload).pipe(map((auth) => {
      if (auth && auth.access_token) {
        this.authService.setToken(auth.access_token);
      }
      return new AuthActions.AuthAuthenticateSuccess(auth);
    }),
    catchError(err => of (new AuthActions.AuthAuthenticateFail(err)) ), )), ));

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private loginService: LoginService,
    private authService: AuthService
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.loginService.apiClient = this.apiClient;
  }

  authenticate(payload: LoginPayload): void {
    this.store.dispatch(new AuthActions.AuthAuthenticate(payload));
  }

  setAuthenticated(): void {
    this.store.dispatch(new AuthActions.SetAuthAuthenticated(this.authService.isAuthenticated()));
  }
}
