import { AppState } from '../state';
import * as TaskActions from './tasks.actions';
import { TaskState } from './tasks.model';
export type Action = TaskActions.All;

export namespace TaskQuery {
  export const tasks = (state: AppState) => state.tasks;
}

export function taskReducer(state: TaskState, action: Action): TaskState {
  switch (action.type) {
    case TaskActions.SIGNALR_GET_CONNECTION_INFO:
      return {
        ...state,
        advertiser: action.advertiserId,
        addAdvertiserAfterConnection: action.addAdvertiserAfterConnection,
        loading: true,
        status: action.type,
      };
    case TaskActions.SIGNALR_GET_CONNECTION_INFO_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        loading: false,
        status: action.type,
      };
    case TaskActions.SIGNALR_START_CONNECTION:
      return {
        ...state,
        signalRConnectionInfo: action.payload,
        loading: false,
        status: action.type,
      };
    case TaskActions.SIGNALR_START_CONNECTION_SUCCESS:
      return { ...state, status: action.type };
    case TaskActions.SIGNALR_START_CONNECTION_FAILED:
      return { ...state, error: action.errorMessage, status: action.type };
    case TaskActions.SIGNALR_ADD_TO_GROUP:
      return {
        ...state,
        status: action.type,
      };
    case TaskActions.SIGNALR_ADD_TO_GROUP_SUCCESS:
      return {
        ...state,
        status: action.type,
      };
    case TaskActions.SIGNALR_ADD_TO_GROUP_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        status: action.type,
      };
    case TaskActions.SIGNALR_REMOVE_FROM_GROUP:
      return {
        ...state,
        status: action.type,
      };
    case TaskActions.SIGNALR_REMOVE_FROM_GROUP_SUCCESS:
      return {
        ...state,
        status: action.type,
      };
    case TaskActions.SIGNALR_REMOVE_FROM_GROUP_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        status: action.type,
      };
    case TaskActions.SIGNALR_SWITCH_ADVERTISER:
      return {
        ...state,
        status: action.type,
      };
    case TaskActions.SIGNALR_SWITCH_ADVERTISER_FAILED:
      return {
        ...state,
        error: action.errorMessage,
        status: action.type,
      };
    case TaskActions.SINGALR_RECEIVED_NOTIFICATION:
      return {
        ...state,
        notifications: action.payload.notifications,
        uncompletedTasks: action.payload.uncompletedTasks,
        unnotified: action.payload.unnotified,
        failedTasks: action.payload.failedTasks,
        status: action.type,
      };
    case TaskActions.SIGNALR_SET_FAILED:
      return {
        ...state,
        failedTasks: action.payload,
        status: action.type,
      };
    case TaskActions.SIGNALR_SET_FAIL_NOTIFIED:
      return {
        ...state,
        failNotifiedTasks: [...(state.failNotifiedTasks || []), ...(action.payload || [])],
        status: action.type,
      };
    case TaskActions.SINGALR_CLEAR_NOTIFICATIONS:
      return {
        ...state,
        clearedNotification: action.clearedNotification,
        status: action.type,
      };
    case TaskActions.SINGALR_DISMISS_ALL:
      return {
        ...state,
        clearedNotification: [],
        notifications: [],
        status: action.type,
      };
    case TaskActions.TASK_SET_NOTIFIED:
      return { ...state, unnotified: action.payload, status: action.type };
    case TaskActions.TASK_GETALL:
      return {
        ...state,
        advertiser: action.payload,
        loading: true,
        status: action.type,
      };
    case TaskActions.TASK_SETATTRIBUTION_COMPLETE:
      return {
        ...state,
        attributionComplete: action.payload,
        status: action.type,
      };
    case TaskActions.TASK_SETAUDIENCE_COMPLETE:
      return {
        ...state,
        audienceComplete: action.payload,
        status: action.type,
      };
    case TaskActions.TASK_CLEARAUDIENCE_COMPLETE:
      return {
        ...state,
        audienceComplete: undefined,
        status: action.type,
      };
    case TaskActions.TASK_SETRFM_COMPLETE:
      return {
        ...state,
        rfmComplete: action.payload,
        status: action.type,
      };
    case TaskActions.TASK_SETLTV_COMPLETE:
      return {
        ...state,
        ltvComplete: action.payload,
        status: action.type,
      };
    case TaskActions.TASK_SETSCV_COMPLETE:
      return { ...state, scvComplete: action.payload, status: action.type };
    case TaskActions.TASK_SETCUSTOMERGRAPHPERSONAQUERY_COMPLETE:
      return {
        ...state,
        CustomerGraphPersonaQueryComplete: action.payload,
        status: action.type,
      };
    case TaskActions.TASK_SETCUSTOMERGRAPHAUDIENCEQUERY_COMPLETE:
      return {
        ...state,
        CustomerGraphAudienceQueryComplete: action.payload,
        status: action.type,
      };
    case TaskActions.TASK_SETCUSTOMERQUERY_COMPLETE:
      return { ...state, CustomerQueryComplete: action.payload, status: action.type };
    case TaskActions.TASK_SETCUSTOMERCOUNTQUERY_COMPLETE:
      return { ...state, CustomerCountQueryComplete: action.payload, status: action.type };
    case TaskActions.TASK_SETCUSTOMERGRAPHPERSONAQUERY_FAILED:
      return {
        ...state,
        CustomerGraphPersonaQueryFailed: action.payload,
        status: action.type,
      };
    case TaskActions.TASK_SETCUSTOMERGRAPHAUDIENCEQUERY_FAILED:
      return {
        ...state,
        CustomerGraphAudienceQueryFailed: action.payload,
        status: action.type,
      };
    case TaskActions.TASK_SETCUSTOMERQUERY_FAILED:
      return { ...state, CustomerQueryFailed: action.payload, status: action.type };
    case TaskActions.TASK_SETCUSTOMERCOUNTQUERY_FAILED:
      return { ...state, CustomerCountQueryFailed: action.payload, status: action.type };
    case TaskActions.TASK_SETATTRIBUTION_FAILED:
      return {
        ...state,
        attributionFailed: action.payload,
        status: action.type,
      };
    case TaskActions.TASK_SETAUDIENCE_FAILED:
      return {
        ...state,
        audienceFailed: action.payload,
        status: action.type,
      };
    case TaskActions.TASK_CLEARAUDIENCE_FAILED:
      return {
        ...state,
        audienceFailed: undefined,
        status: action.type,
      };
    case TaskActions.TASK_SETRFM_FAILED:
      return {
        ...state,
        rfmFailed: action.payload,
        status: action.type,
      };
    case TaskActions.TASK_SETLTV_FAILED:
      return {
        ...state,
        ltvFailed: action.payload,
        status: action.type,
      };
    case TaskActions.TASK_SETSCV_FAILED:
      return { ...state, scvFailed: action.payload, status: action.type };
    default:
      return state;
  }
}
