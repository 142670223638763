<cdk-virtual-scroll-viewport
  itemSize="48"
  style="height: 500px; width: 570px;"
  autosize
  class="navPicker"
>
  <ejs-treeview
    #tree
    id="default"
    [fields]="fields"
    [fullRowSelect]="false"
    expandOn="Click"
    [animation]="{ expand: { duartion: 0 }, collapse: { duration: 0 } }"
    (nodeExpanding)="toggleNode($event)"
  >
    <ng-template #nodeTemplate="" let-data="">
      <div
        *ngIf="data.isGroup"
        class="alignCenter"
        [ngClass]="{'account-selected': isSelectedAdvertiser(data)}"
      >
        <!-- <span style="width: 40px;" *ngIf="!data.isLoaded">
        <a
          mat-icon-button
          [attr.aria-label]="'toggle '"
          *ngIf="!searchFilter"
          (click)="toggleNode(data)"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{data.expanded ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </a>
      </span> -->
        <mat-icon class="mat-icon-rtl-mirror typeIcon">
          {{data.expanded ? 'folder_open' : 'folder'}}
        </mat-icon>
        <a class="nav-button name" mat-button (click)="selectNode(data)" data-cy="nav-panel-group-item">
          {{data.Name}}
        </a>
        <span style="width: 70px;">
          <mat-progress-bar
            *ngIf="data.isLoading || loading[data.AccountId]"
            mode="indeterminate"
            color="accent"
            class="example-tree-progress-bar"
            data-cy="nav-panel-group-progress-bar"
          ></mat-progress-bar>
        </span>
      </div>

      <div
        *ngIf="!data.isGroup"
        [ngClass]="{'account-selected': isSelectedAdvertiser(data)}"
        class="advertiser"
        (click)="selectNode(data)"
      >
        <a class="nav-button name" data-cy="nav-panel-account-item">
          {{data.Name}}
        </a>
      </div>
    </ng-template>
  </ejs-treeview>
  <div *ngIf="dataCount === 0 && !state?.searchLoading" class="no-result-message">No Accounts or Groups found</div>
</cdk-virtual-scroll-viewport>
