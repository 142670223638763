import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import {AppState} from '../state';
import {AuthQuery} from './auth.reducer';
import * as AuthActions from './auth.actions';
type Action = AuthActions.All;

@Injectable()
export class AuthErrorHandlerFacade {
  auth$ = this.store.select(AuthQuery.auth);
  authenticated$ = this.store.select(AuthQuery.authenticated);

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
  ) {}

  setAuthenticated(authenticated: boolean, error = ''): void {
    this.store.dispatch(new AuthActions.SetAuthAuthenticated(authenticated, error));
  }
}
