import { Action } from '@ngrx/store';
import {
  Creative, AccountGroup, Campaign, ClaimsPayload, Account
} from '../../../interfaces';
import { ResourceClaim } from '../../../interfaces/claim.interface';

export const RESET_MANAGEMENT_STATE = 'Management reset state';

export const GET_MANAGEMENT_GROUP = 'Management get group';
export const GET_MANAGEMENT_GROUP_SUCCESS = 'Management get group success';
export const GET_MANAGEMENT_GROUP_FAIL = 'Management get group fail';

export const GET_MANAGEMENT_ADVERTISER = 'Management get advertiser';
export const GET_MANAGEMENT_ADVERTISER_SUCCESS = 'Management get advertiser success';
export const GET_MANAGEMENT_ADVERTISER_FAIL = 'Management get advertiser fail';

export const GET_MANAGEMENT_ADVERTISER_CAMPAIGNS = 'Management get advertiser campaigns';
export const GET_MANAGEMENT_ADVERTISER_CAMPAIGNS_SUCCESS = 'Management get advertiser campaigns success';
export const GET_MANAGEMENT_ADVERTISER_CAMPAIGNS_FAIL = 'Management get advertiser campaigns fail';

export const GET_MANAGEMENT_ADVERTISER_CREATIVES = 'Management get advertiser creatives';
export const GET_MANAGEMENT_ADVERTISER_CREATIVES_SUCCESS = 'Management get advertiser creatives success';
export const GET_MANAGEMENT_ADVERTISER_CREATIVES_FAIL = 'Management get advertiser creatives fail';

export const GET_MANAGEMENT_CLAIMS = 'Management get claims';
export const GET_MANAGEMENT_CLAIMS_SUCCESS = 'Management get claims success';
export const GET_MANAGEMENT_CLAIMS_FAIL = 'Management get claims fail';

export const GET_MANAGEMENT_SUB_GROUP_CLAIMS = 'Management get sub group claims';
export const GET_MANAGEMENT_SUB_GROUP_CLAIMS_SUCCESS = 'Management get sub group claims success';
export const GET_MANAGEMENT_SUB_GROUP_CLAIMS_FAIL = 'Management get sub group claims fail';

export const GET_MANAGEMENT_PARENT_GROUP_CLAIMS = 'Management get parent group claims';
export const GET_MANAGEMENT_PARENT_GROUP_CLAIMS_SUCCESS = 'Management get parent group claims success';
export const GET_MANAGEMENT_PARENT_GROUP_CLAIMS_FAIL = 'Management get parent group claims fail';

export const GET_MANAGEMENT_ADVERTISER_CLAIMS = 'Management get advertiser claims';
export const GET_MANAGEMENT_ADVERTISER_CLAIMS_SUCCESS = 'Management get advertiser claims success';
export const GET_MANAGEMENT_ADVERTISER_CLAIMS_FAIL = 'Management get advertiser claims fail';

export const SAVE_MANAGEMENT_GROUP = 'Management save group';
export const SAVE_MANAGEMENT_GROUP_SUCCESS = 'Management save group success';
export const SAVE_MANAGEMENT_GROUP_FAIL = 'Management save group fail';

export const SAVE_MANAGEMENT_CLAIMS = 'Management save claims';
export const SAVE_MANAGEMENT_CLAIMS_SUCCESS = 'Management save claims success';
export const SAVE_MANAGEMENT_CLAIMS_FAIL = 'Management save claims fail';

export const DELETE_MANAGEMENT_GROUP = 'Management delete group';
export const DELETE_MANAGEMENT_GROUP_SUCCESS = 'Management delete group success';
export const DELETE_MANAGEMENT_GROUP_FAIL = 'Management delete group fail';

export const DELETE_MANAGEMENT_ADVERTISER = 'Management delete advertiser';
export const DELETE_MANAGEMENT_ADVERTISER_SUCCESS = 'Management delete advertiser success';
export const DELETE_MANAGEMENT_ADVERTISER_FAIL = 'Management delete advertiser fail';

export const DELETE_MANAGEMENT_CLAIMS = 'Management delete claims';
export const DELETE_MANAGEMENT_CLAIMS_SUCCESS = 'Management delete claims success';
export const DELETE_MANAGEMENT_CLAIMS_FAIL = 'Management delete claims fail';

export const DELETE_MANAGEMENT_ADVERTISER_CLAIMS = 'Management delete advertiser claims';
export const DELETE_MANAGEMENT_ADVERTISER_CLAIMS_SUCCESS = 'Management delete advertiser claims success';
export const DELETE_MANAGEMENT_ADVERTISER_CLAIMS_FAIL = 'Management delete advertiser claims fail';

export const SAVE_MANAGEMENT_SUBGROUP = 'Management save subgroup';
export const SAVE_MANAGEMENT_SUBGROUP_SUCCESS = 'Management save subgroup success';
export const SAVE_MANAGEMENT_SUBGROUP_FAIL = 'Management save subgroup fail';

export const SAVE_MANAGEMENT_ADVERTISER = 'Management save advertiser';
export const SAVE_MANAGEMENT_ADVERTISER_SUCCESS = 'Management save advertiser success';
export const SAVE_MANAGEMENT_ADVERTISER_FAIL = 'Management save advertiser fail';

export const SAVE_MANAGEMENT_ADVERTISER_CLAIMS = 'Management save advertiser claims';
export const SAVE_MANAGEMENT_ADVERTISER_CLAIMS_SUCCESS = 'Management save advertiser claims success';
export const SAVE_MANAGEMENT_ADVERTISER_CLAIMS_FAIL = 'Management save advertiser claims fail';

export const SET_MANAGEMENT_ADVERTISER_FORM_DIRTY = 'Management set advertiser dirty';

export class ResetManagementState implements Action {
  readonly type = RESET_MANAGEMENT_STATE;
  constructor() {}
}

export class SetManagementDirty implements Action {
  readonly type = SET_MANAGEMENT_ADVERTISER_FORM_DIRTY;
  constructor(public payload: boolean) {}
}

export class GetManagementGroup implements Action {
  readonly type = GET_MANAGEMENT_GROUP;
  constructor(public payload: string) {}
}

export class GetManagementGroupSuccess implements Action {
  readonly type = GET_MANAGEMENT_GROUP_SUCCESS;
  constructor(public payload: AccountGroup) {}
}

export class GetManagementGroupFail implements Action {
  readonly type = GET_MANAGEMENT_GROUP_FAIL;
  constructor(public payload?: string) {}
}

export class GetManagementAdvertiser implements Action {
  readonly type = GET_MANAGEMENT_ADVERTISER;
  constructor(public payload: string) {}
}

export class GetManagementAdvertiserSuccess implements Action {
  readonly type = GET_MANAGEMENT_ADVERTISER_SUCCESS;
  constructor(public payload: Account) {}
}

export class GetManagementAdvertiserFail implements Action {
  readonly type = GET_MANAGEMENT_ADVERTISER_FAIL;
  constructor(public payload?: string) {}
}

export class GetManagementAdvertiserCreatives implements Action {
  readonly type = GET_MANAGEMENT_ADVERTISER_CREATIVES;
  constructor(public payload: string) {}
}

export class GetManagementAdvertiserCreativesSuccess implements Action {
  readonly type = GET_MANAGEMENT_ADVERTISER_CREATIVES_SUCCESS;
  constructor(public payload: Creative[]) {}
}

export class GetManagementAdvertiserCreativesFail implements Action {
  readonly type = GET_MANAGEMENT_ADVERTISER_CREATIVES_FAIL;
  constructor(public payload?: string) {}
}


export class GetManagementAdvertiserCampaign implements Action {
  readonly type = GET_MANAGEMENT_ADVERTISER_CAMPAIGNS;
  constructor(public payload: string) {}
}

export class GetManagementAdvertiserCampaignSuccess implements Action {
  readonly type = GET_MANAGEMENT_ADVERTISER_CAMPAIGNS_SUCCESS;
  constructor(public payload: Campaign[]) {}
}

export class GetManagementAdvertiserCampaignFail implements Action {
  readonly type = GET_MANAGEMENT_ADVERTISER_CAMPAIGNS_FAIL;
  constructor(public payload?: string) {}
}
export class GetManagementClaims implements Action {
  readonly type = GET_MANAGEMENT_CLAIMS;
  constructor(public payload: string) {}
}

export class GetManagementClaimsSuccess implements Action {
  readonly type = GET_MANAGEMENT_CLAIMS_SUCCESS;
  constructor(public payload: ResourceClaim[]) {}
}

export class GetManagementClaimsFail implements Action {
  readonly type = GET_MANAGEMENT_CLAIMS_FAIL;
  constructor(public payload?: string) {}
}

export class GetManagementAdvertiserClaims implements Action {
  readonly type = GET_MANAGEMENT_ADVERTISER_CLAIMS;
  constructor(public payload: ClaimsPayload) {}
}

export class GetManagementAdvertiserClaimsSuccess implements Action {
  readonly type = GET_MANAGEMENT_ADVERTISER_CLAIMS_SUCCESS;
  constructor(public payload: ResourceClaim[]) {}
}

export class GetManagementAdvertiserClaimsFail implements Action {
  readonly type = GET_MANAGEMENT_ADVERTISER_CLAIMS_FAIL;
  constructor(public payload?: string) {}
}

export class GetManagementSubGroupClaims implements Action {
  readonly type = GET_MANAGEMENT_SUB_GROUP_CLAIMS;
  constructor(public payload: string) {}
}

export class GetManagementSubGroupClaimsSuccess implements Action {
  readonly type = GET_MANAGEMENT_SUB_GROUP_CLAIMS_SUCCESS;
  constructor(public payload: ResourceClaim[]) {}
}

export class GetManagementSubGroupClaimsFail implements Action {
  readonly type = GET_MANAGEMENT_SUB_GROUP_CLAIMS_FAIL;
  constructor(public payload?: string) {}
}

export class GetManagementParentGroupClaims implements Action {
  readonly type = GET_MANAGEMENT_PARENT_GROUP_CLAIMS;
  constructor(public payload: string) {}
}

export class GetManagementParentGroupClaimsSuccess implements Action {
  readonly type = GET_MANAGEMENT_PARENT_GROUP_CLAIMS_SUCCESS;
  constructor(public payload: ResourceClaim[]) {}
}

export class GetManagementParentGroupClaimsFail implements Action {
  readonly type = GET_MANAGEMENT_PARENT_GROUP_CLAIMS_FAIL;
  constructor(public payload?: string) {}
}

export class SaveManagementGroup implements Action {
  readonly type = SAVE_MANAGEMENT_GROUP;
  constructor(public groupId: string, public payload: AccountGroup) {}
}

export class SaveManagementGroupSuccess implements Action {
  readonly type = SAVE_MANAGEMENT_GROUP_SUCCESS;
  constructor() {}
}

export class SaveManagementGroupFail implements Action {
  readonly type = SAVE_MANAGEMENT_GROUP_FAIL;
  constructor(public payload?: string) {}
}

export class DeleteManagementGroup implements Action {
  readonly type = DELETE_MANAGEMENT_GROUP;
  constructor(public groupId: string) {}
}

export class DeleteManagementGroupSuccess implements Action {
  readonly type = DELETE_MANAGEMENT_GROUP_SUCCESS;
  constructor() {}
}

export class DeleteManagementGroupFail implements Action {
  readonly type = DELETE_MANAGEMENT_GROUP_FAIL;
  constructor(public payload?: string) {}
}

export class DeleteManagementAdvertiser implements Action {
  readonly type = DELETE_MANAGEMENT_ADVERTISER;
  constructor(public payload: string) {}
}

export class DeleteManagementAdvertiserSuccess implements Action {
  readonly type = DELETE_MANAGEMENT_ADVERTISER_SUCCESS;
  constructor() {}
}

export class DeleteManagementAdvertiserFail implements Action {
  readonly type = DELETE_MANAGEMENT_ADVERTISER_FAIL;
  constructor(public payload?: string) {}
}

export class SaveManagementClaim implements Action {
  readonly type = SAVE_MANAGEMENT_CLAIMS;
  constructor(public groupId: string, public payload: ResourceClaim) {}
}

export class SaveManagementClaimSuccess implements Action {
  readonly type = SAVE_MANAGEMENT_CLAIMS_SUCCESS;
  constructor() {}
}

export class SaveManagementClaimFail implements Action {
  readonly type = SAVE_MANAGEMENT_CLAIMS_FAIL;
  constructor(public payload?: string) {}
}

export class DeleteManagementClaim implements Action {
  readonly type = DELETE_MANAGEMENT_CLAIMS;
  constructor(public payload: ClaimsPayload) {}
}

export class DeleteManagementClaimSuccess implements Action {
  readonly type = DELETE_MANAGEMENT_CLAIMS_SUCCESS;
  constructor() {}
}

export class DeleteManagementClaimFail implements Action {
  readonly type = DELETE_MANAGEMENT_CLAIMS_FAIL;
  constructor(public payload?: string) {}
}

export class DeleteManagementAdvertiserClaim implements Action {
  readonly type = DELETE_MANAGEMENT_ADVERTISER_CLAIMS;
  constructor(public payload: ClaimsPayload) {}
}

export class DeleteManagementAdvertiserClaimSuccess implements Action {
  readonly type = DELETE_MANAGEMENT_ADVERTISER_CLAIMS_SUCCESS;
  constructor() {}
}

export class DeleteManagementAdvertiserClaimFail implements Action {
  readonly type = DELETE_MANAGEMENT_ADVERTISER_CLAIMS_FAIL;
  constructor(public payload?: string) {}
}

export class SaveManagementAdvertiserClaim implements Action {
  readonly type = SAVE_MANAGEMENT_ADVERTISER_CLAIMS;
  constructor(public groupId: string, public id: string, public payload: ResourceClaim) {}
}

export class SaveManagementAdvertiserClaimSuccess implements Action {
  readonly type = SAVE_MANAGEMENT_ADVERTISER_CLAIMS_SUCCESS;
  constructor() {}
}

export class SaveManagementAdvertiserClaimFail implements Action {
  readonly type = SAVE_MANAGEMENT_ADVERTISER_CLAIMS_FAIL;
  constructor(public payload?: string) {}
}

export class SaveManagementSubGroup implements Action {
  readonly type = SAVE_MANAGEMENT_SUBGROUP;
  constructor(public payload: AccountGroup) {}
}

export class SaveManagementSubGroupSuccess implements Action {
  readonly type = SAVE_MANAGEMENT_SUBGROUP_SUCCESS;
  constructor() {}
}

export class SaveManagementSubGroupFail implements Action {
  readonly type = SAVE_MANAGEMENT_SUBGROUP_FAIL;
  constructor(public payload?: string) {}
}

export class SaveManagementAdvertiser implements Action {
  readonly type = SAVE_MANAGEMENT_ADVERTISER;
  constructor(public payload: Account) {}
}

export class SaveManagementAdvertiserSuccess implements Action {
  readonly type = SAVE_MANAGEMENT_ADVERTISER_SUCCESS;
  constructor() {}
}

export class SaveManagementAdvertiserFail implements Action {
  readonly type = SAVE_MANAGEMENT_ADVERTISER_FAIL;
  constructor(public payload?: string) {}
}


export type All
  = ResetManagementState
  | SetManagementDirty
  | GetManagementGroup
  | GetManagementGroupSuccess
  | GetManagementGroupFail
  | SaveManagementGroup
  | SaveManagementGroupSuccess
  | SaveManagementGroupFail
  | SaveManagementAdvertiser
  | SaveManagementAdvertiserSuccess
  | SaveManagementAdvertiserFail
  | SaveManagementSubGroup
  | SaveManagementSubGroupSuccess
  | SaveManagementSubGroupFail
  | DeleteManagementGroup
  | DeleteManagementGroupSuccess
  | DeleteManagementGroupFail
  | GetManagementClaims
  | GetManagementClaimsSuccess
  | GetManagementClaimsFail
  | SaveManagementClaim
  | SaveManagementClaimSuccess
  | SaveManagementClaimFail
  | DeleteManagementClaim
  | DeleteManagementClaimSuccess
  | DeleteManagementClaimFail
  | GetManagementAdvertiser
  | GetManagementAdvertiserSuccess
  | GetManagementAdvertiserFail
  | GetManagementSubGroupClaims
  | GetManagementSubGroupClaimsSuccess
  | GetManagementSubGroupClaimsFail
  | GetManagementParentGroupClaims
  | GetManagementParentGroupClaimsSuccess
  | GetManagementParentGroupClaimsFail
  | GetManagementAdvertiserClaims
  | GetManagementAdvertiserClaimsSuccess
  | GetManagementAdvertiserClaimsFail
  | DeleteManagementAdvertiser
  | DeleteManagementAdvertiserSuccess
  | DeleteManagementAdvertiserFail
  | SaveManagementAdvertiserClaim
  | SaveManagementAdvertiserClaimSuccess
  | SaveManagementAdvertiserClaimFail
  | GetManagementAdvertiserCampaign
  | GetManagementAdvertiserCampaignSuccess
  | GetManagementAdvertiserCampaignFail
  | GetManagementAdvertiserCreatives
  | GetManagementAdvertiserCreativesSuccess
  | GetManagementAdvertiserCreativesFail
  | DeleteManagementAdvertiserClaim
  | DeleteManagementAdvertiserClaimSuccess
  | DeleteManagementAdvertiserClaimFail;
