
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AccountConfirmPayload } from '../../shared/interfaces';
import { Observable } from 'rxjs';
import { ActiveUser, UserInfo, AuthCache } from '../../shared/interfaces/common.interface';

declare var __: any;

@Injectable()
export class AuthService {
  public apiClient = <ApiClient>{};


  cache: AuthCache = {
    /**
     * @ngdoc property
     * @name activeUser
     * @propertyOf webApp.authService
     * @description Stores the current user in cache.
     *  - UserId
     *  - Email
     *
     */
    activeUser: <ActiveUser>{},

    /**
     * @ngdoc property
     * @name isConfirmed
     * @propertyOf webApp.authService
     * @description Flag for account verification notice.  Caches value for the session.
     *
     */
    isConfirmed: true
  };
  isBrowser = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }
  /********
        *  AUTH TOKENS
        *******/
  wipeUserState() {
    this.cache.activeUser = <ActiveUser>{};
  }

  /**
   * @ngdoc method
   * @name setToken
   * @methodOf webApp.authService
   * @description Sets user token in a cookie.  Cookie last for 2 weeks same as token.
   *
   * @param {string} token a unique string
   */
  setToken(token: string) {
    // console.log('setToken', token);
    if (this.isBrowser) {
      __.cookie.set('x-bearer', token, { maxAge: 86400 * 14 });
    }
  }

  /**
   * @ngdoc method
   * @name getToken
   * @methodOf webApp.authService
   * @description Get user's token cookie set as x-bearer.
   *
   * @return {string} token x-bearer cookie
   */
  getToken() {
    // console.log('getToken', __.cookie.get('x-bearer'));
    if (this.isBrowser) {
      return __.cookie.get('x-bearer');
    }
  }

  /**
   * @ngdoc method
   * @name removeTokens
   * @methodOf webApp.authService
   * @description Deletes both x-user & x-bearer cookies.
   *
   */
  removeTokens() {
    if (this.isBrowser) {
      __.cookie.delete('x-bearer');
      __.cookie.delete('x-user');
    }
    this.wipeUserState();
  }

  /********
   *  USER INFO
   *******/

  /**
   * @ngdoc method
   * @name getUser
   * @methodOf webApp.authService
   * @description Gets user's account cookie.
   *
   */
  getUser(): string {
    // console.log('getUser', __.cookie.get('x-user'));
    if (this.isBrowser) {
      return __.cookie.get('x-user');
    }

    return '<UserInfo>{}';
  }

  /**
   * @ngdoc method
   * @name setUser
   * @methodOf webApp.authService
   * @description Sets user's account cookie as x-user
   *
   * @param {object} userInfo Object of user account, email, and statuses
   */
  setUser(userInfo: UserInfo) {
    // console.log('AuthService setUser(userInfo)', userInfo );
    if (this.isBrowser) {
      __.cookie.set('x-user', userInfo);
    }
  }

  /**
   * @ngdoc method
   * @name removeUser
   * @methodOf webApp.authService
   * @description Deletes user's account cookie
   *
   */
  removeUser() {
    if (this.isBrowser) {
      __.cookie.delete('x-user');
    }
    this.wipeUserState();
  }

  /**
   * @ngdoc method
   * @name getUserInfo
   * @methodOf webApp.authService
   * @description GET active user account info and set's cookie.
   *
   * @return {deferred} GET
   */
  getUserInfo() {
    this.apiClient.setBaseUrl(environment.authBase);
    return this.apiClient.callHttpGet({path: `/Account/UserInfo`, version: ''}).pipe(
      tap((data: UserInfo) => this.setUser(data)));
  }

  /**
   * Manage Auth
   */

  /**
   * @ngdoc method
   * @name wipeUser
   * @methodOf webApp.authService
   * @description Wipe user's current session.  Deletes all cookies.
   *
   */
  wipeUser() {
    this.removeTokens();
    this.removeUser();
  }

  /**
   * @ngdoc method
   * @name doLogout
   * @methodOf webApp.authService
   * @description POST logout request.  Kills backend auth session.
   *
   * @return {deferred} POST
   *
   */
  doLogout() {
    this.apiClient.setBaseUrl(environment.authBase);
    return this.apiClient.callHttpPost({path: `/Account/Logout`, param: {}});
  }

  /**
   * @ngdoc method
   * @name isAuthenticated
   * @methodOf webApp.authService
   * @description Checks if use has a token.  Does not validate token.
   *
   * @return {boolean} isAuthenticated
   *
   */
  isAuthenticated() {
    if (this.getToken()) { return true; }
    return false;
  }

  /**
   * @ngdoc method
   * @name doAccountConfirm
   * @methodOf webApp.authService
   * @description POST payload including user/email payload to validate email address.
   *
   * @return {deferred} POST
   *
   */
  doAccountConfirm(payload: AccountConfirmPayload): Observable<void> {
    this.apiClient.setBaseUrl(environment.authBase);
    return this.apiClient.callHttpPost({path: `/Account/Confirm`, param: payload});
  }

  /**
   * @ngdoc method
   * @name doAccountConfirm
   * @methodOf webApp.authService
   * @description Resends verification email.
   *
   * @return {deferred} POST
   *
   */
  doResendConfirmation() {
    this.apiClient.setBaseUrl(environment.authBase);
    return this.apiClient.callHttpPost({path: `/Account/ResendConfirmation`, param: {}});
  }

}
