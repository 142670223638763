<div class="row nomargin">
  <div class="col-md-12 nopadding">
    <div class="col-md-7 nopadding" #navTarget cdk-overlay-origin>
      <app-form-button type="text" (onClick)="onButtonClick()" cssClass="headerpanel" itemId="nav-panel-main">
        <div class="fa locationIcon">
          <mat-icon>location_on</mat-icon>
        </div>
        <div class="ui-breadcrumb ui-widget ui-widget-header ui-helper-clearfix ui-corner-all">
          <small style="color: white; position: relative; top: 4px; font-weight:normal;" id="nav-category">{{category}}</small>
          <ul>
            <li role="menuitem" id="nav-selected-item" style="margin: 0px;">
              <a class="ui-menuitem-link" *ngIf="getAdvertiserName()">
                <span class="instancename ui-menuitem-text" id="nav-panel-selected-item">{{getAdvertiserName()}}</span>
              </a>
              <a class="ui-menuitem-link" *ngIf="getGroupName()">
                <span class="instancename ui-menuitem-text" id="nav-panel-selected-item">{{getGroupName()}}</span>
              </a>
            </li>
          </ul>
        </div>
        <i class="fa fa-chevron-down" id="nav-picker-caret" aria-hidden="true" *ngIf="!isSingleAccount"></i>
      </app-form-button>
    </div>
  </div>
</div>
