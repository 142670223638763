import { createReducer, on, Action } from '@ngrx/store';
import { SideMenuStatusState } from '.';
import * as sideMenuAction from './side-menu-status.actions';

export const initialState: SideMenuStatusState = {
  isOpen: false
};

const reducer = createReducer(
  initialState,

  on(sideMenuAction.actionChangeInSideMenuStatus, (state, {sideMenuIsOpen}) => ({
    ...state,
    isOpen: sideMenuIsOpen
  })),
);

export function sideMenuStatusReducer(
  state: SideMenuStatusState | undefined,
  action: Action
): SideMenuStatusState {
  return reducer(state, action);
}
