
import {throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationsService } from '../notification/notification.service';
import { UtilityService } from '../utility/utility.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { ErrorHandlerInterface } from '../../shared/interfaces/error-handler.interface';
import { ApiService } from '../api/api.service';
import { AuthErrorHandlerFacade } from '../../shared/state/auth/auth-error-handler.facade';
import { ApiResponse, RawErrorData } from '../../shared/interfaces/common.interface';


@Injectable()
export class ErrorHandlerService implements ErrorHandlerInterface {

  constructor(
    private notificationsService: NotificationsService,
    private router: Router,
    private apiClient: ApiService,
    private authFacade: AuthErrorHandlerFacade,
    private authService: AuthService,
    private utilities: UtilityService) { }

  setAuthApiClient() {
    this.apiClient.errorHandler = this;
    this.authService.apiClient = this.apiClient;
  }

  handleHttpError(error: any) {
    let body = <{Message: string}>{};
    if (error._body) {
      body = JSON.parse(error._body);
    }
    if (body.Message) {
      this.notificationsService.notify({
        severity: 'error',
        detail: body.Message,
        summary: 'An error occured.',
        key: 'sticky'
      });
    } else if (error.status >= 500 && error.status <= 599) {
      this.handle500Error();
    } else if (error.status >= 400 && error.status <= 499 && error.status !== 409 && error.status !== 404
      && (error.status !== 403 && error.status !== 401)) {
      let message = this.utilities.parseError(error);
      if (error.error?.metadata?.errors?.length > 0) {
        message = error.error.metadata.errors[0].message
      }
      let title = 'An error occured.';
      if (error.error && (error.error as RawErrorData).title) {
        title = (error.error as RawErrorData).title as string;
      } else if ((error.error as RawErrorData).Message) {
        title = (error.error as RawErrorData).Message as string;
      }
      this.handle400Error(message, title);
    } else if (error.status === 403 || error.status === 401) {
      this.goToLogin();
    } else if (error.status === 0) {
      this.handle0Error();
    } else if (error.status === 409) {
      this.handle409Error(error);
    }
    return observableThrowError(error);
  }

  handle500Error() {
    this.notificationsService.notify({
      severity: 'error',
      detail: 'The server has encountered an error. Please refresh the application. If the problem persists, contact support.',
      summary: 'A server error occured.',
    });
  }

  handle0Error() {
    this.notificationsService.notify({
      severity: 'error',
      detail: 'The server has encountered an error. Please refresh the application. If the problem persists, contact support.',
      summary: 'A server error occured.',
      key: 'non-sticky'
    });
  }

  handle400Error(message: string, title: string) {
    console.log('400 error message', message, title)
    this.notificationsService.notify({
      severity: 'error',
      detail: message,
      summary: title || 'An error occured.',
      key: 'non-sticky'
    });
  }

  handle409Error(error: ApiResponse) {
    const path = this.utilities.string2array(error.url, '/');
    if (path.length === 7 && path[5] === 'creatives') {
      this.notificationsService.notify({
        severity: 'error',
        detail: 'Creative is currently assigned to an ad. Please unassign before deleting this creative.',
        summary: 'An error occured.',
        key: 'non-sticky'
      });
    } else if (path.length === 10 && path[6] === 'feed' && path[7] === 'segments' && path[9] === 'definition') {
      this.notificationsService.notify({
        severity: 'error',
        detail: 'Segment is currently assigned to an ad. Please unassign before deleting this segment.',
        summary: 'An error occured.',
        key: 'non-sticky'
      });
    } else {
      this.notificationsService.notify({
        severity: 'error',
        detail: 'Bad Request. Please refresh the application. If the problem persists, contact support.',
        summary: 'An error occured.',
        key: 'non-sticky'
      });
    }
  }


  goToLogin() {
    this.authService.wipeUser();
    this.authFacade.setAuthenticated(this.authService.isAuthenticated(), 'Your session has expired. Please log back in to continue.');
    this.authService.doLogout();
    this.router.navigate(['/login']);
  }
}
