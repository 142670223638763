import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormGroup,
  UntypedFormControl,
} from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { DropdownOption } from '../../../../../shared/interfaces';
import { OnChangeFunction, OnTouchFunction } from '../types';
import { Validator } from '../../../../../shared/interfaces/field.interface';

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => MultiSelectFormElementComponent),
  multi: true,
};
@Component({
  selector: 'app-form-multi-select',
  template: `
    <div *ngIf="group" [formGroup]="group">
      <mat-form-field>
        <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
        <mat-select
          [(ngModel)]="value"
          (selectionChange)="changed($event)"
          (openedChange)="openedChanged($event)"
          [id]="itemId"
          [formControlName]="name"
          [disabled]="disabled"
          [ngStyle]="cssStyle"
          style="z-index: 500102900;"
          multiple
        >
          <ng-container *ngIf="filter">
            <mat-option>
              <ngx-mat-select-search
                [formControl]="bankFilterCtrl"
                [placeholderLabel]="'Search'"
                [noEntriesFoundLabel]="'no matching item found'"
              ></ngx-mat-select-search>
            </mat-option>
          </ng-container>
          <ng-container *ngIf="filter">
            <mat-option *ngIf="reset" value="">None</mat-option>
            <mat-option
              *ngFor="let option of filteredBanks | async; trackBy: trackByFn"
              [value]="optionValue ? option[optionValue] : option.value"
              [disabled]="option.disabled"
            >
              {{ optionLabel ? option[optionLabel] : option.label
              }}<ng-content></ng-content>
            </mat-option>
          </ng-container>

          <ng-container *ngIf="!filter">
            <mat-option *ngIf="reset" value="">None</mat-option>
            <mat-option
              *ngFor="let option of options; trackBy: trackByFn"
              [value]="optionValue ? option[optionValue] : option.value"
              [disabled]="option.disabled"
            >
              {{ optionLabel ? option[optionLabel] : option.label
              }}<ng-content></ng-content>
            </mat-option>
          </ng-container>
        </mat-select>

        <ng-container
          *ngFor="let validation of validations"
          ngProjectAs="mat-error"
        >
          <mat-error *ngIf="group.get(name)?.hasError(validation.name)">{{
            validation.message
          }}</mat-error>
        </ng-container>
      </mat-form-field>
    </div>
    <div *ngIf="!group">
      <mat-form-field [ngClass]="{ multiSelectIconOnly : iconOnly }">
        <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
        <mat-select
          [(ngModel)]="value"
          *ngIf="!useCompare"
          (selectionChange)="changed($event)"
          (openedChange)="openedChanged($event)"
          [id]="itemId"
          #singleSelect
          [disabled]="disabled"
          [ngStyle]="cssStyle"
          style="z-index: 500102900;"
          multiple
        >
          <mat-select-trigger *ngIf="iconOnly">
            <mat-icon class="selection-icon">{{iconName}}</mat-icon>
          </mat-select-trigger>
          <mat-option *ngIf="filter">
            <ngx-mat-select-search
              [formControl]="bankFilterCtrl"
              [placeholderLabel]="'Search'"
              [noEntriesFoundLabel]="'no matching item found'"
            ></ngx-mat-select-search>
          </mat-option>
          <ng-container *ngIf="filter">
            <mat-option *ngIf="reset" value="">None</mat-option>
            <mat-option
              *ngFor="let option of filteredBanks | async; trackBy: trackByFn"
              [value]="optionValue ? option[optionValue] : option.value"
              [disabled]="option.disabled"
            >
              {{ optionLabel ? option[optionLabel] : option.label
              }}<ng-content></ng-content>
            </mat-option>
          </ng-container>
          <ng-container *ngIf="!filter">
            <mat-option *ngIf="reset" value="">None</mat-option>
            <mat-option
              *ngFor="let option of options; trackBy: trackByFn"
              [value]="optionValue ? option[optionValue] : option.value"
              [disabled]="option.disabled"
            >
              {{ optionLabel ? option[optionLabel] : option.label
              }}<ng-content></ng-content>
            </mat-option>
          </ng-container>
        </mat-select>

        <mat-select
          [(ngModel)]="value"
          *ngIf="useCompare"
          [compareWith]="compareObjects"
          (selectionChange)="changed($event)"
          (openedChange)="openedChanged($event)"
          [id]="itemId"
          #singleSelect
          [disabled]="disabled"
          [ngStyle]="cssStyle"
          style="z-index: 500102900;"
          multiple
        >
          <mat-option *ngIf="filter">
            <ngx-mat-select-search
              [formControl]="bankFilterCtrl"
              [placeholderLabel]="'Search'"
              [noEntriesFoundLabel]="'no matching item found'"
            ></ngx-mat-select-search>
          </mat-option>
          <ng-container *ngIf="filter">
            <mat-option *ngIf="reset" value="">None</mat-option>
            <mat-option
              *ngFor="let option of filteredBanks | async; trackBy: trackByFn"
              [value]="optionValue ? option[optionValue] : option.value"
              [disabled]="option.disabled"
            >
              {{ optionLabel ? option[optionLabel] : option.label
              }}<ng-content></ng-content>
            </mat-option>
          </ng-container>
          <ng-container *ngIf="!filter">
            <mat-option *ngIf="reset" value="">None</mat-option>
            <mat-option
              *ngFor="let option of options; trackBy: trackByFn"
              [value]="optionValue ? option[optionValue] : option.value"
              [disabled]="option.disabled"
            >
              {{ optionLabel ? option[optionLabel] : option.label
              }}<ng-content></ng-content>
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['./multi-select.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class MultiSelectFormElementComponent
  implements ControlValueAccessor, OnInit, OnDestroy {

  constructor() {}

  get options(): DropdownOption[] {
    return this._options;
  }

  @Input() set options(value: DropdownOption[]) {
    this._options = value;
    if (this.filter) {
      this.filteredBanks.next((this._options || []).slice());
    }
  }

  get value(): DropdownOption[] {
    return this.innerValue as DropdownOption[];
  }

  @Input() set value(v: DropdownOption[]) {
    if (v !== this.innerValue) {
      this.innerValue = [...v];
      this.onChangeCallback(v);
    }
  }
  private _options: DropdownOption[] = [];

  @Input() placeholder = '';
  @Input() cssStyle = '';
  @Input() name = '';
  @Input() group: UntypedFormGroup|undefined;
  @Input() validations: Validator[] = [];
  @Input() disabled = false;
  @Input() filter = false;
  @Input() reset = false;
  @Input() autoWidth = false;
  @Input() iconOnly = false;
  @Input() iconName = '';
  @Input() itemId = '';
  @Input() optionLabel = '';
  @Input() optionValue = '';
  @Input() useCompare = false;
  @Output() onChange = new EventEmitter<DropdownOption['value']>();
  @Output() openedChange = new EventEmitter<boolean>();
  innerValue: DropdownOption[]|undefined;
  private onTouchedCallback: OnTouchFunction = noop;
  private onChangeCallback: OnChangeFunction<DropdownOption[]> = noop;
  public bankFilterCtrl: UntypedFormControl = new UntypedFormControl();
  protected _onDestroy = new Subject<void>();
  public filteredBanks: ReplaySubject<DropdownOption[]> = new ReplaySubject<DropdownOption[]>(1);

  @ViewChild('singleSelect') singleSelect: MatSelect|undefined;
  @Input() compareObjects = (o1: DropdownOption[], o2: DropdownOption[]) => {
    return isEqual(o1, o2);
  }

  ngOnInit() {
    this.addFilter();
  }

  private addFilter() {
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  trackByFn(index: DropdownOption['value']): DropdownOption['value'] {
    return index;
  }

  protected filterBanks() {
    if (!this.options) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.options.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.options.filter(
        (bank) => (bank.label as string).toLowerCase().indexOf(search) > -1
      )
    );
  }

  changed(e: MatSelectChange) {
    this.onChange.emit(e.value);
  }

  openedChanged(e: boolean) {
    this.openedChange.emit(e);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: DropdownOption[]) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: OnChangeFunction<DropdownOption[]>) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: OnTouchFunction) {
    this.onTouchedCallback = fn;
  }
}
