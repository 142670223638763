import { fromEventPattern, of } from 'rxjs';
import {
  interpret,
  Machine,
  MachineOptions,
  State,
  EventObject,
} from 'xstate';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { campaignSummaryMachineConfig } from './campaign-summary-machine.config';
import { CampaignSummaryEvent, SelectRows, BulkEdit } from './campaign-summary-machine.events';
import { CampaignSummarySchema, CampaignSummaryContext } from './campaign-summary-machine.schema';


@Injectable()
export class CampaignSummaryMachine {
  campaignSummaryMachineOptions: Partial<MachineOptions<CampaignSummaryContext, CampaignSummaryEvent>> = {
    services: {
    },
    guards: {
      rowsSelected: (context, event) => {
        return event && (event as SelectRows).rows && (event as SelectRows).rows.length > 0;
      },
      openPanel: (context, event) => {
        return (event as BulkEdit).open;
      }
    },
    actions: {
    }
  };

  private _campaignSummaryMachine = Machine<CampaignSummaryContext, CampaignSummarySchema, CampaignSummaryEvent>(
    campaignSummaryMachineConfig
  ).withConfig(this.campaignSummaryMachineOptions);
  private service = interpret(this._campaignSummaryMachine).start();

  campaignSummaryState$ = fromEventPattern<[State<CampaignSummaryContext, CampaignSummaryEvent>, EventObject]>(
    handler => {
      return this.service.onTransition(handler);
    },
    (_, service) => service.stop()
  ).pipe(map(([state, _]) => state));

  send(event: CampaignSummaryEvent) {
    this.service.send(event);
  }

  constructor() {}
}
