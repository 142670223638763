import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationDefinition, IntegrationDefinitionApiResponse,
  IntegrationsDefinitionApiResponse, IntegrationTypeAPIResponse, IntegrationTypesAPIResponse } from '../../../shared/interfaces';
import { ApiDataService } from '../../api/abstract/api.service';
import { createUploadHeaders } from '../../helpers/createHeaders';

@Injectable()
export class AccountIntegrationsService {
  constructor(private apiClient: ApiDataService) { }

  getAccountIntegrationTypes(id: string): Observable<IntegrationTypesAPIResponse> {
    return this.apiClient.callHttpGet({
      path: `/${id}/integrationtypes`,
      type: 'accounts',
    });
  }

  getAccountIntegrationType(id: string, typeId: string): Observable<IntegrationTypeAPIResponse> {
    return this.apiClient.callHttpGet({
      path: `/${id}/integrationtypes/${typeId}`,
      type: 'accounts',
    });
  }

  getAccountIntegrations(accountId: string): Observable<IntegrationsDefinitionApiResponse> {
    return this.apiClient.callHttpGet({
      path: `/${accountId}/integrations`,
      type: 'accounts',
    });
  }

  getAccountIntegration(advertiserId: string, integrationId: string): Observable<IntegrationDefinitionApiResponse> {
    return this.apiClient.callHttpGet({
      path: `/${advertiserId}/integrations/${integrationId}`,
      type: 'accounts',
    });
  }

  addAccountIntegration(
    advertiserId: string,
    payload: Partial<IntegrationDefinition>): Observable<IntegrationDefinitionApiResponse> {
      return this.apiClient.callHttpPost({
        path: `/${advertiserId}/integration`,
        param: payload,
        type: 'accounts',
      });
  }

  editAccountIntegration(
    advertiserId: string,
    payload: Partial<IntegrationDefinition>): Observable<IntegrationDefinitionApiResponse> {
      return this.apiClient.callHttpPut({
        path: `/${advertiserId}/integration/${payload.IntegrationId}`,
        param: payload,
        type: 'accounts',
      });
  }

  deleteAccountIntegration(advertiserId: string, integrationId: string) {
    return this.apiClient.callHttpDelete({
      path: `/${advertiserId}/integration/${integrationId}`,
      type: 'accounts',
    });
  }

  runIntegration(advertiserId: string, integrationId: string): Observable<void> {
    return this.apiClient.callHttpPostWithoutResponse({
      path: `/${advertiserId}/integration/${integrationId}/runimmediately`,
      type: 'accounts',
      param: {}});
  }

  uploadFile(payload: FormData, id: string, intId: string): Observable<void> {
    const api_url = `/advertisers/${id}/manualfileupload/${intId}/upload`;
    return this.apiClient.callHttpPostWithoutResponse({path: api_url, param: payload, headers: createUploadHeaders()});
  }

  uploadFileEmailManifest(payload: FormData, id: string, intId: string): Observable<void> {
    const api_url = `/advertisers/${id}/emailmanifestupload/${intId}/upload`;
    return this.apiClient.callHttpPostWithoutResponse({path: api_url, param: payload, headers: createUploadHeaders()});
  }
}
