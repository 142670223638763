import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { environment } from '../../../environments/environment';
import { createAuthHeaders } from '../helpers/createHeaders';
import {
  ChangePasswordPayload,
  ResetPasswordTokenCheckPayload,
  ResetPasswordPayload,
  SetPasswordPayload,
} from '../../shared/interfaces';
import { CheckResetPasswordTokenResponse, DecodeTokenResponse } from '../../shared/interfaces/auth.interface';

@Injectable()
export class AccountService {
  public apiClient = <ApiClient>{};

  constructor() {}

  changePassword(payload: ChangePasswordPayload): Observable<void> {
    this.apiClient.setBaseUrl(environment.authBase);
    return this.apiClient.callHttpPost({
      path: `/Account/ChangePassword`,
      version: '',
      param: payload,
    });
  }

  resetPassword(payload: ResetPasswordPayload): Observable<void> {
    this.apiClient.setBaseUrl(environment.authBase);
    return this.apiClient.callHttpPostWithoutResponse({
      path: `/Account/ResetPassword`,
      version: '',
      param: payload,
      headers: createAuthHeaders(),
    });
  }

  checkResetPasswordToken(
    payload: ResetPasswordTokenCheckPayload
  ): Observable<CheckResetPasswordTokenResponse> {
    this.apiClient.setBaseUrl(environment.authBase);
    return this.apiClient.callHttpPost({
      path: `/Account/CheckToken`,
      param: payload,
      version: '',
      headers: createAuthHeaders(),
    });
  }

  setPassword(payload: SetPasswordPayload): Observable<void> {
    this.apiClient.setBaseUrl(environment.authBase);
    return this.apiClient.callHttpPost({
      path: '/Account/SetPassword',
      param: payload,
      version: '',
    });
  }

  decodeToken(token: string): Observable<DecodeTokenResponse> {
    this.apiClient.setBaseUrl(environment.authBase);
    return this.apiClient.callHttpGet({
      path: `/password/set/validate-token/${token}`,
      version: '',
    });
  }

  setNewPassword(
    payload: SetPasswordPayload,
    setPasswordToken: string
  ): Observable<void> {
    this.apiClient.setBaseUrl(environment.authBase);
    return this.apiClient.callHttpPost({
      path: `/password/set/${setPasswordToken}`,
      param: payload,
      version: '',
    });
  }
}
