import { isPlatformBrowser, PlatformLocation } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, SubscriptionLike as ISubscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { environment } from '@environments/environment';
import { ApiService } from '@services/api/api.service';
import { AuthService } from '@services/auth/auth.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { LoginService } from '@services/login/login.service';
import { UtilityService } from '@services/utility/utility.service';
import { AuthFacade } from '@shared/state/auth';
import { LoginPayload } from '@shared/interfaces';
import { ExternalLogin } from '@shared/interfaces/auth.interface';
import { CommonUtilityService } from '@services/utility/common-utility.service';
import { AppState } from '@shared/state/state';
import { selectAuthStateAuthenticated, selectAuthStateAuthError, selectAuthStateLoading } from '@shared/state/auth/auth.selector';
import { LiveChatService } from '@services/live-chat/live-chat.service';
interface ExternalLoginMap {
  [key: string]: ExternalLogin;
}

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  logo = environment.defaultLogo;
  baseUrl = environment.authBase;
  appServer = '';
  auth: ExternalLoginMap = {}; // For OAuthProviders
  error = '';
  errors = {
    username: false,
    password: false,
  };
  isLoading = false;
  localLogin = false;
  user = {
    username: '',
    password: '',
    client_id: 'acnmanagementapp',
    client_secret: '@cn16pPr0v1d3rManagementApp',
    scopes: 'openid managementappapi',
    grant_type: 'password',
  };
  private authSubscription: ISubscription | undefined;
  isBrowser: boolean;
  @ViewChild('loader', { static: true }) private loaderElement:
    | ElementRef
    | undefined;
  pageLoaded = false;
  authenticated: boolean|undefined;
  loading$: Observable<boolean>;
  authError$: Observable<string | undefined>;
  constructor(
    private authService: AuthService,
    private loginService: LoginService,
    private utilityService: UtilityService,
    private commonUtility: CommonUtilityService,
    private authFacade: AuthFacade,
    private apiClient: ApiService,
    private store: Store<AppState>,
    private errorHandler: ErrorHandlerService,
    private platformLocation: PlatformLocation,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.appServer = this.isBrowser
      ? (platformLocation as any).location.origin
      : '';
    this.apiClient.errorHandler = this.errorHandler;
    this.authService.apiClient = this.apiClient;
    this.loginService.apiClient = this.apiClient;
    this.loading$ = this.store.select(selectAuthStateLoading);
    this.authError$ = this.store.select(selectAuthStateAuthError);
  }

  ngOnInit() {
    LiveChatService.anonymous();
    this.authenticatFromToken();
    this.authSubscription = this.store.select(selectAuthStateAuthenticated).subscribe((authenticated) => {
      this.authenticated = authenticated;
      if (authenticated) {
        this.router.navigate(['dashboard']);
      }
    });
    this.setGoogleAuth();
    this.getUser();
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  getUser(): void {
    if (this.isBrowser) {
      if (this.authService.getToken()) {
        this.authService.getUserInfo().subscribe({
          next: (user) => {
            if (user) {
              this.router.navigate(['dashboard']);
            } else {
              this.pageLoaded = true;
              this.removeLoader();
            }
          },
          error: () => {
            this.pageLoaded = true;
            this.removeLoader();
          }
        });
      } else {
        this.pageLoaded = true;
        this.removeLoader();
      }
    }
  }

  private removeLoader() {
    if (this.loaderElement) {
      this.loaderElement.nativeElement.remove();
    }
  }

  authenticatFromToken() {
    const hash = this.isBrowser
      ? (this.platformLocation as any).location.hash
      : '';
    if (hash && hash !== '') {
      const params = this.utilityService.string2array(hash, '&');
      if (params && params.length > 0) {
        const token = this.utilityService.string2array(params[0], '=');
        this.authService.setToken(token[1]);
        if (this.isBrowser) {
          this.clearCachedUserRoleData();
        }
      }
    }
  }

  clearCachedUserRoleData() {
    const notifications = localStorage.getItem('notifications');
    const laskAckId = localStorage.getItem('laskAckId');
    const layoutStatic = localStorage.getItem('layoutStatic');
    localStorage.clear();
    localStorage.setItem('notifications', notifications || '[]');
    localStorage.setItem('laskAckId', laskAckId || '');
    localStorage.setItem('layoutStatic', layoutStatic || 'true');
  }

  formatValues() {
    return this.user;
  }

  setGoogleAuth() {
    this.loginService.getAuthLink().subscribe((data) => {
      this.auth = data.reduce((memo, auth) => {
        const provider = auth.Name.toLowerCase();
        memo[provider] = auth;
        return memo;
      }, <ExternalLoginMap>{});
    });
  }

  checkValidation(payload: LoginPayload) {
    let valid = true;

    if (this.commonUtility.isEmail(payload.username)) {
      this.errors.username = false;
    } else {
      this.errors.username = true;
      valid = false;
    }

    if (this.commonUtility.isPassword(payload.password)) {
      this.errors.password = false;
    } else {
      this.errors.password = true;
      valid = false;
    }

    return valid;
  }

  showLocalLogin() {
    this.localLogin = true;
  }

  login() {
    const payload: LoginPayload = this.formatValues();
    this.isLoading = true;
    this.error = '';

    if (this.checkValidation(payload)) {
      this.authFacade.authenticate(payload);
    } else {
      this.isLoading = false;
      this.error = 'There was a problem with your request.  Please try again.';
    }
  }

  doGoogleAuth(auth: ExternalLogin) {
    window.location.href = this.getGoogleAuthLink(auth);
  }

  getGoogleAuthLink(auth: ExternalLogin): string {
    return `${this.baseUrl}${auth.Url}`;
  }

  refresh(): void {
    window.location.reload();
  }
}
