import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import {
  campaignAdSettingsReducer,
  CampaignAdSettingsFacade,
} from './campaign';

import {
  authReducer,
  AuthFacade,
  AuthErrorHandlerFacade,
  AUTH_LOGOUT,
} from './auth';

import { managementReducer, ManagementFacade } from './account';

import {
  creativedashboardReducer,
  CreativedashboardFacade,
  CreativeDetailsFacade,
  creativeDetailsReducer,
  CreativeAnalysisFacade,
  creativeAnalysisReducer,
} from './creativedashboard';
import { AccountFacade, accountReducer } from './global';
import { BuilderFacade, builderReducer } from './query-builder';
import { PermissionsFacade, permissionsReducer } from './permissions';
import { LocationsFacade, locationsReducer } from './locations';
import { TaskFacade, taskReducer } from './tasks';
import { integrationsReducer, IntegrationsFacade } from './integrations';
import {
  PushNotificationFacade,
  pushNotificationReducer,
} from './push-notification';
import { DashboardFacade, dashboardReducer } from './dashboard';
import {
  SideMenuStatusFacade,
  sideMenuStatusReducer,
} from './side-menu-status';
import { CampaignEffects } from './campaign/campaign.effects';
import { IntegrationEffects } from './integrations/new/integrations.effects';
import { advertiserReducer } from './advertisers/accounts.reducer';
import { AccountEffects } from './advertisers/accounts.effects';
import { analyticsReducer } from './analytics/analytics.reducer';
import { AnalyticsEffects } from './analytics/analytics.effects';
import { datasourcesReducer } from './data-sources/data-sources.reducer';
import { DataSourcesEffects } from './data-sources/data-sources.effects';
import { FitEffects } from './fit/fit.effects';
import { EntityStoreModule } from './entity/entity-store.module';
import { AppSelectors } from './entity/app-selectors';
import { ModuleConfigEffects } from './module-config/module-config.effects';
import { GlobalFilterFacade, globalFilterReducer } from './global-filter';

export function clearState(reducer: any) {
  return function (state: any, action: any) {
    if (action.type === AUTH_LOGOUT) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const AppState = {
  advertiser: advertiserReducer,
  analytics: analyticsReducer,
  creativedashboard: creativedashboardReducer,
  creativeDetails: creativeDetailsReducer,
  creativeAnalysis: creativeAnalysisReducer,
  campaignAdSettings: campaignAdSettingsReducer,
  auth: authReducer,
  management: managementReducer,
  account: accountReducer,
  builder: builderReducer,
  permissions: permissionsReducer,
  locations: locationsReducer,
  tasks: taskReducer,
  integrations: integrationsReducer,
  pushNotification: pushNotificationReducer,
  dashboard: dashboardReducer,
  sideMenuStatus: sideMenuStatusReducer,
  dataSources: datasourcesReducer,
  globalFilter: globalFilterReducer
};
@NgModule({
  imports: [
    EffectsModule.forRoot([
      AccountEffects,
      AnalyticsEffects,
      CreativedashboardFacade,
      CreativeDetailsFacade,
      CreativeAnalysisFacade,
      CampaignAdSettingsFacade,
      CampaignEffects,
      DataSourcesEffects,
      FitEffects,
      IntegrationEffects,
      AuthFacade,
      AuthErrorHandlerFacade,
      ManagementFacade,
      AccountFacade,
      BuilderFacade,
      PermissionsFacade,
      LocationsFacade,
      TaskFacade,
      IntegrationsFacade,
      PushNotificationFacade,
      DashboardFacade,
      SideMenuStatusFacade,
      GlobalFilterFacade
    ]),

    StoreModule.forRoot(AppState as any, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
      metaReducers: [clearState],
    }),
    EntityStoreModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
  ],
  providers: [
    AccountEffects,
    AnalyticsEffects,
    CreativedashboardFacade,
    CreativeDetailsFacade,
    CreativeAnalysisFacade,
    CampaignAdSettingsFacade,
    CampaignEffects,
    DataSourcesEffects,
    FitEffects,
    IntegrationEffects,
    AuthFacade,
    AuthErrorHandlerFacade,
    ManagementFacade,
    AccountFacade,
    BuilderFacade,
    PermissionsFacade,
    LocationsFacade,
    TaskFacade,
    IntegrationsFacade,
    PushNotificationFacade,
    DashboardFacade,
    ModuleConfigEffects,
    SideMenuStatusFacade,
    AppSelectors,
    GlobalFilterFacade
  ],
})
export class AppStateModule {
  constructor() {}
}
