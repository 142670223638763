import { AppState } from '../state';
import * as DashboardActions from './dashboard.actions';
import { DashboardState } from './dashboard.model';

export type Action = DashboardActions.All;

export namespace DashboardQuery {
  export const dashboard = (state: AppState) => state.dashboard;
}

/// Reducer function
export function dashboardReducer(state: DashboardState, action: Action) {
  switch (action.type) {
    case DashboardActions.DASHBOARD_EXECUTE_QUERY_SUCCESS:
      return {
        ...state,
        [action.queryType]: action.payload,
        [action.queryType + 'Loaded']: true,
        status: action.type,
      };
    case DashboardActions.DASHBOARD_EXECUTE_QUERY_FAILED:
      return {
        ...state,
        [action.queryType + 'Error']: action.errorMessage,
        [action.queryType + 'Loaded']: true,
        status: action.type,
      };
    default:
      return state;
  }
}
