import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { SubscriptionLike } from 'rxjs';
import { ModuleConfig } from '../../shared/interfaces/common.interface';
import { RouteUrlDetails } from '../../shared/interfaces/routes.interface';
import { selectModuleConfigAndStatus } from '../../shared/state/module-config/module-config.data.selectors';
import { AppState } from '../../shared/state/state';

@Injectable({ providedIn: 'root' })
export class RouteStateService {
  pathSnapshot: ActivatedRouteSnapshot | undefined;
  generatedUrl: RouteUrlDetails | undefined;
  moduleSubscription: SubscriptionLike;
  moduleLoadingComplete = false;
  permissions: ModuleConfig[] = [];
  constructor(private store: Store<AppState>) {
    this.moduleSubscription = this.store
      .select(selectModuleConfigAndStatus)
      .subscribe(({ hasLoaded, isLoading, permissions }) => {
        this.moduleLoadingComplete = hasLoaded && !isLoading;
        if (this.moduleLoadingComplete) {
          this.permissions = permissions;
        }
      });
  }

  updatePathSnapshot(pathSnapshot: ActivatedRouteSnapshot) {
    this.pathSnapshot = pathSnapshot;
  }

  generateUrl(): RouteUrlDetails | undefined {
    if (this.pathSnapshot) {
      const { parent, paramMap, data } = this.pathSnapshot;
      if (data?.module) {
        const hasClaim = this.permissions.find(
          (permission) => permission.ModuleFriendlyName === data.module
        );
        if (hasClaim) {
          this.generatedUrl = {
            root: parent?.url[0].path || '',
            paramMap,
            redirectToDashboard: false,
          };
          return this.generatedUrl;
        } else {
          return {
            root: '',
            paramMap,
            redirectToDashboard: true,
          };
        }
      }
    }
    return;
  }
}
