import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { StateService } from '../state/state.service';
import {
  AccountGroup,
  SearchResult,
  AdvertiserPayload,
  ObjectIndexer,
  AccountsAPIResponse,
  AccountAPIResponse,
  Account,
  AccountGroupAPIResponse,
  AccountGroupsAPIResponse,
} from '../../shared/interfaces';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { ModuleConfig } from '../../shared/interfaces/common.interface';

declare var __: any;
interface AccountCache
  extends ObjectIndexer<
    | undefined
    | boolean
    | number
    | Account
    | Account[]
    | AccountGroup
    | AccountGroup[]
    | {
        Name: string;
        Type: string;
      }
  > {
  navUpdated: boolean;
  lastModified?: number;
  activeSelection: {
    Name: string;
    Type: string;
  };
  // Active Selections - Nav Tree
  activeAdvertiser: Account;
  activeAdvertiserGroup: AccountGroup;
  // Service Cache
  advertiserGroups: AccountGroup[];
  advertisers: Account[];
  advertiserRooftop: AccountGroup[];
}

@Injectable()
export class AdvertiserService {
  public apiClient = <ApiClient>{};
  cache: AccountCache = {
    navUpdated: false,
    lastModified: undefined,
    activeSelection: {
      Name: '',
      Type: '',
    },
    // Active Selections - Nav Tree
    activeAdvertiser: <Account>{},
    activeAdvertiserGroup: <AccountGroup>{},
    // Service Cache
    advertisers: [],
    advertiserGroups: [],
    advertiserRooftop: [],
  };

  cache2 = new BehaviorSubject<AccountCache>(this.cache);

  keyname = {
    advertisers: 'advertiser_',
  };

  constructor(private stateService: StateService) {
    this.cache2.next(this.cache);
  }

  loadSavedData() {
    const savedGroup = __.storage.get('activeAdvertiserGroup');
    const savedAdvertiser = __.storage.get('activeAdvertiser');
    const activeSelection = __.storage.get('activeSelection');

    if (!this.cache.activeAdvertiserGroup['GroupId']) {
      this.cache.activeAdvertiserGroup =
        savedGroup && savedGroup.GroupId ? savedGroup : {};

      if (!this.cache.activeAdvertiser['AccountId']) {
        this.cache.activeAdvertiser =
          savedAdvertiser && savedAdvertiser.AccountId ? savedAdvertiser : {};
      }
      if (!this.cache.activeSelection.Type) {
        this.cache.activeSelection =
          activeSelection && activeSelection.Type ? activeSelection : {};
      }
    }
  }

  refreshCache() {
    this.cache.lastModified = new Date().getTime();
  }

  wipeActiveAdvertisers() {
    __.storage.set('activeAdvertiserGroup', {});
    __.storage.set('activeAdvertiser', {});
    __.storage.set('activeSelection', {});

    // Active Selections
    this.cache.activeAdvertiserGroup = <AccountGroup>{};
    this.cache.activeAdvertiser = <Account>{};
    this.cache.activeSelection = {
      Name: '',
      Type: '',
    };
    // Cache
    this.cache.advertisers = [];
    this.cache.advertiserGroups = [];
    this.cache.advertiserRooftop = [];
    // this.cache2.next(this.cache);
  }

  setActiveAdvertiserGroup(item: AccountGroup) {
    __.storage.set('activeAdvertiserGroup', item);
    this.cache.activeAdvertiserGroup = item;

    this.updateActiveSelection('group');
  }

  setActiveAdvertiser(item: Account) {
    __.storage.set('activeAdvertiser', item);
    this.cache.activeAdvertiser = item;

    this.updateActiveSelection('advertiser');
  }

  updateActiveSelection(type: string) {
    const cache = cloneDeep(this.cache);

    if (
      type === 'advertiser' &&
      cache.activeAdvertiser &&
      cache.activeAdvertiser['Name']
    ) {
      cache.activeSelection.Name = cache.activeAdvertiser['Name'];
      cache.activeSelection.Type = 'Advertiser';
    } else if (
      type === 'group' &&
      cache.activeAdvertiserGroup &&
      cache.activeAdvertiserGroup['Name']
    ) {
      cache.activeSelection = {
        Name: cache.activeAdvertiserGroup['Name'],
        Type: 'Advertiser Group',
      };
    }
    __.storage.set('activeSelection', cache.activeSelection);
  }

  getAdvertiserName() {
    const ac = this.cache;
    return ac.activeAdvertiser['Name']
      ? ac.activeAdvertiser['Name']
      : ac.advertiserRooftop.length
      ? ac.advertiserRooftop[ac.advertiserRooftop.length - 1].Name
      : null;
  }

  getAdvertisersByGroup(groupId: string): Observable<AccountsAPIResponse> {
    return this.apiClient.callHttpGet({
      path: `/${groupId}/accounts`,
      type: 'accountgroups',
    });
  }

  search(query: string): Observable<SearchResult> {
    return this.apiClient.callHttpGet({ path: `/search/${query}` });
  }

  config(payload: AdvertiserPayload): Observable<ModuleConfig[]> {
    return this.apiClient.callHttpGet({
      path: `/config/${payload.groupId}/advertisers/${payload.advertiserId}`,
    });
  }

  getAdvertiser(accountId: string): Observable<AccountAPIResponse> {
    return this.apiClient.callHttpGet({
      path: `/accounts/${accountId}`,
      type: ''
    });
  }

  addAdvertiser(payload: Partial<Account>): Observable<AccountAPIResponse> {
    return this.apiClient.callHttpPost({
      path: '/accounts',
      param: payload,
      type: ''
    });
  }

  editIndirectAdvertiser(payload: Account): Observable<AccountAPIResponse> {
    return this.apiClient.callHttpPut({
      path: `/accountgroups/${payload.AccountGroupId}/accounts/${payload.AccountId}`,
      param: payload,
      type: ''
    });
  }

  editIndirectAccounts(groupId: string, payload: Account[]): Observable<AccountAPIResponse> {
    return this.apiClient.callHttpPut({
      path: `/accountgroups/${groupId}/accounts`,
      param: payload,
      type: ''
    });
  }

  deleteAdvertiser(id: string): Observable<void> {
    return this.apiClient.callHttpDelete({
      path: `/accounts/${id}`,
      type: ''
    });
  }

  getAdvertiserGroups(): Observable<AccountGroupsAPIResponse> {
    return this.apiClient
      .callHttpGet({
        path: `/accountgroups`,
        type: ''
      })
      .pipe(tap((data) => (this.cache.advertiserGroups = data.data)));
  }

  getAdvertiserGroup(groupId: string): Observable<AccountGroupAPIResponse> {
    return this.apiClient.callHttpGet({
      path: `/accountgroups/${groupId}`,
      type: ''
     });
  }

  addAdvertiserGroup(payload: AccountGroup) {
    return this.apiClient.callHttpPost({
      path: '/accountgroups',
      param: payload,
      type: ''
    });
  }

  editAdvertiserGroup(groupId: string, payload: AccountGroup) {
    return this.apiClient
      .callHttpPut({
        path: `/accountgroups/${groupId}`,
        param: payload,
        type: ''
     })
      .pipe(tap(() => this.refreshCache()));
  }

  deleteAdvertiserGroup(groupId: string) {
    return this.apiClient.callHttpDelete({
      path: `/accountgroups/${groupId}`,
      type: ''
    });
  }
}
