import { Injectable } from '@angular/core';
import { isDate, isEqual, isNumber, isObject } from 'lodash-es';

@Injectable()
export class CommonUtilityService {


  public static compareObjects(c1: any,c2: any): boolean {
    return isEqual(c1, c2);
  }

  static timestampStateData(data: any) {
    return data.dateLoaded ? data : { ...data, dateLoaded: new Date() };
  }

  isNotEmpty(str: string) {
    return (str && str.length) ? true : false;
  }
  isAlphaNumeric(str: string) {
    const pattern = /[A-Za-z0-9_.\-]/g;
    return pattern.test(str);
  }
  isNumeric(str: string) {
    return isNumber(str);
  }
  isDate(str: string) {
    return isDate(str);
  }
  isObject(obj: string) {
    return isObject(obj);
  }
  isEmail(str: string) {
    const pattern = /[A-Za-z0-9_\-.]+@+[A-Za-z0-9_\-.]+/g;
    return pattern.test(str);
  }
  isPassword(str: string) {
    return (str && str.length > 7);
  }
  isUrl(str: string) {
    const pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g;
    return pattern.test(str);
  }
  /**
       * @ngdoc method
       * @name isIE
       * @methodOf webApp.utilityFactory
       * @description Checks useragent specifically for IE.
       *
       * @return {boolean} isIE MSIE, Trident, Edge
       */
  isIE() {
    const ua = window.navigator.userAgent.toLowerCase();
    const apps = ['MSIE', 'Trident/', 'Edge/'];
    let result = false;

    apps.forEach((app) => {
      if (ua.indexOf(app.toLowerCase()) > -1) {
        result = true;
      }
    });
    return result;
  }

  sortBy(arr: any[], val: string, desc: boolean) {
    if (!arr.length) {
      return arr;
    }

    const result = arr.sort((a, b) => {
      const aName = a[val];
      const bName = b[val];

      if (desc) {
        if (typeof aName === 'string' && typeof bName === 'string') {
          return (aName.toLowerCase() > bName.toLowerCase()) ? -1 : (aName.toLowerCase() < bName.toLowerCase()) ? 1 : 0;
        }
        return (aName > bName) ? -1 : (aName < bName) ? 1 : 0;
      }
      if (typeof aName === 'string' && typeof bName === 'string') {
        return (aName.toLowerCase() > bName.toLowerCase()) ? 1 : (aName.toLowerCase() < bName.toLowerCase()) ? -1 : 0;
      }
      return (aName > bName) ? 1 : (aName < bName) ? -1 : 0;

    });
    return result;
  }

  /**
   * doSimpleArray
   * @description Takes object and returns a simple array of strings
   * @param arrObj
   * @param name
   * @returns {Array|*}
   */
  doSimpleArray(arrObj: any[], name: string|number) {
    if (!arrObj.length) {
      return [];
    }

    return arrObj.map((arr) => {
      return (arr[name]) ? arr[name] : null;
    });
  }

  /**
   * doFilterBy
   * @param arrObj
   * @param name
   * @param value
   * @returns {*}
   */
  doArrayFilterBy(arrObj: any[], name: string|number, value: any) {
    if (!arrObj.length) {
      return [];
    }

    return arrObj.reduce((memo, arr) => {
      if (arr[name] === value) {
        memo.push(arr);
      }
      return memo;
    }, []);
  }

  /**
    *
    * @param arrObj
    * @param name
    * @param value
    * @returns {*|number}
    */
  doArrayCount(arrObj: any[], name: string|number, value: any) {
    const result = this.doArrayFilterBy(arrObj, name, value);
    return result.length || 0;
  }

  doFlattenArray(arrObj: any[]) {
    if (!arrObj.length) {
      return [];
    }

    return arrObj.reduce((a, b) => {
      return a.concat(b);
    });
  }

  /**
   * doDefinitionObject
   * @description Returns a definition list based on name context
   * @param arrObj
   * @param name
   * @returns {*}
   */
  doDefinitionObject(arrObj: any[], name: string) {
    if (!arrObj) {
      return [];
    }

    const result = arrObj.reduce((memo, arr) => {
      if (typeof arr[name] === 'undefined') {
        return memo;
      }
      memo[arr[name]] = arr;
      return memo;
    }, {});

    return result;
  }
    /**
   * doObjectPluck
   * @description
   * @param obj
   * @param name
   * @returns {{}}
   */
  doObjectPluck(obj: any, name: number|string) {
    return ((obj && name) && obj[name]) ? obj[name] : {};
  }

  doArrayList(arr: any) {
    return Array.prototype.slice.call(arr);
  }

  /**
 * truncateStr
 * @description
 * @param obj
 * @param name
 * @returns {{}}
 */
  truncateStr(str: string, len: number) {
    const maxLen = len || 50;
    return (str.length < maxLen) ? str : str.slice(0, maxLen);
  }

  isEmptyObject(obj: any) {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  downloadCSV(data: any[]) {
    let csv = '';
    for (let row = 0; row < data.length; row++) {
      const keysAmount = Object.keys(data[row]).length;
      let keysCounter = 0;

      // If this is the first row, generate the headings
      if (row === 0) {

        // Loop each property of the object
        // tslint:disable-next-line: forin
        for (const key in data[row]) {

          // This is to not add a comma at the last cell
          // The '\r\n' adds a new line
          csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n' );
          keysCounter++;
        }
        keysCounter = 0;
        // tslint:disable-next-line: forin
        for (const key in data[row]) {
          csv += data[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n' );
          keysCounter++;
        }
      } else {
        // tslint:disable-next-line: forin
        for (const key in data[row]) {
          csv += data[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n' );
          keysCounter++;
        }
      }

      keysCounter = 0;
    }
    const blob = new Blob(['\ufeff' + csv], {
      type: 'text/csv;charset=utf-8;',
    });
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1;
    navigator.userAgent.indexOf('Chrome') == -1;
    // if Safari open in new window to save file with random filename.
    if (isSafariBrowser) {
      downloadLink.setAttribute('target', '_blank');
    }
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute(
      'download',
      'download.csv'
    );
    downloadLink.style.visibility = 'hidden';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
