import { Injectable, Injector, ErrorHandler } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorHandlerService } from '../helpers/error-handler.service';
import { ConfirmationDialogContentComponent } from '../../components/directives/confirmation-dialog/confirmation-dialog.directive';
import { MatDialog } from '@angular/material/dialog';

/** Passes HttpErrorResponse to application-wide error handler */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private errorHandler: ErrorHandlerService, public dialog: MatDialog, ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      tap({
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            const appErrorHandler = this.injector.get(ErrorHandler);
            if (err.url === 'https://edge.fullstory.com/s/fs.js' && request.method === 'HEAD') {
              this.dialog.open(ConfirmationDialogContentComponent, {
                data: {
                  title: 'Oh No!',
                  message: 'It looks like you may have an ad blocker enabled. This may prevent Transitiv from working correctly in some cases. Please whitelist Transitiv in your ad blocker so that you may use the platform with no issues.',
                  acceptButton: 'Ok, got it!',
                  omitCancelButton: true
                },
              });
            } else if (!(request.headers as any).skipErrorHandling) {
              this.errorHandler.handleHttpError(err);
            }
          }
        }
      })
    );
  }
}
