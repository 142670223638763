import { Injectable } from '@angular/core';
import {
  LeaderboardFilter,
  LeaderboardV2Filtering,
  SelectedLocationFilters,
} from '../../shared/interfaces';

@Injectable()
export class GlobalFilterService {

  generateFitRangeFilter(selectedFitRange: number[]): LeaderboardFilter {
    return {
      Type: LeaderboardV2Filtering.OnFit,
      MinScore: selectedFitRange[0],
      MaxScore: selectedFitRange[1],
    };
  }

  generateAgeRangeFilter(selectedAgeRange: number[]): LeaderboardFilter {
    return {
      Type: LeaderboardV2Filtering.OnFranchiseAge,
      MinAge: selectedAgeRange[0],
      MaxAge: selectedAgeRange[1],
    };
  }

  generateRegionFilter(selectedRegions: string[]): LeaderboardFilter {
    return {
      Type: LeaderboardV2Filtering.OnRegionOrTerritory,
      Regions: selectedRegions,
    };
  }

  generateTagFilter(selectedTags: string[]): LeaderboardFilter {
    return {
      Type: LeaderboardV2Filtering.OnTags,
      Tags: selectedTags,
    };
  }

  generateFilterByLocations(
    selectedLocationFilters: SelectedLocationFilters
  ): LeaderboardFilter[] {
    let filters: LeaderboardFilter[] = [];
    const {
      isFilteringByFit,
      selectedFitRange,
      isFilteringByAge,
      selectedAgeRange,
      selectedRegions,
      selectedTags,
    } = selectedLocationFilters;
    if (isFilteringByFit) {
      filters.push(this.generateFitRangeFilter(selectedFitRange));
    }
    if (isFilteringByAge) {
      filters.push(this.generateAgeRangeFilter(selectedAgeRange));
    }
    if (selectedRegions.length) {
      filters.push(this.generateRegionFilter(selectedRegions));
    }
    if (selectedTags.length) {
      filters.push(this.generateTagFilter(selectedTags));
    }
    if (filters.length === 0) {
      filters.push({
        Type: LeaderboardV2Filtering.None,
      });
    }
    return filters;
  }
}
