import { Component, OnInit } from '@angular/core';
import { FieldClass } from '../field/field.class';
@Component({
  selector: 'app-slider',
  template: `
  <div class="col-md-12 nopadding form_slider_" [formGroup]="group">
    <app-form-slider color="primary" [formControlName]="field?.name" [id]="field?.id" (onChange)="onChange($event)"
    [label]="field?.label" [value]="field?.value" [max]="field?.max" [min]="field?.min" [step]="field?.step" [thumbLabel]="field?.thumbLabel"
    [tickInterval]="field?.tickInterval" [cssStyle]="field?.style"></app-form-slider>
  </div>`,
  styles: []
})
export class SliderComponent extends FieldClass implements OnInit {
  constructor() {
    super();
  }
  ngOnInit() {}
}
