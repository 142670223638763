<div
  *ngIf="authenticated"
  class="layout-sidebar"
  [ngClass]="{
    'layout-sidebar-active': app.sidebarActive,
    'layout-sidebar-dark': app.darkMenu
  }"
  (mouseover)="app.sidebarActive = true"
  (mouseleave)="app.sidebarActive = false"
>
  <div class="sidebar-logo text-center">
    <a routerLink="/dashboard">
      <img alt="logo" src="./assets/img/TransitivLogoWhite.svg" class="mainTransitivLogo" />
    </a>
    <a
      class="sidebar-anchor"
      title="Toggle Menu"
      (click)="app.onToggleMenuClick($event)"
      style="margin-right: 20px; margin-top: 9px;"
    ></a>
    <a class="t-white-logo" title="Toggle Menu"
      ><img
        alt="logo"
        src="./assets/img/Transitiv-square-icon-white-background.svg"
        id="transitiv-logo"
    /></a>
  </div>

  <div #layoutMenuScroller class="nano">
    <div class="nano-content sidebar-scroll-content">
      <div class="layout-menu-container" (click)="updateNanoScroll()">
        <ul
          app-submenu
          [item]="model"
          root="true"
          class="layout-menu"
          visible="true"
          [reset]="reset"
        ></ul>
      </div>
    </div>
  </div>
</div>
