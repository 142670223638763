import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {catchError, switchMap, map} from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { Observable ,  of } from 'rxjs';
import {AppState} from '../../state';
import {BuilderQuery} from './builder.reducer';
import * as BuilderActions from './builder.actions';
import { Builder, QueryConfig } from '..';
import { TableObject, schemaKeyMap, TableField, RuleSet, TableJoin,
  QueryFilterConfig, ParsedQueryObject, HavingExpression } from '../../../interfaces/tables.interface';
import { QueryService } from '@services/query/query.service';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { NotificationsService } from '@services/notification/notification.service';
import { UtilityService } from '@services/utility/utility.service';
import { RawSchema } from '../../../interfaces/query.interface';
import { ObjectIndexer } from '../../../interfaces';

type Action = BuilderActions.All;

@Injectable()
export class BuilderFacade {
  loadAdWithVariations = false;
  advertiserId = '';
  builder$ = this.store.select(BuilderQuery.builder);
  filter = ' ';

  saveQuery$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(BuilderActions.SAVE_BUILDER_SQL),
    map((action: BuilderActions.SaveBuilderSql) => action ),
    switchMap(action => this.queryService.saveQuery(action.id, action.name, {Query: action.payload.replace('TOP 10', '')}).pipe(
    map((query) => {
      this.notificationsService.notify({
        severity: 'success',
        detail: 'Query saved successfully.',
        summary: 'Query saved.',
        key: 'non-sticky'
      });
      this.setFormIsClean();
      return new BuilderActions.SaveBuilderSqlSuccess(query);
    }),
    catchError(err => of (new BuilderActions.SaveBuilderSqlFail(err.message)) ), )), ));

  editQuery$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(BuilderActions.EDIT_BUILDER_SQL),
    map((action: BuilderActions.EditBuilderSql) => action),
    switchMap(action => this.queryService.editQuery(action.id, action.name, {Query: action.payload.replace('TOP 10', '')}).pipe(
    map((query) => {
      this.notificationsService.notify({
        severity: 'success',
        detail: 'Query saved successfully.',
        summary: 'Query saved.',
        key: 'non-sticky'
      });
      this.setFormIsClean();
      return new BuilderActions.EditBuilderSqlSuccess(query);
    }),
    catchError(err => of (new BuilderActions.EditBuilderSqlFail(err.message)) ), )), ));

  parseSavedQuery$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(BuilderActions.PARSE_BUILDER_SQL),
    map((action: BuilderActions.ParseBuilderSql) => action),
    switchMap(action => this.queryService.parseQuery(action.id, {Query: action.payload}).pipe(
    map((query) => {
      return new BuilderActions.ParseBuilderSqlSuccess(query as ParsedQueryObject);
    }),
    catchError(err => of (new BuilderActions.ParseBuilderSqlFail(err.message)) ), )), ));

  executeQuery$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(BuilderActions.EXECUTE_BUILDER_SQL),
    map((action: BuilderActions.ExecuteBuilderSql) => action),
    switchMap(action => this.queryService.runQuery(action.id, {Query: action.payload}).pipe(
    map((query) => {
      this.notificationsService.notify({
        severity: 'success',
        detail: 'Query run successfully.',
        summary: 'Query run.',
        key: 'non-sticky'
      });
      return new BuilderActions.ExecuteBuilderSqlSuccess(query);
    }),
    catchError(err => {
      return of (new BuilderActions.ExecuteBuilderSqlFail(err._body));
    } ), )), ));

  getBuilderSchema$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(BuilderActions.GET_BUILDER_SCHEMA),
    map((action: BuilderActions.GetBuilderSchema) => action),
    switchMap(action => {
      return this.queryService.getSchema(action.payload).pipe(
      map((schema) => {
        const parsedSchema = this.parseSchema(schema);
        return new BuilderActions.GetBuilderSchemaSuccess(parsedSchema, schema);
      }),
      catchError(err => of (new BuilderActions.GetBuilderSchemaFail(err.message)) ), ); }
    ), ));

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationsService: NotificationsService,
    private queryService: QueryService,
    private utilityService: UtilityService,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.queryService.apiClient = this.apiClient;
  }

  private parseSchema(schema: RawSchema[]) {
    return this.utilityService.parseSchemas(schema, schemaKeyMap) as TableObject[];
  }

  resetBuilderState(checkState = false): void {
    if (checkState) {
      const state = this.getState();
      if (state?.rawSchema) {
        return;
      }
    }

    this.store.dispatch(new BuilderActions.ResetBuilderState());
  }

  getBuilderSchema(id: string, name = ''): void {
    const state = this.getState();
    if (!name) {
      if (state?.rawSchema?.length > 0 && state?.sql) {
        return;
      }
    } else if (state?.name === name && state?.sql) {
      return;
    }
    this.store.dispatch(new BuilderActions.GetBuilderSchema(id));
  }

  parseGeneratedSql(id: string, sql: string) {
    this.parseBuilderSql(id, sql);
    this.setBuilderAdvancedQuery(sql);
  }

  setBuilderParsed(): void {
    this.store.dispatch(new BuilderActions.SetBuilderParsed());
  }

  setBuilderAdvancedQuery(query: string): void {
    this.store.dispatch(new BuilderActions.SetBuilderAdvancedQuery(query));
  }

  setBuilderParsedQuery(query: RuleSet): void {
    this.store.dispatch(new BuilderActions.SetBuilderParsedQuery(query));
  }

  setBuilderFilterQuery(query: string): void {
    this.store.dispatch(new BuilderActions.SetBuilderFilterQuery(query));
    this.setGeneratedSql();
  }

  setBuilderTableStep(checkState = false): void {
    if (checkState) {
      const state = this.getState();
      if (state?.currentStep) {
        return;
      }
    }
    this.store.dispatch(new BuilderActions.SetBuilderStep('table-definition'));
  }

  setBuilderFilterStep(isDirty = true): void {
    this.store.dispatch(new BuilderActions.SetBuilderStep('filter'));
    if (isDirty) {
      this.setFormIsDirty();
    }
  }

  generateBuilderSql(query: RuleSet): void {
    this.store.dispatch(new BuilderActions.GenerateBuilderSql(query));
    this.setGeneratedSql();
  }

  setBuilderName(name: string, isDirty = true): void {
    this.store.dispatch(new BuilderActions.SaveBuilderName(name));
    if (isDirty) {
      this.setFormIsDirty();
    }
  }

  setFormIsDirty(): void {
    this.store.dispatch(new BuilderActions.SetBuilderDirty(true));
  }

  setFormIsClean(): void {
    this.store.dispatch(new BuilderActions.SetBuilderDirty(false));
  }

  setBuilderState(table: TableObject[], selectedFields: ObjectIndexer<TableField[]>, joins: TableJoin[], query: QueryFilterConfig): void {
    const config = this.generateFilterConfigFromTable(cloneDeep(table));
    this.store.dispatch(new BuilderActions.SetBuilderState(table, selectedFields, joins, config, query));
    this.setFormIsDirty();
  }

  setBuilderFilterConfig(table: TableObject[], selectedFields: ObjectIndexer<TableField[]>, joins: TableJoin[], isDirty = true): void {
    const config = this.generateFilterConfigFromTable(cloneDeep(table));
    this.store.dispatch(new BuilderActions.SetBuilderFilerConfig(table, selectedFields, joins, config));
    if (isDirty) {
      this.setFormIsDirty();
    }
    this.generateSQL();
  }

  setBuilderExtras(groupBy: TableField[], having: HavingExpression[]): void {
    this.store.dispatch(new BuilderActions.SetBuilderFilerExtras(groupBy, having));
    this.setFormIsDirty();
    this.setGeneratedSql();
  }

  private generateFilterConfigFromTable(tables: TableObject[]) {
    const config: QueryConfig = {
      fields: {}
    };
    tables.forEach(table => {
      table.fields.forEach(field => {
        field.name = '[' + table.alias + '].[' + field.name + ']';
        config.fields[field.name] = field;
      });
    });
    return config;
  }

  setGeneratedSql() {
    const q = this.generateSQL();
    this.store.dispatch(new BuilderActions.SetBuilderGeneratedSql(q));
  }

  generateQuery(id: string, preview = true) {
    const q = this.generateSQL(preview);
    this.parseBuilderSql(id, q);
    this.executeBuilderSql(id, q, preview);
  }

  private generateSQL(preview = true) {
    const state = this.getState();
    return this.utilityService.generateSQL(state, '');
  }

  saveBuilderSql(id: string) {
    const state = this.getState();
    const q = this.generateSQL();
    this.store.dispatch(new BuilderActions.SaveBuilderSql(state.name, id, q));
  }

  editBuilderSql(id: string) {
    const state = this.getState();
    const q = this.generateSQL();
    this.store.dispatch(new BuilderActions.EditBuilderSql(state.name, id, q));
  }

  saveBuilderRawSql(id: string, q: string) {
    const state = this.getState();
    this.store.dispatch(new BuilderActions.SaveBuilderSql(state.name, id, q));
  }

  editBuilderRawSql(id: string, q: string) {
    const state = this.getState();
    this.store.dispatch(new BuilderActions.EditBuilderSql(state.name, id, q));
  }

  parseBuilderSql(id: string, q: string) {
    this.store.dispatch(new BuilderActions.ParseBuilderSql(id, q));
  }

  executeBuilderSql(id: string, q: string, preview = true) {
    if (preview) {
      q = q.replace('SELECT', 'SELECT TOP 10');
    }
    this.store.dispatch(new BuilderActions.ExecuteBuilderSql(id, q));
  }

  private getState(): Builder {
    let state = <Builder>{};

    this.store.select(BuilderQuery.builder).subscribe(s => {
      state = s;
    }).unsubscribe();

    return state;
  }
}
