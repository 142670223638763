import { UntypedFormControl } from '@angular/forms';
import {
  ChartV2DataTable,
  ChartV2LineChart,
  ColumnAggregation,
} from './setup/directives/integration-form-types/dashboard-engine-v2-config/interface';

export interface DashboardDataSeries {
  Title: string;
  SourceQueryId: string;
  AggregatedColumn: string;
  Unit: number;
  AggregationType: number;
  SourceHasDateColumn: boolean;
  CustomGroupByColumn?: string;
}

export interface DashboardChart extends DashboardConfig {
  Type: number;
  Description: string | null;
  Series: DashboardDataSeries[];
  GroupByBehavior: DashboardGroupByBehavior;
}

export interface DashboardSection extends DashboardConfig {
  Charts: DashboardChart[];
}

export interface DashboardPage extends DashboardConfig {
  Sections: DashboardSection[];
}

export interface DashboardConfig {
  Title: string;
  Order: number;
  Id?: string;
  Width?: number;
  X?: number;
  Y?: number;
}

export interface DashboardLeaderboard {
  Title: string;
  Description?: string;
  Order: number;
  Id?: number;
  MaxFranchiseeRowCount: number;
  SourceQueryId: string;
  AggregatedColumn: string;
  Unit: DashboardColumnUnit;
  Aggregation: DashboardAggregationType;
  RankingType: ColorSchemeType;
}

export interface DashboardLeaderboardV2Config {
  Id?: number;
  Order: number;
  Title: string;
  Description: string;
  Schema: string;
  Table: string;
  Columns: DashboardLeaderboardV2ConfigColumn[];
  AccountIdColumn: string;
  DateColumn: string;
  UnitType: DashboardColumnUnit;
  MaxFranchiseeRowCount: number;
  RankingType?: RankingType
}

export interface DashboardLeaderboardV2ConfigColumn {
  ColumnName?: string;
  Aggregation: ColumnAggregation;
  DisplayColumnName?: string;
}

export interface HelperBoxSectionHeader {
  HeaderTitle: string;
  Descriptions: string[];
}

export interface HelperBoxSection {
  Title: string;
  Headers: HelperBoxSectionHeader[];
}

export interface FitDrillDownMetric {
  PageId?: string;
  MetricName: string;
  DashboardCharts: (ChartV2LineChart | ChartV2DataTable)[];
  LeaderboardChart: DashboardLeaderboardV2Config;
  HelperBoxSections: HelperBoxSection[];
}

export interface FitScoreConfigMetric {
  MetricName: string;
  DataSource: FitScoreConfigMetricDataSource;
  MetricWeight: number;
  Thresholds: number[];
  ThresholdOrdering: ThresholdOrdering;
  FitMetricWindowInDays: number;
}

export interface FitScoreConfigMetricDataSource {
  DataSourceId: string;
  AccountIdColumn: string;
  DateColumn: string;
  MetricColumn: FitScoreConfigMetricColumn;
  AggregationType: DashboardAggregationType;
  DataSourceSchema: string;
}

export interface FitScoreConfigMetricColumn {
  NumeratorColumnName?: string;
  DenominatorColumnName?: string;
  MetricColumnName?: string;
  Type: FitMetricColumnType;
}

export interface AccountSelectOption {
  name: string;
  id: string;
  selected: boolean;
  display: boolean;
}

export interface LocationAccountSelectOption extends AccountSelectOption {
  locations: AccountSelectOption[];
}

export interface ComponentChartFormSeries {
  title: UntypedFormControl;
  source: UntypedFormControl;
  column: UntypedFormControl;
  unit: UntypedFormControl;
  aggregation: UntypedFormControl;
  sourceHasDateColumn: UntypedFormControl;
  customGroupByColumn?: UntypedFormControl;
}

export interface ComponentChartForm {
  title: UntypedFormControl;
  description: UntypedFormControl;
  type: UntypedFormControl;
  groupByBehavior: UntypedFormControl;
  dataSeries: ComponentChartFormSeries[];
}

export interface MetricWeightFormData {
  name: string;
  value: UntypedFormControl;
}

export interface MetricThresholdFormData {
  name: string;
  thresholds: UntypedFormControl[];
  rangeDirection: UntypedFormControl;
}

export interface FitFranchiseeConfigData {
  FitScoreId: string;
  Metrics: FitFranchiseeConfigMetricData[];
}

export interface FitFranchiseeConfigMetricData {
  MetricName: string;
  Description: string;
  MetricUnitFormat: UnitType;
  MetricDisplayType: MetricDisplayType;
}

export enum FitMetricColumnType {
  Default = 0,
  Ratio = 1,
}

export enum DashboardChartType {
  TimeSeries = 0,
  NumberCard = 1,
  ScoreCard = 2,
  Column = 3,
  Pie = 4,
  Stack = 5,
  Histogram = 6,
  Zip = 7,
  Table = 8,
  Heatmap = 9,
  FranchiseEngagementScore = 10,
  DataQualityScore = 11,
}

export enum DashboardColumnUnit {
  Number = 0,
  Percent = 1,
  Dollars = 2,
  Duration = 3,
}

export enum DashboardGroupByBehavior {
  None = 0,
  Date = 1,
  Account = 2,
  Custom = 3,
}

export enum DashboardAggregationType {
  Count = 0,
  Sum = 1,
  Average = 2,
  CountDistinct = 3
}

export enum ColorSchemeType {
  RedYellowGreen = 0,
  GreenYellowRed = 1,
}

export enum RankingType {
  HigherIsBetter = 0,
  LowerIsBetter = 1,
}

export enum ThresholdOrdering {
  LowToHigh = 0,
  HighToLow = 1,
}

export enum UnitType {
  Number = 0,
  Percent = 1,
  Dollars = 2,
  Duration = 3,
  String = 4,
  Date = 5,
}

export enum DurationType {
  None = 0,
  Millisecond = 1,
  Second = 2,
  Minute = 3,
  Hour = 4,
  Day = 5,
}

export enum MetricDisplayType {
  Rolling30DayAverage = 0,
  Rolling30DayAggregate = 1,
}
