import { map, mergeMap, catchError, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { AppState } from '../state';
import * as DashboardActions from './dashboard.actions';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { DashboardQuery } from './dashboard.reducer';
import { CustomerService } from '@services/customer/customer.service';
import { MarketingSpendService } from '@services/marketSpend/marketspend.service';
import { QueryService } from '@services/query/query.service';
import { IntegrationsService } from '@services/integrations/integrations.service';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { MarkovResponse } from '../../interfaces/analytics.interface';
import { MarketingSpend } from '../../interfaces/market.interface';
import {
  MarketingSpendPayload,
  AttributionFilter,
  IntegrationDefinition,
  ChannelGrouping,
} from '../../interfaces';
import { LocationService } from '@services/location/location.service';
import { AdvertiserLocations } from '../../../components/locations/interfaces';
import { markovPayloadModel } from '../../models/markov-payload.model';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { EntityCollectionService, EntityCollectionServiceFactory } from '@ngrx/data';
import { DashboardDataService } from './dashboard.data.service';
import { EntityMap, QUERY_CHANNEL_GROUPING_COMPLETE_ACTION, QUERY_INTEGRATIONS_COMPLETE_ACTION, QUERY_LOCATIONS_COMPLETE_ACTION } from '../entity/entity-metadata';
import { RawSchema } from '../../interfaces/query.interface';
import { AccountIntegrationsDataService } from '../integrations/account/integrations.data.service';
import { MarkovDataService } from '../analytics/markov.data.service';
import { selectMarkovIntegrationsByType } from '../integrations/new/integrations.data.selectors';
import { LocationsDataService } from '../locations/locations.data.service';
import { selectHasSharedLocations } from '../locations/locations.data.selectors';
import { ChannelGroupingsDataService } from '../integrations/data/channel-groupings.data.service';
import { MarketingSpendDataService } from '../marketing-spend/marketing-spend.data.service';
type Action = DashboardActions.All;

@Injectable()
export class DashboardFacade {
  dashboard$ = this.store.select(DashboardQuery.dashboard);


  executeQueryForSegmentation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DashboardActions.DASHBOARD_EXECUTE_QUERY),
    map((action: DashboardActions.DashboardExecuteQuery) => action),
    mergeMap((action) => {
      let request;
      request = this.queryService.runQuery(action.id, action.payload);
      return request.pipe(
        map((data) => {
          return new DashboardActions.DashboardExecuteQuerySuccess(
            data,
            action.queryType
          );
        }),
        catchError((err) => {
          return of(new DashboardActions.DashboardExecuteQueryFailed(err));
        })
      );
    })
  ));

  dashboardService: EntityCollectionService<RawSchema>;
  integrationService: EntityCollectionService<IntegrationDefinition>;
  markovService: EntityCollectionService<MarkovResponse>;
  accountId = '';
  groupId = ''
  locationsService: EntityCollectionService<AdvertiserLocations>;
  channelGroupingService: EntityCollectionService<ChannelGrouping>;
  marketingSpendService: EntityCollectionService<MarketingSpend>;

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private customerService: CustomerService,
    private marketingSpendApiService: MarketingSpendService,
    private queryService: QueryService,
    private integrationsApiService: IntegrationsService,
    private analyticsService: AnalyticsService,
    private locationsApiService: LocationService,
    private dashboardApiService: DashboardService,
    private channelGroupingsDataService: ChannelGroupingsDataService,
    private dashboardDataService: DashboardDataService,
    private integrationsDataDataService: AccountIntegrationsDataService,
    private locationsDataDataService: LocationsDataService,
    private marketingSpendDataDataService: MarketingSpendDataService,
    private markovDataDataService: MarkovDataService,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.customerService.apiClient = this.apiClient;
    this.marketingSpendApiService.apiClient = this.apiClient;
    this.queryService.apiClient = this.apiClient;
    this.integrationsApiService.apiClient = this.apiClient;
    this.analyticsService.apiClient = this.apiClient;
    this.locationsApiService.apiClient = this.apiClient;
    this.dashboardApiService.apiClient = this.apiClient;

    this.channelGroupingService = this.entityCollectionServiceFactory.create<ChannelGrouping>(EntityMap.ChannelGrouping);
    this.dashboardService = this.entityCollectionServiceFactory.create<RawSchema>(EntityMap.Dashboard);
    this.integrationService = this.entityCollectionServiceFactory.create<IntegrationDefinition>(EntityMap.AccountIntegration);
    this.marketingSpendService = this.entityCollectionServiceFactory.create<MarketingSpend>(EntityMap.MarketingSpend);
    this.markovService = this.entityCollectionServiceFactory.create<MarkovResponse>(EntityMap.Markov);
    this.locationsService = this.entityCollectionServiceFactory.create<AdvertiserLocations>(EntityMap.Locations);

    this.channelGroupingsDataService.analyticsService = this.analyticsService;
    this.dashboardDataService.dashboardService = this.dashboardApiService;
    this.locationsDataDataService.locationService = this.locationsApiService;
    this.markovDataDataService.analyticsService = this.analyticsService;
    this.marketingSpendDataDataService.marketingSpendService = this.marketingSpendApiService;

    this.integrationService.entityActions$.subscribe(action => {
      if (action.type === QUERY_INTEGRATIONS_COMPLETE_ACTION) {
        this.store.pipe(select(selectMarkovIntegrationsByType())).pipe(first())
        .subscribe((markovPayloadData) => {
          if (markovPayloadData.length > 0 && this.accountId) {
            const channelData = markovPayloadData[0];
            let payload: AttributionFilter = cloneDeep(markovPayloadModel);
            payload.ChannelGrouping = channelData?.ChannelGroupings || [];
            this.getMarkovData(payload);
          } else if (this.groupId) {
            this.getLocationsData();
          }
        });
      }
    });

    this.locationsService.entityActions$.subscribe(action => {
      if (action.type === QUERY_LOCATIONS_COMPLETE_ACTION) {
        this.store.pipe(select(selectHasSharedLocations(this.accountId))).pipe(first())
        .subscribe((hasSharedLocations) => {
          if (hasSharedLocations && this.groupId) {
            this.getChannelGroupingData();
          }
        });
      }
    });

    this.channelGroupingService.entityActions$.subscribe(action => {
      if (action.type === QUERY_CHANNEL_GROUPING_COMPLETE_ACTION && this.accountId) {
        this.channelGroupingService.entities$.pipe(first())
        .subscribe((channelGrouping) => {
          if (channelGrouping) {
            let payload: AttributionFilter = cloneDeep(markovPayloadModel);
            payload.ChannelGrouping = channelGrouping || [];
            this.getMarkovData(payload);
          }
        });
      }
    });
  }

  getMarketingSpend(payload: MarketingSpendPayload): void {
    this.marketingSpendDataDataService.payload = payload;
    this.marketingSpendService.load();
  }

  executeQueryForSegmentation(
    payload: { Query: string },
    id: string,
    queryType: string
  ): void {
    this.store.dispatch(
      new DashboardActions.DashboardExecuteQuery(payload, id, queryType)
    );
  }

  getIntegrations(accountId: string, groupId: string): void {
    this.accountId = accountId;
    this.groupId = groupId;
    this.integrationsDataDataService.accountId = accountId;
    this.integrationService.load();
  }
  getMarkovData(payload: AttributionFilter): void {
    this.markovDataDataService.payload = payload;
    this.markovDataDataService.accountId = this.accountId;
    this.markovService.load();
  }

  getLocationsData(): void {
    this.locationsDataDataService.groupId = this.groupId;
    this.locationsService.load();
  }

  getChannelGroupingData(): void {
    this.channelGroupingsDataService.accountId = this.accountId;
    this.channelGroupingService.load();
  }

  getDashboardDataSeriesSchema(paramType: string, id: string): void {
    this.dashboardDataService.entityContext = paramType;
    this.dashboardDataService.entityId = id;
    this.dashboardService.load();
  }
}
