import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { isArray } from 'lodash-es';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { CampaignPayload, Campaign } from '../../shared/interfaces';
import { DateUtilityService } from '../utility';

@Injectable()
export class CampaignService {
  public apiClient = <ApiClient>{};

  pacing = [
    { Name: 'Even', Value: 0 },
    { Name: 'Asap', Value: 1 },
    { Name: 'RampUp', Value: 2 },
    { Name: 'RampDown', Value: 3 },
    { Name: 'Arc', Value: 4 },
    { Name: 'Bow', Value: 5 }
  ];

  frequencyUom = [
    { Name: 'Hourly', Value: 1 },
    { Name: 'Daily', Value: 2 },
    { Name: 'Weekly', Value: 3 },
    { Name: 'Monthly', Value: 4 }
  ];

  frequency = this.frequencyUom;

  tactic = [
    { Name: 'Prospecting', Value: 1 },
    { Name: 'Retargeting', Value: 2 }
  ];

  cache = {
    activeCampaign: {}
  };

  constructor(private dateUtility: DateUtilityService) { }

    /**
        * @ngdoc method
        * @name postFormatCampaigns
        * @methodOf app.home.campaignService
        * @description Post service format the campaign end/start dates
        * and test for persistent status.
        *
        * @param {array} campaigns campaigns data
        * @return {array} campaigns formatted
        *
        */
  postFormatCampaigns(campaigns: Campaign[]) {
    // console.log('postFormatCampaigns', campaigns);
    if (!isArray(campaigns)) {
      // console.log('isArray');
    }


    return campaigns.map((camp) => {
      const isPersistent = this.dateUtility?.isPersistent(camp.CampaignStart, camp.CampaignEnd);
      const persistentType = isPersistent ? 'Persistent' : 'Set';


      const dateData = {
        Start: (new Date(camp.CampaignStart).getTime()) ? moment(camp.CampaignStart).format('M/D/YY') : null,
        End: (new Date(camp.CampaignEnd).getTime()) ? moment(camp.CampaignEnd).format('M/D/YY') : null,

        Type: persistentType,

        Persistent: isPersistent
      };
      camp.dateData = dateData;
      return camp;
    });
  }

  /**
  * @ngdoc method
  * @name getCampaigns
  * @methodOf app.home.campaignService
  * @description getCampaigns
  *
  */
  getCampaigns(id: string): Observable<Campaign[]> {
    return this.apiClient.callHttpGet({path: `/advertisers/${id}/campaigns`});
  }

  /**
  * @ngdoc method
  * @name getCampaigns
  * @methodOf app.home.campaignService
  * @description getCampaigns
  *
  */
  getCampaign(payload: Partial<CampaignPayload>): Observable<Campaign[]> {
    return this.apiClient.callHttpGet({path: `/advertisers/${payload.advertiserId}/campaigns/${payload.campaignId}`});
  }
  /**
  * @ngdoc method
  * @name addCampaign
  * @methodOf app.home.campaignService
  * @description addCampaign
  *
  */
  addCampaign(id: string, payload: CampaignPayload): Observable<Campaign> {
    return this.apiClient.callHttpPost({path: `/advertisers/${id}/campaigns`, param: payload});
  }

  /**
  * @ngdoc method
  * @name editCampaign
  * @methodOf app.home.campaignService
  * @description editCampaign
  *
  */
  editCampaign(id: string, campid: string, payload: CampaignPayload): Observable<Campaign> {
    return this.apiClient.callHttpPut({path: `/advertisers/${id}/campaigns/${campid}`, param: payload});
  }

  /**
  * @ngdoc method
  * @name deleteCampaign
  * @methodOf app.home.campaignService
  * @description deleteCampaign
  *
  */
  deleteCampaign(id: string, campid: string): Observable<void> {
    return this.apiClient.callHttpDelete({path: `/advertisers/${id}/campaigns/${campid}`});
  }
}
