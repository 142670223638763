import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';

import { LoginComponent } from './components/auth/login/login.component';
import { LogoutSuccessComponent } from './components/auth/logout-success/logout-success.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';

import { AuthGuard } from './components/shared/guard/auth.guard';
import { AppAuthComponent } from './app-auth.component';
import { AppMainComponent } from './app-main.component';

import { IndeedComponent } from './components/integrations/indeed/indeed.component';
import { WelcomeComponent } from './components/auth/welcome/welcome.component';
import { LinkedInComponent } from './components/integrations/linked-in/linked-in.component';
import { HighLevelCRMRedirectComponent } from './components/integrations/high-level-crm-redirect/high-level-crm-redirect.component';
import { franchisorDashboardModuleName } from './components/dashboards/media/franchisor/media-dashboard-franchisor.routing.module';
import { franchiseeDashboardModuleName } from './components/dashboards/media/franchisee/media-dashboard-franchisee.routing.module';

export const routes: Routes = [
  {
    path: '',
    component: AppAuthComponent,
    children: [
      { path: '', component: LoginComponent, data: { module: 'Auth' } },
    ],
  },
  {
    path: 'login',
    component: AppAuthComponent,
    children: [
      { path: '', component: LoginComponent, data: { module: 'Auth' } },
    ],
  },
  {
    path: 'logout',
    component: AppAuthComponent,
    children: [
      { path: '', component: LogoutSuccessComponent, data: { module: 'Auth' } },
    ],
  },
  {
    path: 'forgotpassword',
    component: AppAuthComponent,
    children: [
      {
        path: '',
        component: ForgotPasswordComponent,
        data: { module: 'Auth' },
      },
    ],
  },
  {
    path: 'resetpassword/:code/:email',
    component: AppAuthComponent,
    children: [
      { path: '', component: ResetPasswordComponent, data: { module: 'Auth' } },
    ],
  },
  {
    path: 'welcome/:token',
    component: AppAuthComponent,
    children: [
      { path: '', component: WelcomeComponent, data: { module: 'Auth' } },
    ],
  },
  {
    path: 'indeed-authorization/:id/:intid',
    component: AppAuthComponent,
    children: [
      { path: '', component: IndeedComponent, data: { module: 'Auth' } },
    ],
  },
  {
    path: 'indeed-authorization/:id',
    component: AppAuthComponent,
    children: [
      { path: '', component: IndeedComponent, data: { module: 'Auth' } },
    ],
  },
  {
    path: 'linked-in-authorization',
    component: AppAuthComponent,
    children: [
      { path: '', component: LinkedInComponent, data: { module: 'Auth' } },
    ],
  },
  {
    path: 'high-level-crm-authorization',
    component: AppAuthComponent,
    children: [
      { path: '', component: HighLevelCRMRedirectComponent, data: { module: 'Auth' } },
    ],
  },
  {
    path: 'logoutsuccess',
    component: AppAuthComponent,
    children: [
      { path: '', component: LogoutSuccessComponent, data: { module: 'Auth' } },
    ],
  },
  {
    path: '',
    component: AppMainComponent,
    data: { type: 'main' },
    children: [
      {
        path: 'adtag',
        loadChildren: () =>
          import('./components/adtags/adtag.module').then(
            (mod) => mod.AdtagModule
          ),
        canActivate: [AuthGuard],
        data: { module: 'Traffic Campaigns' },
      },
      {
        path: 'roadmap',
        loadChildren: () =>
          import('./components/roadmap/roadmap.module').then(
            (mod) => mod.RoadmapModule
          ),
        canActivate: [AuthGuard],
        data: { module: 'Auth' },
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./components/account/account.module').then(
            (mod) => mod.AccountModule
          ),
        data: { module: 'Auth' },
      },
      {
        path: 'fit',
        loadChildren: () =>
          import('./components/dashboards/fit/fit-dashboard.module').then(
            (mod) => mod.FitDashboardModule
          ),
        canActivate: [AuthGuard],
        data: { module: 'FIT Home' },
      },
      {
        path: 'leaderboards',
        loadChildren: () =>
          import('./components/dashboards/leaderboards/leaderboards.module').then(
            (mod) => mod.LeaderboardsModule
          ),
        canActivate: [AuthGuard],
        data: { module: 'Dashboard Engine' },
      },
      {
        path: 'dashboards',
        loadChildren: () =>
          import('./components/dashboards/dashboard-engine-v2/dashboard-engine-v2.module').then(
            (mod) => mod.DashboardEngineV2Module
          ),
        canActivate: [AuthGuard],
        data: { module: 'Dashboard Engine' },
      },
      {
        path: 'media-dashboard-franchisor',
        loadChildren: () =>
          import('./components/dashboards/media/franchisor/media-dashboard-franchisor.module').then(
            (mod) => mod.MediaDashboarFranchisordModule
          ),
        canActivate: [AuthGuard],
        data: { module: franchisorDashboardModuleName },
      },
      {
        path: 'media-dashboard-franchisee',
        loadChildren: () =>
          import('./components/dashboards/media/franchisee/media-dashboard-franchisee.module').then(
            (mod) => mod.MediaDashboarFranchiseedModule
          ),
        canActivate: [AuthGuard],
        data: { module: franchiseeDashboardModuleName },
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./components/dashboards/default-dashboard/default-dashboard.module').then(
            (mod) => mod.DefaultDashboardModule
          ),
        canActivate: [AuthGuard],
        data: { module: 'Dashboard' },
      },
      {
        path: 'locations',
        loadChildren: () =>
          import('./components/locations/locations.module').then(
            (mod) => mod.LocationsModule
          ),
        canActivate: [AuthGuard],
        data: { module: 'Location Management' },
      },
      {
        path: 'creatives',
        loadChildren: () =>
          import('./components/creatives/creatives.module').then(
            (mod) => mod.CreativesModule
          ),
        canActivate: [AuthGuard],
        data: { module: 'Creatives' },
      },
      {
        path: 'conversion',
        loadChildren: () =>
          import('./components/conversion/conversion.module').then(
            (mod) => mod.ConversionModule
          ),
        canActivate: [AuthGuard],
        data: { module: 'Tracking' },
      },
      {
        path: 'attribution',
        loadChildren: () =>
          import('./components/attribution/attribution.module').then(
            (mod) => mod.AttributionModule
          ),
        canActivate: [AuthGuard],
        data: { module: 'Attribution' },
      },
      {
        path: 'querybuilder',
        loadChildren: () =>
          import('./components/query-builder/query-builder.module').then(
            (mod) => mod.QueryEngineModule
          ),
        canActivate: [AuthGuard],
        data: { module: 'Query Builder' },
      },
      {
        path: 'campaign',
        loadChildren: () =>
          import('./components/campaign/campaign.module').then(
            (mod) => mod.CampaignModule
          ),
        canActivate: [AuthGuard],
        data: { module: 'Traffic Campaigns' },
      },
      {
        path: 'data-sources',
        loadChildren: () =>
          import('./components/data-sources/data-sources.module').then(
            (mod) => mod.DataSourcesModule
          ),
        canActivate: [AuthGuard],
        data: { module: 'Data Sources' },
      },
      {
        path: 'integration',
        loadChildren: () =>
          import('./components/integrations/integrations.module').then(
            (mod) => mod.IntegrationsModule
          ),
        canActivate: [AuthGuard],
        data: { module: 'Integrations' },
      },
      {
        path: 'marketing-spend',
        loadChildren: () =>
          import('./components/marketing-spend/marketing-spend.module').then(
            (mod) => mod.MarketingSpendModule
          ),
        canActivate: [AuthGuard],
        data: { module: 'Marketing Spend' },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    preloadingStrategy: NoPreloading,
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const routedComponents = [
  LoginComponent,
  LogoutSuccessComponent,
  ResetPasswordComponent,
];
