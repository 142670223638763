import { Injectable } from '@angular/core';
import { ApiDataService } from '../api/abstract/api.service';

@Injectable()
export class HighLevelCRMService {
  constructor(private apiClient: ApiDataService) {}

  connect(redirectUrl: string) {
    return this.apiClient.callHttpGet({
      path: `/highlevelcrm/connect?redirect_uri=${redirectUrl}`,
      type: '',
      version: '/v202201',
    });
  }

  authorize(accountId: string, authorizationCode: string) {
    return this.apiClient.callHttpGet({
      path: `/accounts/${accountId}/highlevelcrm/${authorizationCode}/connect`,
      type: '',
      version: '/v202107',
    });
  }
}
