import { Action } from '@ngrx/store';
import { ModuleConfig, ModulePermission } from '../../interfaces/common.interface';
export const SET_PERMISSIONS = 'Permissions set';
export const SET_DASHBOARD = 'Dashboard set';
export const SET_ACCESS = 'Permissions set access';


export class SetPermissions implements Action {
  readonly type = SET_PERMISSIONS;
  constructor(public payload: ModuleConfig[]) {}
}

export class SetDashboard implements Action {
  readonly type = SET_DASHBOARD;
  constructor(public payload: ModuleConfig) {}
}

export class SetAccess implements Action {
  readonly type = SET_ACCESS;
  constructor(public payload: ModulePermission) {}
}


export type All
  = SetPermissions | SetAccess | SetDashboard;
