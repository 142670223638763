import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../../shared/guard/auth.guard';
import { MediaDashboardFranchisorComponent } from './media-dashboard-franchisor.component';

export const franchisorDashboardModuleName = 'Franchisor Embedded Dashboard';

const routes: Routes = [
  {
    path: '',
    component: MediaDashboardFranchisorComponent,
    canActivate: [AuthGuard],
    data: { module: franchisorDashboardModuleName },
  },
  {
    path: 'group/:groupId',
    component: MediaDashboardFranchisorComponent,
    canActivate: [AuthGuard],
    data: { module: franchisorDashboardModuleName },
  },
  {
    path: ':accountId',
    component: MediaDashboardFranchisorComponent,
    canActivate: [AuthGuard],
    data: { module: franchisorDashboardModuleName },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MediaDashboardFranchisorRoutingModule {}
