import { createAction, props } from '@ngrx/store';
import { LocationsTaskAPIResponse } from '.';
import { AdvertiserLocations } from '../../../components/locations/interfaces';
import { Account } from '../../interfaces';

export const actionLocationsGetAll = createAction(
  '[Locations] Get All',
  props<{ id: string }>()
);

export const actionLocationsGetSuccess = createAction(
  '[Locations] Get Success',
  props<{ locations: AdvertiserLocations[] }>()
);

export const actionLocationsGetFail = createAction(
  '[Locations] Get Failed',
  props<{ error: string }>()
);

export const actionLocationsAdd = createAction(
  '[Locations] Add',
  props<{ id: string, locations: AdvertiserLocations[] }>()
);

export const actionLocationsAddSuccess = createAction(
  '[Locations] Add Success',
  props<{success?: any}>()
);

export const actionLocationsAddFail = createAction(
  '[Locations] Add Failed',
  props<{ error: string }>()
);

export const actionLocationsEdit = createAction(
  '[Locations] Edit',
  props<{ id: string, groupId: string, location: AdvertiserLocations }>()
);

export const actionLocationsEditSuccess = createAction(
  '[Locations] Edit Success',
  props<{success?: any}>()
);

export const actionLocationsEditFail = createAction(
  '[Locations] Edit Failed',
  props<{ error: string }>()
);

export const actionLocationsAccountEdit = createAction(
  '[Locations] Account Edit',
  props<{ groupId: string, accounts: Account[] }>()
);

export const actionLocationsAccountEditSuccess = createAction(
  '[Locations] Account Edit Success',
  props<{success?: any}>()
);

export const actionLocationsAccountEditFail = createAction(
  '[Locations] Account Edit Failed',
  props<{ error: string }>()
);

export const actionLocationsCreateAccountAndEdit = createAction(
  '[Locations] Create Account and Edit',
  props<{ id: string, groupId: string, location: AdvertiserLocations }>()
);

export const actionLocationsCreateAccountAndEditSuccess = createAction(
  '[Locations] Create Account and Edit Success',
  props<{success?: any}>()
);

export const actionLocationsCreateAccountAndEditFail = createAction(
  '[Locations] Create Account and Edit Failed',
  props<{ error: string }>()
);

export const actionLocationsDelete = createAction(
  '[Locations] Delete',
  props<{ id: string, locations: AdvertiserLocations[] }>()
);

export const actionLocationsDeleteSuccess = createAction(
  '[Locations] Delete Success',
  props<{ locations: LocationsTaskAPIResponse }>()
);

export const actionLocationsDeleteFail = createAction(
  '[Locations] Delete Failed',
  props<{ error: string }>()
);

export const actionLocationsInvite = createAction(
  '[Locations] Invite',
  props<{ id: string, locations: string[] }>()
);

export const actionLocationsInviteSuccess = createAction(
  '[Locations] Invite Success',
  props<{ locations: AdvertiserLocations[] }>()
);

export const actionLocationsInviteFail = createAction(
  '[Locations] Invite Failed',
  props<{ error: string }>()
);

export const actionLocationsResetAccounts = createAction(
  '[Locations] Account Reset'
);

export const actionLocationsGetAccounts = createAction(
  '[Locations] Get Accounts',
  props<{ id: string }>()
);

export const actionLocationsGetAccountsSuccess = createAction(
  '[Locations] Get Accounts Success',
  props<{ accounts: Account[] }>()
);

export const actionLocationsGetAccountsFail = createAction(
  '[Locations] Get Accounts Failed',
  props<{ error: string }>()
);
