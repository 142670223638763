import { Component, Renderer2, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { AuthFacade } from './shared/state/auth';
import { SubscriptionLike } from 'rxjs';
import * as fs from '@fullstory/browser';
import { environment } from '../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { LiveChatService } from './services/live-chat/live-chat.service';
import { SideMenuStatusFacade } from './shared/state/side-menu-status';
import { Store } from '@ngrx/store';
import { AppState } from './shared/state/state';
import { selectUserWhoIsWithLoadingStatus } from './shared/state/user/user.selectors';
import { selectModuleConfigAndStatus } from './shared/state/module-config/module-config.data.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app-main.component.html',
  styleUrls: ['./app-main.component.css']
})
export class AppMainComponent implements OnInit, OnDestroy {
  menuClick = false;
  menuButtonClick = false;
  topbarMenuActive = false;
  layoutStatic = true;
  sidebarActive = false;
  mobileMenuActive = false;
  darkMenu = false;
  observable: SubscriptionLike|undefined;
  authenticated = false;
  isBrowser: boolean;
  accountSubscription: SubscriptionLike|undefined;
  identityLoaded = false;
  moduleConfigLoadingComplete = false;
  displayLoader = true;
  moduleSubscription: SubscriptionLike;

  constructor(
    private authFacade: AuthFacade,
    private sideMenuFacade: SideMenuStatusFacade,
    public renderer: Renderer2,
    public store: Store<AppState>,
    @Inject(PLATFORM_ID) private platformId: Object, ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.accountSubscription = this.store.select(selectUserWhoIsWithLoadingStatus).subscribe(({loaded, userInfo}) => {
      if (this.isBrowser && !this.identityLoaded) {
        if (loaded && userInfo && userInfo[0]) {
          if (environment.environment === 'production' || environment.environment === 'staging') {
            if (window && (window as any).heap) {
              let heap = (window as any).heap
              heap.identify(userInfo[0].UserId);
              heap.addUserProperties({
                displayName: userInfo[0].DisplayName,
                userName: userInfo[0].UserName,
                email: userInfo[0].UserName,
              });
            }
          }
          LiveChatService.authenticated(userInfo[0]);
          fs.identify(userInfo[0].UserId, {
            displayName: userInfo[0].DisplayName,
            userName: userInfo[0].UserName,
            email: userInfo[0].Email,
          });
          this.identityLoaded = true;
        }
      }
    });
    this.moduleSubscription = this.store
      .select(selectModuleConfigAndStatus)
      .subscribe(
        ({hasLoaded, isLoading }) => {
          if (hasLoaded && !isLoading) {
            setTimeout(() => {
              this.moduleConfigLoadingComplete = true;
              setTimeout(() => {
                this.displayLoader = false;
              }, 1000)
            }, 2500)
          } else {
            this.moduleConfigLoadingComplete = false;
            this.displayLoader = true;
          }
        }
      );
  }

  onWrapperClick() {
    if (!this.menuClick && !this.menuButtonClick) {
      this.mobileMenuActive = false;
    }

    this.menuClick = false;
    this.menuButtonClick = false;
  }

  onToggleMenuClick(event: Event) {
    this.layoutStatic = !this.layoutStatic;
    this.sideMenuFacade.sideMenuStatusChange(this.layoutStatic)
    this.handleLayoutStatusHistory();
  }

  isMobile() {
    return window.innerWidth < 640;
  }

  ngOnInit() {
    this.observable = this.authFacade.authenticated$.subscribe(authenticated => {
      this.authenticated = authenticated;
    });
    this.handleLayoutStatusHistory(true);
  }

  ngOnDestroy() {
    if (this.observable) {
      this.observable.unsubscribe();
    }
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
    if (this.moduleSubscription) {
      this.moduleSubscription.unsubscribe();
    }
    fs?.shutdown();
    if (environment.environment === 'production' || environment.environment === 'staging') {
      if (window && (window as any).heap) {
        (window as any).heap.resetIdentity();
      }
    }
  }

  handleLayoutStatusHistory(isInit?: boolean) {
    if (isInit) {
      const layoutStatus = localStorage.getItem('layoutStatic')
      if (layoutStatus) {
        this.layoutStatic = JSON.parse(layoutStatus)
      }
    } else {
      localStorage.setItem('layoutStatic', JSON.stringify(this.layoutStatic))
    }
    this.sideMenuFacade.sideMenuStatusChange(this.layoutStatic)
  }
}
