import { createSelector } from '@ngrx/store';
import { AppState } from '../state';


export const selectState = (state: AppState) => state.auth;

export const selectAuthStateAuthenticated = createSelector(
  selectState,
  (state) => state?.authenticated
);

export const selectAuthStateAuthError = createSelector(
  selectState,
  (state) => state?.authError
);

export const selectAuthStateLoading = createSelector(
  selectState,
  (state) => state?.loading
);
