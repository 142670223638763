import { cloneDeep } from 'lodash-es';
import { AppState } from '../../state';
import * as CreativedashboardActions from './creativedashboard.actions';
import { Creativedashboard } from './creativedashboard.model';

export type Action = CreativedashboardActions.All;

/**
 * Define all store queries for Creativedashboard(s)
 */
export namespace CreativedashboardQuery {
  export const creativedashboard = (state: AppState) => state.creativedashboard;
}

/// Reducer function
export function creativedashboardReducer(state: Creativedashboard, action: Action) {

  switch (action.type) {
    case CreativedashboardActions.RESET_STATE:
      return {status: action.type};
    case CreativedashboardActions.PAGINATE_CREATIVES:
      return { ...state, status: action.type, pagination: action.payload};
    case CreativedashboardActions.SET_ADVERTISER_TYPE:
      return { ...state, type: 'advertiser', status: action.type, accountId: action.id, groupId: action.gid};
    case CreativedashboardActions.SET_GROUP_TYPE:
      return { ...state, type: 'group', status: action.type, accountId: action.id, groupId: null};
    case CreativedashboardActions.SET_VARIATION_LOADED:
      if (!state.variationLoaded) {
        state.variationLoaded = {};
      }
      const newState = cloneDeep(state);
      if (action.payload) {
        newState.variationLoaded[action.payload] = action.status;
      }
      return { ...state, variationLoaded: {...state.variationLoaded, ...newState.variationLoaded}, status: action.type};
    case CreativedashboardActions.RESET_VARIATION_LOADED:
      return { ...state, variationLoaded: [], status: action.type};
    case CreativedashboardActions.GET_CREATIVES:
      return { ...state, creativeIsLoading: true, status: action.type};
    case CreativedashboardActions.GET_CREATIVES_SUCCESS:
      return { ...state, creatives: action.payload, creativeIsLoading: false, status: action.type };
    case CreativedashboardActions.GET_CREATIVES_FAIL:
      return { ...state, creativeError: action.payload, creativeIsLoading: false, status: action.type };
    case CreativedashboardActions.DELETE_CREATIVE:
      return { ...state, creativeIsLoading: true, status: action.type};
    case CreativedashboardActions.DELETE_CREATIVE_SUCCESS:
      return { ...state, creativeIsLoading: false, status: action.type };
    case CreativedashboardActions.DELETE_CREATIVE_FAIL:
      return { ...state, creativeError: action.payload, creativeIsLoading: false, status: action.type };
    case CreativedashboardActions.DELETE_CREATIVES:
      return { ...state, creativeIsLoading: true, status: action.type};
    case CreativedashboardActions.DELETE_CREATIVES_SUCCESS:
      return { ...state, creativeIsLoading: false, status: action.type };
    case CreativedashboardActions.DELETE_CREATIVES_SELECTED:
      return { ...state, multipleCreativesSelected: action.payload, status: action.type };
    case CreativedashboardActions.DELETE_CREATIVES_FAIL:
      return { ...state, creativeError: action.payload, creativeIsLoading: false, multipleCreativesDeleted: false, status: action.type };
    case CreativedashboardActions.EDIT_CREATIVE:
      return { ...state, creativeIsLoading: true, status: action.type};
    case CreativedashboardActions.EDIT_CREATIVE_SUCCESS:
      return { ...state, creativeIsLoading: false, status: action.type };
    case CreativedashboardActions.EDIT_CREATIVE_FAIL:
      return { ...state, creativeError: action.payload, creativeIsLoading: false, status: action.type };
    case CreativedashboardActions.GET_ADVERTISERS:
      return { ...state, advertiserIsLoading: true, status: action.type};
    case CreativedashboardActions.GET_ADVERTISERS_SUCCESS:
      return { ...state, advertisers: action.payload, advertiserIsLoading: false, status: action.type };
    case CreativedashboardActions.GET_ADVERTISERS_FAIL:
      return { ...state, advertiserError: action.payload, advertiserIsLoading: false, status: action.type };
    case CreativedashboardActions.GET_EVENTS:
      return { ...state, eventIsLoading: true, status: action.type};
    case CreativedashboardActions.GET_EVENTS_SUCCESS:
      return { ...state, events: action.payload, eventIsLoading: false, status: action.type };
    case CreativedashboardActions.GET_EVENTS_FAIL:
      return { ...state, advertiserError: action.payload, eventIsLoading: false, status: action.type };
    default:
      return state;
  }
}
