import { Injectable } from '@angular/core';
import {
  DurationType,
  UnitType,
} from '../../components/integrations/interfaces';

@Injectable()
export class NumberUtilityService {
  static sumArrayNumbers(numbers: number[]) {
    return numbers.reduce((a, b) => a + b, 0);
  }

  numberWithCommasAndDecimal(x: number, n = 2): string | number {
    let str = this.removeCommaAndDecimal(x) as string;
    str =
      str.substring(0, str.length - n) + '.' + str.substring(str.length - n);
    if (str === '.0') {
      str = '0';
    }
    if (str === '.') {
      str = '';
    }
    str = str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (str[0] === '0' && str.length > 1) {
      str = str.substr(1);
    }
    if (str[0] === '.') {
      str = '0' + str;
    }
    return str;
  }

  numberWithCommas(x: number) {
    return this.removeComma(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  removeComma(x: string | number) {
    return typeof x === 'string'
      ? x.split(',').join('')
      : x.toString().split(',').join('');
  }

  removeCommaAndDecimal(x: string | number): string | number {
    return typeof x === 'string'
      ? x.split(',').join('').split('.').join('')
      : x.toString().split(',').join('').split('.').join('');
  }

  abbreviateNumber(value: number, abbreviationThreshold: number = 100000) {
    let newValue: string | number = value;
    if (value >= abbreviationThreshold) {
      let suffixes = ['', 'k', 'm', 'b', 't'];
      let tier = (Math.log10(Math.abs(newValue)) / 3) | 0;

      // if zero, we don't need a suffix
      if (tier == 0) return newValue;

      // get suffix and determine scale
      let suffix = suffixes[tier];
      let scale = Math.pow(10, tier * 3);

      // scale the number
      let scaled = newValue / scale;

      // format number and add suffix
      newValue = scaled.toFixed(1) + suffix;
    } else {
      newValue = newValue.toLocaleString('en-US', { maximumFractionDigits: 1 });
    }
    return newValue;
  }

  calculateTotalPercentAsDecimal(arrayOfObjects: any[], key: string): number {
    let totalAsPercent = Number(
      arrayOfObjects
        .reduce((accum, b) => {
          return accum + (b[key] || 0) * 100;
        }, 0)
        .toFixed(2)
    );
    let totalAsDecimal = (totalAsPercent || 0) / 100;
    return totalAsDecimal;
  }

  determineDurationUnit(
    formattedValue: string | number,
    durationType: DurationType = DurationType.None
  ) {
    switch (durationType) {
      case DurationType.Millisecond:
        return `${formattedValue} ms`;
      case DurationType.Second:
        return `${formattedValue} s`;
      case DurationType.Minute:
        return `${formattedValue} min(s)`;
      case DurationType.Hour:
        return `${formattedValue} hr(s)`;
      case DurationType.Day:
        return `${formattedValue} day(s)`;
      default:
        return formattedValue;
    }
  }

  formatNumberBasedOnUnitType(
    numberToFormat: number,
    type: UnitType,
    durationType?: DurationType,
    abbreviationThreshold?: number
  ) {
    let formattedNumber = this.abbreviateNumber(
      numberToFormat,
      abbreviationThreshold
    );
    switch (type) {
      case UnitType.Dollars:
        return `$${formattedNumber}`;
      case UnitType.Duration:
        return this.determineDurationUnit(formattedNumber, durationType);
      case UnitType.Number:
        return formattedNumber;
      case UnitType.Percent:
        return `${(numberToFormat * 100).toLocaleString('en-US', {
          maximumFractionDigits: 0,
        })}%`;
      default:
        return formattedNumber;
    }
  }

  percentChangeForCharts(
    control: number,
    comparison: number
  ): { displayText: string; percentChange: number; isPositiveValue: boolean } {
    const percentChange = control / comparison - 1;
    const isPositiveValue = percentChange > 0;
    const displayText =
      (isPositiveValue ? '+' : '') +
      (percentChange * 100).toLocaleString('en', {
        maximumFractionDigits: 0,
      }) +
      '%';
    return {
      displayText,
      percentChange,
      isPositiveValue,
    };
  }

  ordinalAttachment(number: number) {
    let tenthRemainder = number % 10,
      hundrethReminder = number % 100;
    if (tenthRemainder == 1 && hundrethReminder != 11) {
      return number + 'st';
    }
    if (tenthRemainder == 2 && hundrethReminder != 12) {
      return number + 'nd';
    }
    if (tenthRemainder == 3 && hundrethReminder != 13) {
      return number + 'rd';
    }
    return number + 'th';
  }
}
