import { Action, createReducer, on } from '@ngrx/store';
import { actionAnalyticsGetChannelGroupings, actionAnalyticsGetChannelGroupingsSuccess, actionAnalyticsPostAttributionFilter, 
  actionAnalyticsPostAttributionFilterSuccess, actionAnalyticsPostBudgetAllocation, 
  actionAnalyticsPostBudgetAllocationSuccess, actionAnalyticsError } from './analytics.actions';
import { AnalyticsState } from './analytics.model';
export const initialState: AnalyticsState = {
  id: '',
  loading: false
};

const reducer = createReducer(
  initialState,
  on(actionAnalyticsGetChannelGroupings, (state, { type }) => ({
    ...state,
    loading: true,
    status: type,
    channelGroupings: undefined,
    error: undefined,
  })),
  on(actionAnalyticsGetChannelGroupingsSuccess, (state, { channelGroupings, type }) => ({
    ...state,
    loading: false,
    channelGroupings,
    status: type,
    error: undefined
  })),
  on(actionAnalyticsPostAttributionFilter, (state, { type }) => ({
    ...state,
    loading: true,
    status: type,
    markovData: undefined,
    error: undefined,
  })),
  on(actionAnalyticsPostAttributionFilterSuccess, (state, { markov, type }) => ({
    ...state,
    loading: false,
    markovData: markov,
    status: type,
    error: undefined
  })),
  on(actionAnalyticsPostBudgetAllocation, (state, { id, type }) => ({
    ...state,
    loading: true,
    budgetAllocationResponse: undefined,
    status: type,
    error: undefined,
    id
  })),
  on(actionAnalyticsPostBudgetAllocationSuccess, (state, { response, type }) => ({
    ...state,
    loading: false,
    budgetAllocationResponse: response,
    status: type,
    error: undefined
  })),
  on(actionAnalyticsError, (state, { error, type, task }) => ({
    ...state,
    status: type,
    loading: false,
    error,
    failedTask: task
  }))
);

export function analyticsReducer(
  state: AnalyticsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
