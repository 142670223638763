import { EntitySelectorsFactory } from "@ngrx/data";
import { createSelector } from "@ngrx/store";
import { Account } from "../../interfaces";
import { EntityMap } from '../entity/entity-metadata';

export const accountsSelectors = new EntitySelectorsFactory().create<Account>(EntityMap.Account);


export const selectAccounts = createSelector(
  accountsSelectors.selectEntities,
  (data) => data
);

export const selectAccountsLoading = createSelector(
  accountsSelectors.selectLoading,
  (data) => data
);
