import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: 'confirmation-dialog.directive.html',
  styleUrls: ['confirmation-dialog.directive.scss'],
})
export class ConfirmationDialogContentComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogContentComponent>,
    private domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message: string;
      acceptButton: string;
      discardButton: string;
      omitCancelButton: boolean;
      title: string;
      textAlignCenter: boolean;
    }
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getHtml(html: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}
