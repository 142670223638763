import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { IntegrationDefinition } from '../../interfaces';
import { AppState } from '../state';
import * as datasourcesAction from './data-sources.actions';
import { DataSourcesState } from './data-sources.model';
import { DataSourceColumnSelection } from "./models/DataSourceColumnSelection";
import { selectDataSourcesState } from './data-sources.selectors';
import { DataSourcePayload } from './models/DataSourcePayload';
import { DataSourceSettings } from './models/DataSourceSettings';
import { JoinsTemplateModel } from './models/JoinsTemplateModel';
import { TransitivDataSourceCustomColumn } from './models/TransitivDataSourceCustomColumn';
import { DataSourceDataService } from './data-sources.data.service';
import { EntityCollectionService, EntityCollectionServiceFactory } from '@ngrx/data';
import { TransitivDataSource } from './models/TransitivDataSource';
import { EntityMap } from '../entity/entity-metadata';
import { IntegrationEffects } from '../integrations/new/integrations.effects';

@Injectable()
export class DataSourcesEffects {
  dataSourceService: EntityCollectionService<TransitivDataSource>;
  constructor(
    private store: Store<AppState>,
    private integrationEffects: IntegrationEffects,
    private dataSourceDataService: DataSourceDataService,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
  ) {
    this.dataSourceService = this.entityCollectionServiceFactory.create<TransitivDataSource>(EntityMap.DataSource);
  }


  private preparePayload(state: DataSourcesState, id: string) {
    const payload: DataSourcePayload = {
      SourceId: state.settings?.name || '',
      SchemaDataSourceType: state.settings?.type || 0,
      UpdateMethodType: state.settings?.method || 0,
      Columns: [],
      Sources: [],
      DependantIds: [],
      SourceEntityType: 1,
      IntegrationId: state.selectedDataSource?.IntegrationId,
      SourceEntityId: id,
      Name: state.settings?.name || '',
      Version: 'V202107',
      CompatibilityType: 2,
      Type: 5600
    };
    if (state.mappings) {
      state.mappings.forEach(mapping => {
        if (mapping.include) {
          payload.Columns.push({
            PrimaryKey: mapping.primaryKey || false,
            ColumnSource: mapping.source.Table,
            ColumnSourceSchema: mapping.source.Schema,
            OriginalColumnName: mapping.col_name,
            ColumnName: mapping.name,
            DataType: mapping.dataType
          });
        }
      });
    } else {
      state.columnSelections?.forEach(mapping => {
        mapping.ColumnsToImport.forEach(col => {
          payload.Columns.push({
            PrimaryKey: col.col_primary_key,
            ColumnSource: mapping.Table,
            ColumnSourceSchema: mapping.Schema,
            OriginalColumnName: col.col_name,
            ColumnName: col.col_name,
            DataType: col.col_suggested_types[0]
          });
        });
      });
    }
    let joinOrder = 0;
    state.joins?.forEach((join, i) => {
      if (join.leftColumn && join.leftSelection) {
        if (i === 0) {
          payload.Sources.push({
            SourceId: join.leftSelection.Table,
            SourceSchema: join.leftSelection.Schema,
            JoinOrder: joinOrder,
            SchemaJoinType: 0,
            JoinPredicate: null
          });
          joinOrder++;
        }

        if (join.rightSelection?.Schema && join.rightSelection?.Table && join.rightColumn?.col_name) {
          payload.Sources.push({
            SourceId: join.rightSelection.Table,
            SourceSchema: join.rightSelection.Schema,
            JoinOrder: joinOrder,
            SchemaJoinType: join.type,
            JoinPredicate: `[${join.rightSelection.Table}].[${join.rightColumn.col_name}] = [${join.leftSelection.Table}].[${join.leftColumn.col_name}]`
          });
          joinOrder++;
        }
      }
    });
    return payload;
  }

  getSchema(groupId: string) {
    this.dataSourceDataService.groupId = groupId;
    this.dataSourceService.load();
  }

  selectDataSource(dataSource: IntegrationDefinition) {
    this.store.dispatch(datasourcesAction.actionDataSourcesSelect({dataSource}));
  }

  saveDataSources(groupId: string) {
    this.store.select(selectDataSourcesState).pipe(first()).subscribe(state => {
      const payload: DataSourcePayload = this.preparePayload(state, groupId);
      this.integrationEffects.saveGroupIntegration({
        groupId: groupId,
        intid: state.selectedDataSource?.IntegrationId || undefined,
        payload: payload as unknown as IntegrationDefinition,
      })
    });
  }

  setSelectedColumns(selectedColumns: DataSourceColumnSelection[]) {
    selectedColumns = cloneDeep(selectedColumns);
    this.store.dispatch(datasourcesAction.actionDataSourcesSetSelectedCols({selectedColumns}));
  }

  setJoins(joins: JoinsTemplateModel[]) {
    joins = cloneDeep(joins);
    this.store.dispatch(datasourcesAction.actionDataSourcesSetJoins({joins}));
  }

  setMappings(mappings: TransitivDataSourceCustomColumn[]) {
    mappings = cloneDeep(mappings);
    this.store.dispatch(datasourcesAction.actionDataSourcesSetMapping({mappings}));
  }

  setSettings(settings: DataSourceSettings) {
    settings = cloneDeep(settings);
    this.store.dispatch(datasourcesAction.actionDataSourcesSetSettings({settings}));
  }
}
