import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityMap } from '../entity/entity-metadata';
import { CommonUtilityService } from '@services/utility/common-utility.service';
import { MarketingSpend } from '../../interfaces/market.interface';
import { MarketingSpendService } from '@services/marketSpend/marketspend.service';
import { MarketingSpendPayload } from '../../interfaces';

@Injectable()
export class MarketingSpendDataService extends DefaultDataService<MarketingSpend> {
  payload?: MarketingSpendPayload
  marketingSpendService: MarketingSpendService|undefined;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super(EntityMap.MarketingSpend, http, httpUrlGenerator);
  }

  getAll(): Observable<MarketingSpend[]> {
    return this.marketingSpendService?.getMarketingSpend(this.payload as MarketingSpendPayload).pipe(
      map((data) => data.map(spendData => CommonUtilityService.timestampStateData(spendData)))) as Observable<MarketingSpend[]>;
  }
}
