
import {of as observableOf, Observable } from 'rxjs';

import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { StateService } from '../state/state.service';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { PagingFilter, CreativeVariationNodeMetadata, GetCreativeEventsSegmentPayload } from '../../shared/interfaces';
import { EventDeviceLookup, FormattedDeviceOption, EventNodeMetaOption,
  CreativeEventsSegment,
  EventType,
  EventTypeFilter} from '../../shared/interfaces/event.interface';
import { DateRange } from '../../shared/interfaces/date.interface';

@Injectable()
export class EventsService {
  public apiClient = <ApiClient>{};
  getNodeMetaOptions = this.formatNodeMetaOptions;
  // var
  dataSegments = [
  {
    name: 'Union',
    value: 0
  },
  {
    name: 'Include',
    value: 1
  },
  {
    name: 'Exclude',
    value: 2
  }];

  datePresets = [
    {
      name: 'This Week',
      fromDate: moment().startOf('week').toISOString(),
      toDate: moment().toISOString()
    }, {
      name: 'Last 7 Days',
      fromDate: moment().subtract(7, 'days').toISOString(),
      toDate: moment().toISOString()
    }, {
      name: 'This Month',
      fromDate: moment().startOf('month').toISOString(),
      toDate: moment().toISOString()
    }, {
      name: 'Last 30 Days',
      fromDate: moment().subtract(30, 'days').toISOString(),
      toDate: moment().toISOString()
    }
  ];

  constructor(private stateService: StateService, ) { }

  formatNodeMetaOptions(meta: CreativeVariationNodeMetadata[]): EventNodeMetaOption[] {
    return meta.reduce((memo: EventNodeMetaOption[], row) => {
      let el;
      if (row.Id) {
        el = row.Id;
      } else if (row.ClassName) {
        el = row.ClassName;
      } else {
        el = row.NodeName;
      }
      memo.push({
        Name: el,
        Selected: false
      });
      return memo;
    }, []);
  }

  formatDeviceOptions(data: EventDeviceLookup): EventDeviceLookup|FormattedDeviceOption[] {
    if (data && !data.DeviceTypes) {
      return data;
    }
    const options = data.DeviceTypes;

    return options.reduce((memo: FormattedDeviceOption[], option) => {
      if (option) {
        memo.push({
          Name: option,
          label: option,
          value: option,
          Selected: false
        });
      }
      return memo;
    }, []);
  }

  /**
   * getGroupedCreativeEvents
   * @param cid
   * @param vid
   * @param fromDate
   * @param toDate
   * @returns {*}
   */
  getGroupedCreativeEvents(cid: string, vid: string, fromDate: string|Date, toDate: string|Date) {
    let api_url = `/analytics/events`;

    if (cid && !vid) {
      api_url = `/analytics/events/${cid}/${cid}/grouped/${fromDate}/${toDate}`;
    } else if (vid) {
      api_url = `/analytics/events/${cid}/${vid}/grouped/${fromDate}/${toDate}`;
    }

    return this.apiClient.callHttpGet({path: api_url});
  }

  /**
   * getCreativeEventsSegment
   * @param cid
   * @param vid
   * @param payload
   * @returns {*}
   */
  getCreativeEventsSegment(id: string, cid: string,
    vid: string, payload: GetCreativeEventsSegmentPayload): Observable<CreativeEventsSegment[]> {
    return this.apiClient.callHttpPost({path: `/analytics/${id}/events/${cid}/${vid}`, param: payload, type: ''});
  }

  getCreativeEvents(id: string, cid: string,
    vid: string, dates: DateRange): Observable<CreativeEventsSegment[]> {
    return this.apiClient.callHttpGet({path: `/analytics/${id}/events/${cid}/${vid}/${dates.DateStart}/${dates.DateEnd}`, type: ''});
  }

  /**
   * getEventsDeviceLookup
   * @returns {*}
   */
  getEventsDeviceLookup() {
    const keyname = 'deviceLookups';
    const cached = this.stateService.get(keyname);
    if (cached && cached.data !== null) {
      return observableOf(cached);
    }

    return this.apiClient.callHttpGet({path: `/events/lookups`}).pipe(
      tap((data: EventDeviceLookup
      ) => {
        const devices = this.formatDeviceOptions(data);
        this.stateService.put(keyname, devices);
      }));
  }

  /**
   * getEventStatus
   * @param type
   * @param id
   * @returns {*}
   */
  getEventStatus(type: string, id: string) {
    let api_url = `/events`;
    if (type && !id) {
      api_url = `/events/${type}`;
    } else if (type && id) {
      api_url = `/events/${type}/${id}`;
    }

    return this.apiClient.callHttpGet({path: api_url});
  }
}
