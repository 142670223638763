import { Component, OnInit } from '@angular/core';
import { UtilityService } from '@services/utility/utility.service';
import { FieldClass } from '../field/field.class';
@Component({
  selector: 'app-date',
  template: `
  <div class="mar-b-50 inputfield_" [ngClass]="{
    'is_block_full': field.isBlock,
    'haselement': field.hasElement,
    'is_col_6': field.size === 'xxxsmall',
    'is_col_5': field.size === 'xxsmall',
    'is_col_4': field.size === 'xsmall',
    'is_col_3': field.size === 'small',
    'is_col_2': field.size === 'medium',
    'is_fullwidth': !field.size || field.size === 'large'}" *ngIf="display()">
      <app-form-date-picker [name]="field.name" [itemId]="field.id" (onSelect)="onSelect($event)" [showTime]="field.showTime"
      *ngIf="!isPersistent()" [group]="group" [validations]="field.validations" [value]="field.value" [defaultTime]="field.defaultTime"
      [placeholder]="field.label + (field.isRequired ? '*' : '')">
      </app-form-date-picker>
      <span *ngIf="isPersistent()">Persistent</span>
      <div class="pri-tooltip lookback-info" *ngIf="field.description">
        <strong class="epsilon">
          <i class="fa fa-question-circle" style="color: #1e88e5;font-size: 16px;"></i>
        </strong>
        <p class="content">
          {{field.description}}
        </p>
      </div>
    </div>`,
  styles: []
})
export class DateComponent extends FieldClass implements OnInit {
  constructor(private utilityService: UtilityService) {
    super();
  }
  ngOnInit() {}
}
