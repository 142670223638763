import { createAction, props } from '@ngrx/store';
import {
  DateComparisonType,
  DateRollup,
  LocationSummaryResponse,
  SelectedLocationFilters,
} from '../../interfaces';
import { TableSorting } from '../../models/global-filtering.model';

export const actionChangeInSortingOption = createAction(
  '[GlobalFilter] Selected Sorting Option Change',
  props<{ selectedSortingOption: TableSorting }>()
);

export const actionChangeInLocationFilters = createAction(
  '[GlobalFilter] Selected Location Filters Change',
  props<{
    selectedLocationFilters: SelectedLocationFilters;
    comparePrevious?: boolean;
    selectedDateComparisonOption?: DateComparisonType;
  }>()
);

export const actionChangeInLocationSummaryData = createAction(
  '[GlobalFilter] Location Summary Data Change',
  props<{
    locationSummary: LocationSummaryResponse['data'];
    accountId: string;
  }>()
);

export const actionChangeInDateFiltering = createAction(
  '[GlobalFilter] Date Filtering Change',
  props<{
    fromDate: Date;
    toDate: Date;
    comparePrevious: boolean;
    selectedDateComparisonOption: DateComparisonType;
    dateRollUp: DateRollup;
    selectedLocationFilters?: SelectedLocationFilters;
  }>()
);

export const actionChangeInDateRange = createAction(
  '[GlobalFilter] Date Range Change',
  props<{
    fromDate: Date;
    toDate: Date;
  }>()
);

export const actionChangeInLocationSearch = createAction(
  '[GlobalFilter] Location Search Change',
  props<{
    searchInput: string;
  }>()
);

export const actionResetGlobalFilterState = createAction(
  '[GlobalFilter] Reset Global Filter State'
);
