import { Injectable } from '@angular/core';

@Injectable()
export class StringUtilityService {

  static isString(value: any) {
    return (typeof value === 'string' || value instanceof String)
  }

  filterText(text: string|number, filter: string): boolean {
    if (typeof text === 'number') {
      text = text.toString();
    }
    const r = new RegExp(filter?.toLowerCase());
    return filter ? r.test(text?.toLowerCase()) : false;
  }
}
