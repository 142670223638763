import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {
  DurationType,
  UnitType,
} from '../../components/integrations/interfaces';
import { NumberUtilityService } from './number-utility.service';

@Injectable()
export class UnitFormatterService {

  constructor(private numberUtility: NumberUtilityService) {}

  unitFormatter(
    value: number | string | Date,
    unitType: UnitType,
    durationType?: DurationType
  ) {
    switch (unitType) {
      case UnitType.Date:
        return moment(value).format('MM/DD/YYYY');
      case UnitType.String:
        return value;
    }
    return this.numberUtility.formatNumberBasedOnUnitType(
      value as number,
      unitType,
      durationType
    );
  }
}
