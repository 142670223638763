import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FieldClass } from '../field/field.class';
@Component({
  selector: 'app-listbox-field',
  template: `
  <div class="col-md-12 mar-b-50" [formGroup]="group">
    <label class="nlabel" style="display:inline-block;">{{field?.label}}<span *ngIf="field?.isRequired" style="color: red;">*</span></label>
    <div class="pri-tooltip lookback-info" *ngIf="field?.description">
      <strong class="epsilon">
        <i class="fa fa-question-circle" style="color: #1e88e5;font-size: 16px;"></i>
      </strong>
      <p class="content">
        {{field?.description}}
      </p>
    </div>
    <p class="slctd">Selected Items: {{displayListboxValues()}}</p>
    <div>
      <app-form-multi-select
        [options]="field?.options"
        [formControlName]="field?.name"
        [id]="field?.id"
        [filter]="field?.filter"
        [disabled]="disabled()"
        [(ngModel)]="value"
        (onChange)="onChange($event)">
      </app-form-multi-select>
    </div>
  </div>`,
  styles: []
})
export class ListboxFieldComponent extends FieldClass implements OnInit, AfterViewInit {
  value = this.field?.value;
  constructor() {
    super();
  }
  ngOnInit() {}

  ngAfterViewInit() {
    this.watchField();
  }

  watchField() {
    if (this.field && this.field?.fieldToWatch && this.group) {
      const f = this.group.get(this.field?.fieldToWatch);
      if (f) {
        f.valueChanges.subscribe(value => {
          if (this.field && this.field?.onWatchedOptionChange) {
            this.field.options = this.field?.onWatchedOptionChange(value);
          }
        });
      }
    }
  }

  displayListboxValues() {
    let text = '';
    if (this.field && this.field?.value) {
      this.field?.value.forEach((item: string) => {
        text += item + ', ';
      });
    }

    return text.replace(/, *$/, '');
  }
}
