import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient, ResponseStructure } from '../../shared/interfaces/api-client.interface';
import { AdvertiserLocations } from '../../components/locations/interfaces';
import { environment } from '../../../environments/environment';
import { LocationsTaskAPIResponse } from '../../shared/state/locations';



@Injectable()
export class LocationService {
  public apiClient = <ApiClient>{};

  constructor() { }

  getAdvertiserLocations(advertiserGroupId: string): Observable<AdvertiserLocations[]> {
    return this.apiClient.callHttpGet({path: `/advertisergroups/${advertiserGroupId}/advertiserlocations`,
    type: 'edgewarehouse'}, true);
  }

  addAdvertiserLocation(advertiserGroupId: string, payload: AdvertiserLocations) {
    return this.apiClient.callHttpPost({path: `/advertisergroups/${advertiserGroupId}/advertiserlocations`,
    type: 'edgewarehouse', param: payload});
  }

  addBulkAdvertiserLocation(advertiserGroupId: string, payload: AdvertiserLocations[]): Observable<LocationsTaskAPIResponse> {
    return this.apiClient.callHttpPost({path: `/advertisergroups/${advertiserGroupId}/bulkadvertiserlocations`,
    type: 'edgewarehouse', param: payload});
  }

  editAdvertiserLocation(id: string, extId: string, payload: AdvertiserLocations) {
    return this.apiClient.callHttpPut(
      {path: `/advertisers/${id}/advertiserlocations/${extId}`,
    type: 'edgewarehouse', param: payload});
  }

  deleteAdvertiserLocation(payload: AdvertiserLocations) {
    return this.apiClient.callHttpDelete(
      {path: `/advertisers/${payload.TransitivAccountId}/advertiserlocations/${payload.ExternalLocationId}`,
    type: 'edgewarehouse'});
  }

  deleteBulkAdvertiserLocation(groupId: string, payload: AdvertiserLocations[]): Observable<LocationsTaskAPIResponse> {
    return this.apiClient.callHttpDeleteWithBody(
      {path: `/advertisers/${groupId}/advertiserlocations`, param: payload,
    type: 'edgewarehouse'});
  }

  createAccountAndEditAdvertiserLocation(groupId: string, accountId: string, payload: AdvertiserLocations) {
    return this.apiClient.callHttpPutWithResponse(
      {path: `/accountgroups/${groupId}/accounts/${accountId}/locations/${payload.ExternalLocationId}/create`, param: payload,
    type: ''});
  }

  sendInvite(advertiserGroupId: string, payload: string[]): Observable<AdvertiserLocations[]> {
    this.apiClient.setBaseUrl(environment.authBase);
    return this.apiClient.callHttpPost({path: `/FranchiseeSignup/v202007/${advertiserGroupId}/send-invites`,
    version: '', param: payload});
  }

  getSharedData(id: string): Observable<AdvertiserLocations[]> {
    return this.apiClient.callHttpGet(
      {path: `/advertisers/advertiserlocations/usingcustomersof/${id}`, type: 'edgewarehouse'});
  }
}
