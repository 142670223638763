import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {AppState} from '../../state';
import {CreativeAnalysisQuery} from './creative-analysis.reducer';
import * as CreativeAnalysisActions from './creative-analysis.actions';
import { CreativeDiagram, CreativeToggle } from '../../../interfaces';

@Injectable()
export class CreativeAnalysisFacade {
  creativeAnalysis$ = this.store.select(CreativeAnalysisQuery.creativeAnalysis);
  variationLoadPrimary = true;

  constructor(
    private store: Store<AppState>
  ) { }

  setVisual(payload: string): void {
    this.store.dispatch(new CreativeAnalysisActions.SetAnalysisVisual(payload));
  }

  setHeatmap(payload: string): void {
    this.store.dispatch(new CreativeAnalysisActions.SetAnalysisHeatmap(payload));
  }

  setLabel(payload: string): void {
    this.store.dispatch(new CreativeAnalysisActions.SetAnalysisLabel(payload));
  }

  setLegend(payload: string): void {
    this.store.dispatch(new CreativeAnalysisActions.SetAnalysisLegend(payload));
  }

  setDiagram(payload: CreativeDiagram): void {
    this.store.dispatch(new CreativeAnalysisActions.SetAnalysisDiagram(payload));
  }

  setToggle(payload: CreativeToggle): void {
    this.store.dispatch(new CreativeAnalysisActions.SetAnalysisToggle(payload));
  }

  resetState() {
    this.store.dispatch(new CreativeAnalysisActions.ResetAnalysisState());
  }

}
