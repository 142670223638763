import { DateUtilityService } from '@services/utility';
import { AttributionFilter, ChannelGrouping } from '../interfaces';

export const markovPayloadModel: AttributionFilter = {
  ChannelGrouping: <ChannelGrouping[]>[],
  FromDate: DateUtilityService.getDefaultMarkovFromDate(),
  ToDate: DateUtilityService.getDefaultMarkovToDate(),
  IncludeSpecificChannelNames: [],
  ExcludeSpecificChannelNames: [],
  ExcludeChannelsBelowThreshold: 0,
  ExcludeChannelsAboveThreshold: 0,
  ConversionFilter: '',
};
