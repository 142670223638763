import { AccountState } from '../account/account.model';
import { Account, AccountGroup } from '../../../interfaces';
import { IntegrationsState } from '../../integrations';

interface GlobalStore {
  data: any;
  actions: any;
}

export function setGlobalStore() {
  (window as any).transitiv = {};

  (window as any).transitiv.account = <GlobalStore>{
    data: <AccountState>{
      loading: false,
      userLoading: false,
      groupLoading: false,
      advertiserLoading: false,
      searchLoading: false,
      searchResult: {
        AdvertiserGroups: [],
        Advertisers: [],
      },
      user: [],
      groupId: '',
      advertiserId: '',
      activeSelection: {
        Name: '',
        Type: ''
      },
      activeAdvertiser: <Account>{},
      activeAdvertiserGroup: <AccountGroup>{},
      advertisers: [],
      advertiserGroups: [],
      advertiserRooftop: [],
      deletedAdvertiser: '',
      error: undefined,
      searchError: undefined,
      userError: undefined,
      groupError: undefined,
      advertiserError: undefined,
    },
    actions: {
      getUser: () => {
        (window as any).dispatchEvent(new Event('transitivGlobalGetUser'));
      }
    }
  };

  (window as any).transitiv.integrations = <GlobalStore>{
    data: {
      loading: false,
      integrations: [],
      integration: {},
      error: undefined,
      status: 'string'
    },
    actions: {
      getIntegrations: () => {
        (window as any).dispatchEvent(new Event('transitivGlobalGetAllIntegrations'));
      },
      getIntegration: (integrationId: string) => {
        (window as any).dispatchEvent(new CustomEvent('transitivGlobalGetIntegration', {
          detail: {integrationId: integrationId}
        }));
      },
    }
  };
}

export function updateIntegrationsState(newState: IntegrationsState) {
  (window as any).transitiv.integrations.data = newState;
  (window as any).dispatchEvent(new Event('transitivGlobalIntegrationStateUpdated'));
}
