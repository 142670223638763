import { Component, OnInit, Input } from '@angular/core';
import { UtilityService } from '@services/utility/utility.service';
import { cloneDeep, filter, sortBy } from 'lodash-es';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { AdvertiserService } from '@services/advertiser/advertiser.service';
import { Account } from '@shared/interfaces';
import { AccountFacade } from '@shared/state/global';
import { environment } from '@environments/environment';
import { PaginationObject } from '@shared/interfaces/common.interface';
import { StringUtilityService } from '@services/utility/string-utility.service';

@Component({
  templateUrl: './group-list.directive.html',
  styleUrls: ['./group-list.directive.scss'],
  selector: 'app-group-list'
})
export class GroupListComponent implements OnInit {
  rows = environment.defaultPageListCount;
  gid = '';
  allAdvertisers: Account[] = [];
  advertisers: Account[] = [];
  sortType = 'CreatedOn';
  sortReverse = false;
  isLoading = false;
  link = '';
  filterData: Account[] = [];
  filter = '';
  constructor(
  private utilityService: UtilityService,
  private stringUtility: StringUtilityService,
  private apiClient: ApiService,
  private errorHandler: ErrorHandlerService,
  private advertiserService: AdvertiserService,
  private accountFacade: AccountFacade,
  private router: Router,
  private route: ActivatedRoute) {
    this.apiClient.errorHandler = this.errorHandler;
    this.advertiserService.apiClient = this.apiClient;
  }

  @Input() set url(value: string) {
    this.link = value;
  }

  get url(): string {
    return this.link;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.gid = params['gid'] || params['groupid'] || params['groupId'];
      this.loadGroupData();
    });
  }

  loadGroupData() {
    if (!this.gid) {
      return;
    }
    this.isLoading = true;
    this.advertiserService.getAdvertisersByGroup(this.gid).subscribe((data) => {
      this.isLoading = false;
      this.allAdvertisers = sortBy(data.data, ['Name']) || [];
      this.filterData = cloneDeep(this.allAdvertisers);
      this.advertisers = this.paginateData({pageIndex: 0, pageSize: this.rows});
    });
  }

  paginate(event: PaginationObject) {
    this.advertisers = this.paginateData(event);
  }

  paginateData(data: PaginationObject): Account[] {
    return this.utilityService.paginateData(this.filterData, data);
  }

  sortColumn(name: string) {
    const oldSort = this.sortType;
    this.sortType = name;

    if (oldSort === name) {
      this.sortReverse = !this.sortReverse;
    } else {
      this.sortReverse = false;
    }
  }

  navigate(advertiser: Account) {
    if (advertiser) {
      this.accountFacade.setActiveAdvertiser(advertiser);
    }
    this.router.navigate(['/' + this.link, advertiser.AccountId]);
  }

  filterTableData() {
    this.filterData = cloneDeep(this.allAdvertisers);
    if (this.filter) {
      this.filterData = filter(this.filterData, (data) => {
        return this.stringUtility.filterText(data.Name, this.filter);
      });
    }
    this.advertisers = this.paginateData({pageIndex: 0, pageSize: this.rows});
  }
}
