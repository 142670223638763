import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {AdGroupPayload} from '../../shared/interfaces/payloads.interface';
import {AdGroup} from '../../shared/interfaces/ad-group.interface';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { ErrorHandlerService } from '../helpers/error-handler.service';


@Injectable()
export class AdGroupService {
  public apiClient = <ApiClient>{};

  constructor() {
   }

  getAdGroups(payload: AdGroupPayload): Observable<AdGroup[]> {
    return this.apiClient.callHttpGet({path: `/${payload.advertiserId}/${payload.campaignId}/adgroups`});
  }

  getAdGroup(payload: AdGroupPayload): Observable<AdGroup> {
    return this.apiClient.callHttpGet({path: `/${payload.advertiserId}/${payload.campaignId}/adgroups/${payload.adGroupId}`});
  }

  addAdGroups(paramsPayload: AdGroupPayload, bodyPayload: AdGroup): Observable<AdGroupPayload> {
    return this.apiClient.callHttpPost({path: `/${paramsPayload.advertiserId}/${paramsPayload.campaignId}/adgroup`,
     param: bodyPayload});
  }

  editAdGroups(paramsPayload: AdGroupPayload, bodyPayload: AdGroup): Observable<AdGroupPayload> {
    const api_url = `/${paramsPayload.advertiserId}/${paramsPayload.campaignId}/adgroup/${paramsPayload.adGroupId}`;

    return this.apiClient.callHttpPut({path: api_url, param: bodyPayload});
  }

  deleteAdGroups(payload: AdGroupPayload): void {
    this.apiClient.callHttpDelete({path: `/${payload.advertiserId}/${payload.campaignId}/adgroups/${payload.adGroupId}`});
  }
}
