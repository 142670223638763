import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TitleService {
  private title = new Subject<string>();
  itemsHandler = this.title.asObservable();
  private context = new Subject<string>();
  contextHandler = this.context.asObservable();

  setTitle(title: string) {
    this.title.next(title);
  }

  setContext(context: string) {
    this.context.next(context);
  }
}
