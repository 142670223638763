import { Action } from '@ngrx/store';
import { Creative, AdEvent, IndirectCreativePayload, Account } from '../../../interfaces';
import { Pagination } from './creativedashboard.model';

export const SET_ADVERTISER_TYPE = 'Creativedashboard set type as advertiser';
export const SET_GROUP_TYPE = 'Creativedashboard set type as group';
export const SET_VARIATION_LOADED = 'Creativedashboard set variation loading';
export const RESET_VARIATION_LOADED = 'Creativedashboard reset variation loading';

export const GET_CREATIVES = 'Creativedashboard get creatives';
export const GET_CREATIVES_SUCCESS = 'Creativedashboard get creatives success';
export const GET_CREATIVES_FAIL = 'Creativedashboard get creatives fail';

export const DELETE_CREATIVE = 'Creativedashboard delete creative';
export const DELETE_CREATIVE_SUCCESS = 'Creativedashboard delete creative success';
export const DELETE_CREATIVE_FAIL = 'Creativedashboard delete creative fail';

export const DELETE_CREATIVES = 'Creativedashboard delete multiple creatives';
export const DELETE_CREATIVES_SUCCESS = 'Creativedashboard delete multiple creatives success';
export const DELETE_CREATIVES_FAIL = 'Creativedashboard delete multiple creatives fail';
export const DELETE_CREATIVES_SELECTED = 'Creativedashboard select multiple creatives';

export const PAGINATE_CREATIVES = 'Creativedashboard paginate creatives';

export const EDIT_CREATIVE = 'Creativedashboard edit creative';
export const EDIT_CREATIVE_SUCCESS = 'Creativedashboard edit creative success';
export const EDIT_CREATIVE_FAIL = 'Creativedashboard edit creative fail';

export const GET_ADVERTISERS = 'Creativedashboard get advertisers';
export const GET_ADVERTISERS_SUCCESS = 'Creativedashboard get advertisers success';
export const GET_ADVERTISERS_FAIL = 'Creativedashboard get advertisers fail';

export const GET_EVENTS = 'Creativedashboard get events';
export const GET_EVENTS_SUCCESS = 'Creativedashboard get events success';
export const GET_EVENTS_FAIL = 'Creativedashboard get events fail';

export const RESET_STATE = 'Creativedashboard reset state';

export class ResetState implements Action {
  readonly type = RESET_STATE;
  constructor() {}
}

export class PaginateCreatives implements Action {
  readonly type = PAGINATE_CREATIVES;
  constructor(public payload: Pagination) {}
}

export class SetAsAdvertiser implements Action {
  readonly type = SET_ADVERTISER_TYPE;
  constructor(public id: string, public gid: string) {}
}

export class SetAsGroup implements Action {
  readonly type = SET_GROUP_TYPE;
  constructor(public id: string) {}
}

export class SetVariationLoaded implements Action {
  readonly type = SET_VARIATION_LOADED;
  constructor(public payload: string, public status: boolean) {}
}

export class ResetVariationLoaded implements Action {
  readonly type = RESET_VARIATION_LOADED;
  constructor() {}
}

export class GetCreatives implements Action {
  readonly type = GET_CREATIVES;
  constructor(public payload: string) {}
}

export class GetCreativesSuccess implements Action {
  readonly type = GET_CREATIVES_SUCCESS;
  constructor(public payload: Creative[]) {}
}

export class GetCreativesFail implements Action {
  readonly type = GET_CREATIVES_FAIL;
  constructor(public payload?: string) {}
}

export class DeleteCreative implements Action {
  readonly type = DELETE_CREATIVE;
  constructor(public id: string, public cid: string) {}
}

export class DeleteCreativeSuccess implements Action {
  readonly type = DELETE_CREATIVE_SUCCESS;
  constructor() {}
}

export class DeleteCreativeFail implements Action {
  readonly type = DELETE_CREATIVE_FAIL;
  constructor(public payload?: string) {}
}

export class DeleteCreatives implements Action {
  readonly type = DELETE_CREATIVES;
  constructor() {}
}

export class DeleteCreativesSuccess implements Action {
  readonly type = DELETE_CREATIVES_SUCCESS;
  constructor() {}
}

export class DeleteCreativesFail implements Action {
  readonly type = DELETE_CREATIVES_FAIL;
  constructor(public payload?: string) {}
}

export class DeleteCreativesSelected implements Action {
  readonly type = DELETE_CREATIVES_SELECTED;
  constructor(public payload: boolean) {}
}

export class EditCreative implements Action {
  readonly type = EDIT_CREATIVE;
  constructor(public payload: IndirectCreativePayload, public id: string, public cid: string) {}
}

export class EditCreativeSuccess implements Action {
  readonly type = EDIT_CREATIVE_SUCCESS;
  constructor() {}
}

export class EditCreativeFail implements Action {
  readonly type = EDIT_CREATIVE_FAIL;
  constructor(public payload?: string) {}
}

export class GetAdvertisers implements Action {
  readonly type = GET_ADVERTISERS;
  constructor(public payload: string) {}
}

export class GetAdvertisersSuccess implements Action {
  readonly type = GET_ADVERTISERS_SUCCESS;
  constructor(public payload: Account[]) {}
}

export class GetAdvertisersFail implements Action {
  readonly type = GET_ADVERTISERS_FAIL;
  constructor(public payload?: string) {}
}

export class GetEvents implements Action {
  readonly type = GET_EVENTS;
  constructor() {}
}

export class GetEventsSuccess implements Action {
  readonly type = GET_EVENTS_SUCCESS;
  constructor(public payload: AdEvent[]) {}
}

export class GetEventsFail implements Action {
  readonly type = GET_EVENTS_FAIL;
  constructor(public payload?: string) {}
}


export type All
  = ResetState
  | PaginateCreatives
  | SetAsAdvertiser
  | SetAsGroup
  | SetVariationLoaded
  | ResetVariationLoaded
  | GetCreatives
  | GetCreativesSuccess
  | GetCreativesFail
  | GetAdvertisers
  | GetAdvertisersSuccess
  | GetAdvertisersFail
  | GetEvents
  | GetEventsSuccess
  | GetEventsFail
  | DeleteCreative
  | DeleteCreativeSuccess
  | DeleteCreativeFail
  | DeleteCreatives
  | DeleteCreativesSuccess
  | DeleteCreativesFail
  | DeleteCreativesSelected
  | EditCreative
  | EditCreativeSuccess
  | EditCreativeFail;
