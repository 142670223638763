import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { environment } from '@environments/environment';
import { LinkedInService } from '@services/linked-in/linked-in.service';

@Component({
  selector: 'app-linked-in',
  templateUrl: './linked-in.component.html',
  styleUrls: ['./linked-in.component.scss'],
})
export class LinkedInComponent implements OnInit {
  id = '';
  code = '';
  intid = '';
  logo = environment.defaultBlackLogo;
  isBrowser: boolean;
  constructor(
    private route: ActivatedRoute,
    private linkedInService: LinkedInService,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.apiClient.errorHandler = this.errorHandler;
    this.linkedInService.apiClient = this.apiClient;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.code = params['code'];
      this.id = params['advertiser_id'];
      this.intid = params['intid'];
      this.authorize();
    });
  }

  authorize() {
    if (this.isBrowser) {
      let originalOrigin = location.origin;
      if (
        !originalOrigin.includes('https') &&
        !originalOrigin.includes('local')
      ) {
        originalOrigin = originalOrigin.replace('http', 'https');
      }
      this.linkedInService
        .authorize(
          this.code,
          `${originalOrigin}/linked-in-authorization?advertiser_id=${this.id}${
            this.intid ? `&intid=${this.intid}` : ''
          }`
        )
        .subscribe((data) => {
          localStorage.setItem(
            'EVENT.PUB',
            JSON.stringify({ code: data.RefreshToken, expiry:  data.RefreshTokenExpiry})
          );
          window.close();
        });
    }
  }
}
