import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  AccountsAPIResponse,
  AccountAPIResponse,
  AccountGroupsAPIResponse,
  AccountGroupAPIResponse,
} from '../../interfaces';

export const actionAccountGetAllGroups = createAction(
  '[Accounts] Get All Groups',
  props<{id?: any}>()
);

export const actionAccountGetAllGroupsSuccess = createAction(
  '[Accounts] Get All Groups Success',
  props<{ groups: AccountGroupsAPIResponse }>()
);

export const actionAccountGetAllAccounts = createAction(
  '[Accounts] Get All Accounts',
  props<{ id: string }>()
);

export const actionAccountGetAllAccountsSuccess = createAction(
  '[Accounts] Get All Accounts Success',
  props<{ accounts: AccountsAPIResponse }>()
);

export const actionAccountError = createAction(
  '[Accounts] Error',
  props<{ error: HttpErrorResponse }>()
);

export const actionAccountGet = createAction(
  '[Accounts] Get',
  props<{ id: string }>()
);

export const actionAccountGetSuccess = createAction(
  '[Accounts] Get Success',
  props<{ account: AccountAPIResponse }>()
);

export const actionAccountGroupGet = createAction(
  '[Accounts] Get Group',
  props<{ id: string }>()
);

export const actionAccountGroupGetSuccess = createAction(
  '[Accounts] Get Group Success',
  props<{ group: AccountGroupAPIResponse }>()
);
