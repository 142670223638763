import { Component, OnDestroy } from '@angular/core';
import { combineLatest, SubscriptionLike as ISubscription } from 'rxjs';
import { Router, NavigationEnd, ResolveEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { find, isEqual } from 'lodash-es';
import { BreadcrumbService } from '@services/breadcrumb/breadcrumb.service';
import { AuthService } from '@services/auth/auth.service';
import { UtilityService } from '@services/utility/utility.service';
import {
  AccountFacade,
  AccountState,
  ActiveSelectionTypes,
} from '@shared/state/global';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import {
  MenuItem,
  ModuleConfig,
} from '@shared/interfaces/common.interface';
import { AppState } from '@shared/state/state';
import { selectPermissionsStateDashboard } from '@shared/state/permissions/permissions.selectors';
import { IntegrationDefinition } from '@shared/interfaces';
import {
  selectAccountIntegrations,
  selectGroupIntegrations,
} from '@shared/state/integrations/new/integrations.data.selectors';
import { IntegrationTypeAndVersion } from '@shared/state/integrations/integration-type-mapping.enum';
import { DashboardPage } from '@integrations/interfaces';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
})
export class AppBreadcrumbComponent implements OnDestroy {
  path: string[] = [];
  breadcrumbItems: { label: string; routerLink: string[] }[] = [];
  state = <AccountState>{};
  authenticated = false;
  items: MenuItem[] = [];
  dashboard = '';
  dashboardPermission: ModuleConfig | undefined;
  integrations: IntegrationDefinition[] = [];
  private subscriptions: ISubscription[] = [];

  constructor(
    public breadcrumbService: BreadcrumbService,
    private utilityService: UtilityService,
    private accountFacade: AccountFacade,
    private store: Store<AppState>,
    private router: Router,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private authService: AuthService
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.authService.apiClient = this.apiClient;
    this.subscriptions.push(
      this.accountFacade.account$.subscribe((state) => {
        this.state = state;
        if (router && this.path.length === 0) {
          this.path = this.utilityService.string2array(router.url, '/');
        }
        if (state) {
          this.initializeBreadcrumb();
        }
      })
    );
    this.authenticated = this.authService.isAuthenticated();
    this.path = this.utilityService.string2array(router.url, '/');
    this.router.events.subscribe((event: any) => {
      this.path = this.utilityService.string2array(event['url'], '/');
      if (event instanceof NavigationEnd || event instanceof ResolveEnd) {
        this.initializeBreadcrumb();
      }
    });
    this.subscriptions.push(
      this.store
        .select(selectPermissionsStateDashboard)
        .subscribe((dashboard) => {
          this.dashboardPermission = dashboard;
          if (dashboard) {
            this.dashboard = dashboard.ModuleNamespace;
          }
        })
    );
    this.subscriptions.push(
      combineLatest([
        this.store.select(selectAccountIntegrations),
        this.store.select(selectGroupIntegrations),
        this.accountFacade.account$,
      ]).subscribe(([accountIntegrations, groupIntegrations, accountState]) => {
        if (accountState && accountState.activeSelection) {
          if (
            accountState.activeSelection.Type === ActiveSelectionTypes.Account
          ) {
            if (accountIntegrations && accountIntegrations.length) {
              if (!isEqual(this.integrations, accountIntegrations)) {
                this.integrations = accountIntegrations;
                this.initializeBreadcrumb();
              }
            }
          } else if (
            accountState.activeSelection.Type ===
            ActiveSelectionTypes.AccountGroup
          ) {
            if (groupIntegrations && groupIntegrations.length) {
              if (!isEqual(this.integrations, groupIntegrations)) {
                this.integrations = groupIntegrations;
                this.initializeBreadcrumb();
              }
            }
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  initializeBreadcrumb(): void {
    this.setDashboardBreadcrumbs();
    this.setFitHomeBreadcrumbs();
    this.setLeaderboardsBreadcrumbs();
    this.setDashboardsBreadcrumbs();
    this.setChangePasswordBreadcrumbs();
    this.setPushNotificationBreadcrumbs();
    this.setAccountDashboardBreadcrumbs();
    this.setPreviewBreadcrumbs();
    this.setCampaignBreadcrumbs();
    this.setLocationsBreadcrumbs();
    this.setMarketingSpendBreadcrumbs();
    this.setCampaignWizardBreadcrumbs();
    this.setFeedsBreadcrumbs();
    this.setCreativesBreadcrumbs();
    this.setTrackingBreadcrumbs();
    this.setEmbeddedDashboardBreadcrumbs();
    this.setManagementBreadcrumbs();
    this.setAttributionsBreadcrumbs();
    this.setQueryBreadcrumbs();
    this.setIntegrationsBreadcrumbs();
    this.setRoadmapBreadcrumbs();
  }

  goToDashboard() {
    const route = this.getHomeLink();
    this.router.navigate(route);
  }

  setAttributionsBreadcrumbs() {
    if (this.path[1] === 'attribution') {
      this.items = [{ label: 'Attribution' }];
    }
  }

  setChangePasswordBreadcrumbs() {
    if (this.path[2] === 'changepassword') {
      this.items = [{ label: 'Change Password' }];
    }
  }

  setPushNotificationBreadcrumbs() {
    if (this.path[2] === 'push-notification') {
      this.items = [{ label: 'Push Notification' }];
    }
  }

  setCreativesBreadcrumbs() {
    if (
      (this.path[1] === 'creatives' &&
        this.path[2] !== 'details' &&
        this.path[2] !== 'upload') ||
      this.path[1] === 'creativedashboardgroup'
    ) {
      this.items = [{ label: 'Creatives' }];
    } else if (this.path[1] === 'creatives' && this.path[2] === 'details') {
      this.items = [
        { label: 'Creatives', routerLink: this.getCreativesHomeLink() },
        { label: 'Creative Details' },
      ];
    } else if (this.path[1] === 'creatives' && this.path[2] === 'upload') {
      this.items = [
        { label: 'Creatives', routerLink: this.getCreativesHomeLink() },
        { label: 'Creative Upload' },
      ];
    }
  }

  setMarketingSpendBreadcrumbs() {
    if (this.path[1] === 'marketing-spend') {
      this.items = [{ label: 'Marketing Spend' }];
    }
  }

  setLocationsBreadcrumbs() {
    if (this.path[1] === 'locations') {
      this.items = [{ label: 'Locations' }];
    }
  }

  setCampaignBreadcrumbs() {
    if (this.path[1] === 'campaign' && this.path[2] === 'summary') {
      this.items = [
        { label: 'Campaigns', routerLink: this.getCampaignHomeLink() },
        { label: 'Campaign Summary' },
      ];
    } else if (
      this.path[1] === 'campaign' &&
      this.path[2] === 'ads' &&
      this.path[4] !== ''
    ) {
      this.items = [
        { label: 'Campaigns', routerLink: this.getCampaignHomeLink() },
        {
          label: 'Campaign Summary',
          routerLink: this.getCampaignSummaryeLink(),
        },
        { label: 'Ad Settings' },
      ];
    } else if (this.path[1] === 'campaign' && this.path[2] === 'settings') {
      this.items = [
        { label: 'Campaigns', routerLink: this.getCampaignHomeLink() },
        { label: 'Campaign Settings' },
      ];
    } else if (this.path[1] === 'campaign' && this.path[2] === 'adtag') {
      this.items = [
        { label: 'Campaigns', routerLink: this.getCampaignHomeLink() },
        {
          label: 'Campaign Summary',
          routerLink: this.getCampaignSummaryeLink(),
        },
        { label: 'Campaign Preview' },
      ];
    } else if (this.path[1] === 'campaign') {
      this.items = [{ label: 'Campaigns' }];
    }
  }

  setCampaignWizardBreadcrumbs() {
    if (
      this.path[1] === 'campaign-wizard' ||
      this.path[1] === 'campaign-wizardgroup'
    ) {
      this.items = [{ label: 'Campaigns' }];
    } else if (this.path[1] === 'campaign-wizard-settings') {
      this.items = [
        { label: 'Campaigns', routerLink: this.getCampaignWizardHomeLink() },
        { label: 'New Campaign' },
      ];
    }
  }

  setDashboardBreadcrumbs(): void {
    if (this.path[1] === 'dashboard') {
      this.items = [{ label: 'Dashboard' }];
    }
  }

  setFitHomeBreadcrumbs(): void {
    if (this.path[1] === 'fit') {
      this.items = [{ label: 'Fit Home' }];
    }
  }

  setLeaderboardsBreadcrumbs(): void {
    if (this.path[1] === 'leaderboards') {
      this.items = [{ label: 'Leaderboards' }];
    }
  }

  setDashboardsBreadcrumbs(): void {
    if (this.path[1] === 'dashboards') {
      this.items = [{ label: 'Dashboards' }];
    }
    if (this.path[2]) {
      const dashboardsIntegration = this.integrations.find(
        (integration) =>
          integration.Type ===
          IntegrationTypeAndVersion.DashboardEngineV2V202203
      );
      if (dashboardsIntegration) {
        const selectedPage: DashboardPage = dashboardsIntegration.Pages?.find(
          (page: DashboardPage) => page.Id === this.path[2]
        );
        if (selectedPage) {
          this.items.push({ label: selectedPage.Title });
        }
      }
    }
  }

  setAccountDashboardBreadcrumbs(): void {
    if (this.path[1] === 'account-dashboard') {
      this.items = [{ label: 'Account Dashboard' }];
    }
  }

  setPreviewBreadcrumbs(): void {
    if (this.path[1] === 'adtag') {
      this.items = [
        { label: 'Campaigns', routerLink: this.getCampaignHomeLink() },
        {
          label: 'Campaign Summary',
          routerLink: this.getCampaignSummaryeLink(),
        },
        { label: 'Ad Preview' },
      ];
    }
  }

  setFeedsBreadcrumbs() {
    if (this.path[1] === 'feed' || this.path[1] === 'feedgroup') {
      this.items = [{ label: 'Feeds' }];
    } else if (this.path[1] === 'feed-upload') {
      this.items = [
        { label: 'Feeds', routerLink: this.getFeedsHomeLink() },
        { label: 'Feed Setting' },
      ];
    } else if (this.path[1] === 'feed-segment') {
      this.items = [
        { label: 'Feeds', routerLink: this.getFeedsHomeLink() },
        { label: 'Feed Segment' },
      ];
    }
  }

  setIntegrationsBreadcrumbs() {
    if (this.path[1] === 'integration' && this.path[2] === 'setup') {
      this.items = [
        { label: 'Integrations', routerLink: this.getIntegrationsHomeLink() },
        { label: 'New' },
      ];
    } else if (this.path[1] === 'integration' && this.path[2] === 'edit') {
      this.items = [
        { label: 'Integrations', routerLink: this.getIntegrationsHomeLink() },
        { label: 'Edit' },
      ];
    } else if (this.path[1] === 'integration') {
      this.items = [{ label: 'Integrations' }];
    }
  }

  setEmbeddedDashboardBreadcrumbs() {
    if (
      this.path[1] === 'embedded-dashboard' ||
      this.path[1] === 'embedded-dashboardgroup'
    ) {
      this.items = [{ label: 'Dashboard' }];
    }
  }

  setManagementBreadcrumbs() {
    if (
      this.path[1] === 'account' &&
      this.path[2] === 'manage' &&
      this.path[3] === 'group'
    ) {
      this.items = [{ label: 'Group Settings' }];
    } else if (this.path[1] === 'account' && this.path[2] === 'manage') {
      this.items = [{ label: 'Account Settings' }];
    }
  }

  setQueryBreadcrumbs() {
    if (
      this.path[1] === 'querybuilder' &&
      (this.path[2] === 'edit' || this.path[2] === 'create')
    ) {
      this.items = [
        { label: 'Queries', routerLink: this.getQueryHomeLink() },
        { label: 'Query Engine' },
      ];
    } else if (this.path[1] === 'querybuilder' && this.path[2] === 'list') {
      this.items = [{ label: 'Queries' }];
    }
  }

  setTrackingBreadcrumbs() {
    if (this.path[1] === 'conversion' && this.path[2] === 'manage') {
      this.items = [
        { label: 'Tracking', routerLink: this.getTrackingHomeLink() },
        { label: 'Edit Conversion' },
      ];
    } else if (this.path[1] === 'conversion') {
      this.items = [{ label: 'Tracking' }];
    }
  }

  setRoadmapBreadcrumbs() {
    if (this.path[1] === 'roadmap') {
      this.items = [{ label: 'Roadmap' }];
    }
  }

  getAdvertiserHomeLink() {
    let link = '';
    if (this.dashboard === 'com.transitiv.dashboard.agency') {
      link = '/dashboard/agency';
    } else if (this.dashboard === 'com.transitiv.dashboard.franchisee') {
      link = '/dashboard/embedded';
    } else {
      link = '/dashboard';
    }

    return this.state && this.state.activeAdvertiser
      ? [link, this.state.activeAdvertiser.AccountId]
      : [link];
  }

  getBuyDetailsHomeLink() {
    return this.state && this.state.activeAdvertiser
      ? [
          '/buy-campaign-details',
          this.state.activeAdvertiser.AccountId,
          this.path[3],
        ]
      : ['/buy-campaign'];
  }

  getBuyHomeLink() {
    return this.state && this.state.activeAdvertiser
      ? ['/buy-campaign', this.state.activeAdvertiser.AccountId]
      : ['/buy-campaign'];
  }

  getBuyAdwordsHomeLink() {
    return this.state && this.state.activeAdvertiser
      ? ['/buy-adwords', this.state.activeAdvertiser.AccountId]
      : ['/buy-adwords'];
  }

  getCampaignHomeLink() {
    return this.state && this.state.activeAdvertiser
      ? ['/campaign', this.state.activeAdvertiser.AccountId]
      : ['/campaign'];
  }

  getCampaignWizardHomeLink() {
    return this.state && this.state.activeAdvertiser
      ? ['/campaign-wizard', this.state.activeAdvertiser.AccountId]
      : ['/campaign-wizard'];
  }

  getCampaignSummaryeLink() {
    return this.state && this.state.activeAdvertiser
      ? [
          '/campaign/summary',
          this.state.activeAdvertiser.AccountId,
          this.path[3],
        ]
      : ['/campaign/summary'];
  }

  getCreativesHomeLink() {
    return this.state && this.state.activeAdvertiser
      ? ['/creatives', this.state.activeAdvertiser.AccountId]
      : ['/creatives'];
  }

  getChatHomeLink() {
    return this.state && this.state.activeAdvertiser
      ? ['/chat', this.state.activeAdvertiser.AccountId]
      : ['/chat'];
  }

  getFeedsHomeLink() {
    return this.state && this.state.activeAdvertiser
      ? ['/feed', this.state.activeAdvertiser.AccountId]
      : ['/feed'];
  }

  getGroupHomeLink() {
    let link = '';
    if (this.dashboard === 'com.transitiv.dashboard.agency') {
      link = '/dashboard/agency/group';
    } else if (this.dashboard === 'com.transitiv.dashboard.franchisee') {
      link = '/dashboard/embedded/group';
    } else {
      link = '/dashboard/group';
    }
    return this.state && this.state.activeAdvertiserGroup
      ? [link, this.state.activeAdvertiserGroup.GroupId]
      : [link];
  }

  getHomeLink() {
    return this.path[1] && this.path[1].includes('group')
      ? this.getGroupHomeLink()
      : this.getAdvertiserHomeLink();
  }

  getIntegrationsHomeLink() {
    return this.state && this.state.activeAdvertiser
      ? ['/integration', this.state.activeAdvertiser.AccountId]
      : ['/integration'];
  }

  getPopupHomeLink() {
    return this.state && this.state.activeAdvertiser
      ? ['/popup', this.state.activeAdvertiser.AccountId]
      : ['/popup'];
  }

  getQueryHomeLink() {
    return this.state && this.state.activeAdvertiser
      ? ['/querybuilder/list', this.state.activeAdvertiser.AccountId]
      : ['/querybuilder/list'];
  }

  getSeoHomeLink() {
    return this.state && this.state.activeAdvertiser
      ? ['/seo', this.state.activeAdvertiser.AccountId]
      : ['/seo'];
  }

  getTrackingHomeLink() {
    return this.state && this.state.activeAdvertiser
      ? ['/conversion', this.state.activeAdvertiser.AccountId]
      : ['/conversion'];
  }

  setParentGroupBreadcrumb() {
    const group = this.state.activeAdvertiserGroup;
    let parent = null;
    if (group.ParentHierarchyPath !== '') {
      const hierachy = this.utilityService.string2array(
        group.ParentHierarchyPath,
        '/'
      );
      hierachy
        .slice()
        .reverse()
        .forEach((element) => {
          parent = find(this.state.advertiserGroups, { GroupId: element });
          if (parent) {
            this.items = [
              {
                label: parent.Name,
                routerLink: ['/' + this.path[1], parent.GroupId],
                entity: parent,
              },
              ...this.items,
            ];
          }
        });
    }
  }
}
