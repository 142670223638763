import { Component } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { LoginService } from '@services/login/login.service';
import { environment } from '@environments/environment';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { ForgotPasswordPayload } from '@shared/interfaces';

@Component({
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  appServer: string;
  logo = environment.defaultLogo;
  error = '';
  isLoading = false;
  complete = false;
  formData = <ForgotPasswordPayload>{};
  isBrowser: boolean;
  constructor(
    private platformLocation: PlatformLocation,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private loginSignupService: LoginService
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.appServer = this.isBrowser
      ? (platformLocation as any).location.origin
      : '';
    this.apiClient.errorHandler = this.errorHandler;
    this.loginSignupService.apiClient = this.apiClient;
  }

  resetPassword() {
    if (this.formData['Email']) {
      this.loginSignupService.doForgotPassword(this.formData).subscribe(
        () => {
          this.complete = true;
          this.isLoading = false;
        },
        (data) => {
          this.isLoading = false;
          this.error =
            data.Message ||
            'There was a problem with your request. Please try again.';
        }
      );
    } else {
      this.error = 'Please enter a valid Email!';
    }
  }
}
