import { AppState } from '../state';
import * as PushNotificationActions from './push-notification.actions';
import { PushNotification } from './push-notification.model';
export type Action = PushNotificationActions.All;

export namespace PushNotificationQuery {
  export const pushNotification = (state: AppState) => state.pushNotification;
}

export function pushNotificationReducer(state: PushNotification, action: Action) {
  switch (action.type) {
  case PushNotificationActions.GET_PUSH_CLAIMS:
    return { ...state, claimsIsLoaded: false, status: action.type};
  case PushNotificationActions.GET_PUSH_CLAIMS_SUCCESS:
    return { ...state, claims: action.payload, claimsIsLoaded: true, status: action.type };
  case PushNotificationActions.GET_PUSH_CLAIMS_FAIL:
    return { ...state, claimsError: action.payload, claimsIsLoaded: true, status: action.type };
  default:
    return state;
  }
}
