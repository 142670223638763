<h1 mat-dialog-title *ngIf="data?.title">{{data.title}}</h1>
<mat-dialog-content class="mat-typography">
  <div
    [innerHTML]="getHtml(data?.message)"
    [ngClass]="{alignCenter: data?.textAlignCenter}"
  ></div>
</mat-dialog-content>
<div class="dialogActions">
  <button
    *ngIf="!data?.omitCancelButton"
    mat-button
    color="warn"
    mat-dialog-close
    id="confirm-dialog-discard"
  >
    {{data?.discardButton || 'Discard'}}
  </button>
  <button
    mat-button
    [mat-dialog-close]="true"
    color="primary"
    id="confirm-dialog-accept"
  >
    {{data?.acceptButton}}
  </button>
</div>
