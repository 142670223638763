import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { OnTouchFunction, OnChangeFunction } from '../types';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line: no-use-before-declare
    useExisting: forwardRef(() => ButtonFormElementComponent),
    multi: true
};


@Component({
  selector: 'app-form-button',
  template: `
    <button mat-button [color]="color" mat-flat-button *ngIf="!buttonType || buttonType === 'flat'"
    [className]="cssClass" [ngStyle]="cssStyle" [type]="type || 'button'" [disabled]="disabled"
    [id]="itemId" (click)="clicked($event)">{{label}}<ng-container *ngTemplateOutlet="contentTpl"></ng-container></button>

    <button mat-button [color]="color" mat-button *ngIf="buttonType === 'basic'"
    [className]="cssClass" [ngStyle]="cssStyle" [type]="type || 'button'" [disabled]="disabled"
    [id]="itemId" (click)="clicked($event)">{{label}}<ng-container *ngTemplateOutlet="contentTpl"></ng-container></button>

    <button [color]="color" mat-raised-button *ngIf="buttonType === 'raised'"
    [className]="cssClass" [ngStyle]="cssStyle" [type]="type || 'button'" [disabled]="disabled" [routerLink]="url"
    [id]="itemId" (click)="clicked($event)">{{label}}<ng-container *ngTemplateOutlet="contentTpl"></ng-container></button>

    <ng-template #contentTpl><ng-content></ng-content></ng-template>
    `,
  styleUrls: ['./button.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class ButtonFormElementComponent implements ControlValueAccessor {
  @Input() label = '';
  @Input() cssClass = '';
  @Input() cssStyle = '';
  @Input() disabled = false;
  @Input() color = 'primary';
  @Input() type = 'button';
  @Input() itemId = '';
  @Input() url = '';
  @Input() buttonType = 'flat';
  @Output() onClick = new EventEmitter<MouseEvent>();
  innerValue = '';
  private onTouchedCallback: OnTouchFunction = noop;
  private onChangeCallback: OnChangeFunction<string> = noop;

  constructor() {
  }

  get value(): string {
    return this.innerValue;
  }

  set value(v: string) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  clicked(e: MouseEvent) {
    this.onBlur();
    if (!this.disabled) {
      this.onClick.emit(e);
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: string) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: OnChangeFunction<string>) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: OnTouchFunction) {
    this.onTouchedCallback = fn;
  }
}
