<div
  id="header"
  class="layout-topbar"
  [ngClass]="{
    'not-confirmed': !userInfo.LoginProviders?.length && showVerificationNotice
  }"
>
  <div
    class="verification-notice row flush"
    *ngIf="!userInfo.LoginProviders?.length && showVerificationNotice"
    [ngClass]="{ show: showVerificationNotice }"
  >
    <p class="epsilon">
      <span class="hide-on-small"
        >A confirmation email has been sent to the email address on file.</span
      >
      <a
        (click)="resendConfirmation()"
        analytics-on
        analytics-event="change"
        analytics-category="GlobalHeader"
        analytics-label="Root_Link_ResendVerificationEmail"
        analytics-value="1"
        >Resend Verification Email
      </a>
      <span class="close stay-right" (click)="closeNotice()">
        <i class="fa fa-close"></i>
      </span>
    </p>
  </div>
  <!-- Global Header -->
  <header class="row flush" id="main-header">
    <!-- Branding (Desktop) -->
    <div
      class="branding hide-on-small"
      [ngClass]="{ sideMenuIsOpen: sideMenuIsOpen }"
    ></div>

    <!-- Main Nav (Desktop & Mobile) -->
    <div
      class="main-nav col-md-4 col-sm-5 col-xs-6"
      style="margin-bottom: 0px;"
    >
      <div *ngIf="userInfo?.Email">
        <app-nav-tree></app-nav-tree>
      </div>
    </div>

    <nav class="user col-sm-5 col-xs-6 stay-right">
      <div class="settings" *ngIf="userInfo.Email">
        <ul style="display: flex; justify-content: flex-end;">
          <li class="email" style="padding-right: 12px;">
            <span class="hide-on-small" id="account-email">{{
              userInfo.Email
            }}</span>
          </li>
          <li class="dropdown account-settings">
            <a
              class="headerIconContainer"
              mat-icon-button
              matTooltip="Help"
              href="https://help.transitiv.io"
              target="_blank"
            >
              <mat-icon>help_outline</mat-icon>
            </a>
          </li>
          <li class="dropdown account-settings">
            <button
              class="headerIconContainer"
              mat-icon-button
              (click)="goToRoadmapPage()"
              matTooltip="Roadmap"
            >
              <mat-icon>map</mat-icon>
            </button>
          </li>
          <li class="dropdown account-settings">
            <app-notification-panel></app-notification-panel>
          </li>
          <li class="dropdown account-settings change-log-item"></li>
          <li
            class="dropdown account-settings stay-right"
            id="settings-menu-items"
          >
            <button
              class="headerIconContainer"
              mat-icon-button
              (click)="goToManagePage()"
              id="settings-item"
              [matTooltip]="
                state &&
                state.activeSelection &&
                state.activeSelection.Type === 'Advertiser Group'
                  ? 'Group'
                  : 'Account' + ' Settings'
              "
            >
              <mat-icon>settings</mat-icon>
            </button>
          </li>
          <li class="dropdown user-settings stay-right" id="account-menu-items">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="More"
              data-cy="account-menu"
              #menuTrigger="matMenuTrigger"
              (mouseenter)="menuTrigger.openMenu()"
              (mouseover)="menuTrigger.openMenu()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu
              #menu="matMenu"
              style="z-index: inherit 1;"
              [overlapTrigger]="false"
              direction="down"
              yPosition="below"
            >
              <span (mouseleave)="menuTrigger.closeMenu()">
                <button
                  mat-menu-item
                  id="account-password"
                  [routerLink]="['/account/changepassword']"
                >
                  <mat-icon>account_box</mat-icon>
                  <span>Change Password</span>
                </button>
                <button
                  mat-menu-item
                  class="logout"
                  id="account-logout"
                  data-cy="logout-button"
                  (click)="logout()"
                >
                  <mat-icon>lock</mat-icon>
                  <span>Logout</span>
                </button>
              </span>
            </mat-menu>
          </li>
        </ul>
      </div>
    </nav>
    <!-- </nav> -->
  </header>
</div>
<div class="header_card">
  <h1 id="page-header-title">
    <span
      class="instancename pull-right"
      [matTooltip]="pageContext"
      matTooltipPosition="below"
      tooltipZIndex="1000000"
      >{{ pageContext }}</span
    >
  </h1>
  <div class="tabflex">
    <div class="area breadcrumbs"><app-breadcrumb></app-breadcrumb></div>
    <div
      *ngIf="tabMenuItems && tabMenuItems.length > 0"
      class="area bg-alt tabMenuContainer"
    >
      <nav mat-tab-nav-bar>
        <a
          mat-tab-link
          *ngFor="let link of tabMenuItems"
          (click)="onTabClick(link)"
          [active]="activeLink == link"
        >
          {{ link.label }}
        </a>
      </nav>
    </div>
  </div>
  <app-loading-bar></app-loading-bar>
</div>
