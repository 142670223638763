import { Action } from '@ngrx/store';
import { IntegrationDefinition } from '../../interfaces';

export const INTEGRATIONS_GETALL = 'Integrations Get all';
export const INTEGRATIONS_GETALL_FAILED = 'Integrations Get all failed';
export const INTEGRATIONS_GETALL_SUCCESS = 'Integrations Get all successful';

export const INTEGRATIONS_GET = 'Integrations Get one';
export const INTEGRATIONS_GET_FAILED = 'Integrations Get one failed';
export const INTEGRATIONS_GET_SUCCESS = 'Integrations Get one successful';

export const INTEGRATIONS_CLEARED = 'Integration cleared';

export const INTEGRATIONS_UPDATE_GLOBAL = 'Integrations Update global state';

export class IntegrationsGetAll implements Action {
  readonly type = INTEGRATIONS_GETALL;
  constructor(public payload: string) {}
}

export class IntegrationsGetAllSuccess implements Action {
  readonly type = INTEGRATIONS_GETALL_SUCCESS;
  constructor(public payload: IntegrationDefinition[]) {}
}

export class IntegrationsGetAllFailed implements Action {
  readonly type = INTEGRATIONS_GETALL_FAILED;
  constructor(public payload: string) {}
}

export class IntegrationsGet implements Action {
  readonly type = INTEGRATIONS_GET;
  constructor(public id: string, public intId: string) {}
}

export class IntegrationsGetSuccess implements Action {
  readonly type = INTEGRATIONS_GET_SUCCESS;
  constructor(public payload: IntegrationDefinition) {}
}
export class IntegrationsCleared implements Action {
  readonly type = INTEGRATIONS_CLEARED;
  constructor() {}
}

export class IntegrationsGetFailed implements Action {
  readonly type = INTEGRATIONS_GET_FAILED;
  constructor(public payload: string) {}
}

export class IntegrationsSetGlobalState implements Action {
  readonly type = INTEGRATIONS_UPDATE_GLOBAL;
  constructor() {}
}

export type All =
  | IntegrationsGetAll
  | IntegrationsGetAllFailed
  | IntegrationsGetAllSuccess
  | IntegrationsGet
  | IntegrationsGetFailed
  | IntegrationsGetSuccess
  | IntegrationsCleared
  | IntegrationsSetGlobalState;
