import { Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
declare var __: any;

@Injectable()
export class AppHttpHeaders {
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }
  createHeaders() {
    if (this.isBrowser) {
      const httpOptions = new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'bearer ' + (__.cookie.get('x-bearer') || null)
      });
      return httpOptions;
    }
    return;
  }

  createDownloadHeaders() {
    if (this.isBrowser) {
      const httpOptions = new HttpHeaders({
        'Accept': 'text/csv',
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'bearer ' + (__.cookie.get('x-bearer') || null)
      });
      return httpOptions;
    }
    return;
  }

  createUrlHeaders() {
    const httpOptions = {
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'bearer ' + (__.cookie.get('x-bearer') || null)
    };
    return httpOptions;
  }

  createAuthHeaders() {
    if (this.isBrowser) {
      const httpOptions = new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      });
      return httpOptions;
    }
    return;
  }

  createUploadHeaders() {
    if (this.isBrowser) {
      const httpOptions = new HttpHeaders({
        'Authorization': 'bearer ' + (__.cookie.get('x-bearer') || null)
      });
      return httpOptions;
    }
    return;
  }
}
