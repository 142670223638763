import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MediaBuyingAnalytic } from '../../shared/interfaces/media-buying-analytic.interface';
import {
  MediaAnalyticsPayload,
  AdGroupPayload,
  AttributionFilter,
  AttributionBudgetPayload,
  ChannelGrouping,
  PagingFilter,
  ConfidenceIntervalPayload,
  ConfidenceIntervalResponse,
  AnalyticsChartPayload,
  FacebookReportPayload,
  MarketingBenchmarkPayload,
  MarketingSpendEditPayload,
} from '../../shared/interfaces';
import * as moment from 'moment';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { AnalyticsDateRange, ConversionViewThrough,
  AnalyticsTag, ChartAnalytics, AudienceSummary,
  FacebookReport, AdperformanceReport, AttributionBudgetAllocation, MarketingBudget, MarketingMetric, MarkovResponse } from '../../shared/interfaces/analytics.interface';
import { AmbiguousData } from '../../shared/interfaces/common.interface';
import { DateRange } from '../../shared/interfaces/date.interface';

@Injectable()
export class AnalyticsService {
  public apiClient = <ApiClient>{};

  constructor() {}

  postAttributionFilter(
    advertiserId: string,
    data: AttributionFilter
  ): Observable<MarkovResponse> {
    return this.apiClient.callHttpPost({
      path: `/analytics/${advertiserId}/attribution/markov/run`,
      param: data,
      type: '',
    });
  }

  postBudgetAllocation(
    advertiserId: string,
    data: AttributionBudgetPayload
  ): Observable<AttributionBudgetAllocation> {
    return this.apiClient.callHttpPost({
      path: `/analytics/${advertiserId}/attribution/markov/budgetallocation`,
      type: '',
      param: data,
    });
  }

  postConfidenceInterval(
    payload: ConfidenceIntervalPayload[]
  ): Observable<ConfidenceIntervalResponse[]> {
    return this.apiClient.callHttpPost({
      path: `/analytics/attribution/confidenceintervals`,
      type: '',
      param: payload,
    });
  }

  getMediaBuyingAnalytics(
    payload: MediaAnalyticsPayload
  ): Observable<MediaBuyingAnalytic[]> {
    const FromDate: string = moment(payload.fromDate).format('YYYY-MM-DD');
    const ToDate: string = moment(payload.toDate).format('YYYY-MM-DD');
    let analyticsUrl;
    if (!payload.adGroupId) {
      analyticsUrl = `/analytics/${payload.advertiserId}/campaigns/${payload.campaignId}/00000000000000000000000000000000/`;
    } else {
      analyticsUrl = `/analytics/${payload.advertiserId}/campaigns/${payload.campaignId}/${payload.adGroupId}/`;
    }

    analyticsUrl += `${payload.timeRollup}/${FromDate}/${ToDate}`;
    return this.apiClient.callHttpGet({ path: analyticsUrl, type: '' });
  }

  getBuyingAnalytics(
    payload: MediaAnalyticsPayload
  ): Observable<MediaBuyingAnalytic[]> {
    const FromDate: string = moment(payload.fromDate).format('YYYY-MM-DD');
    const ToDate: string = moment(payload.toDate).format('YYYY-MM-DD');
    let analyticsUrl;
    analyticsUrl = `/analytics/${payload.advertiserId}/campaigns/00000000000000000000000000000000/00000000000000000000000000000000/`;

    analyticsUrl += `${payload.timeRollup}/${FromDate}/${ToDate}`;
    return this.apiClient.callHttpGet({ path: analyticsUrl, type: '' });
  }

  getViewThroughs(advertiserId: string, campaignId: string, dateRange: AnalyticsDateRange): Observable<ConversionViewThrough[]> {
    return this.apiClient.callHttpGet({
      path: `/analytics/${advertiserId}/campaigns/${campaignId}/viewthroughs/${dateRange.startDate}/${dateRange.endDate}`,
      type: '',
    });
  }
  getViewThroughCounts(
    advertiserId: string,
    campaignId: string,
    dateRange: AnalyticsDateRange
  ) {
    return this.apiClient.callHttpGet({
      path: `/analytics/${advertiserId}/campaigns/${campaignId}/viewthroughscount/${dateRange.startDate}/${dateRange.endDate}`,
      type: '',
    });
  }

  getChannelGroupings(advertiserId: string): Observable<ChannelGrouping[]> {
    return this.apiClient.callHttpGet({
      path: `/${advertiserId}/channelgroupings`,
    });
  }

  getCompareTags(advertiserId: string): Observable<AnalyticsTag[]> {
    return this.apiClient.callHttpGet({
      path: `/analytics/tags/${advertiserId}`,
      type: '',
    });
  }

  getGroupCompareTags(advertiserId: string): Observable<AnalyticsTag[]> {
    return this.apiClient.callHttpGet({
      path: `/analytics/${advertiserId}/tags`,
      type: '',
    });
  }

  getChart(advertiserId: string, payload: AnalyticsChartPayload): Observable<ChartAnalytics> {
    return this.apiClient.callHttpPost({
      path: `/analytics/charts/${advertiserId}`,
      param: payload,
      type: '',
    });
  }

  getLeadsChart(advertiserId: string, payload: AnalyticsDateRange): Observable<AmbiguousData[]> {
    return this.apiClient.callHttpGet({
      path: `/analytics/charts/${advertiserId}/leads/${payload.startDate}/${payload.endDate}`,
      type: '',
      param: payload,
    });
  }

  getFacebookChart(advertiserId: string, payload: FacebookReportPayload): Observable<FacebookReport[]> {
    return this.apiClient.callHttpPost({
      path: `/charts/facebookreports/${advertiserId}`,
      param: payload,
      type: '',
      version: `/v201906/analytics`,
    });
  }

  getGroupChart(advertiserId: string, payload: AnalyticsChartPayload): Observable<ChartAnalytics> {
    return this.apiClient.callHttpPost({
      path: `/analytics/${advertiserId}/charts`,
      param: payload,
      type: '',
    });
  }

  setChannelGroupings(advertiserId: string, payload: ChannelGrouping[]) {
    return this.apiClient.callHttpPostWithoutResponse({
      path: `/${advertiserId}/channelgroupings`,
      param: payload,
    });
  }

  deleteAdGroup(payload: AdGroupPayload) {
    return this.apiClient.callHttpDelete({
      path: `/${payload.advertiserId}/${payload.campaignId}/adgroups/${payload.adGroupId}`,
    });
  }

  getAudienceSummary(id: string, filter?: PagingFilter): Observable<AudienceSummary[]> {
    let apiUrl = `/${id}/audience/summary`;
    if (filter) {
      apiUrl += `/${filter.pageNum}/${filter.pageSize}/${filter.startDate}/${filter.endDate}`;
    }
    return this.apiClient.callHttpGet({ path: apiUrl });
  }

  getAdPerformance(advertiserId: string, payload: AnalyticsDateRange): Observable<AdperformanceReport[]> {
    return this.apiClient.callHttpGet({
      path: `/analytics/charts/${advertiserId}/adwordsreports/adperformance/${payload.startDate}/${payload.endDate}`,
      param: payload,
      type: '',
    });
  }

  getMarketingBenchmarks(advertiserGroupId: string, payload: MarketingBenchmarkPayload) {
    return this.apiClient.callHttpGet({
      type: '',
      // tslint:disable-next-line: max-line-length
      path: `/analytics/${advertiserGroupId}/benchmarks/${payload.selectedBenchmark}/${payload.startDate}/${payload.endDate}`
    });
  }

  callBenchmarkNamesApi(AdvertiserGroupId: string): Observable<string[]> {
    return this.apiClient.callHttpGet({
      type: '',
      path: `/analytics/${AdvertiserGroupId}/benchmarks/benchmarknames`
    });
  }

  allMarketingSpendApi(advertiserId: string, payload: DateRange): Observable<MarketingBudget[]> {
    return this.apiClient.callHttpGet({
      type: '',
      path: `/analytics/marketingspend/data/${advertiserId}/${payload.DateStart}/${payload.DateEnd}`
    });
  }

  callChannelsApi(advertiserGroupId: string): Observable<MarketingMetric[]> {
    const base = '/analytics/marketingspend/';
    return this.apiClient.callHttpGet({
      type: '',
      path: `${base}${advertiserGroupId}/channels`
    });
  }

  callVendorsApi(advertiserGroupId: string): Observable<MarketingMetric[]> {
    const base = '/analytics/marketingspend/';
    return this.apiClient.callHttpGet({
      type: '',
      path: `${base}${advertiserGroupId}/vendors`
    });
  }

  saveMarketingSpend(advertiserId: string, payload: MarketingSpendEditPayload[]) {
    return this.apiClient.callHttpPost({
      type: '',
      path: `/analytics/marketingspend/data/${advertiserId}/submit`,
      param: payload
    });
  }
}
