import { Action } from '@ngrx/store';
import { Creative,
  AdEvent,
  CreativeEvent,
  CreativeVariation,
  IndirectCreativePayload,
  GetCreativeEventsSegmentPayload,
  Account
} from '../../../interfaces';

export const GET_DETAILS_CREATIVE = 'CreativeDetails get creative';
export const GET_DETAILS_CREATIVE_SUCCESS = 'CreativeDetails get creative success';
export const GET_DETAILS_CREATIVE_FAIL = 'CreativeDetails get creative fail';

export const SAVE_DETAILS_CREATIVE = 'CreativeDetails save creative';
export const SAVE_DETAILS_CREATIVE_SUCCESS = 'CreativeDetails save creative success';
export const SAVE_DETAILS_CREATIVE_FAIL = 'CreativeDetails save creative fail';

export const DELETE_DETAILS_CREATIVE = 'CreativeDetails delete creative';
export const DELETE_DETAILS_CREATIVE_SUCCESS = 'CreativeDetails delete creative success';
export const DELETE_DETAILS_CREATIVE_FAIL = 'CreativeDetails delete creative fail';

export const GET_DETAILS_ADVERTISERS = 'CreativeDetails get advertisers';
export const GET_DETAILS_ADVERTISERS_SUCCESS = 'CreativeDetails get advertisers success';
export const GET_DETAILS_ADVERTISERS_FAIL = 'CreativeDetails get advertisers fail';

export const GET_DETAILS_EVENTS = 'CreativeDetails get events';
export const GET_DETAILS_EVENTS_SUCCESS = 'CreativeDetails get events success';
export const GET_DETAILS_EVENTS_FAIL = 'CreativeDetails get events fail';

export const GET_DETAILS_VARIATIONS = 'CreativeDetails get variations';
export const GET_DETAILS_VARIATIONS_SUCCESS = 'CreativeDetails get variations success';
export const GET_DETAILS_VARIATIONS_FAIL = 'CreativeDetails get variations fail';

export const GET_DETAILS_CREATIVE_EVENTS = 'CreativeDetails get creative events';
export const GET_DETAILS_CREATIVE_EVENTS_SUCCESS = 'CreativeDetails get creative events success';
export const GET_DETAILS_CREATIVE_EVENTS_FAIL = 'CreativeDetails get creative events fail';

export const RESET_DETAILS_STATE = 'CreativeDetails reset';

export class ResetDetailsState implements Action {
  readonly type = RESET_DETAILS_STATE;
  constructor() {}
}

export class GetDetailsCreative implements Action {
  readonly type = GET_DETAILS_CREATIVE;
  constructor(public id: string, public cid: string) {}
}

export class GetDetailsCreativesSuccess implements Action {
  readonly type = GET_DETAILS_CREATIVE_SUCCESS;
  constructor(public payload: Creative) {}
}

export class GetDetailsCreativesFail implements Action {
  readonly type = GET_DETAILS_CREATIVE_FAIL;
  constructor(public payload?: string) {}
}

export class SaveDetailsCreative implements Action {
  readonly type = SAVE_DETAILS_CREATIVE;
  constructor(public id: string, public cid: string, public payload: IndirectCreativePayload) {}
}

export class SaveDetailsCreativeSuccess implements Action {
  readonly type = SAVE_DETAILS_CREATIVE_SUCCESS;
  constructor() {}
}

export class SaveDetailsCreativeFail implements Action {
  readonly type = SAVE_DETAILS_CREATIVE_FAIL;
  constructor(public payload?: string) {}
}

export class DeleteDetailsCreative implements Action {
  readonly type = DELETE_DETAILS_CREATIVE;
  constructor(public id: string, public payload: string) {}
}

export class DeleteDetailsCreativeSuccess implements Action {
  readonly type = DELETE_DETAILS_CREATIVE_SUCCESS;
  constructor() {}
}

export class DeleteDetailsCreativeFail implements Action {
  readonly type = DELETE_DETAILS_CREATIVE_FAIL;
  constructor(public payload?: string) {}
}

export class GetDetailsAdvertisers implements Action {
  readonly type = GET_DETAILS_ADVERTISERS;
  constructor(public payload: string) {}
}

export class GetDetailsAdvertisersSuccess implements Action {
  readonly type = GET_DETAILS_ADVERTISERS_SUCCESS;
  constructor(public payload: Account) {}
}

export class GetDetailsAdvertisersFail implements Action {
  readonly type = GET_DETAILS_ADVERTISERS_FAIL;
  constructor(public payload?: string) {}
}

export class GetDetailsEvents implements Action {
  readonly type = GET_DETAILS_EVENTS;
  constructor() {}
}

export class GetDetailsEventsSuccess implements Action {
  readonly type = GET_DETAILS_EVENTS_SUCCESS;
  constructor(public payload: AdEvent[]) {}
}

export class GetDetailsEventsFail implements Action {
  readonly type = GET_DETAILS_EVENTS_FAIL;
  constructor(public payload?: string) {}
}

export class GetDetailsCreativeEvents implements Action {
  readonly type = GET_DETAILS_CREATIVE_EVENTS;
  constructor(public id: string,
    public cid: string,
    public vid: string,
    public payload: GetCreativeEventsSegmentPayload
  ) {}
}

export class GetDetailsCreativeEventsSuccess implements Action {
  readonly type = GET_DETAILS_CREATIVE_EVENTS_SUCCESS;
  constructor(public payload: CreativeEvent[]) {}
}

export class GetDetailsCreativeEventsFail implements Action {
  readonly type = GET_DETAILS_CREATIVE_EVENTS_FAIL;
  constructor(public payload?: string) {}
}

export class GetDetailsVaritions implements Action {
  readonly type = GET_DETAILS_VARIATIONS;
  constructor(public id: string,
    public cid: string,
    public vid: string,
    public primary = true
  ) {}
}

export class GetDetailsVaritionsSuccess implements Action {
  readonly type = GET_DETAILS_VARIATIONS_SUCCESS;
  constructor(public payload: CreativeVariation, public primary = true) {}
}

export class GetDetailsVaritionsFail implements Action {
  readonly type = GET_DETAILS_VARIATIONS_FAIL;
  constructor(public payload?: string) {}
}


export type AllDetails
  = GetDetailsCreative
  | GetDetailsCreativesSuccess
  | GetDetailsCreativesFail
  | GetDetailsAdvertisers
  | GetDetailsAdvertisersSuccess
  | GetDetailsAdvertisersFail
  | GetDetailsEvents
  | GetDetailsEventsSuccess
  | GetDetailsEventsFail
  | GetDetailsVaritions
  | GetDetailsVaritionsSuccess
  | GetDetailsVaritionsFail
  | GetDetailsCreativeEvents
  | GetDetailsCreativeEventsSuccess
  | GetDetailsCreativeEventsFail
  | SaveDetailsCreative
  | SaveDetailsCreativeSuccess
  | SaveDetailsCreativeFail
  | DeleteDetailsCreative
  | DeleteDetailsCreativeSuccess
  | DeleteDetailsCreativeFail
  | ResetDetailsState;
