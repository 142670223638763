import { Action } from '@ngrx/store';
import {
  StringKeyStringNumberValue,
} from '../../interfaces/common.interface';

export const DASHBOARD_EXECUTE_QUERY = 'Dashboard Execute Query';
export const DASHBOARD_EXECUTE_QUERY_SUCCESS =
  'Dashboard Execute Query Successful';
export const DASHBOARD_EXECUTE_QUERY_FAILED = 'Dashboard Execute Query failed';

export class DashboardExecuteQuery implements Action {
  readonly type = DASHBOARD_EXECUTE_QUERY;
  constructor(
    public payload: { Query: string },
    public id: string,
    public queryType: string
  ) {}
}

export class DashboardExecuteQuerySuccess implements Action {
  readonly type = DASHBOARD_EXECUTE_QUERY_SUCCESS;
  constructor(
    public payload: StringKeyStringNumberValue[],
    public queryType: string
  ) {}
}

export class DashboardExecuteQueryFailed implements Action {
  readonly type = DASHBOARD_EXECUTE_QUERY_FAILED;
  constructor(public queryType: string, public errorMessage?: string) {}
}


export type All = DashboardExecuteQuery
  | DashboardExecuteQuerySuccess
  | DashboardExecuteQueryFailed;
