import { Action } from '@ngrx/store';
import {
  AccountGroup, SearchResult, Account
} from '../../../interfaces';
import { UserWhoIsInfo, ResourceClaim } from '../../../interfaces/claim.interface';
import { AccountState } from './account.model';

export const GET_MANAGEMENT_GROUPS = 'Account get groups';
export const GET_MANAGEMENT_GROUPS_SUCCESS = 'Account get groups success';
export const GET_MANAGEMENT_GROUPS_FAIL = 'Account get groups fail';

export const GET_MANAGEMENT_GROUP_ADVERTISERS = 'Account get group advertisers';
export const GET_MANAGEMENT_GROUP_ADVERTISERS_SUCCESS = 'Account get group advertisers success';
export const GET_MANAGEMENT_GROUP_ADVERTISERS_FAIL = 'Account get group advertisers fail';

export const GET_MANAGEMENT_ALLOWED_ADVERTISER = 'Account get allowed advertisers';
export const GET_MANAGEMENT_ALLOWED_ADVERTISER_SUCCESS = 'Account get allowed advertisers success';
export const GET_MANAGEMENT_ALLOWED_ADVERTISER_FAIL = 'Account get allowed advertisers fail';

export const SET_MANAGEMENT_GROUP_ADVERTISERS = 'Account set group advertisers';

export const GET_MANAGEMENT_USER = 'Account get user';
export const GET_MANAGEMENT_USER_SUCCESS = 'Account get user success';
export const GET_MANAGEMENT_USER_FAIL = 'Account get user fail';

export const GET_MANAGEMENT_USER_CLAIMS = 'Account get user claims';
export const GET_MANAGEMENT_USER_CLAIMS_SUCCESS = 'Account get user claims success';
export const GET_MANAGEMENT_USER_CLAIMS_FAIL = 'Account get user claims fail';

export const SEARCH_MANAGEMENT_DATA = 'Account search data';
export const SEARCH_MANAGEMENT_DATA_SUCCESS = 'Account search data success';
export const SEARCH_MANAGEMENT_DATA_FAIL = 'Account search data fail';

export const LOAD_MANAGEMENT_STATE = 'Account init state';
export const SET_MANAGEMENT_SELECTED_GROUP = 'Account set selected group';
export const SET_MANAGEMENT_SELECTED_ADVERTISER = 'Account set selected advertiser';
export const SET_MANAGEMENT_DELETED_ADVERTISER = 'Account set deleted advertiser';

export const RESET_MANAGEMENT_STATE = 'Account reset state';
export const SET_MANAGEMENT_SHOW_INACTIVE = 'Account set state show inactive';


export class LoadAccountState implements Action {
  readonly type = LOAD_MANAGEMENT_STATE;
  constructor(public payload: AccountState) {}
}

export class ResetAccountState implements Action {
  readonly type = RESET_MANAGEMENT_STATE;
  constructor() {}
}

export class SetAccountSelectedGroup implements Action {
  readonly type = SET_MANAGEMENT_SELECTED_GROUP;
  constructor(public payload: AccountGroup) {}
}

export class SetAccountSelectedAdvertiser implements Action {
  readonly type = SET_MANAGEMENT_SELECTED_ADVERTISER;
  constructor(public payload: Account) {}
}

export class SetAccountDeletedAdvertiser implements Action {
  readonly type = SET_MANAGEMENT_DELETED_ADVERTISER;
  constructor(public payload: string) {}
}

export class GetAccountUser implements Action {
  readonly type = GET_MANAGEMENT_USER;
  constructor() {}
}

export class GetAccountUserSuccess implements Action {
  readonly type = GET_MANAGEMENT_USER_SUCCESS;
  constructor(public payload: UserWhoIsInfo[]) {}
}

export class GetAccountUserFail implements Action {
  readonly type = GET_MANAGEMENT_USER_FAIL;
  constructor(public payload?: string) {}
}

export class GetUserClaims implements Action {
  readonly type = GET_MANAGEMENT_USER_CLAIMS;
  constructor() {}
}

export class GetUserClaimsSuccess implements Action {
  readonly type = GET_MANAGEMENT_USER_CLAIMS_SUCCESS;
  constructor(public payload: ResourceClaim[]) {}
}

export class GetUserClaimsFail implements Action {
  readonly type = GET_MANAGEMENT_USER_CLAIMS_FAIL;
  constructor(public payload?: string) {}
}

export class GetAccountGroups implements Action {
  readonly type = GET_MANAGEMENT_GROUPS;
  constructor() {}
}

export class GetAccountGroupsSuccess implements Action {
  readonly type = GET_MANAGEMENT_GROUPS_SUCCESS;
  constructor() {}
}

export class GetAccountGroupsFail implements Action {
  readonly type = GET_MANAGEMENT_GROUPS_FAIL;
  constructor(public payload?: string) {}
}

export class SearchAccountData implements Action {
  readonly type = SEARCH_MANAGEMENT_DATA;
  constructor(public payload: string) {}
}

export class SearchAccountDataSuccess implements Action {
  readonly type = SEARCH_MANAGEMENT_DATA_SUCCESS;
  constructor(public payload: SearchResult) {}
}

export class SearchAccountDataFail implements Action {
  readonly type = SEARCH_MANAGEMENT_DATA_FAIL;
  constructor(public payload?: string) {}
}

export class GetAccountGroupAdvertisers implements Action {
  readonly type = GET_MANAGEMENT_GROUP_ADVERTISERS;
  constructor() {}
}

export class GetAccountGroupAdvertisersSuccess implements Action {
  readonly type = GET_MANAGEMENT_GROUP_ADVERTISERS_SUCCESS;
  constructor(public payload: Account) {}
}

export class SetAccountGroupAdvertisers implements Action {
  readonly type = SET_MANAGEMENT_GROUP_ADVERTISERS;
  constructor(public payload: Account[]) {}
}

export class GetAccountGroupAdvertisersFail implements Action {
  readonly type = GET_MANAGEMENT_GROUP_ADVERTISERS_FAIL;
  constructor(public payload?: string) {}
}

export class GetAccountAllowedAdvertiser implements Action {
  readonly type = GET_MANAGEMENT_ALLOWED_ADVERTISER;
  constructor(public id: string) {}
}

export class GetAccountAllowedAdvertiserSuccess implements Action {
  readonly type = GET_MANAGEMENT_ALLOWED_ADVERTISER_SUCCESS;
  constructor(public payload: Account) {}
}

export class GetAccountAllowedAdvertiserFail implements Action {
  readonly type = GET_MANAGEMENT_ALLOWED_ADVERTISER_FAIL;
  constructor(public payload?: string) {}
}

export class SetAccountShowInactive implements Action {
  readonly type = SET_MANAGEMENT_SHOW_INACTIVE;
  constructor(public payload: boolean) {}
}


export type All
  = GetAccountGroups
  | GetAccountGroupsSuccess
  | GetAccountGroupsFail
  | LoadAccountState
  | SetAccountSelectedGroup
  | GetAccountGroupAdvertisers
  | GetAccountGroupAdvertisersSuccess
  | GetAccountGroupAdvertisersFail
  | SetAccountSelectedAdvertiser
  | SetAccountDeletedAdvertiser
  | GetAccountUser
  | GetAccountUserSuccess
  | GetAccountUserFail
  | GetUserClaims
  | GetUserClaimsSuccess
  | GetUserClaimsFail
  | SearchAccountData
  | SearchAccountDataSuccess
  | SearchAccountDataFail
  | ResetAccountState
  | SetAccountGroupAdvertisers
  | SetAccountShowInactive
  | GetAccountAllowedAdvertiser
  | GetAccountAllowedAdvertiserSuccess
  | GetAccountAllowedAdvertiserFail;
