import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as SideMenuActions from './side-menu-status.actions';
import { AppState } from '../state';

@Injectable()
export class SideMenuStatusFacade {
  constructor(private store: Store<AppState>) {}

  sideMenuStatusChange(sideMenuIsOpen: boolean) {
    this.store.dispatch(
      SideMenuActions.actionChangeInSideMenuStatus({
        sideMenuIsOpen,
      })
    );
  }
}
