import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator} from '@ngrx/data';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityMap } from '../entity/entity-metadata';
import { ResourceClaim } from '../../interfaces/claim.interface';
import { CommonUtilityService } from '@services/utility/common-utility.service';
import { ClaimsService } from '@services/claims/claims.service';

@Injectable()
export class UserClaimsDataService extends DefaultDataService<ResourceClaim> {

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private claimsService: ClaimsService
  ) {
    super(EntityMap.UserClaim, http, httpUrlGenerator);
  }

  getAll(): Observable<ResourceClaim[]> {
    return this.claimsService.getUserClaims().pipe(
      map((claims) => claims.map(claim => CommonUtilityService.timestampStateData(claim))));
  }
}
