import { Action } from '@ngrx/store';
import { CreativeDiagram, CreativeToggle } from '../../../interfaces';

export const SET_ANALYSIS_VISUAL = 'CreativeAnalysis set visual';
export const SET_ANALYSIS_HEATMAP = 'CreativeAnalysis set heatmap';
export const SET_ANALYSIS_LABEL = 'CreativeAnalysis set label';
export const SET_ANALYSIS_LEGEND = 'CreativeAnalysis set legend';
export const SET_ANALYSIS_DIAGRAM = 'CreativeAnalysis set diagram';
export const SET_ANALYSIS_TOGGLE = 'CreativeAnalysis set toggle';

export const RESET_ANALYSIS_STATE = 'CreativeAnalysis reset';

export class ResetAnalysisState implements Action {
  readonly type = RESET_ANALYSIS_STATE;
  constructor() {}
}
export class SetAnalysisVisual implements Action {
  readonly type = SET_ANALYSIS_VISUAL;
  constructor(public payload: string) {}
}

export class SetAnalysisHeatmap implements Action {
  readonly type = SET_ANALYSIS_HEATMAP;
  constructor(public payload: string) {}
}

export class SetAnalysisLabel implements Action {
  readonly type = SET_ANALYSIS_LABEL;
  constructor(public payload: string) {}
}

export class SetAnalysisLegend implements Action {
  readonly type = SET_ANALYSIS_LEGEND;
  constructor(public payload: string) {}
}

export class SetAnalysisDiagram implements Action {
  readonly type = SET_ANALYSIS_DIAGRAM;
  constructor(public payload: CreativeDiagram) {}
}

export class SetAnalysisToggle implements Action {
  readonly type = SET_ANALYSIS_TOGGLE;
  constructor(public payload: CreativeToggle) {}
}


export type AllAnalysis
  = SetAnalysisVisual
  | SetAnalysisHeatmap
  | SetAnalysisLabel
  | SetAnalysisLegend
  | SetAnalysisDiagram
  | SetAnalysisToggle
  | ResetAnalysisState;
