<div
  class="layout-wrapper"
  (click)="onWrapperClick()"
  *ngIf="authenticated"
  [ngClass]="{
    'layout-wrapper-static': layoutStatic,
    'layout-wrapper-active': mobileMenuActive
  }"
>
  <div
    class="app-loading"
    *ngIf="displayLoader"
    [ngClass]="{
      'fade-in-500': !moduleConfigLoadingComplete,
      'fade-out': moduleConfigLoadingComplete
    }"
  >
    <div class="logo"></div>
    <svg class="spinner" viewBox="25 25 50 50">
      <circle
        class="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
  <app-sidebar></app-sidebar>
  <div class="layout-main">
    <app-header></app-header>

    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>

    <div class="layout-main-mask" *ngIf="mobileMenuActive"></div>
  </div>
</div>
