import { Component, OnInit, OnDestroy } from '@angular/core';
import { find } from 'lodash-es';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AdvertiserService } from '@services/advertiser/advertiser.service';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import {
  AccountFacade,
  AccountState,
  ActiveSelectionTypes,
} from '@shared/state/global';
import { AccountGroup, Account } from '@shared/interfaces';
import { BreadcrumbDefinition } from '../../../directives/tree-display/tree-display.directive';
import { RouteStateService } from '@services/route-state/route-state.service';
import { AppState } from '@shared/state/state';
import { selectModuleConfigAndStatus } from '@shared/state/module-config/module-config.data.selectors';
import { ModuleConfig } from '@shared/interfaces/common.interface';
import { dashboardPermissionHierarchy } from '@shared/models/permissions.model';
import { RouteUrlDetails } from '@shared/interfaces/routes.interface';
import { GlobalFilterFacade } from '../../../../shared/state/global-filter';

@Component({
  selector: 'app-loader',
  templateUrl: './nav-panel.component.html',
  styleUrls: ['./nav-panel.component.scss'],
})
export class NavPanelComponent implements OnInit, OnDestroy {
  groupChildren: { [groupId: string]: (AccountGroup | Account)[] } = {};
  searchMode = false;
  showProgress = false;
  statuses = [
    { label: 'All', value: 'all' },
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
  ];
  status = 'all';
  keyword = '';
  private accountSubscription: ISubscription | undefined;
  state = <AccountState>{};
  selectedGroup: AccountGroup | null = <AccountGroup>{};
  allGroups: AccountGroup[] = [];
  advertisers: Account[] = [];
  loadedGroup = '';
  selectedAdvertiser: Account | undefined;
  showInactive = false;
  cleanup?: {
    run: () => void;
  };
  inactiveShown = false;
  breadcrumbs: string[] = [];
  advertiserIsSelected = false;
  moduleSubscription: ISubscription;
  initiateRouteChange = false;
  activeSelectionType: ActiveSelectionTypes | undefined;
  selectedId = '';
  permissions: ModuleConfig[] = [];

  constructor(
    private advertiserService: AdvertiserService,
    private accountFacade: AccountFacade,
    private globalFilterFacade: GlobalFilterFacade,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private routeStateService: RouteStateService,
    private router: Router,
    private store: Store<AppState>
  ) {
    apiClient.errorHandler = this.errorHandler;
    this.advertiserService.apiClient = this.apiClient;
    this.moduleSubscription = this.store
      .select(selectModuleConfigAndStatus)
      .subscribe(
        ({
          hasLoaded,
          isLoading,
          activeSelectionType,
          selectedId,
          permissions,
        }) => {
          if (hasLoaded && !isLoading) {
            (this.activeSelectionType = activeSelectionType),
              (this.selectedId = selectedId),
              (this.permissions = permissions);
            if (this.initiateRouteChange) {
              this.initiateRouteChange = false;
              this.updateRoute();
            }
          }
        }
      );
  }

  ngOnInit() {
    this.accountSubscription = this.accountFacade.account$.subscribe(
      (state) => {
        this.state = state;
        if (state && state.showInactive !== undefined) {
          if (
            (this.showInactive && !state.showInactive) ||
            (!this.showInactive && state.showInactive)
          ) {
            this.showInactive = state.showInactive;
          }
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
  }

  close() {
    if (this.cleanup) {
      this.cleanup.run();
    }
  }

  onSelect(item: AccountGroup | Account) {
    if ((item as AccountGroup).GroupId) {
      this.setActiveAdvertiserGroup(item as AccountGroup);
    } else if ((item as Account).AccountId) {
      this.setActiveAdvertiser(item as Account);
    }
    this.saveToState();
    this.close();
  }

  setBreadcrumbs(breadcrumbs: BreadcrumbDefinition) {
    this.breadcrumbs = breadcrumbs.data;
    this.advertiserIsSelected = breadcrumbs.advertiser;
  }

  getGroupDetails(id: string, index: number) {
    if (index < this.breadcrumbs.length - 1 || !this.advertiserIsSelected) {
      if (this.state && this.state.advertiserGroups) {
        const grp = find(this.state.advertiserGroups, { GroupId: id });
        return grp ? grp.Name : '';
      }
    } else if (this.state && this.state.activeAdvertiser) {
      return this.state.activeAdvertiser.Name;
    }

    return '';
  }

  inactiveEnabled(inactiveShown: boolean) {
    this.showInactive = inactiveShown;
  }

  setActiveAdvertiser(item: Account) {
    this.selectedAdvertiser = item;
  }

  setActiveAdvertiserGroup(item: AccountGroup) {
    this.setGroupAsActive(item);
  }

  setActiveAdvertiserGroupFromId(id: string) {
    if (this.state && this.state.advertiserGroups) {
      const grp = find(this.state.advertiserGroups, { GroupId: id });
      if (grp) {
        this.setGroupAsActive(grp);
        this.saveToState();
        this.updateRoute();
        this.close();
      }
    }
  }

  setGroupAsActive(group: AccountGroup) {
    this.selectedGroup = group;
    this.selectedAdvertiser = <Account>{};
  }

  saveToState() {
    if (this.selectedAdvertiser && this.selectedAdvertiser.AccountId) {
      this.selectedGroup = find(this.state.advertiserGroups, {
        GroupId: this.selectedAdvertiser.AccountGroupId,
      }) as AccountGroup;
      this.accountFacade.setActiveAdvertiser(this.selectedAdvertiser);
      this.globalFilterFacade.resetGlobalFilterState();
      this.initiateRouteChange = true;
    } else if (this.selectedGroup && this.selectedGroup.GroupId) {
      this.accountFacade.setActiveGroup(this.selectedGroup);
      this.globalFilterFacade.resetGlobalFilterState();
      this.initiateRouteChange = true;
    }
  }

  updateRoute() {
    const routeInfo = this.routeStateService.generateUrl();
    if (routeInfo?.root) {
      if (routeInfo?.root === 'dashboards') {
        return this.routeToDashboards(routeInfo);
      }
      let url = `/${routeInfo.root}/`;
      if (this.selectedAdvertiser && this.selectedAdvertiser.AccountId) {
        this.router.navigate([url, this.selectedAdvertiser.AccountId]);
      } else if (this.selectedGroup && this.selectedGroup.GroupId) {
        url += '/group';
        this.router.navigate([url, this.selectedGroup.GroupId]);
      }
    } else if (routeInfo?.redirectToDashboard) {
      this.determineRedirect();
    }
  }

  routeToDashboards(routeInfo: RouteUrlDetails) {
    const { root, paramMap } = routeInfo;
    const { params } = paramMap as any;
    const { pageId } = params;
    if (this.selectedAdvertiser && this.selectedAdvertiser.AccountId) {
      this.router.navigate([
        'dashboards',
        pageId || 0,
        this.selectedAdvertiser.AccountId,
      ]);
    } else if (this.selectedGroup && this.selectedGroup.GroupId) {
      this.router.navigate(['dashboards/group', this.selectedGroup.GroupId]);
    }
  }

  determineRedirect() {
    let foundDashboardPermission = dashboardPermissionHierarchy.find(
      (dashboardPermission) => {
        return this.permissions.find(
          (permission) =>
            permission.ModuleNamespace === dashboardPermission.namespace
        );
      }
    );
    if (this.activeSelectionType) {
      if (foundDashboardPermission) {
        let redirectRoute = foundDashboardPermission.route;
        if (this.activeSelectionType === ActiveSelectionTypes.AccountGroup) {
          redirectRoute += '/group/' + this.selectedId;
        } else {
          redirectRoute += '/' + this.selectedId;
        }
        this.router.navigate([redirectRoute]);
      }
    }
  }
}
