import { MachineConfig } from 'xstate';
import { CampaignSummarySchema, CampaignSummaryContext } from './campaign-summary-machine.schema';
import { CampaignSummaryEvent } from './campaign-summary-machine.events';


export const campaignSummaryMachineConfig: MachineConfig<
CampaignSummaryContext,
CampaignSummarySchema,
CampaignSummaryEvent
> = {
  id: 'campaignSummary',
  initial: 'start',
  states: {
    start: {
      id: 'begin',
      on: {
        SELECTITEMS: [
          { target: 'bulkMenuEnabled', cond: 'rowsSelected' },
        ]
      }
    },
    dirty: {
      id: 'dirty',
      on: {
        SELECTITEMS: { target: 'bulkMenuEnabled', cond: 'rowsSelected' },
      }
    },
    bulkMenuEnabled: {
      id: 'bulkEdit',
      on: {
        BULKEDIT: { target: 'bulkEditMode', cond: 'openPanel' },
      }
    },
    bulkEditMode: {
      initial: 'openEditAll',
      states: {
        openEditAll: {
          on: {
            CLOSE: [
              { target: '#bulkEdit' },
            ],
          }
        }
      }
    }
  }
};
