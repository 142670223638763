import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { LiveChatService } from './services/live-chat/live-chat.service';

@Component({
  templateUrl: './app-auth.component.html',
  styleUrls: ['./app-auth.component.scss']
})
export class AppAuthComponent implements AfterViewInit {
  constructor(public renderer: Renderer2) {
  }

  ngAfterViewInit() {
    LiveChatService.anonymous();
  }

}
