import { createReducer, on, Action } from '@ngrx/store';
import * as locationsAction from './locations.actions';
import { LocationssState } from './locations.model';

export const initialState: LocationssState = {
  locations: [],
  accounts: [],
};

const reducer = createReducer(
  initialState,

  on(locationsAction.actionLocationsGetAll, (state, locations) => ({
    ...state,
    loading: true,
    locations: [],
    error: undefined
  })),
  on(locationsAction.actionLocationsGetSuccess, (state, locations) => ({
    ...state,
    locations: locations.locations,
    loading: false
  })),
  on(locationsAction.actionLocationsGetFail, (state, locations) => ({
    ...state,
    error: locations.error,
    locations: [],
    loading: false
  })),
  on(locationsAction.actionLocationsAdd, (state, locations) => ({
    ...state,
    addLoading: true,
    error: undefined
  })),
  on(locationsAction.actionLocationsAddSuccess, (state, locations) => ({
    ...state,
    addLoading: false
  })),
  on(locationsAction.actionLocationsAddFail, (state, locations) => ({
    ...state,
    error: locations.error,
    addLoading: false
  })),
  on(locationsAction.actionLocationsDelete, (state, locations) => ({
    ...state,
    loading: true,
    error: undefined
  })),
  on(locationsAction.actionLocationsDeleteSuccess, (state, locations) => ({
    ...state,
    loading: false,
    errorDetails: locations.locations
  })),
  on(locationsAction.actionLocationsDeleteFail, (state, locations) => ({
    ...state,
    error: locations.error,
    loading: false
  })),
  on(locationsAction.actionLocationsDelete, (state, locations) => ({
    ...state,
    loading: true,
    error: undefined
  })),
  on(locationsAction.actionLocationsDeleteFail, (state, locations) => ({
    ...state,
    error: locations.error,
    loading: false
  })),

  on(locationsAction.actionLocationsAccountEdit, (state, locations) => ({
    ...state,
    editLoading: true,
    error: undefined
  })),
  on(locationsAction.actionLocationsAccountEditSuccess, (state, locations) => ({
    ...state,
    editLoading: false
  })),
  on(locationsAction.actionLocationsAccountEditFail, (state, locations) => ({
    ...state,
    error: locations.error,
    editLoading: false
  })),

  on(locationsAction.actionLocationsEdit, (state, locations) => ({
    ...state,
    editLoading: true,
    error: undefined
  })),
  on(locationsAction.actionLocationsEditSuccess, (state, locations) => ({
    ...state,
    editLoading: false
  })),
  on(locationsAction.actionLocationsEditFail, (state, locations) => ({
    ...state,
    error: locations.error,
    editLoading: false
  })),
  on(locationsAction.actionLocationsCreateAccountAndEdit, (state, locations) => ({
    ...state,
    editLoading: true,
    error: undefined
  })),
  on(locationsAction.actionLocationsCreateAccountAndEditSuccess, (state, locations) => ({
    ...state,
    editLoading: false
  })),
  on(locationsAction.actionLocationsCreateAccountAndEditFail, (state, locations) => ({
    ...state,
    error: locations.error,
    editLoading: false
  })),

  on(locationsAction.actionLocationsInvite, (state, locations) => ({
    ...state,
    loading: true,
    error: undefined
  })),
  on(locationsAction.actionLocationsInviteSuccess, (state, locations) => ({
    ...state,
    loading: false
  })),
  on(locationsAction.actionLocationsInviteFail, (state, locations) => ({
    ...state,
    error: locations.error,
    loading: false
  })),
  on(locationsAction.actionLocationsResetAccounts, (state, accounts) => ({
    ...state,
    accounts: [],
  })),
  on(locationsAction.actionLocationsGetAccounts, (state, accounts) => ({
    ...state,
    accountLoading: true,
    error: undefined
  })),
  on(locationsAction.actionLocationsGetAccountsSuccess, (state, accounts) => ({
    ...state,
    accounts: [...state.accounts, ...accounts.accounts],
    accountLoading: false
  })),
  on(locationsAction.actionLocationsGetAccountsFail, (state, accounts) => ({
    ...state,
    error: accounts.error,
    accountLoading: false
  })),
);

export function locationsReducer(
  state: LocationssState | undefined,
  action: Action
): LocationssState {
  return reducer(state, action);
}
