<button
  mat-icon-button
  matTooltip="Notifications"
  [matMenuTriggerFor]="notificationMenu"
  (click)="clearNotifications()"
  class="navNotificationIcon"
>
  <mat-icon
    [matBadge]="(newNotificationsCount$ | async) || null"
    matBadgeColor="warn"
    style="z-index: 1;"
    >notifications</mat-icon
  >
</button>
<mat-menu
  #notificationMenu="matMenu"
  [overlapTrigger]="false"
  style="z-index: inherit 1; max-height: 100vh;"
  direction="down"
  yPosition="below"
>
  <div style="max-height: 80vh; overflow: auto;">
    <ng-container *ngIf="notifications.length <= 4">
      <button
        class="mat-menu-item"
        mat-menu-item
        (click)="$event.stopPropagation()"
        *ngFor="let link of notifications"
      >
        <mat-card>
          <div class="col tbldiv" style="margin-right: 0px; border-radius: 2px;">
            <div class="viewsetup">
              <div class="clearfix" style="font-weight: bold;">
                {{ link.Message }}
              </div>
              <div class="clearfix">
                Created on: {{ formatDate(link.Timestamp) }}
              </div>
              <div class="clearfix">Created by: {{ eventCreator(link) }}</div>
            </div>
            <span class="pull-left" *ngIf="isComplete(link)">
              <app-form-button
                cssClass="btn btn-default cancelbtn"
                type="button"
                (click)="navigate(link)"
              >
                View
              </app-form-button>
            </span>
            <span class="pull-right">
              <app-form-button
                cssClass="btn btn-default cancelbtn"
                type="button"
                (click)="$event.stopPropagation()"
                (click)="dismiss(link)"
              >
                Dismiss
              </app-form-button>
            </span>
          </div>
        </mat-card>
      </button>
    </ng-container>
    <ng-container *ngIf="notifications.length > 4">
      <cdk-virtual-scroll-viewport
        itemSize="167"
        autosize
        minBufferPx="835" maxBufferPx="1670"
        style="height: 80vh; width:425px;"
      >
        <button
          class="mat-menu-item"
          mat-menu-item
          (click)="$event.stopPropagation()"
          *cdkVirtualFor="let link of notifications"
        >
          <mat-card>
            <div class="col tbldiv" style="margin-right: 0px; border-radius: 2px;">
              <div class="viewsetup">
                <div class="clearfix" style="font-weight: bold;">
                  {{ link.Message }}
                </div>
                <div class="clearfix">
                  Created on: {{ formatDate(link.Timestamp) }}
                </div>
                <div class="clearfix">Created by: {{ eventCreator(link) }}</div>
              </div>
              <span class="pull-left" *ngIf="isComplete(link)">
                <app-form-button
                  cssClass="btn btn-default cancelbtn"
                  type="button"
                  (click)="navigate(link)"
                >
                  View
                </app-form-button>
              </span>
              <span class="pull-right">
                <app-form-button
                  cssClass="btn btn-default cancelbtn"
                  type="button"
                  (click)="$event.stopPropagation()"
                  (click)="dismiss(link)"
                >
                  Dismiss
                </app-form-button>
              </span>
            </div>
          </mat-card>
        </button>
      </cdk-virtual-scroll-viewport>
    </ng-container>

  </div>
  <div
    class="flex-center"
    *ngIf="notifications && notifications.length > 1"
    (click)="$event.stopPropagation()"
  >
    <span>
      <app-form-button
        cssClass="btn btn-default cancelbtn"
        type="button"
        (click)="dismissAll()"
      >
        Dismiss All
      </app-form-button>
    </span>
  </div>
  <button
    mat-menu-item
    *ngIf="!notifications || notifications.length === 0"
    style="text-align: center;"
  >
    No notifications
  </button>
</mat-menu>
