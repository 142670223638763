import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { Subject } from 'rxjs-compat/Subject';

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  private clientId: string = environment.launchDarklyClientSideId;
  client: LDClient | undefined;
  flags: LDFlagSet | undefined;
  flagChanges: Subject<LDFlagSet> = new Subject<LDFlagSet>();
  launchDarklyService = '';
  localUserId = '';

  constructor() {}

  initializeClient(userId: string) {
    this.localUserId = userId;
    this.client = initialize(this.clientId, { key: userId });
    this.client.on('change', (flags: LDFlagSet) => {
      if (this.flags) {
        this.flagChanges.next(this.flags);
      }
    });

    this.client.on('ready', () => {
      this.setFlags();
    });
  }

  updateUserclient(accountId: string) {
    if (this.client) {
      this.localUserId = accountId;
      this.client.identify({ key: accountId }, undefined, (error, flags) => {
        if (flags) this.flags = flags;
      });
    }
  }

  setFlags() {
    if (this.client) {
      this.flags = this.client.allFlags();
    }
  }

  disconnect() {
    if (this.client) {
      this.client.close();
    }
  }
}
