import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '@services/loader/loader.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
})
export class LoadingBarComponent implements OnInit, OnDestroy {
  showProgress = false;
  subscription: Subscription|undefined;
  timeout: NodeJS.Timer|undefined;

  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
    this.subscribeToNotifications();
  }

  subscribeToNotifications() {
    this.subscription = this.loaderService.itemsHandler.subscribe(response => {
      if (response) {
        this.resetLoader();
      } else {
        this.showProgress = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private resetLoader(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.showProgress = true;
    this.timeout = setTimeout(() => {
      this.showProgress = false;
      this.loaderService.clearStatus();
    }, (environment.loaderTimeout * 1000));
  }
}
