import {catchError, switchMap, map, mergeMap, first} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable ,  of, Subscription } from 'rxjs';
import { filter, find, uniqBy } from 'lodash-es';
import {AppState} from '../../state';
import {AccountState} from './account.model';
import {AccountQuery} from './account.reducer';
import * as AccountActions from './account.actions';
import { AdvertiserService } from '@services/advertiser/advertiser.service';
import { AccountGroup, AccountPayload, Account } from '../../../interfaces';
import { ClaimsService } from '@services/claims/claims.service';
import { AuthService } from '@services/auth/auth.service';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { EntityMap, USER_COMPLETE_ACTION } from '../../entity/entity-metadata';
import { EntityCollectionService, EntityCollectionServiceFactory } from '@ngrx/data';
import { ResourceClaim, UserWhoIsInfo } from '../../../interfaces/claim.interface';
import { UserInfo } from '../../../interfaces/common.interface';
import { selectAccountGroupsAndClaims } from './account.selectors';
import { WhoisDataService } from '../../user/whois.data.service';
import { selectUser } from '../../user/user.selectors';
type Action = AccountActions.All;

@Injectable()
export class AccountFacade {
  loadAdWithVariations = false;
  advertiserId = '';
  adCache = this.advertiserService.cache;
  id = '';
  gid = '';
  account$ = this.store.select(AccountQuery.account);
  accountService: EntityCollectionService<Account>;
  accountGroupService: EntityCollectionService<AccountGroup>;

  search$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AccountActions.SEARCH_MANAGEMENT_DATA),
    map((action: AccountActions.SearchAccountData) => action.payload),
    switchMap(payload => this.advertiserService.search(payload).pipe(
    map((result) => {
      if (this.claims) {
        const grp = find(this.claimsApiService.roles, { Name: 'Group Admin' });
        const grpRole = filter(this.claims, { Roles: grp?.Value });
        const uniqClaims = uniqBy(grpRole, 'EntityKey');
        let allowedGroups: AccountGroup[] = [];
        let allowedAdv: Account[] = [];
        const fetched: string[] = [];
        uniqClaims.forEach(claim => {
          if (fetched.indexOf(claim.EntityKey) === -1) {
            const group = find(result.AdvertiserGroups, {GroupId: claim.EntityKey});
            if (group) {
              allowedGroups.push(group);
            }
            const subGroup = filter(result.AdvertiserGroups, (g) => {
              return g.ParentHierarchyPath.indexOf(claim.EntityKey) > -1;
            });
            if (subGroup) {
              allowedGroups = [...allowedGroups, ...subGroup];
            }
            const grpAdvertiser = filter(result.Advertisers, {AccountGroupId: claim.EntityKey});
            if (grpAdvertiser) {
              allowedAdv = [...allowedAdv, ...grpAdvertiser];
            }
            const subGroups = filter(this.accountGroups, (g) => {
              return g.ParentHierarchyPath.indexOf(claim.EntityKey) > -1;
            });
            const subgrpAdvertiser = filter(result.Advertisers, (g) => {
              return find(subGroups, {GroupId: g.AccountGroupId}) !== undefined;
            });
            if (subgrpAdvertiser) {
              allowedAdv = [...allowedAdv, ...subgrpAdvertiser];
            }
          }
          fetched.push(claim.EntityKey);
        });
        result.AdvertiserGroups = allowedGroups;
        const adv = find(this.claimsApiService.roles, { Name: 'Account Admin' });
        const advRole = filter(this.claims, { Roles: adv?.Value });
        const uniqAdvClaims = uniqBy(advRole, 'EntityKey');
        uniqAdvClaims.forEach(claim => {
          if (fetched.indexOf(claim.EntityKey) === -1) {
            const advertiser = find(result.Advertisers, {AccountId: claim.EntityKey});
            if (advertiser) {
              allowedAdv.push(advertiser);
            }
          }
          fetched.push(claim.EntityKey);
        });
        result.Advertisers = allowedAdv;
      }
     return new AccountActions.SearchAccountDataSuccess(result);
    }),
    catchError(err => of (new AccountActions.SearchAccountDataFail(err)) ), )), ));

  getAllowedAdvertiser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AccountActions.GET_MANAGEMENT_ALLOWED_ADVERTISER),
    map((action: AccountActions.GetAccountAllowedAdvertiser) => action.id),
    mergeMap(payload => this.advertiserService.getAdvertiser(payload).pipe(
    map((result) => {
     return new AccountActions.GetAccountAllowedAdvertiserSuccess(result.data);
    }),
    catchError(err => of (new AccountActions.GetAccountAllowedAdvertiserFail(err)) ), )), ));

  claimsService: EntityCollectionService<ResourceClaim>;
  userService: EntityCollectionService<UserInfo>;
  subscriptions: Subscription[] = [];
  userWhoisService: EntityCollectionService<UserWhoIsInfo>;
  claims: ResourceClaim[] = [];
  accountGroups: AccountGroup[] = [];

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private claimsApiService: ClaimsService,
    private authService: AuthService,
    private advertiserService: AdvertiserService,
    private whoisDataService: WhoisDataService,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.authService.apiClient = this.apiClient;
    this.advertiserService.apiClient = this.apiClient;
    this.claimsApiService.apiClient = this.apiClient;
    this.accountService = this.entityCollectionServiceFactory.create<Account>(EntityMap.Account);
    this.accountGroupService = this.entityCollectionServiceFactory.create<AccountGroup>(EntityMap.AccountGroup);
    this.claimsService = this.entityCollectionServiceFactory.create<ResourceClaim>(EntityMap.UserClaim);
    this.userService = this.entityCollectionServiceFactory.create<UserInfo>(EntityMap.User);
    this.userWhoisService = this.entityCollectionServiceFactory.create<UserWhoIsInfo>(EntityMap.UserWhois);

    this.subscriptions.push(this.store.select(selectAccountGroupsAndClaims).subscribe(groupsAndClaims => {
      this.claims = groupsAndClaims.claims;
      this.accountGroups = groupsAndClaims.accountGroups
      this.prepareGlobalState(groupsAndClaims.claims, groupsAndClaims.accountGroups);
    }));
    this.userService.entityActions$.subscribe(action => {
      if (action.type === USER_COMPLETE_ACTION) {
        this.store.pipe(select(selectUser)).pipe(first())
        .subscribe((user) => {
          if (user.length > 0) {
            this.whoisDataService.email = user[0].Email;
            this.userWhoisService.load();
          }
        });
      }
    });
  }

  private prepareGlobalState(claims: ResourceClaim[], accountGroups: AccountGroup[]) {
    if (accountGroups.length === 0) {
      return;
    }
    const payload: AccountState = {
      ...this.adCache,
      advertiserId: this.id, groupId: this.gid
    };
    payload.advertiserGroups = accountGroups;
    if (this.noGroupIsLoaded(payload)) {
      payload.activeSelection = {Name: payload.advertiserGroups[0].Name, Type: 'Advertiser Group'};
      payload.activeAdvertiserGroup = payload.advertiserGroups[0];
    }
    if (payload.activeSelection && payload.activeSelection.Type === 'Advertiser') {
      if (!payload.activeAdvertiser || !payload.activeAdvertiser.AccountId) {
        payload.activeSelection = <AccountState['activeSelection']>{};
      }
    }
    if (payload.activeSelection && payload.activeSelection.Type === 'Advertiser Group') {
      if (!payload.activeAdvertiserGroup || !payload.activeAdvertiserGroup.GroupId) {
        payload.activeSelection = <AccountState['activeSelection']>{};
      } else {
        const grp = find(payload.advertiserGroups, { GroupId: payload.activeAdvertiserGroup.GroupId });
        if (grp) {
          payload.activeAdvertiserGroup = grp;
          payload.activeSelection = {Name: grp.Name, Type: 'Advertiser Group'};
        }
      }
    }
    if (claims) {
      const grp = find(this.claimsApiService.roles, { Name: 'Group Admin' });
      const grpRole = filter(claims, { Roles: grp?.Value });
      const uniqClaims = uniqBy(grpRole, 'EntityKey');
      let allowedGroups: AccountGroup[] = [];
      uniqClaims.forEach(claim => {
        const group = find(payload.advertiserGroups, { GroupId: claim.EntityKey });
        if (group) {
          allowedGroups.push(group);
        }
        const subGroup = filter(payload.advertiserGroups, (g) => {
          return g.ParentHierarchyPath.indexOf(claim.EntityKey) > -1;
        });
        if (subGroup) {
          allowedGroups = [...allowedGroups, ...subGroup];
        }
      });
      payload.advertiserGroups = allowedGroups;
      if (payload.advertiserGroups.length === 0) {
        const adv = find(this.claimsApiService.roles, { Name: 'Account Admin' });
        const advRole = filter(claims, { Roles: adv?.Value });
        const uniqAdvClaims = uniqBy(advRole, 'EntityKey');
        const fetched: string[] = [];
        uniqAdvClaims.forEach(ad => {
          if (fetched.indexOf(ad.EntityKey) === -1) {
            this.getAllowedAdvertiser(ad.EntityKey);
          }
          fetched.push(ad.EntityKey);
        });
      }
    }

    this.store.dispatch(new AccountActions.LoadAccountState(payload));
  }

  noGroupIsLoaded(state: AccountState) {
    return state && this.isEmptyObject(state.activeAdvertiser)
    && this.isEmptyObject(state.activeAdvertiserGroup) && state.advertiserGroups;
  }

  isEmptyObject(obj: Object) {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  setActiveGroup(group: AccountGroup): void {
    this.advertiserService.setActiveAdvertiserGroup(group);
    this.store.dispatch(new AccountActions.SetAccountSelectedGroup(group));
  }

  setActiveAdvertiser(advertiser: Account): void {
    this.advertiserService.setActiveAdvertiser(advertiser);
    this.store.dispatch(new AccountActions.SetAccountSelectedAdvertiser(advertiser));
  }

  setDeletedAdvertiser(advertiser: string): void {
    this.store.dispatch(new AccountActions.SetAccountDeletedAdvertiser(advertiser));
  }

  initState(payload: AccountPayload): void {
    this.prepareAccountPayloadData(payload);
    this.getClaims();
    this.getUser();
    this.getAdvertiserGroups();
  }

  initializeStateData() {
    this.store.dispatch(new AccountActions.LoadAccountState({...this.adCache,
      advertiserId: this.id, groupId: this.gid}));
  }

  resetState() {
    this.advertiserService.wipeActiveAdvertisers();
    this.store.dispatch(new AccountActions.ResetAccountState());
  }

  getAdvertiserGroups(): void {
    this.accountGroupService.load();
  }

  getAllowedAdvertiser(id: string): void {
    this.store.dispatch(new AccountActions.GetAccountAllowedAdvertiser(id));
  }

  getClaims(): void {
    this.claimsService.load();
  }

  setAdvertisers(advertisers: Account[]): void {
    this.store.dispatch(new AccountActions.SetAccountGroupAdvertisers(advertisers));
  }

  getUser(): void {
    this.userService.load();
  }

  search(query: string) {
    this.store.dispatch(new AccountActions.SearchAccountData(query));
  }

  setShowInactive(query: boolean) {
    this.store.dispatch(new AccountActions.SetAccountShowInactive(query));
  }

  prepareAccountPayloadData(payload: AccountPayload): AccountPayload {
    if (!payload.advertiserId || payload.advertiserId === '') {
      payload.advertiserId = this.adCache.activeAdvertiser['AccountId'];
    }

    if (!payload.groupId || payload.groupId === '') {
      payload.groupId = this.adCache.activeAdvertiserGroup['GroupId'];
    }

    this.id = payload.advertiserId;
    this.gid = payload.groupId;
    return payload;
  }
}
