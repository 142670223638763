import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import {
  HighchartPoint,
  HighchartsAxiaLabel,
  HighchartAxisStyle,
  HighchartsAxisStackLabel,
  XAxis,
  YAxis,
  ChartConfig,
  HighchartsXAxisConfig,
} from '../../shared/interfaces/highcharts.interface';

@Injectable()
export class ChartConfigService {
  formatChartNumber(c: HighchartPoint, prefix = '', suffix = '', decimals = 0) {
    return (
      prefix + Highcharts.numberFormat(c.value, decimals, '.', ',') + suffix
    );
  }

  generateChartConfig(config: ChartConfig) {
    const options = {
      chart: {
        type: config.type || '',
        marginTop: config.marginTop || undefined,
        marginBottom: config.marginBottom || undefined,
        plotBorderWidth: config.plotBorderWidth || undefined,
        style: {
          fontFamily: 'Roboto',
        },
      },
      title: {
        text: config.title || '',
        margin: config.titleMargin || 15,
        align: config.titleAlign || 'center',
        style: config.titleStyle || {},
      },
      xAxis: this.formatXAxis(config),
      yAxis: this.formatYAxis(config),
      legend: config.legend ? config.legend : {},
      tooltip: config.tooltip ? config.tooltip : {},
      colorAxis: config.colorAxis ? config.colorAxis : undefined,
      style: config.style ? config.style : {},
      responsive: config.responsive ? config.responsive : {},
      plotOptions:
        config.type === 'pie'
          ? config.plotOptions
          : config.plotOptions
          ? {
              column: {
                stacking: config.plotOptions.stacking
                  ? config.plotOptions.stacking
                  : config.plotOptions.column?.stacking
                  ? config.plotOptions.column.stacking
                  : null,
                pointPadding: config.plotOptions.pointPadding
                  ? config.plotOptions.pointPadding
                  : null,
                borderWidth: config.plotOptions.borderWidth
                  ? config.plotOptions.borderWidth
                  : null,
                borderRadius: config.plotOptions.borderRadius
                  ? config.plotOptions.borderRadius
                  : null,
                dataLabels: {
                  enabled: config.plotOptions.dataLabels
                    ? config.plotOptions.dataLabels.enabled
                    : false,
                  color: config.plotOptions.dataLabels
                    ? config.plotOptions.dataLabels.color
                    : null,
                },
              },
              series: config.plotOptions.series
                ? config.plotOptions.series
                : {},
            }
          : {},
      series: config.data,
    };
    if (
      config.plotOptions &&
      config.plotOptions.events &&
      options.plotOptions
    ) {
      (options.plotOptions.column as any) = {
        ...options.plotOptions.column,
        events: config.plotOptions.events,
      };
    }
    return options;
  }

  private formatXAxis(config: ChartConfig) {
    if (config.xAxis && config.xAxis instanceof Array) {
      const xAxis: HighchartsXAxisConfig[] = [];
      config.xAxis.forEach((x) => {
        xAxis.push({
          title: {
            text: x && x.text ? x.text : '',
          },
          categories: (x && x.data ? x.data : undefined) as string[],
          min: x && x.min ? x.min : undefined,
          max: x && x.max ? x.max : undefined,
          type: x && x.type ? x.type : '',
          tickInterval: x && x.tickInterval ? x.tickInterval : undefined,
          labels: x && x.labels ? x.labels : <HighchartsAxiaLabel>{},
          crosshair: (x && x.crosshair ? x.crosshair : false) as boolean,
          opposite: x && x.opposite ? x.opposite : false,
          dateTimeLabelFormats:
            x && x.dateTimeLabelFormats ? x.dateTimeLabelFormats : undefined,
          offset: x.offset ? x.offset : undefined,
        });
      });
      return xAxis;
    } else {
      return {
        title: {
          text: config.xAxis && config.xAxis.text ? config.xAxis.text : '',
        },
        categories:
          config.xAxis && config.xAxis.data ? config.xAxis.data : null,
        min: config.xAxis && config.xAxis.min ? config.xAxis.min : undefined,
        max: config.xAxis && config.xAxis.max ? config.xAxis.max : undefined,
        type: config.xAxis && config.xAxis.type ? config.xAxis.type : '',
        tickInterval:
          config.xAxis && config.xAxis.tickInterval
            ? config.xAxis.tickInterval
            : undefined,
        labels: config.xAxis && config.xAxis.labels ? config.xAxis.labels : {},
        crosshair:
          config.xAxis && config.xAxis.crosshair
            ? config.xAxis.crosshair
            : false,
        opposite:
          config.xAxis && config.xAxis.opposite ? config.xAxis.opposite : false,
        dateTimeLabelFormats:
          config.xAxis && config.xAxis.dateTimeLabelFormats
            ? config.xAxis.dateTimeLabelFormats
            : undefined,
        offset:
          config.xAxis && config.xAxis.offset ? config.xAxis.offset : undefined,
      };
    }
  }

  private formatYAxis(config: ChartConfig) {
    if (config.yAxis && config.yAxis instanceof Array) {
      const yAxis: {
        type?: string;
        min?: number;
        reversed?: boolean;
        categories?: string[];
        labels: HighchartsAxiaLabel;
        lineWidth?: number;
        opposite: boolean;
        title: {
          text: string;
          style: HighchartAxisStyle;
          margin?: number;
        };
        stackLabels: HighchartsAxisStackLabel;
      }[] = [];
      config.yAxis.forEach((y) => {
        yAxis.push({
          type: y && y.type ? y.type : undefined,
          min: y && y.min ? y.min : undefined,
          reversed: y.reversed,
          categories: y && y.data ? y.data : undefined,
          labels: (y && y.labels
            ? y.labels
            : <HighchartsAxiaLabel>{}) as HighchartsAxiaLabel,
          lineWidth: y && y.lineWidth ? y.lineWidth : undefined,
          opposite: y && y.opposite ? y.opposite : false,
          title: {
            text: y && y.text ? y.text : '',
            style: y && y.style ? y.style : <HighchartAxisStyle>{},
            margin: y && y.titleMargin ? y.titleMargin : undefined,
          },
          stackLabels:
            y && y.stackLabels ? y.stackLabels : <HighchartsAxisStackLabel>{},
        });
      });
      return yAxis;
    } else {
      return {
        type: config.yAxis && config.yAxis.type ? config.yAxis.type : undefined,
        min: config.yAxis && config.yAxis.min ? config.yAxis.min : undefined,
        reversed: config.yAxis?.reversed,
        categories:
          config.yAxis && config.yAxis.data ? config.yAxis.data : null,
        labels: config.yAxis && config.yAxis.labels ? config.yAxis.labels : {},
        lineWidth:
          config.yAxis && config.yAxis.lineWidth
            ? config.yAxis.lineWidth
            : undefined,
        opposite:
          config.yAxis && config.yAxis.opposite ? config.yAxis.opposite : false,
        title: {
          text: config.yAxis && config.yAxis.text ? config.yAxis.text : '',
          style: config.yAxis && config.yAxis.style ? config.yAxis.style : {},
          margin:
            config.yAxis && config.yAxis.titleMargin
              ? config.yAxis.titleMargin
              : undefined,
        },
        stackLabels:
          config.yAxis && config.yAxis.stackLabels
            ? config.yAxis.stackLabels
            : {},
      };
    }
  }
}
