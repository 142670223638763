import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, SubscriptionLike as ISubscription } from 'rxjs';
import { filter, find } from 'lodash-es';
import { select, Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import {
  TaskState,
  TaskFacade,
  SignalRNotification,
} from '@shared/state/tasks';
import { AccountState } from '@shared/state/global';
import { NotificationsService } from '@services/notification/notification.service';
import { SignarlRService } from '@services/signal-r/signarl-r.service';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import {
  selectFailedTasks,
  selectNewTaskNotificationsCount,
  selectTaskNotifications,
  selectTaskUnnotifiedItems } from '@shared/state/tasks/tasks.selectors';
import { AppState } from '@shared/state/state';
import { selectCurrentActiveAdvertiser, selectCurrentActiveGroup } from '@shared/state/global/account/account.selectors';
import { Account, AccountGroup } from '@shared/interfaces';

@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
})
export class NotificationPanelComponent implements OnInit, OnDestroy {
  private _notifications: SignalRNotification[] = [];
  taskSubscription: ISubscription|undefined;
  taskState = <TaskState>{};
  showNotification = false;
  accountSubscription: ISubscription|undefined;
  state = <AccountState>{};
  id = '';
  notificationCount = 0;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger|undefined;
  lastFailNotofocation: string | number | undefined;
  unnotified$: Observable<string[]>;
  unnotifiedSub: ISubscription|undefined;
  notificationsSub: ISubscription|undefined;
  notifications$: Observable<SignalRNotification[]>;
  newNotificationsCount$: Observable<number>;
  failedTasks$: Observable<SignalRNotification[]>;
  activeCurrentAccount$: Observable<Account>;
  activeCurrentGroup$: Observable<AccountGroup>;
  gid = '';
  groupSubscription: ISubscription|undefined;

  constructor(
    private taskFacade: TaskFacade,
    private notificationsService: NotificationsService,
    private router: Router,
    private apiClient: ApiService,
    private signalRService: SignarlRService,
    private errorHandler: ErrorHandlerService,
    private store: Store<AppState>
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.signalRService.apiClient = this.apiClient;
    this.unnotified$ = this.store.pipe(select(selectTaskUnnotifiedItems));
    this.notifications$ = this.store.pipe(select(selectTaskNotifications));
    this.newNotificationsCount$ = this.store.pipe(select(selectNewTaskNotificationsCount));
    this.failedTasks$ = this.store.pipe(select(selectFailedTasks));
    this.activeCurrentAccount$ = this.store.pipe(select(selectCurrentActiveAdvertiser));
    this.activeCurrentGroup$ = this.store.pipe(select(selectCurrentActiveGroup));
    this.unnotifiedSub = this.unnotified$.subscribe(unnotified => {
      if (unnotified && unnotified.length > 0) {
        this.store.pipe(select(selectTaskNotifications)).pipe(first()).subscribe(notifications => {
          this.parseUnnotifiedTasks(unnotified, notifications);
        });
      }
    });
    this.notificationsSub = this.notifications$.subscribe(notifications => {
      this.notifications = [...notifications || []];
    });

    this.taskSubscription = this.failedTasks$.subscribe((failedTasks) => {
      if (failedTasks && failedTasks.length > 0) {
        failedTasks.forEach((item) => {
          this.displayFailedNotification(item);
        });
        taskFacade.signalRSetFailNotified(failedTasks);
      }
    });
    this.accountSubscription = this.activeCurrentAccount$.subscribe((state) => {
      this.id = state.AccountId;
    });
    this.groupSubscription = this.activeCurrentGroup$.subscribe((state) => {
      this.gid = state.GroupId;
    });
  }

  private parseUnnotifiedTasks(unnotified: string[], notifications: SignalRNotification[]) {
    unnotified.forEach((item) => {
      const task = find(notifications, (t) => {
        return t.Data.TaskId === item && t.Data.JobCompletionTime !== null && t.Data.JobSuccessful !== 'False';
      });
      const failed = filter(notifications, (t) => {
        return t.Data.TaskId === item && t.Data.JobSuccessful === 'False';
      });
      if (task) {
        this.displayNotification(task);
      }
      if (failed.length > 0) {
        failed.forEach((n) => {
          this.displayFailedNotification(n);
        });
      }
    });
    if (unnotified?.length > 0) {
      this.taskFacade.clearNotified();
    }
  }

  private displayNotification(item: SignalRNotification) {
    this.notificationsService.notify({
      severity: 'success',
      detail: item.Message,
      summary: 'Task Completed.',
      key: 'non-sticky',
      type: 'toast'
    });
    this.acknowledgeNotification(item);
    this.taskFacade.setNotified(item.Data.TaskId || '');
  }

  private displayFailedNotification(item: SignalRNotification) {
    if (this.lastFailNotofocation !== item.AcknowledgedId) {
      this.lastFailNotofocation = item.AcknowledgedId;
      this.notificationsService.notify({
        severity: 'error',
        detail: item.Message,
        summary: 'Task Failed.',
        key: 'non-sticky',
        type: 'toast'
      });
      this.acknowledgeFailedNotification(item);
      this.taskFacade.setNotified(item.Data.TaskId || '');
    }
  }

  private acknowledgeNotification(item: SignalRNotification) {
    if (item.Data.JobType === 'Markov') {
      this.taskFacade.setAttributionComplete(item.Data.TaskId || '');
    } else if (item.Data.JobType === 'CustomerGraphQuery') {
      this.taskFacade.setAudienceComplete(item.Data.TaskId || '');
    } else if (item.Data.JobType === 'RecordLinking') {
      this.taskFacade.setScvComplete(item.Data.TaskId || '');
    } else if (item.Data.JobType === 'LTV') {
      this.taskFacade.setLtvComplete(item.Data.TaskId || '');
    } else if (item.Data.JobType === 'RFM') {
      this.taskFacade.setRfmComplete(item.Data.TaskId || '');
    } else if (item.Data.JobType === 'CustomerGraphQuery-CustomerGraphPersonaQuery') {
      this.taskFacade.setCustomerGraphPersonaQueryComplete(item.Data.ParametersKey || '');
    } else if (item.Data.JobType === 'CustomerGraphQuery-CustomerGraphAudienceQuery') {
      this.taskFacade.setCustomerGraphAudienceQueryComplete(item.Data.ParametersKey || '');
    } else if (item.Data.JobType === 'CustomerGraphQuery-CustomerQuery') {
      this.taskFacade.setCustomerQueryComplete(item.Data.ParametersKey || '');
    } else if (item.Data.JobType === 'CustomerGraphQuery-CustomerCountQuery') {
      this.taskFacade.setCustomerCountQueryComplete(item.Data.ParametersKey || '');
    }
  }

  private acknowledgeFailedNotification(item: SignalRNotification) {
    if (item.Data.JobType === 'Markov') {
      this.taskFacade.setAttributionFailed(item.Data.TaskId || '');
    } else if (item.Data.JobType === 'CustomerGraphQuery') {
      this.taskFacade.setAudienceFailed(item.Data.TaskId || '');
    } else if (item.Data.JobType === 'RecordLinking') {
      this.taskFacade.setScvFailed(item.Data.TaskId || '');
    } else if (item.Data.JobType === 'LTV') {
      this.taskFacade.setLtvFailed(item.Data.TaskId || '');
    } else if (item.Data.JobType === 'RFM') {
      this.taskFacade.setRfmFailed(item.Data.TaskId || '');
    } else if (item.Data.JobType === 'CustomerGraphQuery-CustomerGraphPersonaQuery') {
      this.taskFacade.setCustomerGraphPersonaQueryFailed(item.Data.ParametersKey || '');
    } else if (item.Data.JobType === 'CustomerGraphQuery-CustomerGraphAudienceQuery') {
      this.taskFacade.setCustomerGraphAudienceQueryFailed(item.Data.ParametersKey || '');
    } else if (item.Data.JobType === 'CustomerGraphQuery-CustomerQuery') {
      this.taskFacade.setCustomerQueryFailed(item.Data.ParametersKey || '');
    } else if (item.Data.JobType === 'CustomerGraphQuery-CustomerCountQuery') {
      this.taskFacade.setCustomerCountQueryFailed(item.Data.ParametersKey || '');
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.taskSubscription) {
      this.taskSubscription.unsubscribe();
    }
    if (this.unnotifiedSub) {
      this.unnotifiedSub.unsubscribe();
    }
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
    if (this.notificationsSub) {
      this.notificationsSub.unsubscribe();
    }
    if (this.groupSubscription) {
      this.groupSubscription.unsubscribe();
    }
  }

  eventCreator(item: SignalRNotification) {
    if (item.UserId) {
      return item.UserId;
    }
    return 'System';
  }

  isComplete(item: SignalRNotification) {
    return (
      item.Data &&
      (item.Data.JobCompletionTime || item.Data.EventCompletionTime)
    );
  }

  navigate(item: SignalRNotification) {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
    if (item.Data) {
      if (this.id) {
        this.doAccountNavigation(item);
      } else if (this.gid) {
        this.doGroupNavigation(item);
      }
    }
    return null;
  }

  private doAccountNavigation(item: SignalRNotification) {
    if (item.Data.JobType === 'Markov') {
      this.router.navigate(['/attribution', this.id]);
    }
  }

  private doGroupNavigation(item: SignalRNotification) {
    if (item.Data.JobType === 'Markov') {
      this.router.navigate(['/attribution/group', this.gid]);
    }
  }

  dismiss(item: SignalRNotification) {
    const index = this.notifications.findIndex(
      (notification) => notification.AcknowledgedId === item.AcknowledgedId
    );
    this.notifications.splice(index, 1);
    localStorage.setItem('notifications', JSON.stringify(this.notifications));
    this.signalRService.acknowledge(item.AcknowledgedId);
  }

  dismissAll() {
    const lastNotification = this.notifications.length - 1;
    this.signalRService.acknowledge(
      this.notifications[lastNotification].AcknowledgedId
    );
    this.notifications = [];
    this.taskFacade.dismissAllNotifications();
    localStorage.setItem('clearedNotifications', '[]');
    localStorage.setItem('notifications', '[]');
  }

  formatDate(date: string) {
    return moment(date).format('MM/DD/YYYY [at] hh:mm:ss a');
  }

  get notifications(): SignalRNotification[] {
    return this._notifications;
  }

  @Input() set notifications(value: SignalRNotification[]) {
    this._notifications = value;
  }

  clearNotifications() {
    this.taskFacade.clearNotifications();
  }
}
