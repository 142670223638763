import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { cloneDeep, find } from 'lodash-es';
import { DashboardDateRange } from '../../shared/interfaces/date.interface';
import { environment } from '../../../environments/environment';
interface DatePreset {
  presetLabel: string;
  range: {
    fromDate: Date;
    toDate: Date;
  };
}
@Injectable()
export class DateUtilityService {

  static getDefaultMarkovToDate() {
    return moment(new Date).subtract(1, 'd').startOf('day').utc(true).toDate();
  }

  static getDefaultMarkovFromDate() {
    return moment().subtract(91, 'd').startOf('day').utc(true).toDate();
  }

  private dates = new Subject<DashboardDateRange>();
  dateRangesHandler = this.dates.asObservable();

  setDates(dates: DashboardDateRange) {
    this.dates.next(dates);
  }

  setupPresets(labels: string[]) {
    const today = new Date();
    const yesterday = this.backDate(1);
    const minus7 = this.backDate(7);
    const minus30 = this.backDate(30);
    const minus90 = this.backDate(90);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastYearStart = new Date(today.getFullYear() - 1, 0, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
    const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31);


    const presets =  [
      {presetLabel: 'Yesterday', range: { fromDate: yesterday, toDate: today }},
      {presetLabel: 'Last 7 Days', range: { fromDate: minus7, toDate: today }},
      {presetLabel: 'Last 30 Days', range: { fromDate: minus30, toDate: today }},
      {presetLabel: 'Last 90 Days', range: { fromDate: minus90, toDate: today }},
      {presetLabel: 'This Month', range: { fromDate: currMonthStart, toDate: currMonthEnd }},
      {presetLabel: 'Last Month', range: { fromDate: lastMonthStart, toDate: lastMonthEnd }},
      {presetLabel: 'Last Year', range: { fromDate: lastYearStart, toDate: lastYearEnd }},
    ];

    const parsedPresets: DatePreset[] = [];
    if (labels) {
      labels.forEach(label => {
        const preset = find(presets, {presetLabel: label});
        if (preset) {
          parsedPresets.push(preset);
        }
      });
    }
    return parsedPresets;
  }

  setupChartPresets(labels: string[]) {
    const today = this.backDate(1);
    const yesterday = this.backDate(2);
    const minus7 = this.backDate(8);
    const minus3 = this.backDate(4);
    const minus30 = this.backDate(30);
    const minus90 = this.backDate(91);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const currentQuarterStart = moment().startOf('quarter').toDate();
    const currentYearStart = moment().startOf('year').toDate();
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastYearStart = new Date(today.getFullYear() - 1, 0, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
    const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31);

    const presets =  [
      {presetLabel: 'Yesterday', range: { fromDate: this.startOfDate(yesterday), toDate: this.endOfDate(today) }},
      {presetLabel: 'Last 3 Days', range: { fromDate: this.startOfDate(minus3), toDate: this.endOfDate(today) }},
      {presetLabel: 'Last 7 Days', range: { fromDate: this.startOfDate(minus7), toDate: this.endOfDate(today) }},
      {presetLabel: 'Last 30 Days', range: { fromDate: this.startOfDate(minus30), toDate: this.endOfDate(today) }},
      {presetLabel: 'Last 90 Days', range: { fromDate: this.startOfDate(minus90), toDate: this.endOfDate(today) }},
      {presetLabel: 'This Month', range: { fromDate: this.startOfDate(currMonthStart), toDate: this.endOfDate(currMonthEnd) }},
      {presetLabel: 'Last Month', range: { fromDate: this.startOfDate(lastMonthStart), toDate: this.endOfDate(lastMonthEnd) }},
      {presetLabel: 'Month to Date', range: { fromDate: this.startOfDate(currMonthStart), toDate: this.endOfDate(today) }},
      {presetLabel: 'Quarter to Date', range: { fromDate: this.startOfDate(currentQuarterStart), toDate: this.endOfDate(today) }},
      {presetLabel: 'Year to Date', range: { fromDate: this.startOfDate(currentYearStart), toDate: this.endOfDate(today) }},
      {presetLabel: 'Last Year', range: { fromDate: this.startOfDate(lastYearStart), toDate: this.endOfDate(lastYearEnd) }},
    ];
    const parsedPresets: DatePreset[] = [];
    if (labels) {
      labels.forEach(label => {
        const preset = find(presets, {presetLabel: label});
        if (preset) {
          parsedPresets.push(preset);
        }
      });
    }
    return parsedPresets;
  }

  startOfDate(date: Date): Date {
    return moment(date).startOf('D').toDate();
  }

  endOfDate(date: Date): Date {
    return moment(date).startOf('D').toDate();
  }

  backDate(numOfDays: number) {
    return moment().subtract(numOfDays, 'd').toDate()
  }

  forwardDate(numOfDays: number) {
    const t = new Date();
    return new Date(t.setDate(t.getDate() + numOfDays));
  }

  isPersistent(FlightStart: string|Date, FlightEnd: string|Date): boolean {
    const start = moment(FlightStart).toDate().getTime();
    const end = moment(FlightEnd).toDate().getTime();
    const Start = this.getPersistentStartDate().getTime();
    const End = this.getPersistentEndDate().getTime();
    return start === Start  && end === End;
  }

  isPersistentStart(FlightStart: string|Date): boolean {
    const start = moment(FlightStart).toDate().getTime();
    const Start = this.getPersistentStartDate().getTime();
    return start === Start;
  }

  isPersistentEnd(FlightEnd: string|Date): boolean {
    const end = moment(FlightEnd).toDate().getTime();
    const End = this.getPersistentEndDate().getTime();
    return end === End;
  }

  getPersistentStartDate() {
    return moment(environment.persistentStartDate).toDate();
  }

  getPersistentStartDateString() {
    return environment.persistentStartDate;
  }

  getPersistentEndDate() {
    return moment(environment.persistentEndDate).toDate();
  }

  getPersistentEndDateString() {
    return environment.persistentEndDate;
  }

  getPastMonthFormatted(sub = 1, format = 'MMMM YYYY') {
    return this.getPastMonth(sub).format(format);
  }

  getPastMonthStartFormatted(sub = 1, format = 'YYYY-MM-DD') {
    return this.getPastMonthStart(sub).format(format);
  }

  getPastMonthStart(sub: number) {
    return this.getPastMonth(sub).startOf('month');
  }

  getPastMonthEndFormatted(sub = 1, format = 'YYYY-MM-DD') {
    return this.getPastMonthEnd(sub).format(format);
  }

  getPastMonthEnd(sub: number) {
    return this.getPastMonth(sub).endOf('month');
  }

  getPastMonth(sub = 1) {
    return moment().subtract(sub, 'months');
  }

  createUtcDateFormat(date: moment.Moment) {
    return cloneDeep(date).utc().format();
  }
}
