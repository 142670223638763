import { StringKeyStringValue, AmbiguousData } from './common.interface';
import { CustomTableButton } from './channel-grouping.interface';
import { TemplateColumn } from '@syncfusion/ej2-angular-querybuilder';

export const schemaKeyMap = {
  EntityName: 'name',
  Columns: 'fields',
};

export const tableKeyMap = {
  Id: 'name',
  ResultSetColumns: 'fields',
};

export const columnKeyMap: StringKeyStringValue = {
  ColumnName: 'name',
  Type: 'type',
  MaxLength: 'MaxLength',
  Precision: 'Precision',
  Scale: 'Scale'
};

export interface ParsedQueryObject {
  HasParseErrors: boolean;
  ParseErrorDetails: string[];
  ReformattedInput: string;
  IsComplex: boolean;
  SelectExpressions: SelectExpression[];
  FromTable: string;
  FromTableAlias: string;
  JoinExpressions: JoinExpression[];
  GroupByExpression: GroupByExpression[];
  HavingExpression: RuleExpression;
  RuleSets: RuleSet;
}

export interface SelectExpression {
  Table: string;
  Column: string;
  Aggregate: string;
  Expression: string;
}

export interface JoinExpression {
  Type: string;
  Predicate: {
    LeftTable: string;
    LeftColumn: string;
    RightTable: string;
    RightColumn: string;
    RightTableAlias: string;
    LeftTableAlias: string;
  };
}

export interface GroupByExpression {
  Table: string;
  Value: string;
}

export interface RuleExpression {
  operator: string;
  type: string;
  table: string;
  value: string|number;
  label: string;
  field: string;
  Table: string;
  Column: string;
  Operator: string;
  Value: string;
}

export interface HavingExpression {
  field: TableField;
  operator: string;
  value: string;
}

export interface RuleSet {
  rulesSets: RuleExpression[];
  rules: RuleSet[];
  condition: string;
  Condition: string;
  Rules: RuleExpression[];
  RuleSets: RuleSet[];
}

export interface TableField {
  Aggregate: string | undefined;
  special: TableField | undefined;
  aggregate: string;
  alias?: string;
  name: string;
  type: string;
  MaxLength: number;
  Precision: number;
  Scale: number;
  field?: string;
  label?: string;
  table?: string;
  operators: {key: string, value: string}[];
  template?: TemplateColumn;
}

export interface QueryFilterConfig extends TableField {
  table?: string;
  special: QueryFilterConfig | undefined;
  field: string;
  label: string;
  name: string;
}

export interface TableObject {
  name: string;
  alias: string;
  newAlias?: string;
  fields: TableField[];
  parents?: string[];
}

export interface QueryFieldRule {
  Aggregate: string|null;
  field: string;
  label?: string;
  operator: string;
  table: string;
  type?: string;
  value: number;
  special?: boolean;
}

export interface QueryRule {
  Condition?: string;
  Rules?: RuleExpression[];
  RuleSets: QueryRule | ParsedQueryObject;
  condition: string;
  rules: QueryFieldRule[]|QueryRule[];
}

export interface TableRenderColumn {
  field: string;
  header: string;
}

export interface TableRenderButtonClickEvent {
  event: AmbiguousData;
  button: CustomTableButton;
}

export interface TableJoin {
  type: string;
  source: TableField;
  destination: TableField;
}
