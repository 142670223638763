import { Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';

export class SnackBarMessage  {
  message = '';
  action: string|undefined;
  config: MatSnackBarConfig|undefined;
}

@Injectable()
export class SnackBarService implements OnDestroy {
  private messageQueue: any[] = [];
  private subscription: Subscription|undefined;
  private snackBarRef:  MatSnackBarRef<SimpleSnackBar>|undefined;
  isInstanceVisible = false;

  constructor(public snackBar: MatSnackBar) {
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  add(message: string, config?: MatSnackBarConfig): void{

    const sbMessage = new SnackBarMessage();
    sbMessage.message = message;

    this.messageQueue.push(sbMessage);
    if (!this.isInstanceVisible) {
      this.showNext();
    }
  }

  showNext() {
    if (this.messageQueue.length === 0) {
      return;
    }

    const message = this.messageQueue.shift();
    this.isInstanceVisible = true;
    this.snackBarRef = this.snackBar.open(message.message, 'Dismiss', {
      duration: 4000
    });
    this.snackBarRef.afterDismissed().pipe(first()).subscribe(() => {
      this.isInstanceVisible = false;
      this.showNext();
    });
  }
}
