import { NgModule } from '@angular/core';
import { CoreModule } from '../../shared/core.module';

// Components-----------------------------------------------
import { LoginComponent } from './login/login.component';
import { LogoutSuccessComponent } from './logout-success/logout-success.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { WelcomeComponent } from './welcome/welcome.component';

@NgModule({
  declarations: [
    ForgotPasswordComponent,
    LoginComponent,
    LogoutSuccessComponent,
    ResetPasswordComponent,
    WelcomeComponent,
  ],
  imports: [CoreModule],
  providers: [],
})
export class AuthModule {}
