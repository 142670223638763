
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StateService } from '../state/state.service';
import { find } from 'lodash-es';
import {
  Feed,
  FeedDefinition,
  DropdownOption,
  ItemValues,
  ManualFeed
} from '../../shared/interfaces';
import { ApiClient } from '../../shared/interfaces/api-client.interface';

@Injectable()
export class FeedService {
  public apiClient = <ApiClient>{};
  // var
  keyname = {
    feed: 'feed_',
    feedFileMeta: 'feedMeta_',
    segment: 'segment_'
  };

  allSegmentId = '00000000000000000000000000000000';

  uploadTypeArr: Array<DropdownOption> = [
    { label: '...', value: '' },
    { label: 'FTP', value: 'ftp' },
    // { Name: 'HTTP', Value: 'http'},
    { label: 'Manual', value: 'manual' }
  ];

  /**
   * FeedFile Definitions
   */
  feedTypeArr: ItemValues[] = [
    // { Name: 'FTP-Pull', Value: 1, Protocol: 'FTP'}, // FTP Pull
    { Name: 'FTP-Push', Value: 2, Protocol: 'FTP' }, // FTP Push
    { Name: 'HTTP-Pull', Value: 3, Protocol: 'HTTP' }, // HTTP Pull
    { Name: 'Manual', Value: 4, Protocol: 'Manual' }, // HTTP Push / Manual
    { Name: 'BlobPull', Value: 5, Protocol: 'BlobPull' } // Blob Push / Manual
  ];

  feedFormatArr: ItemValues[] = [
    { Name: 'Tsv', Value: 1 },
    { Name: 'Csv ', Value: 2 },
    { Name: 'Excel', Value: 3 }
  ];

  feedFormats: Array<DropdownOption> = [
    { label: 'Tsv', value: 1 },
    { label: 'Csv ', value: 2 },
    { label: 'Excel', value: 3 }
  ];

  emailFormatTypes: Array<DropdownOption> = [
    { label: 'Tsv', value: 1 },
    { label: 'Csv ', value: 2 },
  ];

  /**
   * FeedJoin Definitions
   */
  feedJoinArr = [
    { Name: 'Inner', Value: 1 },
    { Name: 'Left', Value: 2 },
    { Name: 'Right', Value: 3 },
    { Name: 'Zip', Value: 4 }
  ];

  /**
   * Feed Column Definitions
   */
  columnArr = [
    { Name: 'ColumnIndex', Value: 1 },
    { Name: 'ColumnHeader', Value: 2 }
  ];

  /**
   * FeedSegment FeedFilter
   */
  // Tood: will be replaced by a string
  feedFilterArr = [
    { Name: 'Conjunctive', Value: 1 },
    { Name: 'Terminal ', Value: 2 }
  ];

  feedZippedArr: ItemValues[] = [
    { Name: 'Yes', Value: true },
    { Name: 'No', Value: false }
  ];

  feedZipped: Array<DropdownOption> = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 }
  ];

  feedZippedBool: Array<DropdownOption> = [
    { label: 'No', value: false },
    { label: 'Yes', value: true }
  ];

  ftpSchemeArr = [
    { Name: 'FTP', Value: 0 },
    { Name: 'SFTP', Value: 1 },
    { Name: 'FTPS', Value: 2 }
  ];

  ftpSchemes: Array<DropdownOption> = [
    { label: 'FTP', value: 0 },
    { label: 'SFTP', value: 1 },
    { label: 'FTPS', value: 2 }
  ];

  constructor(private stateService: StateService, ) { }

  /**
    * @ngdoc method
    * @name postFormatFeedDefinition
    * @methodOf app.home.feedService
    * @description Formats feed request
    *
    */
  postFormatFeedDefinition(data: Feed) {
    if (!data.FeedFileDefinitions || !data.FeedFileDefinitions.length) { return data; }

    data.FeedFileDefinitions = data.FeedFileDefinitions.map((row) => {
      (row as ManualFeed).TypeData = find(this.feedTypeArr, { Value: row.Type }) as ItemValues;
      (row as ManualFeed).FileFormatData = find(this.feedFormatArr, { Value: row.FileFormat }) as ItemValues;
      (row as ManualFeed).IsZippedData = find(this.feedZippedArr, { Value: row.IsZipped }) as ItemValues;
      if (typeof (row as ManualFeed).Scheme !== 'undefined') {
        (row as ManualFeed).SchemeData = find(this.ftpSchemeArr, { Value: (row as ManualFeed).Scheme });
      }
      return row;
    });
    return data;
  }

  /***********************************
      FEED FILE DEFINITIONS

  ***********************************/

  /**
  * @ngdoc method
  * @name getFeed
  * @methodOf app.home.feedService
  * @description Gets feed definition of an advertiser
  *
  */
  getFeed(id: string): Observable<Feed> {
    return this.apiClient.callHttpGet({path: `/advertisers/${id}/feed`}).pipe(
      tap(data => {
        const feed = this.postFormatFeedDefinition(data);
        this.stateService.put(this.keyname.feed + id, feed);
      }));
  }

  /**
  * @ngdoc method
  * @name getFeedSegment
  * @methodOf app.home.feedService
  * @description Gets all feed segments for an advertiser
  *
  */
  getFeedSegment(id: string): Observable<FeedDefinition> {
    return this.apiClient.callHttpGet({path: `/advertisers/${id}/feed/segments/definitions`});
  }
}
