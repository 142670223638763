import { cloneDeep } from 'lodash-es';
import { AppState } from '../../state';
import * as ManagementActions from './management.actions';
import { Management } from './management.model';

export type Action = ManagementActions.All;

/**
 * Define all store queries for Management(s)
 */
export namespace ManagementQuery {
  export const management = (state: AppState) => state.management;
}


/// Reducer function
export function managementReducer(state: Management, action: Action) {
  let currentState;
  switch (action.type) {
  case ManagementActions.RESET_MANAGEMENT_STATE:
    return {
      status: action.type,
      isDirty: false,
      claimSaveData: undefined,
      advertiserClaimSaveData: undefined,
      group: undefined,
      advertiser: undefined,
      advertiserCampaigns: undefined,
      advertiserCreatives: undefined,
      claims: undefined,
      subGroupClaims: undefined,
      parentGroupClaims: undefined,
      advertiserClaims: undefined,
      claim: undefined,
      accountId: undefined,
      error: undefined,
      groupError: undefined,
      claimsError: undefined,
      advertiserError: undefined,
      advertiserClaimsError: undefined,
      advertiserCampaignsError: undefined,
      advertiserCreativesError: undefined,
      subGroupClaimsError: undefined,
      parentGroupClaimsError: undefined,
    };
  case ManagementActions.SET_MANAGEMENT_ADVERTISER_FORM_DIRTY:
    return { ...state, isDirty: action.payload, status: action.type};
  case ManagementActions.GET_MANAGEMENT_GROUP:
    return { ...state, groupIsLoading: true, status: action.type};
  case ManagementActions.GET_MANAGEMENT_GROUP_SUCCESS:
    return { ...state, group: action.payload, groupIsLoading: false, status: action.type };
  case ManagementActions.GET_MANAGEMENT_GROUP_FAIL:
    return { ...state, groupError: action.payload, groupIsLoading: false, status: action.type };
  case ManagementActions.GET_MANAGEMENT_ADVERTISER:
    return { ...state, advertiserIsLoading: true, status: action.type};
  case ManagementActions.GET_MANAGEMENT_ADVERTISER_SUCCESS:
    return { ...state, advertiser: action.payload, advertiserIsLoading: false, status: action.type };
  case ManagementActions.GET_MANAGEMENT_ADVERTISER_FAIL:
    return { ...state, advertiserError: action.payload, advertiserIsLoading: false, status: action.type };
  case ManagementActions.GET_MANAGEMENT_ADVERTISER_CAMPAIGNS:
    return { ...state, advertiserCampaignsIsLoading: true, status: action.type};
  case ManagementActions.GET_MANAGEMENT_ADVERTISER_CAMPAIGNS_SUCCESS:
    return { ...state, advertiserCampaigns: action.payload, advertiserCampaignsIsLoading: false, status: action.type };
  case ManagementActions.GET_MANAGEMENT_ADVERTISER_CAMPAIGNS_FAIL:
    return { ...state, advertiserCampaignsError: action.payload, advertiserCampaignsIsLoading: false, status: action.type };
  case ManagementActions.GET_MANAGEMENT_ADVERTISER_CREATIVES:
    return { ...state, advertiserCreativesIsLoading: true, status: action.type};
  case ManagementActions.GET_MANAGEMENT_ADVERTISER_CREATIVES_SUCCESS:
    return { ...state, advertiserCreatives: action.payload, advertiserCreativesIsLoading: false, status: action.type };
  case ManagementActions.GET_MANAGEMENT_ADVERTISER_CREATIVES_FAIL:
    return { ...state, advertiserCreativesError: action.payload, advertiserCreativesIsLoading: false, status: action.type };
  case ManagementActions.GET_MANAGEMENT_CLAIMS:
    return { ...state, claimsIsLoading: true, status: action.type};
  case ManagementActions.GET_MANAGEMENT_CLAIMS_SUCCESS:
    return { ...state, claims: state.claims ? [...state.claims, ...action.payload] : action.payload,
      claimsIsLoading: false, status: action.type };
  case ManagementActions.GET_MANAGEMENT_CLAIMS_FAIL:
    return { ...state, claimsError: action.payload, claimsIsLoading: false, status: action.type };
  case ManagementActions.GET_MANAGEMENT_SUB_GROUP_CLAIMS:
    return { ...state, subGroupClaimsIsLoading: true, status: action.type};
  case ManagementActions.GET_MANAGEMENT_SUB_GROUP_CLAIMS_SUCCESS:
    currentState = cloneDeep(state);
    if (!currentState.subGroupClaims) {
      currentState.subGroupClaims = [];
    }
    currentState.subGroupClaims = currentState.subGroupClaims.concat(action.payload);
    currentState.subGroupClaimsIsLoading = false;
    currentState.status = action.type;
    return currentState;
  case ManagementActions.GET_MANAGEMENT_SUB_GROUP_CLAIMS_FAIL:
    return { ...state, subGroupClaimsError: action.payload, subGroupClaimsIsLoading: false, status: action.type };
  case ManagementActions.GET_MANAGEMENT_ADVERTISER_CLAIMS:
    return { ...state, advertiserClaimsIsLoading: true, status: action.type};
  case ManagementActions.GET_MANAGEMENT_ADVERTISER_CLAIMS_SUCCESS:
    return { ...state, advertiserClaims: action.payload, advertiserClaimsIsLoading: false, status: action.type };
  case ManagementActions.GET_MANAGEMENT_ADVERTISER_CLAIMS_FAIL:
    return { ...state, advertiserClaimsError: action.payload, advertiserClaimsIsLoading: false, status: action.type };
  case ManagementActions.GET_MANAGEMENT_PARENT_GROUP_CLAIMS:
    return { ...state, parentGroupClaimsIsLoading: true, status: action.type};
  case ManagementActions.GET_MANAGEMENT_PARENT_GROUP_CLAIMS_SUCCESS:
    return { ...state, parentGroupClaims: state.parentGroupClaims ? [...state.parentGroupClaims, ...action.payload] : action.payload,
      parentGroupClaimsIsLoading: false, status: action.type };
  case ManagementActions.GET_MANAGEMENT_PARENT_GROUP_CLAIMS_FAIL:
    return { ...state, parentGroupClaimsError: action.payload, parentGroupClaimsIsLoading: false, status: action.type };
  case ManagementActions.SAVE_MANAGEMENT_CLAIMS:
    return { ...state, claimSaveData: action.payload, claimIsSaving: true, status: action.type};
  case ManagementActions.SAVE_MANAGEMENT_CLAIMS_SUCCESS:
    return { ...state, claimIsSaving: false, status: action.type };
  case ManagementActions.SAVE_MANAGEMENT_CLAIMS_FAIL:
    return { ...state, claimError: action.payload, claimIsSaving: false, status: action.type };
  case ManagementActions.SAVE_MANAGEMENT_ADVERTISER_CLAIMS:
    return { ...state, advertiserClaimSaveData: action.payload, claimIsSaving: true, status: action.type};
  case ManagementActions.SAVE_MANAGEMENT_ADVERTISER_CLAIMS_SUCCESS:
    return { ...state, claimIsSaving: false, status: action.type };
  case ManagementActions.SAVE_MANAGEMENT_ADVERTISER_CLAIMS_FAIL:
    return { ...state, claimError: action.payload, claimIsSaving: false, status: action.type };
  case ManagementActions.DELETE_MANAGEMENT_CLAIMS:
    return { ...state, claimSaveData: action.payload, claimIsSaving: true, status: action.type};
  case ManagementActions.DELETE_MANAGEMENT_CLAIMS_SUCCESS:
    return { ...state, claimIsSaving: false, claim: null, status: action.type };
  case ManagementActions.DELETE_MANAGEMENT_CLAIMS_FAIL:
    return { ...state, claimError: action.payload, claimIsSaving: false, status: action.type };
  case ManagementActions.DELETE_MANAGEMENT_ADVERTISER_CLAIMS:
    return { ...state, advertiserClaimSaveData: action.payload, advertiserClaimsIsLoading: true, status: action.type};
  case ManagementActions.DELETE_MANAGEMENT_ADVERTISER_CLAIMS_SUCCESS:
    return { ...state, advertiserClaimsIsLoading: false, claim: null, status: action.type };
  case ManagementActions.DELETE_MANAGEMENT_ADVERTISER_CLAIMS_FAIL:
    return { ...state, advertiserClaimsError: action.payload, advertiserClaimsIsLoading: false, status: action.type };
  case ManagementActions.SAVE_MANAGEMENT_GROUP:
    return { ...state, groupIsSaving: true, status: action.type};
  case ManagementActions.SAVE_MANAGEMENT_GROUP_SUCCESS:
    return { ...state, groupIsSaving: false, status: action.type };
  case ManagementActions.SAVE_MANAGEMENT_GROUP_FAIL:
    return { ...state, groupError: action.payload, groupIsSaving: false, status: action.type };
  case ManagementActions.DELETE_MANAGEMENT_GROUP:
    return { ...state, groupIsSaving: true, status: action.type};
  case ManagementActions.DELETE_MANAGEMENT_GROUP_SUCCESS:
    return { ...state, groupIsSaving: false, group: null, status: action.type };
  case ManagementActions.DELETE_MANAGEMENT_GROUP_FAIL:
    return { ...state, groupError: action.payload, groupIsSaving: false, status: action.type };
  case ManagementActions.DELETE_MANAGEMENT_ADVERTISER:
    return { ...state, advertiserIsSaving: true, status: action.type};
  case ManagementActions.DELETE_MANAGEMENT_ADVERTISER_SUCCESS:
    return { ...state, advertiserIsSaving: false, advertiser: null, status: action.type };
  case ManagementActions.DELETE_MANAGEMENT_ADVERTISER_FAIL:
    return { ...state, advertiserError: action.payload, advertiserIsSaving: false, status: action.type };
  case ManagementActions.SAVE_MANAGEMENT_SUBGROUP:
    return { ...state, subgroupIsSaving: true, status: action.type};
  case ManagementActions.SAVE_MANAGEMENT_SUBGROUP_SUCCESS:
    return { ...state, subgroupIsSaving: false, status: action.type };
  case ManagementActions.SAVE_MANAGEMENT_SUBGROUP_FAIL:
    return { ...state, groupError: action.payload, subgroupIsSaving: false, status: action.type };
  case ManagementActions.SAVE_MANAGEMENT_ADVERTISER:
    return { ...state, advertiserIsSaving: true, status: action.type};
  case ManagementActions.SAVE_MANAGEMENT_ADVERTISER_SUCCESS:
    return { ...state, advertiserIsSaving: false, status: action.type };
  case ManagementActions.SAVE_MANAGEMENT_ADVERTISER_FAIL:
    return { ...state, groupError: action.payload, advertiserIsSaving: false, status: action.type };
  default:
    return state;
  }
}
