import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityMap } from '../entity/entity-metadata';
import { AdvertiserService } from '@services/advertiser/advertiser.service';
import { AccountGroup } from '../../interfaces';
import { CommonUtilityService } from '@services/utility/common-utility.service';



@Injectable()
export class AccountGroupDataService extends DefaultDataService<AccountGroup> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private accountService: AdvertiserService,
  ) {
    super(EntityMap.ModuleConfig, http, httpUrlGenerator);
  }

  getAll(): Observable<AccountGroup[]> {
    return this.accountService.getAdvertiserGroups().pipe(
      map((groups) => groups.data.map(data => CommonUtilityService.timestampStateData(data))));
  }

  getById(groupId: string): Observable<AccountGroup> {
    return this.accountService.getAdvertiserGroup(groupId).pipe(
      map((group) => CommonUtilityService.timestampStateData(group.data)));
  }
}
