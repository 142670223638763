import { Injectable } from '@angular/core';
import { DummyDataObj } from '../../shared/interfaces/dashboard/interfaces';

@Injectable()
export class DummyDataService {
  dataSorter(id: string) {
    let accounts = [
      { name: 'atlanta', id: '5f6442ab84fb4109809ec3609383c179' },
      { name: 'houston', id: '5aeef51f24694e7f8ddf86dfe6dd9a1e' },
      { name: 'new-york', id: '926e5a6330724a5d924e1cdf6c78d053' },
      { name: 'san-francisco', id: 'c6c7c65ff78d4f22a3b440afaad9e727' },
      { name: 'seattle', id: '0f2de310d3de4568b06d50bb5ed72d2e' },
      { name: 'franchisor', id: 'b08761b69e1149939a740e51e20c82ad' },
      { name: 'franchisor', id: '107669ec9d8a47a494b8bca6ff3fadc7' },
    ];
    return accounts.find((account) => account.id === id);
  }

  accountOptions = [
    {
      name: 'atlanta',
      id: '5f6442ab84fb4109809ec3609383c179',
      locationName: 'FIT Delivery - Atlanta',
    },
    {
      name: 'houston',
      id: '5aeef51f24694e7f8ddf86dfe6dd9a1e',
      locationName: 'FIT Delivery - Houston',
    },
    {
      name: 'new-york',
      id: '926e5a6330724a5d924e1cdf6c78d053',
      locationName: 'FIT Delivery - New York',
    },
    {
      name: 'san-francisco',
      id: 'c6c7c65ff78d4f22a3b440afaad9e727',
      locationName: 'FIT Delivery - San Francisco',
    },
    {
      name: 'seattle',
      id: '0f2de310d3de4568b06d50bb5ed72d2e',
      locationName: 'FIT Delivery - Seattle',
    },
  ];

  aggregateDataSource(
    dataSources: DummyDataObj[][],
    isAverage: boolean
  ): DummyDataObj[] {
    let dataSourcesLength = dataSources.length;
    if (dataSourcesLength > 1) {
      let returnArray: DummyDataObj[] = Object.values(
        [].concat
          .apply([], [...dataSources])
          .reduce((acc: any, { date, value }: DummyDataObj) => {
            if (!acc[date]) {
              acc[date] = { date: date, value: 0 };
            } else {
              acc[date].value += value;
            }
            return acc;
          }, {})
      );
      if (isAverage) {
        returnArray.forEach(
          (dummyData) => (dummyData.value = dummyData.value / dataSourcesLength)
        );
      }
      return returnArray;
    } else {
      return dataSources[0];
    }
  }
}
