import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Update } from '@ngrx/entity';
import { IntegrationDefinition } from '../../../interfaces';
import { EntityMap } from '../../entity/entity-metadata';
import { CommonUtilityService } from '@services/utility/common-utility.service';
import { GroupIntegrationsService } from '@services/integrations/group/group-integrations.service';
import { NotificationsService } from '@services/notification/notification.service';

@Injectable()
export class GroupIntegrationsDataService extends DefaultDataService<IntegrationDefinition> {
  groupId?: string
  payload?: { groupId: string, intid?: string, run?: boolean, payload: IntegrationDefinition, file?: File[],
    uploadType?: string, token?: string, customer?: boolean, emailManifest?: boolean };
    uploadedFiles: File[] = [];
    files: File[] = [];

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private notificationsService: NotificationsService,
    private integrationsService: GroupIntegrationsService
  ) {
    super(EntityMap.GroupIntegration, http, httpUrlGenerator);
  }

  getAll(): Observable<IntegrationDefinition[]> {
    return this.integrationsService.getAccountGroupIntegrations(
      this.groupId as string).pipe(
        map((integrations) => integrations.data.map(data => CommonUtilityService.timestampStateData(data))));
  }

  getById(integrationId: string): Observable<IntegrationDefinition> {
    return this.integrationsService.getAccountGroupIntegration(
      this.groupId as string, integrationId).pipe(
        map((integrations) => CommonUtilityService.timestampStateData(integrations.data)));
  }

  update(integration: Update<IntegrationDefinition>): Observable<IntegrationDefinition> {
    return this.integrationsService.editAccountGroupIntegration(
      this.groupId as string, integration.changes).pipe(
        map((integrations) => CommonUtilityService.timestampStateData(integrations.data)));
  }

  delete(integrationId: string): Observable<string | number> {
    return this.integrationsService.deleteAccountGroupIntegration(this.groupId as string, integrationId);
  }

  add(integration: IntegrationDefinition): Observable<IntegrationDefinition> {
    return this.integrationsService.addAccountGroupIntegration(
      this.groupId as string, integration).pipe(
      map((data) => {
        this.notificationsService.notify({
          severity: 'success',
          detail: 'Integration was saved successfuly.',
          summary: 'Integration saved.',
          key: 'non-sticky'
        });
        return data.data;
      }));
  }
}
