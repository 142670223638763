import { AppState } from '../../state';
import * as BuilderActions from './builder.actions';
import { Builder } from './builder.model';
export type Action = BuilderActions.All;

export namespace BuilderQuery {
  export const builder = (state: AppState) => state.builder;
}

export function builderReducer(state: Builder, action: Action) {
  switch (action.type) {
  case BuilderActions.RESET_BUILDER_STATE:
    return { status: action.type};
  case BuilderActions.SET_BUILDER_STEP:
    return { ...state, currentStep: action.payload, status: action.type};
  case BuilderActions.SET_BUILDER_FORM_DIRTY:
    return { ...state, isDirty: action.payload, status: action.type};
  case BuilderActions.GENERATE_BUILDER_SQL:
    return { ...state, query: action.payload, currentStep: 'extra', status: action.type};
  case BuilderActions.SET_BUILDER_PARSED_QUERY:
    return { ...state, query: action.query, status: action.type};
  case BuilderActions.SET_BUILDER_ADVANCED_QUERY:
    return { ...state, advancedSql: action.payload, status: action.type};
  case BuilderActions.SET_BUILDER_FILTER_QUERY:
    return { ...state, filterQuery: action.payload, status: action.type};
  case BuilderActions.GET_BUILDER_SCHEMA:
    return { ...state, status: action.type};
  case BuilderActions.GET_BUILDER_SCHEMA_SUCCESS:
    return { ...state, schema: action.payload, rawSchema: action.raw, status: action.type};
  case BuilderActions.GET_BUILDER_SCHEMA_FAIL:
    return { ...state, getSchemaError: action.payload, status: action.type};
  case BuilderActions.SAVE_BUILDER_NAME:
    return { ...state, name: action.name, status: action.type};
  case BuilderActions.SAVE_BUILDER_SQL:
    return { ...state, sql: action.payload, status: action.type};
  case BuilderActions.SAVE_BUILDER_SQL_SUCCESS:
    return { ...state, parsedQuery: action.payload, saveQueryError: undefined, status: action.type};
  case BuilderActions.SAVE_BUILDER_SQL_FAIL:
    return { ...state, saveQueryError: action.payload, status: action.type};
  case BuilderActions.SET_BUILDER_GENERATED_SQL:
    return { ...state, sql: action.sql, status: action.type};
  case BuilderActions.EDIT_BUILDER_SQL:
    return { ...state, sql: action.payload, status: action.type};
  case BuilderActions.EDIT_BUILDER_SQL_SUCCESS:
    return { ...state, parsedQuery: action.payload, saveQueryError: undefined, status: action.type};
  case BuilderActions.EDIT_BUILDER_SQL_FAIL:
    return { ...state, saveQueryError: action.payload, status: action.type};
  case BuilderActions.PARSE_BUILDER_SQL:
    return { ...state, sql: action.payload, status: action.type};
  case BuilderActions.PARSE_BUILDER_SQL_SUCCESS:
    return { ...state, queryObject: action.payload, parsed: true, saveQueryError: undefined, status: action.type};
  case BuilderActions.PARSE_BUILDER_SQL_FAIL:
    return { ...state, saveQueryError: action.payload, status: action.type};
  case BuilderActions.SET_BUILDER_PARSED:
    return { ...state, parsed: false, status: action.type};
  case BuilderActions.EXECUTE_BUILDER_SQL:
    return { ...state, sql: action.payload, status: action.type};
  case BuilderActions.EXECUTE_BUILDER_SQL_SUCCESS:
    return { ...state, queryResponse: action.payload, executeQueryError: undefined, status: action.type};
  case BuilderActions.EXECUTE_BUILDER_SQL_FAIL:
    return { ...state, executeQueryError: action.payload, status: action.type};
  case BuilderActions.SET_BUILDER_FILTER_CONFIG:
    return { ...state, tables: action.tables, selectedFields: action.selectedFields, joins: action.joins,
      filterConfig: action.config, currentStep: 'filter', status: action.type};
  case BuilderActions.SET_BUILDER_STATE:
    return { ...state, tables: action.tables, joins: action.joins,
      filterConfig: action.config, selectedFields: action.selectedFields, query: action.query, status: action.type};
  case BuilderActions.SET_BUILDER_EXTRA_SETUP:
    return { ...state, groupBy: action.groupBy, having: action.having, status: action.type};
  default:
    return state;
  }
}
