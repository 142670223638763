
<div id="login" class="login-body">
  <div class="login-panel ui-fluid" style="padding: 15px;">
    <div>
      <div class="expiredLink">
        <div class="logo">
          <img [src]="logo" />
        </div>
        <div class="text text-center">

          <div class="text">
            Please Wait... Dont close this window, it will close when completed.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
