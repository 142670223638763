import { Injectable } from '@angular/core';
import { find, map, omit } from 'lodash-es';
import { IntegrationDefinition, IntegrationType, UrlFilter, CampaignToScreeshot,
  FranconnectModuleEvent, FranConnectIntegrationModule, SqlImportIntegrationModule } from '../../shared/interfaces';
import { AmbiguousData, StringKeyStringArrayValue } from '../../shared/interfaces/common.interface';

@Injectable()
export class IntegrationUtilityService {

  generatePayload(payload: {integrationType: IntegrationType, filters?: AmbiguousData[],
    urlFilters?: UrlFilter[], campaignsToScreenshot?: CampaignToScreeshot[],
    selectedModules?: FranconnectModuleEvent, selectedColumns?: SqlImportIntegrationModule[]}): IntegrationDefinition {
    const integrationPayload = <IntegrationDefinition>{};
    payload.integrationType.ParameterDescriptors.forEach((type) => {
      if (type.ParameterName === 'CustomerViewColumnFormatters') {
        integrationPayload.CustomerViewColumnFormatters = payload.filters as AmbiguousData[];
      } else if (type.ParameterName === 'ColumnFormatSettings') {
        const vals = map(payload.urlFilters, (o) => {
          return omit(o, ['isEdit', 'delete']);
        });
        integrationPayload.ColumnFormatSettings = vals;
      } else if (type.ParameterName === 'PrimaryKeyColumns') {
        integrationPayload.PrimaryKeyColumns = map((type.DefaultValue as string), 'value');
      } else if (type.ParameterName === 'SnapshotMatchColumn') {
        integrationPayload.SnapshotMatchColumn = map((type.DefaultValue as string), 'value');
      } else if (type.ParameterName === 'ReportTypes') {
        integrationPayload.ReportTypes = map((type.DefaultValue as string), 'value');
      } else if (type.ParameterName === 'CampaignsToScreenshot') {
        integrationPayload.CampaignsToScreenshot = payload.campaignsToScreenshot ?
        this.prepareCampaignsToScreenshot(payload.campaignsToScreenshot) : {};
      } else if (type.ParameterName === 'Modules') {
        const modules: FranConnectIntegrationModule[] = [];
        if (payload.selectedModules?.modules) {
          payload.selectedModules.modules.forEach((mod, i) => {
            if (payload.selectedModules?.submodules) {
              payload.selectedModules.submodules.forEach((sub, j) => {
                if (sub.module === mod.m_sys_name) {
                  const subm = {
                    Module: mod.m_sys_name,
                    SubModule: '',
                    PropertyToImport: '',
                    DataDateRange: {
                      StartPath: '',
                      StartFormat: '',
                      EndPath: '',
                      EndFormat: ''
                    }
                  };
                  subm.SubModule = sub.sm_sys_name;
                  subm.PropertyToImport = sub.sm_schema_name;
                  subm.DataDateRange.StartPath = sub.startPath || '';
                  subm.DataDateRange.EndPath = sub.endPath || '';
                  subm.DataDateRange.StartFormat = sub.startFormat || '';
                  subm.DataDateRange.EndFormat = sub.endFormat || '';

                  modules.push(subm);
                }
              });
            }
          });
        }
        integrationPayload.Modules = modules;
      } else if (type.ParameterName === 'TablesToImport') {
        integrationPayload.TablesToImport = payload.selectedColumns;
      } else {
        integrationPayload[type.ParameterName] = type.DefaultValue;
      }
    });
    integrationPayload.TypeDescriptorId = payload.integrationType.InterfaceId;
    integrationPayload.Type = payload.integrationType.Type;
    return integrationPayload;
  }

  prepareCampaignsToScreenshot(campaignsToScreenshot: CampaignToScreeshot[]) {
    const payload: StringKeyStringArrayValue = {};
    campaignsToScreenshot.forEach(campaign => {
      payload[campaign.campaign] = [];
      if (campaign.ads) {
        campaign.ads.forEach((ad) => {
          payload[campaign.campaign].push(ad.value as string);
        });
      }
    });
    return payload;
  }

  getParameterValue(integration: IntegrationDefinition, name: string): string {
    const value = find(integration.ParameterValues, { ParameterName: name });
    return value ? value.ParameterValue : '';
  }
}
