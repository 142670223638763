
import {map, switchMap, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import {AppState} from '../../state';
import {CreativeDetailsQuery} from './creative-details.reducer';
import * as CreativeDetailsActions from './creative-details.actions';
import { CreativeService } from '@services/creative/creative.service';
import { AdvertiserService } from '@services/advertiser/advertiser.service';
import { EventsService } from '@services/event/events.service';
import { CreativePayload, IndirectCreativePayload, GetCreativeEventsSegmentPayload } from '../../../interfaces';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { NotificationsService } from '@services/notification/notification.service';
type Action = CreativeDetailsActions.AllDetails;

@Injectable()
export class CreativeDetailsFacade {
  creativeDetails$ = this.store.select(CreativeDetailsQuery.creativeDetails);
  variationLoadPrimary = true;
  getCreatives$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CreativeDetailsActions.GET_DETAILS_CREATIVE),
    map((action: CreativeDetailsActions.GetDetailsCreative) => action),
    switchMap(payload => {
      return this.creativeService.getIndirectCreative(payload.id, payload.cid).pipe(
      map((creatives) => {
       return new CreativeDetailsActions.GetDetailsCreativesSuccess(creatives);
      }),
      catchError(err => {
        return of (new CreativeDetailsActions.GetDetailsCreativesFail(err));
      } ), );
    }), ));

  getAdvertisers$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CreativeDetailsActions.GET_DETAILS_ADVERTISERS),
    map((action: CreativeDetailsActions.GetDetailsAdvertisers) => action.payload),
    switchMap(payload => {
      return this.advertiserService.getAdvertiser(payload).pipe(
      map((advertisers) => {
       return new CreativeDetailsActions.GetDetailsAdvertisersSuccess(advertisers.data);
      }),
      catchError(err => {
        return of (new CreativeDetailsActions.GetDetailsAdvertisersFail(err));
      } ), );
    }), ));

  getEvents$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CreativeDetailsActions.GET_DETAILS_EVENTS),
    switchMap(() => {
      return this.creativeService.getJobStatus().pipe(
      map((events) => {
       return new CreativeDetailsActions.GetDetailsEventsSuccess(events);
      }),
      catchError(err => {
        return of (new CreativeDetailsActions.GetDetailsEventsFail(err));
      } ), );
    })));

  getVariations$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CreativeDetailsActions.GET_DETAILS_VARIATIONS),
    map((action: CreativeDetailsActions.GetDetailsVaritions) => {
      this.variationLoadPrimary = action.primary;
      return {
        id: action.id,
        vid: action.vid,
        cid: action.cid
      };
    }),
    switchMap((payload) => {
      const params: CreativePayload = {
        advertiserId: payload.id,
        creativeId: payload.cid,
        variationId: payload.cid
      };
      return this.creativeService.getIndirectCreativeVariation(params).pipe(
      map((payload2) => {
       return new CreativeDetailsActions.GetDetailsVaritionsSuccess(payload2, this.variationLoadPrimary);
      }),
      catchError(err => {
        return of (new CreativeDetailsActions.GetDetailsVaritionsFail(err));
      } ), );
    }), ));

  getCreativeEvents$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CreativeDetailsActions.GET_DETAILS_CREATIVE_EVENTS),
    map((action: CreativeDetailsActions.GetDetailsCreativeEvents) => {
      return {
        id: action.id,
        vid: action.vid,
        cid: action.cid,
        payload: action.payload
      };
    } ),
    switchMap((payload) => {
      return this.eventService.getCreativeEventsSegment(payload.id, payload.cid, payload.vid, payload.payload).pipe(
      map((events) => {
       return new CreativeDetailsActions.GetDetailsCreativeEventsSuccess(events);
      }),
      catchError(err => {
        return of (new CreativeDetailsActions.GetDetailsCreativeEventsFail(err));
      } ), );
    }), ));

  saveCreative$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CreativeDetailsActions.SAVE_DETAILS_CREATIVE),
    map((action: CreativeDetailsActions.SaveDetailsCreative) => {
      return {
        payload: action.payload,
        id: action.id,
        cid: action.cid
      };
    } ),
    switchMap(payload => {
      return this.creativeService.editIndirectCreative(payload.payload, payload.id, payload.cid).pipe(
      map(() => {
        this.getCreative(payload.id, payload.cid);
        this.notificationsService.notify({
          severity: 'success',
          detail: 'Creative was updated successfully.',
          summary: 'Creative updated.',
          key: 'non-sticky'
        });
        return new CreativeDetailsActions.SaveDetailsCreativeSuccess();
      }),
      catchError(err => {
        return of (new CreativeDetailsActions.SaveDetailsCreativeFail(err));
      } ), );
    }), ));

  deleteCreative$: Observable<Action> = this.actions$.pipe(
    ofType(CreativeDetailsActions.DELETE_DETAILS_CREATIVE),
    map((action: CreativeDetailsActions.DeleteDetailsCreative) => {
      return {
        payload: action.payload,
        id: action.id
      };
    }),
    switchMap(payload => {
      return this.creativeService.deleteIndirectCreative(payload.id, payload.payload).pipe(
      map(() => {
        return new CreativeDetailsActions.DeleteDetailsCreativeSuccess();
      }),
      catchError(err => {
        return of (new CreativeDetailsActions.DeleteDetailsCreativeFail(err));
      } ), );
    }), );

  constructor(
    private creativeService: CreativeService,
    private advertiserService: AdvertiserService,
    private eventService: EventsService,
    private notificationsService: NotificationsService,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private actions$: Actions,
    private store: Store<AppState>
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.advertiserService.apiClient = this.apiClient;
    this.creativeService.apiClient = this.apiClient;
    this.eventService.apiClient = this.apiClient;
  }

  getCreative(id: string, cid: string): void {
    this.store.dispatch(new CreativeDetailsActions.GetDetailsCreative(id, cid));
  }

  getAdvertisers(payload: string): void {
    this.store.dispatch(new CreativeDetailsActions.GetDetailsAdvertisers(payload));
  }

  getEvents(): void {
    this.store.dispatch(new CreativeDetailsActions.GetDetailsEvents());
  }

  getVariations(id: string, cid: string, vid: string, primary = true): void {
    this.store.dispatch(new CreativeDetailsActions.GetDetailsVaritions(id, cid, vid, primary));
  }

  getCreativeEvents(id: string, cid: string, vid: string, payload: GetCreativeEventsSegmentPayload): void {
    if (id && cid && vid) {
      this.store.dispatch(new CreativeDetailsActions.GetDetailsCreativeEvents(id, cid, vid, payload));
    }
  }

  resetState(): void {
    this.store.dispatch(new CreativeDetailsActions.ResetDetailsState());
  }

  saveCreative(id: string, cid: string, payload: IndirectCreativePayload): void {
    this.store.dispatch(new CreativeDetailsActions.SaveDetailsCreative(id, cid, payload));
  }

  setCreativeError(payload: string): void {
    this.store.dispatch(new CreativeDetailsActions.SaveDetailsCreativeFail(payload));
  }

  deleteCreative(id: string, payload: string): void {
    this.store.dispatch(new CreativeDetailsActions.DeleteDetailsCreative(id, payload));
  }
}
