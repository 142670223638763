import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormGroup } from '@angular/forms';
import { Validator } from '../../../../../shared/interfaces/field.interface';
import { Time } from '../../../../../shared/interfaces/time';
import { OnTouchFunction, OnChangeFunction } from '../types';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line: no-use-before-declare
    useExisting: forwardRef(() => TimePickerFormElementComponent),
    multi: true
};
@Component({
  selector: 'app-form-time-picker',
  template: `
    <ngb-timepicker [(ngModel)]="value" [meridian]="meridian" size="small"></ngb-timepicker>
  `,
  styles: [],
  styleUrls: ['./time.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class TimePickerFormElementComponent implements ControlValueAccessor {
  @Input() appendTo = '';
  @Input() meridian = false;
  @Input() max: Date|undefined;
  @Input() placeholder = '';
  @Input() cssClass = '';
  @Input() name = '';
  @Input() group: UntypedFormGroup|undefined;
  @Input() validations: Validator[] = [];
  @Input() disabled = false;
  @Input() showTime = false;
  @Input() readonly = false;
  @Input() inline = false;
  @Input() hourFormat: number|undefined;
  @Input() itemId = '';
  @Output() onSelect = new EventEmitter<Time>();
  @Output() onClose = new EventEmitter<Time>();
  innerValue: Time|undefined;
  private onTouchedCallback: OnTouchFunction = noop;
  private onChangeCallback: OnChangeFunction<Time> = noop;

  constructor() {
  }

  get value(): Time {
    return this.innerValue as Time;
  }

  @Input() set value(v: Time) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  changed(e: Time) {
    this.onBlur();
    this.onSelect.emit(e);
  }

  closed(e: Time) {
    this.onBlur();
    this.onClose.emit(e);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: Time) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: OnChangeFunction<Time>) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: OnTouchFunction) {
    this.onTouchedCallback = fn;
  }
}
