import { map } from 'rxjs/operators';
import {
  RequestPayload
} from '../../shared/interfaces/api-client.interface';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { AppHttpHeaders } from '../helpers/http-headers.service';
import { HttpClient } from '@angular/common/http';
import { AmbiguousData } from '../../shared/interfaces/common.interface';
import { ApiDataService } from './abstract/api.service';

@Injectable()
export class ApiHttpClientService extends ApiDataService {
  private headers: Headers|undefined;
  private body: AmbiguousData|null|undefined;
  private baseUrl = environment.apiBase;
  private apiVersion = environment.apiVersion;

  constructor(
    private http: HttpClient,
    private headerService: AppHttpHeaders,
  ) {
    super();
    this.headers = <Headers>{};
    this.body = null;
  }

  getBaseUrl(): string {
    return this.baseUrl;
  }

  setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  getVersion(): string {
    return this.baseUrl;
  }

  setVersion(version: string): void {
    this.apiVersion = version;
  }

  callHttpGet(payload: RequestPayload, skipErrorHandling = false) {
    const url = this.generateUrl(payload);
    const headers = payload.headers
      ? payload.headers
      : this.headerService.createHeaders();
    this.baseUrl = environment.apiBase;
    headers['skipErrorHandling'] = skipErrorHandling;
    return this.http.get(url, {headers: headers, responseType: <'arraybuffer'>payload.responseType}).pipe(
      map((response) => {
        return response;
      })
    );
  }

  callHttpPut(payload: RequestPayload) {
    const url = this.generateUrl(payload);
    const headers = payload.headers
      ? payload.headers
      : this.headerService.createHeaders();
    this.baseUrl = environment.apiBase;
    return this.http.put(url, payload.param, { headers: headers }).pipe(
      map(response => {
        return response;
      })
    );
  }

  callHttpPutWithResponse(payload: RequestPayload) {
    const url = this.generateUrl(payload);
    const headers = payload.headers
      ? payload.headers
      : this.headerService.createHeaders();
    this.baseUrl = environment.apiBase;
    return this.http.put(url, payload.param, { headers: headers });
  }

  callHttpPost(payload: RequestPayload) {
    const url = this.generateUrl(payload);
    const headers = payload.headers
      ? payload.headers
      : this.headerService.createHeaders();
    this.baseUrl = environment.apiBase;
    return this.http.post(url, payload.param, { headers: headers, responseType: <'arraybuffer'>payload.responseType }).pipe(
      map(response => {
        return response;
      })
    );
  }

  callHttpPostWithoutResponse(payload: RequestPayload) {
    const url = this.generateUrl(payload);
    const headers = payload.headers
      ? payload.headers
      : this.headerService.createHeaders();
    this.baseUrl = environment.apiBase;
    return this.http.post(url, payload.param, { headers: headers });
  }

  callHttpDelete(payload: RequestPayload) {
    const url = this.generateUrl(payload);
    const headers = payload.headers
      ? payload.headers
      : this.headerService.createHeaders();
    return this.http.delete(url, { headers: headers });
  }

  callHttpDeleteWithBody(payload: RequestPayload) {
    const url = this.generateUrl(payload);
    const headers = payload.headers
      ? payload.headers
      : this.headerService.createHeaders();

    return this.http.request('delete', url, { headers: headers, body: payload.param }).pipe(
      map(response => {
        return response;
      })
    );
  }

  private generateUrl(payload: RequestPayload) {
    let url;
    if (payload.version === '') {
      url = `${this.baseUrl}${payload.version}${payload.path}`;
    } else if (payload.version) {
      url = `${this.baseUrl}${payload.version}${
        payload.type === '' ? '' : '/'
      }${payload.type || payload.type === '' ? payload.type : 'entities'}${
        payload.path
      }`;
    } else {
      url = `${this.baseUrl}${this.apiVersion}${
        payload.type === '' ? '' : '/'
      }${payload.type || payload.type === '' ? payload.type : 'entities'}${
        payload.path
      }`;
    }
    return url;
  }
}
