import { Injectable } from '@angular/core';
import { EntityCollectionService, EntityCollectionServiceFactory } from '@ngrx/data';
import { EntityMap } from '../entity/entity-metadata';
import { FitDataService } from './fit.data.service';
import { DailyFitApiResponse } from './models/daily-fit-api-response.interface';
import { FitPayload } from './models/fit-payload.interface';

@Injectable()
export class FitEffects {
  fitService: EntityCollectionService<DailyFitApiResponse>;
  constructor(
    private fitDataService: FitDataService,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
  ) {
    this.fitService = this.entityCollectionServiceFactory.create<DailyFitApiResponse>(EntityMap.Fit);
  }

  getDailyFit(accountId: string, payload: FitPayload) {
    this.fitDataService.payload = payload;
    this.fitDataService.accountId = accountId;
    this.fitService.getAll();
  }
}
