import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationTypesAPIResponse, IntegrationTypeAPIResponse, IntegrationsDefinitionApiResponse,
  IntegrationDefinitionApiResponse, IntegrationDefinition } from '../../../shared/interfaces';
import { ApiDataService } from '../../api/abstract/api.service';


@Injectable()
export class GroupIntegrationsService {
  constructor(private apiClient: ApiDataService) { }

  getAccountGroupIntegrationTypes(id: string): Observable<IntegrationTypesAPIResponse> {
    return this.apiClient.callHttpGet({
      path: `/${id}/integrationtypes`,
      type: 'accountgroups',
    });
  }

  getAccountGroupIntegrationType(id: string, typeId: string): Observable<IntegrationTypeAPIResponse> {
    return this.apiClient.callHttpGet({
      path: `/${id}/integrationtypes/${typeId}`,
      type: 'accountgroups',
    });
  }

  getAccountGroupIntegrations(groupId: string): Observable<IntegrationsDefinitionApiResponse> {
    return this.apiClient.callHttpGet({
      path: `/${groupId}/integrations`,
      type: 'accountgroups',
    });
  }

  getAccountGroupIntegration(groupId: string, integrationId: string): Observable<IntegrationDefinitionApiResponse> {
    return this.apiClient.callHttpGet({
      path: `/${groupId}/integrations/${integrationId}`,
      type: 'accountgroups',
    });
  }

  addAccountGroupIntegration(
    groupId: string,
    payload: Partial<IntegrationDefinition>): Observable<IntegrationDefinitionApiResponse> {
      return this.apiClient.callHttpPost({
        path: `/${groupId}/integration`,
        param: payload,
        type: 'accountgroups',
      });
  }

  editAccountGroupIntegration(
    groupId: string,
    payload: Partial<IntegrationDefinition>): Observable<IntegrationDefinitionApiResponse> {
      return this.apiClient.callHttpPut({
        path: `/${groupId}/integration/${payload.IntegrationId}`,
        param: payload,
        type: 'accountgroups',
      });
  }

  deleteAccountGroupIntegration(groupId: string, integrationId: string) {
    return this.apiClient.callHttpDelete({
      path: `/${groupId}/integration/${integrationId}`,
      type: 'accountgroups',
    });
  }
}
