import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { FranConnectIntegrationRequest, FranconnectResponse, IntegrationDefinition, IntegrationDefinitionApiResponse,
  IntegrationsDefinitionApiResponse, IntegrationType, IntegrationTypeAPIResponse, IntegrationTypesAPIResponse,
  SqlImportIntegrationRequest, SqlImportIntegrationResponse } from '../../shared/interfaces';
import { createUploadHeaders } from '../helpers/createHeaders';
import { RawSchema } from '../../shared/interfaces/query.interface';

@Injectable()
export class IntegrationsService {
  public apiClient = <ApiClient>{};
  constructor() { }

  getFranConnectMetaData(payload: FranConnectIntegrationRequest): Observable<FranconnectResponse> {
    return this.apiClient.callHttpPost({
      path: `/franconnect/metadata`,
      type: '',
      version: '',
      param: payload,
    });
  }

  getSqlImportMetaData(payload: SqlImportIntegrationRequest): Observable<SqlImportIntegrationResponse> {
    return this.apiClient.callHttpPost({
      path: `/sqlimport/metadata`,
      type: '',
      version: '/v202107',
      param: payload,
    });
  }

  getAccountIntegrationTypes(id: string): Observable<IntegrationTypesAPIResponse> {
    return this.apiClient.callHttpGet({
      path: `/${id}/integrationtypes`,
      type: 'accounts',
    });
  }

  getAccountGroupIntegrationTypes(id: string): Observable<IntegrationTypesAPIResponse> {
    return this.apiClient.callHttpGet({
      path: `/${id}/integrationtypes`,
      type: 'accountgroups',
    });
  }

  getIntegrationType(id: string, typeId: string): Observable<IntegrationType> {
    return this.apiClient.callHttpGet({path: `/${id}/integrationtypes/${typeId}`});
  }

  getAccountIntegrationType(id: string, typeId: string): Observable<IntegrationTypeAPIResponse> {
    return this.apiClient.callHttpGet({
      path: `/${id}/integrationtypes/${typeId}`,
      type: 'accounts',
    });
  }

  getAccountGroupIntegrationType(id: string, typeId: string): Observable<IntegrationTypeAPIResponse> {
    return this.apiClient.callHttpGet({
      path: `/${id}/integrationtypes/${typeId}`,
      type: 'accountgroups',
    });
  }

  getIntegration(advertiserId: string, integrationId: string): Observable<IntegrationDefinition> {
    return this.apiClient.callHttpGet({path: `/${advertiserId}/integration/${integrationId}`});
  }

  getAccountIntegrations(accountId: string): Observable<IntegrationsDefinitionApiResponse> {
    return this.apiClient.callHttpGet({
      path: `/${accountId}/integrations`,
      type: 'accounts',
    });
  }

  getAccountIntegration(advertiserId: string, integrationId: string): Observable<IntegrationDefinitionApiResponse> {
    return this.apiClient.callHttpGet({
      path: `/${advertiserId}/integrations/${integrationId}`,
      type: 'accounts',
    });
  }

  getAccountGroupIntegrations(groupId: string): Observable<IntegrationsDefinitionApiResponse> {
    return this.apiClient.callHttpGet({
      path: `/${groupId}/integrations`,
      type: 'accountgroups',
    });
  }

  getAccountGroupIntegration(groupId: string, integrationId: string): Observable<IntegrationDefinitionApiResponse> {
    return this.apiClient.callHttpGet({
      path: `/${groupId}/integrations/${integrationId}`,
      type: 'accountgroups',
    });
  }

  getSchema(advertiserId: string): Observable<RawSchema[]> {
    return this.apiClient.callHttpGet({path: `/edgewarehouse/${advertiserId}/schema/`, type: ''});
  }

  addIntegration(advertiserId: string, payload: Partial<IntegrationDefinition>): Observable<IntegrationDefinition> {
    return this.apiClient.callHttpPost({
      path: `/${advertiserId}/integration`,
      param: payload});
  }

  addAccountIntegration(
    advertiserId: string,
    payload: Partial<IntegrationDefinition>): Observable<IntegrationDefinitionApiResponse> {
      return this.apiClient.callHttpPost({
        path: `/${advertiserId}/integration`,
        param: payload,
        type: 'accounts',
      });
  }

  addAccountGroupIntegration(
    groupId: string,
    payload: Partial<IntegrationDefinition>): Observable<IntegrationDefinitionApiResponse> {
      return this.apiClient.callHttpPost({
        path: `/${groupId}/integration`,
        param: payload,
        type: 'accountgroups',
      });
  }

  editIntegration(advertiserId: string, integrationId: string, payload: Partial<IntegrationDefinition>)
  : Observable<IntegrationDefinition> {
    return this.apiClient.callHttpPut({
      path: `/${advertiserId}/integration/${integrationId}`,
      param: payload});
  }

  editAccountIntegration(
    advertiserId: string,
    integrationId: string,
    payload: Partial<IntegrationDefinition>): Observable<IntegrationDefinitionApiResponse> {
      return this.apiClient.callHttpPut({
        path: `/${advertiserId}/integration/${integrationId}`,
        param: payload,
        type: 'accounts',
      });
  }

  editAccountGroupIntegration(
    groupId: string,
    integrationId: string,
    payload: Partial<IntegrationDefinition>): Observable<IntegrationDefinitionApiResponse> {
      return this.apiClient.callHttpPut({
        path: `/${groupId}/integration/${integrationId}`,
        param: payload,
        type: 'accountgroups',
      });
  }

  deleteIntegration(advertiserId: string, integrationId: string): Observable<IntegrationDefinition[]> {
    return this.apiClient.callHttpDelete({path: `/${advertiserId}/integration/${integrationId}`});
  }

  deleteAccountIntegration(advertiserId: string, integrationId: string) {
    return this.apiClient.callHttpDelete({
      path: `/${advertiserId}/integration/${integrationId}`,
      type: 'accounts',
    });
  }

  deleteAccountGroupIntegration(groupId: string, integrationId: string) {
    return this.apiClient.callHttpDelete({
      path: `/${groupId}/integration/${integrationId}`,
      type: 'accountgroups',
    });
  }

  runIntegration(advertiserId: string, integrationId: string): Observable<void> {
    return this.apiClient.callHttpPostWithoutResponse({
      path: `/${advertiserId}/integration/${integrationId}/runimmediately`,
      type: 'accounts',
      param: {}});
  }

  uploadFile(payload: FormData, id: string, intId: string): Observable<void> {
    const api_url = `/advertisers/${id}/manualfileupload/${intId}/upload`;
    return this.apiClient.callHttpPostWithoutResponse({path: api_url, param: payload, headers: createUploadHeaders()});
  }

  uploadFileEmailManifest(payload: FormData, id: string, intId: string): Observable<void> {
    const api_url = `/advertisers/${id}/emailmanifestupload/${intId}/upload`;
    return this.apiClient.callHttpPostWithoutResponse({path: api_url, param: payload, headers: createUploadHeaders()});
  }
}
