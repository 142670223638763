import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { CustomersFilteroption } from '../../shared/interfaces/custumer.interface';

@Injectable()
export class CustomerService {
  public apiClient = <ApiClient>{};

  constructor() {}

  getCustomersFilteroptions(id: string): Observable<CustomersFilteroption[]> {
    return this.apiClient.callHttpGet({
      path: `/${id}/customers/filteroptions`,
      type: 'edgewarehouse',
    });
  }

  insertCustomer(id: string, payload: string): Observable<void> {
    const p = {
      InsertKey: payload,
    };
    return this.apiClient.callHttpPost({
      path: `/customers/${id}/update/insert`,
      type: 'edgewarehouse',
      param: p,
    });
  }
}
