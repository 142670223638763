import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EntityCollectionService, EntityCollectionServiceFactory } from '@ngrx/data';
import { ApiService } from '@services/api/api.service';
import { CampaignService } from '@services/campaign/campaign.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { NotificationsService } from '@services/notification/notification.service';
import { Campaign, CampaignPayload } from '../../interfaces';
import { EntityMap } from '../entity/entity-metadata';
import { CampaignDataService } from './campaign.data.service';

const EDIT_COMPLETE_ACTION = `[${EntityMap.Campaign}] @ngrx/data/save/update-one/success`;
const ADD_COMPLETE_ACTION = `[${EntityMap.Campaign}] @ngrx/data/save/add-one/success`;

@Injectable()
export class CampaignEffects {
  campaignsService: EntityCollectionService<Campaign>;
  accountId?:string;
  constructor(
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private campaignService: CampaignService,
    private notificationService: NotificationsService,
    private router: Router,
    private campaignDataService: CampaignDataService,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.campaignService.apiClient = this.apiClient;
    this.campaignsService = this.entityCollectionServiceFactory.create<Campaign>(EntityMap.Campaign);
    this.campaignDataService.campaignService = this.campaignService;
    this.campaignsService.entityActions$.subscribe(action => {
      if (action.type === EDIT_COMPLETE_ACTION
      || action.type === ADD_COMPLETE_ACTION) {
        this.notificationService.notify({
          severity: 'success',
          detail: 'Campaign was saved successfuly.',
          summary: 'Campaign saved.',
          key: 'non-sticky'
        });
        if (this.accountId) {
          this.router.navigate(['/campaign', this.accountId ]);
        }
      }
    });
  }

  getCampaigns(id: string) {
    this.campaignDataService.accountId = id;
    this.accountId = id;
    this.campaignsService.getAll();
  }

  getCampaign(id: string, campid: string) {
    this.campaignDataService.accountId = id;
    this.accountId = id;
    this.campaignsService.getByKey(campid);
  }

  editCampaign(payload: Partial<CampaignPayload>) {
    this.accountId = payload.AccountId;
    this.campaignsService.update(payload);
  }

  addCampaign(payload: Campaign) {
    this.accountId = payload.AccountId;
    this.campaignsService.add(payload);
  }
}
