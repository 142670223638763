import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { DropdownOption } from '../../../../../shared/interfaces';
import { OnTouchFunction, OnChangeFunction } from '../types';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line: no-use-before-declare
    useExisting: forwardRef(() => RadioFormElementComponent),
    multi: true
};
@Component({
  selector: 'app-form-radio',
  template: `
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="example-radio-group"
        [id]="id"
        (change)="changed($event)"
        [disabled]="disabled"
        [(ngModel)]="value">
        <mat-radio-button class="example-radio-button" *ngFor="let option of options" [value]="option.value">
          {{option.label}}
        </mat-radio-button>
      </mat-radio-group>`,
  styleUrls: ['./radio.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class RadioFormElementComponent implements ControlValueAccessor {
  @Input() options: DropdownOption[] = [];
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() filter = false;
  @Input() autoWidth = false;
  @Input() id = '';
  @Output() onChange = new EventEmitter<DropdownOption['value']>();
  innerValue: DropdownOption['value']|undefined;
  private onTouchedCallback: OnTouchFunction = noop;
  private onChangeCallback: OnChangeFunction<DropdownOption['value']> = noop;

  constructor() {
  }

  get value(): DropdownOption['value'] {
    return this.innerValue as DropdownOption['value'];
  }

  @Input() set value(v: DropdownOption['value']) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  changed(e: DropdownOption['value']) {
    this.onBlur();
    this.onChange.emit(e);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: DropdownOption['value']) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: OnChangeFunction<DropdownOption['value']>) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: OnTouchFunction) {
    this.onTouchedCallback = fn;
  }
}
