import { Component, Renderer2, Inject, AfterViewInit } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { VersionCheckService } from './services/version-check/version-check.service';
import { AccountFacade, AccountState } from './shared/state/global';
import { setGlobalStore } from './shared/state/global/shared/shared.store';
import { IntegrationsFacade } from './shared/state/integrations';
import { SubscriptionLike } from 'rxjs';
import * as fs from '@fullstory/browser';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { IconRegistryService } from './services/icon-registry/icon-registry.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  appServer = '';
  cssFile = 'app.css';
  isBrowser: boolean;
  accountSubscription: SubscriptionLike | undefined;
  state: AccountState | undefined;
  constructor(
    private platformLocation: PlatformLocation,
    private versionCheckService: VersionCheckService,
    private accountFacade: AccountFacade,
    private integrationsFacade: IntegrationsFacade,
    private iconRegistryService: IconRegistryService,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    public renderer: Renderer2,
    public dialog: MatDialog,
  ) {
    setGlobalStore();
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.appServer = this.isBrowser
      ? (platformLocation as any).location.origin
      : '';
    if (this.isBrowser) {
      if (environment.production) {
        this.versionCheckService.initVersionCheck(
          this.appServer + environment.versionCheckURL
        );
      }
      fs.init({ orgId: '9WEZX', devMode: !environment.production });
    }
    this.accountSubscription = this.accountFacade.account$.subscribe(
      (state) => {
        this.state = state;
      }
    );
    iconRegistryService.registerIcons();
  }

  ngAfterViewInit() {
    window.addEventListener('transitivGlobalGetAllIntegrations', () => {
      if (
        this.state &&
        this.state.activeAdvertiser &&
        this.state.activeAdvertiser.AccountId
      ) {
        this.integrationsFacade.getAllIntegrations(
          this.state.activeAdvertiser.AccountId
        );
      }
    });

    window.addEventListener('transitivGlobalGetIntegration', ((
      e: CustomEvent
    ) => {
      if (
        this.state &&
        this.state.activeAdvertiser &&
        this.state.activeAdvertiser.AccountId
      ) {
        this.integrationsFacade.getIntegration(
          this.state.activeAdvertiser.AccountId,
          e.detail.integrationId
        );
      }
    }) as EventListener);
    this.adBlockDetector();
  }

  adBlockDetector() {
    this.http.head('https://edge.fullstory.com/s/fs.js').subscribe(() => {
      // There is no AdBlocker, do nothing
    });
  }
}
