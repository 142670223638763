import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator
} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityMap } from '../entity/entity-metadata';
import { FitService } from '@services/fit/fit.service';
import { FitPayload } from './models/fit-payload.interface';
import { FitData } from './models/fit-data.interface';
import { CommonUtilityService } from '@services/utility/common-utility.service';

@Injectable()
export class FitDataService extends DefaultDataService<FitData> {
  accountId?: string
  payload: FitPayload|undefined;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private fitService: FitService
  ) {
    super(EntityMap.Fit, http, httpUrlGenerator);
  }

  getAll(): Observable<FitData[]> {
    return this.fitService.getFitData(this.accountId as string, this.payload as FitPayload)
      .pipe(
        map((fit) => [CommonUtilityService.timestampStateData(fit.data)])) as Observable<FitData[]>;
  }
}
