import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IntegrationDefinition, IntegrationsDefinitionApiResponse } from '../../interfaces';
import { DataSourceColumnSelection } from "./models/DataSourceColumnSelection";
import { DataSourceSettings } from './models/DataSourceSettings';
import { DataSourcesSchemaAPIResponse } from './models/DataSourcesSchemaAPIResponse';
import { JoinsTemplateModel } from './models/JoinsTemplateModel';
import { TransitivDataSourceCustomColumn } from './models/TransitivDataSourceCustomColumn';

export const actionDataSourcesGetSchema = createAction(
  '[DataSources] Get Schema',
  props<{ id: string }>()
);

export const actionDataSourcesGetSchemaSuccess = createAction(
  '[DataSources] Get Schema Success',
  props<{ schema: DataSourcesSchemaAPIResponse }>()
);

export const actionDataSourcesGetAll = createAction(
  '[DataSources] Get All',
  props<{ id: string }>()
);

export const actionDataSourcesGetAllSuccess = createAction(
  '[DataSources] Get All Success',
  props<{ dataSources: IntegrationDefinition[] }>()
);

export const actionDataSourcesSelect = createAction(
  '[DataSources] Select Data Source',
  props<{ dataSource: IntegrationDefinition }>()
);

export const actionDataSourcesSave = createAction(
  '[DataSources] Save',
  props<{ id: string }>()
);

export const actionDataSourcesSaveSuccess = createAction(
  '[DataSources] Save Success',
  props<{ response?: IntegrationsDefinitionApiResponse }>()
);

export const actionDataSourcesError = createAction(
  '[DataSources] Error',
  props<{ error: HttpErrorResponse }>()
);

export const actionDataSourcesSetSelectedCols = createAction(
  '[DataSources] Set Selected Cols',
  props<{ selectedColumns: DataSourceColumnSelection[] }>()
);

export const actionDataSourcesSetJoins = createAction(
  '[DataSources] Set Joins',
  props<{ joins: JoinsTemplateModel[] }>()
);

export const actionDataSourcesSetMapping = createAction(
  '[DataSources] Set Mapping',
  props<{ mappings: TransitivDataSourceCustomColumn[] }>()
);

export const actionDataSourcesSetSettings = createAction(
  '[DataSources] Set Settings',
  props<{ settings: DataSourceSettings }>()
);
