import {
  UntypedFormGroup,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  UntypedFormControl,
} from '@angular/forms';
import { Injectable } from '@angular/core';
import {
  StringKeyStringValue,
  StringKeyAmbiguousValue,
} from '../../shared/interfaces/common.interface';

@Injectable()
export class FormValidatorService {
  formErrors: StringKeyStringValue = {};
  validationMessages: StringKeyAmbiguousValue = {};
  form: UntypedFormGroup = <UntypedFormGroup>{};

  static RepeatPasswordValidator(field: UntypedFormControl) {
    const password =
      field && field.parent!
        ? field.parent!.get('NewPassword')
          ? field.parent!.get('NewPassword')
          : field.parent!.get('Password')
        : null;
    const passConfirmation = field.value;
    let oldPass = '';
    if (password) {
      oldPass = password.value;
    }
    return oldPass === passConfirmation ? null : { passwordsNotEqual: true };
  }

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  static compareValidator(compareString: string) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      const valid = compareString.toLowerCase() === control.value.toLowerCase();
      return valid ? null : { EmailsNotEqual: true };
    };
  }

  static passwordMatchValidator(control: UntypedFormControl) {
    const password = control.get('Password'); // get password from our password form control
    const confirmPassword = control.get('Password2'); // get password from our confirmPassword form control
    if (
      password &&
      confirmPassword &&
      password.value !== confirmPassword.value
    ) {
      confirmPassword.setErrors({ NoPassswordMatch: true });
    }
  }

  static authModePasswordValidator(control: UntypedFormControl) {
    const IsFormsAuth = control.get('IsFormsAuth');
    const Password = control.get('Password');
    if (IsFormsAuth && Password && IsFormsAuth.value && !Password.value) {
      Password.setErrors({ required: true });
    }
  }

  validate(): void {
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        this.formErrors[field] = '';
        this.getFormErrors(this.form, field);
      }
    }
  }

  getFormErrors(form: UntypedFormGroup, field: string): void {
    const control = form.get(field) as AbstractControl;
    if (this.formControlCanBeValidated(control)) {
      const messages = this.validationMessages[field];
      if (control.errors) {
        for (const key in control.errors) {
          if (control.errors.hasOwnProperty(key)) {
            this.formErrors[field] += messages[key] + ' ';
          }
        }
      }
    }
  }

  formControlCanBeValidated(control: AbstractControl): boolean {
    return control && control.dirty && !control.valid;
  }
}
