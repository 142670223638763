import { Component, OnInit } from '@angular/core';
import { FieldClass } from '../field/field.class';
@Component({
  selector: 'app-button',
  template: `
  <span style="margin-right: 10px">
    <app-form-button cssClass="btn btn-default newbtn" (onClick) = "onClick($event)"
    [type]="field?.inputType || 'submit'" [disabled]="!group?.valid || disabled()"
    [itemId]="field?.id" color="primary" [label]="field?.label"></app-form-button>
  </span>`,
  styles: []
})
export class ButtonComponent extends FieldClass implements OnInit {
  constructor() {
    super();
  }
  ngOnInit() {}
}
