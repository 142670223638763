import { RequestPayload } from '../../../shared/interfaces/api-client.interface';
import { Observable } from 'rxjs';

export abstract class ApiDataService {

  abstract getBaseUrl(): string;

  abstract setBaseUrl(url: string): void;

  abstract getVersion(): string;

  abstract setVersion(version: string): void;

  abstract callHttpGet(payload: RequestPayload, skipErrorHandling?: boolean): Observable<any>;

  abstract callHttpPost(payload: RequestPayload): Observable<any>;

  abstract callHttpPostWithoutResponse(payload: RequestPayload): Observable<any>;

  abstract callHttpPut(payload: RequestPayload): Observable<any>;

  abstract callHttpPutWithResponse(payload: RequestPayload): Observable<any>;

  abstract callHttpDelete(payload: RequestPayload): Observable<any>;

  abstract callHttpDeleteWithBody(payload: RequestPayload): Observable<any>;
}
