import { FieldConfig } from '../../../../shared/interfaces/field.interface';
import { UntypedFormGroup } from '@angular/forms';
import { AfterViewInit, Directive } from '@angular/core';
import { AmbiguousData } from '../../../../shared/interfaces/common.interface';

@Directive()
export class FieldClass implements AfterViewInit {
  field: FieldConfig|undefined;
  group: UntypedFormGroup|undefined;
  constructor() {}

  ngAfterViewInit() {
    if (this.field && this.field.name) {
      const f = this.group?.get(this.field.name);
      if (f && this.field.disabled && this.field.disabled()) {
        setTimeout(() => {
          f.disable();
        });
      }
    }
  }

  onChange(event: AmbiguousData) {
    if (this.field && this.field.onChange) {
      return this.field.onChange(event, this.field, this.group);
    }
  }

  onSelect(event: AmbiguousData) {
    if (this.field && this.field.onSelect) {
      this.field.onSelect(event, this.field, this.group);
    }
  }

  onClick(event: AmbiguousData) {
    if (this.field && this.field.onClick) {
      this.field.onClick(event, this.field, this.group);
    }
  }

  disabled(event?: AmbiguousData) {
    if (this.field && this.field.disabled) {
      return this.field.disabled(event, this.field, this.group);
    }
    return false;
  }

  readonly(event?: AmbiguousData) {
    if (this.field && this.field.readonly) {
      return this.field.readonly(event, this.field, this.group);
    }
    return false;
  }

  isPersistent(event?: AmbiguousData) {
    if (this.field && this.field.isPersistent) {
      return this.field.isPersistent(event, this.field, this.group);
    }
    return false;
  }

  display(event?: AmbiguousData) {
    if (this.field && this.field.display) {
      return this.field.display(event, this.field, this.group);
    }
    return true;
  }
}
