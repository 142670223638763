import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  DashboardConfigDataSource,
  DashboardLeaderboardAPIResponse,
  DashboardLeaderboardPayload,
  DashboardLeaderboardV2Payload,
  DashboardPagePayload,
  DashboardPageResponse,
  DashboardV2PagePayload,
  DashboardV2PageResponse,
  DashboardV2WithDateRollUpPageResponse,
  DashobardLeaderboardV2APIResponse,
  LocationCountByActiveFiltersPayload,
  LocationCountByActiveFiltersResponse,
  LocationSummaryResponse,
} from '../../shared/interfaces';
import { ApiClient } from '../../shared/interfaces/api-client.interface';

@Injectable()
export class DashboardService {
  public apiClient = <ApiClient>{};

  constructor() {}

  postPageData(
    accountId: string,
    integrationId: string,
    pageId: string,
    payload: DashboardPagePayload
  ): Observable<DashboardPageResponse> {
    return this.apiClient.callHttpPost({
      path: `/accounts/${accountId}/dashboard/${integrationId}/page/${pageId}`,
      type: '',
      param: payload,
    });
  }

  postPageDataV2(
    accountId: string,
    integrationId: string,
    pageId: string,
    payload: DashboardV2PagePayload
  ): Observable<DashboardV2PageResponse> {
    return this.apiClient.callHttpPost({
      path: `/accounts/${accountId}/dashboard/${integrationId}/page/${pageId}`,
      type: '',
      version: '/v202204',
      param: payload,
    });
  }

  postPageDataV2WithDateRollUp(
    accountId: string,
    integrationId: string,
    pageId: string,
    payload: DashboardV2PagePayload
  ): Observable<DashboardV2WithDateRollUpPageResponse> {
    return this.apiClient.callHttpPost({
      path: `/accounts/${accountId}/dashboard/${integrationId}/page/${pageId}`,
      type: '',
      version: '/v202303',
      param: payload,
    })
  }

  getLocationSummarydata(
    accountId: string
  ): Observable<LocationSummaryResponse> {
    return this.apiClient.callHttpGet({
      path: `/accounts/${accountId}/locations/summary`,
      type: '',
    });
  }

  getLocationCountByActiveFilters(
    accountId: string,
    payload: LocationCountByActiveFiltersPayload
  ): Observable<LocationCountByActiveFiltersResponse> {
    return this.apiClient.callHttpPost({
      path: `/accounts/${accountId}/locations/count`,
      type: '',
      version: '/v202301',
      param: payload,
    });
  }

  getAccountDashboardConfigSeriesDataSource(
    accountId: string
  ): Observable<DashboardConfigDataSource> {
    return this.apiClient.callHttpGet({
      path: `/accounts/${accountId}/dashboard/schema`,
      type: '',
    });
  }

  getAccountGroupDashboardConfigSeriesDataSource(
    groupId: string
  ): Observable<DashboardConfigDataSource> {
    return this.apiClient.callHttpGet({
      path: `/accountgroups/${groupId}/dashboard/schema`,
      type: '',
    });
  }

  postDashboardLeaderboardData(
    accountId: string,
    integrationId: string,
    payload: DashboardLeaderboardPayload
  ): Observable<DashboardLeaderboardAPIResponse> {
    return this.apiClient.callHttpPost({
      path: `/accounts/${accountId}/dashboard/leaderboard/${integrationId}`,
      type: '',
      param: payload,
    });
  }

  postDashboardLeaderboarV2dData(
    accountId: string,
    integrationId: string,
    payload: DashboardLeaderboardV2Payload
  ): Observable<DashobardLeaderboardV2APIResponse> {
    return this.apiClient.callHttpPost({
      path: `/accounts/${accountId}/dashboard/leaderboard/${integrationId}`,
      type: '',
      param: payload,
      version: '/v202209',
    });
  }
}
