import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormGroup } from '@angular/forms';
import { OnTouchFunction, OnChangeFunction } from '../types';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line: no-use-before-declare
    useExisting: forwardRef(() => SwitchFormElementComponent),
    multi: true
};
@Component({
  selector: 'app-form-switch',
  template: `
    <div *ngIf="group" [formGroup]="group">
      <mat-slide-toggle
        [id]="id" [(ngModel)]="value" [formControlName]="name"
        [checked]="checked"
        (click)="changed()"
        [disabled]="disabled">
        {{label}}<ng-content></ng-content>
      </mat-slide-toggle>
    </div>
    <div *ngIf="!group">
      <mat-slide-toggle
        [id]="id" [(ngModel)]="value"
        (click)="changed()"
        [checked]="checked"
        [disabled]="disabled">
        {{label}}<ng-content></ng-content>
      </mat-slide-toggle>
    </div>`,
  styles: [],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class SwitchFormElementComponent implements ControlValueAccessor {
  @Input() placeholder = '';
  @Input() name = '';
  @Input() type = 'text';
  @Input() label = '';
  @Input() group: UntypedFormGroup|undefined;
  @Input() disabled = false;
  @Input() checked = false;
  @Input() filter = false;
  @Input() autoWidth = false;
  @Input() id = '';
  innerValue = false;
  @Output() onChange = new EventEmitter<boolean>();
  private onTouchedCallback: OnTouchFunction = noop;
  private onChangeCallback: OnChangeFunction<boolean> = noop;

  constructor() {
  }

  get value(): boolean {
    return this.innerValue;
  }

  @Input() set value(v: boolean) {
    if (v !== this.innerValue) {
      this.onChangeCallback(v);
      this.innerValue = v;
      this.onChange.emit(v);
    }
  }

  changed() {
    this.onChange.emit(this.value);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: boolean) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: OnChangeFunction<boolean>) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: OnTouchFunction) {
    this.onTouchedCallback = fn;
  }
}
