import { Component, OnInit } from '@angular/core';
import { FieldClass } from '../field/field.class';
@Component({
  selector: 'app-label',
  template: `
    <div class="col-md-5 mar-b-50" *ngIf="display()">
      <div class="col-md-6 nopadding mar-b-30">
        <app-form-label [label]="field.label"></app-form-label>
      </div>
    </div>`,
  styles: []
})
export class LabelComponent extends FieldClass implements OnInit {
  constructor() {
    super();
  }
  ngOnInit() {
  }
}
