import { HttpHeaders } from '@angular/common/http';

declare var __: any;

export const createHeaders = () => {
  return new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': 'bearer ' +  (__.cookie.get('x-bearer') || null)
  });
};

export const createAuthHeaders = () => {
  return new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  });
};

// Added new function for file upload headers
export const createUploadHeaders = () => {
  return new HttpHeaders({
    'Authorization': 'bearer ' + __.cookie.get('x-bearer')
  });
};

export const createNoAuthHeaders = () => {
  return new HttpHeaders({})
};

export const createUrlEncodedFormHeaders = () => {
  return new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded'
  });
};

export default createHeaders;
