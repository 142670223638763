import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator } from '@ngrx/data';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Update } from '@ngrx/entity';
import { find } from 'lodash-es';
import { IntegrationDefinition, IntegrationDefinitionApiResponse } from '../../../interfaces';
import { EntityMap } from '../../entity/entity-metadata';
import { CommonUtilityService } from '@services/utility/common-utility.service';
import { AccountIntegrationsService } from '@services/integrations/account/account-integrations.service';
import { CustomerService } from '@services/customer/customer.service';
import { FeedService } from '@services/feed/feed.service';
import { NotificationsService } from '@services/notification/notification.service';

@Injectable()
export class AccountIntegrationsDataService extends DefaultDataService<IntegrationDefinition> {
  accountId?: string;
  payload?: { accountId: string, intid?: string, run?: boolean, payload: IntegrationDefinition, file?: File[],
    uploadType?: string, token?: string, customer?: boolean, emailManifest?: boolean };
    uploadedFiles: File[] = [];
    files: File[] = [];

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private integrationsService: AccountIntegrationsService,
    private notificationsService: NotificationsService,
    private customerService: CustomerService,
    private feedService: FeedService,
  ) {
    super(EntityMap.AccountIntegration, http, httpUrlGenerator);
  }

  getAll(): Observable<IntegrationDefinition[]> {
    return this.integrationsService.getAccountIntegrations(
      this.accountId as string).pipe(
        map((integrations) => integrations.data.map(data => CommonUtilityService.timestampStateData(data))));
  }

  getById(integrationId: string): Observable<IntegrationDefinition> {
    return this.integrationsService.getAccountIntegration(
      this.accountId as string, integrationId).pipe(
        map((integrations) => CommonUtilityService.timestampStateData(integrations.data)));
  }

  add(integration: IntegrationDefinition): Observable<IntegrationDefinition> {
    return this.integrationsService.addAccountIntegration(
      this.accountId as string, integration).pipe(
        tap((data) => {
          this.processCustomerData();
          this.uploadFiles(data);
          this.runIntegration(data);
          this.runBulkUpload(data);
          this.payload = undefined;
        }),
        map((data) => {
          this.notificationsService.notify({
            severity: 'success',
            detail: 'Integration was saved successfuly.',
            summary: 'Integration saved.',
            key: 'non-sticky'
          });
          return data.data;
        }));
  }

  private runBulkUpload(data: IntegrationDefinitionApiResponse) {
    if (!this.payload?.emailManifest && this.payload?.file) {
      this.bulkUploadFiles(this.payload?.file || [], this.payload?.payload.FileFormat, this.payload?.accountId, data.data.IntegrationId);
    }
  }

  private runIntegration(data: IntegrationDefinitionApiResponse) {
    if (this.payload?.run) {
      this.notificationsService.notify({
        severity: 'success',
        detail: 'Integration is Running.',
        summary: 'Running integration.',
        key: 'non-sticky',
      });
      const sub = this.integrationsService.runIntegration(this.payload?.accountId, data.data.IntegrationId).subscribe(() => {
        this.notificationsService.notify({
          severity: 'success',
          detail: 'Integration triggered successfully.',
          summary: 'Integration triggered.',
          key: 'non-sticky',
        });
        sub.unsubscribe();
      });
    }
  }

  private uploadFiles(data: IntegrationDefinitionApiResponse) {
    if (this.payload?.emailManifest && this.payload?.file) {
      const formData = new FormData();
      formData.append('file', this.payload?.file[0]);
      formData.append('fileName', this.payload?.file[0].name);
      formData.append('uploadType', this.payload?.uploadType || 'File');
      const sub1 = this.integrationsService
        .uploadFileEmailManifest(formData, this.payload?.accountId, data.data.IntegrationId)
        .subscribe((d) => {
          this.notificationsService.notify({
            severity: 'success',
            detail: 'Integration saved successfully.',
            summary: 'Integration saved.',
            key: 'non-sticky',
          });

          sub1.unsubscribe();
        });
    }
  }

  private processCustomerData() {
    if (this.payload?.customer) {
      this.customerService
        .insertCustomer(this.payload?.accountId, this.payload?.token || '')
        .subscribe(() => {
          this.notificationsService.notify({
            severity: 'success',
            detail: 'Single Customer View update has started',
            summary: 'Single Customer update started',
            key: 'non-sticky',
          });
        });
    }
  }

  update(integration: Update<IntegrationDefinition>): Observable<IntegrationDefinition> {
    return this.integrationsService.editAccountIntegration(
      this.accountId as string, integration.changes).pipe(
        tap((data) => {
          this.processCustomerData();
          this.uploadFiles(data);
          this.runIntegration(data);
          this.runBulkUpload(data);
          this.payload = undefined;
        }),
        map((integrations) => CommonUtilityService.timestampStateData(integrations.data)));
  }

  delete(integrationId: string): Observable<string | number> {
    return this.integrationsService.deleteAccountIntegration(this.accountId as string, integrationId);
  }

  private bulkUploadFiles(files: File[], format: string, id: string, intid: string) {
    this.uploadedFiles = [];
    this.files = files;
    files?.forEach((file, i) => {
      if (file) {
        this.uploadFile(file, format, id, intid);
      }
    });
  }

  uploadFile(file: File, format: string, id: string, intid: string) {
    const type = find(this.feedService.feedFormats, {
      value: format,
    });
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    formData.append('uploadType', type ? type.label.toString() : 'File');
    this.integrationsService
      .uploadFile(formData, id, intid)
      .subscribe(
        () => {
          this.uploadedFiles.push(file);
          if (this.uploadedFiles.length === this.files.length) {
            this.notificationsService.notify({
              severity: 'success',
              detail: 'Files uploaded successfully.',
              summary: 'Files uploaded.',
              key: 'non-sticky',
            });
          }
        },
        (data) => {}
      );
  }
}
