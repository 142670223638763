import {
  switchMap,
  map,
  catchError,
} from 'rxjs/operators';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as SignalR from '@microsoft/signalr';
import { Observable, of, from } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { chain, cloneDeep, filter, find, orderBy, reject, unionBy } from 'lodash-es';
import { AppState } from '../state';
import * as TaskActions from './tasks.actions';
import { LoginService } from '@services/login/login.service';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import {
  TaskState,
  SignalRConnectionInfo,
  SignalRNotification,
  ParsedTask,
} from './tasks.model';
import { TaskQuery } from './tasks.reducer';
import { AuthService } from '@services/auth/auth.service';
import { SignarlRService } from '@services/signal-r/signarl-r.service';
type Action = TaskActions.All;

@Injectable()
export class TaskFacade {
  tasks$ = this.store.select(TaskQuery.tasks);
  getConnectionretries = 2;
  startConnectionRetries = 2;
  addToGroupRetries = 2;
  isBrowser: boolean;
  addAdvertiserAfterConnection = false;
  advertiserId = '';
  connectionInfo: SignalRConnectionInfo|undefined;
  retryTImeout = 15000;


  signalRStartConnectionProcess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.SIGNALR_GET_CONNECTION_INFO),
    map((action: TaskActions.SignalRGetConnectionInfo) => action),
    switchMap(() => {
      return this.signalRService.getConnectionInfo().pipe(
        map((response) => {
          this.connectionInfo = response;
          return new TaskActions.SignalRStartConnection(response);
        }),
        catchError((err) => {
          this.retryGetConnectionInfo();
          return of(new TaskActions.SignalRGetConnectionInfoFailed(err));
        })
      );
    })
  ));


  signalRStartConnection$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.SIGNALR_START_CONNECTION),
    map((action: TaskActions.SignalRStartConnection) => action),
    switchMap((action: TaskActions.SignalRStartConnection) => {
      return from(this.startConnection(action.payload)).pipe(
        map((response) => {
          console.log('connected to SignalR!');
          this.retrieveLocalNotifications();
          this.signalRService.setConnectionStatus(true);
          const state = this.getState();
          if (state.addAdvertiserAfterConnection) {
            this.signalRAddToGroup(state.advertiser);
          }
          return new TaskActions.SignalRStartConnectionSuccess();
        }),
        catchError((err) => {
          this.retryStartConnection();
          return of(new TaskActions.SignalRGetConnectionInfoFailed(err));
        })
      );
    })
  ));


  signalRAddToGroup$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.SIGNALR_ADD_TO_GROUP),
    map((action: TaskActions.SignalRAddToGroup) => action),
    switchMap((action: TaskActions.SignalRAddToGroup) => {
      return this.signalRService.addToGroup(action.advertiserId).pipe(
        map(() => {
          return new TaskActions.SignalRAddToGroupSuccess();
        }),
        catchError((err) => {
          this.retryAddToGroup();
          return of(new TaskActions.SignalRAddToGroupFailed(err));
        })
      );
    })
  ));


  signalRRemoveFromGroup$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.SIGNALR_REMOVE_FROM_GROUP),
    map((action: TaskActions.SignalRRemoveFromGroup) => action),
    switchMap((action: TaskActions.SignalRRemoveFromGroup) => {
      return this.signalRService.removeFromGroup(action.advertiserId).pipe(
        map(() => {
          return new TaskActions.SignalRRemoveFromGroupSuccess();
        }),
        catchError((err) => {
          return of(new TaskActions.SignalRRemoveFromGroupFailed(err));
        })
      );
    })
  ));


  signalRSwitchAdvertiser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.SIGNALR_SWITCH_ADVERTISER),
    map((action: TaskActions.SignalRSwitchAdvertiser) => action),
    switchMap((action: TaskActions.SignalRSwitchAdvertiser) => {
      const oldAdvertiser = this.signalRService.localAdvertiserId;
      return this.signalRService.removeFromGroup(oldAdvertiser).pipe(
        map(() => {
          return new TaskActions.SignalRAddToGroup(action.advertiserId);
        }),
        catchError((err) => {
          return of(new TaskActions.SignalRSwitchAdvertiserFailed(err));
        })
      );
    })
  ));


  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private authService: AuthService,
    private loginService: LoginService,
    private signalRService: SignarlRService,
    @Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.apiClient.errorHandler = this.errorHandler;
    this.loginService.apiClient = this.apiClient;
    this.signalRService.apiClient = this.apiClient;
  }

  retryGetConnectionInfo() {
    setTimeout(() => {
      if (this.getConnectionretries > 0) {
        this.signalRStartConnectionProcess(this.addAdvertiserAfterConnection, this.advertiserId);
        this.getConnectionretries -= 1;
      }
    }, this.retryTImeout);
  }

  retryStartConnection() {
    setTimeout(() => {
      if (this.startConnectionRetries > 0 && this.connectionInfo) {
        this.store.dispatch(new TaskActions.SignalRStartConnection(this.connectionInfo));
        this.startConnectionRetries -= 1;
      }
    }, this.retryTImeout);
  }

  retryAddToGroup() {
    setTimeout(() => {
      if (this.addToGroupRetries > 0) {
        const state = this.getState();
        if (state.addAdvertiserAfterConnection) {
          this.signalRAddToGroup(state.advertiser);
        }
        this.addToGroupRetries -= 1;
      }
    }, this.retryTImeout);
  }

  clearNotifications(): void {
    const state = this.getState();
    const clearedNotification = unionBy(state.notifications, state.clearedNotification || [], 'AcknowledgedId');
    localStorage.setItem('clearedNotifications', JSON.stringify(clearedNotification));
    this.store.dispatch(new TaskActions.SignalRClearNotifications(clearedNotification));
  }

  retrieveLocalNotifications(): void {
    const localNotifications = localStorage.getItem('notifications');
    if (localNotifications) {
      this.signalRReceivedNotification(JSON.parse(localNotifications));
    }
  }

  dismissAllNotifications(): void {
    this.store.dispatch(new TaskActions.SignalRDismissAll());
  }

  retrieveLocalClearedNotifications(): void {
    const localNotifications = localStorage.getItem('clearedNotifications');
    const state = this.getState();
    const clearedNotification = localNotifications ? JSON.parse(localNotifications) : [];
    if (clearedNotification.length > 0 && state.notifications && (!state.clearedNotification || state.clearedNotification.length === 0) &&
      state.notifications.length > 0 && state.notifications.length >= clearedNotification.length) {
      this.store.dispatch(new TaskActions.SignalRClearNotifications(clearedNotification));
    }
  }

  setNotified(payload: string): void {
    const state = this.getState();
    let unnotifiedTasks: string[] = [];
    unnotifiedTasks = cloneDeep(state.unnotified) || [];
    const i = unnotifiedTasks.indexOf(payload);
    if (i > -1) {
      unnotifiedTasks.splice(i, 1);
    }
    this.store.dispatch(new TaskActions.TaskSetNotified(unnotifiedTasks));
  }

  clearNotified() {
    this.store.dispatch(new TaskActions.TaskSetNotified([]));
  }

  signalRStartConnectionProcess(
    addAdvertiserAfterConnection: boolean,
    advertiserId: string
  ): void {
    this.addAdvertiserAfterConnection = addAdvertiserAfterConnection;
    this.advertiserId = advertiserId;
    this.store.dispatch(
      new TaskActions.SignalRGetConnectionInfo(
        addAdvertiserAfterConnection,
        advertiserId
      )
    );
  }

  startConnection(response: SignalRConnectionInfo) {
    if (this.isBrowser) {
      const data = {
        username: '',
        newMessage: '',
        messages: [],
        ready: false,
        accessToken: '',
        url: '',
      };
      data.accessToken = response.accessToken || (response.accessKey as string);
      data.url = response.url || (response.endpoint as string);
      data.ready = true;
      const options = {
        accessTokenFactory: () => data.accessToken,
      };
      const connection = new SignalR.HubConnectionBuilder()
        .withUrl(data.url, options)
        .configureLogging(SignalR.LogLevel.Information)
        .build();
        connection.on(this.signalRService.signalRMethod, (e) => {
          this.notificationReceived(e);
          this.retrieveLocalClearedNotifications();
        }
      ); // when a notification is received, use this function to process it.
      connection.onclose(() => console.log('disconnected'));
      console.log('connecting to SignalR...');
      this.signalRService.connection = connection;

      return this.signalRService.connection.start();
    }
    return of(null);
  }

  notificationReceived(notification: SignalRNotification) {
    const state = this.getState();
    let notifications: SignalRNotification[] = [];
    notifications = [...(state.notifications || [])];
    // checking to see if notification is already present or is an old notification
    const laskAckId = localStorage.getItem('laskAckId') || '';
    if (laskAckId === '' || laskAckId < notification.AcknowledgedId) {
      if (
        notifications.findIndex((local) => {
          if (notification.NotificationType === 1) {
            return (
              local.AcknowledgedId === notification.AcknowledgedId &&
              local.Data.EventId === notification.Data.EventId
            );
          }
          return (
            local.AcknowledgedId === notification.AcknowledgedId &&
            local.Data.TaskId === notification.Data.TaskId
          );
        }) < 0
      ) {
        notifications.push(notification);
        notifications.sort(
          (a, b) => Number(a.AcknowledgedId) - Number(b.AcknowledgedId)
        );
      }
    }
    localStorage.setItem('notifications', JSON.stringify(notifications));
    this.signalRReceivedNotification(notifications);
  }

  private signalRReceivedNotification(notifications: SignalRNotification[]) {
    const state = this.getState();
    notifications = orderBy(notifications, ['Timestamp'], ['desc']);
    let uncompletedTasks = state.uncompletedTasks || [];
    let unread: string[] = state.unread || [];
    let unnotified: string[] = state.unnotified || [];
    const tasks: ParsedTask[] = chain(notifications)
    .groupBy((o) => {
      return o.Data.TaskId;
    })
    .map((value, key) => ({ task: key, values: value }))
    .value();
    const uncompleted = unionBy(uncompletedTasks, filter(tasks, (v) => v.values.length === 1), 'task');
    uncompletedTasks = uncompleted;
    let failedTasks: SignalRNotification[] = [];
    uncompleted.forEach(task => {
      const t = find(tasks, {task: task.task});
      if (t && t.values.length > 1) {
        const comp = filter(t.values, (ts) => {
          return ts.Data.JobSuccessful === 'False';
        });
        if (comp.length === 0) {
          uncompletedTasks = reject(uncompletedTasks, { task: task.task });
          if (unread.indexOf(task.task) === -1) {
            unread = [...unread, task.task];
          }
          if (unnotified.indexOf(task.task) === -1) {
            unnotified = [...unnotified, task.task];
          }
        } else {
          failedTasks = [...failedTasks, ...comp];
          if (state?.failNotifiedTasks) {
            this.removeAlreadyNotifiedTasks(failedTasks, state.failNotifiedTasks);
          }
        }
      }
    });
    this.store.dispatch(new TaskActions.SignalRReceivedNotification({
      notifications: notifications,
      uncompletedTasks: uncompletedTasks,
      unnotified: unnotified,
      failedTasks: failedTasks
    }));
  }

  private removeAlreadyNotifiedTasks(failedTasks: SignalRNotification[], failNotifiedTasks: SignalRNotification[]) {
    for (let i = 0; i <= failedTasks.length; i++) {
      if (failedTasks[i]) {
        const ft = find(failNotifiedTasks || [], { AcknowledgedId: failedTasks[i].AcknowledgedId });
        if (ft) {
          failedTasks.splice(i, 1);
        }
      }
    }
  }

  signalRAddToGroup(advertiserId: string): void {
    this.store.dispatch(new TaskActions.SignalRAddToGroup(advertiserId));
  }

  signalRRemoveFromGroup(advertiserId: string): void {
    this.store.dispatch(new TaskActions.SignalRRemoveFromGroup(advertiserId));
  }

  signalRSwitchAdvertiser(advertiserId: string): void {
    this.store.dispatch(new TaskActions.SignalRSwitchAdvertiser(advertiserId));
  }

  signalRSetFailed(tasks: SignalRNotification[]): void {
    this.store.dispatch(new TaskActions.SignalRSetFailed(tasks));
  }

  signalRSetFailNotified(tasks: SignalRNotification[]): void {
    this.store.dispatch(new TaskActions.SignalRSetFailNotified(tasks));
  }

  setAttributionComplete(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetAttributionComplete(payload));
  }

  setAudienceComplete(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetAudienceComplete(payload));
  }

  clearAudienceComplete(): void {
    this.store.dispatch(new TaskActions.TaskClearAudienceComplete());
  }

  setScvComplete(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetScvComplete(payload));
  }

  setLtvComplete(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetLtvComplete(payload));
  }

  setRfmComplete(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetRfmComplete(payload));
  }

  setCustomerGraphPersonaQueryComplete(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetCustomerGraphPersonaQueryComplete(payload));
  }

  setCustomerGraphAudienceQueryComplete(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetCustomerGraphAudienceQueryComplete(payload));
  }

  setCustomerQueryComplete(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetCustomerQueryComplete(payload));
  }

  setCustomerCountQueryComplete(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetCustomerCountQueryComplete(payload));
  }

  setCustomerGraphPersonaQueryFailed(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetCustomerGraphPersonaQueryFailed(payload));
  }

  setCustomerGraphAudienceQueryFailed(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetCustomerGraphAudienceQueryFailed(payload));
  }

  setCustomerQueryFailed(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetCustomerQueryFailed(payload));
  }

  setCustomerCountQueryFailed(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetCustomerCountQueryFailed(payload));
  }

  setAttributionFailed(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetAttributionFailed(payload));
  }

  setAudienceFailed(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetAudienceFailed(payload));
  }

  clearAudienceFailed(): void {
    this.store.dispatch(new TaskActions.TaskClearAudienceFailed());
  }

  setScvFailed(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetScvFailed(payload));
  }

  setLtvFailed(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetLtvFailed(payload));
  }

  setRfmFailed(payload: string): void {
    this.store.dispatch(new TaskActions.TaskSetRfmFailed(payload));
  }

  getTasksInfo(payload: string): void {
    this.store.dispatch(new TaskActions.TaskGetAll(payload));
  }

  private getState(): TaskState {
    let state = <TaskState>{};

    this.store
      .select(TaskQuery.tasks)
      .subscribe((s) => {
        state = s;
      })
      .unsubscribe();

    return state;
  }
}
