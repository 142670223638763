import { Action } from '@ngrx/store';
import {
  Creative,
  CampaignPayload,
  Campaign,
  AdPayload,
  Ad,
  FeedDefinition,
  CreativeVariation,
  CreativePayload,
  IntegrationDefinition
} from '../../../interfaces';
import { CreativeSegmentKey } from '../../../interfaces/creative-segment-key.interface';

export const GET_CAMPAIGN_AD_CREATIVES = 'CampaignAdSettings get creatives';
export const GET_CAMPAIGN_AD_CREATIVES_SUCCESS = 'CampaignAdSettings get creatives success';
export const GET_CAMPAIGN_AD_CREATIVES_FAIL = 'CampaignAdSettings get creatives fail';

export const GET_CAMPAIGN_AD_VARIATION = 'CampaignAdSettings get variation';
export const GET_CAMPAIGN_AD_VARIATION_SUCCESS = 'CampaignAdSettings get variation success';
export const GET_CAMPAIGN_AD_VARIATION_FAIL = 'CampaignAdSettings get variation fail';

export const GET_CAMPAIGN_AD_CAMPAIGNS = 'CampaignAdSettings get campaigns';
export const GET_CAMPAIGN_AD_CAMPAIGNS_SUCCESS = 'CampaignAdSettings get campaigns success';
export const GET_CAMPAIGN_AD_CAMPAIGNS_FAIL = 'CampaignAdSettings get campaigns fail';

export const GET_CAMPAIGN_AD_ADS = 'CampaignAdSettings get ads';
export const GET_CAMPAIGN_AD_ADS_SUCCESS = 'CampaignAdSettings get ads success';
export const GET_CAMPAIGN_AD_ADS_FAIL = 'CampaignAdSettings get ads fail';

export const GET_CAMPAIGN_AD_FEED = 'CampaignAdSettings get feed';
export const GET_CAMPAIGN_AD_FEED_SUCCESS = 'CampaignAdSettings get feed success';
export const GET_CAMPAIGN_AD_FEED_FAIL = 'CampaignAdSettings get feed fail';

export const GET_CAMPAIGN_AD_INTEGRATION = 'CampaignAdSettings get integration';
export const GET_CAMPAIGN_AD_INTEGRATION_SUCCESS = 'CampaignAdSettings get integration success';
export const GET_CAMPAIGN_AD_INTEGRATION_FAIL = 'CampaignAdSettings get integration fail';

export const ADD_CAMPAIGN_AD_SEGMENTKEY = 'CampaignAdSettings add segment key';
export const SET_CAMPAIGN_AD_NEW_AD = 'CampaignAdSettings set new ad';

export const SET_CAMPAIGN_AD_PERSISTENT = 'CampaignAdSettings set persistent';
export const TOGGLE_CAMPAIGN_AD_PERSISTENT = 'CampaignAdSettings toggle persistent';
export const SET_CAMPAIGN_AD_AD_WIDTH = 'CampaignAdSettings set ad width';
export const SET_CAMPAIGN_AD_AD_HEIGHT = 'CampaignAdSettings set ad height';
export const SET_CAMPAIGN_AD_AD_NAME = 'CampaignAdSettings set ad name';
export const SET_CAMPAIGN_AD_SAVE_DATA = 'CampaignAdSettings set ad save data';
export const SET_CAMPAIGN_AD_AD_ACTIVE = 'CampaignAdSettings set ad status';
export const SET_CAMPAIGN_AD_AD_FLIGHTSTART = 'CampaignAdSettings set ad flight date';
export const SET_CAMPAIGN_AD_AD_FLIGHTEND = 'CampaignAdSettings set ad flight end';
export const SET_CAMPAIGN_AD_AD_ROTATION = 'CampaignAdSettings set ad rotation';
export const SET_CAMPAIGN_AD_AD_SEGMENTKEY = 'CampaignAdSettings set ad segment key';
export const DELETE_CAMPAIGN_AD_AD_SEGMENTKEY = 'CampaignAdSettings delete ad segment key';
export const SAVE_CAMPAIGN_AD_DATA = 'CampaignAdSettings save ad data';
export const SAVE_CAMPAIGN_AD_DATA_SUCCESS = 'CampaignAdSettings save ad data success';
export const SAVE_CAMPAIGN_AD_DATA_FAIL = 'CampaignAdSettings save ad data fail';

export const EDIT_CAMPAIGN_AD_AD_SEGMENTKEY = 'CampaignAdSettings edit ad segment key';

export const SET_CAMPAIGN_AD_FORM_DIRTY = 'CampaignAdSettings set form dirty';

export const RESET_CAMPAIGN_STATE = 'CampaignAdSettings reset state';


export class SetCampaignAdSettingsDirty implements Action {
  readonly type = SET_CAMPAIGN_AD_FORM_DIRTY;
  constructor(public payload: boolean) {}
}

export class ResetCampaignAdSettingsState implements Action {
  readonly type = RESET_CAMPAIGN_STATE;
  constructor() {}
}


export class GetCampaignAdSettingsCreatives implements Action {
  readonly type = GET_CAMPAIGN_AD_CREATIVES;
  constructor(public payload: string) {}
}

export class GetCampaignAdSettingsCreativesSuccess implements Action {
  readonly type = GET_CAMPAIGN_AD_CREATIVES_SUCCESS;
  constructor(public payload: Creative[]) {}
}

export class GetCampaignAdSettingsCreativesFail implements Action {
  readonly type = GET_CAMPAIGN_AD_CREATIVES_FAIL;
  constructor(public payload?: string) {}
}

export class GetCampaignAdSettingsVariation implements Action {
  readonly type = GET_CAMPAIGN_AD_VARIATION;
  constructor(public payload: CreativePayload) {}
}

export class GetCampaignAdSettingsVariationSuccess implements Action {
  readonly type = GET_CAMPAIGN_AD_VARIATION_SUCCESS;
  constructor(public payload: CreativeVariation) {}
}

export class GetCampaignAdSettingsVariationFail implements Action {
  readonly type = GET_CAMPAIGN_AD_VARIATION_FAIL;
  constructor(public payload?: string) {}
}

export class GetCampaignAdSettingsCampaigns implements Action {
  readonly type = GET_CAMPAIGN_AD_CAMPAIGNS;
  constructor(public payload: CampaignPayload) {}
}

export class GetCampaignAdSettingsCampaignsSuccess implements Action {
  readonly type = GET_CAMPAIGN_AD_CAMPAIGNS_SUCCESS;
  constructor(public payload: Campaign) {}
}

export class GetCampaignAdSettingsCampaignsFail implements Action {
  readonly type = GET_CAMPAIGN_AD_CAMPAIGNS_FAIL;
  constructor(public payload?: string) {}
}

export class GetCampaignAdSettingsAds implements Action {
  readonly type = GET_CAMPAIGN_AD_ADS;
  constructor(public payload: AdPayload) {}
}

export class GetCampaignAdSettingsAdsSuccess implements Action {
  readonly type = GET_CAMPAIGN_AD_ADS_SUCCESS;
  constructor(public payload: Ad) {}
}

export class GetCampaignAdSettingsAdsFail implements Action {
  readonly type = GET_CAMPAIGN_AD_ADS_FAIL;
  constructor(public payload?: string) {}
}


export class GetCampaignAdSettingsFeed implements Action {
  readonly type = GET_CAMPAIGN_AD_FEED;
  constructor(public payload: string) {}
}

export class GetCampaignAdSettingsFeedSuccess implements Action {
  readonly type = GET_CAMPAIGN_AD_FEED_SUCCESS;
  constructor(public payload: FeedDefinition) {}
}

export class GetCampaignAdSettingsFeedFail implements Action {
  readonly type = GET_CAMPAIGN_AD_FEED_FAIL;
  constructor(public payload?: string) {}
}

export class GetCampaignAdSettingsIntegration implements Action {
  readonly type = GET_CAMPAIGN_AD_INTEGRATION;
  constructor(public payload: string) {}
}

export class GetCampaignAdSettingsIntegrationSuccess implements Action {
  readonly type = GET_CAMPAIGN_AD_INTEGRATION_SUCCESS;
  constructor(public payload: IntegrationDefinition[]) {}
}

export class GetCampaignAdSettingsIntegrationFail implements Action {
  readonly type = GET_CAMPAIGN_AD_INTEGRATION_FAIL;
  constructor(public payload?: string) {}
}

export class AddCampaignAdSettingsSegmentKey implements Action {
  readonly type = ADD_CAMPAIGN_AD_SEGMENTKEY;
  constructor() {}
}

export class SetCampaignAdSettingsNewAd implements Action {
  readonly type = SET_CAMPAIGN_AD_NEW_AD;
  constructor(public payload: boolean) {}
}

export class SetCampaignAdSettingsAdWidth implements Action {
  readonly type = SET_CAMPAIGN_AD_AD_WIDTH;
  constructor(public payload: number) {}
}

export class SetCampaignAdSettingsAdHeight implements Action {
  readonly type = SET_CAMPAIGN_AD_AD_HEIGHT;
  constructor(public payload: number) {}
}

export class SetCampaignAdSettingsAdName implements Action {
  readonly type = SET_CAMPAIGN_AD_AD_NAME;
  constructor(public payload: string) {}
}

export class SetCampaignAdSettingsAdSaveData implements Action {
  readonly type = SET_CAMPAIGN_AD_SAVE_DATA;
  constructor(public payload?: Ad) {}
}

export class SetCampaignAdSettingsAdActive implements Action {
  readonly type = SET_CAMPAIGN_AD_AD_ACTIVE;
  constructor(public payload: boolean) {}
}

export class SetCampaignAdSettingsAdFlightStart implements Action {
  readonly type = SET_CAMPAIGN_AD_AD_FLIGHTSTART;
  constructor(public payload: Date) {}
}

export class SetCampaignAdSettingsAdFlightEnd implements Action {
  readonly type = SET_CAMPAIGN_AD_AD_FLIGHTEND;
  constructor(public payload: Date) {}
}

export class SetCampaignAdSettingsAdRotation implements Action {
  readonly type = SET_CAMPAIGN_AD_AD_ROTATION;
  constructor(public payload: number) {}
}

export class SetCampaignAdSettingsAdSegmentKey implements Action {
  readonly type = SET_CAMPAIGN_AD_AD_SEGMENTKEY;
  constructor(public payload: CreativeSegmentKey) {}
}

export class DeleteCampaignAdSettingsAdSegmentKey implements Action {
  readonly type = DELETE_CAMPAIGN_AD_AD_SEGMENTKEY;
  constructor(public payload: CreativeSegmentKey) {}
}

export class EditCampaignAdSettingsAdSegmentKey implements Action {
  readonly type = EDIT_CAMPAIGN_AD_AD_SEGMENTKEY;
  constructor(public original: CreativeSegmentKey, public payload: CreativeSegmentKey) {}
}

export class SetCampaignAdSettingsAdPersistent implements Action {
  readonly type = SET_CAMPAIGN_AD_PERSISTENT;
  constructor(public payload: boolean) {}
}

export class ToggleCampaignAdSettingsAdPersistent implements Action {
  readonly type = TOGGLE_CAMPAIGN_AD_PERSISTENT;
  constructor() {}
}

export class SaveCampaignAdSettingsAdSave implements Action {
  readonly type = SAVE_CAMPAIGN_AD_DATA;
  constructor(public params: AdPayload, public payload: Ad) {}
}

export class SaveCampaignAdSettingsAdSaveSuccess implements Action {
  readonly type = SAVE_CAMPAIGN_AD_DATA_SUCCESS;
  constructor() {}
}

export class SaveCampaignAdSettingsAdSaveFail implements Action {
  readonly type = SAVE_CAMPAIGN_AD_DATA_FAIL;
  constructor(public payload?: string) {}
}

export type All
  = SetCampaignAdSettingsDirty
  | GetCampaignAdSettingsCreatives
  | GetCampaignAdSettingsCreativesSuccess
  | GetCampaignAdSettingsCreativesFail
  | GetCampaignAdSettingsVariation
  | GetCampaignAdSettingsVariationSuccess
  | GetCampaignAdSettingsVariationFail
  | GetCampaignAdSettingsCampaigns
  | GetCampaignAdSettingsCampaignsSuccess
  | GetCampaignAdSettingsCampaignsFail
  | GetCampaignAdSettingsAds
  | GetCampaignAdSettingsAdsSuccess
  | GetCampaignAdSettingsAdsFail
  | GetCampaignAdSettingsFeed
  | GetCampaignAdSettingsFeedSuccess
  | GetCampaignAdSettingsFeedFail
  | GetCampaignAdSettingsIntegration
  | GetCampaignAdSettingsIntegrationSuccess
  | GetCampaignAdSettingsIntegrationFail
  | SetCampaignAdSettingsAdWidth
  | SetCampaignAdSettingsAdHeight
  | SetCampaignAdSettingsAdName
  | SetCampaignAdSettingsAdSaveData
  | SetCampaignAdSettingsAdActive
  | SetCampaignAdSettingsAdFlightStart
  | SetCampaignAdSettingsAdFlightEnd
  | SetCampaignAdSettingsAdRotation
  | SetCampaignAdSettingsAdSegmentKey
  | SetCampaignAdSettingsAdPersistent
  | ToggleCampaignAdSettingsAdPersistent
  | SaveCampaignAdSettingsAdSave
  | SaveCampaignAdSettingsAdSaveSuccess
  | SaveCampaignAdSettingsAdSaveFail
  | ResetCampaignAdSettingsState
  | EditCampaignAdSettingsAdSegmentKey
  | AddCampaignAdSettingsSegmentKey
  | SetCampaignAdSettingsNewAd
  | DeleteCampaignAdSettingsAdSegmentKey;
