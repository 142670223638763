
import {catchError, switchMap, map, mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import {AppState} from '../../state';
import {ManagementQuery} from './management.reducer';
import * as ManagementActions from './management.actions';
import { AdvertiserService } from '@services/advertiser/advertiser.service';
import { AccountGroup, ClaimsPayload, Account } from '../../../interfaces';
import { AccountFacade } from '../../global';
import { ClaimsService } from '@services/claims/claims.service';
import { ResourceClaim } from '../../../interfaces/claim.interface';
import { CampaignService } from '@services/campaign/campaign.service';
import { CreativeService } from '@services/creative/creative.service';
import { NotificationsService } from '@services/notification/notification.service';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { CommonUtilityService } from '@services/utility/common-utility.service';
type Action = ManagementActions.All;

@Injectable()
export class ManagementFacade {
  loadAdWithVariations = false;
  advertiserId = '';
  allRoles = this.utilityService.doDefinitionObject(this.claimsService.roles, 'Value');

  management$ = this.store.select(ManagementQuery.management);


  getAdvertiserGroup$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.GET_MANAGEMENT_GROUP),
    map((action: ManagementActions.GetManagementGroup) => action),
    switchMap(action => {
      return this.advertiserService.getAdvertiserGroup(action.payload).pipe(
      map((group) => {
        return new ManagementActions.GetManagementGroupSuccess(group.data);
      }),
      catchError(err => {
        return of (new ManagementActions.GetManagementGroupFail(err));
      } ), );
    }), ));


  getAdvertiser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.GET_MANAGEMENT_ADVERTISER),
    map((action: ManagementActions.GetManagementAdvertiser) => action),
    switchMap(action => {
      return this.advertiserService.getAdvertiser(action.payload).pipe(
      map((group) => {
       return new ManagementActions.GetManagementAdvertiserSuccess(group.data);
      }),
      catchError(err => {
        return of (new ManagementActions.GetManagementAdvertiserFail(err));
      } ), );
    }), ));


  getAdvertiserCampaigns$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.GET_MANAGEMENT_ADVERTISER_CAMPAIGNS),
    map((action: ManagementActions.GetManagementAdvertiserCampaign) => action),
    switchMap(action => {
      return this.campaignService.getCampaigns(action.payload).pipe(
      map((campaigns) => {
        return new ManagementActions.GetManagementAdvertiserCampaignSuccess(campaigns);
      }),
      catchError(err => {
        return of (new ManagementActions.GetManagementAdvertiserCampaignFail(err));
      } ), );
    }), ));


    getAdvertiserCreatives$: Observable<Action> = createEffect(() => this.actions$.pipe(
      ofType(ManagementActions.GET_MANAGEMENT_ADVERTISER_CAMPAIGNS),
      map((action: ManagementActions.GetManagementAdvertiserCreatives) => action),
      switchMap(action => {
        return this.creativeService.getIndirectCreatives(action.payload).pipe(
        map((creatives) => {
          return new ManagementActions.GetManagementAdvertiserCreativesSuccess(creatives);
        }),
        catchError(err => {
          return of (new ManagementActions.GetManagementAdvertiserCreativesFail(err));
        }), );
      }), ));


  getAdvertiserGroupClaims$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.GET_MANAGEMENT_CLAIMS),
    map((action: ManagementActions.GetManagementClaims) => action),
    switchMap(action => {
      return this.claimsService.getGroupClaims(action.payload, true).pipe(
      map((claims) => {
        claims.reduce((memo: ResourceClaim[], res) => {
            memo.push(this.formatGroupUserRow(res));
          return memo;
        }, []);
       return new ManagementActions.GetManagementClaimsSuccess(claims);
      }),
      catchError(err => {
        return of (new ManagementActions.GetManagementClaimsFail(err));
      } ), );
    }), ));


  getAdvertiserParentGroupClaims$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.GET_MANAGEMENT_PARENT_GROUP_CLAIMS),
    map((action: ManagementActions.GetManagementParentGroupClaims) => action),
    switchMap(action => {
      return this.claimsService.getGroupClaims(action.payload, true).pipe(
      map((claims: ResourceClaim[]) => {
        if (claims) {
          claims.reduce((memo: ResourceClaim[], res: ResourceClaim) => {
            memo.push(this.formatGroupUserRow(res));
            return memo;
          }, []);
        }
        return new ManagementActions.GetManagementParentGroupClaimsSuccess(claims);
      }),
      catchError(err => {
        return of (new ManagementActions.GetManagementParentGroupClaimsFail(err));
      } ), );
    }), ));


  getAdvertiserSubGroupClaims$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.GET_MANAGEMENT_SUB_GROUP_CLAIMS),
    map((action: ManagementActions.GetManagementSubGroupClaims) => action),
    mergeMap(action => {
      return this.claimsService.getGroupClaims(action.payload, true).pipe(
      map((claims: ResourceClaim[]) => {
        claims.reduce((memo: ResourceClaim[], res) => {
          memo.push(this.formatGroupUserRow(res));
          return memo;
        }, []);
       return new ManagementActions.GetManagementSubGroupClaimsSuccess(claims);
      }),
      catchError(err => {
        return of (new ManagementActions.GetManagementSubGroupClaimsFail(err));
      }), );
    }), ));


  getAdvertiserClaims$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.GET_MANAGEMENT_ADVERTISER_CLAIMS),
    map((action: ManagementActions.GetManagementAdvertiserClaims) => action),
    switchMap(action => {
      let request;
      if (action.payload.advertiserId) {
        request = this.claimsService.getAdvertiserClaims(action.payload.groupId, true);
      } else {
        request = this.claimsService.getUserClaims();
      }
      return request.pipe(map((claims) => {
        claims.reduce((memo: ResourceClaim[], res) => {
            memo.push(this.formatGroupUserRow(res));
          return memo;
        }, []);
       return new ManagementActions.GetManagementAdvertiserClaimsSuccess(claims);
      }),
      catchError(err => {
        return of (new ManagementActions.GetManagementAdvertiserClaimsFail(err));
      } ), );
    }), ));


  saveAdvertiserGroup$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.SAVE_MANAGEMENT_GROUP),
    map((action: ManagementActions.SaveManagementGroup) => action),
    switchMap(action => {
      return this.advertiserService.editAdvertiserGroup(action.groupId, action.payload).pipe(
      map(() => {
        this.store.dispatch(new ManagementActions.GetManagementGroup(action.groupId));
      }),
      map(() => {
        this.notificationsService.notify({
          severity: 'success',
          detail: 'Group details saved successfuly.',
          summary: 'Group details saved.',
          key: 'non-sticky'
        });
        this.setFormIsClean();
       return new ManagementActions.SaveManagementGroupSuccess();
      }),
      catchError(err => {
        return of (new ManagementActions.SaveManagementGroupFail(err));
      } ), );
    }), ));


  saveAdvertiserClaim$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.SAVE_MANAGEMENT_CLAIMS),
    map((action: ManagementActions.SaveManagementClaim) => action),
    switchMap(action => {
      return this.claimsService.editGroupClaims(action.groupId, action.payload).pipe(map(() => {
        this.store.dispatch(new ManagementActions.GetManagementClaims(action.groupId));
      }),
      map(() => {
        this.notificationsService.notify({
          severity: 'success',
          detail: 'User access updated successfuly.',
          summary: 'User access updated.',
          key: 'non-sticky'
        });
        this.setFormIsClean();
        return new ManagementActions.SaveManagementClaimSuccess();
      }),
      catchError(err => {
        return of (new ManagementActions.SaveManagementClaimFail(err));
      }), );
    }), ));


  saveManagementAdvertiserClaim$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.SAVE_MANAGEMENT_ADVERTISER_CLAIMS),
    map((action: ManagementActions.SaveManagementAdvertiserClaim) => action),
    switchMap(action => {
      return this.claimsService.editAdvertiserClaims(action.groupId, action.id, action.payload).pipe(map(() => {
        this.store.dispatch(new ManagementActions.GetManagementAdvertiserClaims({groupId: action.groupId, advertiserId: action.id}));
      }),
      map(() => {
        this.notificationsService.notify({
          severity: 'success',
          detail: 'User access updated successfuly.',
          summary: 'User access updated.',
          key: 'non-sticky'
        });
        this.setFormIsClean();
       return new ManagementActions.SaveManagementAdvertiserClaimSuccess();
      }),
      catchError(err => {
        return of (new ManagementActions.SaveManagementAdvertiserClaimFail(err));
      } ), );
    }), ));


  deleteAdvertiserGroup$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.DELETE_MANAGEMENT_GROUP),
    map((action: ManagementActions.DeleteManagementGroup) => action),
    switchMap(action => {
      return this.advertiserService.deleteAdvertiserGroup(action.groupId).pipe(map(() => {
        this.accountFacade.initState({advertiserId: '', groupId: ''});
      }),
      map(() => {
        this.notificationsService.notify({
          severity: 'success',
          detail: 'Group was deleted successfuly.',
          summary: 'Group deleted.',
          key: 'non-sticky'
        });
        this.setFormIsClean();
        return new ManagementActions.DeleteManagementGroupSuccess();
      }),
      catchError(err => {
        return of (new ManagementActions.DeleteManagementGroupFail(err));
      }), );
    }), ));


  deleteAdvertiser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.DELETE_MANAGEMENT_ADVERTISER),
    map((action: ManagementActions.DeleteManagementAdvertiser) => action),
    switchMap(action => {
      this.advertiserService.setActiveAdvertiser(<Account>{});
      this.accountFacade.setDeletedAdvertiser(action.payload);
      return this.advertiserService.deleteAdvertiser(action.payload).pipe(map(() => {
        this.accountFacade.initState({advertiserId: '', groupId: ''});
      }),
      map(() => {
        this.notificationsService.notify({
          severity: 'success',
          detail: 'Account was deleted successfuly.',
          summary: 'Account deleted.',
          key: 'non-sticky'
        });
        this.setFormIsClean();
        return new ManagementActions.DeleteManagementAdvertiserSuccess();
      }),
      catchError(err => {
        return of (new ManagementActions.DeleteManagementAdvertiserFail(err));
      }), );
    }), ));


  deleteAdvertiserGroupClaim$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.DELETE_MANAGEMENT_CLAIMS),
    map((action: ManagementActions.DeleteManagementClaim) => action),
    switchMap(action => {
      return this.claimsService.deleteGroupClaims(action.payload).pipe(map(() => {
        this.store.dispatch(new ManagementActions.GetManagementClaims(action.payload.groupId));
      }),
      map(() => {
        this.notificationsService.notify({
          severity: 'success',
          detail: 'User access revoked successfuly.',
          summary: 'User access revoked.',
          key: 'non-sticky'
        });
        this.setFormIsClean();
        return new ManagementActions.DeleteManagementClaimSuccess();
      }),
      catchError(err => {
        return of (new ManagementActions.DeleteManagementClaimFail(err));
      } ), );
    }), ));


  deleteAdvertiserUserClaim$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.DELETE_MANAGEMENT_ADVERTISER_CLAIMS),
    map((action: ManagementActions.DeleteManagementAdvertiserClaim) => action),
    switchMap(action => {
      return this.claimsService.deleteAdvertiserClaims(action.payload).pipe(map(() => {
        this.store.dispatch(new ManagementActions.GetManagementAdvertiserClaims(action.payload));
      }),
      map(() => {
        this.notificationsService.notify({
          severity: 'success',
          detail: 'User access revoked successfuly.',
          summary: 'User access revoked.',
          key: 'non-sticky'
        });
        this.setFormIsClean();
       return new ManagementActions.DeleteManagementAdvertiserClaimSuccess();
      }),
      catchError(err => {
        return of (new ManagementActions.DeleteManagementAdvertiserClaimFail(err));
      } ), );
    }), ));


  saveAdvertiserSubGroup$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.SAVE_MANAGEMENT_SUBGROUP),
    map((action: ManagementActions.SaveManagementSubGroup) => action),
    switchMap(action => {
      return this.advertiserService.addAdvertiserGroup(action.payload).pipe(map(() => {
        this.accountFacade.initState({advertiserId: '', groupId: ''});
      }),
      map(() => {
        this.notificationsService.notify({
          severity: 'success',
          detail: 'Child group created successfuly.',
          summary: 'Child group created.',
          key: 'non-sticky'
        });
        this.setFormIsClean();
        return new ManagementActions.SaveManagementSubGroupSuccess();
      }),
      catchError(err => {
        return of (new ManagementActions.SaveManagementSubGroupFail(err));
      }), );
    }), ));


  saveAdvertiser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ManagementActions.SAVE_MANAGEMENT_ADVERTISER),
    map((action: ManagementActions.SaveManagementAdvertiser) => action),
    switchMap(action => {
      if (action.payload.CreatedOn && action.payload.AccountId) {
        return this.advertiserService.editIndirectAdvertiser(action.payload).pipe(map(() => {
          this.store.dispatch(new ManagementActions.GetManagementAdvertiser(action.payload.AccountId));
        }),
        map(() => {
          this.notificationsService.notify({
            severity: 'success',
            detail: 'Account details saved successfuly.',
            summary: 'Account details saved.',
            key: 'non-sticky'
          });
          this.setFormIsClean();
          return new ManagementActions.SaveManagementAdvertiserSuccess();
        }),
        catchError(err => {
          return of (new ManagementActions.SaveManagementAdvertiserFail(err));
        } ), );
      } else {
        return this.advertiserService.addAdvertiser(action.payload).pipe(map(() => {
          this.accountFacade.initState({advertiserId: '', groupId: ''});
        }),
        map(() => {
          this.notificationsService.notify({
            severity: 'success',
            detail: 'Account details saved successfuly.',
            summary: 'Account details saved.',
            key: 'non-sticky'
          });
          return new ManagementActions.SaveManagementAdvertiserSuccess();
        }),
        catchError(err => {
          return of (new ManagementActions.SaveManagementAdvertiserFail(err));
        } ), );
      }
    }), ));

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private utilityService: CommonUtilityService,
    private notificationsService: NotificationsService,
    private advertiserService: AdvertiserService,
    private claimsService: ClaimsService,
    private campaignService: CampaignService,
    private creativeService: CreativeService,
    private accountFacade: AccountFacade
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.advertiserService.apiClient = this.apiClient;
    this.campaignService.apiClient = this.apiClient;
    this.claimsService.apiClient = this.apiClient;
    this.creativeService.apiClient = this.apiClient;
  }

  setFormIsDirty(): void {
    this.store.dispatch(new ManagementActions.SetManagementDirty(true));
  }

  setFormIsClean(): void {
    this.store.dispatch(new ManagementActions.SetManagementDirty(false));
  }

  resetManagementState(): void {
    this.store.dispatch(new ManagementActions.ResetManagementState());
  }

  getAdvertiserGroup(payload: string): void {
    this.store.dispatch(new ManagementActions.GetManagementGroup(payload));
  }

  getAdvertiser(payload: string): void {
    this.store.dispatch(new ManagementActions.GetManagementAdvertiser(payload));
  }

  getAdvertiserCampaigns(payload: string): void {
    this.store.dispatch(new ManagementActions.GetManagementAdvertiserCampaign(payload));
  }

  getAdvertiserCreatives(payload: string): void {
    this.store.dispatch(new ManagementActions.GetManagementAdvertiserCreatives(payload));
  }

  getAdvertiserGroupClaims(payload: string): void {
    this.store.dispatch(new ManagementActions.GetManagementClaims(payload));
  }

  getAdvertiserParentGroupClaims(payload: string): void {
    this.store.dispatch(new ManagementActions.GetManagementParentGroupClaims(payload));
  }

  getAdvertiserSubGroupClaims(payload: string): void {
    if (payload) {
      this.store.dispatch(new ManagementActions.GetManagementSubGroupClaims(payload));
    }
  }

  getAdvertiserClaims(payload: ClaimsPayload): void {
    this.store.dispatch(new ManagementActions.GetManagementAdvertiserClaims(payload));
  }

  saveAdvertiserGroup(groupId: string, payload: AccountGroup): void {
    this.store.dispatch(new ManagementActions.SaveManagementGroup(groupId, payload));
  }

  saveAdvertiserClaim(groupId: string, payload: ResourceClaim): void {
    this.store.dispatch(new ManagementActions.SaveManagementClaim(groupId, payload));
  }

  saveManagementAdvertiserClaim(groupId: string, id: string, payload: ResourceClaim): void {
    this.store.dispatch(new ManagementActions.SaveManagementAdvertiserClaim(groupId, id, payload));
  }

  saveAdvertiserSubGroup(payload: AccountGroup): void {
    this.store.dispatch(new ManagementActions.SaveManagementSubGroup(payload));
  }

  deleteAdvertiserGroup(payload: string): void {
    this.store.dispatch(new ManagementActions.DeleteManagementGroup(payload));
  }

  deleteAdvertiser(payload: string): void {
    this.store.dispatch(new ManagementActions.DeleteManagementAdvertiser(payload));
  }

  deleteAdvertiserGroupClaim(payload: ClaimsPayload): void {
    this.store.dispatch(new ManagementActions.DeleteManagementClaim(payload));
  }

  deleteAdvertiserUserClaim(payload: ClaimsPayload): void {
    this.store.dispatch(new ManagementActions.DeleteManagementAdvertiserClaim(payload));
  }

  saveAdvertiser(payload: Account): void {
    this.store.dispatch(new ManagementActions.SaveManagementAdvertiser(payload));
  }

  formatGroupUserRow(row: ResourceClaim) {
    row.RoleData = this.allRoles[row.Roles as number];
    return row;
  }
}
