import { AppState } from '../state';
import * as IntegrationsActions from './integrations.actions';
import { IntegrationsState } from './integrations.model';
import { IntegrationDefinition } from '../../interfaces';

export type Action = IntegrationsActions.All;

export namespace IntegrationsQuery {
  export const integrations = (state: AppState) => state.integrations;
}

export function integrationsReducer(state: IntegrationsState, action: Action): IntegrationsState {
  switch (action.type) {
  case IntegrationsActions.INTEGRATIONS_GETALL:
    return { ...state, status: action.type};
  case IntegrationsActions.INTEGRATIONS_GETALL_SUCCESS:
    return { ...state, integrations: action.payload, status: action.type};
  case IntegrationsActions.INTEGRATIONS_GETALL_FAILED:
    return { ...state, integrations: [], status: action.type};
  case IntegrationsActions.INTEGRATIONS_GET:
    return { ...state, status: action.type};
  case IntegrationsActions.INTEGRATIONS_GET_SUCCESS:
    return { ...state, integration: action.payload, status: action.type};
  case IntegrationsActions.INTEGRATIONS_GET_FAILED:
    return { ...state, integration: <IntegrationDefinition>{}, status: action.type};
  case IntegrationsActions.INTEGRATIONS_CLEARED:
    return { ...state, integration: <IntegrationDefinition>{}};
  case IntegrationsActions.INTEGRATIONS_UPDATE_GLOBAL:
    return { ...state, status: action.type};
  default:
    return state;
  }
}
