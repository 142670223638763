import { Injectable } from '@angular/core';
import { cloneDeep, filter, find, includes, orderBy } from 'lodash-es';
import { SubscriptionLike as ISubscription, Subject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AccountsAPIResponse, Account, AccountGroup } from '@shared/interfaces';
import { AdvertiserService } from '@services/advertiser/advertiser.service';
import { UtilityService } from '@services/utility';
import { AccountFacade, AccountState } from '@shared/state/global';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';

@Injectable({providedIn: 'root'})
export class DynamicDatabase {
  state = <AccountState>{};
  private accountSubscription: ISubscription|undefined;
  selectedGroup: AccountGroup | null = <AccountGroup>{};
  allGroups: AccountGroup[] = [];
  advertisers: Account[] = [];
  loadedGroup = '';
  allAdvertisers: Account[] = [];
  selectedAdvertiser = <Account>{};
  groupChildren = new Map<string, (AccountGroup | Account)[]>();
  showInactive = false;
  keyword = '';
  searchStack: string[] = [];
  lastSearchWord = '';
  advertisersResult: Account[] = [];
  groupResult: AccountGroup[] = [];
  searchMode = false;
  private outputData = new Subject<(AccountGroup | Account)[]>();
  dataChange = this.outputData.asObservable();
  lastSearchResult: (AccountGroup | Account)[] = [];
  groupId = '';
  onlyGroups = false;
  allowedAdvertisers: Account[] = [];

  constructor(
  private advertiserService: AdvertiserService,
  private utilityService: UtilityService,
  private accountFacade: AccountFacade,
  private apiClient: ApiService,
  private errorHandler: ErrorHandlerService, ) {
    apiClient.errorHandler = this.errorHandler;
    this.advertiserService.apiClient = this.apiClient;
    this.startSubscription();
  }

  private startSubscription() {
    this.accountSubscription = this.accountFacade.account$.subscribe(state => {
      this.state = state;
      if (this.state && this.state.allowedAdvertisers) {
        this.allowedAdvertisers = this.state.allowedAdvertisers;
      }
      if (!this.searchMode) {
        this.setTopLevelGroup();
      } else {
        this.prepareSearchData();
        this.searchFromStack();
      }
    });
  }

  /** Initial data from database */
  initialData(showInactive = false, groupId = '', onlyGroups = false) {
    if (this.allGroups && this.allGroups.length > 0) {
      const groups = this.parseGroupData(showInactive, groupId, onlyGroups);
      return groups.map(item => {
        return {AdvertiserName: item.Name, AccountId: item.GroupId, isGroup: true, loading: false, expandable: true, ...item};
      });
    } else if (this.allowedAdvertisers && this.allowedAdvertisers.length > 0) {
      const advertisers = this.parseAdvertiserData(showInactive);
      return advertisers.map(item => {
        return { isGroup: false, loading: false, expandable: false, ...item};
      });
    }

    return [];
  }

  private parseGroupData(showInactive: boolean, groupId = '', onlyGroups = false) {
    this.showInactive = showInactive;
    this.groupId = groupId;
    this.onlyGroups = onlyGroups;
    let groups = this.allGroups;
    if (!showInactive) {
      groups = filter(this.allGroups, { IsActive: true });
    }
    if (groupId) {
      groups = filter(groups, {GroupId: groupId});
    }
    groups = orderBy(groups, [group => group.Name.toLowerCase()], ['asc']);
    return groups;
  }

  private parseAdvertiserData(showInactive: boolean) {
    this.showInactive = showInactive;
    let advertisers = this.allowedAdvertisers;
    if (!showInactive) {
      advertisers = filter(this.allowedAdvertisers, { IsActive: true });
    }

    advertisers = orderBy(advertisers, [account => account.Name.toLowerCase()], ['asc']);
    return advertisers;
  }

  disconnect() {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
  }

  setTopLevelGroup(): void {
    if (this.state && this.state.advertiserGroups) {
      if (
        this.state.activeSelection.Type === 'Advertiser Group' ||
        !this.state.activeSelection.Type
      ) {
        const group = find(this.state.advertiserGroups, {
          GroupId: this.state.activeAdvertiserGroup.GroupId
        });
        if (!group && this.state.advertiserGroups[0]) {
          this.accountFacade.setActiveGroup(this.state.advertiserGroups[0]);
        }
      }
      this.getRootGroups(this.state.advertiserGroups);
    }
  }

   async getSubGroups(group: AccountGroup) {
    this.selectedGroup = group;
    if (this.selectedGroup && this.selectedGroup.GroupId) {
      const children = this.utilityService.getSubGroups(
        this.state.advertiserGroups,
        this.selectedGroup
      );
      let advertisers: Observable<AccountsAPIResponse>;
      advertisers = await this.getAdvertiserByGroup(this.selectedGroup.GroupId);
      let data: Account[];
      data = await advertisers.pipe(first()).toPromise().then(adv => {
        return adv?.data || [];
      });
      this.accountFacade.setAdvertisers(cloneDeep(data));

      return {groups: children, advertisers: data, showInactive: this.showInactive};
    }

    return null;
  }

  async getAdvertiserByGroup(groupId: string) {
    return this.advertiserService.getAdvertisersByGroup(groupId);
  }

  getRootGroups(groups: AccountGroup[]): void {
    const topGroups = filter(groups, { ParentHierarchyPath: '' }) || [];
    this.advertisers = [];
    this.allGroups = topGroups;
  }

  search(keyword: string) {
    if (this.accountSubscription && this.accountSubscription.closed) {
      this.startSubscription();
    }
    if (this.lastSearchResult) {
      this.LoadNodeData(this.lastSearchResult);
    }
    this.keyword = keyword;
    if (this.keyword.length > 2) {
      if (this.state && this.state.searchLoading) {
        if (!includes(this.searchStack, this.keyword)) {
          this.searchStack.push(this.keyword);
          this.lastSearchWord = this.keyword;
        }
      } else if (this.lastSearchWord !== this.keyword) {
        this.lastSearchWord = this.keyword;
        this.accountFacade.search(this.keyword);
      }
      this.searchMode = true;
    } else {
      this.searchMode = false;
    }
  }

  searchFromStack() {
    if (this.state && !this.state.searchLoading) {
      if (this.searchStack.length > 0) {
        const query = this.searchStack.pop() as string;
        this.searchStack = [];
        if (query !== this.lastSearchWord) {
          this.lastSearchWord = query;
          this.accountFacade.search(query);
        }
      }
    }
  }

  prepareSearchData() {
    if (this.state && this.state.searchResult) {
      this.advertisersResult = [];
      this.groupResult = [];
      this.filterNavItems();
    }
  }

  filterNavItems() {
    this.filterNavWithoutSearch();
    this.filterNavSearchResult();
    this.groupResult = orderBy(this.groupResult,
      [group => group.Name.toLowerCase()], ['asc']);
    this.groupResult = this.groupResult.map(item => {
      return {AdvertiserName: item.Name, AccountId: item.GroupId, isGroup: true, isLoading: false, expandable: false, ...item}
    });
    this.advertisersResult = orderBy(this.advertisersResult,
      [account => account.Name.toLowerCase()], ['asc']);
    this.LoadNodeData([...this.groupResult, ...this.advertisersResult]);
  }

  filterNavWithoutSearch() {
    if (!this.searchMode) {
      let advertisers: Account[] = [];
      if (this.showInactive) {
        advertisers = this.allAdvertisers;
      } else {
        advertisers = filter(this.allAdvertisers, { IsActive: true });
      }
      this.prepareAdvertisersData(advertisers);
    }
  }
  filterNavSearchResult() {
    if (this.searchMode && this.state.searchResult) {
      let advertisers: Account[] = [];
      const groups = this.state.searchResult.AdvertiserGroups;
      if (this.showInactive) {
        advertisers = this.state.searchResult.Advertisers;
      } else {
        advertisers = filter(this.state.searchResult.Advertisers, {
          IsActive: true
        });
      }
      this.prepareAdvertisersSearchData(advertisers);
      this.prepareGroupSearchData(groups);
    }
  }

  prepareAdvertisersData(advertisers: Account[]) {
    this.advertisers = advertisers;
  }

  prepareAdvertisersSearchData(advertisers: Account[]) {
    this.advertisersResult = advertisers;
  }

  prepareGroupSearchData(groups: AccountGroup[]) {
    this.groupResult = groups;
  }

  isExpandable(node: AccountGroup|Account): boolean {
    return (node as AccountGroup).GroupId ? true : false;
  }

  private LoadNodeData(items: (AccountGroup | Account)[]) {
    this.lastSearchResult = items;
    let groups = items;
    if (!this.showInactive) {
      groups = filter(items, { IsActive: true });
    }
    this.outputData.next(groups);
  }
}
