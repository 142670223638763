import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormGroup,
} from '@angular/forms';
import { Validator } from '../../../../../shared/interfaces/field.interface';
import { OnTouchFunction, OnChangeFunction } from '../types';

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => InputFormElementComponent),
  multi: true,
};
@Component({
  selector: 'app-form-input',
  template: `
    <div *ngIf="group" [formGroup]="group">
      <mat-form-field class="example-full-width" [floatLabel]="float">
        <input
          matInput
          #appFormInput
          [autocomplete]="autocomplete"
          [id]="id ? id + '-field' : ''"
          [(ngModel)]="value"
          [ngClass]="conditionalClass"
          [readonly]="readonly"
          [formControlName]="name"
          [value]="innerValue"
          [ngStyle]="cssStyle"
          [placeholder]="placeholder"
          [type]="type === 'password' ? (hide ? 'password' : 'text') : type"
          [disabled]="disabled"
          [maxlength]="maxlength"
          onwheel="this.blur()"
          (click)="setTouched(name)"
          (keyup)="handleTextChange()"
          (keydown.enter)="handleEnterButton($event)"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="handleButtonClick($event)"
          *ngIf="type === 'password'"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <ng-container
          *ngFor="let validation of validations"
          ngProjectAs="mat-error"
        >
          <mat-error *ngIf="group.get(name)?.hasError(validation.name)">{{
            validation.message
          }}</mat-error>
        </ng-container>
      </mat-form-field>
    </div>
    <div *ngIf="!group">
      <mat-form-field class="example-full-width" [floatLabel]="float">
        <input
          matInput
          #appFormInput
          autofocus
          [autocomplete]="autocomplete"
          [id]="id ? id + '-field' : ''"
          [(ngModel)]="value"
          [ngClass]="conditionalClass"
          [readonly]="readonly"
          [value]="innerValue"
          [ngStyle]="cssStyle"
          [placeholder]="placeholder"
          onwheel="this.blur()"
          [type]="type === 'password' ? (hide ? 'password' : 'text') : type"
          [disabled]="disabled"
          [maxlength]="maxlength"
          (keyup)="handleTextChange()"
          (keydown.enter)="handleEnterButton($event)"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="handleButtonClick($event)"
          *ngIf="type === 'password'"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['./input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class InputFormElementComponent implements ControlValueAccessor {
  @Input() placeholder = '';
  @Input() name = '';
  @Input() group: UntypedFormGroup|undefined;
  @Input() type = 'text';
  @Input() disabled = false;
  @Input() filter = false;
  @Input() autoWidth = false;
  @Input() float = 'auto';
  @Input() readonly = false;
  @Input() validations: Validator[] = [];
  @Input() id = '';
  @Input() cssStyle = '';
  @Input() conditionalClass = {};
  innerValue = '';
  @Input() autocomplete = 'on';
  @Input() maxlength: number|undefined;
  hide = true;
  @ViewChild('appFormInput') inputElement: ElementRef|undefined;
  @Output() onTextChange = new EventEmitter<string>();
  @Output() onEnterButton = new EventEmitter<boolean>();
  private onTouchedCallback: OnTouchFunction = noop;
  private onChangeCallback: OnChangeFunction<string> = noop;
  private _autoFocus = false;

  constructor() {
  }

  get value(): string {
    return this.innerValue;
  }

  @Input() set value(v: string) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  get autoFocus(): boolean {
    return this._autoFocus;
  }

  @Input() set autoFocus(v: boolean) {
    this._autoFocus = v;
    if (v) {
      setTimeout(() => {
        this.inputElement?.nativeElement?.focus();
      }, 0);
    }
  }

  changed(e: string) {
    this.onBlur();
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: string) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: OnChangeFunction<string>) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: OnTouchFunction) {
    this.onTouchedCallback = fn;
  }

  setTouched(name: string) {
    this.group?.get(name)?.markAsTouched();
  }

  handleTextChange() {
    if (this.onTextChange) {
      this.onTextChange.emit(this.value);
    }
  }

  handleButtonClick($event: Event) {
    this.hide = !this.hide; 
    $event.preventDefault();
  }

  handleEnterButton($event: Event) {
    if (this.type === 'password') {
      $event.preventDefault();
    }

    this.onEnterButton.emit(true)
  }
}
