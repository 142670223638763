import { Injectable } from '@angular/core';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { ConversionPayload } from '../../shared/interfaces';
import { Observable } from 'rxjs';
import { ConversionRule } from '../../shared/interfaces/conversion.interface';

@Injectable()
export class ConversionService {
  public apiClient = <ApiClient>{};

  type = [
    { label: 'Path Filter', value: 1 },
    { label: 'Time Filter', value: 2 },
    { label: 'Action Filter', value: 3 }
  ];
  sessionType = [
    { label: 'Once Per Session', value: 0 },
    { label: 'Many Per Session', value: 1 }
  ];

  pfrSnippet = '\
    <script>\r\
    (function(){\r\
    window.__xlSiteId = "{id}";\r\
    var scr = document.createElement("script");\r\
    scr.setAttribute("async", "true");\r\
    scr.type = "text/javascript";\r\
    scr.src = "{host}/js/" + window.__xlSiteId +  "/s.min.js";\r\
    ((document.getElementsByTagName("body") || [null])[0] || document.getElementsByTagName("script")[0].parentNode).appendChild(scr);\r\
    })();\r\
    </script>';

  constructor() { }

  getConversions(id: string): Observable<ConversionRule[]> {
    return this.apiClient.callHttpGet({path: `/${id}/conversionrules`});
  }

  getConversionRule(id: string, ruleId: string): Observable<ConversionRule> {
    return this.apiClient.callHttpGet({path: `/${id}/conversionrules/${ruleId}`});
  }

  addConversion(id: string, payload: ConversionPayload): Observable<ConversionRule> {
    return this.apiClient.callHttpPost({path: `/${id}/conversionrules`, param: payload});
  }

  editConversion(id: string, ruleId: string, payload: ConversionPayload): Observable<void> {
    return this.apiClient.callHttpPut({path: `/${id}/conversionrules/${ruleId}`, param: payload});
  }

  deleteConversion(id: string, ruleId: string): Observable<void> {
    return this.apiClient.callHttpDelete({path: `/${id}/conversionrules/${ruleId}`});
  }
}
