import { Injectable } from '@angular/core';
import { ModuleConfig } from '../../interfaces/common.interface';
import { ModuleConfigDataService } from './module-config.data.service';
import { EntityCollectionServiceFactory } from '@ngrx/data';
import { EntityMap } from '../entity/entity-metadata';
import { AdvertiserPayload } from '../../interfaces';

@Injectable()
export class ModuleConfigEffects {
  moduleConfigService: any;

  constructor(
    private moduleConfigDataService: ModuleConfigDataService,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
  ) {
    this.moduleConfigService = this.entityCollectionServiceFactory.create<ModuleConfig>(EntityMap.ModuleConfig);
  }

  getModuleConfig(accessLevel: AdvertiserPayload) {
    this.moduleConfigDataService.payload = accessLevel;
    this.moduleConfigService.load();
  }
}
