import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormGroup,
} from '@angular/forms';
import { Validator } from '../../../../../shared/interfaces/field.interface';
import { OnTouchFunction, OnChangeFunction } from '../types';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { DropdownOption } from '../../../../../shared/interfaces';
import { MatChipInputEvent } from '@angular/material/chips';
import { options } from 'numeral';

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => ChipFormElementComponent),
  multi: true,
};
@Component({
  selector: 'app-form-chips',
  template: `
    <div *ngIf="group" [formGroup]="group">
      <mat-form-field class="example-chip-list" [floatLabel]="float">
        <mat-chip-list #chipList [formControlName]="name">
          <mat-chip *ngFor="let option of options"
                  [removable]="true" (removed)="remove(option)">
            {{option.label}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input [placeholder]="placeholder"
          [id]="id"
          [disabled]="disabled"
          [ngStyle]="cssStyle"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="false"
          (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
        <ng-container
          *ngFor="let validation of validations"
          ngProjectAs="mat-error"
        >
          <mat-error *ngIf="group.get(name)?.hasError(validation.name)">{{
            validation.message
          }}</mat-error>
        </ng-container>
      </mat-form-field>
    </div>
    <div *ngIf="!group">
     <mat-form-field class="example-chip-list" [floatLabel]="float">
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let option of options"
                  [removable]="true" (removed)="remove(option)">
            {{option.label}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input [placeholder]="placeholder"
          [id]="id"
          [disabled]="disabled"
          [ngStyle]="cssStyle"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="false"
          (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['./chip.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class ChipFormElementComponent implements ControlValueAccessor {
  @Input() placeholder = '';
  @Input() name = '';
  @Input() group: UntypedFormGroup|undefined;
  @Input() type = 'text';
  @Input() disabled = false;
  @Input() filter = false;
  @Input() autoWidth = false;
  @Input() float = 'auto';
  @Input() readonly = false;
  @Input() validations: Validator[] = [];
  @Input() id = '';
  @Input() cssStyle = '';
  @Input() conditionalClass = {};
  innerValue = '';
  @Input() autocomplete = 'on';
  @Input() maxlength: number|undefined;
  @Input() options: DropdownOption[] = [];
  @Output() onChange = new EventEmitter<DropdownOption[]>();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  hide = true;
  private onTouchedCallback: OnTouchFunction = noop;
  private onChangeCallback: OnChangeFunction<string> = noop;

  constructor() {
  }

  get value(): string {
    return this.innerValue;
  }

  @Input() set value(v: string) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  changed(e: string) {
    this.onBlur();
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: string) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: OnChangeFunction<string>) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: OnTouchFunction) {
    this.onTouchedCallback = fn;
  }

  setTouched(name: string) {
    this.group?.get(name)?.markAsTouched();
  }

  add(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.options.push({label: value.trim(), value: value.trim()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.onChange.emit(this.options);
  }

  remove(option: DropdownOption): void {
    const index = this.options.indexOf(option);

    if (index >= 0) {
      this.options.splice(index, 1);
    }
    this.onChange.emit(this.options);
  }
}
