import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Tooltip } from '../../shared/interfaces/tooltip.interface';
import { HighchartPoint } from '../../shared/interfaces/highcharts.interface';

@Injectable()
export class ChartUtilityService {

  formatChartNumber(c: HighchartPoint, prefix = '', suffix = '', decimals = 0) {
    return prefix + Highcharts.numberFormat(c.value, decimals, '.', ',') + suffix;
  }

  getTooltipTemplate(pointObject: Tooltip) {
    return '<span style="font-size:20px">' + pointObject.header + '</span><br><span style="font-size:20px">' +
    pointObject.subHeader + '</span><br> ' + pointObject.name + '<br><span style="font-size:30px">' +
    pointObject.value + '</span>'
  }
}
