import { EntitySelectorsFactory } from "@ngrx/data";
import { createSelector } from "@ngrx/store";
import { AdvertiserLocations } from '../../../components/locations/interfaces';
import { EntityMap } from '../entity/entity-metadata';


export const locationsSelectors = new EntitySelectorsFactory().create<AdvertiserLocations>(EntityMap.Locations);

export const selectHasSharedLocations = (accountId: string) => createSelector(
  locationsSelectors.selectEntities,
  (data) => hasSharedLocations(data, accountId)
);



function hasSharedLocations(response: AdvertiserLocations[], accountId: string) {
  let locationChecker = false;
  if (response && response.length > 0) {
    let locations = response;

    let advertiserLocations = locations.filter(
      (location) => location.TransitivAccountId === accountId
    );
    if (advertiserLocations && advertiserLocations.length) {
      let isLocationShared = advertiserLocations.find(
        (location) =>
          location.UseCustomersFromLocations &&
          location.UseCustomersFromLocations.length
      );
      if (isLocationShared) {
        locationChecker = true;
      }
    }
    let externalLocationIdIndex = locations.findIndex(
      (location) => location.TransitivAccountId === accountId
    );
    if (externalLocationIdIndex > -1) {
      let externalLocationId =
        locations[externalLocationIdIndex].ExternalLocationId;
      let modifiedLocations = locations;
      modifiedLocations.splice(externalLocationIdIndex, 1);
      let isLocationBeingShared = false;
      modifiedLocations.forEach((location) => {
        let locationBeingSharedIndex = location.UseCustomersFromLocations.indexOf(
          externalLocationId
        );
        if (locationBeingSharedIndex > -1) {
          isLocationBeingShared = true;
        }
      });
      if (isLocationBeingShared) {
        locationChecker = true;
      }
    }
  }

  return locationChecker;
}
