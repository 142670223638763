import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Ad, AdPayload, AdScreenshotPayload } from '../../shared/interfaces';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { AdScreenshotObject } from '../../shared/interfaces/AdScreenshotObject';
import { DateUtilityService } from '../utility';


@Injectable()
export class AdService {
  public apiClient = <ApiClient>{};

  constructor(private dateUtility: DateUtilityService, ) {
  }

    /**
    * @ngdoc method
    * @name getAds
    * @methodOf app.home.adService
    * @description getAds
    *
    */
  getAds(advertiserId: string, tranCampaignId: string): Observable<Ad[]> {
    return this.apiClient.callHttpGet({path: `/${advertiserId}/${tranCampaignId}/ads`});
  }

  getAdScreenshot(advertiserId: string, payload: AdScreenshotPayload): Observable<AdScreenshotObject[]> {
    return this.apiClient.callHttpGet({
      path: `/${advertiserId}/ads/metadata/screenshots/${payload.year}/${payload.month}/`
      + `${payload.day}/${payload.snapshotType}`});
  }

  getAdScreenshotImage(advertiserId: string, datePayload: AdScreenshotPayload,
    payload: AdScreenshotObject): Observable<AdScreenshotObject> {
    return this.apiClient.callHttpPost({ param: payload,
      path: `/${advertiserId}/ads/metadata/screenshots/${datePayload.year}/${datePayload.month}/`
      + `${datePayload.day}/${datePayload.snapshotType}`});
  }

  getAdScreenshotDate(advertiserId: string, snapshotType: number): Observable<string[]> {
    return this.apiClient.callHttpGet({
      path: `/${advertiserId}/ads/metadata/screenshots/availabledates/${snapshotType}`});
  }
  // SINGLE AD
    /**
    * @ngdoc method
    * @name getAd
    * @methodOf app.home.adService
    * @description getAd
    *
    */
  getAd(payload: AdPayload): Observable<Ad[]> {
    return this.apiClient.callHttpGet({path: `/${payload.advertiserId}/${payload.campaignId}/ads/${payload.adId}`});
  }
  /**
   * @ngdoc method
   * @name postFormatAds
   * @methodOf app.home.adService
   * @description Post service format the campaign end/start dates
   * and test for persistent status.
   *
   * @param {array} campaigns campaigns data
   * @return {array} campaigns formatted
   *
   */
  postFormatAds(ads: Ad[]) {
    return ads.map((ad) => {
      const dateData = {
        Start: (new Date(ad.FlightStart).getTime()) ? moment(ad.FlightStart).format('M/D/YY hh:mm A') : null,
        End: (new Date(ad.FlightStart).getTime()) ? moment(ad.FlightEnd).format('M/D/YY hh:mm A') : null,
        Type: this.dateUtility.isPersistent(ad.FlightStart, ad.FlightEnd) ? 'Persistent' : 'Set',
        Persistent: this.dateUtility.isPersistent(ad.FlightStart, ad.FlightEnd)
      };
      ad.dateData = dateData;
      return ad;
    });
  }
  /**
  * @ngdoc method
  * @name addAd
  * @methodOf app.home.adService
  * @description addAd
  *
  */
  addAd(id: string, campid: string, payload: Ad): Observable<Ad> {
    return this.apiClient.callHttpPost({path: `/${id}/${campid}/ads`, param: payload});
  }
  /**
  * @ngdoc method
  * @name editAd
  * @methodOf app.home.adService
  * @description editAd
  *
  */
  editAd(id: string, campid: string, adid: string, payload: Ad): Observable<Ad> {
    return this.apiClient.callHttpPut({path: `/${id}/${campid}/ads/${adid}`, param: payload});
  }
  /**
  * @ngdoc method
  * @name deleteAd
  * @methodOf app.home.adService
  * @description deleteAd
  *
  */
  deleteAd(id: string, campid: string, adid: string): Observable<Ad> {
    return this.apiClient.callHttpDelete({path: `/${id}/${campid}/ads/${adid}`});
  }

  getAdExportLink(id: string, campid: string): string {
    const api_url = `/${id}/${campid}/tagexport`;
    return api_url;
  }

  downloadAdTag(id: string, campid: string): Observable<BlobPart>  {
    const api_url = this.getAdExportLink(id, campid);
    return this.apiClient.callHttpGet({path: api_url, responseType: 'arraybuffer'});
  }
}
