
import {catchError, switchMap, map, mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { head, map as lodashMap } from 'lodash-es';
import * as moment from 'moment';
import { Observable ,  of } from 'rxjs';
import {AppState} from '../../state';
import {CampaignAdSettingsQuery} from './campaign-ad-settings.reducer';
import { FeedService } from '@services/feed/feed.service';
import * as CampaignAdSettingsActions from './campaign-ad-settings.actions';
import {
  CampaignPayload,
  AdPayload,
  CreativePayload,
  Ad,
  Campaign,
  Creative,
} from '../../../interfaces';
import { CreativeService } from '@services/creative/creative.service';
import { CampaignService } from '@services/campaign/campaign.service';
import { AdService } from '@services/ad/ad.service';
import { CreativeSegmentKey } from '../../../interfaces/creative-segment-key.interface';
import { getEndDate } from '../../../utils/general.utils';
import { NotificationsService } from '@services/notification/notification.service';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { CampaignAdSettings } from '..';
import { IntegrationsService } from '@services/integrations/integrations.service';
import { DateUtilityService } from '@services/utility';
type Action = CampaignAdSettingsActions.All;

@Injectable()
export class CampaignAdSettingsFacade {
  loadAdWithVariations = false;
  advertiserId = '';
  campaignAdSettings$ = this.store.select(CampaignAdSettingsQuery.campaignAdSettings);


  saveAd$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CampaignAdSettingsActions.SAVE_CAMPAIGN_AD_DATA),
    map((action: CampaignAdSettingsActions.SaveCampaignAdSettingsAdSave) => action),
    switchMap(action => {
      let request;
      action = {...action, payload: {...action.payload, FlightStart: moment(action.payload.FlightStart).format('YYYY-MM-DDTHH:mm:ss.000'),
      FlightEnd: moment(action.payload.FlightEnd).format('YYYY-MM-DDTHH:mm:ss.000')}};
      if (action.params.advertiserId && action.params.adId) {
        request = this.adService.editAd(
          action.params.advertiserId, action.params.campaignId, action.params.adId, action.payload);
      } else {
        request = this.adService.addAd(action.params.advertiserId, action.params.campaignId, action.payload);
      }
      return request.pipe(map(() => {
        this.notificationsService.notify({
          severity: 'success',
          detail: 'Ad was saved successfuly.',
          summary: 'Ad saved.',
          key: 'non-sticky'
        });
        this.setFormIsClean();
       return new CampaignAdSettingsActions.SaveCampaignAdSettingsAdSaveSuccess();
      }),
      catchError(err => {
        return of (new CampaignAdSettingsActions.SaveCampaignAdSettingsAdSaveFail(err));
      } ), );
    }), ));



  getCreatives$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CampaignAdSettingsActions.GET_CAMPAIGN_AD_CREATIVES),
    map((action: CampaignAdSettingsActions.GetCampaignAdSettingsCreatives) => action.payload),
    switchMap(payload => {
      return this.creativeService.getIndirectCreatives(payload).pipe(
      map((creatives) => {
        creatives = this.postFormatVariationOptions(creatives);
       return new CampaignAdSettingsActions.GetCampaignAdSettingsCreativesSuccess(creatives);
      }),
      catchError(err => {
        return of (new CampaignAdSettingsActions.GetCampaignAdSettingsCreativesFail(err));
      } ), );
    }), ));


  getVariation$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CampaignAdSettingsActions.GET_CAMPAIGN_AD_VARIATION),
    map((action: CampaignAdSettingsActions.GetCampaignAdSettingsVariation) => action.payload),
    mergeMap(payload => {
      return this.creativeService.getIndirectCreativeVariation(payload).pipe(
      map((variation) => {
       return new CampaignAdSettingsActions.GetCampaignAdSettingsVariationSuccess(variation);
      }),
      catchError(err => {
        return of (new CampaignAdSettingsActions.GetCampaignAdSettingsVariationFail(err));
      } ), );
    }), ));


  getCampaigns$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CampaignAdSettingsActions.GET_CAMPAIGN_AD_CAMPAIGNS),
    map((action: CampaignAdSettingsActions.GetCampaignAdSettingsCampaigns ) => action.payload),
    switchMap(payload => {
      return this.campaignService.getCampaign(payload);
    }),
    map((campaign) => {
      return new CampaignAdSettingsActions.GetCampaignAdSettingsCampaignsSuccess(head(campaign) as Campaign);
    }),
    catchError(err => {
      return of (new CampaignAdSettingsActions.GetCampaignAdSettingsCampaignsFail(err));
    } ), ));


  getAds$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CampaignAdSettingsActions.GET_CAMPAIGN_AD_ADS),
    map((action: CampaignAdSettingsActions.GetCampaignAdSettingsAds) => action.payload),
    switchMap(payload => {
      this.advertiserId = payload.advertiserId;
      return this.adService.getAd(payload).pipe(
      map((ads) => {
        const ad = head(ads) as Ad;
        if (this.loadAdWithVariations) {
          this.fetchCreativeVariation(this.advertiserId, ad);
        }
        ad.Persistent = this.utilityService.isPersistent(ad.FlightStart, ad.FlightEnd);
        this.setSaveData(ad);
        return new CampaignAdSettingsActions.GetCampaignAdSettingsAdsSuccess(ad);
      }),
      catchError(err => {
        return of (new CampaignAdSettingsActions.GetCampaignAdSettingsAdsFail(err));
      } ), );
    }), ));


  getFeed$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CampaignAdSettingsActions.GET_CAMPAIGN_AD_FEED),
    map((action: CampaignAdSettingsActions.GetCampaignAdSettingsFeed) => action.payload),
    switchMap(payload => {
      return this.feedService.getFeedSegment(payload).pipe(
      map((feed) => {
        return new CampaignAdSettingsActions.GetCampaignAdSettingsFeedSuccess(feed);
      }),
      catchError(err => {
        return of (new CampaignAdSettingsActions.GetCampaignAdSettingsFeedFail(err));
      } ), );
    }), ));


  getIntegrations$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CampaignAdSettingsActions.GET_CAMPAIGN_AD_INTEGRATION),
    map((action: CampaignAdSettingsActions.GetCampaignAdSettingsIntegration) => action.payload),
    switchMap(payload => {
      return this.integrationsService.getAccountIntegrations(payload).pipe(
      map((integrations) => {
        return new CampaignAdSettingsActions.GetCampaignAdSettingsIntegrationSuccess(integrations.data);
      }),
      catchError(err => {
        return of (new CampaignAdSettingsActions.GetCampaignAdSettingsIntegrationFail(err));
      } ), );
    }), ));

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationsService: NotificationsService,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private utilityService: DateUtilityService,
    private feedService: FeedService,
    private integrationsService: IntegrationsService,
    private creativeService: CreativeService,
    private campaignService: CampaignService,
    private adService: AdService
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.adService.apiClient = this.apiClient;
    this.campaignService.apiClient = this.apiClient;
    this.creativeService.apiClient = this.apiClient;
    this.feedService.apiClient = this.apiClient;
    this.integrationsService.apiClient = this.apiClient;
  }

  resetState(): void {
    this.store.dispatch(new CampaignAdSettingsActions.ResetCampaignAdSettingsState());
  }

  addNewAdSegmentKey(): void {
    this.store.dispatch(new CampaignAdSettingsActions.AddCampaignAdSettingsSegmentKey());
  }

  setFormIsDirty(): void {
    this.store.dispatch(new CampaignAdSettingsActions.SetCampaignAdSettingsDirty(true));
  }

  setFormIsClean(): void {
    this.store.dispatch(new CampaignAdSettingsActions.SetCampaignAdSettingsDirty(false));
  }

  setNewAdStatus(payload: boolean): void {
    this.store.dispatch(new CampaignAdSettingsActions.SetCampaignAdSettingsNewAd(payload));
  }

  saveAd(params: AdPayload, payload: Ad): void {
    this.store.dispatch(new CampaignAdSettingsActions.SaveCampaignAdSettingsAdSave(params, payload));
  }

  getCreatives(payload: string): void {
    this.store.dispatch(new CampaignAdSettingsActions.GetCampaignAdSettingsCreatives(payload));
  }

  getCampaigns(payload: CampaignPayload): void {
    this.store.dispatch(new CampaignAdSettingsActions.GetCampaignAdSettingsCampaigns(payload));
  }

  getAds(payload: AdPayload, withVariations = true): void {
    this.loadAdWithVariations = withVariations;
    this.store.dispatch(new CampaignAdSettingsActions.GetCampaignAdSettingsAds(payload));
  }

  getVariation(payload: CreativePayload): void {
    this.store.dispatch(new CampaignAdSettingsActions.GetCampaignAdSettingsVariation(payload));
  }

  getFeed(payload: string): void {
    this.store.dispatch(new CampaignAdSettingsActions.GetCampaignAdSettingsFeed(payload));
    this.getIntegrations(payload);
  }

  getIntegrations(payload: string): void {
    this.store.dispatch(new CampaignAdSettingsActions.GetCampaignAdSettingsIntegration(payload));
  }

  setAdWidth(payload: number): void {
    this.store.dispatch(new CampaignAdSettingsActions.SetCampaignAdSettingsAdWidth(payload));
    this.setFormIsDirty();
  }

  setAdHeight(payload: number): void {
    this.store.dispatch(new CampaignAdSettingsActions.SetCampaignAdSettingsAdHeight(payload));
    this.setFormIsDirty();
  }

  setSaveData(ad?: Ad): void {
    this.store.dispatch(new CampaignAdSettingsActions.SetCampaignAdSettingsAdSaveData(ad));
  }

  setName(payload: string, isDirty = true): void {
    this.store.dispatch(new CampaignAdSettingsActions.SetCampaignAdSettingsAdName(payload));
    if (isDirty) {
      this.setFormIsDirty();
    }
  }

  setActive(payload: boolean): void {
    this.store.dispatch(new CampaignAdSettingsActions.SetCampaignAdSettingsAdActive(payload));
    this.setFormIsDirty();
  }

  setFlightStart(payload: Date): void {
    this.store.dispatch(new CampaignAdSettingsActions.SetCampaignAdSettingsAdFlightStart(payload));
    this.setFormIsDirty();
  }

  setFlightEnd(payload: Date): void {
    this.store.dispatch(new CampaignAdSettingsActions.SetCampaignAdSettingsAdFlightEnd(payload));
    this.setFormIsDirty();
  }

  setAdRotation(payload: number, isDirty = true): void {
    this.store.dispatch(new CampaignAdSettingsActions.SetCampaignAdSettingsAdRotation(payload));
    if (isDirty) {
      this.setFormIsDirty();
    }
  }

  setAdSegmentKey(payload: CreativeSegmentKey): void {
    if (payload.Persistent) {
      payload = {...payload, FlightEnd: this.utilityService.getPersistentEndDateString(),
        FlightStart: this.utilityService.getPersistentStartDateString()};
    }
    this.store.dispatch(new CampaignAdSettingsActions.SetCampaignAdSettingsAdSegmentKey(payload));
    this.setFormIsDirty();
  }

  deleteAdSegmentKey(payload: CreativeSegmentKey): void {
    this.store.dispatch(new CampaignAdSettingsActions.DeleteCampaignAdSettingsAdSegmentKey(payload));
    this.setFormIsDirty();
  }

  editAdSegmentKey(original: CreativeSegmentKey, payload: CreativeSegmentKey): void {
    this.store.dispatch(new CampaignAdSettingsActions.EditCampaignAdSettingsAdSegmentKey(original, payload));
    this.setFormIsDirty();
  }

  setAdPersistent(payload: boolean): void {
    this.store.dispatch(new CampaignAdSettingsActions.SetCampaignAdSettingsAdPersistent(payload));
    this.setFormIsDirty();
  }

  toggleAdPersistent(): void {
    this.store.dispatch(new CampaignAdSettingsActions.ToggleCampaignAdSettingsAdPersistent());
    const state = this.getState();
    this.formatAdDates(state.saveData);
  }

  fetchCreativeVariation(advertiserId: string, ad: Ad) {
    ad.CreativeSegmentKeys.forEach((variation) => {
      const payload: CreativePayload = this.getVariationPayload(advertiserId, variation);
      this.getVariation(payload);
    });
  }

  formatDates(payload: CreativeSegmentKey): CreativeSegmentKey {
    if (payload.Persistent) {
      payload = {...payload, FlightEnd: this.utilityService.getPersistentEndDateString(),
        FlightStart: this.utilityService.getPersistentStartDateString()};
    } else if (!payload.Persistent && this.dateIsUnset(payload)) {
      payload = {...payload, FlightEnd: getEndDate(), FlightStart: new Date};
    }
    return payload;
  }

  formatAdDates(payload: Ad) {
    if (payload.Persistent) {
      payload = {...payload, FlightEnd: this.utilityService.getPersistentEndDateString(),
        FlightStart: this.utilityService.getPersistentStartDateString()};
    } else if (!payload.Persistent && this.dateIsUnset(payload)) {
      payload = {...payload, FlightEnd: getEndDate(), FlightStart: new Date};
    }
    this.setSaveData(payload);
  }

  getVariationPayload(advertiserId: string, variation: CreativeSegmentKey): CreativePayload {
    return {
      advertiserId: advertiserId,
      creativeId: variation.CreativeId,
      variationId: variation.VariationId
    };
  }

  dateIsUnset(payload: CreativeSegmentKey|Ad): boolean {
    return (moment(payload.FlightStart).toDate().getTime() === this.utilityService.getPersistentStartDate().getTime());
  }

  dateIsSet(date: Date | string) {
    return moment(date).toDate().getTime() > 0;
  }

  postFormatVariationOptions(creatives: Creative[]) {
    creatives = lodashMap(creatives, (creative) => {
      if (creative.Variations) {
        const v = lodashMap(creative.Variations, (variation, index: number) => {
          const created = moment(variation.CreatedOn).format('M/D/YY');
          variation = {...variation, Index: index + 1, Text: 'v' + (index + 1) + ' (' + created + ')'};
          return variation;
        });
        creative = {...creative, Variations: v};
      }
      return creative;
    });
    return creatives;
  }

  private getState(): CampaignAdSettings {
    let state = <CampaignAdSettings>{};

    this.store.select(CampaignAdSettingsQuery.campaignAdSettings).subscribe(s => {
      state = s;
    }).unsubscribe();

    return state;
  }
}
