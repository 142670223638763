import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NotificationsService } from '@services/notification/notification.service';
import { SnackBarService } from '@services/snackbar/snackbar.service';

export interface NotificationItem {
  severity?: string;
  summary?: string;
  detail?: string;
  id?: string;
  key?: string;
  life?: number;
  sticky?: boolean;
  closable?: boolean;
  data?: string;
  type?: 'toast' | 'snackbar';
}
@Component({
  selector: 'app-notifications',
  templateUrl: './notification.component.html',
})
export class NotificationsComponent implements OnInit, OnDestroy {
  msgs: NotificationItem[] = [];
  subscription: Subscription|undefined;
  buffer: NotificationItem[] = [];
  interval: NodeJS.Timer|undefined;
  bufferEmpty = true;

  constructor(
  private notificationsService: NotificationsService,
  private snackBarService: SnackBarService,
  private toastr: ToastrService) {
    this.toastr.toastrConfig.preventDuplicates = true;
    this.toastr.toastrConfig.countDuplicates = true;
    this.toastr.toastrConfig.newestOnTop = true;
    this.toastr.toastrConfig.maxOpened = 3;
    this.toastr.toastrConfig.closeButton = true;
    this.toastr.toastrConfig.enableHtml = true;
  }

  ngOnInit() {
    this.subscribeToNotifications();
  }

  subscribeToNotifications() {
    this.subscription = this.notificationsService.notificationChange
    .subscribe(notification => {
      if (notification.type === 'toast') {
        this.pushNotification(notification);
      } else {
        if (notification.detail) {
          this.snackBarService.add(notification.detail);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  pushNotification(notification: NotificationItem) {
    if (notification.severity === 'success') {
      this.showSuccess(notification);
    } else if (notification.severity === 'error') {
      this.showError(notification);
    } else if (notification.severity === 'info') {
      this.showInfo(notification);
    } else if (notification.severity === 'warning') {
      this.showWarning(notification);
    }
  }

  showSuccess(notification: NotificationItem) {
    this.toastr.success(notification.detail, notification.summary);
  }

  showError(notification: NotificationItem) {
    this.toastr.error(notification.detail, notification.summary, {
      enableHtml :  true
    });
  }

  showInfo(notification: NotificationItem) {
    this.toastr.info(notification.detail, notification.summary);
  }

  showWarning(notification: NotificationItem) {
    this.toastr.warning(notification.detail, notification.summary);
  }
}
