import { Injectable } from '@angular/core';
import { EntityCollectionService, EntityCollectionServiceFactory } from '@ngrx/data';
import { NotificationsService } from '@services/notification/notification.service';
import { Observable } from 'rxjs';
import { IntegrationDefinition, IntegrationType } from '../../../interfaces';
import { ACCOUNT_INTEGRATION_SAVED, ACCOUNT_INTEGRATION_UPDATED, EntityMap } from '../../entity/entity-metadata';
import { AccountIntegrationTypeDataService } from '../account/integration-type.data.service';
import { AccountIntegrationsDataService } from '../account/integrations.data.service';
import { GroupIntegrationTypeDataService } from '../group/integration-type.data.service';
import { GroupIntegrationsDataService } from '../group/integrations.data.service';

@Injectable()
export class IntegrationEffects {
  uploadedFiles: File[] = [];
  files: File[] = [];
  accountIntegrationService: EntityCollectionService<IntegrationDefinition>;
  accountIntegrationTypeService: EntityCollectionService<IntegrationType>;
  groupIntegrationService: EntityCollectionService<IntegrationDefinition>;
  groupIntegrationTypeService: EntityCollectionService<IntegrationType>;
  constructor(
    private notificationsService: NotificationsService,
    private accountIntegrationsDataService: AccountIntegrationsDataService,
    private accountIntegrationTypeDataService: AccountIntegrationTypeDataService,
    private groupIntegrationsDataService: GroupIntegrationsDataService,
    private groupIntegrationTypeDataService: GroupIntegrationTypeDataService,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
  ) {
    this.accountIntegrationService = this.entityCollectionServiceFactory.create<IntegrationDefinition>(EntityMap.AccountIntegration);
    this.accountIntegrationTypeService = this.entityCollectionServiceFactory.create<IntegrationType>(EntityMap.AccountIntegrationType);
    this.groupIntegrationService = this.entityCollectionServiceFactory.create<IntegrationDefinition>(EntityMap.GroupIntegration);
    this.groupIntegrationTypeService = this.entityCollectionServiceFactory.create<IntegrationType>(EntityMap.GroupIntegrationType);

    this.accountIntegrationService.entityActions$.subscribe(action => {
      if (action.type === ACCOUNT_INTEGRATION_SAVED || action.type === ACCOUNT_INTEGRATION_UPDATED) {
        this.notificationsService.notify({
          severity: 'success',
          detail: 'Integration saved successfully.',
          summary: 'Integration saved.',
          key: 'non-sticky'
        });
      }
    });
  }

  getIntegrations(accountId: string): void {
    this.accountIntegrationsDataService.accountId = accountId;
    this.accountIntegrationService.load();
  }

  getIntegrationTypes(accountId: string): void {
    this.accountIntegrationTypeDataService.accountId = accountId;
    this.accountIntegrationTypeService.load();
  }

  getGroupIntegrations(groupId: string) {
    this.groupIntegrationsDataService.groupId = groupId;
    this.groupIntegrationService.load();
  }

  getGroupIntegrationTypes(groupId: string): void {
    this.groupIntegrationTypeDataService.groupId = groupId;
    this.groupIntegrationTypeService.load();
  }

  getIntegration(accountId: string, intid: string): void {
    this.accountIntegrationsDataService.accountId = accountId;
    this.accountIntegrationService.getByKey(intid);
  }

  deleteIntegration(accountId: string, intid: string): void {
    this.accountIntegrationsDataService.accountId = accountId;
    this.accountIntegrationService.delete(intid);
  }

  getIntegrationType(accountId: string, intid: string): void {
    this.accountIntegrationTypeDataService.accountId = accountId;
    this.accountIntegrationTypeService.getByKey(intid);
  }

  saveIntegration(payload: {accountId: string, payload: IntegrationDefinition, customer?: boolean, uploadType?: string,
  file?: File[], token?: string , intid?: string, run?: boolean, emailManifest?: boolean}): Observable<IntegrationDefinition> {
    this.accountIntegrationsDataService.accountId = payload.accountId;
    this.accountIntegrationsDataService.payload = payload;
    if (!payload.intid) {
      return this.accountIntegrationService.add(payload.payload);
    } else {
      return this.accountIntegrationService.update(payload.payload);
    }
  }

  getGroupIntegration(groupId: string, intid: string): void {
    this.groupIntegrationsDataService.groupId = groupId;
    this.groupIntegrationService.getByKey(intid);
  }

  deleteGroupIntegration(groupId: string, intid: string): void {
    this.groupIntegrationsDataService.groupId = groupId;
    this.groupIntegrationService.delete(intid);
  }

  getGroupIntegrationType(groupId: string, intid: string): void {
    this.groupIntegrationTypeDataService.groupId = groupId;
    this.groupIntegrationTypeService.getByKey(intid);
  }

  saveGroupIntegration(payload: {groupId: string, payload: IntegrationDefinition, customer?: boolean, uploadType?: string,
  file?: File[], token?: string , intid?: string, run?: boolean, emailManifest?: boolean}): Observable<IntegrationDefinition> {
    this.groupIntegrationsDataService.groupId = payload.groupId;
    this.groupIntegrationsDataService.payload = payload;
    if (!payload.intid) {
      return this.groupIntegrationService.add(payload.payload);
    } else {
      return this.groupIntegrationService.update(payload.payload);
    }
  }
}
