import { Injectable } from '@angular/core';
import { flatten } from 'lodash-es';
import * as moment from 'moment';
import {
  DashboardChartYAxisConfigObj,
  DashboardDefaultChartSeriesDataObj,
  DashboardDefaultChartSeriesTooltipObj,
  DashboardPieChartDataObj,
  DashboardZipCodeChartDataObj,
} from '../../shared/interfaces/dashboard/interfaces';
import {
  DashboardAggregationType,
  DashboardColumnUnit,
} from '../../components/integrations/interfaces';
import {
  DashboardPageResponseCharts,
  DashboardPageResponseSeries,
} from '../../shared/interfaces';

@Injectable()
export class DashboardEngineChartUtilityService {
  colorCodes = ['#1E88E5', '#F7941D', '#7DCA5C', '#7a4b9b'];

  generateDefaultXAxis() {
    return {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%m/%d',
        week: '%m/%d',
        month: '%m/%y',
        year: '%Y',
      },
    };
  }

  generateDefaultYAxis(seriesData: DashboardPageResponseSeries[]) {
    if (seriesData.length > 1) {
      return seriesData.map((data, index) => {
        let returnObj: DashboardChartYAxisConfigObj = {
          title: { text: data.series_title },
        };
        if (index > 0) {
          returnObj.opposite = true;
        }
        returnObj.labels = this.generateXAxisLabels(data.series_unit_type)
        return returnObj;
      });
    } else {
      let returnObj: DashboardChartYAxisConfigObj = {
        title: { text: seriesData[0].series_title },
      };
      returnObj.labels = this.generateXAxisLabels(seriesData[0].series_unit_type)
      return returnObj;
    }
  }

  generateXAxisLabels(unitType: DashboardColumnUnit) {
    return {
      formatter: function (this:any) {
        let label = this.axis.defaultLabelFormatter.call(this);
        switch(unitType) {
          case DashboardColumnUnit.Dollars:
            return '$'+label;
          case DashboardColumnUnit.Percent:
            return label+'%';
          case DashboardColumnUnit.Duration:
            return label+'hrs'
          default:
            return label
        }
      }
    }
  }

  generateDefaultSeriesData(
    chartData: DashboardPageResponseCharts,
    comparePreviousPeriod: boolean
  ) {
    let seriesData = chartData.chart_series;
    let suffix =
      seriesData[0].series_agg_type === DashboardAggregationType.Average
        ? ' (Avg)'
        : seriesData[0].series_agg_type === DashboardAggregationType.Sum
        ? ' (Sum)'
        : '';
    let generatedChartData = chartData.chart_series.map((dataSeries, index) => {
      let isComparisonActive = dataSeries.series_comparator !== null;
      let returnArray: DashboardDefaultChartSeriesDataObj[] = [
        {
          data: this.generateSeriesData(
            dataSeries,
            false,
            comparePreviousPeriod
          ),
          color: index === 0 ? this.colorCodes[0] : this.colorCodes[2],
          name: `${isComparisonActive ? 'You - ' : ''}${
            dataSeries.series_title
          }${suffix}`,
          marker: {
            symbol: 'circle',
          },
          yAxis: index,
          tooltip: this.generateDataSeriesTooltip(dataSeries),
        },
      ];
      if (dataSeries.series_comparator) {
        returnArray.push({
          data: this.generateSeriesData(
            dataSeries,
            true,
            comparePreviousPeriod
          ),
          color: index === 0 ? this.colorCodes[1] : this.colorCodes[3],
          name: `Comparison - ${dataSeries.series_title}${suffix}`,
          marker: {
            symbol: 'triangle',
          },
          yAxis: index,
        });
      }
      return returnArray;
    });
    return flatten(generatedChartData);
  }

  generateDataSeriesTooltip(
    dataSeries: DashboardPageResponseSeries
  ): DashboardDefaultChartSeriesTooltipObj | undefined {
    switch(dataSeries.series_unit_type) {
      case DashboardColumnUnit.Dollars:
        return {
          valuePrefix: '$ ',
        };
      case DashboardColumnUnit.Duration:
        return {
          valueSuffix: 'hrs',
          valuePrefix: '',
        }
      case DashboardColumnUnit.Percent:
        return {
          valueSuffix: '%',
          valuePrefix: '',
        }
      default:
        return undefined
    }
  }

  generateSeriesData(
    dataSeries: DashboardPageResponseSeries,
    isComparator: boolean,
    comparePreviousPeriod: boolean
  ) {
    let returnData = [];
    if (comparePreviousPeriod && dataSeries.series_comparator) {
      let combinedXaxis = [
        ...dataSeries.series_comparator.x_axis,
        ...dataSeries.series_base.x_axis,
      ];
      let combinedYAxis = [
        ...dataSeries.series_comparator.y_axis,
        ...dataSeries.series_base.y_axis,
      ];
      let comparatorLength = dataSeries.series_comparator.x_axis.length;
      for (let i = 0; i < combinedXaxis.length; i++) {
        let yAxisValue = null;
        if (isComparator) {
          if (i <= comparatorLength - 1) {
            yAxisValue = combinedYAxis[i];
          }
        } else {
          if (i > comparatorLength - 1) {
            yAxisValue = combinedYAxis[i];
          }
        }
        if (dataSeries.series_unit_type === DashboardColumnUnit.Percent) {
          yAxisValue = yAxisValue ? yAxisValue*100 : null
        }
        returnData.push([
          moment(combinedXaxis[i]).utc(true).valueOf(),
          yAxisValue,
        ]);
      }
    } else {
      let xAxis = [];
      let yAxis = [];
      if (isComparator && dataSeries.series_comparator) {
        xAxis = dataSeries.series_comparator.x_axis;
        yAxis = dataSeries.series_comparator.y_axis;
      } else {
        xAxis = dataSeries.series_base.x_axis;
        yAxis = dataSeries.series_base.y_axis;
      }
      for (let i = 0; i < xAxis.length; i++) {
        let yValue = yAxis[i];
        if (dataSeries.series_unit_type === DashboardColumnUnit.Percent) {
          yValue = yAxis[i]*100
        }
        returnData.push([moment(xAxis[i]).utc(true).valueOf(), yValue]);
      }
    }
    let sortedData = returnData.sort((a, b) => (a[0] || 0) - (b[0] || 0));
    return sortedData;
  }

  generatePieChartData(
    chartData: DashboardPageResponseCharts,
    generateCompareData: boolean
  ): DashboardPieChartDataObj[] {
    let dataArray: DashboardPieChartDataObj[] = [];
    let seriesData = chartData.chart_series;
    dataArray = seriesData
      .map(
        (seriesData): DashboardPieChartDataObj => {
          let dataSource = generateCompareData
            ? seriesData.series_comparator
            : seriesData.series_base;
          return {
            name: seriesData.series_title,
            y: dataSource?.y_axis[0] || 0,
          };
        }
      )
      .sort((a, b) => a.y - b.y);
    return dataArray;
  }

  generateZipCodeSeriesAndColor(
    chartData: DashboardPageResponseCharts,
    generateCompareData: boolean
  ) {
    let maxColor = generateCompareData
      ? this.colorCodes[1]
      : this.colorCodes[0];
    let midColor = generateCompareData ? '#f7941d' : '#88c0f1';
    let colorAxis = {
      min: 1,
      type: 'logarithmic',
      minColor: '#EEEEFF',
      maxColor: maxColor,
      stops: [
        [0, '#EFEFFF'],
        [0.67, midColor],
        [1, maxColor],
      ],
    };
    let series = [
      {
        animation: {
          duration: 1000,
        },
        data: [] as DashboardZipCodeChartDataObj[],
        joinBy: ['postal-code', 'code'],
        dataLabels: {
          enabled: true,
          color: '#FFFFFF',
          format: '{point.code}',
        },
      },
    ];
    let dataSource = generateCompareData
      ? chartData.chart_series[0].series_comparator
      : chartData.chart_series[0].series_base;
    dataSource?.x_axis.forEach((xValue, index) => {
      series[0].data.push({
        code: xValue,
        value: dataSource?.y_axis[index] || 0,
      });
    });
    return {
      colorAxis,
      series,
    };
  }

  generateHeatMapAxisData(
    chartData: DashboardPageResponseCharts,
    generateCompareData: boolean
  ) {
    let xAxis = {
      categories: [] as string[],
    };
    let yAxis = {
      categories: [] as string[],
      title: null,
      reversed: true,
    };
    let series = {
      name: null,
      borderWidth: 1,
      dataLabels: {
        enabled: true,
        color: '#000000',
      },
      data: [] as number[][],
    };
    chartData.chart_series.map((dataSeries, index) => {
      let selectedSeriesSource = generateCompareData
        ? dataSeries.series_comparator
        : dataSeries.series_base;
      if (index === 0) {
        yAxis.categories = selectedSeriesSource?.x_axis || [];
      }
      xAxis.categories.push(dataSeries.series_title);
      (selectedSeriesSource?.y_axis || []).map((dataPoint, subIndex) => {
        series.data.push([index, subIndex, dataPoint]);
      });
    });
    return {
      xAxis,
      yAxis,
      series: [series],
    };
  }
}
