import { Injectable } from '@angular/core';
import { ApiClient } from '../../shared/interfaces/api-client.interface';


@Injectable()
export class IndeedService {
  public apiClient = <ApiClient>{};

  constructor() { }

  connect(redirectUrl: string) {
    return this.apiClient.callHttpGet(
      {path: `/indeed/connect?redirect_uri=${redirectUrl}`, type: ''});
  }

  authorize(authorizationCode: string, redirectUrl: string) {
    return this.apiClient.callHttpGet({path: `/indeed/${authorizationCode}/connect?redirect_uri=${redirectUrl}`,
    type: ''});
  }

}
