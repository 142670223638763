import { EntitySelectorsFactory } from '@ngrx/data';
import { createSelector } from '@ngrx/store';
import { Account, AccountGroup } from '../../../interfaces';
import { selectClaims } from '../../claims/claims.data.selectors';
import { EntityMap } from '../../entity/entity-metadata';
import { AppState } from '../../state';
import { AccountState } from './account.model';


export const selectors = new EntitySelectorsFactory().create<AccountGroup>(EntityMap.AccountGroup);

export const selectAccountGroups = createSelector(
  selectors.selectEntities,
  (group) => group
);

export const selectAccountGroupLoading = createSelector(
  selectors.selectLoading,
  (loading) => loading
);

export const selectAccountGroupsAndClaims = createSelector(
  selectAccountGroups,
  selectClaims,
  (groups, claims) => {
    return {
      accountGroups: groups,
      claims: claims
    }
  }
);

export const selectAccountState = (state: AppState) => state.account;

export const selectActiveAdvertiser = createSelector(
  selectAccountState,
  (state: AccountState) => state?.activeAdvertiser
);

export const selectCurrentActiveAdvertiser = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return state?.activeSelection?.Type === 'Advertiser' ? state?.activeAdvertiser : <Account>{};
  }
);

export const selectActiveGroup = createSelector(
  selectAccountState,
  (state: AccountState) => state?.activeAdvertiserGroup
);

export const selectCurrentActiveGroup = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return state?.activeSelection?.Type === 'Advertiser Group' ? state?.activeAdvertiserGroup : <AccountGroup>{};
  }
);

export const selectGlobalAccountAdvertisers = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return state?.advertisers;
  }
);

export const selectGlobalAccountActiveItem = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return state?.activeSelection;
  }
);

export const selectGlobalAccountActiveGroup = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return (state?.activeAdvertiserGroup && state.activeAdvertiserGroup.GroupId) ? state.activeAdvertiserGroup : undefined;
  }
);

export const selectGlobalAccountClaims = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return state?.claims;
  }
);

export const selectGlobalAccountUser = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return state?.user;
  }
);

export const selectGlobalAccountActiveAccount = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return (state?.activeAdvertiser && state.activeAdvertiser.AccountId) ? state.activeAdvertiser : undefined;
  }
);
