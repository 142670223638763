import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { SubscriptionLike } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserWhoIsInfo } from '../../shared/interfaces/claim.interface';
import { AppState } from '../../shared/state/state';
import { selectGlobalFilterState } from '../../shared/state/global-filter/global-filter.selectors';
import { isEqual } from 'lodash-es';
import { GlobalFilterState } from '../../shared/state/global-filter';
import { Account } from '../../shared/interfaces';
import { selectActiveAdvertiser } from '../../shared/state/global/account/account.selectors';
import { UserInfo } from '../../shared/interfaces/common.interface';
import { selectUser } from '../../shared/state/user/user.selectors';

@Injectable()
export class LiveChatService implements OnDestroy {
  isBrowser: boolean;
  liveChartServiceSubscriptions: SubscriptionLike[] = [];
  globalFilterState: GlobalFilterState = <GlobalFilterState>{};
  activeAccount: Account = <Account>{};
  user: UserInfo[] = [];
  private static appId = 'y82074rt';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
    private store: Store<AppState>
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.liveChartServiceSubscriptions.push(
      this.store
        .select(selectGlobalFilterState)
        .subscribe((globalFilterState) => {
          if (!isEqual(globalFilterState, this.globalFilterState)) {
            this.globalFilterState = globalFilterState;
          }
        }),
      this.store.select(selectActiveAdvertiser).subscribe((activeAccount) => {
        if (!isEqual(activeAccount, this.activeAccount)) {
          this.activeAccount = activeAccount;
        }
      }),
      this.store.select(selectUser).subscribe((user) => {
        if (!isEqual(user, this.user)) {
          this.user = user;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.liveChartServiceSubscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );
  }

  static anonymous() {
    if ((window as any).Intercom) {
      (window as any).Intercom('boot', {
        app_id: LiveChatService.appId,
        name: 'Anonymous', // Full name
      });
      (window as any).Intercom('update');
    }
  }

  static authenticated(user: UserWhoIsInfo) {
    if ((window as any).Intercom) {
      (window as any).Intercom('boot', {
        app_id: LiveChatService.appId,
        name: user.DisplayName,
        email: user.UserName,
        user_id: user.UserId,
      });
      (window as any).Intercom('update');
    }
  }

  static initiateChartErrorChat(message: string) {
    if ((window as any).Intercom) {
      (window as any).Intercom('showNewMessage', message);
    }
  }

  initiateChartDataIssueMessage(
    chartTitle: string,
    dashboardTitle: string,
    chartType: string
  ) {
    let message = `Hello, I have noticed an issue with the ${chartTitle} chart on the ${dashboardTitle} dashboard. Please see the details below: \n \n Account: ${this.activeAccount.Name} \n Dashboard: ${dashboardTitle} \n Chart Title: ${chartTitle} \n Chart Type: ${chartType} \n Control Start Date: ${this.globalFilterState.dateRange.fromDate} \n Control End Date: ${this.globalFilterState.dateRange.toDate} \n Comparisons: \n  Location Comparisons: \n  Selected Fit Range: ${this.globalFilterState.selectedLocationFilters.selectedFitRange} \n  Selected Tags: ${this.globalFilterState.selectedLocationFilters.selectedTags}\n  Selected Regions: ${this.globalFilterState.selectedLocationFilters.selectedRegions}\n  Selected Age Range: ${this.globalFilterState.selectedLocationFilters.selectedAgeRange}\n  Selected Date Comparison Option: ${this.globalFilterState.selectedDateComparisonOption}
      `;

    if ((window as any).Intercom) {
      (window as any).Intercom('showNewMessage', message);
    }
  }
}
