import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {TargetingCriterion} from '../../shared/interfaces/targeting-criterion.interface';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { ErrorHandlerService } from '../helpers/error-handler.service';


@Injectable()
export class TargetingService {
  public apiClient = <ApiClient>{};

  constructor() { }

  getTargetingCriteria(advertiserId: string): Observable<TargetingCriterion[]> {
    const api_url = `/${advertiserId}/targetingcriteria`;
    return this.apiClient.callHttpGet({path: api_url});
  }
}
