import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StateService } from '../state/state.service';
import { ResourceClaim, UserWhoIsInfo, AdvertiserRole } from '../../shared/interfaces/claim.interface';
import { ClaimsPayload } from '../../shared/interfaces';
import { ApiService } from '../api/api.service';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { ErrorHandlerService } from '../helpers/error-handler.service';

@Injectable()
export class ClaimsService {
  public apiClient = <ApiClient>{};
  cache = {};
  keyname = {
    advertiserGroup: 'advertiserGroupClaims_',
    advertiser: 'advertiserClaims_',
  };

  roles = [
    {
      // None - not a valid role
      //     Name: 'None',
      //     Value: 0,
      //     Icon: 'fa-ban'
      // },{
      Name: 'Read Only',
      Value: 1,
      Icon: 'fa-eye',
    },
    {
      Name: 'Delete',
      Value: 2,
      Icon: 'fa-wrench',
    },
    {
      Name: 'Account Admin',
      Value: 4,
      Icon: 'fa-wrench',
    },
    {
      Name: 'Traffic',
      Value: 8,
      Icon: 'fa-wrench',
    },
    {
      Name: 'Buy',
      Value: 16,
      Icon: 'fa-wrench',
    },
    {
      Name: 'Reporting',
      Value: 32,
      Icon: 'fa-wrench',
    },
    {
      Name: 'User Admin',
      Value: 64,
      Icon: 'fa-wrench',
    },
    {
      Name: 'Group Admin',
      Value: 128,
      Icon: 'fa-wrench',
    },
  ];

  advertiserRoles: AdvertiserRole[] = [
    {
      Name: 'Account Admin',
      Value: 4,
      label: 'Account Admin',
      value: 4,
      Icon: 'fa-wrench',
    },
  ];

  groupRoles = [
    {
      Name: 'Group Admin',
      Value: 128,
      Icon: 'fa-wrench',
    },
  ];

  constructor(private stateService: StateService) {}

  // check service impl, may only need userid
  getUserWhois(payload: UserWhoIsInfo): Observable<UserWhoIsInfo[]> {
    return this.apiClient.callHttpPost({
      path: `/userclaims/whois`,
      param: payload,
    });
  }

  // Todo: remove from cache on edit
  getGroupClaims(groupId: string, skipErrorHandling = false): Observable<ResourceClaim[]> {
    const keyname = this.keyname.advertiserGroup + groupId;

    return this.apiClient
      .callHttpGet({ path: `/userclaims/advertisergroups/${groupId}` }, skipErrorHandling)
      .pipe(tap((data) => this.stateService.put(keyname, data)));
  }
  // EDIT AND ADD look the same because API uses PUT for BOTH.  Do not change!!!
  editGroupClaims(groupId: string, payload: ResourceClaim): Observable<ResourceClaim> {
    const keyname = this.keyname.advertiserGroup + groupId;
    this.stateService.remove(keyname);

    return this.apiClient.callHttpPut({
      path: `/userclaims/advertisergroups/${groupId}`,
      param: payload,
    });
  }

  addGroupClaim(groupId: string, payload: ResourceClaim): void {
    const keyname = this.keyname.advertiserGroup + groupId;
    this.stateService.remove(keyname);

    this.apiClient.callHttpPut({
      path: `/userclaims/advertisergroups/${groupId}`,
      param: payload,
    });
  }

  deleteGroupClaims(payload: ClaimsPayload): Observable<void> {
    const keyname = this.keyname.advertiserGroup + payload.groupId;
    this.stateService.remove(keyname);

    return this.apiClient.callHttpDelete({
      path: `/userclaims/advertisergroups/${payload.groupId}/${payload.userClaimId}`,
    });
  }

  deleteAdvertiserClaims(claim: ClaimsPayload): Observable<void> {
    const keyname = this.keyname.advertiserGroup + claim.groupId;
    this.stateService.remove(keyname);

    return this.apiClient.callHttpDelete({
      path: `/userclaims/${claim.groupId}/advertiser/${claim.advertiserId}/${claim.userClaimId}`,
    });
  }
  /**
   *
   * @param groupId
   * @returns {*}
   */
  getAdvertiserClaims(groupId: string, skipErrorHandling = false): Observable<ResourceClaim[]> {
    const keyname = this.keyname.advertiser + groupId;

    return this.apiClient
      .callHttpGet({
        path: `/userclaims/advertisergroups/${groupId}/advertisers`,
      }, skipErrorHandling)
      .pipe(tap((data) => this.stateService.put(keyname, data)));
  }

  getAdvertiserClaims2020(groupId: string, skipErrorHandling = false): Observable<ResourceClaim[]> {
    const keyname = this.keyname.advertiser + groupId;

    return this.apiClient
      .callHttpGet({
        path: `/userclaims/advertisergroups/${groupId}/advertisers`,
      }, skipErrorHandling)
      .pipe(tap((data) => this.stateService.put(keyname, data)));
  }

  getAdvertiserClaim(payload: ClaimsPayload): Observable<ResourceClaim[]> {
    const keyname = this.keyname.advertiser + payload.groupId;

    return this.apiClient
      .callHttpGet({
        path: `/userclaims/advertisergroups/${payload.groupId}/advertisers/${payload.advertiserId}`,
      })
      .pipe(tap((data) => this.stateService.put(keyname, data)));
  }

  getUserClaims(): Observable<ResourceClaim[]> {

    return this.apiClient.callHttpGet({
      path: `/userclaims`,
    });
  }

  editAdvertiserClaims(
    groupId: string,
    id: string,
    payload: ResourceClaim
  ): Observable<ResourceClaim> {
    const keyname = this.keyname.advertiser + groupId;
    this.stateService.remove(keyname);

    return this.apiClient.callHttpPut({
      path: `/userclaims/${groupId}/advertiser/${id}`,
      param: payload,
    });
  }

  addAdvertiserClaim(
    groupId: string,
    id: string,
    payload: ResourceClaim
  ): void {
    const keyname = this.keyname.advertiser + groupId;
    this.stateService.remove(keyname);

    this.apiClient.callHttpPut({
      path: `/userclaims/${groupId}/advertiser/${id}`,
      param: payload,
    });
  }
}
