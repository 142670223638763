import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformLocation, isPlatformBrowser } from '@angular/common';
import { Store } from '@ngrx/store';
import { environment } from '@environments/environment';
import { LiveChatService } from '@services/live-chat/live-chat.service';
import { AppState } from '@shared/state/state';
import { AuthLogoutSuccess } from '@shared/state/auth';

@Component({
  templateUrl: './logout-success.component.html',
  styleUrls: ['./logout-success.component.scss'],
})
export class LogoutSuccessComponent implements OnInit {
  logo = environment.defaultLogo;
  appServer = '';
  isBrowser: boolean;
  constructor(
    private platformLocation: PlatformLocation,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private store: Store<AppState>
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.appServer = this.isBrowser
      ? (platformLocation as any).location.origin
      : '';
  }

  ngOnInit() {
    LiveChatService.anonymous();
  }

  goToLogin(): void {
    this.store.dispatch(new AuthLogoutSuccess())
    this.router.navigate(['login']);
  }
}
