export enum IntegrationTypeAndVersion {
  None = 0,
  SingleCustomerViewV201811 = 1,
  BlobImportIntegrationV201811 = 100,
  MarketoIntegrationV201811 = 200,
  MilleniumIntegrationV201811 = 300,
  SftpImportIntegrationV201811 = 400,
  AttributionCustomersIntegrationV201811 = 500,
  AttributionProductCatalogIntegrationV201811 = 600,
  AttributionSalesIntegrationV201811 = 700,
  DynamicAdServingIntegrationV201811 = 800,
  SegmentationRFMV201812 = 900,
  SegmengationLTVV201812 = 1000,
  AdWordsAudienceV201812 = 1100,
  FacebookAudienceV201812 = 1200,
  DirectMailV201901 = 1300,
  MailChimpV201902 = 1400,
  LeadCaptureV201902 = 1500,
  EngagementWidgetsConfigV201904 = 1700,
  AdWordsDynamicCampaignIntegrationV201904 = 1900,
  OxifreshCrmIntegrationV201905 = 2000,
  AiQnAModelV201905 = 2100,
  ActiveCampaignV201906 = 2200,
  FranConnectV201906 = 2300,
  NarangaV201906 = 2400,
  FranchiseSoftV201906 = 2500,
  AlohaPosV201906 = 2600,
  MindBodyV201906 = 2700,
  SawyerToolsV201906 = 2800,
  SugarCrmV201908 = 3000,
  HttpPullIntegrationV201909 = 3100,
  SegmentationLTVV201909 = 3200,
  SharpSpringSyncV201909 = 3300,
  GoogleMerchantFeedV201910 = 3400,
  FacebookCatalogFeedV201910 = 3500,
  ManualFileUploadIntegrationV201910 = 3600,
  CustomerFilterListIntegrationV201911 = 3700,
  CustomerOrdersV201912 = 3800,
  EmailManifestV202001 = 3900,
  ShortenedLinkBeaconsV202001 = 4000,
  ClientTetherIntegrationV202002 = 4100,
  MarketSharpIntegrationV202003 = 4200,
  EmbeddedDashboardV202004 = 4300,
  GoogleAdsReportingV202006 = 4400,
  FacebookAdInsightsReportingV202006 = 4500,
  TradeDeskReportingV202006 = 4600,
  CallRailReportingV202006 = 4700,
  IndeedReportingV202009 = 4800,
  MicrosoftAdvertisingReportingV202009 = 4900,
  LinkedInReportingV202101 = 5000,
  ConnectedTVReportingV202103 = 5100,
  DashboardConfigurationV202104 = 5200,
  LeaderboardConfigurationV202104 = 5300,
  FranConnectV202107 = 5400,
  SqlImportV202107 = 5500,
  DataSourceConfigurationV202107 = 5600,
  FitCoordinatorConfigurationV202110 = 5700,
  FitScoreConfigurationV202110 = 5800,
  FitFranchiseeHomeViewIntegrationV202110 = 5900,
  FitEmailAlertIntegrationV202110 = 6000,
  HighLevelCrmV202202 = 6100,
  DashboardEngineV2V202203 = 6500,
  FiveTranV202202 = 6700,
  TransitivETLV202208 = 6900,
  LeaderboardV2Integration = 7000,
  FitDrillDownConfiguration = 7400,
}
