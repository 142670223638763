<div id="welcomeContainer">
  <div class="cardPanel">
    <div class="contentContainer">
      <h2 class="text-center mar-t-30 mar-b-10" id="page-title">
        Welcome to Transitiv!
      </h2>
      <img [src]="logo" id="transitiv-logo" />
      <div class="formContainer">
        <h3>Please set your password to continue</h3>
        <div class="setPasswordForm">
          <app-dynamic-form
            [fields]="accountFormConfig"
            (submit)="setPassword($event)"
          ></app-dynamic-form>
        </div>
      </div>
      <div class="welcomeSpinnerContainer">
        <mat-spinner [diameter]="50" *ngIf="isLoading"></mat-spinner>
        <div *ngIf="errorMsg" class="errorMsg">
          Error: {{ errorMsg }}. Contact Customer Support for further assistance
        </div>
      </div>
    </div>
  </div>
</div>
