import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { SubscriptionLike } from 'rxjs';
import { AccountService } from '@services/account/account.service';
import { TitleService } from '@services/title/title.service';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { FieldConfig } from '@shared/interfaces/field.interface';
import { FormValidatorService } from '@services/form-validator/form-validator.service';
import { environment } from '@environments/environment';
import { ResetPasswordPayload } from '@shared/interfaces';
import { DecodeTokenResponse } from '@shared/interfaces/auth.interface';
import { AuthFacade } from '@shared/state/auth';

@Component({
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, OnDestroy {
  error = '';
  errors = {
    Email: false,
    Password: false,
    ConfirmPassword: false,
  };
  errorMsg = '';
  isLoading = true;
  isFetching = false;
  accountFormConfig: FieldConfig[] = [
    {
      type: 'input',
      id: 'form-email',
      label: 'Email',
      inputType: 'text',
      name: 'Email',
      isBlock: true,
      hasTooltip: false,
      hasElement: true,
      validations: [
        {
          name: 'required',
          validator: Validators.required,
          message: 'Email Required',
        },
      ],
      disabled: () => true,
      value: undefined,
    },
    {
      type: 'input',
      id: 'form-new-password',
      label: 'Password',
      inputType: 'password',
      name: 'Password',
      isBlock: true,
      hasTooltip: false,
      hasElement: true,
      tooltip:
        'Passwords should have one uppercase letter, one number, one special symbol (!,@,?,etc)',
      validations: [
        {
          name: 'required',
          validator: Validators.required,
          message: 'Password is Required',
        },
        {
          name: 'minlength',
          validator: Validators.minLength(8),
          message: 'Password must be at least 8 characters',
        },
        {
          name: 'hasSpecialCharacters',
          // tslint:disable-next-line: max-line-length
          validator: FormValidatorService.patternValidator(
            /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:/,
            { hasSpecialCharacters: true }
          ),
          message: 'Passwords must contain at least one Special Character',
        },
        {
          name: 'hasSmallCase',
          validator: FormValidatorService.patternValidator(/[a-z]/, {
            hasSmallCase: true,
          }),
          message: 'Password must contain at least one Letter in Small Case',
        },
        {
          name: 'hasNumber',
          validator: FormValidatorService.patternValidator(/\d/, {
            hasNumber: true,
          }),
          message: 'Password should contain at least one number',
        },
        {
          name: 'hasCapitalCase',
          validator: FormValidatorService.patternValidator(/[A-Z]/, {
            hasCapitalCase: true,
          }),
          message: 'Passwords must contain at least one in Capital Case',
        },
      ],
    },
    {
      type: 'input',
      id: 'form-confirm-password',
      label: 'Confirm Password',
      inputType: 'password',
      name: 'ConfirmPassword',
      isBlock: true,
      hasTooltip: false,
      hasElement: true,
      validations: [
        {
          name: 'required',
          validator: Validators.required,
          message: 'Confirm Password Required',
        },
        {
          name: 'passwordsNotEqual',
          validator: FormValidatorService.RepeatPasswordValidator,
          message: 'Passwords must match',
        },
      ],
    },
    {
      type: 'button',
      id: 'form-password-save',
      label: 'Set Password and Log In',
      buttonLocation: 'normal',
    },
  ];
  token = '';
  isBrowser: boolean;
  appServer = '';
  logo = environment.squareLogoWhite;
  setPasswordToken = '';
  user = {
    username: '',
    password: '',
    client_id: 'acnmanagementapp',
    client_secret: '@cn16pPr0v1d3rManagementApp',
    scopes: 'openid managementappapi',
    grant_type: 'password',
  };
  email = '';
  private authSubscription: SubscriptionLike | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiClient: ApiService,
    private accountService: AccountService,
    private titleService: TitleService,
    private errorHandler: ErrorHandlerService,
    private authFacade: AuthFacade,

    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.titleService.setTitle('Change Password');
    this.apiClient.errorHandler = this.errorHandler;
    this.accountService.apiClient = this.apiClient;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.token = params['token'] || '';
      if (this.token) {
        this.accountService
          .decodeToken(this.token)
          .subscribe((responese: DecodeTokenResponse) => {
            this.setPasswordToken = responese.SetPasswordToken;
            this.accountFormConfig[0].value = responese.EmailAddress;
            this.email = responese.EmailAddress
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
            this.errorMsg = error?.error;
          });
      }
    });
    this.authSubscription = this.authFacade.auth$.subscribe((state) => {
      if (state && state.authenticated) {
        this.router.navigate(['dashboard']);
      }
    });
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  setPassword(payload: ResetPasswordPayload) {
    this.isFetching = true;
    this.error = '';
    let setPasswordPayload = {
      ConfirmPassword: payload.Password,
      NewPassword: payload.ConfirmPassword,
    };
    this.accountService
      .setNewPassword(setPasswordPayload, this.setPasswordToken)
      .subscribe(() => {
        this.isFetching = false;
        this.user.username = this.email;
        this.user.password = payload.Password
        this.authFacade.authenticate(this.user);
      },
      (error) => {
        this.isFetching = false
      });
  }
}
