
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Creative, BulkUpdateResponse, CreativeUploadResponse, CreativeLabelMap, CreativeUnlabeledMap } from '../../shared/interfaces/creative.interface';
import { createUploadHeaders} from '../helpers/createHeaders';
import * as moment from 'moment';
import { StateService } from '../state/state.service';
import { AdEvent, CreativePayload, CreativeVariation, CreativeVariationNodeMetadata, FormattedCreativeVariationNodeMetadata,
  IndirectCreativePayload, CreativeEvent } from '../../shared/interfaces';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { NumberKeyStringValue, StringKeyNumberValue, AmbiguousData } from '../../shared/interfaces/common.interface';

@Injectable()
export class CreativeService {
  public apiClient = <ApiClient>{};
  unlabeledName = 'Unlabeled';
  maxActiveCallouts = 15;
  segmentFilters = <{
    All: {
      OperatorType: 0,
      SetName: string[]
    },
    Include: {
      OperatorType: 1,
      SetName: string[]
    },
    Exclude: {
      OperatorType: 2,
      SetName: string[]
    },
    [key: string]: {
      OperatorType: number,
      SetName: string[]
    }
  }>{};
  // var
  imageFormats = [
    'image/gif',
    'image/jpg',
    'image/jpeg',
    'image/png'
  ];

  // Ad Types
  adTypes: NumberKeyStringValue = {
    0: 'None',
    1: 'Banner Ad',
    2: 'HTML5',
    3: 'Video'
  };

  creativeTypes: NumberKeyStringValue = {
    1: 'Image Creative',
    2: 'HTML5 Creative',
    3: 'Video Creative'
  };

  eventTypes: StringKeyNumberValue = {
    'None': 0,
    'Hover': 1,
    'Click': 2,
    'none': 0,
    'hover': 1,
    'click': 2,
  };

  jobStatus = [
    { id: 0, value: 'None', msg: 'None', image: './assets/img/job-status-1.jpg' },
    { id: 1, value: 'NotStarted', msg: 'Not Started', image: './assets/img/job-status-1.jpg' },
    { id: 2, value: 'Started', msg: 'Job Started', image: './assets/img/job-status-2.jpg' },
    { id: 3, value: 'Failed', msg: 'Job Failed', image: './assets/img/job-status-3.jpg' },
    { id: 4, value: 'Completed', msg: 'Job Completed', image: './assets/img/loader.gif' },
    { id: 5, value: 'InProgress', msg: 'Job In Progress', image: './assets/img/job-status-6.jpg' }
  ];

  // Todo: remove instances of creativeDimensions and uses creativeSizes instead
  creativeDimensions = [
    { Name: '336 x 280', Type: 'Lg Rectangle', Width: 336, Height: 280 },
    { Name: '300 x 250', Type: 'Med. Rectangle', Width: 300, Height: 250 },
    { Name: '180 x 150', Type: 'Sm. Rectangle', Width: 180, Height: 150 },
    { Name: '728 x 90', Type: 'Leaderboard', Width: 728, Height: 90 },
    { Name: '120 x 600', Type: 'Skyscraper', Width: 120, Height: 600 },
    { Name: '160 x 600', Type: 'Wide Skyscraper', Width: 160, Height: 600 },
    { Name: '300 x 600', Type: 'Half Page', Width: 300, Height: 600 },
    { Name: '320 x 100', Type: 'Lg Mobile Leaderboard', Width: 320, Height: 100 },
    { Name: '320 x 50', Type: 'Mobile Leaderboard', Width: 320, Height: 50 },
    { Name: '120 x 60', Type: 'Button', Width: 120, Height: 60 },
    { Name: '88 x 31', Type: 'MicroBar', Width: 88, Height: 31 },
    { Name: '970 x 250', Type: 'Billboard', Width: 970, Height: 250 },
    { Name: 'Other...', Type: 'Custom', Width: null, Height: null }
  ];

  creativeSizes = [
    { Name: '88 x 31', Width: 88, Height: 31 },
    { Name: '120 x 60', Width: 120, Height: 60 },
    { Name: '120 x 90', Width: 120, Height: 90 },
    { Name: '120 x 240', Width: 120, Height: 240 },
    { Name: '120 x 600', Width: 120, Height: 600 },
    { Name: '125 x 125', Width: 125, Height: 125 },
    { Name: '160 x 600', Width: 160, Height: 600 },
    { Name: '180 x 150', Width: 180, Height: 150 },
    { Name: '200 x 200', Width: 200, Height: 200 },
    { Name: '200 x 446', Width: 200, Height: 446 },
    { Name: '220 x 90', Width: 220, Height: 90 },
    { Name: '234 x 60', Width: 234, Height: 60 },
    { Name: '240 x 133', Width: 240, Height: 133 },
    { Name: '240 x 400', Width: 240, Height: 400 },
    { Name: '250 x 250', Width: 250, Height: 250 },
    { Name: '292 x 30', Width: 292, Height: 30 },
    { Name: '300 x 31', Width: 300, Height: 31 },
    { Name: '300 x 50', Width: 300, Height: 50 },
    { Name: '300 x 100', Width: 300, Height: 100 },
    { Name: '300 x 250', Width: 300, Height: 250 },
    { Name: '300 x 600', Width: 300, Height: 600 },
    { Name: '320 x 50', Width: 320, Height: 50 },
    { Name: '320 x 100', Width: 320, Height: 100 },
    { Name: '336 x 280', Width: 336, Height: 280 },
    { Name: '468 x 60', Width: 468, Height: 60 },
    { Name: '720 x 300', Width: 720, Height: 300 },
    { Name: '728 x 90', Width: 728, Height: 90 },
    { Name: '970 x 66', Width: 970, Height: 66 },
    { Name: '970 x 90', Width: 970, Height: 90 },
    { Name: '970 x 250', Width: 970, Height: 250 },
    { Name: '240 x 1200', Width: 240, Height: 1200 },
    { Name: '600 x 500', Width: 600, Height: 500 },
    { Name: '600 x 1200', Width: 600, Height: 1200 },
    { Name: '672 x 560', Width: 672, Height: 560 },
    { Name: '1456 x 180', Width: 1456, Height: 180 }
  ];

  creativeSizes2 = [
    { label: 'Please Select', value: '', Width: 0, Height: 0 },
    { label: '88 x 31', value: '88 x 31', Width: 88, Height: 31 },
    { label: '120 x 60', value: '120 x 60', Width: 120, Height: 60 },
    { label: '120 x 90', value: '120 x 90', Width: 120, Height: 90 },
    { label: '120 x 240', value: '120 x 240', Width: 120, Height: 240 },
    { label: '120 x 600', value: '120 x 600', Width: 120, Height: 600 },
    { label: '125 x 125', value: '125 x 125', Width: 125, Height: 125 },
    { label: '160 x 600', value: '160 x 600', Width: 160, Height: 600 },
    { label: '180 x 150', value: '180 x 150', Width: 180, Height: 150 },
    { label: '200 x 200', value: '200 x 200', Width: 200, Height: 200 },
    { label: '200 x 446', value: '200 x 446', Width: 200, Height: 446 },
    { label: '220 x 90', value: '220 x 90', Width: 220, Height: 90 },
    { label: '234 x 60', value: '234 x 60', Width: 234, Height: 60 },
    { label: '240 x 133', value: '240 x 133', Width: 240, Height: 133 },
    { label: '240 x 400', value: '240 x 400', Width: 240, Height: 400 },
    { label: '250 x 250', value: '250 x 250', Width: 250, Height: 250 },
    { label: '292 x 30', value: '292 x 30', Width: 292, Height: 30 },
    { label: '300 x 31', value: '300 x 31', Width: 300, Height: 31 },
    { label: '300 x 50', value: '300 x 50', Width: 300, Height: 50 },
    { label: '300 x 100', value: '300 x 100', Width: 300, Height: 100 },
    { label: '300 x 250', value: '300 x 250', Width: 300, Height: 250 },
    { label: '300 x 600', value: '300 x 600', Width: 300, Height: 600 },
    { label: '320 x 50', value: '320 x 50', Width: 320, Height: 50 },
    { label: '320 x 100', value: '320 x 100', Width: 320, Height: 100 },
    { label: '336 x 280', value: '336 x 280', Width: 336, Height: 280 },
    { label: '468 x 60', value: '468 x 60', Width: 468, Height: 60 },
    { label: '720 x 300', value: '720 x 300', Width: 720, Height: 300 },
    { label: '728 x 90', value: '728 x 90', Width: 728, Height: 90 },
    { label: '970 x 66', value: '970 x 66', Width: 970, Height: 66 },
    { label: '970 x 90', value: '970 x 90', Width: 970, Height: 90 },
    { label: '970 x 250', value: '970 x 250', Width: 970, Height: 250 },
    { label: '240 x 1200', value: '240 x 1200', Width: 240, Height: 1200 },
    { label: '600 x 500', value: '600 x 500', Width: 600, Height: 500 },
    { label: '600 x 1200', value: '600 x 1200', Width: 600, Height: 1200 },
    { label: '672 x 560', value: '672 x 560', Width: 672, Height: 560 },
    { label: '1456 x 180', value: '1456 x 180', Width: 1456, Height: 180 }
  ];



  constructor(private stateService: StateService, ) { }

  getDataUri(url: string, callback: Function) {
    const image = new Image();

    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.naturalWidth; // or 'width' if you want a special/scaled size
      canvas.height = image.naturalHeight; // or 'height' if you want a special/scaled size
      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(image, 0, 0);
      }

      // Get raw image data
      callback(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));

      // ... or get as Data URI
      callback(canvas.toDataURL('image/png'));
    };
    image.src = url;
  }


  searchByMetadata(id: string, dataObj: CreativePayload): Observable<Creative> {
    return this.apiClient.callHttpPutWithResponse({path: `/advertisers/${id}/creatives/metadata/search`, param: dataObj});
  }

  bulkCreativesPost(advertiserId: string, tranCreativeId: string,
    variationId: string, dataArray: string[]): Observable<BulkUpdateResponse> {
    return this.apiClient.callHttpPost({path: `/advertisers/${advertiserId}/creatives/${tranCreativeId}/bulkupdate/${variationId}`,
    param: dataArray});
  }

  /**
         * @ngdoc method
         * @name postFormatCreatives
         * @methodOf app.home.creativesService
         * @description Ad adType to creative
         *
         * @param {string} cid CID
         * @returns {deferred} GET Direct Creatives
         */
  // Todo: rename and transform get response
  postFormatCreatives(creatives: Creative[]) {
    return creatives.map((creative) => {
      creative.AdType = this.adTypes[creative.CreativeType] || this.adTypes[0];
      return creative;
    });
  }

  /**
   * @ngdoc method
   * @name getCreatives
   * @methodOf app.home.creativesService
   * @description Get all creatives (Deprecated)
   *
   * @param {string} cid CID
   * @returns {deferred} GET Direct Creatives
   */

  getCreatives(cid: string) {
    let api_url = `/creatives`;
    if (cid) {
      api_url = `/creatives/${cid}`;
    }
    return this.apiClient.callHttpGet({path: api_url});
  }

  /**
   * getIndirectCreatives
   * @description
   * @param cid
   * @returns {*}
   */
  getIndirectCreatives(id: string, cid?: string): Observable<Creative[]> {
    let api_url = `/${id}/creatives`;
    if (cid) {
      api_url = `/${id}/creatives/${cid}`;
    }
    return this.apiClient.callHttpGet({path: api_url});
  }

  getIndirectCreative(id: string, cid: string): Observable<Creative> {
    return this.apiClient.callHttpGet({path: `/${id}/creatives/${cid}`});
  }

  /**
   * uploadCreatives
   * @param payload
   * @param id
   * @param cid
   * @returns {*}
   */
  uploadCreatives(payload: FormData, id: string, cid: string): Observable<CreativeUploadResponse> {
    let api_url = `/advertisers/${id}/creatives/upload`;
    if (cid) {
      api_url = `/advertisers/${id}/creatives/${cid}/upload`;
    }
    return this.apiClient.callHttpPost({path: api_url, param: payload, headers: createUploadHeaders()});
  }

  /**
   * editCreative
   * @param payload
   * @param id
   * @returns {*}
   */
  editCreative(payload: CreativePayload, cid: string): Observable<Creative> {
    return this.apiClient.callHttpPut({path: `/creatives/${cid}`, param: payload});
  }

  editIndirectCreative(payload: Partial<IndirectCreativePayload>, id: string, cid: string): Observable<Creative> {
    return this.apiClient.callHttpPut({path: `/${id}/creatives/${cid}`, param: payload});
  }

  /**
   * deleteCreative
   * @param id
   * @returns {*}
   */
  deleteCreative(id: string): Observable<void> {
    return this.apiClient.callHttpDelete({path: `/creatives/${id}`});
  }

  deleteIndirectCreative(id: string, cid: string): Observable<void> {
    return this.apiClient.callHttpDelete({path: `/${id}/creatives/${cid}`});
  }

  /**
   * getCreativeVariation
   * @param cid
   * @param vid
   * @returns {*}
   */
  getCreativeVariation(cid: string, vid: string) {
    let api_url = `/creatives`;

    if (cid && !vid) {
      api_url = `/creatives/${cid}/${cid}`;
    } else if (vid) {
      api_url = `/creatives/${cid}/${vid}`;
    }
    const keyname = 'variation_' + vid;
    const cached = this.stateService.get(keyname);
    if (cached.data !== null) {
      return cached;
    }

    return this.apiClient.callHttpGet({path: api_url}).pipe(
      tap(data => {
        moment(data['CreatedOn']).format('M/D/YY h:mmA');
      }));
  }

  getIndirectCreativeVariation(payload: CreativePayload): Observable<CreativeVariation> {
    let api_url = `/${payload.advertiserId}/creatives`;

    if (payload.creativeId && !payload.variationId) {
      api_url = `/${payload.advertiserId}/creatives/${payload.creativeId}/${payload.creativeId}`;
    } else if (payload.variationId) {
      api_url = `/${payload.advertiserId}/creatives/${payload.creativeId}/${payload.variationId}`;
    }

    return this.apiClient.callHttpGet({path: api_url});
  }

  getSnapshot(id: string, payload: string[]): Observable<AmbiguousData> {
    return this.apiClient.callHttpPost({path: `/${id}/creatives/variations/snapshotUris`, param: payload, type: ''});
  }

  /**
   * getCreativeDownloadLink
   * @description
   */
  getCreativeDownloadLink(id: string, cid: string): string {
    return `/${id}${cid}/${cid}/download`;
  }

  /**
   * getJobStatus
   * @param jid
   * @returns {*}
   */
  getJobStatus(): Observable<AdEvent[]> {
    return this.apiClient.callHttpGet({path: `/events`});
  }

  segmentCreativeNodes(creative: Creative, variation: CreativeVariation) {
    const nodeData: CreativeLabelMap[] = creative.LabelMap || [];
    const meteData = (variation.MetaData && variation.MetaData.NodeMetadata.length) ?
      variation.MetaData.NodeMetadata : [];
    const formattedMeta = this.getVariationNodeMetaDefs(meteData);

    const result = nodeData.reduce((memo: CreativeLabelMap[], node) => {
    const nodeId = node.NodeLabelMapEntryId;

    if (nodeId && nodeId.length) { // If valid node Id
      if (formattedMeta[nodeId]) { // If found in variation meta
        node = {...node, Dimensions: formattedMeta[nodeId].Dimensions};
        memo.push(node);
      }
    }
    return memo;
    }, []);

    return result;
  }

  getVariationNodeMetaDefs(meta: CreativeVariationNodeMetadata[]) {
    let result: FormattedCreativeVariationNodeMetadata = <FormattedCreativeVariationNodeMetadata>{};
    result = meta.reduce((memo: FormattedCreativeVariationNodeMetadata, row) => {
      if (row.Id) {
        memo[row.Id] = row;
      } else if (row.ClassName) {
        memo[row.ClassName] = row;
      } else {
        memo[row.NodeName] = row;
      }
      return memo;
    }, <FormattedCreativeVariationNodeMetadata>{});
    return result;
  }

  filterEventData(events: CreativeEvent[], eventType: number) {
    let result = [];
    let totalEventCount = 0;
    eventType = this.eventTypes[eventType];
    result = events.reduce((memo: CreativeEvent[], event) => {
      if (event.Layer === eventType) {
        memo.push(event);
        totalEventCount += event.EventCount;
      }
      return memo;
    }, []);

    return { totalEvents: totalEventCount, nodeList: result };
  }

  countTotalActiveEvents(data: CreativeLabelMap[]) {
    const result = data.reduce((memo: CreativeLabelMap[], d) => {
      if (d.IsActive) {
        memo.push(d);
      }
      return memo;
    }, []);
    return result.length || 0;
  }

  setUnlabeledMap(): CreativeUnlabeledMap {
    return {
      NodeLabelMapEntryId: this.unlabeledName,
      Label: this.unlabeledName,
      Selector: this.unlabeledName,
      IsActive: false,
      Valid: true,
      Dimensions: { Width: 1, Height: 1, X: 0, Y: 0 },
      Event: { EventCount: 0 }
    };
  }

  sortByEvent(arr: CreativeLabelMap[], val: string, desc: boolean) {
    const result = arr.sort((a, b) => {
      const aEvent = a.Event[val];
      const bEvent = b.Event[val];

      if (desc) {
        return (aEvent > bEvent) ? -1 : (aEvent < bEvent) ? 1 : 0;
      }
      return (aEvent > bEvent) ? 1 : (aEvent < bEvent) ? -1 : 0;

    });
    return result;
  }

  activateTopEvents(evt: CreativeLabelMap, i: number) {
    if (i < 10 && evt.NodeLabelMapEntryId !== this.unlabeledName) {
      evt.IsActive = true;
    }
    return evt;
  }

  setCreativeMetaPayload(data: CreativeLabelMap[]) {
    return data.reduce((memo: Partial<CreativeLabelMap>[], row) => {
      if (row.NodeLabelMapEntryId === this.unlabeledName) {
        return memo;
      }

      memo.push({
        NodeLabelMapEntryId: row.NodeLabelMapEntryId,
        Label: row.Label,
        Selector: row.Selector,
        IsActive: row.IsActive
      });
      return memo;
    }, []);
  }
}
