import { Injectable } from '@angular/core';
import { isDate, isNumber, isObject } from 'lodash-es';

@Injectable()
export class ConfigService {
  jobStatus = [
    { id: 0, value: 'None', msg: 'None', image: './assets/img/job-status-1.jpg' },
    { id: 1, value: 'NotStarted', msg: 'Not Started', image: './assets/img/job-status-1.jpg' },
    { id: 2, value: 'Started', msg: 'Job Started', image: './assets/img/job-status-2.jpg' },
    { id: 3, value: 'Failed', msg: 'Job Failed', image: './assets/img/job-status-3.jpg' },
    { id: 4, value: 'Completed', msg: 'Job Completed', image: './assets/img/loader.gif' },
    { id: 5, value: 'InProgress', msg: 'Job In Progress', image: './assets/dist/img/job-status-6.jpg' }
  ];

  creativeDimensions = [
    { Name: 'Lg Rectangle - 336 x 280', Type: 'LgRect', Width: 336, Height: 280 },
    { Name: 'Med. Rectangle - 300 x 250', Type: 'MedRect', Width: 300, Height: 250 },
    { Name: 'Sm. Rectangle - 180 x 150', Type: 'SmRect', Width: 180, Height: 150 },
    { Name: 'Leaderboard - 728 x 90', Type: 'Lb', Width: 728, Height: 90 },
    { Name: 'Skyscraper - 120 x 600', Type: 'Sky', Width: 120, Height: 600 },
    { Name: 'Wide Skyscraper - 160 x 600', Type: 'WdSky', Width: 160, Height: 600 },
    { Name: 'Half Page - 300 x 600', Type: 'HP', Width: 300, Height: 600 },
    { Name: 'Lg Mobile Leaderboard - 320 x 100', Type: 'LgMobile', Width: 320, Height: 100 },
    { Name: 'Mobile Leaderboard - 320 x 50', Type: 'Mobile', Width: 320, Height: 50 },
    { Name: 'Button - 120 x 60', Type: 'Button', Width: 120, Height: 60 },
    { Name: 'MicroBar - 88 x 31', Type: 'Micro', Width: 88, Height: 31 },
    { Name: 'Billboard - 970 x 250', Type: 'Bb', Width: 970, Height: 250 },
    { Name: 'Other...', Type: 'Custom', Width: null, Height: null }
  ];

  adTypes = {
    0: 'None',
    1: 'Banner Ad',
    2: 'HTML5',
    3: 'Video'
  };

  eventTypes = {
    0: 'None',
    1: 'Hover',
    2: 'Click'
  };

  validation = {
    isNotEmpty: (str: string) => {
      return (str && str.length) ? true : false;
    },
    isAlphaNumeric: (str: string) => {
      const pattern = /[A-Za-z0-9_.\-]/g;
      return pattern.test(str);
    },
    isNumeric: (str: string) => {
      return isNumber(str);
    },
    isDate: (str: string) => {
      return isDate(str);
    },
    isObject: (obj: string) => {
      return isObject(obj);
    },
    isEmail: (str: string) => {
      const pattern = /[A-Za-z0-9_\-.]+@+[A-Za-z0-9_\-.]+/g;
      return pattern.test(str);
    },
    isPassword: (str: string) => {
      return (str && str.length > 7);
    }
  };
  util = {
    isIE: () => {
      const ua = window.navigator.userAgent.toLowerCase();
      const apps = ['MSIE', 'Trident/', 'Edge/'];
      let result = false;

      apps.forEach((app) => {
        if (ua.indexOf(app.toLowerCase()) > -1) {
          result = true;
        }
      });
      return result;
    }
  };

  constructor() { }
}
