import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModuleConfig } from '../../interfaces/common.interface';
import { EntityMap } from '../entity/entity-metadata';
import { AdvertiserService } from '@services/advertiser/advertiser.service';
import { AdvertiserPayload } from '../../interfaces';
import { CommonUtilityService } from '@services/utility/common-utility.service';

@Injectable()
export class ModuleConfigDataService extends DefaultDataService<ModuleConfig> {
  payload?: AdvertiserPayload;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private accountService: AdvertiserService,
  ) {
    super(EntityMap.ModuleConfig, http, httpUrlGenerator);
  }

  getAll(): Observable<ModuleConfig[]> {
    return this.accountService.config(
      this.payload as AdvertiserPayload).pipe(
        map((config) => config.map(data => CommonUtilityService.timestampStateData(data))));
  }
}
