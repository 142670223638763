import { UntypedFormControl } from '@angular/forms';
import {
  PercentChangeType,
  SummaryAggregationType,
} from '../../../../../../shared/interfaces/d3-charts.interface';
import {
  DashboardConfig,
  DurationType,
  UnitType,
} from '../../../../interfaces';

export interface ChartV2DataSourceFilterColumnComponentForm {
  accountIdColumn: UntypedFormControl;
  locationIdColumn: UntypedFormControl;
  dateColumn: UntypedFormControl;
}

export interface ChartV2DataSourceAggregationComponentForm {
  aggregationType: UntypedFormControl;
  groupByColumns: UntypedFormControl;
  averageNumeratorColumn: UntypedFormControl;
  averageDenominatorColumn: UntypedFormControl;
}

export interface ChartV2DataSourceComponentForm {
  schema: UntypedFormControl;
  table: UntypedFormControl;
  filterColumns: ChartV2DataSourceFilterColumnComponentForm;
  metricColumn: UntypedFormControl;
  aggregation: ChartV2DataSourceAggregationComponentForm;
}

export interface ChartV2ColumnPieDataSourceComponentForm {
  schema: UntypedFormControl;
  table: UntypedFormControl;
  filterColumns: ChartV2DataSourceFilterColumnComponentForm;
  columns: ChartV2ColumnPieDataSourceColumn[];
  groupByColumns: UntypedFormControl;
}

export interface ChartV2LineChartSeriesComponentForm {
  title: UntypedFormControl;
  dataSource: ChartV2DataSourceComponentForm;
}

export interface ChartV2DataTableDataSourceColumnAggregation {
  aggregationType: UntypedFormControl;
  averageNumeratorColumn: UntypedFormControl;
  averageDenominatorColumn: UntypedFormControl;
  isRatio: UntypedFormControl;
}

export interface ChartV2ColumnPieDataSourceColumn {
  columnName: UntypedFormControl;
  aggregation: ChartV2DataTableDataSourceColumnAggregation;
  displayColumnName: UntypedFormControl;
}

export interface ChartV2DataTableDataSourceColumn {
  columnName: UntypedFormControl;
  unitType: UntypedFormControl;
  durationType: UntypedFormControl;
  aggregation: ChartV2DataTableDataSourceColumnAggregation;
}

export interface ChartV2DataTableDataSourceComponentForm {
  schema: UntypedFormControl;
  table: UntypedFormControl;
  filterColumns: ChartV2DataSourceFilterColumnComponentForm;
  columns: ChartV2DataTableDataSourceColumn[];
  groupByColumns: UntypedFormControl;
}

export interface ChartV2ComponentForm {
  title: UntypedFormControl;
  description: UntypedFormControl;
  chartType: UntypedFormControl;
}

export interface ChartV2LineChartComponentForm extends ChartV2ComponentForm {
  unitType: UntypedFormControl;
  durationType: UntypedFormControl;
  summaryAggregationType: UntypedFormControl;
  percentChangeType: UntypedFormControl;
  series: ChartV2LineChartSeriesComponentForm;
}

export interface ChartV2NumberCardComponentForm extends ChartV2ComponentForm {
  unitType: UntypedFormControl;
  durationType: UntypedFormControl;
  percentChangeType: UntypedFormControl;
  dataSource: ChartV2DataSourceComponentForm;
}

export interface ChartV2DataTableComponentForm extends ChartV2ComponentForm {
  dataSource: ChartV2DataTableDataSourceComponentForm;
}

export interface ChartV2PieChartComponentForm extends ChartV2ComponentForm {
  unitType: UntypedFormControl;
  durationType: UntypedFormControl;
  percentChangeType: UntypedFormControl;
  dataSource: ChartV2ColumnPieDataSourceComponentForm;
}

export interface ChartV2ColumnChartComponentForm extends ChartV2ComponentForm {
  unitType: UntypedFormControl;
  durationType: UntypedFormControl;
  summaryAggregationType: UntypedFormControl;
  percentChangeType: UntypedFormControl;
  dataSource: ChartV2ColumnPieDataSourceComponentForm;
}

export type ChartV2ComponentFormTypes =
  | ChartV2LineChartComponentForm
  | ChartV2NumberCardComponentForm
  | ChartV2DataTableComponentForm
  | ChartV2ColumnChartComponentForm
  | ChartV2PieChartComponentForm;

export interface ChartDataSourceAggregation {
  AggregationType: DataSourceAggregationType;
  GroupByColumns?: string[];
  AverageNumeratorColumn?: string;
  AverageDenominatorColumn?: string;
}

export interface ColumnAggregation {
  AggregationType: DataSourceAggregationType;
  AverageNumeratorColumn?: string;
  AverageDenominatorColumn?: string;
  IsRatio?: boolean;
}

export interface ChartDataSourceFilterColumn {
  AccountIdColumn: string;
  LocationIdColumn?: string;
  DateColumn?: string;
}

export interface ChartDataSource {
  Schema: string;
  Table: string;
  FilterColumns: ChartDataSourceFilterColumn;
  MetricColumn?: string;
  Aggregation: ChartDataSourceAggregation;
}

export interface DataTableDataSourceColumn {
  ColumnName: string;
  DisplayName?: string;
  UnitType: UnitType;
  DurationType: DurationType;
  Aggregation: ColumnAggregation;
}

export interface ColumnPieChartDataSourceColumn {
  ColumnName: string;
  Aggregation: ColumnAggregation;
  DisplayColumnName?: string;
}

export interface DataTableDataSource {
  Schema: string;
  Table: string;
  FilterColumns: ChartDataSourceFilterColumn;
  Columns: DataTableDataSourceColumn[];
  GroupByColumns: string[];
}

export interface ColumnPieChartDataSource {
  Schema: string;
  Table: string;
  FilterColumns: ChartDataSourceFilterColumn;
  Columns: ColumnPieChartDataSourceColumn[];
}

export interface DataSeriesV2 {
  Id?: string;
  Title: string;
  DataSource: ChartDataSource;
}

export interface ChartV2 extends DashboardConfig {
  Description: string;
  Type: ChartV2Type;
}

export interface ChartV2LineChart extends ChartV2 {
  Type: ChartV2Type.LineChart;
  UnitType: UnitType;
  DurationType: DurationType;
  SummaryAggregationType: SummaryAggregationType;
  PercentChangeType: PercentChangeType;
  Series: DataSeriesV2;
}

export interface ChartV2NumberCard extends ChartV2 {
  Type: ChartV2Type.NumberCard;
  UnitType: UnitType;
  DurationType: DurationType;
  PercentChangeType: PercentChangeType;
  DataSource: ChartDataSource;
}

export interface ChartV2DataTable extends ChartV2 {
  Type: ChartV2Type.DataTable;
  DataSource: DataTableDataSource;
}

export interface ChartV2ColumnChart extends ChartV2 {
  Type: ChartV2Type.ColumnChart;
  UnitType: UnitType;
  DurationType: DurationType;
  SummaryAggregationType: SummaryAggregationType;
  PercentChangeType: PercentChangeType;
  DataSource: ColumnPieChartDataSource;
}

export interface ChartV2PieChart extends ChartV2 {
  Type: ChartV2Type.PieChart;
  UnitType: UnitType;
  DurationType: DurationType;
  PercentChangeType: PercentChangeType;
  DataSource: ColumnPieChartDataSource;
}

export type ChartV2DataTypes =
  | ChartV2LineChart
  | ChartV2NumberCard
  | ChartV2DataTable
  | ChartV2ColumnChart
  | ChartV2PieChart;

export interface DashboardSectionV2 extends DashboardConfig {
  Charts: ChartV2DataTypes[];
}

export interface DashboardPageV2 extends DashboardConfig {
  Sections: DashboardSectionV2[];
}

export enum ChartV2Type {
  LineChart = 0,
  NumberCard = 1,
  ColumnChart = 2,
  PieChart = 3,
  DataTable = 4,
}

export enum DataSourceAggregationType {
  None = 0,
  Count = 1,
  Sum = 2,
  Average = 3,
  CountDistinct = 4
}
