import { Injectable } from '@angular/core';

@Injectable()
export class AnalyticsUtilityService {

  arrayCTRCalculate(clicks: number[], impressions: number[]) {
    const result: number[] = [];
    if (clicks.length > 0 && impressions.length > 0) {
      for (let c = 0; c < clicks.length; c++) {
        result.push(this.roundNumber((clicks[c] / impressions[c]) * 100) || 0);
      }
    }

    return result;
  }

  arrayCPMCalculate(spend: number[], impressions: number[]) {
    const result: number[] = [];
    if (spend.length > 0 && impressions.length > 0) {
      for (let c = 0; c < spend.length; c++) {
        result.push(this.roundNumber((spend[c] / impressions[c]) * 1000));
      }
    }

    return result;
  }

  arrayCPCCalculate(spend: number[], clicks: number[]) {
    const result: number[] = [];
    if (spend.length > 0 && clicks.length > 0) {
      for (let c = 0; c < spend.length; c++) {
        result.push(this.roundNumber((spend[c] / clicks[c])));
      }
    }

    return result;
  }

  arrayCPACalculate(spend: number[], actions: number[]) {
    const result: number[] = [];
    if (spend.length > 0 && actions.length > 0) {
      for (let c = 0; c < spend.length; c++) {
        result.push(this.roundNumber((spend[c] / actions[c])));
      }
    }

    return result;
  }

  arrayCostConvCalculate(spend: number[], conversion: number[]) {
    const result: number[] = [];
    if (spend.length > 0 && conversion.length > 0) {
      for (let c = 0; c < spend.length; c++) {
        result.push(this.roundNumber((spend[c] / conversion[c])));
      }
    }

    return result;
  }

  arrayConvRateCalculate(conversions: number[], clicks: number[]) {
    const result: number[] = [];
    if (conversions.length > 0 && clicks.length > 0) {
      for (let c = 0; c < conversions.length; c++) {
        result.push(this.roundNumber((conversions[c] / clicks[c]) * 100));
      }
    }

    return result;
  }

  private roundNumber(num: number) {
    return Math.round(num * 100) / 100;
  }
}
