import { Action } from '@ngrx/store';
import { ResourceClaim } from '../../interfaces/claim.interface';

export const GET_PUSH_CLAIMS = 'Push Get Claims';
export const GET_PUSH_CLAIMS_SUCCESS = 'Push Get Claims success';
export const GET_PUSH_CLAIMS_FAIL = 'Push Get Claims fail';

export class GetPushClaim implements Action {
  readonly type = GET_PUSH_CLAIMS;
  constructor(public payload: string) {}
}

export class GetPushClaimSuccess implements Action {
  readonly type = GET_PUSH_CLAIMS_SUCCESS;
  constructor(public payload: ResourceClaim[]) {}
}

export class GetPushClaimFail implements Action {
  readonly type = GET_PUSH_CLAIMS_FAIL;
  constructor(public payload?: string) {}
}

export type All = GetPushClaim | GetPushClaimSuccess | GetPushClaimFail;
