import { Injectable } from '@angular/core';
import {orderBy} from 'lodash-es';

@Injectable()
export class TaskUtilityService {

  extractRecentTasks(notifications: Notification[]) {
    const items = orderBy(notifications, 'LastLoaded', 'desc');
    return items.slice(0, 10);
  }
}
