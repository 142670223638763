
export const environment = {
  production: true,
  apiBase: '#{ManagementApiBase}',
  authBase: '#{ManagementAuthApiBase}',
  gaAccount: '#{ManagementGoogleAccount}',
  signalRAccessCode: '#{ManagementSignalRAccessCode}',
  signalRHost: '#{ManagementSignalRHost}',
  userLocationBase: '#{ManagementLocationBase}',
  launchDarklyClientSideId: '#{ManagementLaunchDarklyClientSideId}',
  geoCoderBase: 'https://transitiv-microservices-geodata.azurewebsites.net',
  environment: '#{ReleaseEnvironment}',
  apiVersion: '/v202107',
  versionCheckURL: '/version.json',
  versionCheckInterval: 30,
  snackBarTimeout: 5000,
  defaultPfr: '#{DefaultPfr}',
  defaultAdServerHost: '#{DefaultAdServerHost}',
  persistentStartDate: '2000-01-01T00:00:00',
  persistentEndDate: '2999-12-31T23:59:59',
  defaultCssFile: 'app.css',
  defaultLogo: './assets/img/TransitivLogoWhite.svg',
  defaultBlackLogo: './assets/img/transitiv.logo-black-text.svg',
  defaultAltLogo: './assets/img/transitiv_logo_white.png',
  defaultFavicon: './assets/img/favicon-transitiv-16x16.png',
  squareLogoWhite: './assets/img/Transitiv-square-icon-white-background.svg',
  defaultPageListCount: 50,
  defaultPageCardCount: 12,
  defaultCustomerPageItemCount: 10000,
  loaderTimeout: 20,
  localStorageExpiry: 1000 * 60 * 60 * 12,
  emptyAccountId: '00000000000000000000000000000000',
  excludedIntegrations: [1700, 1, 4000],
  runnableIntegrations: [1200, 1100, 3700],
  excludedIntegrationCategories: [11],
  demoGroup: [
    '18ad2871911048aea6d5465ac6715b41',
    '4cae13f0d4264f66a60e4bfb0d92252a'
  ],
  demoAccount: [
    '107669ec9d8a47a494b8bca6ff3fadc7',
    'b08761b69e1149939a740e51e20c82ad'
  ],
  // tslint:disable-next-line:max-line-length
  azureBuildHash: '#{AzureBuildHash}'
};
