import { Injectable } from '@angular/core';
import { ApiClient } from '../../shared/interfaces/api-client.interface';

@Injectable()
export class VariationService {
  public apiClient = <ApiClient>{};
  constructor() { }

  getVariation(advertiserId: string, tranCreativeId: string, variationId: string) {
    const variationUrl = `/${advertiserId}/creatives/${tranCreativeId}/${variationId}`;
    return this.apiClient.callHttpGet({path: variationUrl});
  }
}
