<div class="ui-g ui-fluid" *ngIf="gid">
  <div class="ui-g-12 ui-lg-12">
    <!--<div class="card clearfix" id="dashboard-advertisers">
      <h1 *ngIf="allAdvertisers?.length" class="nomargin withbtn pull-left">Advertisers
        <span class="badge badge-pill badge-success bgintitle">
          {{ allAdvertisers.length }}
        </span>
      </h1>
    </div>-->

    <div class="card clearfix" id="page-header">
      <span class="pull-left">
        <div class="ui-inputgroup">
          <app-form-input type="text" placeholder="Search" [(ngModel)]="filter" (keyup)="filterTableData()"></app-form-input>
          <button type="button" class="searchiconbtn"></button>
        </div>
      </span>
    </div>
  </div>
  <div class="ui-g-12 ui-lg-12">
    <div class="card clearfix">
      <ul class="campaign-list">
        <li class="row title-row nomargin-btm">
          <div class="tb_advertiser col-md-3 col-sm-3 col-xs-3 sortable text-left" (click)="sortColumn('AdvertiserName')">
            Account
            <span class="sort">
                <i class="fa"
                  [ngClass]="{
                'fa-sort': sortType !== 'AdvertiserName',
                'fa-sort-desc': sortType === 'AdvertiserName' && sortReverse,
                'fa-sort-asc': sortType === 'AdvertiserName' && !sortReverse }">
                </i>
              </span>
          </div>
        </li>
      </ul>
      <ul>
        <!-- body -->
        <!-- TODO find a sortType PIPE -->
        <!-- <li class="row list-row"
            *ngFor="let campaign of groupCampaigns | orderBy : sortType:sortReverse"> -->
        <li class="row list-row" *ngFor="let advertiser of advertisers | orderBy : sortType:sortReverse">
          <div class="tb_campaign col-md-3 col-sm-groupCampaigns3 col-xs-3">
            <a (click)="navigate(advertiser)">{{ advertiser.Name}}</a>
          </div>
        </li>
        <li class="loading" *ngIf="!advertisers.length && !isLoading">
          <h4>0 Advertisers Found</h4>
        </li>
      </ul>
      <mat-paginator [length]="filterData.length" [pageSize]="rows" (page)="paginate($event)"  *ngIf="allAdvertisers"
        [pageSizeOptions]="[5, 10, 20, 50]"></mat-paginator>
    </div>
  </div>
</div>
