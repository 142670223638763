import { Injectable } from '@angular/core';
import { cloneDeep, each, filter, find, findIndex, keys, map, mapKeys, pick, slice, take } from 'lodash-es';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { UntypedFormGroup } from '@angular/forms';
import { AccountGroup, AttributionBudgetPayload, Assumptions,
  DropdownOption, Creative, ObjectIndexer, Account } from '../../shared/interfaces';
import { environment } from '../../../environments/environment';
import { FieldConfig } from '../../shared/interfaces/field.interface';
import { columnKeyMap, JoinExpression, TableObject, ParsedQueryObject, TableRenderColumn, TableField,
  QueryRule, RuleSet, TableJoin, RuleExpression, QueryFieldRule } from '../../shared/interfaces/tables.interface';
import { Builder } from '../../shared/state/query-builder';
import { PaginationObject, AmbiguousData, ApiError, RawErrorData, StringKeyStringValue, StringKeyStringArrayValue } from '../../shared/interfaces/common.interface';
import { RawSchema, SavedQuery } from '../../shared/interfaces/query.interface';
import { RemovalEffect } from '../../shared/interfaces/analytics.interface';

export interface ErrorObject {
  status: number;
  data?: string;
  Message?: string;
  error_description?: string;
  statusText?: string;
}

@Injectable()
export class UtilityService {

  regex = {
    tabNewLineRegex: /[\n\t]/g,
    extraSpaceRegex: /\s{2,}/g,
    specialCharRegex: /[^A-Za-z0-9_-]/g
  };
  filter = '';
  sqlFilter = '';
  fieldTypes: {[key: string]: string} = {
    nvarchar: 'string',
    int: 'number',
    datetime2: 'date',
    char: 'string',
    varchar: 'string',
    decimal: 'number',
    numeric: 'number',
    bigint: 'number',
    float: 'number',
    bit: 'boolean',
    smalldatetime: 'date',
    DateTime: 'date',
    String: 'string',
    Decimal: 'number',
    Category: 'category',
    Boolean: 'boolean',
  };
  operators: StringKeyStringValue = {
    '=': 'equal', '!=': 'notequal', '>': 'greaterthan', '>=': 'greaterthanorequal', '<': 'lessthan', 'IN': 'in', 'NOT IN': 'notin',
    '<=': 'lessthanorequal', 'BETWEEN': 'between', 'NOT BETWEEN': 'notbetween',
    'LIKE': 'contains', 'IS NULL': 'isnull', 'IS NOT NULL': 'isnotnull', 'IS EMPTY': 'isempty', 'IS NOT EMPTY': 'isnotempty',
    'NOT LIKE': 'notcontains'
  };
  errorMessages: StringKeyStringValue = {
    'invalid_username_or_password': 'Invalid username or password.',
  };
  static isDemoAccount(id: string) {
    return environment.demoAccount.indexOf(id) !== -1;
  }

  static isDemoGroup(groupid: string) {
    return environment.demoGroup.indexOf(groupid) !== -1;
  }

  constructor(private router: Router) {}

  /**
    * @ngdoc method
    * @name errorCallback
    * @methodOf webApp.utilityFactory
    * @description Main error callback for XHR requests.
    *
    * @param {object} data XHR error response
    *
    * @return {string} error status text
    */
  errorCallback(data: ErrorObject) {
    const res = data || {};
    if (res.data) {
      if (typeof (res.data) === 'string') { return res.data; }
      if (res.error_description) { return 'Error: ' + res.error_description; }
      if (res.Message) { return 'Error: ' + res.Message; }
      if (res.statusText) { return 'Error: ' + res.statusText; }
    }
    return 'Error: Sorry there was an error with your request.  Please try again.';
  }

  /**
  * @ngdoc method
  * @name authErrorCallback
  * @methodOf webApp.utilityFactory
  * @description Error callback for authorization/permissions errors
  *
  */
  authErrorCallback(data: ErrorObject) {
    const res = data || {};
    if (res.data && (res.status === 403)) {
      return 'Error: Sorry you do not have the permission to access this feature.';
    }
    return this.errorCallback(data);
  }

  parseError(error: ApiError, trim = true) {
    let message = '';
    if (!message && error.error && (error.error as StringKeyStringValue).error_description) {
      message = this.errorMessages[(error.error as StringKeyStringValue).error_description] || '';
    } else if (error.error && (error.error as RawErrorData).title) {
      if ((error.error as RawErrorData).errors) {
        for (const key in (error.error as RawErrorData).errors) {
          // tslint:disable-next-line: no-non-null-assertion
          if ((error.error as RawErrorData).errors && ((error.error as RawErrorData).errors!)[key]) {
            // tslint:disable-next-line: no-non-null-assertion
            if ((error.error as RawErrorData).errors![key].length) {
              // tslint:disable-next-line: no-non-null-assertion
              message = this.extractErrorMessage((error.error as RawErrorData).errors![key], trim, message);
            }
          }
        }
        message = `${message}`;
      }
    } else if ((error.error as RawErrorData) && (error.error as RawErrorData).Details && (error.error as RawErrorData).Details.length > 0) {
      message = this.extractErrorMessage((error.error as RawErrorData).Details, trim, message);
    } else if (error && error.error && typeof error.error === 'object') {
      const keys = Object.keys(error.error);
      if (keys) {
        keys.forEach(key => {
          if ((error.error as StringKeyStringArrayValue)[key] && (error.error as StringKeyStringArrayValue)[key].length) {
            (error.error as StringKeyStringArrayValue)[key].forEach(item => {
              if (typeof item === 'string') {
                message = `${message}${item}, `;
              }
            });
          }
        });
      }
    } else {
      message = error.error as string;
    }
    message = this.removeLastComa(message);
    return message;
  }

  removeLastComa(text: string) {
    let strVal = text.trim();
    const lastChar = strVal.slice(-1);
    if (lastChar === ',') {
        strVal = strVal.slice(0, -1);
    }
    return strVal;
  }

  private extractErrorMessage(error: string[], trim: boolean, message: string) {
    error.forEach((item: string) => {
      if (trim) {
        if (item.length <= 100) {
          message = `${message} <br /> <b>&#42;</b> ${item}`;
        }
      } else {
        message = `${message} <br /> <b>&#42;</b> ${item}`;
      }
    });
    return message;
  }

   /**
     * @ngdoc method
     * @name string2array
     * @methodOf webApp.utilityFactory
     * @description Converts a string to an array with a specific pattern.
     * This function is mostly used for advertisergroup ParentHierarchyPath.
     *
     * @param {string} string string
     * @param {string} pattern string pattern for example: [-,\_]
     *
     * @return {array} array of strings
     */
  string2array(str: string, pattern: string) {
    return (str) ? str.replace(/\/\B/g, '').split(pattern) : [];
  }

  /**
   * @ngdoc
   * @name generateNameId
   * @methodOf webApp.utilityFactory
   * @description Generates a unique Id based on a
   * name or a string.
   * - Truncates name and adds a timestamp
   *
   * @param {string} str name
   * @return {string} id name prefix + timestamp
   */
  generateNameId(str: string) {
    const name = str.replace(this.regex.specialCharRegex, '');
    const prefix = (name.length > 12) ? name.slice(0, 12) : name;
    return prefix + '-' + new Date().getTime();
  }

  refresh(): void {
    window.location.reload();
  }

  getSubGroups(groups: AccountGroup[], group: AccountGroup) {
    const children = filter(groups, {
      ParentHierarchyPath: group.ParentHierarchyPath + group.GroupId + '/'});
    return children;
  }

  getSubGroupAdvertisers(advertisers: Account[], group: AccountGroup) {
    const children = filter(advertisers, {
      AdvertiserGroupId: group.GroupId});
    return children;
  }

  generateSelectOptionsFromEnum(enumData: any): DropdownOption[] {
    const options: DropdownOption[] = [];
    const vals = [];
    for (const item in enumData) {
      if (isNaN(Number(item))) {
        vals.push(item);
      }
    }
    vals.forEach((val) => {
      const label = val.replace('_', ' ');
      options.push({ label: label, value: enumData[val] });
    });
    return options;
  }

  isMediaType(budgetData: AttributionBudgetPayload, channel: Assumptions|RemovalEffect, id: number) {
    const type = findIndex(budgetData.ChannelData.Filter.ChannelGrouping, {ChannelGrouping: channel.ChannelName, Category: id});
    return type !== -1;
  }

  extractColumns(tableData: AmbiguousData[]) {
    const cols: TableRenderColumn[] = [];
    if (tableData && tableData.length > 0) {
      const objKeys = keys(tableData[0]);
      objKeys.forEach(field => {
        cols.push({ field: field, header: field });
      });
    }
    return cols;
  }

  formatTableData(tableObject: any[], display: StringKeyStringValue, rowId: string = ''): AmbiguousData[] {
    const displayObject = cloneDeep(display);
    for (const key in displayObject) {
      if (displayObject.hasOwnProperty(key)) {
        tableObject = each(tableObject, (value, k) => {
          if (displayObject[key] && displayObject[key] !== '') {
            if (tableObject[k][key]) {
              tableObject[k][displayObject[key]] = tableObject[k][key];
            }
          }
          if (rowId !== '') {
            if (tableObject[k][rowId]) {
              tableObject[k].ID = tableObject[k][rowId];
            }
            displayObject['ID'] = '';
          }
        });
        if (displayObject[key] && displayObject[key] !== '') {
          displayObject[displayObject[key]] = '';
          delete displayObject[key];
        }
      }
    }
    return map(tableObject, (o) =>  {
      return pick(o, keys(displayObject));
    }) as AmbiguousData[];
  }

  postFormatVariationOptions(creatives: Creative[]): Creative[] {
    return creatives.map((creative) => {
      if (creative.Variations) {
        creative.Variations.map((variation, index: number) => {
          const created = moment(variation.CreatedOn).format('M/D/YY');
          variation.Index = index + 1;
          variation.Text = 'v' + variation.Index + ' (' + created + ')';
          return variation;
        });
      }
      return creative;
    });
  }

  generateSQL(state: Builder, filterText = '') {
    let q = 'SELECT ' + filterText + ' ';
    if (state.selectedFields) {
      for (const key in state.selectedFields) {
        if (state.selectedFields[key] && find(state.tables, { alias: key })) {
          state.selectedFields[key].forEach((f) => {
            if (f.aggregate && f.aggregate !== 'NONE') {
              q = q + f.aggregate + '([' + key + '].[' + f.name + ']) ';
            } else {
              q = q + '[' + key + '].[' + f.name + '] ';
            }
            if (f.alias) {
              q = q + 'AS ' + '[' + f.alias + ']';
            }
            q = q + ', ';
          });
        }
      }
      q = q.replace(/,\s*$/, ' ');
    }
    if (state.tables && state.tables.length > 0) {
      q = q + 'FROM ';
      q = q + '[' + state.tables[0].name + '] AS ' + '[' + state.tables[0].alias + '] ';
    }
    if (state.joins && state.joins.length > 0) {
      state.joins.forEach((join) => {
        q = q + join.type + ' JOIN [' + join.destination.table + '] AS [' + join.destination.alias + '] ON ['
          + join.source.alias + '].[' + join.source.name
          + '] = [' + join.destination.alias + '].[' + join.destination.name + '] ';
      });
    }
    if (state.filterQuery) {
      q = q + 'WHERE ' + state.filterQuery;
    }
    if (state.groupBy && state.groupBy.length > 0) {
      q = q + 'GROUP BY ';
      state.groupBy.forEach((group) => {
        q = q + '[' + group.table + '].[' + group.name + '], ';
      });
      q = q.replace(/,\s*$/, ' ');
    }
    if (state.having && state.having.length > 0) {
      q = q + 'HAVING ';
      state.having.forEach((have) => {
        if (have.field && have.field.aggregate && have.field.aggregate !== 'NONE') {
          q = q + have.field.aggregate + '([' + have.field.table + '].[' + have.field.name + ']) ' +
            have.operator + ' ' + have.value + ', ';
        }
      });
      q = q.replace(/,\s*$/, ' ');
    }
    return q;
  }

  parseSchemas(schema: RawSchema[], schemaKeyMap: AmbiguousData) {
    const parsedSchema: TableObject[] = [];
    schema.forEach((table, i) => {
      parsedSchema.push(this.parseSchema(table as unknown as SavedQuery, schemaKeyMap));
    });
    return parsedSchema;
  }

  parseSchema(table: SavedQuery|TableObject, mapkey: AmbiguousData): TableObject {
    let newTable =  <TableObject>{};
    if (table) {
      table = mapKeys(table, (value, key) => {
        return mapkey[key];
      }) as unknown as TableObject;
      newTable = cloneDeep(table);
      newTable.fields = [];
      table.fields.forEach((field) => {
        field = mapKeys(field, (value, key) => {
          return columnKeyMap[key];
        }) as unknown as TableField;
        if (!field.type) {
          field.type = 'string';
        } else {
          field.type = this.fieldTypes[field.type];
        }
        newTable.fields.push(field);
      });
    }
    return newTable;
  }

  convertQueryRuleObject(RuleSets: RuleSet, ruleKeyMap: StringKeyStringValue) {
    RuleSets.condition = RuleSets.Condition ? RuleSets.Condition.toLowerCase() : '';
    RuleSets.rules = RuleSets.RuleSets;
    RuleSets.rulesSets = RuleSets.Rules;
    RuleSets.Rules = [];
    RuleSets.Condition = '';
    RuleSets.RuleSets = []
    if (RuleSets.rulesSets.length > 0) {
      RuleSets.rulesSets.forEach((rule, index: number) => {
        RuleSets.rulesSets[index] = mapKeys(RuleSets.rulesSets[index], (value, key) => {
          return ruleKeyMap[key];
        }) as unknown as RuleExpression;
        RuleSets.rulesSets[index].field = RuleSets.rulesSets[index].field;
        RuleSets.rulesSets[index].label = RuleSets.rulesSets[index].field;
        RuleSets.rulesSets[index].value =
        isNaN(RuleSets.rulesSets[index].value as number) ? RuleSets.rulesSets[index].value : Number(RuleSets.rulesSets[index].value);
        RuleSets.rulesSets[index].type =
        RuleSets.rulesSets[index].type ? RuleSets.rulesSets[index].type
        : (isNaN(RuleSets.rulesSets[index].value as number) ? 'string' : 'number');
        RuleSets.rulesSets[index].operator = this.operators[RuleSets.rulesSets[index].operator] || 'equal';
        RuleSets.rules.push(RuleSets.rulesSets[index] as unknown as RuleSet);
        RuleSets.rulesSets[index] = <RuleExpression>{};
      });
    } else if (RuleSets.rules.length > 0) {
      RuleSets.rules.forEach((rule) => {
        this.convertQueryRuleObject(rule, ruleKeyMap);
      });
    }
  }

  convertAttributeQueryRuleObject(RuleSets: QueryRule) {
    if (RuleSets && RuleSets.rules.length) {
      (RuleSets.rules as QueryRule[]).forEach((rule, i: number) => {
        if (rule.Rules && rule.Rules.length === 2 && (rule.Rules[0].Table === 'att_type' && rule.Rules[1].Table === 'att_val') ||
        rule.rules && rule.rules.length === 2
        && ((rule.rules as QueryFieldRule[])[0].table === 'att_type' && (rule.rules as QueryFieldRule[])[1].table === 'att_val')) {
          RuleSets.condition = rule.Condition ? rule.Condition.toLowerCase() : 'and';
          RuleSets.rules[i] = {
            Aggregate: null,
            field: (rule.Rules ? rule.Rules[0].Value : (rule.rules[0] as QueryFieldRule).value) as string,
            operator: rule.Rules ? rule.Rules[1].Operator : (rule.rules[0] as QueryFieldRule).operator,
            table: rule.Rules ? rule.Rules[0].Table : (rule.rules[0] as QueryFieldRule).table,
            special: true,
            value: (rule.Rules ? rule.Rules[1].Value : (rule.rules[0] as QueryFieldRule).value) as number,
          };
        } else if (rule.rules && rule.rules.length > 0) {
          this.convertAttributeQueryRuleObject(rule);
        }
      });
    }
  }

  editObjectInArray(arr: FieldConfig[], find: {name: string}, replace: FieldConfig) {
    const index = findIndex(arr, find);
    return map(arr, (a, i) => {
      return i === index ? replace : a;
    });
  }

  getFormValue(field: FieldConfig,  group: UntypedFormGroup) {
    if (field.name) {
      const status = group.get(field.name);
      if (status) {
        return status.value;
      }
    }
    return null;
  }

  paginateData(data: any[], pager: PaginationObject): any[] {
    const slicedData = slice(data, pager.pageSize * (pager.pageIndex))
    return take(slicedData, pager.pageSize);
  }

  rehydrateJoins(JoinExpressions: JoinExpression[], selectedTables: TableObject[]) {
    const joins: TableJoin[] = [];
    if (JoinExpressions) {
      JoinExpressions.forEach(joinExpression => {
        const join = <TableJoin>{};
        const leftTable = find(selectedTables, { alias: joinExpression.Predicate.LeftTableAlias });
        if (leftTable) {
          let leftColumn = find(leftTable.fields, { name: joinExpression.Predicate.LeftColumn });
          if (leftColumn) {
            leftColumn = cloneDeep(leftColumn);
            leftColumn.alias = joinExpression.Predicate.LeftTableAlias;
            leftColumn.table = joinExpression.Predicate.LeftTable;
            join.source = leftColumn;
          }
        }
        const rightTable = find(selectedTables, { alias: joinExpression.Predicate.RightTableAlias });
        if (rightTable) {
          let rightColumn = find(rightTable.fields, { name: joinExpression.Predicate.RightColumn });
          if (rightColumn) {
            rightColumn = cloneDeep(rightColumn);
            rightColumn.alias = joinExpression.Predicate.RightTableAlias;
            rightColumn.table = joinExpression.Predicate.RightTable;
            join.destination = rightColumn;
          }
        }
        join.type = joinExpression.Type;
        joins.push(join);
      });
    }
    return joins;
  }

  fixJoins(joins: TableJoin[], parsedQuery: ParsedQueryObject) {
    joins.forEach(join => {
      if (!join.source) {
        const s = find(joins, { source: { table: parsedQuery.FromTable } });
        if (s) {
          join.source = s.source;
        }
      }
    });
    return joins;
  }

  rehydrateTables(queryObject: ParsedQueryObject, selected: TableObject[],
    selectedFields: ObjectIndexer<TableField[]>, availableTables: TableObject[]) {
    if (queryObject && queryObject.JoinExpressions) {
      queryObject.JoinExpressions.forEach(table => {
        const {selectedTables, fields} = this.rehydrateTable(queryObject, table, availableTables, selected, selectedFields);
        selected = selectedTables;
        selectedFields = fields;
      });
    }

    let main = find(availableTables, {name: queryObject.FromTable});
    if (!this.isSelectedTable(selected, queryObject.FromTableAlias) && main) {
      const {mainTable, selectedTables} = this.extractFields(queryObject, main, selected);
      main = mainTable;
      selected = selectedTables;
      selectedFields = this.rehydrateSelectedFields(main, selectedFields, queryObject);
    }

    return {selected, selectedFields};
  }

  extractFields(queryObject: ParsedQueryObject, mainTable: TableObject, selectedTables: TableObject[]) {
    mainTable = {...mainTable, alias: queryObject.FromTableAlias, newAlias: queryObject.FromTableAlias};
    const t = find(selectedTables, { alias: mainTable.alias });
    if (!t) {
      selectedTables.push(mainTable);
    } else {
      const index = findIndex(selectedTables, {alias: mainTable.alias});
      selectedTables.splice(index, 1, mainTable);
    }
    return {mainTable, selectedTables};
  }

  private isSelectedTable(selectedTables: TableObject[], alias: string) {
    return find(selectedTables, { alias: alias });
  }

  private rehydrateTable(queryObject: ParsedQueryObject, table: JoinExpression,
    availableTables: TableObject[], selectedTables: TableObject[], fields: ObjectIndexer<TableField[]>) {
    let selectedTable = find(availableTables, { name: table.Predicate.LeftTable });
    if (selectedTable) {
      selectedTable = cloneDeep(selectedTable);
      selectedTable.alias = table.Predicate.LeftTableAlias;
      selectedTable.newAlias = table.Predicate.LeftTableAlias;
      const t = find(selectedTables, { alias: table.Predicate.LeftTableAlias });
      if (!t) {
        selectedTables.push(selectedTable);
      } else {
        const index = findIndex(selectedTables, {alias: table.Predicate.LeftTableAlias});
        selectedTables.splice(index, 1, selectedTable);
      }
      fields = this.rehydrateSelectedFields(selectedTable, fields, queryObject);
    }
    selectedTable = find(availableTables, { name: table.Predicate.RightTable });
    if (selectedTable) {
      selectedTable = cloneDeep(selectedTable);
      selectedTable.alias = table.Predicate.RightTableAlias;
      selectedTable.newAlias = table.Predicate.RightTableAlias;
      const t = find(selectedTables, { alias: table.Predicate.RightTableAlias });
      if (!t) {
        selectedTables.push(selectedTable);
      } else {
        const index = findIndex(selectedTables, {alias: table.Predicate.RightTableAlias});
        selectedTables.splice(index, 1, selectedTable);
      }
      fields = this.rehydrateSelectedFields(selectedTable, fields, queryObject);
    }
    return {selectedTables, fields};
  }

  private rehydrateSelectedFields(table: TableObject, selectedFields: ObjectIndexer<TableField[]>, queryObject: ParsedQueryObject) {
    selectedFields[table.alias] = [];
    if (queryObject.SelectExpressions.length > 0) {
      selectedFields = this.rehydrateFields(queryObject, table, selectedFields);
    } else if (table.fields) {
      selectedFields = this.rehydrateFieldsFromTable(queryObject, table, selectedFields);
    }

    return selectedFields;
  }

  private rehydrateFields(queryObject: ParsedQueryObject, table: TableObject, selectedFields: ObjectIndexer<TableField[]>) {
    queryObject.SelectExpressions.forEach(field => {
      const fl = field.Expression.split('[').join('').split(']').join('').split('.');
      const f = find(table.fields, { name: fl[1] });
      if (f) {
        selectedFields = this.selectField(queryObject, table, f, selectedFields);
      }
    });

    return selectedFields;
  }

  private rehydrateFieldsFromTable(queryObject: ParsedQueryObject, table: TableObject, selectedFields: ObjectIndexer<TableField[]>) {
    table.fields.forEach((field) => {
      selectedFields = this.selectField(queryObject, table, field, selectedFields);
    });

    return selectedFields;
  }

  private selectField(queryObject: ParsedQueryObject, table: TableObject, field: TableField, selectedFields: ObjectIndexer<TableField[]>) {
    Object.assign({}, field, {table: table.alias});
    const t = find(queryObject.SelectExpressions, (exp) => {
      const fx = exp.Expression.split('[').join('').split(']').join('').split('.');
      return exp.Table === field.table && fx[1] === field.name;
    });
    if (t) {
      const f = t.Expression.split('[').join('').split(']').join('').split('.');
      if (f[1] && f[1] !== t.Column) {
        field.alias = t.Column;
      }
    }
    selectedFields[table.alias].push(field);
    if (field.Aggregate) {
      selectedFields =  this.setAggregate(field, table.alias, field.Aggregate, selectedFields);
    }

    return selectedFields;
  }

  setAggregate(field: TableField, table: string, aggregate = 'NONE', selectedFields: ObjectIndexer<TableField[]>) {
    const i = findIndex(selectedFields[table], {name: field.name});
    if (selectedFields[table][i]) {
      selectedFields[table][i].aggregate = aggregate;
      selectedFields[table][i].table = table;
    }

    return selectedFields;
  }

  rankOrder(array: any[], key: string) {
    let rank = 1;
    for (let i = 0; i < array.length; i++) {
      if (i === 0) {
        array[i].rank = rank;
      } else if (i > 0 && array[i][key] < array[i - 1][key]) {
        rank++;
        array[i].rank = rank;
      } else {
        array[i].rank = 'T-' + rank;
        array[i - 1].rank = 'T-' + rank;
      }
    }
    return array;
  }
}
