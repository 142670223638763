import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChannelGrouping } from '../../../interfaces';
import { EntityMap } from '../../entity/entity-metadata';
import { CommonUtilityService } from '@services/utility/common-utility.service';
import { AnalyticsService } from '@services/analytics/analytics.service';


@Injectable()
export class ChannelGroupingsDataService extends DefaultDataService<ChannelGrouping> {
  accountId?: string
  analyticsService: AnalyticsService|undefined;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super(EntityMap.ChannelGrouping, http, httpUrlGenerator);
  }

  getAll(): Observable<ChannelGrouping[]> {
    return this.analyticsService?.getChannelGroupings(this.accountId as string).pipe(
        map((groupings) => groupings.map(data => CommonUtilityService.timestampStateData(data)))) as Observable<ChannelGrouping[]>;
  }
}
