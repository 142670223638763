<div id="login" class="login-body">
    <div class="login-panel ui-fluid">
      <div class="login-panel-header">
          <img [src]="logo" alt="Transitiv Logo">
      </div>
      <div class="login-panel-content">
      <h2 class="text-center mar-t-30 mar-b-10" id="page-title">You have successfully logged out!</h2>
        <div class="row flush centered">
            <div class="ui-g">
              <div class="ui-g-12 nopadding-top">
                  <app-form-button cssClass="msbtn flatbtn" itemId="btnShowForm"
                  (onClick)="goToLogin()"
                  analytics-on="click"
                  analytics-event="click"
                  analytics-category="LogoutSuccess"
                  analytics-label="Root_Button_GotoLogin"
                  analytics-value="1"> Return to Log In
                </app-form-button>
              </div>
            </div>


                </div>
                </div>
            </div>
    </div>
