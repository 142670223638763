import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  ValidatorFn,
} from '@angular/forms';
import { filter } from 'lodash-es';
import { FieldConfig, Validator } from '../../../../shared/interfaces/field.interface';
import { AmbiguousData } from '../../../../shared/interfaces/common.interface';

@Component({
  selector: 'app-dynamic-form',
  template: ` <div class="_settings" *ngIf="form">
    <form class="dynamic-form" [formGroup]="form" (submit)="onSubmit($event)" (change)="onChange($event)">
      <div class="row ui-fluid nomargin-btm mar-lft--15">
        <div class="col-md-12 form-group nopadding pad-t-15 pad-b-0">
          <div class="row nomargin">
            <ng-container *ngFor="let field of fieldConfig">
              <ng-container
                *ngIf="
                  field.type !== 'button' ||
                  (field.type === 'button' && field.buttonLocation === 'normal')
                "
                dynamicField
                [field]="field"
                [group]="form"
              >
              </ng-container>
            </ng-container>
            <div class="campaign_footer" *ngIf="displayFooter()">
              <ng-container *ngFor="let field of fieldConfig">
                <ng-container
                  *ngIf="
                    field.type === 'button' && field.buttonLocation !== 'normal'
                  "
                  dynamicField
                  [field]="field"
                  [group]="form"
                >
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>`,
  styles: [],
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  fieldConfig: FieldConfig[] = [];

  @Output() submit = new EventEmitter<AmbiguousData>();
  @Output() parentChangeUpdater = new EventEmitter<AmbiguousData>();

  form: UntypedFormGroup|undefined;

  get value() {
    return this.form?.value;
  }

  constructor(private fb: UntypedFormBuilder) {

  }

  ngOnInit() {
    if (this.fieldConfig && this.fieldConfig.length > 0) {
      this.form = this.createControl();
    }
  }

  ngOnDestroy() {
    this.fieldConfig = [];
    this.form = undefined;
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.form && this.form.valid) {
      this.submit.emit(this.form.value);
    } else if (this.form) {
      this.validateAllFormFields(this.form);
    }
  }

  onChange(event: Event) {
    event.preventDefault();
    event.stopPropagation;
    if (this.form && this.parentChangeUpdater) {
      this.parentChangeUpdater.emit(this.form.value)
    }
  }

  @Input() set fields(value: FieldConfig[]) {
    if (value && value.length > 0) {
      this.fieldConfig = [...value];
      this.form = this.createControl();
    }
  }

  get fields(): FieldConfig[] {
    return this.fieldConfig;
  }

  createControl() {
    const group = this.fb.group({});
    this.fieldConfig.forEach((field) => {
      if (field.type === 'button') {
        return;
      }
      const control = this.fb.control(
        field.value,
        this.bindValidations(field.validations || [])
      );
      if (field.name) {
        group.addControl(field.name, control);
      }
    });
    return group;
  }

  bindValidations(validations: Validator[]) {
    if (validations.length > 0) {
      const validList: ValidatorFn[] = [];
      validations.forEach((valid) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  displayFooter() {
    let display = false;
    const button = filter(this.fields, { type: 'button' });
    button.forEach((b) => {
      if (b.buttonLocation !== 'normal') {
        display = true;
      }
    });
    return display;
  }
}
