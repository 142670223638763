import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { AttributionFilter, ChannelGrouping } from '../../interfaces';
import { AttributionBudget, AttributionBudgetAllocation, MarkovResponse } from '../../interfaces/analytics.interface';
import { AnalyticsState } from './analytics.model';

export const actionAnalyticsGetChannelGroupings = createAction(
  '[Analytics] Get Channel Groupings',
  props<{ id: string }>()
);

export const actionAnalyticsGetChannelGroupingsSuccess = createAction(
  '[Analytics] Get Channel Groupings Success',
  props<{ channelGroupings: ChannelGrouping[] }>()
);

export const actionAnalyticsError = createAction(
  '[Analytics] Error',
  props<{ error: HttpErrorResponse, task: AnalyticsState['failedTask'] }>()
);

export const actionAnalyticsPostAttributionFilter = createAction(
  '[Analytics] Post Attribution Filter',
  props<{ id: string, payload: AttributionFilter }>()
);

export const actionAnalyticsPostAttributionFilterSuccess = createAction(
  '[Analytics] Post Attribution Filter Success',
  props<{ markov: MarkovResponse }>()
);

export const actionAnalyticsPostBudgetAllocation = createAction(
  '[Analytics] Post Budget Allocation',
  props<{ id: string, payload: AttributionBudget }>()
);

export const actionAnalyticsPostBudgetAllocationSuccess = createAction(
  '[Analytics] Post Budget Allocation Success',
  props<{ response: AttributionBudgetAllocation }>()
);
