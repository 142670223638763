import { Component, OnInit } from '@angular/core';
import { FieldClass } from '../field/field.class';
@Component({
  selector: 'app-checkbox',
  template: `
  <div class="row mar-b-50 inputfield_" [ngClass]="{
    'is_col_6': field?.size === 'xxxsmall',
    'is_col_5': field?.size === 'xxsmall',
    'is_col_4': field?.size === 'xsmall',
    'is_col_3': field?.size === 'small',
    'is_col_2': field?.size === 'medium', 'haselement': field?.hasElement,
    'is_block_full': field?.isBlock,
    'is_fullwidth': !field?.size || field?.size === 'large'}">
    <app-form-checkbox [name]="field?.name" [id]="field?.id" (onChange)="onChange($event)" [group]="group"
    [label]="field?.label" [value]="field?.value" binary="true"></app-form-checkbox>
  </div>`,
  styles: []
})
export class CheckboxComponent extends FieldClass implements OnInit {
  constructor() {
    super();
  }
  ngOnInit() {}
}
