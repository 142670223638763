export interface SelectableFilterOption {
  name: string;
  isSelected: boolean;
}

export interface SelectedLocationFilters {
  [x: string]: string[] | number[] | boolean;
  selectedFitRange: number[];
  isFilteringByFit: boolean;
  selectedTags: string[];
  selectedRegions: string[];
  selectedAgeRange: number[];
  isFilteringByAge: boolean;
}

export interface SelectedFilterChip {
  type: FilterChipType;
  name: string;
}

export enum FilterChipType {
  Tag = 'Tag',
  Region = 'Region',
  FitRange = 'Fit Range',
  AgeRange = 'Age Range',
  Date = 'Date'
}
