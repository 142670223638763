import { AccountGroup, CreativeLabelMap } from '.';
import {
  AmbiguousData,
  StringKeyNumberValue,
  StringKeyStringValue,
} from './common.interface';
import {
  AttributionBudget,
  AnalyticsDateRange,
} from './analytics.interface';
import { AccountState } from '../state/global';
import { LargeBlockSizeBehavior } from '../models/customer.model';
import { AnalyticTimeRollupDim } from '../models/analytics.model';
import { ConversionFilter } from './conversion.interface';
import { Campaign } from './campaign.interface';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { Account } from './advertiser.interface';

export interface CampaignPayload extends Campaign {
  Persistent: boolean;
  advertiserId: string;
  campaignId: string;
}

export interface AdvertiserPayload {
  advertiserId: string;
  groupId: string;
}

export interface AdPayload {
  advertiserId: string;
  campaignId: string;
  adId?: string;
}

export interface CreativePayload {
  advertiserId: string;
  creativeId: string;
  variationId?: string;
}

export interface IndirectCreativePayload {
  Name: string;
  IsActive: boolean;
  LabelMap: Partial<CreativeLabelMap>[];
  ExternalMetaData?: StringKeyStringValue;
}

export interface MediaAnalyticsPayload {
  advertiserId: string;
  campaignId: string;
  adGroupId?: string;
  timeRollup: AnalyticTimeRollupDim;
  fromDate: Date;
  toDate: Date;
}

export interface AdGroupPayload {
  advertiserId: string;
  campaignId: string;
  adGroupId?: string;
}

export interface TargetingCriterionPayload {
  advertiserId: string;
  targetingCriterionId?: string;
}

export interface AccountPayload {
  groupId: string;
  advertiserId: string;
}

export interface ClaimsPayload {
  groupId: string;
  advertiserId?: string;
  userClaimId?: string;
}

export interface SearchResult {
  AdvertiserGroups: AccountGroup[];
  Advertisers: Account[];
}

export interface AttributionFilter {
  ChannelGrouping?: ChannelGrouping[];
  FromDate: Date | string;
  ToDate: Date | string;
  IncludeSpecificChannelNames: string[];
  ExcludeSpecificChannelNames: string[];
  ExcludeChannelsBelowThreshold: number;
  ExcludeChannelsAboveThreshold: number;
  ConversionFilter?: string;
  TrimCustomerEventsAfterFilter?: boolean;
}

export type AttributionBudgetPayload = AttributionBudget;

export interface AttributionBudgetResponse {
  BudgetDeltas: StringKeyNumberValue;
  ConversionDeltas: StringKeyNumberValue;
}

export interface ConfidenceIntervalPayload {
  Mean: number;
  ConfidenceLevel: number;
  StandardDeviation: number;
  SampleLength: number;
}

export interface ConfidenceIntervalResponse {
  UpperBound: number;
  LowerBound: number;
}

export interface ChannelData {
  ChannelSourceName: string;
  ChannelDestinationName: string;
  TransitionCount: number;
  TransitionRate: number;
  IsTransitionToAbsorbingState: boolean;
  IsStartState: boolean;
}

export interface Assumptions {
  ImpressionShare?: number;
  ImpressionShareDelta?: number;
  ChannelMaxCapacity?: number;
  ChannelName: string;
  Conversions: number;
  ReccomendedBudget?: number | string;
  BudgetDelta?: number | string;
  ConversionsDelta: number;
  ExistingBudget: number | string;
  ExistingBudgetFormatted?: number | string;
  ProjectedConversions?: number | string;
  channelIndex?: number;
  isPaidMedia?: boolean;
  valid?: boolean;
}

export interface ChannelGrouping {
  Category: number | string;
  ChannelGrouping: string;
  SrcMedium: string;
}

export interface TableChannelGrouping {
  Category: number | string;
  'Channel Group': string;
  Referer: string;
}

export interface PageState {
  url: string;
  accountState: Partial<AccountState> | undefined;
  id: string;
  groupUrl: string;
  mainUrl: string;
  groupId: string;
  checkSub?: boolean;
  subscription?: ISubscription;
  childRoute?: string;
}
export interface PagingFilter {
  pageNum: number;
  pageSize: number;
  startDate: Date | string;
  endDate: Date | string;
}

export interface SetPasswordPayload {
  ConfirmPassword: string;
  NewPassword: string;
}

export interface ChangePasswordPayload extends SetPasswordPayload {
  OldPassword: string;
}

export interface ResetPasswordPayload {
  Email: string;
  Code: string;
  OldPassword: string;
  Password: string;
  ConfirmPassword: string;
}

export interface SetPasswordFranchiseePayload {
  Email: string;
  Password: string;
  ConfirmPassword: string;
}

export interface AdScreenshotPayload {
  year: number;
  month: number;
  day: number;
  snapshotType: number;
}

export interface ConversionPayload {
  isLoading: boolean;
  AdvertiserId: string;
  ConversionRuleId: string;
  CreatedOn: string | Date;
  ConversionRuleName: string;
  ConversionFilters: ConversionFilter[];
  LookbackClick: number;
  LookbackView: number;
  SessionCountingType: number;
}

export interface AnalyticsChartPayload {
  ChartType: number;
  PrimaryTagAccountIds: string[];
  CompareToTagAccountIds: string[];
  PrimaryDateStart: string | Date;
  PrimaryDateEnd: string | Date;
  CompareToDateStart?: string | Date;
  CompareToDateEnd?: string | Date;
  Metric1?: number;
  Metric2?: number;
}

export interface AnalyticsChatPayload {
  ActivityName: string;
  StartDate: string | Date;
  EndDate: string | Date;
  StartIdKey?: number;
  EndIdKey?: number;
}

export interface AnalyticsLeadsPayload extends AnalyticsDateRange {
  ActivityName: string;
}

export interface FacebookReportPayload {
  Level: string;
  Breakdown: string;
  DateStart: string | Date;
  DateStop: string | Date;
}

export interface AccountConfirmPayload {
  Email: string;
  Code: string;
}

export interface GetCreativeEventsSegmentPayload {
  FromDate: string | Date;
  ToDate: string | Date;
  DeviceTypes: string[];
  SetFilters: {
    OperatorType: number;
    SetName: string[];
  };
}

export interface LoginPayload {
  client_id: string;
  client_secret: string;
  grant_type: string;
  password: string;
  scopes: string;
  username: string;
  [key: string]: string;
}

export interface ForgotPasswordPayload {
  Email: string;
}

export interface ResetPasswordTokenCheckPayload {
  Email: string;
  Code: string;
}

export interface MarketingSpendPayload {
  advertiserId: string;
  startDate: string;
  endDate: string;
}

export interface QueryPayload {
  Query: string;
}

export interface MarketingBenchmarkPayload {
  aggregateType: number;
  selectedBenchmark: string;
  startDate: Date | string;
  endDate: Date | string;
}

export enum aggregateType {
  Sum = 0,
  Min = 1,
  Max = 2,
  Average = 3
}

export interface MarketingSpendEditPayload {
  AdvertiserId: string;
  ChannelId: string;
  VendorId: string;
  DateStart: Date|string;
  DateEnd: Date|string;
  BudgetAmount: number;
  Note?: string;
  MarketingSpendDataPointId?: string
}
