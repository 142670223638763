import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormGroup,
} from '@angular/forms';
import { Validator } from '../../../../../shared/interfaces/field.interface';
import { OnTouchFunction, OnChangeFunction } from '../types';

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => TextareaFormElementComponent),
  multi: true,
};
@Component({
  selector: 'app-form-textarea',
  template: `
    <div *ngIf="group" [formGroup]="group">
      <mat-form-field>
        <textarea
          matInput
          [id]="id"
          [(ngModel)]="value"
          [readonly]="readonly"
          [value]="innerValue"
          [ngStyle]="cssStyle"
          [placeholder]="placeholder"
          [rows]="rows"
          [cols]="cols"
          [disabled]="disabled"
          [maxlength]="maxlength"
          [formControlName]="name"
        ></textarea>
        <ng-container
          *ngFor="let validation of validations"
          ngProjectAs="mat-error"
        >
          <mat-error *ngIf="group.get(name)?.hasError(validation.name)">{{
            validation.message
          }}</mat-error>
        </ng-container>
      </mat-form-field>
    </div>
    <div *ngIf="!group">
      <mat-form-field>
        <textarea
          matInput
          [id]="id"
          [(ngModel)]="value"
          [readonly]="readonly"
          [value]="innerValue"
          [ngStyle]="cssStyle"
          [placeholder]="placeholder"
          [rows]="rows"
          [cols]="cols"
          [disabled]="disabled"
          [maxlength]="maxlength"
        ></textarea>
      </mat-form-field>
    </div>
  `,
  styles: [],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class TextareaFormElementComponent implements ControlValueAccessor {
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() disabled = false;
  @Input() rows = '';
  @Input() name = '';
  @Input() cols = '';
  @Input() readonly = false;
  @Input() id = '';
  @Input() cssStyle = '';
  @Input() maxlength: number|undefined;
  @Input() validations: Validator[] = [];
  @Input() group: UntypedFormGroup|undefined;

  innerValue = '';
  private onTouchedCallback: OnTouchFunction = noop;
  private onChangeCallback: OnChangeFunction<string> = noop;

  constructor() {}

  get value(): string {
    return this.innerValue;
  }

  @Input() set value(v: string) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  changed() {
    this.onBlur();
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: string) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: OnChangeFunction<string>) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: OnTouchFunction) {
    this.onTouchedCallback = fn;
  }
}
