import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormGroup } from '@angular/forms';
import { OnChangeFunction, OnTouchFunction } from '../types';
import { MatCheckboxChange } from '@angular/material/checkbox';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line: no-use-before-declare
    useExisting: forwardRef(() => CheckboxFormElementComponent),
    multi: true
};
@Component({
  selector: 'app-form-checkbox',
  template: `
  <span *ngIf="group" [formGroup]="group">
    <mat-checkbox [id]="id" [(ngModel)]="value" [indeterminate]="indeterminate" [formControlName]="name" (change)="changed($event)"
      [disabled]="disabled">{{label}}</mat-checkbox>
  </span>
  <span *ngIf="!group">
    <mat-checkbox [id]="id" [(ngModel)]="value" name="name" [indeterminate]="indeterminate" (change)="changed($event)"
      [disabled]="disabled">{{label}}</mat-checkbox>
  </span>
  `,
  styleUrls: ['./checkbox.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CheckboxFormElementComponent implements ControlValueAccessor {
  @Input() placeholder = '';
  @Input() name = '';
  @Input() label = '';
  @Input() disabled = false;
  @Input() filter = false;
  @Input() autoWidth = false;
  @Input() id = '';
  @Input() value = false;
  @Input() indeterminate = false;
  innerValue = false;
  @Output() onChange = new EventEmitter<boolean>();

  private _group: UntypedFormGroup | undefined;
  public get group(): UntypedFormGroup | undefined {
    return this._group;
  }
  @Input()
  public set group(value: UntypedFormGroup | undefined) {
    this._group = value;
  }
  private onTouchedCallback: OnTouchFunction = noop;
  private onChangeCallback: OnChangeFunction<boolean> = noop;
  checkedSet = false;

  constructor() {
  }

  get checked(): boolean {
    return this.value;
  }

  @Input() set checked(v: boolean) {
    if (!this.checkedSet) {
      this.value = v;
      this.checkedSet = true;
    }
  }

  changed(v: MatCheckboxChange) {
    this.onChangeCallback(v.checked);
    this.onChange.emit(v.checked);
  }

  writeValue(value: boolean) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: OnChangeFunction<boolean>) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: OnTouchFunction) {
    this.onTouchedCallback = fn;
  }
}
