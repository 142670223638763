
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {AppState} from '../state';
import {PermissionsQuery} from './permissions.reducer';
import * as PermissionsActions from './permissions.actions';
import { ModuleConfig, ModulePermission } from '../../interfaces/common.interface';

@Injectable()
export class PermissionsFacade {
  permissions$ = this.store.select(PermissionsQuery.permissions);

  constructor(
    private store: Store<AppState>,
  ) {}

  setPermissions(permissions: ModuleConfig[]) {
    this.store.dispatch(new PermissionsActions.SetPermissions(permissions));
  }

  setDashboard(dash: ModuleConfig) {
    this.store.dispatch(new PermissionsActions.SetDashboard(dash));
  }

  setAccess(access: ModulePermission) {
    this.store.dispatch(new PermissionsActions.SetAccess(access));
  }
}
