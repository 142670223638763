import { Injectable } from '@angular/core';
import { DashboardLeaderboardV2Config, HelperBoxSection } from '../../components/integrations/interfaces';
import {
  ChartDataSource,
  ChartDataSourceFilterColumn,
  ChartV2DataTable,
  ChartV2DataTypes,
  ChartV2LineChart,
  ChartV2Type,
  DataSourceAggregationType,
  DataTableDataSource,
} from '../../components/integrations/setup/directives/integration-form-types/dashboard-engine-v2-config/interface';

@Injectable()
export class ChartsService {
  checkCommonChartData(chartData: ChartV2LineChart): any[] {
    return [
      chartData.UnitType,
      chartData.DurationType,
      chartData.PercentChangeType,
    ];
  }

  checkCommonFilterColumnData(
    filterColumnData?: ChartDataSourceFilterColumn
  ): any[] {
    if (filterColumnData) {
      return [filterColumnData.AccountIdColumn];
    } else {
      return [undefined];
    }
  }

  checkCommonDataSourceData(dataSourceData?: ChartDataSource): any[] {
    if (dataSourceData) {
      return [
        dataSourceData.Schema,
        dataSourceData.Table,
        ...this.checkCommonFilterColumnData(dataSourceData.FilterColumns),
        dataSourceData.Aggregation?.AggregationType,
      ];
    } else {
      return [undefined];
    }
  }

  checkLineChartSpecificData(chartData: ChartV2LineChart): any[] {
    return [
      ...this.checkCommonChartData(chartData),
      chartData?.SummaryAggregationType,
      chartData?.Series?.Title,
      ...this.checkCommonDataSourceData(chartData?.Series?.DataSource),
    ];
  }

  checkDataTableSpecificData(chartData: ChartV2DataTable): (any | any[])[] {
    const dataSource: DataTableDataSource = chartData.DataSource;
    return [
      dataSource?.Schema,
      dataSource?.Table,
      dataSource?.FilterColumns?.AccountIdColumn,
      [].concat.apply(
        [],
        dataSource?.Columns?.map((column) => {
          return [
            column.ColumnName,
            column.UnitType,
            column.DurationType,
            column.Aggregation?.AggregationType,
          ];
        })
      ),
    ];
  }

  isChartDataComplete(chartData: ChartV2DataTypes): boolean {
    const { Title, Type, Description } = chartData;
    let checker: (any | any[])[] = [Title, Description];
    if (Type === ChartV2Type.LineChart) {
      checker.push(...this.checkLineChartSpecificData(chartData));
    } else if (Type === ChartV2Type.DataTable) {
      checker.push(...this.checkDataTableSpecificData(chartData));
    }
    const merged: any[] = [].concat.apply([], checker);
    const containsIncompleteData =
      merged.includes(undefined) ||
      merged.includes('') ||
      merged.includes(null);
    return !containsIncompleteData;
  }

  isLeaderboardChartComplete(
    leaderboardData: DashboardLeaderboardV2Config
  ): boolean {
    const {
      Title,
      Description,
      Schema,
      Table,
      AccountIdColumn,
      DateColumn,
      UnitType,
      RankingType,
      MaxFranchiseeRowCount,
      Columns
    } = leaderboardData;
    if (
      Title &&
      Description &&
      Schema &&
      Table &&
      AccountIdColumn &&
      DateColumn &&
      UnitType !== undefined &&
      RankingType !== undefined &&
      MaxFranchiseeRowCount &&
      Columns &&
      Columns.length
    ) {
      const { Aggregation, ColumnName  } = Columns[0];
      if (Aggregation.IsRatio) {
        if (
          Aggregation.AverageNumeratorColumn &&
          Aggregation.AverageDenominatorColumn
          && Aggregation.AggregationType === DataSourceAggregationType.Average
        ) {
          return true;
        }
      } else {
        if (ColumnName && Aggregation.AggregationType !== undefined) {
          return true;
        }
      }
    }
    return false;
  }

  isHelperBoxComplete(helperBoxData: HelperBoxSection[]) :boolean {
    const completionChecker = helperBoxData.map(
      (tab) => {
        const { Title, Headers } = tab;
        if (Title) {
          return Headers.map((header) => {
            const { HeaderTitle, Descriptions } = header;
            if (HeaderTitle) {
              return Descriptions.map((description) => {
                if (description) return true;
                return false;
              });
            }
            return false;
          });
        }
        return false;
      }
    );
    const mergedChecker: boolean[] = [].concat.apply([], completionChecker);
    let hasFailed = mergedChecker.findIndex((status: boolean) => status === false);
    return hasFailed === -1
  }
}
