import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import './rxjs-extensions';
import { ButtonComponent } from '../components/directives/dynamic-form/button/button.component';
import { CheckboxComponent } from '../components/directives/dynamic-form/checkbox/checkbox.component';
import { SliderFormElementComponent } from '../components/directives/dynamic-form/form-elements/slider/slider.component';
import { InputComponent } from '../components/directives/dynamic-form/input/input.component';
import { TextareaComponent } from '../components/directives/dynamic-form/textarea/textarea.component';
import { LabelComponent } from '../components/directives/dynamic-form/label/label.component';
import { DateComponent } from '../components/directives/dynamic-form/date/date.component';
import { RadiobuttonComponent } from '../components/directives/dynamic-form/radiobutton/radiobutton.component';
import { SelectFieldComponent } from '../components/directives/dynamic-form/select/select.component';
import { MultiSelectFieldComponent } from '../components/directives/dynamic-form/multi-select/multi-select.component';
import { ListboxFieldComponent } from '../components/directives/dynamic-form/listbox/listbox.component';
import { SwitchFieldComponent } from '../components/directives/dynamic-form/switch/switch.component';
import { SliderComponent } from '../components/directives/dynamic-form/slider/slider.component';
import { DynamicFieldDirective } from '../components/directives/dynamic-form/dynamic-field/dynamic-field.directive';
import { DynamicFormComponent } from '../components/directives/dynamic-form/dynamic-form/dynamic-form.component';
import { SelectFormElementComponent } from '../components/directives/dynamic-form/form-elements/select/select.component';
import { MultiSelectFormElementComponent } from '../components/directives/dynamic-form/form-elements/multi-select/multi-select.component';
import { RadioFormElementComponent } from '../components/directives/dynamic-form/form-elements/radio/radio.component';
import { SwitchFormElementComponent } from '../components/directives/dynamic-form/form-elements/switch/switch.component';
import { InputFormElementComponent } from '../components/directives/dynamic-form/form-elements/input/input.component';
import { TextareaFormElementComponent } from '../components/directives/dynamic-form/form-elements/textarea/textarea.component';
import { LabelFormElementComponent } from '../components/directives/dynamic-form/form-elements/label/label.component';
import { CheckboxFormElementComponent } from '../components/directives/dynamic-form/form-elements/checkbox/checkbox.component';
import { ButtonFormElementComponent } from '../components/directives/dynamic-form/form-elements/button/button.component';
import { DatePickerFormElementComponent } from '../components/directives/dynamic-form/form-elements/date/date.component';
import { TimePickerFormElementComponent } from '../components/directives/dynamic-form/form-elements/time/time.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChipsComponent } from '../components/directives/dynamic-form/chip/chip.component';
import { ChipFormElementComponent } from '../components/directives/dynamic-form/form-elements/chip/chip.component';
import { TableInputComponent } from '../components/directives/dynamic-form/table-input/table-input.component';


@NgModule({
  declarations: [
    ButtonComponent,
    CheckboxComponent,
    SliderFormElementComponent,
    InputComponent,
    ChipsComponent,
    TableInputComponent,
    TextareaComponent,
    LabelComponent,
    DateComponent,
    RadiobuttonComponent,
    SelectFieldComponent,
    MultiSelectFieldComponent,
    ListboxFieldComponent,
    SwitchFieldComponent,
    SliderComponent,
    DynamicFieldDirective,
    DynamicFormComponent,
    SelectFormElementComponent,
    MultiSelectFormElementComponent,
    RadioFormElementComponent,
    SwitchFormElementComponent,
    InputFormElementComponent,
    ChipFormElementComponent,
    TextareaFormElementComponent,
    LabelFormElementComponent,
    CheckboxFormElementComponent,
    ButtonFormElementComponent,
    DatePickerFormElementComponent,
    TimePickerFormElementComponent,
  ],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    CheckboxComponent,
    SliderFormElementComponent,
    InputComponent,
    ChipsComponent,
    TableInputComponent,
    TextareaComponent,
    LabelComponent,
    DateComponent,
    RadiobuttonComponent,
    SelectFieldComponent,
    MultiSelectFieldComponent,
    ListboxFieldComponent,
    SwitchFieldComponent,
    SliderComponent,
    DynamicFieldDirective,
    DynamicFormComponent,
    SelectFormElementComponent,
    MultiSelectFormElementComponent,
    RadioFormElementComponent,
    SwitchFormElementComponent,
    InputFormElementComponent,
    ChipFormElementComponent,
    TextareaFormElementComponent,
    LabelFormElementComponent,
    ButtonFormElementComponent,
    DatePickerFormElementComponent,
    TimePickerFormElementComponent,
    CheckboxFormElementComponent,
    NgbModule,
    MaterialModule
  ],
  bootstrap: [],
})
export class CustomFormModule {}
