<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title>A new version is available. Please refresh.</h2>
<mat-dialog-content class="mat-typography">
  <div>
    <div>
      There is a new version of Transitiv available. Please refresh your browser
      window to use the new version of Transitiv. If you have unsaved work, you
      may close this message, save your work, and refresh your browser after.
    </div>
    <div class="actionContainer">
      <button
        mat-button
        color="primary"
        style="margin: 5px;"
        cdkFocusInitial
        (click)="reload()"
      >
        <span
          class="material-icons"
          style="
            width: 50px;
            height: 50px;
            vertical-align: middle;
            font-size: 50px;
          "
        >
          refresh
        </span>
        Click to refresh.
      </button>
    </div>
  </div>
</mat-dialog-content>
