import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntegrationType } from '../../../interfaces';
import { EntityMap } from '../../entity/entity-metadata';
import { CommonUtilityService } from '@services/utility/common-utility.service';
import { GroupIntegrationsService } from '@services/integrations/group/group-integrations.service';

@Injectable()
export class GroupIntegrationTypeDataService extends DefaultDataService<IntegrationType> {
  groupId?: string

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private integrationsService: GroupIntegrationsService
  ) {
    super(EntityMap.GroupIntegrationType, http, httpUrlGenerator);
  }

  getAll(): Observable<IntegrationType[]> {
    return this.integrationsService.getAccountGroupIntegrationTypes(
      this.groupId as string).pipe(
        map((integrations) => integrations.data.map(data => CommonUtilityService.timestampStateData(data))));
  }

  getById(integrationId: string): Observable<IntegrationType> {
    return this.integrationsService.getAccountGroupIntegrationType(
      this.groupId as string, integrationId).pipe(
        map((integrations) => CommonUtilityService.timestampStateData(integrations.data)));
  }
}
