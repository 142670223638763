<div class="col-md-11 pulldown">
  <div class="row nomargin" data-cy="nav-panel-list">
    <div class="col-md-12 pad-t-10 pad-b-10 menusearcharea" style="padding-left: 0px; padding-right: 0px">
      <div class="col-md-12 nopadding">
        <div class="breadcrumbContainer">
          <span *ngFor="let breadcrumb of breadcrumbs; let i = index">
            <span *ngIf="i < breadcrumbs.length - 1">
              <a (click)="setActiveAdvertiserGroupFromId(breadcrumb)">{{
                getGroupDetails(breadcrumb, i)
              }}</a>
              <span class="material-icons" style="vertical-align: bottom;">
                keyboard_arrow_right
              </span>
            </span>
            <span *ngIf="i === breadcrumbs.length - 1">
              {{ getGroupDetails(breadcrumb, i) }}
            </span>
          </span>
        </div>
        <mat-divider></mat-divider>
        <div class="ui-inputgroup">
          <app-form-button type="button" cssClass="smttblicons"
            ><i class="fa fa-search" aria-hidden="true"></i
          ></app-form-button>
          <app-form-input
            autocomplete="off"
            [autoFocus]="true"
            type="text"
            float="never"
            placeholder="Search Groups and Accounts"
            [(ngModel)]="keyword"
            id="nav-panel-search"
          ></app-form-input>
          <button
            mat-icon-button
            id="nav-panel-filter"
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="locationPickerFilter">
            <span class="title">Show</span>
            <button mat-menu-item>
              <app-form-checkbox
                name="groupname"
                [value]="showInactive"
                id="nav-panel-show-inactive"
                [(ngModel)]="showInactive"
              ></app-form-checkbox>
              <span>Inactive accounts and groups</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

    <div class="col-md-12 fixdmenu">
      <div class="col-md-12 accounts nopadding">
        <div class="panel_area">
          <mat-progress-bar
            *ngIf="state?.searchLoading"
            mode="indeterminate"
            color="accent"
            class="example-tree-progress-bar"
            data-cy="nav-panel-main-progress-bar"
          ></mat-progress-bar>
          <app-tree-display
            [showInactive]="showInactive"
            [searchFilter]="keyword"
            (breadcrumb)="setBreadcrumbs($event)"
            (onSelect)="onSelect($event)"
            (inactiveEnabled)="inactiveEnabled($event)"
          ></app-tree-display>
        </div>
      </div>
    </div>
  </div>
</div>
