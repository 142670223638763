import { Action } from '@ngrx/store';
import { BuilderStep, QueryConfig } from '..';
import { TableObject, TableJoin, TableField, QueryFilterConfig, RuleSet, ParsedQueryObject,
  HavingExpression } from '../../../interfaces/tables.interface';
import { RawSchema, SavedQuery } from '../../../interfaces/query.interface';
import { StringKeyStringNumberValue } from '../../../interfaces/common.interface';
import { ObjectIndexer } from '../../../interfaces';

export const RESET_BUILDER_STATE = 'Builder reset state';
export const SET_BUILDER_STEP = 'Builder set step';
export const SET_BUILDER_FILTER_CONFIG = 'Builder set filter config';
export const SET_BUILDER_STATE = 'Builder set state';
export const SET_BUILDER_GENERATED_SQL = 'Builder set generated sql';
export const SET_BUILDER_PARSED_QUERY = 'Builder set parsed query';
export const GENERATE_BUILDER_SQL = 'Builder generate sql';
export const GET_BUILDER_SCHEMA = 'Builder get schema';
export const GET_BUILDER_SCHEMA_SUCCESS = 'Builder get schema success';
export const GET_BUILDER_SCHEMA_FAIL = 'Builder get schema fail';
export const SAVE_BUILDER_NAME = 'Builder save name';
export const SAVE_BUILDER_SQL = 'Builder save sql';
export const SAVE_BUILDER_SQL_SUCCESS = 'Builder save sql success';
export const SAVE_BUILDER_SQL_FAIL = 'Builder save sql fail';
export const EDIT_BUILDER_SQL = 'Builder edit sql';
export const EDIT_BUILDER_SQL_SUCCESS = 'Builder edit sql success';
export const EDIT_BUILDER_SQL_FAIL = 'Builder edit sql fail';
export const PARSE_BUILDER_SQL = 'Builder parse sql';
export const PARSE_BUILDER_SQL_SUCCESS = 'Builder parse sql success';
export const PARSE_BUILDER_SQL_FAIL = 'Builder parse sql fail';
export const SET_BUILDER_PARSED = 'Builder parsed';
export const EXECUTE_BUILDER_SQL = 'Builder execute sql';
export const EXECUTE_BUILDER_SQL_SUCCESS = 'Builder execute sql success';
export const EXECUTE_BUILDER_SQL_FAIL = 'Builder execute sql fail';
export const SET_BUILDER_EXTRA_SETUP = 'Builder set extras';
export const SET_BUILDER_FORM_DIRTY = 'Builder set form dirty';
export const SET_BUILDER_ADVANCED_QUERY = 'Builder set advanced query';
export const SET_BUILDER_FILTER_QUERY = 'Builder set filter query';

export class ResetBuilderState implements Action {
  readonly type = RESET_BUILDER_STATE;
  constructor() {}
}

export class SetBuilderDirty implements Action {
  readonly type = SET_BUILDER_FORM_DIRTY;
  constructor(public payload: boolean) {}
}

export class SetBuilderStep implements Action {
  readonly type = SET_BUILDER_STEP;
  constructor(public payload: BuilderStep) {}
}

export class SetBuilderState implements Action {
  readonly type = SET_BUILDER_STATE;
  constructor(public tables: TableObject[], public selectedFields: ObjectIndexer<TableField[]>, public joins: TableJoin[],
    public config: QueryConfig, public query: QueryFilterConfig) {}
}

export class SetBuilderFilerConfig implements Action {
  readonly type = SET_BUILDER_FILTER_CONFIG;
  constructor(public tables: TableObject[], public selectedFields: ObjectIndexer<TableField[]>, public joins: TableJoin[],
    public config: QueryConfig) {}
}

export class SetBuilderFilerExtras implements Action {
  readonly type = SET_BUILDER_EXTRA_SETUP;
  constructor(public groupBy: TableField[], public having: HavingExpression[]) {}
}

export class GenerateBuilderSql implements Action {
  readonly type = GENERATE_BUILDER_SQL;
  constructor(public payload: RuleSet) {}
}

export class SetBuilderGeneratedSql implements Action {
  readonly type = SET_BUILDER_GENERATED_SQL;
  constructor(public sql: string) {}
}

export class SetBuilderParsedQuery implements Action {
  readonly type = SET_BUILDER_PARSED_QUERY;
  constructor(public query: RuleSet) {}
}

export class GetBuilderSchema implements Action {
  readonly type = GET_BUILDER_SCHEMA;
  constructor(public payload: string) {}
}

export class GetBuilderSchemaSuccess implements Action {
  readonly type = GET_BUILDER_SCHEMA_SUCCESS;
  constructor(public payload: TableObject[], public raw: RawSchema[]) {}
}

export class GetBuilderSchemaFail implements Action {
  readonly type = GET_BUILDER_SCHEMA_FAIL;
  constructor(public payload: string) {}
}

export class SaveBuilderName implements Action {
  readonly type = SAVE_BUILDER_NAME;
  constructor(public name: string) {}
}

export class SaveBuilderSql implements Action {
  readonly type = SAVE_BUILDER_SQL;
  constructor(public name: string, public id: string, public payload: string) {}
}

export class SaveBuilderSqlSuccess implements Action {
  readonly type = SAVE_BUILDER_SQL_SUCCESS;
  constructor(public payload: SavedQuery) {}
}

export class SaveBuilderSqlFail implements Action {
  readonly type = SAVE_BUILDER_SQL_FAIL;
  constructor(public payload: string) {}
}

export class EditBuilderSql implements Action {
  readonly type = EDIT_BUILDER_SQL;
  constructor(public name: string, public id: string, public payload: string) {}
}

export class EditBuilderSqlSuccess implements Action {
  readonly type = EDIT_BUILDER_SQL_SUCCESS;
  constructor(public payload: SavedQuery) {}
}

export class EditBuilderSqlFail implements Action {
  readonly type = EDIT_BUILDER_SQL_FAIL;
  constructor(public payload: string) {}
}

export class ParseBuilderSql implements Action {
  readonly type = PARSE_BUILDER_SQL;
  constructor(public id: string, public payload: string) {}
}

export class ParseBuilderSqlSuccess implements Action {
  readonly type = PARSE_BUILDER_SQL_SUCCESS;
  constructor(public payload: ParsedQueryObject) {}
}

export class ParseBuilderSqlFail implements Action {
  readonly type = PARSE_BUILDER_SQL_FAIL;
  constructor(public payload: string) {}
}

export class SetBuilderParsed implements Action {
  readonly type = SET_BUILDER_PARSED;
  constructor() {}
}

export class ExecuteBuilderSql implements Action {
  readonly type = EXECUTE_BUILDER_SQL;
  constructor(public id: string, public payload: string) {}
}

export class ExecuteBuilderSqlSuccess implements Action {
  readonly type = EXECUTE_BUILDER_SQL_SUCCESS;
  constructor(public payload: StringKeyStringNumberValue[]) {}
}

export class ExecuteBuilderSqlFail implements Action {
  readonly type = EXECUTE_BUILDER_SQL_FAIL;
  constructor(public payload: string) {}
}

export class SetBuilderAdvancedQuery implements Action {
  readonly type = SET_BUILDER_ADVANCED_QUERY;
  constructor(public payload: string) {}
}

export class SetBuilderFilterQuery implements Action {
  readonly type = SET_BUILDER_FILTER_QUERY;
  constructor(public payload: string) {}
}

export type All
  = ResetBuilderState
  | SetBuilderDirty
  | SetBuilderState
  | SetBuilderStep
  | SetBuilderFilerConfig
  | GenerateBuilderSql
  | SaveBuilderSql
  | SetBuilderFilerExtras
  | SaveBuilderSqlSuccess
  | SaveBuilderSqlFail
  | EditBuilderSql
  | EditBuilderSqlSuccess
  | EditBuilderSqlFail
  | ParseBuilderSql
  | ParseBuilderSqlSuccess
  | ParseBuilderSqlFail
  | GetBuilderSchema
  | GetBuilderSchemaSuccess
  | GetBuilderSchemaFail
  | ExecuteBuilderSql
  | ExecuteBuilderSqlSuccess
  | ExecuteBuilderSqlFail
  | SetBuilderParsed
  | SaveBuilderName
  | SetBuilderAdvancedQuery
  | SetBuilderFilterQuery
  | SetBuilderGeneratedSql
  | SetBuilderParsedQuery;
