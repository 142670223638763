import { Injectable } from '@angular/core';
import { EntityCollectionService, EntityCollectionServiceFactory } from '@ngrx/data';
import { Account, AccountGroup } from '../../interfaces';
import { EntityMap } from '../entity/entity-metadata';
import { AccountDataService } from './accounts.data.service';

@Injectable()
export class AccountEffects {
  accountService: EntityCollectionService<Account>;
  accountGroupService: EntityCollectionService<AccountGroup>;
  constructor(
    private accountDataService: AccountDataService,
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
  ) {
    this.accountService = this.entityCollectionServiceFactory.create<Account>(EntityMap.Account);
    this.accountGroupService = this.entityCollectionServiceFactory.create<AccountGroup>(EntityMap.AccountGroup);
  }

  getGroups() {
    this.accountGroupService.load();
  }

  getGroup(groupId: string) {
    this.accountGroupService.getByKey(groupId);
  }

  getAccounts(groupId: string) {
    this.accountDataService.groupId = groupId;
    this.accountService.load();
  }

  getAccount(groupId: string, accountId: string) {
    this.accountDataService.groupId = groupId;
    this.accountService.getByKey(accountId);
  }
}
