import { EntitySelectorsFactory } from "@ngrx/data";
import { createSelector } from "@ngrx/store";
import { ResourceClaim } from '../../interfaces/claim.interface';
import { EntityMap } from '../entity/entity-metadata';

export const selectors = new EntitySelectorsFactory().create<ResourceClaim>(EntityMap.UserClaim);


export const selectClaims = createSelector(
  selectors.selectEntities,
  (claims) => claims
);

export const selectClaimsLoading = createSelector(
  selectors.selectLoading,
  (loading) => loading
);
