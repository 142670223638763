import { createSelector } from '@ngrx/store';
import { AppState } from '../state';

export const selectGlobalFilterState = (state: AppState) => state.globalFilter;

export const selectSelectedSortingOption = createSelector(
  selectGlobalFilterState,
  (state) => state?.selectedSortingOption
);

export const selectSelectedLocationFilters = createSelector(
  selectGlobalFilterState,
  (state) => state?.selectedLocationFilters
);

export const selectDateComparisonData = createSelector(
  selectGlobalFilterState,
  ({
    comparePrevious,
    selectedDateComparisonOption,
  }) => {
    return {
      comparePrevious,
      selectedDateComparisonOption,
    };
  }
);

export const selectLocationSummaryData = createSelector(
  selectGlobalFilterState,
  (state) => state?.locationSummary
);

export const selectLocationSearchInput = createSelector(
  selectGlobalFilterState,
  (state) => state?.searchInput
);
