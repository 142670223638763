import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from '../../shared/interfaces/api-client.interface';
import { SavedQuery, RawSchema } from '../../shared/interfaces/query.interface';
import { StringKeyStringNumberValue } from '../../shared/interfaces/common.interface';
import { QueryPayload } from '../../shared/interfaces';
import { ParsedQueryObject, QueryRule } from '../../shared/interfaces/tables.interface';


@Injectable()
export class QueryService {
  public apiClient = <ApiClient>{};
  constructor() { }

  runQuery(id: string, payload: QueryPayload): Observable<StringKeyStringNumberValue[]> {
    return this.apiClient.callHttpPost({path: `/${id}/query/execute`, param: payload, type: 'edgewarehouse'});
  }

  parseQuery(id: string, payload: QueryPayload): Observable<ParsedQueryObject|QueryRule> {
    return this.apiClient.callHttpPost({path: `/${id}/query/parse`, param: payload, type: 'edgewarehouse'});
  }

  getSchema(id: string): Observable<RawSchema[]> {
    return this.apiClient.callHttpGet({path: `/${id}/schema`, type: 'edgewarehouse'});
  }

  saveQuery(id: string, name: string, payload: QueryPayload): Observable<SavedQuery> {
    return this.apiClient.callHttpPostWithoutResponse({path: `/${id}/query/saved/${name}`, param: payload, type: 'edgewarehouse'});
  }

  editQuery(id: string, name: string, payload: QueryPayload): Observable<SavedQuery> {
    return this.apiClient.callHttpPut({path: `/${id}/query/saved/${name}`, param: payload, type: 'edgewarehouse'});
  }

  deleteQuery(id: string, name: string): Observable<void> {
    return this.apiClient.callHttpDelete({path: `/${id}/query/saved/${name}`, type: 'edgewarehouse'});
  }

  getSavedQuery(id: string): Observable<SavedQuery[]> {
    return this.apiClient.callHttpGet({path: `/${id}/query/saved`, type: 'edgewarehouse'});
  }
}
