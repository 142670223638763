
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { withLatestFrom, mergeMap, map, catchError, first } from 'rxjs/operators';
import { find } from 'lodash-es';
import {AppState} from '../state';
import * as LocationsActions from './locations.actions';
import { UtilityService } from '@services/utility';
import { LocationService } from '@services/location/location.service';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { selectLocationsState } from './locations.selectors';
import { AccountGroup } from '../../interfaces';
import { AdvertiserService } from '@services/advertiser/advertiser.service';
import { AdvertiserLocations } from '../../../components/locations/interfaces';
import { NotificationsService } from '@services/notification/notification.service';
import { Account } from '../../interfaces';

@Injectable()
export class LocationsFacade {

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private utilityService: UtilityService,
    private locationService: LocationService,
    private advertiserService: AdvertiserService,
    private notificationsService: NotificationsService,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.locationService.apiClient = this.apiClient;
    this.advertiserService.apiClient = this.apiClient;
  }

  GetLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsActions.actionLocationsGetAll),
      withLatestFrom(this.store.pipe(select(selectLocationsState))),
      mergeMap(([action, state]) =>
        this.locationService.getAdvertiserLocations(action.id).pipe(
          map((data) => {
            return LocationsActions.actionLocationsGetSuccess({ locations: data });
          }),
          catchError((error: Error) => {
            return of(LocationsActions.actionLocationsGetFail({ error: error.message }));
          })
        )
      )
    )
  );

  AddLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsActions.actionLocationsAdd),
      withLatestFrom(this.store.pipe(select(selectLocationsState))),
      mergeMap(([action, state]) =>
        this.locationService.addBulkAdvertiserLocation(action.id, action.locations).pipe(
          map((data) => {
            this.getLocations(action.id);
            this.notificationsService.notify({
              severity: 'success',
              detail: data?.data?.length ? 'Something went wrong when creating location(s).' : 'Location(s) has been created successfully.',
              summary: 'Location(s) Created.',
              key: 'non-sticky',
            });
            data?.data?.forEach(err => {
              this.notificationsService.notify({
                severity: 'error',
                detail: err.Description,
                summary: 'Error Code: ' + err.Code,
                key: 'non-sticky',
              });
            });
            this.getLocations(action.id);
            return LocationsActions.actionLocationsAddSuccess({  });
          }),
          catchError((error: Error) => {
            return of(LocationsActions.actionLocationsAddFail({ error: error.message }));
          })
        )
      )
    )
  );

  EditLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsActions.actionLocationsEdit),
      withLatestFrom(this.store.pipe(select(selectLocationsState))),
      mergeMap(([action, state]) =>
        this.locationService.editAdvertiserLocation(action.id, action.location.ExternalLocationId, action.location).pipe(
          map((data) => {
            this.getLocations(action.groupId);
            this.notificationsService.notify({
              severity: 'success',
              detail: 'Location has been edited successfully.',
              summary: 'Location Edited.',
              key: 'non-sticky',
            });
            return LocationsActions.actionLocationsEditSuccess({  });
          }),
          catchError((error: Error) => {
            return of(LocationsActions.actionLocationsEditFail({ error: error.message }));
          })
        )
      )
    )
  );

  EditLocationAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsActions.actionLocationsAccountEdit),
      withLatestFrom(this.store.pipe(select(selectLocationsState))),
      mergeMap(([action, state]) =>
        this.advertiserService.editIndirectAccounts(action.groupId, action.accounts).pipe(
          map((data) => {
            this.getLocations(action.groupId);
            return LocationsActions.actionLocationsAccountEditSuccess({  });
          }),
          catchError((error: Error) => {
            return of(LocationsActions.actionLocationsAccountEditFail({ error: error.message }));
          })
        )
      )
    )
  );

  CreateAccountAndEditLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsActions.actionLocationsCreateAccountAndEdit),
      withLatestFrom(this.store.pipe(select(selectLocationsState))),
      mergeMap(([action, state]) =>
        this.locationService.createAccountAndEditAdvertiserLocation(action.groupId, action.id, action.location).pipe(
          map((data) => {
            this.getLocations(action.groupId);
            this.notificationsService.notify({
              severity: 'success',
              detail: 'Location has been edited successfully.',
              summary: 'Location Edited.',
              key: 'non-sticky',
            });
            return LocationsActions.actionLocationsEditSuccess({  });
          }),
          catchError((error: Error) => {
            return of(LocationsActions.actionLocationsEditFail({ error: error.message }));
          })
        )
      )
    )
  );

  DeleteLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsActions.actionLocationsDelete),
      withLatestFrom(this.store.pipe(select(selectLocationsState))),
      mergeMap(([action, state]) =>
        this.locationService.deleteBulkAdvertiserLocation(action.id, action.locations).pipe(
          map((data) => {
            this.getLocations(action.id);
            this.notificationsService.notify({
              severity: 'success',
              detail: data?.data?.length ? 'Something went wrong when deleting locations.' : 'Location has been deleted successfully.',
              summary: 'Location Deleted.',
              key: 'non-sticky',
            });
            data?.data?.forEach(err => {
              this.notificationsService.notify({
                severity: 'error',
                detail: err.Description,
                summary: 'Error Code: ' + err.Code,
                key: 'non-sticky',
              });
            });
            return LocationsActions.actionLocationsDeleteSuccess({ locations: data });
          }),
          catchError((error: Error) => {
            return of(LocationsActions.actionLocationsDeleteFail({ error: error.message }));
          })
        )
      )
    )
  );

  InviteLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsActions.actionLocationsInvite),
      withLatestFrom(this.store.pipe(select(selectLocationsState))),
      mergeMap(([action, state]) =>
        this.locationService.sendInvite(action.id, action.locations).pipe(
          map((data) => {
            this.notificationsService.notify({
              severity: 'success',
              detail: 'Invites sent successfully.',
              summary: 'Invites sent.',
              key: 'non-sticky',
            });
            return LocationsActions.actionLocationsInviteSuccess({ locations: data });
          }),
          catchError((error: Error) => {
            return of(LocationsActions.actionLocationsInviteFail({ error: error.message }));
          })
        )
      )
    )
  );

  GetAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsActions.actionLocationsGetAccounts),
      withLatestFrom(this.store.pipe(select(selectLocationsState))),
      mergeMap(([action, state]) =>
        this.advertiserService.getAdvertisersByGroup(action.id).pipe(
          map((data) => {
            return LocationsActions.actionLocationsGetAccountsSuccess({ accounts: data.data });
          }),
          catchError((error: Error) => {
            return of(LocationsActions.actionLocationsGetAccountsFail({ error: error.message }));
          })
        )
      )
    )
  );

  getLocations(id: string) {
    this.store.dispatch(LocationsActions.actionLocationsGetAll({id: id}));
  }

  addLocations(id: string, locations: AdvertiserLocations[]) {
    this.store.dispatch(LocationsActions.actionLocationsAdd({id: id, locations: locations}));
  }

  editLocation(id: string, groupId: string, location: AdvertiserLocations) {
    this.store.dispatch(LocationsActions.actionLocationsEdit({id: id, groupId: groupId, location: location}));
  }

  editAccounts(groupId: string, accounts: Account[]) {
    this.store.dispatch(LocationsActions.actionLocationsAccountEdit({groupId: groupId, accounts: accounts}));
  }

  createAccountAndEditLocation(id: string, groupId: string, location: AdvertiserLocations) {
    this.store.dispatch(LocationsActions.actionLocationsCreateAccountAndEdit({id: id, groupId: groupId, location: location}));
  }

  deleteLocations(id: string, locations: AdvertiserLocations[]) {
    this.store.dispatch(LocationsActions.actionLocationsDelete({id: id, locations: locations}));
  }

  sendLocationInvites(id: string, locations: string[]) {
    this.store.dispatch(LocationsActions.actionLocationsInvite({id: id, locations: locations}));
  }

  getGroupAdvertisers(groupId: string, advertiserGroups: AccountGroup[]) {
    if (groupId && advertiserGroups && advertiserGroups.length > 0) {
      this.store.dispatch(LocationsActions.actionLocationsResetAccounts());
      const group = find(advertiserGroups, { GroupId: groupId });
      if (group) {
        const groups = this.utilityService.getSubGroups(
          advertiserGroups,
          group
        );
        this.getAdvertisersFromSubGroups(groups, advertiserGroups);
      }
    }
  }

  private getAdvertisersFromSubGroups(groups: AccountGroup[], advertiserGroups: AccountGroup[]) {
    const done: boolean[] = [];

    groups.forEach((grp, i) => {
      const grps = this.utilityService.getSubGroups(advertiserGroups, grp);
      if (grps.length > 0) {
        this.getAdvertisersFromSubGroups(grps, advertiserGroups);
      }
      this.store.dispatch(LocationsActions.actionLocationsGetAccounts({id: grp.GroupId}));
    });
  }
}
