import { EntityMetadataMap, PropsFilterFnFactory } from '@ngrx/data';
import { uniqueId } from 'lodash-es';
import { AdvertiserLocations } from '../../../components/locations/interfaces';
import { Account, AccountGroup, Campaign, IntegrationDefinition, IntegrationType } from '../../interfaces';
import { ResourceClaim, UserWhoIsInfo } from '../../interfaces/claim.interface';
import { ModuleConfig, UserInfo } from '../../interfaces/common.interface';
import { RawSchema } from '../../interfaces/query.interface';

export const entityMetadata: EntityMetadataMap = {
  Account: {
    selectId: (account: Account) => account.AccountId
  },
  AccountGroup: {
    selectId: (accountGroup: AccountGroup) => accountGroup.GroupId
  },
  AccountIntegration: {
    selectId: (integration: IntegrationDefinition) => integration.IntegrationId
  },
  AccountIntegrationType: {
    selectId: (integration: IntegrationType) => integration.Type as number
  },
  Campaign: {
    selectId: (campaign: Campaign) => campaign.Id || campaign.CampaignId,
    sortComparer: sortByName
  },
  ChannelGrouping: {
    selectId: () => uniqueId()
  },
  CustomerSummary: {
    selectId: () => 'customerSummary'
  },
  Dashboard: {
    selectId: (config: RawSchema) => config.EntityName
  },
  DataSource: {
    selectId: () => uniqueId()
  },
  Fit: {
    selectId: () => 'fitData'
  },
  GroupIntegration: {
    selectId: (integration: IntegrationDefinition) => integration.IntegrationId
  },
  GroupIntegrationType: {
    selectId: (integration: IntegrationType) => integration.Type as number
  },
  Locations: {
    selectId: (location: AdvertiserLocations) => `${location.TransitivAccountId}-${location.ExternalLocationId}`
  },
  MarketingSpend: {
    selectId: () => uniqueId()
  },
  Markov: {
    selectId: () => 'markov'
  },
  ModuleConfig: {
    selectId: (config: ModuleConfig) => config.ModuleNamespace
  },
  User: {
    selectId: (user: UserInfo) => user.Email
  },
  UserClaim: {
    selectId: (claim: ResourceClaim) => claim.UserClaimId
  },
  UserWhois: {
    selectId: (user: UserWhoIsInfo) => user.UserId
  },
};


export function nameFilter<T extends { CampaignName: string }>(entities: T[], pattern: string) {
  return PropsFilterFnFactory(['CampaignName'])(entities, pattern);
}

export function sortByName(a: { CampaignName: string }, b: { CampaignName: string }): number {
  return a?.CampaignName?.localeCompare(b.CampaignName);
}




export enum EntityMap {
  Account = 'Account',
  AccountGroup = 'AccountGroup',
  AccountIntegration = 'AccountIntegration',
  AccountIntegrationType = 'AccountIntegrationType',
  Campaign = 'Campaign',
  ChannelGrouping = 'ChannelGrouping',
  Dashboard = 'Dashboard',
  Fit = 'Fit',
  GroupIntegration = 'GroupIntegration',
  GroupIntegrationType = 'GroupIntegrationType',
  Locations = 'Locations',
  Markov = 'Markov',
  MarketingSpend = 'MarketingSpend',
  ModuleConfig = 'ModuleConfig',
  User = "User",
  UserClaim = 'UserClaim',
  UserWhois = "UserWhois",
  DataSource = "DataSource"
}

export const ACCOUNT_INTEGRATION_SAVED = `[${EntityMap.AccountIntegration}] @ngrx/data/save/add-one/success`;
export const ACCOUNT_INTEGRATION_UPDATED = `[${EntityMap.AccountIntegration}] @ngrx/data/save/update-one/success`;
export const GROUP_INTEGRATION_DELETED = `[${EntityMap.GroupIntegration}] @ngrx/data/save/delete-one/success`;
export const GROUP_INTEGRATION_SAVED = `[${EntityMap.GroupIntegration}] @ngrx/data/save/add-one/success`;
export const GROUP_INTEGRATION_UPDATED = `[${EntityMap.GroupIntegration}] @ngrx/data/save/update-one/success`;
export const QUERY_CHANNEL_GROUPING_COMPLETE_ACTION = `[${EntityMap.ChannelGrouping}] @ngrx/data/query-all/success`;
export const QUERY_INTEGRATIONS_COMPLETE_ACTION = `[${EntityMap.AccountIntegration}] @ngrx/data/query-all/success`;
export const QUERY_LOCATIONS_COMPLETE_ACTION = `[${EntityMap.Locations}] @ngrx/data/query-all/success`;
export const USER_COMPLETE_ACTION = `[${EntityMap.User}] @ngrx/data/query-load/success`;
