import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FieldClass } from '../field/field.class';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-multi-select-field',
  template: ` <div
    class="row mar-b-40 inputfield_"
    [ngClass]="{
      is_col_6: field.size === 'xxxsmall',
      is_col_5: field.size === 'xxsmall',
      is_col_4: field.size === 'xsmall',
      is_col_3: field.size === 'small',
      is_col_2: field.size === 'medium',
      haselement: field.hasElement,
      is_block_full: field.isBlock,
      is_fullwidth: !field.size || field.size === 'large'
    }"
    *ngIf="display()"
  >
    <div [ngClass]="{ i_tooltip: field.hasTooltip }">
      <div class="nopadding">
        <app-form-multi-select
          [filter]="field.filter"
          [placeholder]="field.label + (field.isRequired ? '*' : '')"
          [group]="group"
          [validations]="field.validations"
          [options]="field.options"
          [name]="field.name"
          [value]="field.value"
          [itemId]="field.id"
          [disabled]="disabled()"
          (onChange)="onChange($event)"
          [autoWidth]="false"
        >
        </app-form-multi-select>
      </div>
    </div>
    <div class="pri-tooltip lookback-info" *ngIf="field.description">
      <strong class="epsilon">
        <i
          class="fa fa-question-circle"
          style="color: #1e88e5;font-size: 16px;"
        ></i>
      </strong>
      <p class="content">
        {{ field.description }}
      </p>
    </div>
  </div>`,
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectFieldComponent extends FieldClass
  implements OnInit, AfterViewInit {
  constructor() {
    super();
  }
  ngOnInit() {}

  ngAfterViewInit() {
    this.watchField();
    this.watchOnChange();
  }

  watchField() {
    if (this.field && this.field.fieldToWatch && this.group) {
      const f = this.group.get(this.field.fieldToWatch);
      if (f) {
        f.valueChanges.subscribe((value) => {
          if (this.field && this.field.onWatchedOptionChange) {
            this.field.options = this.field.onWatchedOptionChange(value);
          }
        });
      }
    }
  }
  watchOnChange() {
    if (this.group && this.field && this.field.handleSelectAll && this.field.name) {
      const f = this.group.get(this.field.name);
      if (f) {
        f.valueChanges
          .subscribe((value) => {
            if (this.field && this.field.handleSelectAll) {
              this.field.handleSelectAll(value, this.group);
            }
          });
      }
    }
  }
}
