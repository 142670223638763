import { createSelector } from '@ngrx/store';
import { AppState } from '../state';


export const selectLocationsState = (state: AppState) => state.locations;

export const selectLocationsStateLocations = createSelector(
  selectLocationsState,
  (state) => state?.locations
);

export const selectLocationsStateAccounts = createSelector(
  selectLocationsState,
  (state) => state?.accounts
);

export const selectLocationsStateLoading = createSelector(
  selectLocationsState,
  (state) => state?.loading
);

export const selectLocationsStateAddLoading = createSelector(
  selectLocationsState,
  (state) => state?.addLoading
);
