import { Injectable } from '@angular/core';
import * as SignalR from '@microsoft/signalr';
import { ApiService } from '../api/api.service';
import {
  SignalRNotification, SignalRConnectionInfo,
} from '../../shared/state/tasks';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ApiClient } from '../../shared/interfaces/api-client.interface';

@Injectable({
  providedIn: 'root',
})
export class SignarlRService {
  private apiBaseUrl = environment.signalRHost;
  private accessCode: string = environment.signalRAccessCode;
  signalRMethod = 'notificationReceived';
  connection = <SignalR.HubConnection>{};
  isConnected = false;
  localAdvertiserId = '';
  acknowledgedList: SignalRNotification['AcknowledgedId'][] = [];
  acknowledgingStatus = false;
  apiClient = <ApiClient>{};
  constructor(
  ) {}

  setBaseUrlForSignalR() {
    return this.apiClient.setBaseUrl(this.apiBaseUrl);
  }

  getConnectionInfo(): Observable<SignalRConnectionInfo> {
    this.setBaseUrlForSignalR();
    return this.apiClient.callHttpGet({
      path: `/api/negotiate?code=${this.accessCode}`,
      version: '',
    });
  }

  setConnectionStatus(status: boolean) {
    this.isConnected = status;
  }

  acknowledge(acknowledgedKey: SignalRNotification['AcknowledgedId']) {
    if (this.acknowledgingStatus) {
      if (!this.acknowledgedList.includes(acknowledgedKey)) {
        this.acknowledgedList.push(acknowledgedKey);
        (this.acknowledgedList as number[]).sort((a, b) => a - b);
      }
    } else {
      this.acknowledgingStatus = true;
      this.acknowledgedList.push(acknowledgedKey);
      console.log(this.acknowledgedList, 'acknowledge list');
      // setimeout is used to delay sending the acknowldged id to the endpoint until the user finishes acknowledging
      // the notifications. This is used to prevent calling the endpoint multiple times. Does not slow down the
      // app, functionally.
      setTimeout(() => {
        this.setBaseUrlForSignalR();
        return this.apiClient
          .callHttpGet({
            path: `/api/acknowledge/${
              this.acknowledgedList[this.acknowledgedList.length - 1]
            }?code=${this.accessCode}`,
            version: '',
          })
          .subscribe(
            () => {
              console.log(
                'acknowldged: ',
                this.acknowledgedList[this.acknowledgedList.length - 1]
              );
              localStorage.setItem(
                'laskAckId',
                this.acknowledgedList[this.acknowledgedList.length - 1] as string
              );
              this.acknowledgedList = [];
              this.acknowledgingStatus = false;
            },
            (err) => console.log('error sending acknowledge id: ', err)
          );
      }, 5000);
    }
  }

  // if user changes selected advertiser, you must first remove the previous advertiserId from the group before adding
  // the new selected advertiser to the group
  addToGroup(advertiserId: string) {
    this.setBaseUrlForSignalR();
    this.localAdvertiserId = advertiserId;
    return this.apiClient.callHttpPost({
      path: `/api/addToGroup/${advertiserId}?code=${this.accessCode}`,
      version: '',
      param: null,
    });
  }
  removeFromGroup(advertiserId: string) {
    this.setBaseUrlForSignalR();
    this.localAdvertiserId = '';
    return this.apiClient.callHttpPost({
      path: `/api/removeFromGroup/${advertiserId}?code=${this.accessCode}`,
      version: '',
      param: null,
    });
  }

  // tempoarary function to test signalR

  sendMessageToUser(data: SignalRNotification) {
    this.setBaseUrlForSignalR();
    console.log('message sent', data);
    return this.apiClient
      .callHttpPost({
        path: `/api/sendMessage?code=${this.accessCode}`,
        param: data,
        version: '',
      })
      .subscribe(
        (response) => console.log('response from sending message: ', response),
        (err) => console.log('error sending message: ', err)
      );
  }

  // tempoarary function to test signalR

  sendMessageToGroup(data: {}, advertiserId: string) {
    this.setBaseUrlForSignalR();
    return this.apiClient.callHttpPost({
      path: `/api/sendMessage/${advertiserId}?code=${this.accessCode}`,
      param: data,
      version: '',
    });
  }

  disconnectSignalR() {
    if (this.connection.stop)
    this.connection
      .stop()
      .then(() => console.log('disconnected from SignalR'))
      .catch((err) => console.log('error disconnecting from SignalR: ', err));
  }
}

/* ====================================== CLIENT NOTES =================================================
 *
 * signalR:
 *
 *      This comes from the package @microsoft/signalr. It's what you will use to actually
 *      connect to the SignalR service to receive messages.
 *
 *          ex: npm install @microsoft/signalr
 *
 * axios:
 *
 *      This is what the demo I got this from uses to make HTTP calls. Frontend and mobile
 *      may have other libraries/components to use, but I am okay with this.
 *
 * apiBaseUrl:
 *
 *      This will be the url of the actual Azure Function application that must be made.
 *
 *          Development: https://transitiv-microservices-notifications-development.azurewebsites.net/
 *          Production: https://transitiv-microservices-notifications.azurewebsites.net/
 *
 * accessCode:
 *
 *      This will be required for any HTTP call to the Azure Function application. The code is
 *      different for development and production. You must add it as a query parameter to the HTTP
 *      request with the name 'code'.
 *
 *          Development: 6IE3EI5tYNCdxILCml5dg6x265Qada0nonZHD6wiihsJ55tlEsEtog==
 *          Production: <Will update when I make the Function App>
 *
 *          ex: http://localhost:7071/api/1234567890/negotiate?code=<accessCode>
 *
 * advertiserId:
 *
 *      This will be the advertiserId associated with the page that the user is currently on.
 *
 * token:
 *
 *      This is the access token that you get from the auth server, and use to pass into calls to
 *      Management App. It should be used the same way, i.e. in the Authorization header.
 *
 * signalRMethod:
 *
 *      This is the name of the method that will be called by SignalR when a notification is
 *      sent out. This cannot change, as this is the name that the server will be coded to
 *      use. So you just have to deal with my epic naming on this one. :D
 *
 * getConnectionInfo():
 *
 *      This call is what retrieves the information that you will use to connect to the SignalR
 *      service. If the token that is passed in is not valid, you will get 401 Unauthorized from
 *      the endpoint. Otherwise, you will get 200 Ok with the following body.
 *
 *          {
 *              "url": <String>,
 *              "accessToken": <String>
 *          }
 *
 *      The returned accessToken IS NOT the same as the token from the auth server, and the two
 *      are not interchangeable. The returned accessToken is for use only when dealing with SignalR.
 *
 * notificationReceived():
 *
 *      This is the method that will get called by SignalR when a notification is sent. This is where
 *      you will handle the notification. The method name must be the same as signalRMethod.
 *
 * acknowledge( acknowledgedKey ):
 *
 *      This will be called periodically to inform the server the most current notification that you
 *      have received. A valid acknowledgedKey will be passed with every message that you receive,
 *      and that is the key that must be passed into this function. If the token that is passed in is
 *      not valid, you will get 401 Unauthorized from the endpoint. Otherwise, you will get 200 Ok
 *      with an empty body.
 *
 * addToGroup():
 *
 *      This will be used to manually add a user to a group. This is only necessary when the user
 *      changes pages after first connecting to SignalR. When the user first connects, an event
 *      is sent out, and the server will automatically subscribe them to the advertiser passed
 *      in to /negotiate.
 *
 * removeFromGroup():
 *
 *      This will be used to manually remove a user from a group. This should be performed when the
 *      user moves to a new page. You should unsubscribe them from the previous advertiser. The
 *      server will automatically remove the user from the last group they were registered to
 *      when they get disconnected. So if an unexpected disconnect is experience, you will also
 *      need to resubscribe the user after a connection is reestablished.
 *
 * sendMessageToUser(data): <-- This is a temporary endpoint for testing. Do not rely upon it in production code.
 *
 *      A message sent to this endpoint will be sent directly to the user associated with the auth
 *      token in Authorization. Information on the shape of data will be posted at the bottom.
 *
 *
 * function sendMessageToGroup(data): <-- This is a temporary endpoint for testing. Do not rely upon it in production code.
 *
 *      A message sent to this endpoint will be sent to the group associated with advertiserId, not
 *      only to the user associated with the auth token in Authorization. If the user is not a
 *      member of that group, they should not receive the message. Information on the shape of data
 *      will be posted at the bottom.
 *
 *
 * Message Data Shapes:
 *
 *  {
 *    "AdvertiserId": <Guid>,
 *    "UserId": <Guid>,
 *    "Timestamp": <DateTime>,
 *    "NotificationType": <Enum: NotificationType>,
 *    "Message": <String>,
 *    "Data": <Dictionary<String, String>>,
 *  }
 *
 *  NotificationType Enum: This Enum indicates what keys to expect in Data.
 *      Event: 1,
 *      OrchestratedTask: 2,
 *      MobilePush: 3 ( Currently not in use and there is no shape for it. )
 *
 *
 *  NOTE: While the following shapes give my typical data type, they will all come in as String and
 *        thus will be quoted. The type specified is the actual type that it was originally, and if
 *        you need to do any parsing that is the type that it should be parsed to.
 *
 *  Event Data Dictionary Keys and Types:
 *  {
 *    "EventType": <String>,
 *    "EventId": <Integer>,
 *    "ParentId": <Integer>,
 *    "EventCompletionTime": <DateTime: ISO Format>
 *  }
 *
 *  OrchestratedTask Data Dictionary Keys and Types:
 *  {
 *    "TaskId": <String>,
 *    "JobType": <String>,
 *    "JobSuccessful": <Boolean>,
 *    "JobCompletionTime": <DateTime: ISO Format>
 *  }
 *
 * ===================================================================================================== */
