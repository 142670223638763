import { AppState } from '../state';
import * as AuthActions from './auth.actions';
import { AuthState } from './auth.model';

export type Action = AuthActions.All;

/**
 * Define all store queries for Auth(s)
 */
export namespace AuthQuery {
  export const auth = (state: AppState) => state.auth;
  export const authenticated = (state: AppState) => state.auth.authenticated;
}


/// Reducer function
export function authReducer(state: AuthState, action: Action): AuthState {

  switch (action.type) {
  case AuthActions.AUTH_AUTHENTICATE:
    return { ...state, loading: true, authenticated: false, status: action.type};
  case AuthActions.AUTH_AUTHENTICATE_SUCCESS:
    return { ...state, user: action.payload.userName, authenticated: true, loading: false, status: action.type };
  case AuthActions.AUTH_AUTHENTICATE_FAIL:
    return { ...state, error: action.payload, authenticated: false, loading: false, status: action.type };
  case AuthActions.SET_AUTH_AUTHENTICATED:
    return { ...state, authenticated: action.payload, authError: action.error, loading: false, status: action.type };
  case AuthActions.AUTH_LOGOUT:
    return { ...state, loading: true, authenticated: false, status: action.type};
  case AuthActions.AUTH_LOGOUT_SUCCESS:
    return { ...state, loading: false, authenticated: false, error: '', status: action.type };
  case AuthActions.AUTH_LOGOUT_FAIL:
    return { ...state, error: action.payload, authenticated: false, loading: false, status: action.type };
  default:
    return state;
  }
}

export const isAuthenticated = (state: AuthState) => state.authenticated;
