import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityMap } from '../entity/entity-metadata';
import { CommonUtilityService } from '@services/utility/common-utility.service';
import { AdvertiserLocations } from '../../../components/locations/interfaces';
import { LocationService } from '@services/location/location.service';

@Injectable()
export class LocationsDataService extends DefaultDataService<AdvertiserLocations> {
  groupId?: string;
  locationService: LocationService|undefined;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super(EntityMap.Locations, http, httpUrlGenerator);
  }

  getAll(): Observable<AdvertiserLocations[]> {
    return this.locationService?.getAdvertiserLocations(this.groupId as string)
    .pipe(map((data) => data.map((loc) => CommonUtilityService.timestampStateData(loc)))) as Observable<AdvertiserLocations[]>;
  }
}
