import { Action } from '@ngrx/store';
import {
  SignalRConnectionInfo,
  SignalRNotification,
  ParsedTask,
} from './tasks.model';

export const TASK_GETALL = 'Task Get all';
export const TASK_GETALL_FAILED = 'Task Get all failed';
export const TASK_GETALL_SUCCESS = 'Task Get all successful';

// SIGNAL R ACTIONS
export const SIGNALR_GET_CONNECTION_INFO = 'SignalR Get connection info';
export const SIGNALR_GET_CONNECTION_INFO_FAILED =
  'SignalR Get connection info failed';
export const SIGNALR_START_CONNECTION = 'SignalR Start Connection';
export const SIGNALR_START_CONNECTION_SUCCESS =
  'SignalR Start Connection success';
export const SIGNALR_START_CONNECTION_FAILED =
  'SignalR Start Connection failed';

export const SIGNALR_ADD_TO_GROUP = 'Signal R Add to Group';
export const SIGNALR_ADD_TO_GROUP_SUCCESS = 'Signal R Add to Group Success';
export const SIGNALR_ADD_TO_GROUP_FAILED = 'Signal R Add to Group Failed';

export const SIGNALR_REMOVE_FROM_GROUP = 'Signal R Remove from Group';
export const SIGNALR_REMOVE_FROM_GROUP_SUCCESS =
  'Signal R Remove from Group Success';
export const SIGNALR_REMOVE_FROM_GROUP_FAILED =
  'Signal R Remove from Group Failed';

export const SIGNALR_SWITCH_ADVERTISER = 'Signal R Switch Advertiser';
export const SIGNALR_SWITCH_ADVERTISER_FAILED =
  'Signal R Switch Advertiser Failed';

// Singal R Received Notification
export const SINGALR_RECEIVED_NOTIFICATION = 'Signal R Recieved Notification';

export const SINGALR_CLEAR_NOTIFICATIONS = 'Signal R Clear Notifications';

export const SINGALR_DISMISS_ALL = 'Signal R Dismiss All';

export const SIGNALR_SET_FAILED = 'Task Set task failed';
export const SIGNALR_SET_FAIL_NOTIFIED = 'Task Set task fail notified';

export const TASK_SETATTRIBUTION_COMPLETE = 'Task Set attribution completed';
export const TASK_SETAUDIENCE_COMPLETE = 'Task Set audience completed';
export const TASK_CLEARAUDIENCE_COMPLETE = 'Task Clear audience completed';
export const TASK_SETSCV_COMPLETE = 'Task Set scv completed';
export const TASK_SETLTV_COMPLETE = 'Task Set ltv completed';
export const TASK_SETRFM_COMPLETE = 'Task Set rfm completed';
export const TASK_SETCUSTOMERGRAPHPERSONAQUERY_COMPLETE = 'Task Set CustomerGraphPersonaQuery completed';
export const TASK_SETCUSTOMERGRAPHAUDIENCEQUERY_COMPLETE = 'Task Set CustomerGraphAudienceQuery completed';
export const TASK_SETCUSTOMERQUERY_COMPLETE = 'Task Set CustomerQuery completed';
export const TASK_SETCUSTOMERCOUNTQUERY_COMPLETE = 'Task Set CustomerCountQuery completed';

export const TASK_SETATTRIBUTION_FAILED = 'Task Set attribution failed';
export const TASK_SETAUDIENCE_FAILED = 'Task Set audience failed';
export const TASK_CLEARAUDIENCE_FAILED = 'Task Clear audience failed';
export const TASK_SETSCV_FAILED = 'Task Set scv failed';
export const TASK_SETLTV_FAILED = 'Task Set ltv failed';
export const TASK_SETRFM_FAILED = 'Task Set rfm failed';
export const TASK_CLEAR_NOTIFICATIONS = 'Task clear notifications';
export const TASK_SET_NOTIFIED = 'Task Set task notified';
export const TASK_SETCUSTOMERGRAPHPERSONAQUERY_FAILED = 'Task Set CustomerGraphPersonaQuery failed';
export const TASK_SETCUSTOMERGRAPHAUDIENCEQUERY_FAILED = 'Task Set CustomerGraphAudienceQuery failed';
export const TASK_SETCUSTOMERQUERY_FAILED = 'Task Set CustomerQuery failed';
export const TASK_SETCUSTOMERCOUNTQUERY_FAILED = 'Task Set CustomerCountQuery failed';


export class TaskClearNotifications implements Action {
  readonly type = TASK_CLEAR_NOTIFICATIONS;
  constructor() {}
}

export class TaskSetNotified implements Action {
  readonly type = TASK_SET_NOTIFIED;
  constructor(public payload: string[]) {}
}

export class SignalRSetFailed implements Action {
  readonly type = SIGNALR_SET_FAILED;
  constructor(public payload: SignalRNotification[]) {}
}

export class SignalRSetFailNotified implements Action {
  readonly type = SIGNALR_SET_FAIL_NOTIFIED;
  constructor(public payload: SignalRNotification[]) {}
}

export class SignalRClearNotifications implements Action {
  readonly type = SINGALR_CLEAR_NOTIFICATIONS;
  constructor(public clearedNotification: SignalRNotification[]) {}
}

export class SignalRDismissAll implements Action {
  readonly type = SINGALR_DISMISS_ALL;
  constructor() {}
}

export class SignalRGetConnectionInfo implements Action {
  readonly type = SIGNALR_GET_CONNECTION_INFO;
  constructor(
    public addAdvertiserAfterConnection: boolean,
    public advertiserId: string
  ) {}
}
export class SignalRGetConnectionInfoFailed implements Action {
  readonly type = SIGNALR_GET_CONNECTION_INFO_FAILED;
  constructor(public errorMessage: string) {}
}

export class SignalRStartConnection implements Action {
  readonly type = SIGNALR_START_CONNECTION;
  constructor(public payload: SignalRConnectionInfo) {}
}

export class SignalRStartConnectionSuccess implements Action {
  readonly type = SIGNALR_START_CONNECTION_SUCCESS;
  constructor() {}
}

export class SignalRStartConnectionFailed implements Action {
  readonly type = SIGNALR_START_CONNECTION_FAILED;
  constructor(public errorMessage: string) {}
}

export class SignalRAddToGroup implements Action {
  readonly type = SIGNALR_ADD_TO_GROUP;
  constructor(public advertiserId: string) {}
}

export class SignalRAddToGroupSuccess implements Action {
  readonly type = SIGNALR_ADD_TO_GROUP_SUCCESS;
  constructor() {}
}

export class SignalRAddToGroupFailed implements Action {
  readonly type = SIGNALR_ADD_TO_GROUP_FAILED;
  constructor(public errorMessage: string) {}
}

export class SignalRRemoveFromGroup implements Action {
  readonly type = SIGNALR_REMOVE_FROM_GROUP;
  constructor(public advertiserId: string) {}
}

export class SignalRRemoveFromGroupSuccess implements Action {
  readonly type = SIGNALR_REMOVE_FROM_GROUP_SUCCESS;
  constructor() {}
}

export class SignalRRemoveFromGroupFailed implements Action {
  readonly type = SIGNALR_REMOVE_FROM_GROUP_FAILED;
  constructor(public errorMessage: string) {}
}

export class SignalRSwitchAdvertiser implements Action {
  readonly type = SIGNALR_SWITCH_ADVERTISER;
  constructor(public advertiserId: string) {}
}

export class SignalRSwitchAdvertiserFailed implements Action {
  readonly type = SIGNALR_SWITCH_ADVERTISER_FAILED;
  constructor(public errorMessage: string) {}
}

export class SignalRReceivedNotification implements Action {
  readonly type = SINGALR_RECEIVED_NOTIFICATION;
  constructor(
    public payload: {
      notifications: SignalRNotification[];
      uncompletedTasks: ParsedTask[];
      unnotified: string[];
      failedTasks: SignalRNotification[];
    }
  ) {}
}

export class TaskGetAll implements Action {
  readonly type = TASK_GETALL;
  constructor(public payload: string) {}
}

export class TaskSetAttributionComplete implements Action {
  readonly type = TASK_SETATTRIBUTION_COMPLETE;
  constructor(public payload: string) {}
}

export class TaskSetAudienceComplete implements Action {
  readonly type = TASK_SETAUDIENCE_COMPLETE;
  constructor(public payload: string) {}
}

export class TaskClearAudienceComplete implements Action {
  readonly type = TASK_CLEARAUDIENCE_COMPLETE;
  constructor() {}
}

export class TaskSetScvComplete implements Action {
  readonly type = TASK_SETSCV_COMPLETE;
  constructor(public payload: string) {}
}

export class TaskSetLtvComplete implements Action {
  readonly type = TASK_SETLTV_COMPLETE;
  constructor(public payload: string) {}
}

export class TaskSetRfmComplete implements Action {
  readonly type = TASK_SETRFM_COMPLETE;
  constructor(public payload: string) {}
}

export class TaskSetCustomerGraphPersonaQueryComplete implements Action {
  readonly type = TASK_SETCUSTOMERGRAPHPERSONAQUERY_COMPLETE;
  constructor(public payload: string) {}
}

export class TaskSetCustomerGraphAudienceQueryComplete implements Action {
  readonly type = TASK_SETCUSTOMERGRAPHAUDIENCEQUERY_COMPLETE;
  constructor(public payload: string) {}
}

export class TaskSetCustomerQueryComplete implements Action {
  readonly type = TASK_SETCUSTOMERQUERY_COMPLETE;
  constructor(public payload: string) {}
}

export class TaskSetCustomerCountQueryComplete implements Action {
  readonly type = TASK_SETCUSTOMERCOUNTQUERY_COMPLETE;
  constructor(public payload: string) {}
}

export class TaskSetAttributionFailed implements Action {
  readonly type = TASK_SETATTRIBUTION_FAILED;
  constructor(public payload: string) {}
}

export class TaskSetAudienceFailed implements Action {
  readonly type = TASK_SETAUDIENCE_FAILED;
  constructor(public payload: string) {}
}

export class TaskClearAudienceFailed implements Action {
  readonly type = TASK_CLEARAUDIENCE_FAILED;
  constructor() {}
}

export class TaskSetScvFailed implements Action {
  readonly type = TASK_SETSCV_FAILED;
  constructor(public payload: string) {}
}

export class TaskSetLtvFailed implements Action {
  readonly type = TASK_SETLTV_FAILED;
  constructor(public payload: string) {}
}

export class TaskSetRfmFailed implements Action {
  readonly type = TASK_SETRFM_FAILED;
  constructor(public payload: string) {}
}

export class TaskSetCustomerGraphPersonaQueryFailed implements Action {
  readonly type = TASK_SETCUSTOMERGRAPHPERSONAQUERY_FAILED;
  constructor(public payload: string) {}
}

export class TaskSetCustomerGraphAudienceQueryFailed implements Action {
  readonly type = TASK_SETCUSTOMERGRAPHAUDIENCEQUERY_FAILED;
  constructor(public payload: string) {}
}

export class TaskSetCustomerQueryFailed implements Action {
  readonly type = TASK_SETCUSTOMERQUERY_FAILED;
  constructor(public payload: string) {}
}

export class TaskSetCustomerCountQueryFailed implements Action {
  readonly type = TASK_SETCUSTOMERCOUNTQUERY_FAILED;
  constructor(public payload: string) {}
}

export type All =
  | TaskGetAll
  | TaskSetAttributionComplete
  | TaskSetAudienceComplete
  | TaskClearAudienceComplete
  | TaskSetScvComplete
  | TaskSetLtvComplete
  | TaskSetRfmComplete
  | TaskSetCustomerGraphPersonaQueryComplete
  | TaskSetCustomerGraphAudienceQueryComplete
  | TaskSetCustomerQueryComplete
  | TaskSetCustomerCountQueryComplete
  | TaskSetAttributionFailed
  | TaskSetAudienceFailed
  | TaskClearAudienceFailed
  | TaskSetScvFailed
  | TaskSetLtvFailed
  | TaskSetRfmFailed
  | TaskSetCustomerGraphPersonaQueryFailed
  | TaskSetCustomerGraphAudienceQueryFailed
  | TaskSetCustomerQueryFailed
  | TaskSetCustomerCountQueryFailed
  | TaskClearNotifications
  | TaskSetNotified
  | SignalRGetConnectionInfo
  | SignalRGetConnectionInfoFailed
  | SignalRStartConnection
  | SignalRStartConnectionSuccess
  | SignalRStartConnectionFailed
  | SignalRAddToGroup
  | SignalRAddToGroupSuccess
  | SignalRAddToGroupFailed
  | SignalRRemoveFromGroup
  | SignalRRemoveFromGroupSuccess
  | SignalRRemoveFromGroupFailed
  | SignalRSwitchAdvertiser
  | SignalRSwitchAdvertiserFailed
  | SignalRReceivedNotification
  | SignalRClearNotifications
  | SignalRSetFailed
  | SignalRSetFailNotified
  | SignalRDismissAll;
