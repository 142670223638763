import { StringKeyStringValue } from './common.interface';

export interface HighchartPoint {
  value: number;
}

export interface HighchartData {
  name?: string;
  type?: string;
  yAxis?: number;
  xAxis?: number;
  borderWidth?: number;
  borderColor?: string;
  data: number[]|number[][]|number[][][]|({
    name?: string;
    y: number;
    sliced?: boolean;
    selected?: boolean;
    desc?: string;
    color?: string;
  })[];
  dataSorting?: {
    enabled?: boolean
  };
  color?: string;
  pointWidth?: number;
  grouping?: boolean;
  grouppadding?: number;
  tooltip?: {
    pointFormat: string,
    headerFormat?: string
  };
  colorByPoint?: boolean;
  dataLabels?: {
    enabled: boolean,
    color: string
  };
  stack?: string;
}

export interface HighchartsTooltip {
  headerFormat?: string;
  pointFormat?: string;
  footerFormat?: string;
  shared?: boolean;
  useHTML?: boolean;
  formatter?: () => string;
}

export interface HighchartsPlotoptionEvent {
  click?: () => boolean;
  legendItemClick?: () => boolean;
  legendGroupClick?: () => boolean;
}

export interface HighchartPlotoptionSeries {
  states?: {
    inactive: {
      opacity: number;
    };
  };
  turboThreshold?: number;
}

export interface HighchartsColoraxis {
  reversed?: boolean;
  min?: number;
  minColor?: string;
  maxColor?: string;
  stops?: (string|number)[][];
  labels?: {
    enabled?: boolean;
    formatter?: () => string;
  };
}

export interface HighchartsLegend {
  enabled?: boolean;
  align?: string;
  layout?: string;
  margin?: number;
  verticalAlign?: string;
  y?: number;
  x?: number;
  symbolHeight?: number;
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: string;
  shadow?: boolean;
}

export interface HighchartsResponsive {
  rules?: {
    condition: {
      maxWidth: number;
    };
    chartOptions: {
      legend: {
        align: string;
        verticalAlign: string;
        layout: string;
      };
      yAxis: {
        labels: {
          align: string;
          x: number;
          y: number;
        };
        title: {
          text: string|null;
        };
      };
      subtitle: {
        text: string|null
      },
      credits: {
        enabled: boolean
      }
    };
  }[];
}

export interface HighchartsAxiaLabel {
  formatter?: (c: HighchartPoint) => string;
  enabled?: boolean;
}

export interface HighchartsDateFormats {
  day: string;
  week: string;
  month: string;
  year: string;
}

export interface HighchartAxisStyle {
  fontFamily: string;
  color?: string;
}

export interface HighchartsAxisStackLabel {
  enabled?: boolean;
  style?: {
    fontWeight?: string;
    color?: string;
  };
}

export interface StaticChartLabel {
  style: {
    color: string;
    fontFamily: string;
  };
}

export interface YAxis {
  type?: string;
  data?: string[];
  text?: string;
  title?: {text: string|null};
  titleMargin?: number;
  reversed?: boolean;
  min?: number;
  max?: number;
  opposite?: boolean;
  labels?: HighchartsAxiaLabel|StaticChartLabel;
  lineWidth?: number;
  style?: HighchartAxisStyle;
  stackLabels?: HighchartsAxisStackLabel;
}

export interface XAxis {
  dateTimeLabelFormats?: HighchartsDateFormats;
  data?: string[]|Date[];
  text?: string;
  reversed?: boolean;
  min?: number;
  max?: number;
  type?: string;
  tickInterval?: number;
  labels?: HighchartsAxiaLabel;
  crosshair?: boolean|{
    width: number,
  };
  title?: {
    text: string|null
  };
  opposite?: boolean;
  categories?: string[];
  offset?: number;
}

export interface ChartBoxInfo {
  chartWidth: number;
  chartHeight: number;
  text: string;
  total: string;
  totalOffset: number;
  totalDigitOffset: number;
  offset: number;
  titleOffset: number;
  boxWidthOffset?: number;
}

export interface HighchartsXAxisConfig {
  title: {
    text: string;
  };
  categories?: string[];
  min?: number;
  max?: number;
  type: string;
  tickInterval?: number;
  labels: HighchartsAxiaLabel;
  crosshair: boolean;
  opposite: boolean;
  dateTimeLabelFormats?: HighchartsDateFormats;
  offset?: number
}
export interface HighchartsPlotOption {
  series?: HighchartPlotoptionSeries;
  stacking?: string;
  pointPadding?: number;
  borderWidth?: number;
  borderRadius?: number;
  dataLabels?: {
    enabled?: boolean;
    color?: string;
  };
  events?: HighchartsPlotoptionEvent;
  column?: {
    pointPadding: number,
    borderWidth: number,
    stacking?: string;
  };
  pie?: {
    allowPointSelect?: boolean,
    cursor?: string,
    dataLabels?: {
      enabled: boolean,
      format?: '<b>{point.name}</b>: {point.percentage:.1f} %'
    }
    showInLegend?: boolean
  }
}

export interface ChartConfig {
  data: HighchartData[];
  type?: string;
  marginTop?: number;
  marginBottom?: number;
  plotBorderWidth?: number;
  title?: string;
  titleMargin?: number;
  titleAlign?: string;
  titleStyle?: StringKeyStringValue;
  xAxis?: XAxis | XAxis[];
  yAxis?: YAxis | YAxis[];
  tooltip?: HighchartsTooltip;
  plotOptions?: HighchartsPlotOption;
  colorAxis?: HighchartsColoraxis;
  legend?: HighchartsLegend;
  style?: {
    fontFamily?: string;
  };
  responsive?: HighchartsResponsive;
}

export const colors = {
  // blue
  blueShade: '#b3d9ff',
  lightestBlue: '#55C9F2',
  lightBlue: '#1C9BD3',
  blue: '#027ABC',
  signUpBkgrnd: '#0E78BC',

  // red
  maroon: '#800000',
  orange: '#f7941d',

  // gray
  black: '#000000',
  textColor: '#333333',
  headerBackground: '#424242',
  tabIconColor: '#808080',
  lightGray: '#D3D3D3',
  borderGray: '#cccccc',
  white: '#FFFFFF'
};
