
import {switchMap, map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import {AppState} from '../state';
import * as IntegrationsActions from './integrations.actions';
import { LoginService } from '@services/login/login.service';
import { IntegrationsService } from '@services/integrations/integrations.service';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { IntegrationsState } from './integrations.model';
import { IntegrationsQuery } from './integrations.reducer';
import { updateIntegrationsState } from '../global/shared/shared.store';
type Action = IntegrationsActions.All;

@Injectable()
export class IntegrationsFacade {
  integrations$ = this.store.select(IntegrationsQuery.integrations);

  getAllIntegrations$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(IntegrationsActions.INTEGRATIONS_GETALL),
    map((action: IntegrationsActions.IntegrationsGetAll) => action),
    switchMap((action) => {
      return this.integrationsService.getAccountIntegrations(action.payload).pipe(
      map((integrations) => {
        return new IntegrationsActions.IntegrationsGetAllSuccess(integrations.data);
      }),
      catchError(err => of (new IntegrationsActions.IntegrationsGetAllFailed(err)) ), );
    }), ));

  getIntegration$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(IntegrationsActions.INTEGRATIONS_GET),
    map((action: IntegrationsActions.IntegrationsGet) => action),
    switchMap((action) => {
      return this.integrationsService.getAccountIntegration(action.id, action.intId).pipe(
      map((integrations) => {
        return new IntegrationsActions.IntegrationsGetSuccess(integrations.data);
      }),
      catchError(err => of (new IntegrationsActions.IntegrationsGetFailed(err)) ), );
    }), ));

  getAllIntegrationsSucess$: Observable<Action> = createEffect(() => this.actions$
  .pipe(
    ofType(
      IntegrationsActions.INTEGRATIONS_GETALL_SUCCESS,
      IntegrationsActions.INTEGRATIONS_GETALL_FAILED,
      IntegrationsActions.INTEGRATIONS_GET_SUCCESS,
      IntegrationsActions.INTEGRATIONS_GET_FAILED,
      IntegrationsActions.INTEGRATIONS_CLEARED,
    ),
    map((action) => action),
    switchMap(() => {
      return of(updateIntegrationsState(this.getState())).pipe(
      map(() => {
        return new IntegrationsActions.IntegrationsSetGlobalState();
      }),
      catchError(err => of (new IntegrationsActions.IntegrationsSetGlobalState()) ), );
    }), ));

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private loginService: LoginService,
    private integrationsService: IntegrationsService
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.loginService.apiClient = this.apiClient;
  }

  getAllIntegrations(id: string): void {
    this.store.dispatch(new IntegrationsActions.IntegrationsGetAll(id));
  }

  getIntegration(id: string, intId: string): void {
    this.store.dispatch(new IntegrationsActions.IntegrationsGet(id, intId));
  }

  clearIntegration():void {
    this.store.dispatch(new IntegrationsActions.IntegrationsCleared())
  }

  private getState(): IntegrationsState {
    let state = <IntegrationsState>{};

    this.store.select(IntegrationsQuery.integrations).subscribe(s => {
      state = s;
    }).unsubscribe();

    return state;
  }
}
