import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-label',
  template: `
    <label class="nlabel" style="display:inline-block;">{{label}}<span *ngIf="isRequired" style="color: red;">*</span></label>`,
  styles: [],
})
export class LabelFormElementComponent implements OnInit {
  @Input() label = '';
  @Input() id = '';
  @Input() isRequired = false;

  constructor() {
  }

  ngOnInit() {
  }
}
