<div id="login" class="login-body">
  <div class="login-panel ui-fluid">
    <div class="login-panel-header">
      <img [src]="logo" alt="Transitiv Logo" id="transitiv-logo" />
    </div>
    <div class="login-panel-content">
      <h2 class="text-center mar-t-30 mar-b-10" id="page-title">
        Forgot Password
      </h2>
      <div class="row flush centered">
        <div class="ui-g">
          <div class="ui-g-12 nopadding-top">
            <form *ngIf="!complete">
              <p class="mar-b-30" style="font-size: 12px;" id="page-message">
                Enter the email address associated with your Transitiv account.
              </p>
              <span class="md-inputfield">
                <app-form-input
                  type="text"
                  placeholder="Email"
                  name="Email"
                  id="email"
                  [(ngModel)]="formData.Email"
                  cssClass="ui-inputtext ui-corner-all ui-widget"
                ></app-form-input>
              </span>
              <div class="alert alert-danger plainalert" *ngIf="error">
                <i class="fa fa-warning"></i> Error! {{ error }}
              </div>
              <div class="mar-t-40">
                <app-form-button
                  cssClass="msbtn flatbtn"
                  itemId="btnSubmit"
                  buttonType="raised"
                  *ngIf="!isLoading"
                  (onClick)="resetPassword()"
                  analytics-on="click"
                  analytics-event="click"
                  label="Reset Password"
                  analytics-category="ForgotPassword"
                  analytics-label="Root_Button_ResetPassword"
                  analytics-value="1"
                >
                </app-form-button>
                <app-form-button
                  cssClass="btn btn-primary"
                  buttonType="raised"
                  *ngIf="isLoading"
                  disabled
                  label="Saving Changes"
                >
                  <i class="fa fa-spinner fa-spin"></i> Saving Changes
                </app-form-button>
              </div>
            </form>
          </div>
        </div>
        <div class="ui-g-12">
          <p class="divider"><span>OR</span></p>
        </div>
        <div class="ui-g-12 centered">
          <app-form-button
            cssClass="loginemail flatbtn"
            itemId="login-button"
            label="Log In"
            buttonType="raised"
            [routerLink]="['/login']"
            analytics-on="click"
            analytics-event="click"
            analytics-category="ChangePassword"
            analytics-label="Root_Button_Login"
            analytics-value="1"
          >
          </app-form-button>
        </div>
        <div *ngIf="complete">
          <h4 class="centered">Reset Email Sent</h4>
          <p>Check your email spam folders for a reset link.</p>
        </div>
      </div>
    </div>
  </div>
</div>
