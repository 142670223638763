<div
  class="row mar-b-50 inputfield_ tableInputComponentContainer"
  [ngClass]="{
    is_block_full: field?.isBlock,
    haselement: field?.hasElement,
    is_col_6: field?.size === 'xxxsmall',
    is_col_5: field?.size === 'xxsmall',
    is_col_4: field?.size === 'xsmall',
    is_col_3: field?.size === 'small',
    is_col_2: field?.size === 'medium',
    is_fullwidth: !field?.size || field?.size === 'large'
  }"
>
  <div [ngClass]="{ i_tooltip: field?.hasTooltip }">
    <div class="tableContainer">
      <ng-container
        *ngFor="let column of field?.tableConfig; let index = index"
      >
        <div class="columnContainer">
          <div class="columnNameContainer">
            <div class="name">{{ column.label }}</div>
          </div>
        </div>
      </ng-container>
    </div>
    <div
      class="rowContainer"
      *ngFor="let row of tableData; let rowIndex = index"
    >
      <ng-container *ngFor="let key of tableKeys(row)">
        <div
          class="dataContainer"
          *ngIf="displayTableHelper(key, 'type') === 'input'"
        >
          <app-form-input
            [id]="key"
            [name]="key"
            [readonly]="isReadonly()"
            (onTextChange)="changed($event, key, rowIndex)"
            [validations]="field?.validations"
            [cssStyle]="field?.style"
            [placeholder]="placeholderAdjuster(displayTableHelper(key))"
            [disabled]="disabled()"
            [value]="row[key]"
          ></app-form-input>
        </div>
        <div
          class="dataContainer"
          *ngIf="displayTableHelper(key, 'type') === 'select'"
        >
          <app-form-select
            [filter]="field.filter"
            [placeholder]="placeholderAdjuster(displayTableHelper(key))"
            [validations]="field.validations"
            [options]="displayTableHelper(key, 'dataSource')"
            [name]="key"
            [value]="row[key]"
            [itemId]="key"
            [disabled]="disabled()"
            (onChange)="changed($event.value, key, rowIndex)"
            [autoWidth]="false"
          ></app-form-select>
        </div>
      </ng-container>
      <div class="removeRowBtn" *ngIf="tableData.length > 1">
        <a
          mat-mini-fab
          color="warn"
          matTooltip="Remove Row"
          [matTooltipPosition]="'after'"
          (click)="handleRemoveTableRow(rowIndex)"
          style="outline: none;"
        >
          <mat-icon >remove</mat-icon>
        </a>
      </div>
    </div>
    <a
      mat-button
      color="primary"
      class="addRowContainer"
      (click)="addTableRow()"
      style="outline: none;"
    >
      <mat-icon>add</mat-icon>
      <div class="btnText">Add Row</div>
    </a>
    <div
      class="col-md-4 col-sm-4 nopadding"
      *ngIf="field?.suffix || field?.tooltip"
    >
      <label style="font-weight: normal;" *ngIf="field?.suffix">{{
        field?.suffix
      }}</label>
      <!-- Tooltip Info -->
      <div class="pri-tooltip lookback-info" *ngIf="field?.tooltip">
        <strong class="epsilon">
          <i
            class="fa fa-question-circle"
            style="color: #1e88e5; font-size: 16px;"
          >
          </i>
        </strong>
        <p class="content">
          <strong>{{ field?.tooltipTitle }}</strong>
          {{ field?.tooltip }}
        </p>
      </div>
    </div>
  </div>
  <div class="pri-tooltip lookback-info" *ngIf="field?.description">
    <strong class="epsilon">
      <i
        class="fa fa-question-circle"
        style="color: #1e88e5; font-size: 16px;"
      ></i>
    </strong>
    <p class="content">
      {{ field?.description }}
    </p>
  </div>
</div>
