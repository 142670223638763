import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { environment } from '@environments/environment';
import { IndeedService } from '@services/indeed/indeed.service';


@Component({
  selector: 'app-indeed',
  templateUrl: './indeed.component.html',
  styleUrls: ['./indeed.component.scss']
})
export class IndeedComponent implements OnInit {
  id = '';
  code = '';
  intid = '';
  logo = environment.defaultBlackLogo;
  isBrowser: boolean;
  constructor(
    private route: ActivatedRoute,
    private indeedService: IndeedService,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    @Inject(PLATFORM_ID) private platformId: Object, ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.apiClient.errorHandler = this.errorHandler;
    this.indeedService.apiClient = this.apiClient;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.code = params['code'];
    });
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.intid = params['intid'];
      this.authorize();
    });
  }

  authorize() {
    if (this.isBrowser) {
      this.indeedService
      .authorize(this.code, `${location.origin}/indeed-authorization/${this.id}/${this.intid || ''}`).subscribe(data => {
        localStorage.setItem('EVENT.PUB', JSON.stringify({code: data}));
        window.close();
      });
    }
  }
}
