import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityMap } from '../entity/entity-metadata';
import { AdvertiserService } from '@services/advertiser/advertiser.service';
import { Account } from '../../interfaces';
import { CommonUtilityService } from '@services/utility/common-utility.service';



@Injectable()
export class AccountDataService extends DefaultDataService<Account> {
  groupId?: string;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private accountService: AdvertiserService,
  ) {
    super(EntityMap.Account, http, httpUrlGenerator);
  }

  getAll(): Observable<Account[]> {
    return this.accountService.getAdvertisersByGroup(
      this.groupId as string).pipe(
        map((acct) => acct.data.map(data => CommonUtilityService.timestampStateData(data))));
  }
}
