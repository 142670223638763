import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator,
} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Campaign, CampaignPayload } from '../../interfaces';
import { CampaignService } from '@services/campaign/campaign.service';
import { Update } from '@ngrx/entity';
import { EntityMap } from '../entity/entity-metadata';

@Injectable()
export class CampaignDataService extends DefaultDataService<Campaign> {
  accountId?: string
  campaignService: CampaignService|undefined;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super(EntityMap.Campaign, http, httpUrlGenerator);
  }

  getAll(): Observable<Campaign[]> {
    return this.campaignService?.getCampaigns(this.accountId as string)
      .pipe(
        tap(result => this.campaignService?.postFormatCampaigns(result)),
        map((campaigns) => campaigns.map(campaign => this.mapCampaign(campaign)))) as Observable<Campaign[]>;
  }

  getById(id: string): Observable<Campaign> {
    return this.campaignService?.getCampaign({
      advertiserId: this.accountId as string,
      campaignId: id
    }).pipe(
      map((campaigns) => campaigns.map(campaign => this.mapCampaign(campaign))[0])) as Observable<Campaign>;
  }

  update(campaign: Update<CampaignPayload>): Observable<Campaign> {
    return this.campaignService?.editCampaign(
      campaign.changes.AccountId as string,
      campaign.changes.CampaignId as string, campaign.changes as CampaignPayload) as Observable<Campaign>;
  }

  add(campaign: Campaign): Observable<Campaign> {
    return this.campaignService?.addCampaign(
      campaign.AccountId as string, campaign as CampaignPayload) as Observable<Campaign>;
  }

  private mapCampaign(Campaign: Campaign): Campaign {
    return Campaign.dateLoaded ? Campaign : { ...Campaign, dateLoaded: new Date() };
  }
}
