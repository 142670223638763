import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { NotificationsService } from '@services/notification/notification.service';
import { AttributionFilter } from '../../interfaces';
import { AttributionBudget } from '../../interfaces/analytics.interface';
import { AppState } from '../state';
import * as analyticsAction from './analytics.actions';

@Injectable()
export class AnalyticsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private analyticsService: AnalyticsService,
    private notificationsService: NotificationsService,
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.analyticsService.apiClient = this.apiClient;
  }

  GetChannelGroupings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(analyticsAction.actionAnalyticsGetChannelGroupings),
      mergeMap((action) =>
        this.analyticsService.getChannelGroupings(action.id).pipe(
          map((data) => {
            return analyticsAction.actionAnalyticsGetChannelGroupingsSuccess({ channelGroupings: data });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(analyticsAction.actionAnalyticsError({ error: error, task: 'ChannelGrouping' }));
          })
        )
      )
    )
  );

  GetMarkov$ = createEffect(() =>
    this.actions$.pipe(
      ofType(analyticsAction.actionAnalyticsPostAttributionFilter),
      mergeMap((action) =>
        this.analyticsService.postAttributionFilter(action.id, action.payload).pipe(
          map((data) => {
            return analyticsAction.actionAnalyticsPostAttributionFilterSuccess({ markov: data });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(analyticsAction.actionAnalyticsError({ error: error, task: 'Markov' }));
          })
        )
      )
    )
  );

  GetBudgetAllocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(analyticsAction.actionAnalyticsPostBudgetAllocation),
      mergeMap((action) =>
        this.analyticsService.postBudgetAllocation(action.id, action.payload).pipe(
          map((data) => {
            return analyticsAction.actionAnalyticsPostBudgetAllocationSuccess({ response: data });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(analyticsAction.actionAnalyticsError({ error: error, task: 'Budget' }));
          })
        )
      )
    )
  );



  getChannelGroupings(id: string) {
    this.store.dispatch(analyticsAction.actionAnalyticsGetChannelGroupings({id}));
  }

  getMarkovData(id: string, payload: AttributionFilter) {
    this.store.dispatch(analyticsAction.actionAnalyticsPostAttributionFilter({id, payload}));
  }

  getBudgetAllocation(id: string, payload: AttributionBudget) {
    this.store.dispatch(analyticsAction.actionAnalyticsPostBudgetAllocation({id, payload}));
  }

}
