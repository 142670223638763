import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityMap } from '../entity/entity-metadata';
import { CommonUtilityService } from '@services/utility/common-utility.service';
import { MarkovResponse } from '../../interfaces/analytics.interface';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { AttributionFilter } from '../../interfaces';


@Injectable()
export class MarkovDataService extends DefaultDataService<MarkovResponse> {
  payload?: AttributionFilter;
  analyticsService: AnalyticsService|undefined;
  accountId?: string;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super(EntityMap.Markov, http, httpUrlGenerator);
  }

  getAll(): Observable<MarkovResponse[]> {
    return this.analyticsService?.postAttributionFilter(this.accountId as string, this.payload as AttributionFilter)
    .pipe(map((data) => [CommonUtilityService.timestampStateData(data)])) as Observable<MarkovResponse[]>;
  }
}
