import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator} from '@ngrx/data';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityMap } from '../entity/entity-metadata';
import { CommonUtilityService } from '@services/utility/common-utility.service';
import { TransitivDataSource } from './models/TransitivDataSource';
import { DataSourcesService } from '@services/data-sources/data-sources.service';

@Injectable()
export class DataSourceDataService extends DefaultDataService<TransitivDataSource> {
  groupId?: string

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private datasourcesService: DataSourcesService,
  ) {
    super(EntityMap.DataSource, http, httpUrlGenerator);
  }

  getAll(): Observable<TransitivDataSource[]> {
    return this.datasourcesService.getSchema(this.groupId as string)
      .pipe(
        map((sources) => sources.data.map(data => CommonUtilityService.timestampStateData(data)))) as Observable<TransitivDataSource[]>;
  }
}
