import { switchMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { AppState } from '../state';
import * as PushNotificationActions from './push-notification.actions';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { PushNotificationQuery } from './push-notification.reducer';
import { ClaimsService } from '@services/claims/claims.service';
import { ResourceClaim } from '../../interfaces/claim.interface';
import { CommonUtilityService } from '@services/utility/common-utility.service';
type Action = PushNotificationActions.All;

@Injectable()
export class PushNotificationFacade {
  pushNotification$ = this.store.select(PushNotificationQuery.pushNotification);
  allRoles = this.utilityService.doDefinitionObject(
    this.claimsService.roles,
    'Value'
  );


  getAdvertiserClaims$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(PushNotificationActions.GET_PUSH_CLAIMS),
    map((action: PushNotificationActions.GetPushClaim) => action),
    switchMap((action) => {
      let request;
      request = this.claimsService.getAdvertiserClaims2020(action.payload);

      return request.pipe(
        map((claims) => {
          claims.reduce((memo: ResourceClaim[], res) => {
            memo.push(this.formatGroupUserRow(res));
            return memo;
          }, []);
          return new PushNotificationActions.GetPushClaimSuccess(claims);
        }),
        catchError((err) => {
          return of(new PushNotificationActions.GetPushClaimFail(err));
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private claimsService: ClaimsService,
    private utilityService: CommonUtilityService
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.claimsService.apiClient = this.apiClient;
  }

  getAdvertiserClaims(groupId: string): void {
    this.store.dispatch(new PushNotificationActions.GetPushClaim(groupId));
  }

  formatGroupUserRow(row: ResourceClaim) {
    row.RoleData = this.allRoles[row.Roles as number];
    return row;
  }
}
