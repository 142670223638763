import { Component, OnInit } from '@angular/core';
import { FieldClass } from '../field/field.class';
@Component({
  selector: 'app-switch-field',
  template: ` <div
    class="mar-b-50 inputfield_"
    [ngClass]="{
      is_block_full: field?.isBlock,
      haselement: field?.hasElement,
      is_col_6: field?.size === 'xxxsmall',
      is_col_5: field?.size === 'xxsmall',
      is_col_4: field?.size === 'xsmall',
      is_col_3: field?.size === 'small',
      is_col_2: field?.size === 'medium',
      is_fullwidth: !field?.size || field?.size === 'large'
    }"
  >
    <div style="display:block;">
      <label class="nlabel" style="display:inline-block;"
        >{{ field?.label }}
        <span *ngIf="field?.isRequired" style="color: red;">*</span></label
      >
      <div class="pri-tooltip lookback-info" *ngIf="field?.description">
        <strong class="epsilon">
          <i
            class="fa fa-question-circle"
            style="color: #1e88e5;font-size: 16px;"
          ></i>
        </strong>
        <p class="content">
          {{ field?.description }}
        </p>
      </div>
    </div>
    <div style="display:block;">
      <label class="switchlabel">
        <span style="display:inline-block; padding-right: 10px;">{{
          field?.offLabel
        }}</span>
        <span style="display:inline-block;">
          <app-form-switch
            cssClass="_switch_"
            [id]="field?.id"
            (onChange)="onChange($event)"
            [group]="group"
            [name]="field?.name"
            [value]="field?.value"
          >
          </app-form-switch>
        </span>
        <span style="display:inline-block; padding-left: 10px;">
          {{ field?.onLabel }}
        </span>
      </label>
    </div>
  </div>`,
  styles: [],
})
export class SwitchFieldComponent extends FieldClass implements OnInit {
  constructor() {
    super();
  }
  ngOnInit() {}
}
