import { Injectable, Component } from '@angular/core';
import { NotificationsService } from '../notification/notification.service';
import { environment } from '../../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, StringKeyBooleanValue } from '../../shared/interfaces/common.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class VersionCheckService {

  constructor(private http: HttpClient,
    public dialog: MatDialog,
    private notificationsService: NotificationsService) {}
  static hash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  // this will be replaced by actual hash post-build.js
  currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  notified: StringKeyBooleanValue = {};

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(url: string, frequency = 1000 * 60 * environment.versionCheckInterval) {
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url: string) {
    // timestamp these requests to invalidate caches
    this.http.get(url + '?t=' + new Date().getTime())
    .subscribe(
      (response) => {
        const hash = (response as {version: string, hash: string}).hash;
        const hashChanged = this.hasHashChanged(this.currentHash, hash);
        // If new version, do something
        if (hashChanged && !this.notified[hash]) {
          // tslint:disable-next-line: no-use-before-declare
          this.dialog.open(VersionDialogComponent);
          this.notified[hash] = true;
        }
        // store the new hash so we wouldn't trigger versionChange again
        // only necessary in case you did not force refresh
        this.currentHash = hash;
      },
      (err: string) => {
        console.error(err, 'Could not get version');
      }
    );
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentHash: string, newHash: string) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }
    return currentHash !== newHash;
  }
}


@Component({
  templateUrl: 'version-check.html',
  styleUrls: ['./version-check.scss'],
})
export class VersionDialogComponent {
  reload() {
    window.location.reload();
  }
}
