import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator
} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntegrationType } from '../../../interfaces';
import { EntityMap } from '../../entity/entity-metadata';
import { CommonUtilityService } from '@services/utility/common-utility.service';
import { AccountIntegrationsService } from '@services/integrations/account/account-integrations.service';


@Injectable()
export class AccountIntegrationTypeDataService extends DefaultDataService<IntegrationType> {
  accountId?: string

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private integrationsService: AccountIntegrationsService
  ) {
    super(EntityMap.AccountIntegrationType, http, httpUrlGenerator);
  }

  getAll(): Observable<IntegrationType[]> {
    return this.integrationsService.getAccountIntegrationTypes(
      this.accountId as string).pipe(
        map((integrations) => integrations.data.map(data => CommonUtilityService.timestampStateData(data))));
  }

  getById(integrationId: string): Observable<IntegrationType> {
    return this.integrationsService.getAccountIntegrationType(
      this.accountId as string, integrationId).pipe(
        map((integrations) => CommonUtilityService.timestampStateData(integrations.data)));
  }
}
