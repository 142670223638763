import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ComponentCanDeactivateDirective } from './component-can-deactivate';
import { Observable, Observer } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogContentComponent } from '../../directives/confirmation-dialog/confirmation-dialog.directive';

@Injectable()
export class CanDeactivateGuard
  implements CanDeactivate<ComponentCanDeactivateDirective> {
  constructor(public dialog: MatDialog) {}

  canDeactivate(component: ComponentCanDeactivateDirective) {
    if (component.canDeactivate()) {
      return true;
    }
    return Observable.create((observer: Observer<boolean>) => {
      this.openConfirmDialog(observer);
    });
  }

  private openConfirmDialog(observer: Observer<boolean>) {
    const dialogRef = this.dialog.open(ConfirmationDialogContentComponent, {
      data: {
        title: 'Discard Changes?',
        message: `You are about to navigate away from this page with unsaved changes.
        If you continue, your changes will be lost. Do you want to discard changes?`,
        acceptButton: 'Discard',
        discardButton: 'Cancel',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        observer.next(true);
        observer.complete();
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }
}
