import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  Router,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { find, isEqual } from 'lodash-es';
import { AuthFacade } from '@shared/state/auth';
import { AuthService } from '@services/auth/auth.service';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { AppState } from '@shared/state/state';
import { ModuleConfig } from '@shared/interfaces/common.interface';
import { selectModuleConfigAndStatus } from '@shared/state/module-config/module-config.data.selectors';
import { dashboardPermissionHierarchy } from '@shared/models/permissions.model';
import { ActiveSelectionTypes } from '@shared/state/global';

/**
 * Prevent unauthorized activating and loading of routes
 * @class AuthGuard
 */
@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  authenticated = false;
  publicRoutes = ['account/manage'];
  lastUrl = '';
  activeSelectionType: ActiveSelectionTypes | undefined;
  selectedId = '';
  /**
   * @constructor
   */

  constructor(
    private authFacade: AuthFacade,
    private store: Store<AppState>,
    private authService: AuthService,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private router: Router
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.authService.apiClient = this.apiClient;
    this.setAuthenticated();
  }

  /**
   * True when user is authenticated
   * @method canActivate
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.store
      .select(selectModuleConfigAndStatus)
      .subscribe(({ permissions, activeSelectionType, selectedId }) => {
        if (
          this.authenticated &&
          route.data &&
          route.data.module &&
          route.data.module !== 'Auth' &&
          route.data.module !== 'Dashboard' &&
          permissions?.length &&
          !isEqual(this.activeSelectionType, activeSelectionType) &&
          !isEqual(this.selectedId, selectedId)
        ) {
          this.activeSelectionType = activeSelectionType;
          this.selectedId = selectedId;
          const mod = find(permissions, {
            ModuleFriendlyName: route.data.module,
          });
          if (!mod) {
            this.goToDashboard(permissions, activeSelectionType, selectedId);
          }
        }
      });
    return this.authenticate();
  }

  /**
   * True when user is authenticated
   * @method canLoad
   */
  canLoad(): Observable<boolean> {
    return this.authenticate();
  }

  authenticate(): Observable<boolean> {
    this.getUser();
    const observable = this.authFacade.authenticated$;
    observable.subscribe((authenticated) => {
      this.authenticated = authenticated;
      if (!authenticated) {
        this.goToLogin();
      }
    });

    return observable;
  }

  setAuthenticated() {
    this.authFacade.setAuthenticated();
  }

  goToLogin(): void {
    this.router.navigate(['login']);
  }

  goToDashboard(
    permissions: ModuleConfig[],
    selectedAccountType: ActiveSelectionTypes | undefined,
    selectedId: string
  ): void {
    let foundDashboardPermission = dashboardPermissionHierarchy.find(
      (dashboardPermission) => {
        return permissions.find(
          (permission) =>
            permission.ModuleNamespace === dashboardPermission.namespace
        );
      }
    );
    let route = '/dashboard';
    if (foundDashboardPermission) {
      route = foundDashboardPermission.route;
    }
    if (selectedAccountType === ActiveSelectionTypes.AccountGroup) {
      route += '/group/' + selectedId;
    } else {
      route += '/' + selectedId;
    }
    this.router.navigate([route]);
  }

  getUser(): void {
    if (this.authService.getToken()) {
      this.authService.getUserInfo().subscribe((user) => {
        if (!user) {
          this.goToLogin();
        }
      });
    } else {
      this.goToLogin();
    }
  }
}
