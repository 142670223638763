import { cloneDeep, filter, findIndex } from 'lodash-es';
import { AppState } from '../../state';
import * as CampaignAdSettingsActions from './campaign-ad-settings.actions';
import { CampaignAdSettings } from './campaign-ad-settings.model';
import { CreativeSegmentKey } from '../../../interfaces/creative-segment-key.interface';
import { getEndDate } from '../../../utils/general.utils';
import { DropdownOption, IntegrationDefinition, SegmentDefinition, FeedDefinition } from '../../../interfaces';

export type Action = CampaignAdSettingsActions.All;

/**
 * Define all store queries for CampaignAdSettings(s)
 */
export namespace CampaignAdSettingsQuery {
  export const campaignAdSettings = (state: AppState) => state.campaignAdSettings;
}


/// Reducer function
export function campaignAdSettingsReducer(state: CampaignAdSettings, action: Action) {

  switch (action.type) {
  case CampaignAdSettingsActions.RESET_CAMPAIGN_STATE:
    return {isNewAd: state ? state.isNewAd : null, saveData: {
      Name: '',
      IsActive: true,
      FlightStart: new Date,
      FlightEnd: getEndDate(),
      AdId: '',
      CampaignId: '',
      CreativeSegmentKeys: <CreativeSegmentKey[]>[],
      Width: 0,
      Height: 0,
      DynamicRotationType: 0,
    }, status: action.type};
  case CampaignAdSettingsActions.ADD_CAMPAIGN_AD_SEGMENTKEY:
    const stateClone = cloneDeep(state);
    if (stateClone && stateClone.saveData && stateClone.saveData.CreativeSegmentKeys.length < 10) { // @Todo store limit in config
      stateClone.saveData.CreativeSegmentKeys.push( {
        CreativeId: '',
        VariationId: '',
        SegmentId: '',
        ClickTagDestinationUrlOrColumnNames: {0: ''},
        FlightStart: new Date,
        FlightEnd: getEndDate(),
        Persistent: false,
        IsActive: true,
      });
    }
    stateClone.status = action.type;
  return stateClone;
  case CampaignAdSettingsActions.SET_CAMPAIGN_AD_FORM_DIRTY:
    return { ...state, isDirty: action.payload, status: action.type};
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_CREATIVES:
    return { ...state, creativeIsLoading: true, status: action.type};
  case CampaignAdSettingsActions.SET_CAMPAIGN_AD_NEW_AD:
    return { ...state, isNewAd: action.payload, status: action.type};
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_CREATIVES_SUCCESS:
    return { ...state, creatives: action.payload, creativeIsLoading: false, status: action.type };
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_CREATIVES_FAIL:
    return { ...state, creativeError: action.payload, creativeIsLoading: false, status: action.type };
  case CampaignAdSettingsActions.SAVE_CAMPAIGN_AD_DATA:
    return { ...state, creativeIsSaving: true, status: action.type};
  case CampaignAdSettingsActions.SAVE_CAMPAIGN_AD_DATA_SUCCESS:
    return { ...state, creativeIsSaving: false, status: action.type };
  case CampaignAdSettingsActions.SAVE_CAMPAIGN_AD_DATA_FAIL:
    return { ...state, adError: action.payload, creativeIsSaving: false, status: action.type };
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_VARIATION:
    return { ...state, variationIsLoading: true, status: action.type};
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_VARIATION_SUCCESS:
    const newState = cloneDeep(state);
    if (!newState.creativeVariations) {
      newState.creativeVariations = [];
    }
    newState.creativeVariations.push(action.payload);
    newState.variationIsLoading = false;
    newState.status = action.type;
    return newState;
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_VARIATION_FAIL:
    return { ...state, variationError: action.payload, variationIsLoading: false, status: action.type };
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_CAMPAIGNS:
    return { ...state, campaignIsLoading: true, status: action.type};
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_CAMPAIGNS_SUCCESS:
    return { ...state, campaign: action.payload, campaignIsLoading: false, status: action.type };
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_CAMPAIGNS_FAIL:
    return { ...state, campaignError: action.payload, campaignIsLoading: false, status: action.type };
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_ADS:
    return { ...state, adIsLoading: true, status: action.type};
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_ADS_SUCCESS:
    return { ...state, ad: action.payload, adIsLoading: false, status: action.type };
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_ADS_FAIL:
    return { ...state, adError: action.payload, adIsLoading: false, status: action.type };
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_FEED:
    return { ...state, feedIsLoading: true, status: action.type};
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_FEED_SUCCESS:
    const s = generateSegments(state, action.payload);
    return { ...state, feedDefinition: action.payload, segments: s, feedIsLoading: false, status: action.type };
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_FEED_FAIL:
    return { ...state, feedError: action.payload, feedIsLoading: false, status: action.type };
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_INTEGRATION:
    return { ...state, integrationIsLoading: true, status: action.type};
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_INTEGRATION_SUCCESS:
    const segment = generateSegmentsFromIntegrations(state, action.payload);
    return { ...state, integrations: action.payload, segments: segment, integrationIsLoading: false, status: action.type };
  case CampaignAdSettingsActions.GET_CAMPAIGN_AD_INTEGRATION_FAIL:
    return { ...state, integrationError: action.payload, integrationIsLoading: false, status: action.type };
  case CampaignAdSettingsActions.SET_CAMPAIGN_AD_AD_WIDTH:
    return { ...state, saveData: { ...state.saveData, Width: action.payload} , status: action.type };
  case CampaignAdSettingsActions.SET_CAMPAIGN_AD_AD_HEIGHT:
    return { ...state, saveData: { ...state.saveData, Height: action.payload} , status: action.type };
  case CampaignAdSettingsActions.SET_CAMPAIGN_AD_SAVE_DATA:
    return { ...state, saveData: action.payload ? action.payload : state.ad, status: action.type };
  case CampaignAdSettingsActions.SET_CAMPAIGN_AD_AD_NAME:
    return { ...state, saveData: { ...state.saveData, Name: action.payload} , status: action.type };
  case CampaignAdSettingsActions.SET_CAMPAIGN_AD_AD_FLIGHTSTART:
    return { ...state, saveData: { ...state.saveData, FlightStart: action.payload} , status: action.type };
  case CampaignAdSettingsActions.SET_CAMPAIGN_AD_AD_FLIGHTEND:
    return { ...state, saveData: { ...state.saveData, FlightEnd: action.payload} , status: action.type };
  case CampaignAdSettingsActions.SET_CAMPAIGN_AD_AD_ACTIVE:
    return { ...state, saveData: { ...state.saveData, IsActive: action.payload} , status: action.type };
  case CampaignAdSettingsActions.SET_CAMPAIGN_AD_AD_ROTATION:
    return { ...state, saveData: { ...state.saveData, DynamicRotationType: action.payload} , status: action.type };
  case CampaignAdSettingsActions.SET_CAMPAIGN_AD_PERSISTENT:
    return { ...state, saveData: { ...state.saveData, Persistent: action.payload}, status: action.type };
  case CampaignAdSettingsActions.TOGGLE_CAMPAIGN_AD_PERSISTENT:
    return { ...state, saveData: { ...state.saveData, Persistent: !state.saveData.Persistent}, status: action.type };
  case CampaignAdSettingsActions.SET_CAMPAIGN_AD_AD_SEGMENTKEY:
    const stateCopy = cloneDeep(state);
    if (stateCopy.saveData.CreativeSegmentKeys && stateCopy.saveData.CreativeSegmentKeys.length < 10) {
      const c = findIndex(stateCopy.saveData.CreativeSegmentKeys,
        {
          VariationId: action.payload.VariationId,
          CreativeId: action.payload.CreativeId,
          SegmentId: action.payload.SegmentId
        });
      if (c > -1) {
        stateCopy.saveData.CreativeSegmentKeys.splice(c, 1, action.payload);
      } else {
        stateCopy.saveData.CreativeSegmentKeys.push(action.payload);
      }
    }
    stateCopy.status = action.type;
    return stateCopy;
  case CampaignAdSettingsActions.DELETE_CAMPAIGN_AD_AD_SEGMENTKEY:
    const stateCopy2 = cloneDeep(state);
    const i = stateCopy2.saveData.CreativeSegmentKeys.indexOf(action.payload);
    stateCopy2.saveData.CreativeSegmentKeys.splice(i, 1);
    stateCopy2.status = action.type;
    return stateCopy2;
  case CampaignAdSettingsActions.EDIT_CAMPAIGN_AD_AD_SEGMENTKEY:
    const stateData = cloneDeep(state);
    const index = findIndex(stateData.saveData.CreativeSegmentKeys,
      {
        VariationId: action.original.VariationId,
        CreativeId: action.original.CreativeId,
        SegmentId: action.original.SegmentId
      });
    if (index > -1) {
      stateData.saveData.CreativeSegmentKeys.splice(index, 1, action.payload);
    }
    stateData.status = action.type;
    return stateData;
  default:
    return state;
  }
}

function generateSegments(s: CampaignAdSettings, feed: FeedDefinition) {
  let creativeList: DropdownOption[] = [{ label: 'Static', value: '' }];
  if (feed && feed.SegmentDefinitions) {
    const fieldSetting: DropdownOption = { label: 'Name', value: 'Id' };
    creativeList = [...creativeList, ...generateSelectOptions(feed.SegmentDefinitions, fieldSetting)];
  }
  if (s.integrations) {
    const adSetting: DropdownOption = {label: 'Name', value: 'IntegrationId'};
    const dynamicAds = filter(s.integrations, {Type: 800});
    const list = [...creativeList, ...generateSelectOptions(dynamicAds, adSetting)];
    creativeList = list;
  }

  return creativeList;
}

function generateSegmentsFromIntegrations(s: CampaignAdSettings, integrations: IntegrationDefinition[]) {
  let creativeList: DropdownOption[] = [{ label: 'Static', value: '' }];
  if (s.feedDefinition && s.feedDefinition.SegmentDefinitions) {
    const fieldSetting: DropdownOption = { label: 'Name', value: 'Id' };
    creativeList = [...creativeList, ...generateSelectOptions(s.feedDefinition.SegmentDefinitions, fieldSetting)];
  }
  if (integrations) {
    const adSetting: DropdownOption = {label: 'Name', value: 'IntegrationId'};
    const dynamicAds = filter(integrations, {Type: 800});
    creativeList = [...creativeList, ...generateSelectOptions(dynamicAds, adSetting)];
  }

  return creativeList;
}

function generateSelectOptions(
  optionDataSet: SegmentDefinition[]|IntegrationDefinition[],
  fieldSetting: DropdownOption = {label: 'Name', value: 'Name'},
  addEmptyField = false): DropdownOption[] {
  const options: DropdownOption[] = [];
  if (!optionDataSet) {
    return [{ label: '', value: '' }];
  }
  if (addEmptyField) {
    options.push({ label: 'Static', value: '' });
  }
  for (const optionData of optionDataSet) {
    options.push({ label: optionData[fieldSetting.label as string] as string, value: optionData[(fieldSetting.value as string)] as string});
  }
  return options;
}
