import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataSourcesSchemaAPIResponse } from '../../shared/state/data-sources/models/DataSourcesSchemaAPIResponse';
import { ApiDataService } from '../api/abstract/api.service';

@Injectable()
export class DataSourcesService {

  constructor(private apiClient: ApiDataService) { }

  getSchema(accountId: string): Observable<DataSourcesSchemaAPIResponse> {
    return this.apiClient.callHttpGet({path: `/accountgroups/${accountId}/datasources/getschema`, type: ''});
  }

  getPostConfig(accountId: string): Observable<DataSourcesSchemaAPIResponse> {
    return this.apiClient.callHttpGet({path: `accounts/${accountId}/datasources/sqltoconfig`, type: ''});
  }
}
