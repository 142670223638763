import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Location, PlatformLocation } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { Router, RouterEvent, ResolveEnd } from '@angular/router';
import {
  combineLatest,
  Observable,
  Subscription
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { cloneDeep, find, isEqual } from 'lodash-es';
import { AdvertiserService } from '@services/advertiser/advertiser.service';
import { AuthService } from '@services/auth/auth.service';
import {
  AccountState,
  AccountFacade,
  ActiveSelectionTypes,
} from '@shared/state/global/index';
import { AppMainComponent } from '../../../app-main.component';
import { environment } from '@environments/environment';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import {
  MenuItem,
  ModuleConfig,
} from '@shared/interfaces/common.interface';
import { CommonUtilityService } from '@services/utility/common-utility.service';
import { dashboardPermissionHierarchy } from '@shared/models/permissions.model';
import {
  Account,
  AccountType,
  AdvertiserPayload,
  IntegrationDefinition,
} from '@shared/interfaces';
import { ClaimsService } from '@services/claims/claims.service';
import { ResourceClaim } from '@shared/interfaces/claim.interface';
import { AccountEffects } from '@shared/state/advertisers/accounts.effects';
import { AppState } from '@shared/state/state';
import { selectAccountStateLoading } from '@shared/state/advertisers/accounts.selectors';
import { ModuleConfigEffects } from '@shared/state/module-config/module-config.effects';
import {
  selectModuleConfig,
  selectModuleConfigLoading,
} from '@shared/state/module-config/module-config.data.selectors';
import { PermissionNamespaceMap } from '@shared/state/permissions/permissions-namespace-map.enum';
import { selectClaims } from '@shared/state/claims/claims.data.selectors';
import { selectCurrentActiveAdvertiser } from '@shared/state/global/account/account.selectors';
import { selectAccounts } from '@shared/state/advertisers/accounts.data.selectors';
import {
  selectAccountIntegrations,
  selectGroupIntegrations,
} from '@shared/state/integrations/new/integrations.data.selectors';
import { IntegrationTypeAndVersion } from '@shared/state/integrations/integration-type-mapping.enum';
import { DashboardPage } from '@integrations/interfaces';

declare var jQuery: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  appServer = '';
  menuConfig: ModuleConfig[] = [];
  groupId = '';
  accountId = '';
  logo = environment.defaultBlackLogo;
  authenticated = false;
  nav = {
    adServe: {
      isVisible: false,
    },
    templates: {
      isVisible: false,
    },
    analytics: {
      isVisible: false,
    },
  };
  lastAdId = '';
  lastGroupId = '';
  @Input() reset = false;
  model: MenuItem[] = [];
  layoutMenuScroller = <HTMLDivElement>{};
  state = <AccountState>{};
  @ViewChild('layoutMenuScroller') layoutMenuScrollerViewChild = <ElementRef>{};
  isBrowser: boolean;
  url = '';
  claims: ResourceClaim[] = [];
  lastRedirectId = '';
  selectedAccountType: AccountType | undefined;
  subscription: Subscription[] = [];
  config$: Observable<ModuleConfig[] | undefined>;
  loading$: Observable<boolean>;
  accounts$: Observable<Account[] | undefined>;
  claims$: Observable<ResourceClaim[]>;
  selectedAccount$: Observable<Account>;
  integrations: IntegrationDefinition[] = [];
  selectedUserType = '';

  constructor(
    public app: AppMainComponent,
    private advertiserService: AdvertiserService,
    private claimsService: ClaimsService,
    private utilityService: CommonUtilityService,
    private router: Router,
    private accountFacade: AccountFacade,
    private accountEffects: AccountEffects,
    private moduleConfigEffects: ModuleConfigEffects,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private store: Store<AppState>,
    private platformLocation: PlatformLocation,
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthService
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.apiClient.errorHandler = this.errorHandler;
    this.authService.apiClient = this.apiClient;
    this.advertiserService.apiClient = this.apiClient;
    this.appServer = this.isBrowser
      ? (platformLocation as any).location.origin
      : '';

    this.config$ = this.store.select(selectModuleConfig);
    this.claims$ = this.store.select(selectClaims);
    this.accounts$ = this.store.select(selectAccounts);
    this.loading$ = this.store.select(selectAccountStateLoading);
    this.selectedAccount$ = this.store.select(selectCurrentActiveAdvertiser);
    this.subscription.push(
      this.config$.subscribe((data) => {
        if (data) {
          this.menuConfig = data;
          this.parseMenu();
          if (!data) {
            this.lastGroupId = '';
          }
        }
      })
    );
    this.subscription.push(
      this.accounts$.subscribe((accounts) => {
        if (accounts?.length) {
          this.accountFacade.setActiveAdvertiser(accounts[0]);
          this.getAdvertiserPermissions();
        }
      })
    );
    this.subscription.push(
      this.claims$.subscribe((claims) => {
        if (claims) {
          this.claims = claims;
          this.getMenuPermissions();
        }
      })
    );
    this.subscription.push(
      this.selectedAccount$.subscribe((selectedAccount) => {
        if (selectedAccount) {
          this.selectedAccountType = selectedAccount.Type;
        }
      })
    );
    this.subscription.push(
      combineLatest([
        this.store.select(selectAccountIntegrations),
        this.store.select(selectGroupIntegrations),
        this.accountFacade.account$,
      ]).subscribe(([accountIntegrations, groupIntegrations, accountState]) => {
        if (accountState && accountState.activeSelection) {
          if (
            accountState.activeSelection.Type === ActiveSelectionTypes.Account
          ) {
            if (accountIntegrations && accountIntegrations.length) {
              if (!isEqual(this.integrations, accountIntegrations)) {
                this.integrations = accountIntegrations;
                this.setAndUpdateDashboards();
              }
            }
          } else if (
            accountState.activeSelection.Type ===
            ActiveSelectionTypes.AccountGroup
          ) {
            if (groupIntegrations && groupIntegrations.length) {
              if (!isEqual(this.integrations, groupIntegrations)) {
                this.integrations = groupIntegrations;
                this.setAndUpdateDashboards();
              }
            }
          }
        }
      })
    );
    this.subscription.push(
      this.accountFacade.account$.subscribe((stateData) => {
        this.state = stateData;
        if (this.state && this.state.activeSelection) {
          this.getMenuPermissions();
        }
      })
    );
  }

  ngOnInit() {
    this.authenticated = this.authService.isAuthenticated();
    this.url = this.router.url;
    if (this.url === '/') {
      this.url = 'dashboard';
    }
    if (this.url[0] === '/') {
      this.url = this.url.slice(1);
    }
    if (this.url.includes('/')) {
      this.url = this.url.split('/')[0];
    }
  }

  ngAfterViewInit() {
    this.setupMenu();
  }

  getMenuPermissions() {
    if (this.claims && this.claims.length > 0) {
      this.fetchMenuConfigBasedOnClaims(this.claims);
    }
  }

  private getGroupPermissions() {
    if (this.lastGroupId !== this.state.activeAdvertiserGroup.GroupId) {
      this.lastGroupId = this.state.activeAdvertiserGroup.GroupId;
      this.lastAdId = '';
      this.getPermissions({
        advertiserId: '00000000000000000000000000000000',
        groupId: this.state.activeAdvertiserGroup.GroupId,
      });
    }
  }

  private fetchMenuConfigBasedOnClaims(response: ResourceClaim[]) {
    if (this.state?.activeSelection?.Type) {
      const adv = find(this.claimsService.roles, { Name: 'Account Admin' });
      const grp = find(this.claimsService.roles, { Name: 'Group Admin' });
      const grpRole = find(response, { Roles: grp?.Value });
      const advRole = find(response, { Roles: adv?.Value });
      if (grpRole && !advRole) {
        this.getAnyLevelConfig();
      }
      if (advRole && !grpRole) {
        this.getAdvertiserLevelConfig();
      }
      if (grpRole && advRole) {
        this.getAnyLevelConfig();
      }
    }
  }

  private getAnyLevelConfig() {
    if (
      this.state.activeSelection.Type === 'Advertiser Group' &&
      !this.utilityService.isEmptyObject(this.state.activeAdvertiserGroup)
    ) {
      this.getGroupPermissions();
    } else if (
      this.state.activeSelection.Type === 'Advertiser' &&
      !this.utilityService.isEmptyObject(this.state.activeAdvertiser)
    ) {
      this.getAdvertiserPermissions();
    }
  }

  private getAdvertiserLevelConfig() {
    if (
      this.state.activeSelection.Type === 'Advertiser Group' &&
      !this.utilityService.isEmptyObject(this.state.activeAdvertiserGroup)
    ) {
      this.accountEffects.getAccounts(this.state.activeAdvertiserGroup.GroupId);
    } else {
      this.getAdvertiserPermissions();
    }
  }

  private getGroupLevelConfig() {
    if (
      this.state.activeSelection.Type === 'Advertiser' &&
      !this.utilityService.isEmptyObject(this.state.activeAdvertiser)
    ) {
      this.accountFacade.setActiveGroup(this.state.advertiserGroups[0]);
      this.getGroupPermissions();
    } else {
      this.getGroupPermissions();
    }
  }

  private getAdvertiserPermissions() {
    if (this.lastAdId !== this.state.activeAdvertiser.AccountId) {
      this.lastAdId = this.state.activeAdvertiser.AccountId;
      this.lastGroupId = '';
      this.getPermissions({
        advertiserId: this.state.activeAdvertiser.AccountId,
        groupId: this.state.activeAdvertiser.AccountGroupId,
      });
    }
  }

  private getPermissions(payload: AdvertiserPayload) {
    this.moduleConfigEffects.getModuleConfig(payload);
  }

  parseMenu() {
    if (this.menuConfig) {
      this.model = [];
      this.setFitDashboardMenu();
      this.setLeaderboardMenu();
      this.setAndUpdateDashboards();
      this.setMediaDashboardMenu();
      this.setDefaultDashboardMenu();
      this.setTrafficMenu();
      this.setMarketingSpendMenu();
      this.setAttributionsMenu();
      this.setIntegrationsMenu();
      this.setQueryMenu();
      this.setLocationsMenu();
      this.setDataSourcesMenu();
    }
  }

  setFitDashboardMenu() {
    const fitPermission = find(this.menuConfig, {
      ModuleNamespace: PermissionNamespaceMap.FITHome,
    });
    if (fitPermission) {
      let menu: MenuItem = {
        id: 'fit-dashboard',
        label: 'FIT Home',
        svgIcon: 'fit-icon',
        links: ['fit'],
        command: () => {
          this.state.activeSelection.Type === 'Advertiser'
            ? this.setDestinationAndNavigate('fit')
            : this.setDestinationAndNavigate('fit/group');
        },
      };
      this.model.push(menu);
    }
  }

  setLeaderboardMenu() {
    const enginePermission = find(this.menuConfig, {
      ModuleNamespace: PermissionNamespaceMap.DashboardEngine,
    });
    if (enginePermission) {
      const menu: MenuItem = {
        id: 'leaderboards',
        label: 'Leaderboards',
        svgIcon: 'leaderboard-icon',
        links: ['leaderboards'],
        command: () => {
          this.state.activeSelection.Type === 'Advertiser'
            ? this.setDestinationAndNavigate('leaderboards')
            : this.setDestinationAndNavigate('leaderboards/group');
        },
      };
      this.model.push(menu);
    }
  }

  setAndUpdateDashboards() {
    const dashboardsPermission = find(this.menuConfig, {
      ModuleNamespace: PermissionNamespaceMap.DashboardEngine,
    });
    if (dashboardsPermission) {
      const menu: MenuItem = {
        id: 'dashboards',
        label: 'Dashboards',
        icon: 'dashboard',
        items: [],
      };
      menu.items = this.generateDashboardsSubItems();
      const dashboardsIndex = this.model.findIndex(
        (menuItem) => menuItem.id === 'dashboards'
      );
      if (dashboardsIndex > -1) {
        this.model.splice(dashboardsIndex, 1, menu);
      } else {
        this.model.push(menu);
      }
    }
  }

  generateDashboardsSubItems(): MenuItem[] {
    let menuItems: MenuItem[] = [];
    const dashboardsIntegration = this.integrations.find(
      (integration) =>
        integration.Type === IntegrationTypeAndVersion.DashboardEngineV2V202203
    );
    if (dashboardsIntegration) {
      menuItems = dashboardsIntegration.Pages?.map(
        (pageData: DashboardPage) => {
          return {
            id: `dashboards-page-${pageData.Title}`,
            label: pageData.Title,
            links: [`dashboards/${pageData.Id}`],
            command: () => {
              this.state.activeSelection.Type === ActiveSelectionTypes.Account
                ? this.setDestinationAndNavigate(`dashboards/${pageData.Id}`)
                : this.setDestinationAndNavigate(`dashboards/${pageData.Id}/group`);
            },
          };
        }
      );
    }
    return menuItems;
  }

  setMediaDashboardMenu() {
    const mediaPermission = this.menuConfig.find(
      (permission) =>
        permission.ModuleNamespace ===
          PermissionNamespaceMap.FranchiseeEmbeddedDashboard ||
        permission.ModuleNamespace ===
          PermissionNamespaceMap.FranchisorEmbeddedDashboard
    );
    if (mediaPermission) {
      const link =
        mediaPermission.ModuleNamespace ===
        PermissionNamespaceMap.FranchiseeEmbeddedDashboard
          ? 'media-dashboard-franchisee'
          : 'media-dashboard-franchisor';
      const menu: MenuItem = {
        id: 'media-dashboard',
        label: 'Media Dashboard',
        icon: 'equalizer',
        links: [link],
        command: () => {
          this.state.activeSelection.Type === 'Advertiser'
            ? this.setDestinationAndNavigate(link)
            : this.setDestinationAndNavigate(link + '/group');
        },
      };
      this.model.push(menu);
    }
  }

  setDefaultDashboardMenu() {
    const permissions = cloneDeep(this.menuConfig);
    const foundDashboardPermission = dashboardPermissionHierarchy.find(
      (dashboardPermission) => {
        return permissions.find(
          (permission) =>
            permission.ModuleNamespace === dashboardPermission.namespace
        );
      }
    );
    if (foundDashboardPermission === undefined) {
      let menu: MenuItem = {};
      menu = {
        id: 'dashboard-menu',
        label: 'Dashboard',
        icon: 'home',
        links: ['dashboard'],
        command: () => {
          this.state.activeSelection.Type === 'Advertiser'
            ? this.setDestinationAndNavigate('/dashboard')
            : this.setDestinationAndNavigate('/dashboard/group');
        },
      };
      this.model.push(menu);
    }
  }

  setTrafficMenu() {
    const traffic = find(this.menuConfig, {
      ModuleNamespace: 'com.transitiv.traffic',
    });
    let menu: MenuItem = {};
    if (traffic) {
      menu = {
        id: 'traffic-menu',
        label: 'Traffic',
        icon: 'compare_arrows',
        items: [],
      };
      const campaign = this.getCampaignMenu();
      const creative = this.getCreativeMenu();
      const tracking = this.getTrackingMenu();

      if (campaign && menu.items) {
        (menu['items'] as MenuItem[]).push(campaign);
      }
      if (creative && menu.items) {
        (menu['items'] as MenuItem[]).push(creative);
      }
      if (tracking && menu.items) {
        (menu['items'] as MenuItem[]).push(tracking);
      }
      this.model.push(menu);
    }
  }

  setPreviewMenu() {
    let menu: MenuItem = {};
    menu = {
      id: 'preview-menu',
      label: 'Preview',
      icon: 'assessment',
      links: ['adtag'],
      command: () => {
        this.setDestinationAndNavigate('adtag');
      },
    };
    this.model.push(menu);
  }

  setQueryMenu() {
    const permission = find(this.menuConfig, {
      ModuleNamespace: 'com.transitiv.querybuilder',
    });
    if (permission) {
      let menu: MenuItem = {};
      menu = {
        id: 'query-menu',
        label: 'Query Engine',
        icon: 'search',
        links: ['querybuilder'],
        command: () => {
          this.state.activeSelection.Type === 'Advertiser'
            ? this.setDestinationAndNavigate('querybuilder/list')
            : this.setDestinationAndNavigate('querybuilder/group');
        },
      };
      this.model.push(menu);
    }
  }

  setDataSourcesMenu() {
    const permission = find(this.menuConfig, {
      ModuleNamespace: 'com.transitiv.datasources',
    });
    if (permission) {
      const isGroupSelected =
        this.state?.activeSelection?.Type === 'Advertiser Group';
      if (permission && isGroupSelected) {
        let menu: MenuItem = {
          id: 'data-sources-menu',
          label: 'Data Sources',
          icon: 'search',
          links: ['data-sources'],
          command: () => {
            this.state.activeSelection.Type === 'Advertiser'
              ? this.setDestinationAndNavigate('data-sources')
              : this.setDestinationAndNavigate('data-sources/group');
          },
        };
        this.model.push(menu);
      }
    }
  }

  setAttributionsMenu() {
    let menu: MenuItem = {};
    const attribution = find(this.menuConfig, {
      ModuleNamespace: 'com.transitiv.attribution',
    });
    if (attribution) {
      menu = {
        id: 'attribution-menu',
        label: 'Attribution',
        icon: 'timeline',
        links: ['attribution'],
        command: () => {
          this.state.activeSelection.Type === 'Advertiser'
            ? this.setDestinationAndNavigate('attribution')
            : this.setDestinationAndNavigate('attribution/group');
        },
      };
      this.model.push(menu);
    }
  }

  setIntegrationsMenu() {
    let menu: MenuItem = {};
    const integrationConfig = find(this.menuConfig, {
      ModuleNamespace: 'com.transitiv.integrations',
    });
    if (integrationConfig) {
      menu = {
        id: 'integrations-menu',
        label: 'Integrations',
        icon: 'device_hub',
        links: ['integration'],
        command: () => {
          this.state.activeSelection.Type === 'Advertiser'
            ? this.setDestinationAndNavigate('integration')
            : this.setDestinationAndNavigate('integration/group');
        },
      };
      this.model.push(menu);
    }
  }

  getIntegrationsSetupMenu() {
    const menu = find(this.menuConfig, {
      ModuleNamespace: 'com.transitiv.integrations',
    });
    if (menu) {
      return {
        id: 'integrations-list-menu',
        label: 'List',
        icon: 'timeline',
        command: () => {
          this.setDestinationAndNavigate('integration/list');
        },
      };
    }
    return null;
  }

  getIntegrationsDisplayMenu() {
    const menu = find(this.menuConfig, {
      ModuleNamespace: 'com.transitiv.integrations',
    });
    if (menu) {
      return {
        id: 'integrations-display-menu',
        label: 'Display',
        icon: 'timeline',
        command: () => {
          this.setDestinationAndNavigate('integration');
        },
      };
    }
    return null;
  }

  setMarketingSpendMenu() {
    let menu: MenuItem = {};
    const item = find(this.menuConfig, {
      ModuleNamespace: 'com.transitiv.marketingspend',
    });
    if (item) {
      menu = {
        id: 'marketing-spend-menu',
        label: 'Marketing Spend',
        icon: 'attach_money',
        links: ['marketing-spend'],
        command: () => {
          this.setDestinationAndNavigate(
            'marketing-spend',
            'marketing-spend/group'
          );
        },
      };
      this.model.push(menu);
    }
  }

  setLocationsMenu() {
    let menu: MenuItem = {};
    const item = find(this.menuConfig, {
      ModuleNamespace: 'com.transitiv.locations',
    });
    const isGroupSelected =
      this.state?.activeSelection?.Type === 'Advertiser Group';
    if (item && isGroupSelected) {
      menu = {
        id: 'locations-menu',
        label: 'Locations',
        icon: 'place',
        links: ['locations'],
        command: () => {
          this.setDestinationAndNavigateForGroupOnly('locations', 'locations');
        },
      };
      this.model.push(menu);
    }
  }

  getCampaignMenu() {
    const menu = find(this.menuConfig, {
      ModuleNamespace: 'com.transitiv.traffic.campaign',
    });
    if (menu) {
      return {
        id: 'campaigns-menu',
        label: 'Campaigns',
        icon: 'speaker_notes',
        links: ['campaign'],
        command: () => {
          this.state.activeSelection.Type === 'Advertiser'
            ? this.setDestinationAndNavigate('campaign')
            : this.setDestinationAndNavigate('campaign/group');
        },
      };
    }
    return null;
  }

  getCreativeMenu() {
    const menu = find(this.menuConfig, {
      ModuleNamespace: 'com.transitiv.traffic.creative',
    });
    if (menu) {
      return {
        id: 'creatives-menu',
        label: 'Creatives',
        icon: 'filter_center_focus',
        links: ['creatives'],
        command: () => {
          this.state.activeSelection.Type === 'Advertiser'
            ? this.setDestinationAndNavigate('creatives')
            : this.setDestinationAndNavigate('creatives/group');
        },
      };
    }
    return null;
  }

  getTrackingMenu() {
    const menu = find(this.menuConfig, {
      ModuleNamespace: 'com.transitiv.traffic.conversion',
    });
    if (menu) {
      return {
        id: 'tracking-menu',
        label: 'Tracking',
        icon: 'label',
        links: ['conversion'],
        command: () => {
          this.state.activeSelection.Type === 'Advertiser'
            ? this.setDestinationAndNavigate('conversion')
            : this.setDestinationAndNavigate('conversion/group');
        },
      };
    }
    return null;
  }

  setDestinationAndNavigate(route: string, groupRoute?: string) {
    this.groupId = this.state.activeAdvertiserGroup.GroupId;
    this.accountId = this.state.activeAdvertiser.AccountId;
    if (this.groupId === undefined && this.accountId === undefined) {
      return;
    }

    if (this.accountId === undefined && !route.includes('group')) {
      route = groupRoute ? groupRoute : route + 'group';
      this.router.navigate(['/' + route, this.groupId]);
      return;
    }

    if (this.accountId !== undefined && !route.includes('group')) {
      this.router.navigate(['/' + route, this.accountId]);
      return;
    }

    if (this.groupId !== undefined) {
      route = groupRoute ? groupRoute : route;
      this.router.navigate(['/' + route, this.groupId]);
      return;
    }
  }

  setDestinationAndNavigateForGroupOnly(route: string, groupRoute?: string) {
    this.groupId = this.state.activeAdvertiserGroup.GroupId;
    this.accountId = this.state.activeAdvertiser.AccountId;
    if (this.groupId === undefined && this.accountId === undefined) {
      return;
    }

    route = groupRoute ? groupRoute : route;
    this.router.navigate(['/' + route, this.groupId]);
    return;
  }

  setupMenu() {
    if (
      this.authenticated &&
      this.layoutMenuScrollerViewChild &&
      this.layoutMenuScrollerViewChild.nativeElement
    ) {
      this.layoutMenuScroller = <HTMLDivElement>(
        this.layoutMenuScrollerViewChild.nativeElement
      );

      setTimeout(() => {
        jQuery(this.layoutMenuScroller).nanoScroller({ flash: true });
      }, 10);
    }
  }

  changeTheme(theme: string) {
    const themeLink: HTMLLinkElement = <HTMLLinkElement>(
      document.getElementById('theme-css')
    );
    themeLink.href = './assets/theme/theme-' + theme + '.css';
  }

  changeLayout(theme: string) {
    const layoutLink: HTMLLinkElement = <HTMLLinkElement>(
      document.getElementById('layout-css')
    );
    layoutLink.href = './assets/layout/css/layout-' + theme + '.css';
  }

  updateNanoScroll() {
    setTimeout(() => {
      jQuery(this.layoutMenuScroller).nanoScroller();
    }, 500);
  }

  ngOnDestroy() {
    this.subscription.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-submenu]',
  /* tslint:enable:component-selector */
  template: `
    <ng-template
      ngFor
      let-child
      let-i="index"
      [ngForOf]="root ? menuItem : menuItem.items"
    >
      <li
        [ngClass]="{
          'active-menuitem': isActive(i, child),
          disabled: loading$ | async,
          hasChild: child.items
        }"
        [class]="child.badgeStyleClass"
      >
        <a
          [href]="child.url || '#'"
          (click)="itemClick($event, child, i)"
          *ngIf="!child.routerLink"
          [attr.tabindex]="!visible ? '-1' : null"
          [attr.target]="child.target"
          (mouseenter)="hover = true"
          (mouseleave)="hover = false"
          class="ripplelink"
          [id]="child.id"
        >
          <i class="material-icons" *ngIf="child.icon">{{ child.icon }}</i>
          <mat-icon
            svgIcon="{{ child.svgIcon }}"
            *ngIf="child.svgIcon"
            aria-hidden="false"
            aria-label="{{ child.svgIcon }}"
          >
          </mat-icon>
          <span class="menuitem-text">{{ child.label }}</span>
          <i class="material-icons layout-submenu-toggler" *ngIf="child.items"
            >keyboard_arrow_down</i
          >
          <span class="menuitem-badge" *ngIf="child.badge">{{
            child.badge
          }}</span>
        </a>

        <a
          (click)="itemClick($event, child, i)"
          *ngIf="child.routerLink"
          [routerLink]="child.routerLink"
          routerLinkActive="active-menuitem-routerlink"
          [routerLinkActiveOptions]="{ exact: true }"
          [attr.tabindex]="!visible ? '-1' : null"
          [attr.target]="child.target"
          (mouseenter)="hover = true"
          (mouseleave)="hover = false"
          class="ripplelink"
          [id]="child.id"
        >
          <i class="material-icons" *ngIf="child.icon">{{ child.icon }}</i>
          <mat-icon
            svgIcon="{{ child.svgIcon }}"
            *ngIf="child.svgIcon"
            aria-hidden="false"
            aria-label="{{ child.svgIcon }}"
          >
          </mat-icon>
          <span class="menuitem-text">{{ child.label }}</span>
          <i class="material-icons layout-submenu-toggler" *ngIf="child.items"
            >>keyboard_arrow_down</i
          >
          <span class="menuitem-badge" *ngIf="child.badge">{{
            child.badge
          }}</span>
        </a>
        <ul
          app-submenu
          [item]="child"
          *ngIf="child.items"
          [visible]="isActive(i, child)"
          [reset]="reset"
          [@children]="
            isActive(i, child) && (app.sidebarActive || app.layoutStatic)
              ? 'visible'
              : 'hidden'
          "
        ></ul>
      </li>
    </ng-template>
  `,
  animations: [
    trigger('children', [
      state(
        'visible',
        style({
          height: '*',
        })
      ),
      state(
        'hidden',
        style({
          height: '0px',
        })
      ),
      transition(
        'visible => hidden',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
      transition(
        'hidden => visible',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
    ]),
  ],
  styleUrls: ['./sidebar.component.scss', './sidebar-menu-item.component.scss'],
})
export class AppSubMenuComponent implements OnInit {
  @Input() menuItem = <MenuItem>{};

  @Input() root = false;

  @Input() visible = false;

  _reset = false;

  activeIndex = 0;
  active: MenuItem[] = [];

  hover = false;
  url = '';
  currentItem: MenuItem | undefined;
  loading$: Observable<boolean>;

  constructor(
    public app: AppMainComponent,
    public router: Router,
    public location: Location,
    private store: Store<AppState>
  ) {
    this.loading$ = this.store.select(selectModuleConfigLoading);
  }

  ngOnInit() {
    this.extractLink(this.router.url);
    this.router.events
      .pipe(filter((e) => e instanceof RouterEvent))
      .subscribe((e) => {
        if (e instanceof ResolveEnd) {
          this.extractLink(e.url);
        }
      });
  }

  private extractLink(url: string) {
    this.url = url;
    if (this.url === '/') {
      this.url = 'dashboard';
    }
    if (this.url[0] === '/') {
      this.url = this.url.slice(1);
    }
    if (this.url.includes('dashboards')) {
      const dashboardsSplitUrl = this.url.split('/');
      this.url = [dashboardsSplitUrl[0], dashboardsSplitUrl[1]].join('/');
    } else if (this.url.includes('/')) {
      this.url = this.url.split('/')[0];
    }
    this.setActiveItem(this.menuItem);
  }

  itemClick(event: Event, item: MenuItem, index: number): boolean | void {
    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // activate current item and deactivate active sibling if any
    if (item.routerLink || item.items || item.command || item.url) {
      this.activeIndex = this.activeIndex === index ? -1 : index;
    }

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      if (this.app.isMobile()) {
        this.app.sidebarActive = false;
        this.app.mobileMenuActive = false;
      }
    }
    this.currentItem = item;
    this.setActiveItem(this.menuItem);
  }

  isActive(index: number, child: MenuItem): boolean {
    return !child.items
      ? child && this.active && this.active.includes(child)
      : this.currentItem && child && this.currentItem.id !== child.id
      ? this.activeIndex === index
      : (child && this.active && this.active.includes(child)) ||
        this.activeIndex === index;
  }

  isActive2(index: number): boolean {
    return this.activeIndex === index;
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;
  }

  @Input() get item(): MenuItem {
    return this.menuItem;
  }

  set item(val: MenuItem) {
    this.setActiveItem(val);
    this.menuItem = val;
  }

  private setActiveItem(val: MenuItem | MenuItem[]) {
    this.active = [];
    if (val && (val as MenuItem[]).length > 0) {
      (val as MenuItem[]).forEach((item) => {
        if (
          !item.items &&
          item.links &&
          (item.links.includes(this.url) ||
            item.links.includes(this.url.replace('group', '')))
        ) {
          this.active.push(item);
        } else if (item.items) {
          this.getActiveItemsFromSubmenu(item);
        }
      });
    } else if (
      val &&
      (val as MenuItem).items &&
      ((val as MenuItem).items as MenuItem[]).length > 0
    ) {
      this.getActiveItemsFromSubmenu(val as MenuItem);
    }
  }

  private getActiveItemsFromSubmenu(menuItem: MenuItem) {
    if (menuItem.items) {
      (menuItem.items as MenuItem[]).forEach((subItem) => {
        if (
          subItem.links &&
          this.url &&
          (subItem.links.includes(this.url) ||
            subItem.links.includes(this.url.replace('group', '')))
        ) {
          this.active.push(menuItem);
          this.active.push(subItem);
        }
      });
    }
  }
}
