import { Action, createReducer, on } from '@ngrx/store';
import { actionDataSourcesGetSchema, actionDataSourcesGetSchemaSuccess, actionDataSourcesError, 
  actionDataSourcesSetSelectedCols, actionDataSourcesSetJoins, actionDataSourcesSetMapping, 
  actionDataSourcesSetSettings, actionDataSourcesSave, actionDataSourcesSaveSuccess, actionDataSourcesGetAll, actionDataSourcesGetAllSuccess, actionDataSourcesSelect } from './data-sources.actions';
import { DataSourcesState } from './data-sources.model';

export const initialState: DataSourcesState = {
  accountId: '',
  loading: false
};

const reducer = createReducer(
  initialState,
  on(actionDataSourcesGetSchema, (state, { id, type }) => ({
    ...state,
    loading: true,
    status: type,
    schema: undefined,
    error: undefined,
    id
  })),
  on(actionDataSourcesGetSchemaSuccess, (state, { schema, type }) => ({
    ...state,
    loading: false,
    schema: {...schema},
    status: type,
    error: undefined
  })),
  on(actionDataSourcesGetAll, (state, { id, type }) => ({
    ...state,
    loading: true,
    status: type,
    dataSources: undefined,
    selectedDataSource: undefined,
    error: undefined,
  })),
  on(actionDataSourcesGetAllSuccess, (state, { dataSources, type }) => ({
    ...state,
    loading: false,
    dataSources: [...dataSources],
    selectedDataSource: undefined,
    status: type,
    error: undefined
  })),
  on(actionDataSourcesSelect, (state, { dataSource, type }) => ({
    ...state,
    selectedDataSource: {...dataSource},
    status: type,
  })),
  on(actionDataSourcesSave, (state, { id, type }) => ({
    ...state,
    loading: true,
    status: type,
    error: undefined,
  })),
  on(actionDataSourcesSaveSuccess, (state, { response, type }) => ({
    ...state,
    loading: false,
    status: type,
    error: undefined
  })),
  on(actionDataSourcesError, (state, { error, type }) => ({
    ...state,
    status: type,
    loading: false,
    error
  })),
  on(actionDataSourcesSetSelectedCols, (state, { selectedColumns, type }) => ({
    ...state,
    status: type,
    columnSelections: [...selectedColumns]
  })),
  on(actionDataSourcesSetJoins, (state, { joins, type }) => ({
    ...state,
    status: type,
    joins: [...joins]
  })),
  on(actionDataSourcesSetMapping, (state, { mappings, type }) => ({
    ...state,
    status: type,
    mappings: [...mappings]
  })),
  on(actionDataSourcesSetSettings, (state, { settings, type }) => ({
    ...state,
    status: type,
    settings: {...settings}
  })),
);

export function datasourcesReducer(
  state: DataSourcesState | undefined,
  action: Action
) {
  return reducer(state, action);
}
