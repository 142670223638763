import { EntitySelectorsFactory } from '@ngrx/data';
import { createSelector } from '@ngrx/store';
import { UserWhoIsInfo } from '../../interfaces/claim.interface';
import { UserInfo } from '../../interfaces/common.interface';
import { EntityMap } from '../entity/entity-metadata';

const selectors = new EntitySelectorsFactory().create<UserInfo>(EntityMap.User);
const whoisSelectors = new EntitySelectorsFactory().create<UserWhoIsInfo>(
  EntityMap.UserWhois
);

export const selectUser = createSelector(
  selectors.selectEntities,
  (user) => user
);

export const selectUserLoading = createSelector(
  selectors.selectLoading,
  (loading) => loading
);

export const selectUserWhois = createSelector(
  whoisSelectors.selectEntities,
  (user) => user
);

export const selectUserWhoisLoading = createSelector(
  whoisSelectors.selectLoading,
  (loading) => loading
);

export const selectUserWhoIsWithLoadingStatus = createSelector(
  whoisSelectors.selectLoaded,
  whoisSelectors.selectEntities,
  (loaded, userInfo) => {
    return { loaded, userInfo };
  }
);
