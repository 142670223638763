import {catchError, map, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import {AppState} from '../../state';
import { Pagination} from './creativedashboard.model';
import {CreativedashboardQuery} from './creativedashboard.reducer';
import * as CreativedashboardActions from './creativedashboard.actions';
import { CreativeService } from '@services/creative/creative.service';
import { AdvertiserService } from '@services/advertiser/advertiser.service';
import { NotificationsService } from '@services/notification/notification.service';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { IndirectCreativePayload } from '../../../interfaces';
type Action = CreativedashboardActions.All;

@Injectable()
export class CreativedashboardFacade {
  creativedashboard$ = this.store.select(CreativedashboardQuery.creativedashboard);
  payload: { id: string; cid: string; }|undefined;
  getCreatives$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CreativedashboardActions.GET_CREATIVES),
    map((action: CreativedashboardActions.GetCreatives) => action.payload),
    switchMap(payload => {
      return this.creativeService.getIndirectCreatives(payload).pipe(
      map((creatives) => {
       return new CreativedashboardActions.GetCreativesSuccess(creatives);
      }),
      catchError(err => {
        return of (new CreativedashboardActions.GetCreativesFail(err));
      } ), );
    }), ));

  getAdvertisers$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CreativedashboardActions.GET_ADVERTISERS),
    map((action: CreativedashboardActions.GetAdvertisers) => action.payload),
    switchMap(payload => {
      return this.advertiserService.getAdvertisersByGroup(payload).pipe(
      map((advertisers) => {
       return new CreativedashboardActions.GetAdvertisersSuccess(advertisers.data);
      }),
      catchError(err => {
        return of (new CreativedashboardActions.GetAdvertisersFail(err));
      } ), );
    }), ));

  getEvents$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CreativedashboardActions.GET_EVENTS),
    switchMap(() => {
      return this.creativeService.getJobStatus().pipe(
      map((events) => {
       return new CreativedashboardActions.GetEventsSuccess(events);
      }),
      catchError(err => {
        return of (new CreativedashboardActions.GetEventsFail(err));
      } ), );
    })));

  deleteCreative$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CreativedashboardActions.DELETE_CREATIVE),
    map((action: CreativedashboardActions.DeleteCreative) => {
      this.payload = {id: action.id, cid: action.cid};
      return {id: action.id, cid: action.cid};
    } ),
    switchMap(payload => {
      return this.creativeService.deleteIndirectCreative(payload.id, payload.cid).pipe(
      map(() => {
        this.notificationsService.notify({
          severity: 'success',
          detail: 'Creative was deleted successfuly.',
          summary: 'Creative deleted.',
          key: 'non-sticky'
        });
        return new CreativedashboardActions.DeleteCreativeSuccess();
      }),
      catchError(err => {
        return of (new CreativedashboardActions.DeleteCreativeFail(err));
      } ), );
    }), ));

  editCreative$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CreativedashboardActions.EDIT_CREATIVE),
    map((action: CreativedashboardActions.EditCreative) => {
      return {payload: action.payload, id: action.id, cid: action.cid};
    } ),
    switchMap(payload => {
      return this.creativeService.editIndirectCreative(payload.payload, payload.id, payload.cid).pipe(
      map(() => {
        return new CreativedashboardActions.EditCreativeSuccess();
      }),
      catchError(err => {
        return of (new CreativedashboardActions.EditCreativeFail(err));
      } ), );
    }), ));

  constructor(
    private creativeService: CreativeService,
    private advertiserService: AdvertiserService,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private notificationsService: NotificationsService,
    private actions$: Actions,
    private store: Store<AppState>
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.advertiserService.apiClient = this.apiClient;
    this.creativeService.apiClient = this.apiClient;
  }

  resetState(): void {
    this.store.dispatch(new CreativedashboardActions.ResetState());
  }

  paginate(paginator:  Pagination): void {
    this.store.dispatch(new CreativedashboardActions.PaginateCreatives(paginator));
  }

  getCreatives(payload: string): void {
    this.store.dispatch(new CreativedashboardActions.GetCreatives(payload));
  }

  getAdvertisers(payload: string): void {
    this.store.dispatch(new CreativedashboardActions.GetAdvertisers(payload));
  }

  getEvents(): void {
    this.store.dispatch(new CreativedashboardActions.GetEvents());
  }

  setTypeAsAdvertiser(id: string, gid: string): void {
    this.store.dispatch(new CreativedashboardActions.SetAsAdvertiser(id, gid));
  }

  deleteCreative(id: string, cid: string): void {
    this.store.dispatch(new CreativedashboardActions.DeleteCreative(id, cid));
  }

  deleteCreatives(): void {
    this.store.dispatch(new CreativedashboardActions.DeleteCreatives());
  }

  deleteCreativeSuccess(): void {
    this.store.dispatch(new CreativedashboardActions.DeleteCreativeSuccess());
  }

  deleteCreativesFail(): void {
    this.store.dispatch(new CreativedashboardActions.DeleteCreativesFail());
  }

  deleteCreativesSelected(status: boolean): void {
    this.store.dispatch(new CreativedashboardActions.DeleteCreativesSelected(status));
  }

  editCreative(payload: IndirectCreativePayload, id: string, cid: string): void {
    this.store.dispatch(new CreativedashboardActions.EditCreative(payload, id, cid));
  }

  setTypeAsGroup(id: string): void {
    this.store.dispatch(new CreativedashboardActions.SetAsGroup(id));
  }

  setVariationIsLoaded(payload: string, status: boolean): void {
    this.store.dispatch(new CreativedashboardActions.SetVariationLoaded(payload, status));
  }

  resetVariationIsLoaded(): void {
    this.store.dispatch(new CreativedashboardActions.ResetVariationLoaded());
  }
}
