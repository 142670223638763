import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@environments/environment';
import { HighLevelCRMService } from '@services/high-level-crm/high-level-crm.service';

@Component({
  templateUrl: './high-level-crm-redirect.component.html',
  styleUrls: ['./high-level-crm-redirect.component.scss'],
})
export class HighLevelCRMRedirectComponent implements OnInit {
  accountId = '';
  code = '';
  integrationId = '';
  logo = environment.defaultBlackLogo;
  isBrowser: boolean;
  constructor(
    private route: ActivatedRoute,
    private highLevelCRMService: HighLevelCRMService,

    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.code = params['code'];
      this.accountId = localStorage.getItem('accountId') || '';
      this.integrationId = localStorage.getItem('integrationId') || '';
      this.authorize();
    });
  }

  authorize() {
    if (this.isBrowser) {
      let originalOrigin = location.origin;
      if (
        !originalOrigin.includes('https') &&
        !originalOrigin.includes('local')
      ) {
        originalOrigin = originalOrigin.replace('http', 'https');
      }
      this.highLevelCRMService
        .authorize(this.accountId, this.code)
        .subscribe((data) => {
          localStorage.setItem(
            'EVENT.PUB',
            JSON.stringify({
              locationId: data.data.LocationId,
            })
          );
          window.close();
        });
    }
  }
}
