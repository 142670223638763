import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DefaultDataService,
  HttpUrlGenerator} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityMap } from '../entity/entity-metadata';
import { DashboardConfigDataSource } from '../../interfaces';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { RawSchema } from '../../interfaces/query.interface';
import { CommonUtilityService } from '@services/utility/common-utility.service';


@Injectable()
export class DashboardDataService extends DefaultDataService<RawSchema> {
  entityContext?: string
  entityId?: string
  dashboardService: DashboardService|undefined;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super(EntityMap.Dashboard, http, httpUrlGenerator);
  }

  getAll(): Observable<RawSchema[]> {
    let request: Observable<DashboardConfigDataSource>;
      if (this.entityContext === 'group') {
        request = this.dashboardService?.getAccountGroupDashboardConfigSeriesDataSource(
          this.entityId as string) as Observable<DashboardConfigDataSource>;
      } else {
        request = this.dashboardService?.getAccountDashboardConfigSeriesDataSource(
          this.entityId as string) as Observable<DashboardConfigDataSource>;
      }
    return request.pipe(
      map((dashboardConfig) => dashboardConfig.data.map(data => CommonUtilityService.timestampStateData(data))));
  }
}
