<div class="layout-breadcrumb" *ngIf="authenticated">
  <ul>
    <li><a (click)="goToDashboard()" id="home-breadcrumb-item"><i class="material-icons">home</i></a></li>
    <li>/</li>
    <ng-template ngFor let-item let-i="index" let-last="last" [ngForOf]="items">
        <li [id]="'breadcrumb-page-item-' + i">
            <a [routerLink]="item.routerLink" *ngIf="item.routerLink">{{item.label}}</a>
            <ng-container *ngIf="!item.routerLink">{{item.label}}</ng-container>
        </li>
        <li *ngIf="!last">/</li>
    </ng-template>
  </ul>
</div>