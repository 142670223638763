import { Component, OnInit } from '@angular/core';
import { sortBy } from 'lodash-es';
import { TitleService } from '@services/title/title.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { ErrorHandlerService } from '@services/helpers/error-handler.service';
import { UtilityService } from '@services/utility/utility.service';
import { Account } from '@shared/interfaces';
import { AdvertiserService } from '@services/advertiser/advertiser.service';
import { IntegrationsFacade } from '@shared/state/integrations';
import { RouteStateService } from '@services/route-state/route-state.service';

@Component({
  templateUrl: '../media-dashboard.component.html',
  styleUrls: ['../media-dashboard.component.scss'],
})
export class MediaDashboardFranchiseeComponent implements OnInit {
  accountId = '';
  groupId = '';
  isLoading = false;
  allAdvertisers: Account[] = [];
  error = '';
  constructor(
    private titleService: TitleService,
    private route: ActivatedRoute,
    private integrationsFacade: IntegrationsFacade,
    private utilityService: UtilityService,
    private advertiserService: AdvertiserService,
    private apiClient: ApiService,
    private errorHandler: ErrorHandlerService,
    private routeStateService: RouteStateService
  ) {
    this.apiClient.errorHandler = this.errorHandler;
    this.advertiserService.apiClient = this.apiClient;
    this.titleService.setTitle('Dashboard');
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.accountId = params['accountId'];
      this.groupId = params['groupId'];
      this.loadGroupData();
      this.resetGlobalState();
    });
    this.routeStateService.updatePathSnapshot(this.route.snapshot);
  }

  loadGroupData() {
    if (!this.groupId) {
      return;
    }
    this.isLoading = true;
    this.advertiserService.getAdvertisersByGroup(this.groupId).subscribe({
      next: (data) => {
        this.allAdvertisers = sortBy(data.data, ['Name']) || [];
        this.isLoading = false;
      },
      error: (data) => {
        this.error = this.utilityService.authErrorCallback(data);
        this.isLoading = false;
      },
    });
  }

  resetGlobalState() {
    this.integrationsFacade.clearIntegration();
  }
}
