import { EntitySelectorsFactory } from '@ngrx/data';
import { createSelector } from '@ngrx/store';
import { EntityMap } from '../entity/entity-metadata';
import { AppState } from '../state';
import { DataSourcesState } from './data-sources.model';
import { TransitivDataSource } from './models/TransitivDataSource';

export const selectedDataSourcesState = (state: AppState) => state.dataSources;
export const dataSourcesSelectors = new EntitySelectorsFactory().create<TransitivDataSource>(EntityMap.DataSource);


export const selectDataSourcesStateSchema = createSelector(
  dataSourcesSelectors.selectEntities,
  (schema) => schema
);

export const selectDataSourcesState = createSelector(
  selectedDataSourcesState,
  (state: DataSourcesState) => state
);

export const selectDataSourcesStateSelectedColumns = createSelector(
  selectedDataSourcesState,
  (state: DataSourcesState) => state?.columnSelections
);

export const selectDataSourcesStateDataSources = createSelector(
  selectedDataSourcesState,
  (state: DataSourcesState) => state?.dataSources
);

export const selectDataSourcesStateSelectedDataSource = createSelector(
  selectedDataSourcesState,
  (state: DataSourcesState) => state?.selectedDataSource
);

export const selectDataSourcesStateMappings = createSelector(
  selectedDataSourcesState,
  (state: DataSourcesState) => state?.mappings
);

export const selectDataSourcesStateSettings = createSelector(
  selectedDataSourcesState,
  (state: DataSourcesState) => state?.settings
);

export const selectDataSourcesStateJoins = createSelector(
  selectedDataSourcesState,
  (state: DataSourcesState) => state?.joins
);

export const selectDataSourcesStateLoading = createSelector(
  selectedDataSourcesState,
  (state: DataSourcesState) => state?.loading
);

export const selectDataSourcesStateStatus = createSelector(
  selectedDataSourcesState,
  (state: DataSourcesState) => state?.status
);
