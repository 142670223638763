import { createSelector } from '@ngrx/store';
import { differenceBy } from 'lodash-es';
import { TaskState } from '.';
import { AppState } from '../state';

export const selectNotificationState = (state: AppState) => state.tasks;

export const selectTaskCustomerGraphPersonaQueryComplete = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.CustomerGraphPersonaQueryComplete
);

export const selectTaskAttributionComplete = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.attributionComplete
);

export const selectCustomerQueryComplete = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.CustomerQueryComplete
);

export const selectCustomerQueryFailed = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.CustomerQueryFailed
);

export const selectCustomerCountQueryComplete = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.CustomerCountQueryComplete
);

export const selectCustomerCountQueryFailed = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.CustomerCountQueryFailed
);

export const selectTaskAttributionFailed = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.attributionFailed
);

export const selectTaskScvComplete = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.scvComplete
);

export const selectTaskScvFailed = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.scvFailed
);

export const selectTaskRfmComplete = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.rfmComplete
);

export const selectTaskRfmFailed = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.rfmFailed
);

export const selectTaskLtvComplete = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.ltvComplete
);

export const selectTaskLtvFailed = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.ltvFailed
);

export const selectTaskUnnotifiedItems = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.unnotified
);

export const selectFailedTasks = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.failedTasks
);

export const selectTaskNotifications = createSelector(
  selectNotificationState,
  (state: TaskState) => state?.notifications
);

export const selectNewTaskNotificationsCount = createSelector(
  selectNotificationState,
  (state: TaskState) => {
    const diff = differenceBy(
      state?.notifications || [],
      state?.clearedNotification || [],
      'AcknowledgedId'
    );
    return diff.length;
  }
);
