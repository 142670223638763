import { AppState } from '../../state';
import * as AccountActions from './account.actions';
import { AccountState } from './account.model';

export type Action = AccountActions.All;

export namespace AccountQuery {
  export const account = (state: AppState) => state.account;
}

export function accountReducer(state: AccountState, action: Action) {

  switch (action.type) {
  case AccountActions.LOAD_MANAGEMENT_STATE:
    return { ...action.payload, status: action.type};
  case AccountActions.SET_MANAGEMENT_SELECTED_GROUP:
    return { ...state, activeAdvertiserGroup: action.payload, activeSelection: {Name: action.payload.Name, Type: 'Advertiser Group'},
      groupId: action.payload.GroupId, status: action.type};
  case AccountActions.SET_MANAGEMENT_SELECTED_ADVERTISER:
    return { ...state, activeAdvertiser: action.payload, activeSelection: action.payload ?
      {Name: action.payload.Name, Type: 'Advertiser'} : null,
      advertiserId: action.payload.AccountId, status: action.type};
  case AccountActions.GET_MANAGEMENT_GROUPS:
    return { ...state, groupIsLoading: true, status: action.type};
  case AccountActions.SET_MANAGEMENT_DELETED_ADVERTISER:
    return { ...state, deletedAdvertiser: action.payload, status: action.type};
  case AccountActions.GET_MANAGEMENT_GROUPS_SUCCESS:
    return { ...state, groupIsLoading: false, status: action.type };
  case AccountActions.GET_MANAGEMENT_GROUPS_FAIL:
    return { ...state, groupError: action.payload, groupIsLoading: false, status: action.type };
  case AccountActions.SEARCH_MANAGEMENT_DATA:
    return { ...state, searchLoading: true, status: action.type};
  case AccountActions.SEARCH_MANAGEMENT_DATA_SUCCESS:
    return { ...state, searchLoading: false, searchResult: action.payload, status: action.type };
  case AccountActions.SEARCH_MANAGEMENT_DATA_FAIL:
    return { ...state, searchError: action.payload, searchLoading: false, status: action.type };
  case AccountActions.GET_MANAGEMENT_GROUP_ADVERTISERS:
    return { ...state, advertiserLoading: true, status: action.type};
  case AccountActions.GET_MANAGEMENT_GROUP_ADVERTISERS_SUCCESS:
    return { ...state, advertisers: action.payload, advertiserLoading: false, status: action.type };
  case AccountActions.SET_MANAGEMENT_GROUP_ADVERTISERS:
    return { ...state, groupAdvertisers: action.payload, status: action.type };
  case AccountActions.GET_MANAGEMENT_GROUP_ADVERTISERS_FAIL:
    return { ...state, advertiserError: action.payload, advertiserLoading: false, status: action.type };
  case AccountActions.GET_MANAGEMENT_ALLOWED_ADVERTISER:
    return { ...state, advertiserLoading: true, status: action.type};
  case AccountActions.GET_MANAGEMENT_ALLOWED_ADVERTISER_SUCCESS:
    return { ...state, allowedAdvertisers: [...state.allowedAdvertisers || [], action.payload],
      advertiserLoading: false, status: action.type };
  case AccountActions.GET_MANAGEMENT_ALLOWED_ADVERTISER_FAIL:
    return { ...state, advertiserError: action.payload, advertiserLoading: false, status: action.type };
  case AccountActions.GET_MANAGEMENT_USER:
    return { ...state, userLoading: true, status: action.type};
  case AccountActions.GET_MANAGEMENT_USER_SUCCESS:
    return { ...state, user: action.payload, userLoading: false, status: action.type };
  case AccountActions.GET_MANAGEMENT_USER_FAIL:
    return { ...state, userError: action.payload, userLoading: false, status: action.type };
  case AccountActions.GET_MANAGEMENT_USER_CLAIMS:
    return { ...state, loading: true, status: action.type};
  case AccountActions.GET_MANAGEMENT_USER_CLAIMS_SUCCESS:
    return { ...state, claims: action.payload, loading: false, status: action.type };
  case AccountActions.GET_MANAGEMENT_USER_CLAIMS_FAIL:
    return { ...state, error: action.payload, loading: false, status: action.type };
  case AccountActions.SET_MANAGEMENT_SHOW_INACTIVE:
    return { ...state, showInactive: action.payload, status: action.type };
  case AccountActions.RESET_MANAGEMENT_STATE:
    return {status: action.type };
  default:
    return state;
  }
}
