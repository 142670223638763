import { EntitySelectorsFactory } from "@ngrx/data";
import { createSelector } from "@ngrx/store";
import { reject } from 'lodash-es';
import { IntegrationDefinition, IntegrationType } from '../../../interfaces';
import { EntityMap } from '../../entity/entity-metadata';
import { selectUser } from "../../user/user.selectors";
import { IntegrationTypeAndVersion } from '../integration-type-mapping.enum';

export const accountIntegrationSelectors = new EntitySelectorsFactory().create<IntegrationDefinition>(EntityMap.AccountIntegration);
export const accountIntegrationTypeSelectors = new EntitySelectorsFactory().create<IntegrationType>(EntityMap.AccountIntegrationType);

export const groupIntegrationSelectors = new EntitySelectorsFactory().create<IntegrationDefinition>(EntityMap.GroupIntegration);
export const groupIntegrationTypeSelectors = new EntitySelectorsFactory().create<IntegrationType>(EntityMap.GroupIntegrationType);

export const selectAccountIntegrations = createSelector(
  accountIntegrationSelectors.selectEntities,
  (data) => data
);

export const selectAccountIntegrationsAndLoadingStatus = createSelector(
  accountIntegrationSelectors.selectEntities,
  accountIntegrationSelectors.selectLoaded,
  (data, hasLoaded) => {
    return {
      integrations: data,
      hasLoaded
    }
  }
);

export const selectAccountIntegrationTypes = createSelector(
  accountIntegrationTypeSelectors.selectEntities,
  (data) => data
);

export const selectGroupIntegrations = createSelector(
  groupIntegrationSelectors.selectEntities,
  (data) => data
);

export const selectGroupIntegrationMap = createSelector(
  groupIntegrationSelectors.selectEntityMap,
  (data) => data
);

export const selectGroupIntegrationTypes = createSelector(
  groupIntegrationTypeSelectors.selectEntities,
  (data) => data
);

export const selectAccountIntegrationMap = createSelector(
  accountIntegrationSelectors.selectEntityMap,
  (data) => data
);

export const selectAccountIntegrationLoading = createSelector(
  accountIntegrationSelectors.selectLoading,
  (data) => data
);

export const selectAccountIntegrationStatusChange = createSelector(
  accountIntegrationSelectors.selectChangeState,
  (data) => data
);

export const selectAccountIntegrationTypeStatusChange = createSelector(
  accountIntegrationTypeSelectors.selectChangeState,
  (data) => data
);

export const selectAccountIntegrationAndTypeStatusChange = createSelector(
  selectAccountIntegrationStatusChange,
  selectAccountIntegrationTypeStatusChange,
  (integration, integrationType) => {
    return {integration, integrationType}
  }
);

export const selectIntegrationsByTypes = (integrationTypeIds: number[]) => createSelector(
  selectAccountIntegrations,
  (data) => filterIntegrationDataByType(data, integrationTypeIds)
);

export const selectGroupIntegrationsByTypes = (integrationTypeIds: number[]) => createSelector(
  selectGroupIntegrations,
  (data) => filterIntegrationDataByType(data, integrationTypeIds)
);

export const privateTransitivIntegrationTypes = [
  IntegrationTypeAndVersion.FitCoordinatorConfigurationV202110,
  IntegrationTypeAndVersion.FitScoreConfigurationV202110,
  IntegrationTypeAndVersion.FitFranchiseeHomeViewIntegrationV202110,
  IntegrationTypeAndVersion.FitEmailAlertIntegrationV202110,
  IntegrationTypeAndVersion.FiveTranV202202,
  IntegrationTypeAndVersion.TransitivETLV202208,
];

export const selectDasboardIntegrationsByTypes = () => createSelector(
  selectIntegrationsByTypes([
    IntegrationTypeAndVersion.AttributionProductCatalogIntegrationV201811,
    IntegrationTypeAndVersion.SegmentationRFMV201812,
    IntegrationTypeAndVersion.SegmengationLTVV201812,
    IntegrationTypeAndVersion.EmbeddedDashboardV202004,
    IntegrationTypeAndVersion.SegmentationLTVV201909,
    IntegrationTypeAndVersion.CustomerFilterListIntegrationV201911,
    IntegrationTypeAndVersion.DashboardConfigurationV202104,
    IntegrationTypeAndVersion.LeaderboardConfigurationV202104,
    IntegrationTypeAndVersion.DashboardEngineV2V202203,
    IntegrationTypeAndVersion.LeaderboardV2Integration,
  ]),
  (data) => data
);

export const selectMarkovIntegrationsByType = () => createSelector(
  selectIntegrationsByTypes([IntegrationTypeAndVersion.AttributionProductCatalogIntegrationV201811]),
  (data) => data
);

function filterIntegrationDataByType(data: IntegrationDefinition[], integrationTypeIds: number[]) {
  return data.filter(item => integrationTypeIds.includes(item.Type as number))
}

export const selectAccountIntegrationsAndTypes = createSelector(
  selectAccountIntegrations,
  selectAccountIntegrationTypes,
  (integrations, integrationTypes) => {
    return {
    integrations,
    integrationTypes
  }},
);

export const selectGroupIntegrationsAndTypes = createSelector(
  selectGroupIntegrations,
  selectGroupIntegrationTypes,
  (integrations, integrationTypes) => {
    return {
    integrations,
    integrationTypes
  }},
);

export const selectFilteredAccountIntegrations = createSelector(
  selectAccountIntegrations,
  selectUser,
  (integrations, user) => {
    let isTransitivUser = false;
    if (user && user[0]) {
      isTransitivUser = user[0].Email.includes('@transitiv.io');
    }
    return filterTransitivPrivateIntegrations(integrations, isTransitivUser)
  },
);

export const selectFilteredAccountIntegrationsTypes = createSelector(
  selectAccountIntegrationTypes,
  selectUser,
  (integrationTypes, user) => {
    let isTransitivUser = false;
    if (user && user[0]) {
      isTransitivUser = user[0].Email.includes('@transitiv.io');
    }
    return filterTransitivPrivateIntegrationTypes(integrationTypes, isTransitivUser)
  },
);

export const selectFilteredAccountIntegrationsAndTypes = createSelector(
  selectFilteredAccountIntegrations,
  selectFilteredAccountIntegrationsTypes,
  (integrations, integrationTypes) => {
    return {
    integrations,
    integrationTypes
  }},
);

export const selectFilteredGroupIntegrations = createSelector(
  selectGroupIntegrations,
  selectUser,
  (integrations, user) => {
    let isTransitivUser = false;
    if (user && user[0]) {
      isTransitivUser = user[0].Email.includes('@transitiv.io');
    }
    return filterTransitivPrivateIntegrations(integrations, isTransitivUser)
  },
);

export const selectFilteredGroupIntegrationTypes = createSelector(
  selectGroupIntegrationTypes,
  selectUser,
  (integrationTypes, user) => {
    let isTransitivUser = false;
    if (user && user[0]) {
      isTransitivUser = user[0].Email.includes('@transitiv.io');
    }
    return filterTransitivPrivateIntegrationTypes(integrationTypes, isTransitivUser)},
);

export const selectFilteredGroupIntegrationsAndTypes = createSelector(
  selectFilteredGroupIntegrations,
  selectFilteredGroupIntegrationTypes,
  (integrations, integrationTypes) => {
    return {
    integrations,
    integrationTypes
  }},
);

function filterTransitivPrivateIntegrations(integrations: IntegrationDefinition[], isTransitivUser: boolean): IntegrationDefinition[] {
  if (!isTransitivUser) {
    return removePrivateItems(integrations) as IntegrationDefinition[];
  } else {
    return integrations
  }
}

function filterTransitivPrivateIntegrationTypes(integrationTypes: IntegrationType[], isTransitivUser: boolean): IntegrationType[] {
  if (!isTransitivUser) {
    return removePrivateItems(integrationTypes);
  } else {
    return integrationTypes
  }
}
function removePrivateItems(items: IntegrationType[]|IntegrationDefinition[]): IntegrationType[]|IntegrationDefinition[] {
  return reject(items, (item) => {
    return privateTransitivIntegrationTypes.includes(item.Type as IntegrationTypeAndVersion)}
  );
}
