import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class StateService {
  baseUrl = environment.apiBase;
  cache: any = {};

  constructor() {
   }

  list() {
    return this.cache;
  }

  put(key: string, value: any) {
    this.cache[key] = value;
    // console.log('StateService put', key, value);

    // $rootScope.$broadcast("cache.item.updated", { key: key, newValue: value, oldValue: oldValue });
  }

  remove(key: string) {
    delete this.cache[key];
    // $rootScope.$broadcast("cache.item.removed", { key: key, value: value });
  }

  get(key: string) {
    return this.cache[key] || null;
  }
}
