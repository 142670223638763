import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import SentryFullStory from '@sentry/fullstory';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('Mjk1NzM3NEAzMjMwMmUzMjJlMzBvUiswaUhzeTlaOG8yVmZiOWxTV2xvNVUxc0ZBendnOVZCSnh0bDc4MlZnPQ==;Mjk1NzM3NUAzMjMwMmUzMjJlMzBUTG4vdVdOQVRVODUwaVJiaXNLLzBGVzFiYW9IeUdGaVNHTHlvYWxEWGpvPQ==;Mgo+DSMBaFt/QHJqVVhkWlpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5iSH1Td0dhWHpWcXFXQQ==;Mgo+DSMBPh8sVXJ0S0R+XE9AcFRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Sd0ZjWH1ccXFdR2ZUUg==;ORg4AjUWIQA/Gnt2VVhiQlFacllJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQd0RgWX5ccnVQTmFaWUc=;NRAiBiAaIQQuGjN/V0Z+Xk9EaFtEVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdURiWn9fcnZUQ2hdWUF2;Mjk1NzM4MEAzMjMwMmUzMjJlMzBtTE02RU5IMDVaZUwybGJXNDN4Szk5NzFkTUFKS0FlY2I0dEtDdWQ3eHZJPQ==;Mjk1NzM4MUAzMjMwMmUzMjJlMzBQSUJpWDJobnRueCtTZ1RTbm5IY09PMzBtbXlySzVLRzZQNWdRTzBBVW0wPQ==;Mgo+DSMBMAY9C3t2VVhiQlFacllJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQd0RgWX5ccnVQTmJfUE0=;Mjk1NzM4M0AzMjMwMmUzMjJlMzBmbDg5NW1EL0lTZDNBMEJoaUd4UUlGUDA0cnA1TjJrYXpxQ29aMlZLZUZZPQ==;Mjk1NzM4NEAzMjMwMmUzMjJlMzBiaW5hVkgrZEpJMXR2bDZWRkNqV3QvQndKbVBpWUhNbGJqVG52K1YyZkhRPQ==;Mjk1NzM4NUAzMjMwMmUzMjJlMzBtTE02RU5IMDVaZUwybGJXNDN4Szk5NzFkTUFKS0FlY2I0dEtDdWQ3eHZJPQ==');

if (environment.production) {
  enableProdMode();
}


if (environment.environment !== 'dev') {
  Sentry.init({
    dsn: "https://0f90eace4cbb48e4babb94a853710efe@o578467.ingest.sentry.io/5784445" ,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [environment.apiBase, environment.authBase],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new SentryFullStory('transitiv')
    ],
    tracesSampleRate: 1.0,
    release: 'transitiv@'+ environment.azureBuildHash,
    environment: environment.environment
  });
}

const loadingElement = document.querySelector('.app-loading');
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => loadingElement?.classList.add('fade-out'))
  .then(() => setTimeout(() => loadingElement?.remove(), 900));
