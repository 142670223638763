import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FitDrillDownPayload, FitDrillDownResponse } from '../../shared/interfaces/metric-drill-down.interface';
import { DailyFitApiResponse } from '../../shared/state/fit/models/daily-fit-api-response.interface';
import { DailyFit } from '../../shared/state/fit/models/daily-fit.interface';
import { FitPayload } from '../../shared/state/fit/models/fit-payload.interface';
import { ApiDataService } from '../api/abstract/api.service';

@Injectable()
export class FitService {
  constructor(private apiClient: ApiDataService) {}

  getFitData(
    accountId: string,
    payload: FitPayload
  ): Observable<DailyFitApiResponse> {
    return this.apiClient.callHttpPost({
      path: `/accounts/${accountId}/fit`,
      param: payload,
      type: '',
      version: '/v202107',
    });
  }

  getFitDrillDownEndpoint(
    accountId: string,
    integrationId: string,
    metricId: string,
    payload: FitDrillDownPayload
  ): Observable<FitDrillDownResponse> {
    return this.apiClient.callHttpPost({
      path: `/accounts/${accountId}/fit/drilldown/${integrationId}/page/${metricId}`,
      param: payload,
      type: '',
      version: '/v202212',
    });
  }

  getDailyFit(id: string) {
    const fitData: DailyFit[] = require(`./demo-data/${id}/daily-fit.json`);
    return of({ data: fitData, status_code: 0, metadata: {} });
  }

  getAttritionRate(id: string) {
    const fitData: DailyFit[] = require(`./demo-data/${id}/attrition-rate.json`);
    return of({ data: fitData, status_code: 0, metadata: {} });
  }

  getNewCustomers(id: string) {
    const fitData: DailyFit[] = require(`./demo-data/${id}/new-customers.json`);
    return of({ data: fitData, status_code: 0, metadata: {} });
  }

  getCostPerLead(id: string) {
    const fitData: DailyFit[] = require(`./demo-data/${id}/cost-per-lead.json`);
    return of({ data: fitData, status_code: 0, metadata: {} });
  }

  getDailyRevenue(id: string) {
    const fitData: DailyFit[] = require(`./demo-data/${id}/daily-revenue.json`);
    return of({ data: fitData, status_code: 0, metadata: {} });
  }

  getNetPromoterScore(id: string) {
    const fitData: DailyFit[] = require(`./demo-data/${id}/net-promoter-score.json`);
    return of({ data: fitData, status_code: 0, metadata: {} });
  }

  getNewLeads(id: string) {
    const fitData: DailyFit[] = require(`./demo-data/${id}/new-leads.json`);
    return of({ data: fitData, status_code: 0, metadata: {} });
  }

  getRevenuePerTrainer(id: string) {
    const fitData: DailyFit[] = require(`./demo-data/${id}/revenue-per-trainer.json`);
    return of({ data: fitData, status_code: 0, metadata: {} });
  }

  getTrainerHour(id: string) {
    const fitData: DailyFit[] = require(`./demo-data/${id}/trainer-hour.json`);
    return of({ data: fitData, status_code: 0, metadata: {} });
  }
}
