import { isPlatformBrowser } from '@angular/common';
import {
  APP_ID,
  Inject,
  NgModule,
  PLATFORM_ID,
  ErrorHandler,
  CUSTOM_ELEMENTS_SCHEMA,
  APP_INITIALIZER,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';

import { AppAuthComponent } from './app-auth.component';
import { AppMainComponent } from './app-main.component';
import { AppRoutingModule } from './app-routing.module';
import { AppTagComponent } from './app-tag.component';
// Components-----------------------------------------------
import { AppComponent } from './app.component';
import { AuthModule } from './components/auth/auth.module';

import { NotificationsComponent } from './components/directives/notification/notification.component';
import { AuthGuard } from './components/shared/guard/auth.guard';
import { AccountService } from './services/account/account.service';
import { AdGroupService } from './services/ad-group/ad-group.service';
import { AdService } from './services/ad/ad.service';
import { AdvertiserService } from './services/advertiser/advertiser.service';
import { AnalyticsService } from './services/analytics/analytics.service';
import { ApiService } from './services/api/api.service';
import { AuthService } from './services/auth/auth.service';
import { BreadcrumbService } from './services/breadcrumb/breadcrumb.service';
import { CampaignService } from './services/campaign/campaign.service';
import { ClaimsService } from './services/claims/claims.service';
import { ConfigService } from './services/config/config.service';
import { ConversionService } from './services/conversion/conversion.service';
import { CreativeService } from './services/creative/creative.service';
import { EventsService } from './services/event/events.service';
// Services--------------------------------------------------
import { FeedService } from './services/feed/feed.service';
import { FormValidatorService } from './services/form-validator/form-validator.service';
import { ErrorHandlerService } from './services/helpers/error-handler.service';
import { AppHttpHeaders } from './services/helpers/http-headers.service';
import { IntegrationsService } from './services/integrations/integrations.service';
import { LoaderService } from './services/loader/loader.service';
import { LoginService } from './services/login/login.service';
import { NotificationsService } from './services/notification/notification.service';
import { PagerService } from './services/pager/pager.service';
import { QueryService } from './services/query/query.service';
import { StateService } from './services/state/state.service';
import { TabService } from './services/tab/tab.service';
import { TargetingService } from './services/targeting/targeting.service';
import { TitleService } from './services/title/title.service';
import { UtilityService } from './services/utility/utility.service';
import { VariationService } from './services/variation/variation.service';
import { SignarlRService } from './services/signal-r/signarl-r.service';
// MODULES----------------------------------------------
import { CanDeactivateGuard } from './components/shared/can-deactivate/can-deactivate.guard';
import { CustomerService } from './services/customer/customer.service';
import { SegmentationService } from './services/segmentation/segmentation.service';
import { FaviconService } from './services/favicon/favicon.service';
import { MaterialModule } from './material.module';
import {
  DateUtilityService,
  IntegrationUtilityService,
  ChartUtilityService,
  DummyDataService,
  LeaderboardUtilityService,
} from './services/utility';
import { ChartConfigService } from './services/chart-config/chart-config.service';
import { AppDashboardComponent } from './app-dashboard.component';
import {
  VersionCheckService,
  VersionDialogComponent,
} from './services/version-check/version-check.service';
import { ConfirmationDialogContentComponent } from './components/directives/confirmation-dialog/confirmation-dialog.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { TaskUtilityService } from './services/utility/tasks-utility.service';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NavPanelComponent } from './components/shared/nav-tree/directives/nav-panel.component';
import { CampaignSummaryMachine } from './components/campaign/campaign-summary/xstate/campaign-summary-machine.service';

import { MarketingSpendService } from './services/marketSpend/marketspend.service';
import { CreativeSettingsDialogContentComponent } from './components/creatives/details/settings-dialog/settings-dialog.directive';
import { CreativeFilterDialogContentComponent } from './components/creatives/details/filter-dialog/filter-dialog.directive';

import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { SliderModule } from '@syncfusion/ej2-angular-inputs';

import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { AppStateModule } from './shared/state/state.module';
import {
  SidebarComponent,
  AppSubMenuComponent,
} from './components/shared/sidebar/sidebar.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { NavTreeComponent } from './components/shared/nav-tree/nav-tree.component';
import { AppBreadcrumbComponent } from './components/shared/breadcrumb/app.breadcrumb.component';
import { StringUtilityService } from './services/utility/string-utility.service';
import { NumberUtilityService } from './services/utility/number-utility.service';
import { CommonUtilityService } from './services/utility/common-utility.service';
import { AnalyticsUtilityService } from './services/utility/analytics-utility.service';
import { LoadingBarComponent } from './components/directives/loading-bar/loading-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { CustomFormModule } from './shared/form.module';
import { NotificationPanelComponent } from './components/directives/notification-panel/notification-panel.component';
import { TreeDisplayContentComponent } from './components/directives/tree-display/tree-display.directive';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { SnackBarService } from './services/snackbar/snackbar.service';

import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { IndeedService } from './services/indeed/indeed.service';
import { IndeedComponent } from './components/integrations/indeed/indeed.component';
import { LocationService } from './services/location/location.service';
import { LinkedInService } from './services/linked-in/linked-in.service';
import { interceptorProviders } from './services/interceptors/interceptors';
import { DashboardService } from './services/dashboard/dashboard.service';
import { DashboardEngineChartUtilityService } from './services/utility/dashboard-engine-chart-utility.service';
import { LaunchDarklyService } from './services/launch-darkly/launch-darkly.service';
import { DataSourcesService } from './services/data-sources/data-sources.service';
import { FitService } from './services/fit/fit.service';
import { ApiDataService } from './services/api/abstract/api.service';
import { ApiHttpClientService } from './services/api/api.data.service';
import { AccountIntegrationsService } from './services/integrations/account/account-integrations.service';
import { GroupIntegrationsService } from './services/integrations/group/group-integrations.service';
import { HighLevelCRMService } from './services/high-level-crm/high-level-crm.service';
import { UnitFormatterService } from './services/utility/unit-formatter.service';
import { RouteStateService } from './services/route-state/route-state.service';
import { IconRegistryService } from './services/icon-registry/icon-registry.service';
import { ChartsService } from './services/charts/charts.service';
import { GlobalFilterService } from './services/global-filter/global-filter.service';
import { GridsterModule } from 'angular-gridster2';
import { LiveChatService } from './services/live-chat/live-chat.service';

@NgModule({
    declarations: [
        AppComponent,
        AppAuthComponent,
        AppMainComponent,
        AppDashboardComponent,
        AppTagComponent,
        NotificationsComponent,
        ConfirmationDialogContentComponent,
        CreativeSettingsDialogContentComponent,
        CreativeFilterDialogContentComponent,
        NavPanelComponent,
        SidebarComponent,
        AppSubMenuComponent,
        HeaderComponent,
        NavTreeComponent,
        AppBreadcrumbComponent,
        LoadingBarComponent,
        NotificationPanelComponent,
        TreeDisplayContentComponent,
        VersionDialogComponent,
        IndeedComponent,
    ],
    imports: [
        // SHARED MODULES
        BrowserModule.withServerTransition({ appId: 'transitiv' }),
        HttpClientModule,
        AppRoutingModule,
        MaterialModule,
        OverlayModule,
        // CUSTOM MODULES
        AuthModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        CheckBoxModule,
        ListViewModule,
        SliderModule,
        AppStateModule,
        RouterModule,
        CustomFormModule,
        TreeViewModule,
        FormsModule,
        ReactiveFormsModule,
        GridsterModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        LoginService,
        FeedService,
        CreativeService,
        CampaignService,
        DataSourcesService,
        FitService,
        AdService,
        AdGroupService,
        TargetingService,
        VariationService,
        AdvertiserService,
        TitleService,
        TabService,
        AnalyticsService,
        UtilityService,
        StringUtilityService,
        NumberUtilityService,
        UnitFormatterService,
        DummyDataService,
        CommonUtilityService,
        AnalyticsUtilityService,
        DateUtilityService,
        ChartUtilityService,
        TaskUtilityService,
        DashboardEngineChartUtilityService,
        StateService,
        ConversionService,
        AuthService,
        AppHttpHeaders,
        AccountService,
        ClaimsService,
        EventsService,
        ConfigService,
        FormValidatorService,
        BreadcrumbService,
        AuthGuard,
        PagerService,
        LoaderService,
        NotificationsService,
        ErrorHandlerService,
        ApiService,
        IntegrationsService,
        QueryService,
        CustomerService,
        SegmentationService,
        FaviconService,
        IntegrationUtilityService,
        ChartConfigService,
        ChartsService,
        VersionCheckService,
        CampaignSummaryMachine,
        LocationService,
        interceptorProviders,
        AccountIntegrationsService,
        GroupIntegrationsService,
        LeaderboardUtilityService,
        { provide: ApiDataService, useClass: ApiHttpClientService },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
            useValue: undefined
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        CanDeactivateGuard,
        SignarlRService,
        RouteStateService,
        MarketingSpendService,
        SnackBarService,
        IconRegistryService,
        IndeedService,
        LinkedInService,
        DashboardService,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { autoFocus: false, maxWidth: '40vw', hasBackdrop: true },
        },
        LaunchDarklyService,
        HighLevelCRMService,
        GlobalFilterService,
        LiveChatService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string
  ) {
    const platform = isPlatformBrowser(platformId)
      ? 'in the browser'
      : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
